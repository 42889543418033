import React, { Component } from "react";
import { Card, Container, Table, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";

const initialDay = [
    { id: 1, day_name: "Thứ 2", short_name: "T2" },
    { id: 2, day_name: "Thứ 3", short_name: "T3" },
    { id: 3, day_name: "Thứ 4", short_name: "T4" },
    { id: 4, day_name: "Thứ 5", short_name: "T5" },
    { id: 5, day_name: "Thứ 6", short_name: "T6" },
    { id: 6, day_name: "Thứ 7", short_name: "T7" },
]

class NutritionPortionWeekReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolInfo: {},
            marketList: [],
            quantitative: [],

            mealInWeek: [],

            isLoading: false,

            groupOption: [],
            groupSelect: { value: 0, label: "Không có nhóm" },

            // Week
            listDay: [],
            startWeek: new Date(),
            endWeek: new Date(),
            weekInMonth: ""
        };
    }

    async componentDidMount() {
        let initialDate = new Date();
        let initialWeek = this.getDaysInWeek(initialDate);

        this.getDetailschool();
        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length - 1]);
        this.getClassGroupByTimes(initialWeek[0], initialWeek[initialWeek.length - 1]);

        this.setStateWeek(initialWeek);
    }

    //#region Fetch API
    getDetailschool = () => {
        axios.get(`/detailSchool`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        schoolInfo: res.data.data[0]
                    })
                }
            });
    }

    getQuantitativeVoucher = (startDay, endDay) => {
        this.setState({
            isLoading: true
        })

        axios.post(`/voucher?is_real=0&type=2&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}&id_day=1,2,3,4,5,6`)
            .then(res => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status === true) {
                    if (res.data.data.length !== 0) {
                        let arrayGroup = [];

                        res.data.data.map(dayItem => {
                            arrayGroup = arrayGroup.concat(dayItem.idGroup);
                            return dayItem;
                        })

                        const newGroupOption = arrayGroup
                            .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
                            .sort(function (a, b) {
                                return b.id - a.id;
                            })
                            .map(item => {
                                return {
                                    value: Number(item.id),
                                    label: `${item.group_name}`,
                                    // is_adult: false,
                                }
                            })
                            .filter(item => !isNaN(item.value))

                        this.setState({
                            quantitative: res.data.data,
                            groupOption: newGroupOption,
                            groupSelect: newGroupOption.length !== 0 ? newGroupOption[0] : { value: 0, label: "Không có nhóm" },
                        })
                    } else {
                        this.setState({
                            quantitative: [],
                            groupOption: [],
                            groupSelect: { value: 0, label: "Không có nhóm" },
                        })
                    }
                } else {
                    this.setState({
                        quantitative: [],
                        groupOption: [],
                        groupSelect: { value: 0, label: "Không có nhóm" },
                    })
                }
            });
    }

    getClassGroupByTimes = (startDate, endDate) => {
        axios.get(`/getClassGroupByTimes?startDate=${startDate.getTime() / 1000}&endDate=${endDate.getTime() / 1000}`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        mealInWeek: res.data.data
                    })
                }
            });
    }
    //#endregion

    //#region Handle Logic
    handleXportPortion = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn xuất file báo cáo khẩu phần dinh dưỡng tuần`,
            icon: "info",
            buttons: ["Đóng", "Xuất"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }

    xport = () => {
        const wb = XLSX.utils.book_new();
        const ws = this.xportWeekNutritionReport();
        XLSX.utils.book_append_sheet(wb, ws, "Khẩu phần dinh dưỡng tuần");
        XLSX.writeFile(wb, "Khẩu phần dinh dưỡng tuần.xlsx");
    };

    xportWeekNutritionReport = () => {
        const table = document.getElementById("week-nutrition-table");

        var ws = XLSX.utils.table_to_sheet(table, { raw: true })

        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", 'K', "L", "M", "N", "O", "P", "Q", "R"];

        const borderStyle = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
        }

        // Title
        ws[`A1`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 16
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 14
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`A3`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 14
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        let index = 4;

        // Header
        for (let i = index; i < 7; i++) {
            colAlpha.map((alpha) => {
                if (ws[`${alpha}${i}`].v === 'empty') {
                    ws[`${alpha}${i}`].v = ' '
                }

                ws[`${alpha}${i}`].s = {
                    fill: {
                        patternType: 'solid',
                        fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'b0edee' },
                        bgColor: { indexed: 64 }
                    },
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    border: borderStyle
                };

                return alpha;
            })

            index++;
        }

        // Calculate list length
        let allIngredient = [];

        this.state.quantitative.map(dayItem => {
            return dayItem.time.map(timeItem => {
                return timeItem.detail.map(foodItem => {
                    return foodItem.ingrendent.map(ingredientItem => {
                        allIngredient.push({
                            ...ingredientItem,
                            calculator: timeItem.is_calcultor,
                            dayIndex: dayItem.stt_day
                        })
                        return ingredientItem;
                    })
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");

        let ingredientLength = this.calculateArrayLength(aIngredientArray);

        const menu = [];

        this.state.listDay.map(day => {
            let mealInDay = 0;

            const timeArray = this.state.quantitative.find(dayItem => dayItem.stt_day === day.id).time.map(timeItem => {
                const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                if (calItem) {
                    mealInDay += Number(calItem.count_student);
                }
                return timeItem;
            })

            if (mealInDay === 0) {
                return "";
            }

            menu.push(`${day.day_name}: ${this.stringLastDate2(day.day)}`)

            timeArray.map(timeItem => {
                if (!timeItem.idGroup?.some(groupItem => groupItem.id === this.state.groupSelect.value)) {
                    return "";
                }

                const foodInSelectedGroup = [];
                timeItem.detail.map(foodItem => {
                    if (foodItem.ingrendent.some(ingredientItem => ingredientItem.group.some(groupItem => groupItem.id === this.state.groupSelect.value))) {
                        foodInSelectedGroup.push(foodItem)
                    }
                    return foodItem;
                })

                menu.push(`${timeItem.time_name}:${foodInSelectedGroup.map((foodItem) => {
                    return " " + foodItem.food_name;
                })
                    }`.replaceAll(",", " - "))

                return timeItem;
            })

            return day;
        })

        const finalLength = menu.length > ingredientLength ? menu.length : ingredientLength

        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 17 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 17 } },

            //Header
            { s: { r: 3, c: 0 }, e: { r: 5, c: 0 } },
            { s: { r: 3, c: 1 }, e: { r: 5, c: 1 } },

            { s: { r: 3, c: 8 }, e: { r: 3, c: 9 } },
            { s: { r: 3, c: 10 }, e: { r: 5, c: 10 } },
            { s: { r: 3, c: 11 }, e: { r: 3, c: 12 } },
            { s: { r: 3, c: 13 }, e: { r: 3, c: 14 } },

            { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },
            { s: { r: 4, c: 12 }, e: { r: 5, c: 12 } },
            { s: { r: 4, c: 13 }, e: { r: 5, c: 13 } },
            { s: { r: 4, c: 14 }, e: { r: 5, c: 14 } },

            { s: { r: 3, c: 15 }, e: { r: 5, c: 15 } },
            { s: { r: 3, c: 16 }, e: { r: 5, c: 16 } },
            { s: { r: 3, c: 17 }, e: { r: 5, c: 17 } },

            // Footer
            { s: { r: finalLength + 7, c: 0 }, e: { r: finalLength + 7, c: 3 } },
            { s: { r: finalLength + 8, c: 0 }, e: { r: finalLength + 8, c: 3 } },
            { s: { r: finalLength + 9, c: 0 }, e: { r: finalLength + 9, c: 3 } },

            { s: { r: finalLength + 7, c: 4 }, e: { r: finalLength + 7, c: 5 } },
            { s: { r: finalLength + 8, c: 4 }, e: { r: finalLength + 8, c: 5 } },
            { s: { r: finalLength + 9, c: 4 }, e: { r: finalLength + 9, c: 5 } },

            { s: { r: finalLength + 6, c: 6 }, e: { r: finalLength + 6, c: 10 } },
            { s: { r: finalLength + 7, c: 6 }, e: { r: finalLength + 7, c: 10 } },
            { s: { r: finalLength + 8, c: 6 }, e: { r: finalLength + 8, c: 10 } },
            { s: { r: finalLength + 9, c: 6 }, e: { r: finalLength + 9, c: 10 } },
            { s: { r: finalLength + 10, c: 6 }, e: { r: finalLength + 10, c: 10 } },

            { s: { r: finalLength + 7, c: 11 }, e: { r: finalLength + 7, c: 12 } },
            { s: { r: finalLength + 7, c: 13 }, e: { r: finalLength + 7, c: 14 } },

            { s: { r: finalLength + 9, c: 15 }, e: { r: finalLength + 10, c: 15 } },
            { s: { r: finalLength + 9, c: 16 }, e: { r: finalLength + 10, c: 16 } },

            { s: { r: finalLength + 10, c: 11 }, e: { r: finalLength + 10, c: 12 } },
            { s: { r: finalLength + 10, c: 13 }, e: { r: finalLength + 10, c: 14 } },

            { s: { r: finalLength + 11, c: 0 }, e: { r: finalLength + 11, c: 8 } },
            { s: { r: finalLength + 11, c: 9 }, e: { r: finalLength + 11, c: 17 } },
        ];

        // Body
        for (let i = index; i < finalLength + index; i++) {
            colAlpha.map((alpha) => {
                if (ws[`${alpha}${i}`].v === 'empty') {
                    ws[`${alpha}${i}`].v = ' '
                }
                ws[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: alpha === "B" || alpha === "R" ? "left" : "right",
                        wrapText: true
                    },
                    border: borderStyle
                };
                return alpha;
            })

            merge.push({ s: { r: i - 1, c: 8 }, e: { r: i - 1, c: 9 } })
        }

        index += finalLength;

        const colAlphaFooter1 = ["A", "B", "C", "D", "E", "F"]
        const colAlphaFooter2 = ["G", "L", "M", "N", "O", "P", "Q"];

        // Footter
        let indexFooter1 = index + 1;

        for (let i = indexFooter1; i < 3 + indexFooter1; i++) {
            colAlphaFooter1.map((alpha) => {
                ws[`${alpha}${i}`].s = {
                    fill: {
                        patternType: 'solid',
                        fgColor: { theme: 8, tint: 0.3999755851924192, rgb: alpha === "A" ? 'b0edee' : "ffffff" },
                        bgColor: { indexed: 64 }
                    },
                    font: {
                        name: "Times New Roman",
                        bold: alpha === "A"
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: alpha === "A" ? "right" : 'center',
                        wrapText: true
                    },
                    border: borderStyle
                };
                return alpha;
            })
        }

        let indexFooter2 = index;

        for (let i = indexFooter2; i < 5 + indexFooter2; i++) {
            colAlphaFooter2.map((alpha) => {
                if (ws[`${alpha}${i}`].v === 'empty') {
                    ws[`${alpha}${i}`].v = ' '
                }

                ws[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: alpha === "G" || i === indexFooter2 + 1
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: alpha === "G" ? "right" : 'center',
                        wrapText: true
                    },
                    border: alpha === "G" ? {} : borderStyle
                };
                return alpha;
            })
        }

        ws[`A${5 + indexFooter2}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`J${5 + indexFooter2}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws['!cols'] = [
            { wch: 2 },
            { wch: 20 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 7 },
            { wch: 5 },
            { wch: 9 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 40 },
        ];

        const row = [{ hpt: 40 }, { hpt: 25 }, { hpt: 25 }, { hpt: 30 }];
        ws['!rows'] = row;

        ws["!merges"] = merge;

        return ws;
    }

    calculateArrayLength = (aIngredientArray) => {
        let ingredientLength = 0;

        aIngredientArray.map(ingredientArray => {
            let ingredientAmount = 0;

            ingredientArray.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.find(groupItem => groupItem.id === this.state.groupSelect.value);
                const selectedCalculator = ingredientItem.calculator.find(calculatorItem => Number(calculatorItem.id_group) === this.state.groupSelect.value);

                if (selectedGroup && selectedCalculator && Number(selectedCalculator.count_student) !== 0) {
                    if (ingredientItem.unit.is_root === 0) {
                        ingredientAmount += Number(selectedGroup.amount) * Number(selectedCalculator.count_student);
                    } else {
                        ingredientAmount += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume * Number(selectedCalculator.count_student));
                    }
                }

                return ingredientItem;
            })

            if (ingredientAmount === 0) {
                return "";
            }

            ingredientLength++;

            return ingredientArray;
        })

        return ingredientLength;
    }

    getDaysInMonth = (month, year) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    getDaysInWeek = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    stringLastDate2 = (date) => ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();

    setStateWeek = (week) => {
        this.setState({
            listDay: initialDay.map((day, index) => {
                return {
                    id: day.id,
                    day_name: day.day_name,
                    short_name: day.short_name,
                    day: week[index]
                }
            }),
            weekInMonth: (0 | week[0].getDate() / 7) + 1,
            startWeek: week[0],
            endWeek: week[week.length - 1]
        })
    }

    group = (arr, key) => {
        return [...arr.reduce((acc, o) =>
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
            , new Map()).values()];
    }
    //#endregion

    // Render
    RenderWeekNutritionPortion(aIngredientArray, totalMealInWeek, arrayMealInWeek) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (aIngredientArray.length === 0 || totalMealInWeek === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center my-3">
                    Chưa có thực đơn hoặc bạn chưa lưu khẩu phần ăn
                </div>
            )
        }

        // Menu following form
        const menu = [];

        this.state.listDay.map(day => {
            let mealInDay = 0;

            const timeArray = this.state.quantitative.find(dayItem => dayItem.stt_day === day.id).time.map(timeItem => {
                const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                if (calItem) {
                    mealInDay += Number(calItem.count_student);
                }
                return timeItem;
            })

            if (mealInDay === 0) {
                return "";
            }

            menu.push(`${day.day_name}: ${this.stringLastDate2(day.day)}`)

            timeArray.map(timeItem => {
                if (!timeItem.idGroup?.some(groupItem => groupItem.id === this.state.groupSelect.value)) {
                    return "";
                }

                const foodInSelectedGroup = [];
                timeItem.detail.map(foodItem => {
                    if (foodItem.ingrendent.some(ingredientItem => ingredientItem.group.some(groupItem => groupItem.id === this.state.groupSelect.value))) {
                        foodInSelectedGroup.push(foodItem)
                    }
                    return foodItem;
                })

                menu.push(`${timeItem.time_name}:${foodInSelectedGroup.map((foodItem) => {
                    return " " + foodItem.food_name;
                })
                    }`.replaceAll(",", " - "))

                return timeItem;
            })

            return day;
        })

        let ingredientLength = this.calculateArrayLength(aIngredientArray);

        if (menu.length > ingredientLength) {
            for (let i = 0; i < menu.length - ingredientLength; i++) {
                aIngredientArray.push([{ pass: true }])
            }
        }

        let numberDayInWeek = 0;
        let totalCostInWeek = 0;

        let ingredientIndex = 0;
        let ingredientIndex1 = 0;

        let energyStat = 0;
        let glucidStat = 0;

        let animalProteinStat = 0;
        let plantProteinStat = 0;

        let animalLipidStat = 0;
        let plantLipidStat = 0;

        let recommendTo = 0;

        return (
            <>
                <div className="d-flex flex-column-reverse">
                    <div style={{ overflowX: "scroll" }}>
                        <Table bordered style={{ minWidth: 777 }}>
                            <thead className="align-middle text-center">
                                <tr>
                                    {
                                        this.state.listDay.map(day => {
                                            return (
                                                <th key={day.id}>{day.day_name}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        this.state.listDay.map(day => {
                                            let mealInDay = 0;

                                            const timeArray = this.state.quantitative.find(dayItem => dayItem.stt_day === day.id).time.map(timeItem => {
                                                const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                                                if (calItem) {
                                                    mealInDay += Number(calItem.count_student);
                                                }
                                                return timeItem;
                                            })

                                            if (mealInDay === 0) {
                                                return (
                                                    <td key={day.id}></td>
                                                );
                                            }

                                            return (
                                                <td key={day.id} style={{ maxWidth: 111 }}>
                                                    {
                                                        timeArray.map(timeItem => {
                                                            if (!timeItem.idGroup?.some(groupItem => groupItem.id === this.state.groupSelect.value)) {
                                                                return "";
                                                            }

                                                            const foodInSelectedGroup = [];
                                                            timeItem.detail.map(foodItem => {
                                                                if (foodItem.ingrendent.some(ingredientItem => ingredientItem.group.some(groupItem => groupItem.id === this.state.groupSelect.value))) {
                                                                    foodInSelectedGroup.push(foodItem)
                                                                }
                                                                return foodItem;
                                                            })

                                                            return (
                                                                <div key={timeItem.id_time}>
                                                                    <div className="fw-bold d-inline">
                                                                        {`${timeItem.time_name}:`}
                                                                    </div>
                                                                    {
                                                                        `${foodInSelectedGroup.map((foodItem) => {
                                                                            return " " + foodItem.food_name;
                                                                        })
                                                                            }`.replaceAll(",", " - ")
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div style={{ overflowX: "scroll" }}>
                        <Table bordered>
                            <thead className="align-middle text-center">
                                <tr>
                                    <th rowSpan={3}>STT</th>
                                    <th rowSpan={3}>Tên thực phẩm</th>
                                    {
                                        this.state.listDay.map(day => {

                                            return (
                                                <th key={day.id}>{day.short_name}</th>
                                            )
                                        })
                                    }
                                    <th colSpan={2}>Tổng cộng (kg)</th>
                                    <th rowSpan={3}>Số (g) 1 suất ăn bình quân/ngày</th>
                                    <th colSpan={2}>Chất đạm (g)</th>
                                    <th colSpan={2}>Chất béo (g)</th>
                                    <th rowSpan={3}>Chất đường bột (g)</th>
                                    <th rowSpan={3}>Năng lượng (kcal)</th>
                                </tr>
                                <tr>
                                    {
                                        this.state.listDay.map(day => {
                                            let mealInDay = 0;

                                            this.state.quantitative.find(dayItem => dayItem.stt_day === day.id).time.map(timeItem => {
                                                const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                                                if (calItem) {
                                                    mealInDay += Number(calItem.count_student);
                                                }
                                                return timeItem;
                                            })

                                            if (mealInDay === 0) {
                                                return (
                                                    <th key={day.id}></th>
                                                )
                                            }

                                            numberDayInWeek++;
                                            return (
                                                <th key={day.id}>{this.stringFirstDate(day.day)}</th>
                                            )
                                        })
                                    }
                                    <th>Ngày</th>
                                    <th>{numberDayInWeek}</th>

                                    <th rowSpan={2}>Động vật</th>
                                    <th rowSpan={2}>Thực vật</th>
                                    <th rowSpan={2}>Động vật</th>
                                    <th rowSpan={2}>Thực vật</th>
                                </tr>
                                <tr>
                                    {
                                        arrayMealInWeek.map(meal => {

                                            if (meal.meal === 0) {
                                                return (
                                                    <th key={meal.id}></th>
                                                )
                                            }

                                            return (
                                                <th key={meal.id}>
                                                    <CurrencyFormat
                                                        value={Number(meal.meal)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </th>
                                            )
                                        })
                                    }
                                    <th>Suất ăn</th>
                                    <th>
                                        <CurrencyFormat
                                            value={Number(totalMealInWeek)}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            isNumericString
                                            displayType="text"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    aIngredientArray.map((ingredientArray) => {
                                        let realMass = 0; // Tổng KL 1 nguyên liệu
                                        let realMassDateArray = []; // KL theo ngày
                                        let averageRealMassOfMeal = 0; // KL bình quân 1 suất ăn

                                        let averagePureMassOfMeal = 0; // KL tinh bình quân 1 suất ăn

                                        let energy = 0;
                                        let animalProtein = 0;
                                        let plantProtein = 0;
                                        let animalLipid = 0;
                                        let plantLipid = 0;
                                        let glucid = 0;

                                        // Tính KL, KL tinh và KL bình quân
                                        this.state.listDay.map(day => {
                                            let realMassOfDate = 0;
                                            let pureMassOfDate = 0;

                                            const mealInDay = arrayMealInWeek.find(meal => meal.id === day.id)?.meal;

                                            ingredientArray
                                                .filter(ingredientItem => ingredientItem.dayIndex === day.id)
                                                .map(ingredientItem => {
                                                    const selectedGroup = ingredientItem.group.find(groupItem => groupItem.id === this.state.groupSelect.value);
                                                    const selectedCalculator = ingredientItem.calculator.find(calculatorItem => Number(calculatorItem.id_group) === this.state.groupSelect.value);

                                                    if (selectedGroup && selectedCalculator && Number(selectedCalculator.count_student) !== 0) {
                                                        if (ingredientItem.unit.is_root === 0) {
                                                            realMassOfDate += Number(selectedGroup.amount) * Number(selectedCalculator.count_student);
                                                            pureMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                                        } else {
                                                            realMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_RealVolume);
                                                            pureMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_FineMass);
                                                        }

                                                        recommendTo = Number(selectedGroup.recommended_to);
                                                    }

                                                    return ingredientItem;
                                                })

                                            if (mealInDay !== 0) {
                                                realMass += realMassOfDate;
                                                realMassDateArray.push({
                                                    dayIndex: day.id,
                                                    mass: realMassOfDate
                                                })

                                                averageRealMassOfMeal += (realMassOfDate / mealInDay);
                                                averagePureMassOfMeal += (pureMassOfDate / mealInDay);

                                            } else {
                                                realMassDateArray.push({
                                                    dayIndex: day.id,
                                                    mass: 0
                                                })
                                            }

                                            const timeIngredientArray = this.group(ingredientArray.filter(ingredientItem => ingredientItem.dayIndex === day.id), "timeIndex");

                                            timeIngredientArray.map(timeIngredient => {

                                                timeIngredient.map(ingredientItem => {
                                                    const selectedGroup = ingredientItem.group.find(groupItem => groupItem.id === this.state.groupSelect.value);
                                                    const selectedCalculator = ingredientItem.calculator.find(calculatorItem => Number(calculatorItem.id_group) === this.state.groupSelect.value);

                                                    if (selectedGroup && selectedCalculator && Number(selectedCalculator.count_student) !== 0) {
                                                        if (ingredientItem.unit.is_root === 0) {
                                                            totalCostInWeek += Math.round(Number(selectedGroup.amount) * Number(selectedCalculator.count_student) * Number(timeIngredient[0].nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume));
                                                        } else {
                                                            totalCostInWeek += Math.round(Number(selectedGroup.amount) * Number(selectedCalculator.count_student) * Number(timeIngredient[0].nutrients.price));
                                                        }
                                                    }

                                                    return ingredientItem;
                                                })

                                                return timeIngredient;
                                            })

                                            return day
                                        })

                                        if (realMass === 0) {
                                            return "";
                                        }

                                        if (arrayMealInWeek.filter(meal => meal.meal !== 0).length !== 0) {
                                            averageRealMassOfMeal = averageRealMassOfMeal / arrayMealInWeek.filter(meal => meal.meal !== 0).length;
                                            averagePureMassOfMeal = averagePureMassOfMeal / arrayMealInWeek.filter(meal => meal.meal !== 0).length;
                                        }

                                        // Tính năng lượng
                                        if (averagePureMassOfMeal !== 0) {
                                            if (ingredientArray[0].type_subspecies === 1) {
                                                animalProtein = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_protein) / 100;
                                                animalLipid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_lipid) / 100;
                                            } else {
                                                plantProtein = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_protein) / 100;
                                                plantLipid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_lipid) / 100;
                                            }

                                            energy = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_energy) / 100;
                                            glucid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_glucid) / 100;
                                        }

                                        energyStat += energy;
                                        glucidStat += glucid;
                                        animalProteinStat += animalProtein;
                                        plantProteinStat += plantProtein;
                                        animalLipidStat += animalLipid;
                                        plantLipidStat += plantLipid;

                                        ingredientIndex++;

                                        return (
                                            <tr key={ingredientArray[0].id_ingredent} className="align-middle text-center">
                                                <td>{ingredientArray[0].pass ? "" : ingredientIndex}</td>
                                                <td className="text-start">{ingredientArray[0]?.ingredent_name ? ingredientArray[0].ingredent_name : ""}</td>
                                                {
                                                    this.state.listDay.map(day => {
                                                        const realMassOfDate = realMassDateArray.find(massItem => massItem.dayIndex === day.id).mass;

                                                        return (
                                                            <td key={day.id}>{realMassOfDate !== 0 ? formatter.format(realMassOfDate / 1000) : ""}</td>
                                                        )
                                                    })
                                                }
                                                <td colSpan={2}>{realMass !== 0 ? formatter.format(realMass / 1000) : ""}</td>
                                                <td>{averageRealMassOfMeal !== 0 ? formatter.format(averageRealMassOfMeal) : ""}</td>
                                                <td>{animalProtein !== 0 ? formatter.format(animalProtein) : ""}</td>
                                                <td>{plantProtein !== 0 ? formatter.format(plantProtein) : ""}</td>
                                                <td>{animalLipid !== 0 ? formatter.format(animalLipid) : ""}</td>
                                                <td>{plantLipid !== 0 ? formatter.format(plantLipid) : ""}</td>
                                                <td>{glucid !== 0 ? formatter.format(glucid) : ""}</td>
                                                <td>{energy !== 0 ? formatter.format(energy) : ""}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>

                    <Row>
                        <Col sm={12} md={4}>
                            <div className="d-flex align-content-between flex-wrap h-100">
                                <div className="fw-bold text-center w-100 mb-3">
                                    <div>Tuần lễ từ ngày {this.stringLastDate(this.state.startWeek)} đến ngày {this.stringLastDate(this.state.endWeek)}</div>
                                    <div>
                                        {this.state.groupSelect.label} (Buổi
                                        {
                                            this.state.quantitative[0]?.time?.filter(timeItem => timeItem.idGroup.some(groupItem => groupItem.id === this.state.groupSelect.value))
                                                .map((timeItem, index) => {

                                                    if (index === this.state.quantitative[0]?.time?.filter(timeItem => timeItem.idGroup.some(groupItem => groupItem.id === this.state.groupSelect.value)).length - 1) {
                                                        return ` ${timeItem.time_name}`;
                                                    }

                                                    return ` ${timeItem.time_name} -`;
                                                })
                                        }
                                        )
                                    </div>
                                </div>

                                <Table bordered style={{ marginBottom: "2rem" }}>
                                    <tbody>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">
                                                Tổng số tiền chi trong tuần:
                                            </th>
                                            <td>
                                                <CurrencyFormat
                                                    value={Number(totalCostInWeek).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">
                                                Bình quân chi 1 suất:
                                            </th>
                                            <td>
                                                <CurrencyFormat
                                                    value={Number(totalCostInWeek / totalMealInWeek).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">
                                                Giá thành kcal:
                                            </th>
                                            <td>
                                                {
                                                    totalMealInWeek !== 0 && energyStat !== 0
                                                        ?
                                                        <CurrencyFormat
                                                            value={Number(totalCostInWeek / (energyStat * totalMealInWeek)).toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                        : ""
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col sm={12} md={8}>
                            <div style={{ overflowX: "scroll" }}>
                                <Table bordered style={{ minWidth: 444 }}>
                                    <thead className="align-middle text-center">
                                        <tr>
                                            <th rowSpan={2}></th>
                                            <th colSpan={2}>Đạm(g)</th>
                                            <th colSpan={2}>Béo(g)</th>
                                            <th rowSpan={2}>Đường bột(g)</th>
                                            <th rowSpan={2}>Năng lượng (kcal)</th>
                                        </tr>
                                        <tr>
                                            <th>ĐV</th>
                                            <th>TV</th>
                                            <th>ĐV</th>
                                            <th>TV</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">Tổng cộng:</th>
                                            <td>{animalProteinStat !== 0 ? formatter.format(animalProteinStat) : ""}</td>
                                            <td>{plantProteinStat !== 0 ? formatter.format(plantProteinStat) : ""}</td>
                                            <td>{animalLipidStat !== 0 ? formatter.format(animalLipidStat) : ""}</td>
                                            <td>{plantLipidStat !== 0 ? formatter.format(plantLipidStat) : ""}</td>
                                            <td>{glucidStat !== 0 ? formatter.format(glucidStat) : ""}</td>
                                            <td>{energyStat !== 0 ? formatter.format(energyStat) : ""}</td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">P-L-G:</th>
                                            <th colSpan={2}>14</th>
                                            <th colSpan={2}>26</th>
                                            <th>60</th>
                                            <td></td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">Định mức:</th>
                                            <td>{formatter.format(Number((recommendTo * 14 * 60) / 40000))}</td>
                                            <td>{formatter.format(Number((recommendTo * 14 * 40) / 40000))}</td>
                                            <td>{formatter.format(Number((recommendTo * 26 * 60) / 90000))}</td>
                                            <td>{formatter.format(Number((recommendTo * 26 * 40) / 90000))}</td>
                                            <td>{formatter.format(Number((recommendTo * 60) / 400))}</td>
                                            <td>
                                                <CurrencyFormat
                                                    value={recommendTo}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">Tỉ lệ từng loại:</th>
                                            <td>{formatter.format(Number(animalProteinStat * 100 / ((recommendTo * 14 * 60) / 40000)))}%</td>
                                            <td>{formatter.format(Number(plantProteinStat * 100 / ((recommendTo * 14 * 40) / 40000)))}%</td>
                                            <td>{formatter.format(Number(animalLipidStat * 100 / ((recommendTo * 26 * 60) / 90000)))}%</td>
                                            <td>{formatter.format(Number(plantLipidStat * 100 / ((recommendTo * 26 * 40) / 90000)))}%</td>
                                            <td
                                                rowSpan={2}
                                                style={
                                                    60 <= Number(glucidStat * 100 / ((recommendTo * 60) / 400)) && Number(glucidStat * 100 / ((recommendTo * 60) / 400))
                                                        ? { color: "green" }
                                                        : { color: "red" }
                                                }
                                            >
                                                {formatter.format(Number(glucidStat * 100 / ((recommendTo * 60) / 400)))}%
                                            </td>
                                            <td
                                                rowSpan={2}
                                                style={
                                                    60 <= Number(energyStat * 100 / recommendTo) && Number(energyStat * 100 / recommendTo)
                                                        ? { color: "green" }
                                                        : { color: "red" }
                                                }
                                            >
                                                {formatter.format(Number(energyStat * 100 / recommendTo))}%
                                            </td>
                                        </tr>
                                        <tr className="align-middle text-center">
                                            <th className="text-end">Tỉ lệ đạt:</th>
                                            <td
                                                colSpan={2}
                                                style={
                                                    60 <= Number((animalProteinStat + plantProteinStat) * 100 / (((recommendTo * 14 * 60) / 40000) + ((recommendTo * 14 * 40) / 40000))) && Number((animalProteinStat + plantProteinStat) * 100 / (((recommendTo * 14 * 60) / 40000) + ((recommendTo * 14 * 40) / 40000)))
                                                        ? { color: "green" }
                                                        : { color: "red" }
                                                }
                                            >
                                                {formatter.format(Number((animalProteinStat + plantProteinStat) * 100 / (((recommendTo * 14 * 60) / 40000) + ((recommendTo * 14 * 40) / 40000))))}%
                                            </td>
                                            <td
                                                colSpan={2}
                                                style={
                                                    60 <= Number((animalLipidStat + plantLipidStat) * 100 / (((recommendTo * 26 * 60) / 90000) + ((recommendTo * 26 * 40) / 90000))) && Number((animalLipidStat + plantLipidStat) * 100 / (((recommendTo * 26 * 60) / 90000) + ((recommendTo * 26 * 40) / 90000)))
                                                        ? { color: "green" }
                                                        : { color: "red" }
                                                }
                                            >
                                                {formatter.format(Number((animalLipidStat + plantLipidStat) * 100 / (((recommendTo * 26 * 60) / 90000) + ((recommendTo * 26 * 40) / 90000))))}%
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Table
                    bordered
                    className="d-none"
                    id="week-nutrition-table"
                >
                    <thead className="align-middle text-center">
                        <tr>
                            <th colSpan={18}>BẢNG TỔNG HỢP KHẨU PHẦN DINH DƯỠNG TRONG TUẦN</th>
                        </tr>
                        <tr>
                            <th colSpan={18}>Tuần lễ từ ngày {this.stringLastDate(this.state.startWeek)} đến ngày {this.stringLastDate(this.state.endWeek)}</th>
                        </tr>
                        <tr>
                            <th colSpan={18}>
                                {this.state.groupSelect.label} (Buổi
                                {
                                    this.state.quantitative[0]?.time?.filter(timeItem => timeItem.idGroup.some(groupItem => groupItem.id === this.state.groupSelect.value))
                                        .map((timeItem, index) => {

                                            if (index === this.state.quantitative[0]?.time?.filter(timeItem => timeItem.idGroup.some(groupItem => groupItem.id === this.state.groupSelect.value)).length - 1) {
                                                return ` ${timeItem.time_name}`;
                                            }

                                            return ` ${timeItem.time_name} -`;
                                        })
                                }
                                )
                            </th>
                        </tr>
                        <tr>
                            <th>STT</th>
                            <th>Tên thực phẩm</th>
                            {
                                this.state.listDay.map(day => {

                                    return (
                                        <th key={day.id}>{day.short_name}</th>
                                    )
                                })
                            }
                            <th>Tổng cộng (kg)</th>
                            <th>empty</th>
                            <th>Số (g) 1 suất ăn bình quân/ngày</th>
                            <th>Chất đạm (g)</th>
                            <th>empty</th>
                            <th>Chất béo (g)</th>
                            <th>empty</th>
                            <th>Chất đường bột (g)</th>
                            <th>Năng lượng (kcal)</th>
                            <th>Thực đơn</th>
                        </tr>
                        <tr>
                            <th>empty</th>
                            <th>empty</th>
                            {
                                this.state.listDay.map(day => {
                                    let mealInDay = 0;

                                    this.state.quantitative.find(dayItem => dayItem.stt_day === day.id).time.map(timeItem => {
                                        const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                                        if (calItem) {
                                            mealInDay += Number(calItem.count_student);
                                        }
                                        return timeItem;
                                    })

                                    if (mealInDay === 0) {
                                        return (
                                            <th key={day.id}>empty</th>
                                        )
                                    }

                                    numberDayInWeek++;
                                    return (
                                        <th key={day.id}>{this.stringFirstDate(day.day)}</th>
                                    )
                                })
                            }
                            <th>Ngày</th>
                            <th>{numberDayInWeek}</th>
                            <th>empty</th>

                            <th>Động vật</th>
                            <th>Thực vật</th>
                            <th>Động vật</th>
                            <th>Thực vật</th>

                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr>
                            <th>empty</th>
                            <th>empty</th>
                            {
                                arrayMealInWeek.map(meal => {

                                    if (meal.meal === 0) {
                                        return (
                                            <th key={meal.id}>empty</th>
                                        )
                                    }

                                    return (
                                        <th key={meal.id}>
                                            <CurrencyFormat
                                                value={Number(meal.meal)}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                isNumericString
                                                displayType="text"
                                            />
                                        </th>
                                    )
                                })
                            }
                            <th>Suất ăn</th>
                            <th>
                                <CurrencyFormat
                                    value={Number(totalMealInWeek)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </th>
                            <th>empty</th>

                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            aIngredientArray.map((ingredientArray) => {
                                let realMass = 0; // Tổng KL 1 nguyên liệu
                                let realMassDateArray = []; // KL theo ngày
                                let averageRealMassOfMeal = 0; // KL bình quân 1 suất ăn

                                let averagePureMassOfMeal = 0; // KL tinh bình quân 1 suất ăn

                                let energy = 0;
                                let animalProtein = 0;
                                let plantProtein = 0;
                                let animalLipid = 0;
                                let plantLipid = 0;
                                let glucid = 0;

                                // Tính KL, KL tinh và KL bình quân
                                this.state.listDay.map(day => {
                                    let realMassOfDate = 0;
                                    let pureMassOfDate = 0;

                                    const mealInDay = arrayMealInWeek.find(meal => meal.id === day.id)?.meal;

                                    ingredientArray
                                        .filter(ingredientItem => ingredientItem.dayIndex === day.id)
                                        .map(ingredientItem => {
                                            const selectedGroup = ingredientItem.group.find(groupItem => groupItem.id === this.state.groupSelect.value);
                                            const selectedCalculator = ingredientItem.calculator.find(calculatorItem => Number(calculatorItem.id_group) === this.state.groupSelect.value);

                                            if (selectedGroup && selectedCalculator && Number(selectedCalculator.count_student) !== 0) {
                                                if (ingredientItem.unit.is_root === 0) {
                                                    realMassOfDate += Number(selectedGroup.amount) * Number(selectedCalculator.count_student);
                                                    pureMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                                } else {
                                                    realMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_RealVolume);
                                                    pureMassOfDate += Number(selectedGroup.amount * selectedCalculator.count_student * ingredientItem.nutrients.ingredent_FineMass);
                                                }
                                            }

                                            return ingredientItem;
                                        })

                                    if (mealInDay !== 0) {
                                        realMass += realMassOfDate;
                                        realMassDateArray.push({
                                            dayIndex: day.id,
                                            mass: realMassOfDate
                                        })

                                        averageRealMassOfMeal += (realMassOfDate / mealInDay);
                                        averagePureMassOfMeal += (pureMassOfDate / mealInDay);

                                    } else {
                                        realMassDateArray.push({
                                            dayIndex: day.id,
                                            mass: 0
                                        })
                                    }

                                    return day
                                })

                                if (realMass === 0 && !ingredientArray[0].pass) {
                                    return "";
                                }

                                if (arrayMealInWeek.filter(meal => meal.meal !== 0).length !== 0) {
                                    averageRealMassOfMeal = averageRealMassOfMeal / arrayMealInWeek.filter(meal => meal.meal !== 0).length;
                                    averagePureMassOfMeal = averagePureMassOfMeal / arrayMealInWeek.filter(meal => meal.meal !== 0).length;
                                }

                                // Tính năng lượng
                                if (averagePureMassOfMeal !== 0) {
                                    if (ingredientArray[0].type_subspecies === 1) {
                                        animalProtein = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_protein) / 100;
                                        animalLipid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_lipid) / 100;
                                    } else {
                                        plantProtein = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_protein) / 100;
                                        plantLipid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_lipid) / 100;
                                    }

                                    energy = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_energy) / 100;
                                    glucid = averagePureMassOfMeal * Number(ingredientArray[0].nutrients.ingredent_glucid) / 100;
                                }

                                ingredientIndex1++;

                                return (
                                    <tr key={ingredientArray[0].id_ingredent} className="align-middle text-center">
                                        <td>{ingredientArray[0].pass ? "empty" : ingredientIndex1}</td>
                                        <td className="text-start">{ingredientArray[0]?.ingredent_name ? ingredientArray[0].ingredent_name : "empty"}</td>
                                        {
                                            this.state.listDay.map(day => {
                                                const realMassOfDate = realMassDateArray.find(massItem => massItem.dayIndex === day.id).mass;

                                                return (
                                                    <td key={day.id}>{realMassOfDate !== 0 ? formatter.format(realMassOfDate / 1000) : "empty"}</td>
                                                )
                                            })
                                        }
                                        <td>{realMass !== 0 ? formatter.format(realMass / 1000) : "empty"}</td>
                                        <td>empty</td>
                                        <td>{averageRealMassOfMeal !== 0 ? formatter.format(averageRealMassOfMeal) : "empty"}</td>
                                        <td>{animalProtein !== 0 ? formatter.format(animalProtein) : "empty"}</td>
                                        <td>{plantProtein !== 0 ? formatter.format(plantProtein) : "empty"}</td>
                                        <td>{animalLipid !== 0 ? formatter.format(animalLipid) : "empty"}</td>
                                        <td>{plantLipid !== 0 ? formatter.format(plantLipid) : "empty"}</td>
                                        <td>{glucid !== 0 ? formatter.format(glucid) : "empty"}</td>
                                        <td>{energy !== 0 ? formatter.format(energy) : "empty"}</td>
                                        <td className="text-start">
                                            {
                                                ingredientIndex1 - 1 < menu.length ? menu[ingredientIndex1 - 1] : "empty"
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr className="align-middle text-center">
                            <th colSpan={6}></th>
                            <th colSpan={5} className="text-end">Tổng cộng:</th>
                            <td>{animalProteinStat !== 0 ? formatter.format(animalProteinStat) : "empty"}</td>
                            <td>{plantProteinStat !== 0 ? formatter.format(plantProteinStat) : "empty"}</td>
                            <td>{animalLipidStat !== 0 ? formatter.format(animalLipidStat) : "empty"}</td>
                            <td>{plantLipidStat !== 0 ? formatter.format(plantLipidStat) : "empty"}</td>
                            <td>{glucidStat !== 0 ? formatter.format(glucidStat) : "empty"}</td>
                            <td>{energyStat !== 0 ? formatter.format(energyStat) : "empty"}</td>
                            <td></td>
                        </tr>
                        <tr className="align-middle text-center">
                            <th className="text-end">
                                Tổng số tiền chi trong tuần:
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <td>
                                <CurrencyFormat
                                    value={Number(totalCostInWeek).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </td>
                            <td>empty</td>
                            <th colSpan={5} className="text-end">Tỉ lệ P-L-G:</th>
                            <th>14</th>
                            <th>empty</th>

                            <th>26</th>
                            <th>empty</th>

                            <th>60</th>
                            <td>empty</td>
                            <td></td>
                        </tr>
                        <tr className="align-middle text-center">
                            <th className="text-end">
                                Bình quân chi 1 suất:
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <td>
                                <CurrencyFormat
                                    value={Number(totalCostInWeek / totalMealInWeek).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </td>
                            <td>empty</td>

                            <th colSpan={5} className="text-end">Định mức:</th>
                            <td>{formatter.format(Number((recommendTo * 14 * 60) / 40000))}</td>
                            <td>{formatter.format(Number((recommendTo * 14 * 40) / 40000))}</td>
                            <td>{formatter.format(Number((recommendTo * 26 * 60) / 90000))}</td>
                            <td>{formatter.format(Number((recommendTo * 26 * 40) / 90000))}</td>
                            <td>{formatter.format(Number((recommendTo * 60) / 400))}</td>
                            <td>
                                <CurrencyFormat
                                    value={recommendTo}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr className="align-middle text-center">
                            <th className="text-end">
                                Giá thành kcal:
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <td>
                                {
                                    totalMealInWeek !== 0 && energyStat !== 0
                                        ?
                                        <CurrencyFormat
                                            value={Number(totalCostInWeek / (energyStat * totalMealInWeek)).toFixed(2)}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            isNumericString
                                            displayType="text"
                                        />
                                        : ""
                                }
                            </td>
                            <td>empty</td>

                            <th colSpan={5} className="text-end">Tỉ lệ từng loại:</th>
                            <td>{formatter.format(Number(animalProteinStat * 100 / ((recommendTo * 14 * 60) / 40000)))}%</td>
                            <td>{formatter.format(Number(plantProteinStat * 100 / ((recommendTo * 14 * 40) / 40000)))}%</td>
                            <td>{formatter.format(Number(animalLipidStat * 100 / ((recommendTo * 26 * 60) / 90000)))}%</td>
                            <td>{formatter.format(Number(plantLipidStat * 100 / ((recommendTo * 26 * 40) / 90000)))}%</td>
                            <td>{formatter.format(Number(glucidStat * 100 / ((recommendTo * 60) / 400)))}%</td>
                            <td>{formatter.format(Number(energyStat * 100 / recommendTo))}%</td>

                            <td></td>
                        </tr>
                        <tr className="align-middle text-center">
                            <td colSpan={6}></td>
                            <th colSpan={5} className="text-end">Tỉ lệ đạt:</th>

                            <td>{formatter.format(Number((animalProteinStat + plantProteinStat) * 100 / (((recommendTo * 14 * 60) / 40000) + ((recommendTo * 14 * 40) / 40000))))}%</td>
                            <td>empty</td>

                            <td>{formatter.format(Number((animalLipidStat + plantLipidStat) * 100 / (((recommendTo * 26 * 60) / 90000) + ((recommendTo * 26 * 40) / 90000))))}%</td>
                            <td>empty</td>

                            <td>empty</td>
                            <td>empty</td>
                            <td></td>
                        </tr>
                        <tr className="align-middle text-center">
                            <td>Người lập</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Hiệu phó bán trú</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    render() {
        let allIngredient = [];

        this.state.quantitative.map(dayItem => {
            return dayItem.time.map(timeItem => {
                return timeItem.detail.map(foodItem => {
                    return foodItem.ingrendent.map(ingredientItem => {

                        allIngredient.push({
                            ...ingredientItem,
                            calculator: timeItem.is_calcultor,
                            dayIndex: dayItem.stt_day,
                            timeIndex: timeItem.stt_time
                        })

                        return ingredientItem;
                    })
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");


        let totalMealInWeek = 0;
        const arrayMealInWeek = [];
        this.state.listDay.map(day => {
            let mealInDay = 0;

            this.state.quantitative.find(dayItem => dayItem.stt_day === day.id)?.time.map(timeItem => {
                const calItem = timeItem.is_calcultor.find(calItem => Number(calItem.id_group) === this.state.groupSelect.value);
                if (calItem) {
                    mealInDay += Number(calItem.count_student);
                }
                return timeItem;
            })

            const group = this.state.mealInWeek.find(dayItem => dayItem.id_day === day.id)?.classGroups.find(groupItem => groupItem.id_group === this.state.groupSelect.value);

            if (group) {
                let absent = group.count_absent || 0;
                let notEat = group.count_not_eat || 0;
                let totalStudent = group.count_student || 0;

                arrayMealInWeek.push({
                    id: day.id,
                    meal: mealInDay === 0 ? 0 : totalStudent - notEat - absent
                })
                totalMealInWeek += mealInDay === 0 ? 0 : (totalStudent - notEat - absent)
            }

            return day;
        })

        return (
            <Container fluid >
                <Card>
                    <Card.Header>
                        <div className="d-block d-md-flex justify-content-between">
                            <div className="d-block d-md-flex">
                                <Card.Title className="me-3 d-flex align-items-center">
                                    Khẩu phần dinh dưỡng tuần {this.stringFirstDate(this.state.startWeek)} - {this.stringLastDate(this.state.endWeek)}
                                </Card.Title>
                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                                        className="me-2"
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(e.target.valueAsNumber);
                                                const week = this.getDaysInWeek(firstDayOfWeek);

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1]);
                                                this.getClassGroupByTimes(week[0], week[week.length - 1]);

                                                this.setStateWeek(week)
                                            } else {
                                                const today = new Date();
                                                const week = this.getDaysInWeek(today);

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1]);
                                                this.getClassGroupByTimes(week[0], week[week.length - 1]);

                                                this.setStateWeek(week)
                                            }
                                        }}
                                        disabled={this.state.isLoading}
                                    />

                                    <Select
                                        options={this.state.groupOption}
                                        value={this.state.groupSelect}
                                        onChange={(choice) => {
                                            this.setState({
                                                groupSelect: choice,
                                            })
                                        }}

                                        isDisabled={this.state.groupSelect?.value === 0 || this.state.isLoading}
                                    />
                                </div>
                            </div>

                            <Button
                                variant="success"
                                size="sm"
                                onClick={() => {
                                    this.handleXportPortion();
                                }}
                                disabled={this.state.isLoading || this.state.groupSelect?.value === 0 || aIngredientArray.length === 0 || totalMealInWeek === 0}
                                className="mt-3 mt-md-0 me-3"
                            >
                                Xuất file <i className="fa-solid fa-file-export"></i>
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {
                            this.state.isLoading
                                ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải khẩu phần dinh dưỡng tuần...</span>
                                    </div>
                                </div>
                                :
                                this.RenderWeekNutritionPortion(aIngredientArray, totalMealInWeek, arrayMealInWeek)
                        }
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default NutritionPortionWeekReport;