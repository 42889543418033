
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
// import { toast } from "react-toastify";
// import swal from "sweetalert";
// import FormatData from "../FormatData";
import DataTables from "../datatable/DataTables";

import {
  // Button,
  // Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab, Form, Table
} from "react-bootstrap";

class Storeinventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listinventory: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      tabkey: "1",
      checkload: true,

      monthOptions: [],
      selectedMonth: { value: 0, label: "Tất cả tháng" },

      yearOptions: [],
      selectedYear: { value: 0, label: "Tất cả năm" },
    };
  }

  componentDidMount() {
    document.title = "Nguyên liệu";
    this.getlistinventory(1, 100);

    // Init month options
    const initialMonths = [];
    initialMonths.push({ value: 0, label: "Tất cả tháng" })
    for (let i = 1; i <= 12; i++) {
      initialMonths.push({ value: i, label: "Tháng " + i })
    }

    // Init year options
    const initialYears = [];
    initialYears.push({ value: 0, label: "Tất cả năm" })
    const currentDate = new Date();
    for (let i = currentDate.getFullYear(); i >= currentDate.getFullYear() - 10; i--) {
      initialYears.push({ value: i, label: i })
    }

    this.setState({
      monthOptions: initialMonths,
      yearOptions: initialYears,
    })
  }

  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }

  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  changeTab = (key) => {
    this.setState({ tabkey: key, checkload: true })
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" })
  }

  getlistinventory = (type, limit) => {

    axios
      .post(`/viewHisInventory?ingredent_type=${type}&limit=${limit}`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            listinventory: res.data.data.sort(function (a, b) {
              return b.created_at - a.created_at;
            }), checkload: false
          });

          this.handleIngredentSearch(res.data.data.sort(function (a, b) {
            return b.created_at - a.created_at;
          }), this.state.ingredentTextSearch, this.state.selectedMonth, this.state.selectedYear)
        }
      });
  };

  formatNumber(num) {
    if (num != null && num != '')
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  getDate = (data) => {
    const date = new Date(data * 1000);
    // date.setHours(0, 0, 0, 0);
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
    // ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getHours()}
    // let date = new Date(data * 1000);
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  dataTable() {

    const columns = [
      {
        name: "Nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
        style: {
          div: {
            // background:'red',

            // overflow: 'inherit !important ',
            // width: '750px'
          }
        }
      },
      {
        name: "Loại",
        selector: (row) => row.type,
        sortable: true,
        style: {
          div: {
            overflow: 'inherit !important ',
            maxWidth: '70px'
          }
        }
      },

      {
        name: "Lí do",
        selector: (row) => row.inventoryType_name,
        sortable: true,
        // center: true,
      },
      {
        name: "ĐVT",
        selector: (row) => row.unit_name,
        style: {
          div: {

            // overflow: 'inherit !important ',
            // minWidth: '200px !important'
          }
        }
        // sortable: true,
        // right: true,
      },
      {
        name: "Số lượng",
        selector: (row) => row.amount,
        sortable: true,
        // right: true,
      },
      {
        name: "Giá tiền",
        selector: (row) => row.ingredent_price,
        // center: true,
      }, {
        name: "Ngày cập nhật",
        selector: (row) => row.created_at,
        // center: true,
        style: {
          div: {

            // overflow: 'inherit !important ',
            // width: '190px'
          }
        }
      }, {
        name: "Nhập bởi",
        selector: (row) => row.user_name,
        // center: true,
        style: {
          div: {
            // background:'red',

            // overflow: 'inherit !important ',
            // width: '200px'
          }
        }

      }
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item, i) => {

        data.push({
          id: item.id,
          ingredent_name:
            // item.ingredent_name,
            <div style={{ width: "100%" }} className="text-wrap">{item.ingredent_name}</div>,
          type: item.is_type == 1 ? "Nhập" : "Xuất",

          inventoryType_name: item.inventoryType_name,
          unit_name: item.unit_name,
          amount: this.formatter.format(item.amount),
          ingredent_price: this.formatNumber(item.ingredent_price),
          created_at: <div style={{ width: "100%" }} className="text-wrap">{this.getDate(item.created_at)}</div>,
          user_name:
            // item.user_name,
            <div style={{ width: "100%" }} className="text-wrap">{item.user_name}</div>,
          // <td>{item.user_name}</td>



        });

        return item;
      });
    }

    // const filteredItems = data.filter((item) => {

    //   if (
    //     (new FormatData().toSlug(item.student_name)).includes(new FormatData().toSlug(this.state.filterText.trim()))

    //   ) {

    //     return item;

    //   }
    //   return false
    // }


    // );





    return (
      <>


        <DataTables data={data} columns={columns} selectedRows={selectedRows}
        // className="striped-rows"

        // selectableRows={this.state.isDelete}
        // onSelectedRowsChange={(item) =>
        //   this.deleteMore(item.selectedRows)

        // }
        />
      </>

    );
  }
  renderTableIngedent = () => {
    return (
      <>

        {this.dataTable()}
        {/* </div> */}

      </>
    )
  }

  RenderStoreList = () => {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Lịch sử kho
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {this.RenderFilterDate()}

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              // settabkey(k);
              this.setState({ tabkey: k, checkload: true })
              this.getlistinventory(k, 100);
              this.setState({ ingredentTextSearch: "" })
            }}
            className="mb-3"
          >
            <Tab key={1} title="Chợ" eventKey={1}>
              {this.state.checkload ? this.loading() : this.renderTableIngedent()}
            </Tab>
            <Tab key={2} title="Kho" eventKey={2}>
              {this.state.checkload ? this.loading() : this.renderTableIngedent()}
            </Tab>
            <Tab key={3} title="Vật tư" eventKey={3}>
              {this.state.checkload ? this.loading() : this.renderTableIngedent()}
            </Tab>
          </Tabs>
        </Card.Body>
      </>
    );
  };

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text, month, year) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text
    })

    if (text !== "") {
      root_ = this.state.listinventory.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim())));
    }

    if (month.value !== 0) {
      root_ = root_.filter((e) => new Date(e.created_at * 1000).getMonth() + 1 === Number(month.value));
    }

    if (year.value !== 0) {
      root_ = root_.filter((e) => new Date(e.created_at * 1000).getFullYear() === Number(year.value));
    }

    this.setState({
      ingredents_search: root_
    })
  }

  RenderFilterDate() {

    return (
      <div className="d-flex mb-3 "
      // style={{float:"right"}}
      >
        <Col style={{ zIndex: 3 }} md={2}>
          <Select
            options={this.state.monthOptions}
            value={this.state.selectedMonth}
            className="me-3"
            onChange={(choice) => {
              this.handleIngredentSearch(this.state.listinventory, this.state.ingredentTextSearch.trim(), choice, this.state.selectedYear)

              this.setState({
                selectedMonth: choice
              })
            }}
            styles={{ zIndex: 9 }}
          />
        </Col>
        <Col md={2} style={{ zIndex: 3 }}>
          <Select
            options={this.state.yearOptions}
            value={this.state.selectedYear}
            className="me-3"
            onChange={(choice) => {
              this.handleIngredentSearch(this.state.listinventory, this.state.ingredentTextSearch.trim(), this.state.selectedMonth, choice)

              this.setState({
                selectedYear: choice
              })
            }}
          />
        </Col>


        <Col md={4}>
          <Form.Control style={{ height: 39 }}
            type="text"
            placeholder="Tìm nguyên liệu"
            value={this.state.ingredentTextSearch}
            onChange={
              (e) => {
                this.handleIngredentSearch(this.state.listinventory, e.target.value, this.state.selectedMonth, this.state.selectedYear)
              }
            }
          />
        </Col>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Lịch sử</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            {this.RenderStoreList()}
          </Card>
        </Container>
      </div>
    );
  }
}

export default Storeinventory;
