import axios from "axios";
import React, { useState, useEffect } from "react";
import Footer from "../../../layouts/frontend/Footer";
import Navbar from "../../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";
// import 'react-toastify/dist/ReactToastify.css';
// import Loader from "../../../layouts/frontend/Loader";

function Login() {
  useEffect(() => {
    document.title = "Đăng nhập";
    checkLogin();
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [loginInput, setLogin] = useState({
    type: 2,
    account: localStorage.getItem("auth_account") || "",
    numberphone: localStorage.getItem("auth_phone") || "",
    password: "",
    error_list: [],
  });

  const checkLogin = () => {
    if (localStorage.getItem("access_token")) {
      window.location.href = "/admin";
    }
  };

  const setType = (num) => {
    setLogin({ ...loginInput, type: num });
  };

  const handleInput = (e) => {
    e.persist();
    let field = e.target.name;
    let value = e.target.value;

    setLogin({ ...loginInput, [field]: value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    let path =
      loginInput.type === 2
        ? `/login?type=2&user_phone=${loginInput.numberphone}&user_account=${
            loginInput.account
          }&user_password=${loginInput.password}&date=${Date.now() / 1000}`
        : `/login?type=1&user_phone=${loginInput.numberphone}&user_password=${
            loginInput.password
          }&date=${Date.now() / 1000}`;

    axios
      .post(path)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("auth_id_shool", res.data.data.id_school);
          localStorage.setItem("auth_id_user", res.data.data.id);
          // localStorage.setItem("auth_name", res.data.data.user_name);
          localStorage.setItem("role_rank", res.data.data.role.role_rank);
          localStorage.setItem("role_id", res.data.data.role.role_id);
          localStorage.setItem("role_name", res.data.data.role.role_name);
          localStorage.setItem("permission", res.data.data.role.permission);
          localStorage.setItem("token_type", res.data.data.token_type);
          localStorage.setItem("access_token", res.data.data.access_token);
          localStorage.setItem("auth_phone", loginInput.numberphone);
          localStorage.setItem("auth_account", loginInput.account);
          localStorage.setItem("vip", res.data.data.vip);
          localStorage.setItem("version", res.data.data.version);
          localStorage.setItem("id_login_kafood", res.data.data.id);

          toast(res.data.data.mess, { type: "success", autoClose: 1000 });

          window.location.href =
            res.data.data?.version === "2.0" ? "/admin-v2" : "/admin";
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar />
      <div></div>
      <div id="content" style={{ flexGrow: "1" }}>
        <main>
          <Container className="py-5" id="login">
            <Row className="justify-content-center">
              <Col md={6}>
                <Card className="text-center">
                  <Form onSubmit={loginSubmit}>
                    <Card.Header>
                      <Nav
                        variant="tabs"
                        defaultActiveKey={"user" + loginInput.type}
                      >
                        <Nav.Item className="w-50">
                          <Nav.Link
                            style={{ fontWeight: "bold" }}
                            eventKey={"user2"}
                            onClick={() => setType(2)}
                          >
                            Nhân viên
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="w-50">
                          <Nav.Link
                            style={{ fontWeight: "bold" }}
                            eventKey={"user1"}
                            onClick={() => setType(1)}
                          >
                            Hiệu trưởng
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>

                    <Card.Body>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          onChange={handleInput}
                          defaultValue={loginInput.numberphone}
                          name="numberphone"
                          type="text"
                          placeholder="Số điện thoại"
                          required
                        />
                        <Form.Label>
                          <i className="fa-solid fa-phone" /> Số điện thoại
                        </Form.Label>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          onChange={handleInput}
                          defaultValue={loginInput.account}
                          name="account"
                          type={loginInput.type === 1 ? "hidden" : "text"}
                          required={loginInput.type === 1 ? false : true}
                          placeholder="Tài khoản"
                        />
                        <Form.Label>
                          <i className="fa-solid fa-user" /> Tài khoản
                        </Form.Label>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <InputGroup className="has-validation w-100 is-invalid">
                          <Form.Floating style={{ width: "calc(100% - 46px)" }}>
                            <Form.Control
                              onChange={handleInput}
                              defaultValue={loginInput.password}
                              type={passwordShown ? "text" : "password"}
                              name="password"
                              className="border-end-0 rounded-end-0"
                              placeholder="Mật khẩu"
                              required
                            />
                            <Form.Label>
                              <i className="fa-solid fa-key" /> Mật khẩu
                            </Form.Label>
                          </Form.Floating>

                          <InputGroup.Text
                            onClick={togglePassword}
                            style={{ width: "46px" }}
                          >
                            <i
                              className={
                                passwordShown
                                  ? "fa-regular fa-eye-slash"
                                  : "fa-regular fa-eye"
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Floating>
                    </Card.Body>

                    <Card.Footer>
                      <Col sm={6} className="d-grid gap-2 mx-auto">
                        <Button size="md" type="submit" variant="success">
                          Đăng nhập{" "}
                          <i className="fa-solid fa-right-to-bracket" />
                        </Button>
                      </Col>
                    </Card.Footer>
                  </Form>
                </Card>
              </Col>
            </Row>
            <br />
            <div className="text-center" style={{ fontSize: "15px" }}>
              {" "}
              Chưa có tài khoản?
              <a href="tel:+84889881010"> Liên hệ hotline: 0889881010</a>
              {/* <p>Kafood một sản phẩm của Katec</p> */}
            </div>
          </Container>
        </main>
      </div>

      <Footer />
    </div>
  );
}

export default Login;
