import React, { Component } from "react";
import {Table } from "react-bootstrap";

class NutritionTable extends Component {
    toSlug = (str) => {
        str = str.toLowerCase(); // Chuyển hết sang chữ thường

        // xóa dấu
        str = str
            .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
            .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

        str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

        str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

        return str;
    }

    render(){  
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });

        let energyStat = 0;
        let proteinStat = 0;
        let lipidStat = 0;
        let glucidStat = 0;

        let animalProtein = 0;
        let animalLipid = 0;

        let recommendFrom = 0;
        let recommendTo = 0;

        this.props.quantitative?.time?.filter(timeItem => timeItem.id_time === this.props.timeSelect.value)[0]?.detail?.filter(item => item.ingrendent !== undefined).map(detailItem => {
            detailItem.ingrendent.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                if(selectedGroup){

                    let pureMassOfOneChild = 0; // KL ăn được 1 trẻ

                    // Tính KL ăn được 1 trẻ
                    if(ingredientItem.unit.is_root === 0){
                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                    } else {
                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass);
                    }

                    recommendFrom = Number(selectedGroup.recommended_from);
                    recommendTo = Number(selectedGroup.recommended_to);

                    // Tính protein và lipid động vật
                    if(ingredientItem.type_subspecies === 1){
                        animalProtein += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                        animalLipid += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                    }

                    // Tính năng lượng, protein, lipid và glucid
                    energyStat += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                    proteinStat += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                    lipidStat += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                    glucidStat += pureMassOfOneChild * Number(ingredientItem.nutrients.ingredent_glucid) / 100;
                }
                
                return ingredientItem;
            })
            return detailItem;
        })

        const plgTotal = (proteinStat * 4 + lipidStat * 9 + glucidStat * 4);

        let energyPersen = 0;

        let recommendFromTime = 0;
        let recommendToTime = 0;

        if(recommendFrom !== 0 ){
            if(this.toSlug(this.props.timeSelect.label).includes(this.toSlug("Ngày"))){
                energyPersen = energyStat * 100 / (recommendFrom * 60/100);
                recommendFromTime = (recommendFrom * 60/100);
                recommendToTime = (recommendTo * 60/100);
                
            } 
            else if(this.toSlug(this.props.timeSelect.label).includes(this.toSlug("Trưa"))){
                energyPersen = energyStat * 100 / (recommendFrom * 30/100);
                recommendFromTime = (recommendFrom * 30/100);
                recommendToTime = (recommendTo * 30/100);
                
            } else if(this.toSlug(this.props.timeSelect.label).includes(this.toSlug("Chiều"))){
                energyPersen = energyStat * 100 / (recommendFrom * 25/100);
                recommendFromTime = (recommendFrom * 25/100);
                recommendToTime = (recommendTo * 25/100);

            } else {
                energyPersen = energyStat * 100 / (recommendFrom * 10/100);
                recommendFromTime = (recommendFrom * 10/100);
                recommendToTime = (recommendTo * 10/100);
            }

        }

        return(
            <div>
                <div>
                    <div className="fw-bold d-inline">Năng lượng khuyến nghị:</div> {formatter.format(recommendFromTime)} - {formatter.format(recommendToTime)} (Kcal)
                </div>
                <Table bordered responsive size="sm" className="mt-2">
                <thead>
                    <tr className="align-middle text-center">
                        <th></th>
                        <th>Tỉ lệ đạt(%)</th>
                        <th>Năng lượng(Kcal)</th>
                        <th>Đạm(g)</th>
                        <th>Béo(g)</th>
                        <th>Đường bột(g)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td title="Định lượng">ĐL</td>
                        <td
                            style={
                                energyPersen < 100 
                                ?
                                    {color: "red"} 
                                : 
                                    {color: "green"} 
                            }
                        >
                            { formatter.format(energyPersen) }
                        </td>
                        <td >
                            {formatter.format(energyStat)}
                        </td>
                        <td>{formatter.format(proteinStat)}</td>
                        <td>{formatter.format(lipidStat)}</td>
                        <td>{formatter.format(glucidStat)}</td>
                    </tr>

                    <tr className="text-center">
                        <td>P:L:G</td>
                        <td></td>
                        <td></td>
                        <td style={ plgTotal !== 0 ? formatter.format(proteinStat*100*4/plgTotal) < 14 ? { color: "red" } : { color: "green" } : { color: "red" } }>{ plgTotal !== 0 ? formatter.format(proteinStat*100*4/plgTotal) : ""}</td>
                        <td style={ plgTotal !== 0 ? formatter.format(lipidStat*100*9/plgTotal) < 26 ? { color: "red" } : { color: "green" } : { color: "red" } }>{ plgTotal !== 0 ? formatter.format(lipidStat*100*9/plgTotal) : ""}</td>
                        <td style={ plgTotal !== 0 ? formatter.format(glucidStat*100*4/plgTotal) < 60 ? { color: "red" } : { color: "green" } : { color: "red" } }>{ plgTotal !== 0 ? formatter.format(glucidStat*100*4/plgTotal) : ""}</td>
                    </tr>

                    <tr className="text-center">
                        <td title="Động vật">ĐV</td>
                        <td></td>
                        <td></td>
                        <td 
                            style={ 
                                proteinStat !== 0 
                                ? 
                                    formatter.format(animalProtein*100/proteinStat) > 60 || formatter.format(animalProtein*100/proteinStat) < 40 ? { color: "red" } : { color: "green" } 
                                : 
                                    { color: "red" } 
                            }
                        >
                            {proteinStat !== 0 ? formatter.format(animalProtein*100/proteinStat) : ""}
                        </td>
                        <td
                            style={ 
                                lipidStat !== 0 
                                ? 
                                    formatter.format(animalLipid*100/lipidStat) > 60 || formatter.format(animalLipid*100/lipidStat) < 40 ? { color: "red" } : { color: "green" } 
                                : 
                                    { color: "red" } 
                            }
                        >
                            {lipidStat !== 0 ? formatter.format(animalLipid*100/lipidStat) : ""}
                        </td>
                        <td></td>
                    </tr>

                    <tr className="text-center">
                        <td title="Thực vật">TV</td>
                        <td></td>
                        <td></td>
                        <td
                            style={ 
                                proteinStat !== 0 
                                ? 
                                    formatter.format(animalProtein*100/proteinStat) > 60 || formatter.format(animalProtein*100/proteinStat) < 40 ? { color: "red" } : { color: "green" } 
                                : 
                                    { color: "red" } 
                            }
                        >
                            {proteinStat !== 0 ? formatter.format( 100 - (animalProtein*100/proteinStat)) : ""}
                        </td>
                        <td
                            style={ 
                                lipidStat !== 0 
                                ? 
                                    formatter.format(animalLipid*100/lipidStat) > 60 || formatter.format(animalLipid*100/lipidStat) < 40 ? { color: "red" } : { color: "green" } 
                                : 
                                    { color: "red" } 
                            }
                        >
                            {lipidStat !== 0 ? formatter.format(  100 - (animalLipid*100/lipidStat)) : ""}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            </div>
        )
    }
}

export default NutritionTable;