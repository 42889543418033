import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import swal from "sweetalert";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listStudent: [],
      startDate: new Date(),
      endDate: new Date(),
      show: false,
      start_: new Date(),
      end_: new Date(),
      listClass: [],
      tongAll: 0,
      tongdihoc: 0,
      tongcoan: 0,
      tongkhongan: 0,
      totalnghi: 0,
      totalkhongphep: 0,
      totalcophep: 0,
      count: [],
      checkload: true,
      listCut: [],
    };
  }

  async componentDidMount() {
    document.title = "Thống kê sỉ số";
    this.getClassRoom(new Date(), new Date());
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 0);
    this.setState({
      startDate: start,
      endDate: end,
    });
  }

  getDate_ = (data) => {
    let date = new Date(data);
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };
  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  getClassRoom = (start, end) => {
    let start_ = start.setHours(0, 0, 0) / 1000;
    let end_ = end.setHours(23, 59, 59) / 1000;
    axios
      .get(
        `/classroom-shows?start_date=${parseInt(start_)}&end_date=${parseInt(
          end_
        )}`
      )
      .then((res) => {
        // this.setState({ listClass: res.data.data?.classrooms });
        this.getlistCutAll(
          res.data.data?.classrooms,
          new Date(start_ * 1000),
          new Date(end_ * 1000)
        );

        // }
      });
  };
  getlistCutAll = (dataClass, start, end) => {
    axios
      .get(
        `/report-class?start_date=${parseInt(start / 1000)}&end_date=${parseInt(
          end / 1000
        )}`
      )
      .then((res) => {
        let arr = [];
        for (const obj1 of dataClass)
          arr.push({
            ...obj1,
            countStudent:
              res.data.data[0]?.class?.filter((e) => e.id_class == obj1.id)[0]
                ?.countStudent || 0,
          });
        this.setState({ listClass: arr });
        // this.getListMealstudent(this.state.startDate, this.state.endDate);
        this.getListMealstudent(start, end);
      });
  };
  getListMealstudent(start, end) {
    this.setState({ checkload: true });
    let start_ = start.setHours(0, 0, 0) / 1000;
    let end_ = end.setHours(23, 59, 59) / 1000;
    axios
      .get(
        `day-off-shows?&start_date=${parseInt(start_)}&end_date=${parseInt(
          end_
        )}
       `
      )
      .then((res) => {
        this.setState({ listCut: res.data.data });
        const dateFilter = res.data.data?.filter(
          (absentDay) => absentDay.is_status == 1
        );
        const nghinguyenngay =
          dateFilter.length > 0
            ? dateFilter.filter((e) => e.is_day_off == 1)
            : [];
        const khonganlist =
          dateFilter.length > 0
            ? dateFilter.filter((e) => e.is_meal_cut == 1)
            : [];
        let listClass = [];
        let count1 = [];
        var tongDs = 0;
        var tongDihoc = 0;
        var tongCoan = 0;
        var tongKhongan = 0;
        var tongNghi = 0;
        var tongKhongphep = 0;
        var tongCophep = 0;
        this.state.listClass.map((e) => {
          var count_student = e.countStudent;
          var dihoc = 0;
          var coan = 0;
          var khongan = khonganlist?.filter(
            (el) => el.class_name == e.class_name
          ).length;

          var nghi = nghinguyenngay.filter(
            (el) => el.class_name == e.class_name
          ).length;
          var khongphep = nghinguyenngay.filter(
            (el) => el.class_name == e.class_name && el.is_valid == 0
          ).length;
          var cophep = nghinguyenngay.filter(
            (el) => el.class_name == e.class_name && el.is_valid >= 1
          ).length;
          dihoc += e.countStudent;

          dihoc = dihoc - nghi;
          coan = dihoc - khongan;
          tongDs += count_student;
          tongKhongan += khongan;
          tongDihoc += dihoc;
          tongCoan += coan;
          tongNghi += nghi;
          tongKhongphep += khongphep;
          tongCophep += cophep;

          let data = {
            id: e.id,
            class_name: e.class_name,
            user_name: e.teacher_name,
            total: e.countStudent,
            dihoc: dihoc,
            coan: coan,
            khongan: khongan,
            tongnghi: nghi,
            khongphep: khongphep,
            cophep: cophep,
          };

          listClass.push(data);
        });

        this.setState({
          tongAll: tongDs,
          tongkhongan: tongKhongan,
          tongdihoc: tongDihoc,
          tongcoan: tongCoan,
          totalnghi: tongNghi,
          totalkhongphep: tongKhongphep,
          totalcophep: tongCophep,
          listStudent: listClass,
          count: count1,
          checkload: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  changeTime = (update) => {
    this.setState({ show: false });
    this.setState({ start_: new Date(update.setHours(0, 0, 0)) });
    this.setState({ end_: new Date(update.setHours(23, 59, 59)) });
    this.getClassRoom(
      new Date(update.setHours(0, 0, 0)),
      new Date(update.setHours(23, 59, 59))
    );
    this.getListMealstudent(
      new Date(update.setHours(0, 0, 0)),
      new Date(update.setHours(23, 59, 59))
    );
    this.setState({
      startDate: new Date(update.setHours(0, 0, 0)),
      endDate: new Date(update.setHours(23, 59, 59)),
    });
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  renderCutMealListTable() {
    return (
      <>
        {this.state.checkload ? (
          this.loading()
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table bordered style={{ borderColor: "#000" }}>
              <thead>
                <tr>
                  {" "}
                  <th className="text-center" colSpan={10}>
                    {" "}
                    THỐNG KÊ SỈ SỐ ({this.getDate_(this.state.startDate)})
                  </th>
                </tr>
                <tr>
                  <th rowSpan="2" className="align-middle text-center">
                    STT
                  </th>
                  <th className="align-middle text-center" rowSpan="2">
                    Lớp
                  </th>
                  <th className="align-middle text-center" rowSpan="2">
                    Tên GV
                  </th>
                  <th className=" align-middle text-center" rowSpan="2">
                    Tổng DS
                  </th>
                  <th
                    className="align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    {" "}
                    Đi học
                  </th>
                  {/* </tr>
								<tr> */}

                  <th
                    className="align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    {" "}
                    Có ăn
                  </th>
                  <th
                    className=" align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    Không ăn
                  </th>

                  <th
                    className="align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    {" "}
                    Tổng nghỉ
                  </th>
                  <th
                    className=" align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    Không phép
                  </th>
                  <th
                    className="align-middle text-center"
                    rowSpan="2"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    Có phép
                  </th>
                </tr>
              </thead>

              {this.state.listStudent?.length > 0 ? (
                <tbody>
                  <>
                    {this.state.listStudent.map((student, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{student.class_name}</td>
                          <td>{student.user_name}</td>
                          <td>{student.total}</td>
                          <td>{student.dihoc}</td>
                          <td>{student.coan}</td>
                          <td>{student.khongan}</td>

                          <td>{student.tongnghi}</td>
                          <td>{student.khongphep}</td>
                          <td>{student.cophep}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <th colSpan="3">Tổng</th>
                      <th>{this.state.tongAll}</th>
                      <th>{this.state.tongdihoc}</th>
                      <th>{this.state.tongcoan}</th>
                      <th>{this.state.tongkhongan}</th>

                      <th>{this.state.totalnghi}</th>
                      <th>{this.state.totalkhongphep}</th>
                      <th>{this.state.totalcophep}</th>
                    </tr>
                  </>
                </tbody>
              ) : (
                <>
                  <tr>
                    <td colSpan={10} className="text-center">
                      Không có dữ liệu...
                    </td>
                  </tr>
                </>
              )}
            </Table>

            <Table
              bordered
              id="CutUserTable"
              style={{ borderColor: "#000" }}
              className="d-none"
            >
              <thead>
                <tr>
                  <th className="text-center">
                    {" "}
                    THỐNG KÊ SỈ SỐ ({this.getDate_(this.state.startDate)})
                  </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>

                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th className="align-middle text-center">STT</th>
                  <th className="align-middle text-center">Lớp</th>
                  <th className="align-middle text-center">Tên GV</th>
                  <th className=" align-middle text-center">Tổng DS</th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    {" "}
                    Đi học
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    {" "}
                    Có ăn
                  </th>
                  <th
                    className=" align-middle text-center"
                    style={{ backgroundColor: "#c3d6da" }}
                  >
                    Không ăn
                  </th>

                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    {" "}
                    Tổng nghỉ
                  </th>
                  <th
                    className=" align-middle text-center"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    Không phép
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#d9ffd9" }}
                  >
                    Có phép
                  </th>
                </tr>
              </thead>

              <tbody>
                {this.state.listStudent.map((student, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{student.class_name}</td>
                      <td>{student.user_name}</td>
                      <td>{student.total}</td>
                      <td>{student.dihoc}</td>
                      <td>{student.coan}</td>
                      <td>{student.khongan}</td>

                      <td>{student.tongnghi}</td>
                      <td>{student.khongphep}</td>
                      <td>{student.cophep}</td>
                    </tr>
                  );
                })}
                <tr>
                  <th>Tổng</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>{this.state.tongAll}</th>
                  <th>{this.state.tongdihoc}</th>
                  <th>{this.state.tongcoan}</th>
                  <th>{this.state.tongkhongan}</th>

                  <th>{this.state.totalnghi}</th>
                  <th>{this.state.totalkhongphep}</th>
                  <th>{this.state.totalcophep}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </>
    );
  }

  xport = () => {
    const table = document.getElementById("CutUserTable");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
    ];
    for (let i = 3; i < this.state.listStudent?.length + 4; i++) {
      colAlpha.slice(0, 10).map((alpha) => {
        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal:
              alpha != "A" || alpha != "B" || alpha != "C" ? "center" : "left",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }
    colAlpha.slice(0, 10).map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 16,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, 10).map((alpha, index) => {
      if (index > 6 && index < 6 + 1) {
        ws[`${alpha}2`].s = {
          fill: {
            patternType: "solid",
            fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "E6E6FA" },
            bgColor: { indexed: 64 },
          },
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      } else if (index > 6) {
        ws[`${alpha}2`].s = {
          fill: {
            patternType: "solid",
            fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "d9ffd9" },
            bgColor: { indexed: 64 },
          },
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      }

      ws[`${alpha}2`].s = {
        fill:
          alpha === "E" || alpha === "F" || alpha === "G"
            ? {
                patternType: "solid",
                fgColor: {
                  theme: 8,
                  tint: 0.3999755851924192,
                  rgb: "c3d6da",
                },
                bgColor: { indexed: 64 },
              }
            : {
                patternType: "solid",
                fgColor: {
                  theme: 8,
                  tint: 0.3999755851924192,
                  rgb: "ffffff",
                },
                bgColor: { indexed: 64 },
              },
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    ws["!cols"] = [
      { wch: 4 },
      { wch: 15 },
      { wch: 25 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
    ];
    ws["!rows"] = [{ hpt: 30 }];

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } },

      {
        s: { r: this.state.listStudent?.length + 2, c: 0 },
        e: { r: this.state.listStudent?.length + 2, c: +2 },
      },
    ];
    ws["!merges"] = merge;

    XLSX.writeFile(
      wb,
      `Thống kê sỉ số ${this.getDate_(this.state.startDate)} .xlsx`
    );
  };
  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách thống kê sỉ số ${this.getDate_(
        this.state.startDate
      )}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Thống kê sỉ số V2</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i>Thống kê sỉ số{" "}
                {this.state.startDate
                  ? this.getDate_(this.state.startDate)
                  : ""}{" "}
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <i className="fa-solid fa-calendar-days"> </i>{" "}
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.handleExport()}
                  disabled={this.state.listStudent?.length == 0}
                >
                  Xuất danh sách thống kê sỉ số{" "}
                  <i className="fa-solid fa-file-export"></i>
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>{this.renderCutMealListTable()}</Card.Body>
          </Card>
        </Container>
        <Modal
          show={this.state.show}
          size={"sm"}
          onHide={() => this.setState({ show: false })}
          keyboard={false}
          backdrop="static"
        >
          <Modal.Header closeButton={this.state.endDate != null}>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.changeTime(date)}
                inline
              />
            </center>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Report;
