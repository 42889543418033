import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Nav,
  Row, Form, InputGroup
} from "react-bootstrap";
import CategoryAdd from "./CategoryAdd";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import CategoryEdit from "./CategoryEdit";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Check from "../other/Check";
import FoodIndex from "../food/FoodIndex";

class CategoryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorys: [],
      category_child: [],
      category_id: "",
      category: [],
      foods: [],
      id_T: "",
      addCategoryModalShow: false,
      editFoodModalShow: false,
      editCategoryModalShow: false,
      checkload: false, foodTextSearch: "", navkey: 0,
    };
  }

  async componentDidMount() {
    document.title = "Danh mục món";
    this.getCategorysData();
    this.getFoodsData();
    this.dataCategogys();
    // this.getChildCategoryData(this.state.categorys[0]);
  }
  //Category
  getCategorysData1 = () => {
    axios.post(`/viewCategory`).then((res) => {
      if (res.data.status === true) {

        this.setState({ categorys: res.data.data });
        if (res.data.data.length > 0) {
          // this.setState({checkload:true})
          this.setState({ category: res.data.data[0] });
          this.getChildCategoryData(res.data.data[res.data.data.length].id);
          this.setState({ navkey: res.data.data[res.data.data.length].id })
        }
      }
    });
  };
  getCategorysData = () => {
    var rs = []
    rs.push({
      id: -1,
      category_name: "Tất cả",
      notDel: 0,
      is_main: 0

    })
    axios.post(`/viewCategory`).then((res) => {
      if (res.data.status === true) {
        res.data.data.map(e => rs.push(e))
        rs.push({
          id: 0,
          category_name: "Chưa có danh mục",
          notDel: 0
        })
        // res.data.data.push({
        //   id: -1,
        //   category_name: "Tất cả",
        //   notDel: 0,
        //   is_main:0

        // })

        this.setState({ categorys: rs });
        if (res.data.data.length > 0) {
          this.setState({ checkload: true })
          this.setState({ category: rs[0] });
          this.setState({ id_category: rs[0].id })
          this.getChildCategoryData(res.data.data[0].id);
          this.setState({ navkey: res.data.data[0].id })
        }
      }
    });
  };
  getChildCategoryData = (idCategory) => {
    axios
      .post(`/childCategory?id_category=${idCategory}`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ checkload: false })
          this.setState({ category_child: res.data.data });
        }
      })
      .catch((err) => console.log("err", err));
  };

  hanldDeleteCategory = () => {
    let id_category = this.state.category.id;
    swal({
      title: "Bạn muốn xóa danh mục?",
      text: `"${this.state.category.category_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios.post(`/deleteCategory?id_category=${id_category}`).then((res) => {
          if (res.data.status === true) {
            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
            this.getCategorysData();
            this.setState({ navkey: 0 })
          } else {
            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
          }
        });
      }
    });
  };

  dataCategogys() {
    if (this.state.categorys) {
      let categorys = this.state.categorys;
      if (categorys.length > 0) {
        return categorys.map((item, i) => {
          return (
            <Nav.Item
              key={"category" + item.id}
            >
              <Nav.Link
                eventKey={
                  "categoryLink" + i
                  // item.id
                  // this.state.navkey
                }
                // eventKey={"categoryLink" + i}
                onClick={() => {
                  return (
                    this.setState({ checkload: true }),
                    this.setState({ category_id: item.id }),
                    this.setState({ category: item }),
                    this.getChildCategoryData(item.id),
                    this.setState({ navkey: i })
                  );
                }}
              >
                {item.category_name}
              </Nav.Link>
            </Nav.Item>
          );
        });
      }
    }
  }
  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }
  renderCategorys() {
    const addModalClose = () => {
      this.setState({ addCategoryModalShow: false });
      this.getCategorysData1();
    };
    const editModalClose = () => {
      this.setState({ editCategoryModalShow: false });
    };
    return (
      <>
        <Card>
          <Card.Header>
            <Card.Title>
              <i className="fas fa-list me-1"></i> Danh mục món
              <>
                {new Check().permission(['36'], 'or') ?
                  <Button variant="success" type="button" size="sm" style={{ marginLeft: "5px", float: "right", marginRight: "5px" }} onClick={() => this.setState({ addCategoryModalShow: true })} title="Thêm danh mục món" >
                    <i className="fa-solid fa-plus"></i> Thêm danh mục
                  </Button>
                  : null
                }
                {new Check().permission(['37'], 'or') ?
                  <Button type="button" variant="warning" size="sm" style={{ marginLeft: "5px", float: "right" }} onClick={() => this.setState({ editCategoryModalShow: true })}
                    hidden={this.state.category?.notDel == 0}
                    disabled={this.state.categorys.length > 0 ? false : true}
                  >
                    <i className="fa-solid fa-edit"></i> Sửa danh mục
                  </Button>
                  : null
                }
                {new Check().permission(['38'], 'or') ?
                  <Button type="button" variant="danger me-2" size="sm" style={{ marginLeft: "5px", float: "right" }} onClick={this.hanldDeleteCategory}
                    hidden={this.state.category.is_main == 0 || this.state.category?.notDel == 0}
                    disabled={this.state.categorys.length > 0 ? false : true}
                  >
                    <i className="fa-solid fa-trash"></i> Xóa danh mục
                  </Button>
                  : null
                }
                <CategoryAdd show={this.state.addCategoryModalShow} onHide={addModalClose} />
                <CategoryEdit show={this.state.editCategoryModalShow} onHide={editModalClose} obj={this.state.category} />
              </>
            </Card.Title>
          </Card.Header>
          {/* <Col className="mb-2 mb-md-0 mt-2" md={6} style={{ zIndex: 2 }}> */}

          {/* <Row> */}
          <Card.Body className="pb-0">
            <Col md={4}>
              <InputGroup style={{ height: '30px' }}>
                <Form.Control
                  style={{ marginRight: '10px', height: '30px', paddingTop: "1px" }}
                  type="text"
                  placeholder="Tìm món ăn"
                  value={this.state.foodTextSearch}
                  onChange={(event) =>
                    this.setState({ foodTextSearch: event.target.value })
                    //  this.handleSearchFood(event)
                  }
                />
                {/* <InputGroup.Text>
                            <i className="fa-solid fa-search me-1" />
                        </InputGroup.Text> */}
              </InputGroup>
            </Col>

            <Row>
              <Col md={10}>
                <Nav variant="tabs" defaultActiveKey={"categoryLink0"} className="flex-contaniner" style={{
                  marginTop: "10px",
                  display: 'flex', flexWrap: 'nowrap', maxWidth: '1200px',
                  overflow: 'auto'
                }}>
                  {this.dataCategogys()}
                  {/* {this.getChildCategoryData()} */}
                  <Nav.Item className="position-absolute end-0 " style={{ whiteSpace: 'nowrap' }}>
                    {/* <Nav.Item className="flex-contaniner"> */}
                    <CategoryAdd show={this.state.addCategoryModalShow} onHide={addModalClose} />
                    <CategoryEdit show={this.state.editCategoryModalShow} onHide={editModalClose} obj={this.state.category} />
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Card.Body>

          <FoodIndex
            id_category={this.state.category.id}
            foodTextSearch={this.state.foodTextSearch}
          />

          {/* <center>
            {this.state.checkload? this.loading():   <Row style={{
              alignItems: "center", display: "flex", padding: "15px"
            }}  >
              {(this.state.category_child.length > 0 ?
                this.state.category_child.map((item, index) =>
                  <Col style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    webkitLineClamp: "1",
                    display: "-webkit-box",
                    webkitBoxOrient: "vertical",
                    height: "75px",
                    lineHeight: "75px",
                    width: "210px",
                    borderRadius: "10px",
                    border: "1px solid rgb(222, 226, 230)", margin: "5px"
                  }}
                    title={item.food_name}
                    xs={2} sm={2}>{item.food_name}</Col>
                )
                : <p className="text-center">Không có dữ liệu</p>)}
            </Row>}
         </center> */}
        </Card>
      </>
    );
  }
  //end Category

  // food
  getFoodsData = () => {
    if (this.state.category_id !== "") {
      axios
        .post(`/childCategory?id_category=${this.state.category_id}`)
        .then((res) => {
          if (res.data.status === true) {
            this.setState({ foods: res.data.data });
          }
        });
    }
  };

  dataFoods() {
    const columns = [
      {
        name: "Tên món ăn",
        selector: (row) => row.title,
        sortable: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };
    // this.getCategorysData();
    if (this.state.category_child.length > 0) {
      this.state.category_child.map((item, i) => {
        data.push({
          id: item.id,
          title: item.food_name,
          setting: (
            <>
              <Button
                size="sm"
                variant="primary me-2"
                type="button"
                onClick={() => this.handleEditFoodModalShow(item.id)}
                title="Chi tiết món ăn"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>

              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.handleDelete(item.id)}
                title="Xóa món ăn"
              >
                <i className="fas fa-trash-can"></i>
              </Button>
            </>
          ),
        });

        return item;
      });
    } else return null;

    return localStorage.getItem("dm") > 0 ? (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    ) : null;
  }

  renderFoods() {
    const editModalClose = () => {
      this.setState({ editCategoryModalShow: false });
    };
    const editModalOpen = () => {
      this.setState({ editCategoryModalShow: true });
    };
    let dm = this.state.categorys.length;
    localStorage.setItem("dm", dm);

    return (
      <>
        <Card.Body>
          <Row>
            <Col md={12}>
              <CategoryEdit
                show={editModalOpen}
                onHide={editModalClose}
                obj={this.state.category}
              // obj={this.state.editCategoryModalShow}
              />
            </Col>
          </Row>
        </Card.Body>

        <Card.Body>
          {
            // this.state.categorys.length<0 ?

            this.dataFoods()
            // : null
          }
        </Card.Body>
      </>
    );
  }
  // end food

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Món ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh mục món</Breadcrumb.Item>
          </Breadcrumb>

          {this.renderCategorys()}
        </Container>
      </>
    );
  }
}

export default CategoryIndex;
