import React, { Component } from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

class MarketBill extends Component {
    ingredientPortionArray = () => {
        let detailArray = [];

        this.props.quantitative.time?.map(timeItem => {
            detailArray = detailArray.concat(timeItem.detail);
            return timeItem;
        })

        return detailArray;
    }

    group = (arr, key) => {
        return [...arr.reduce( (acc, o) => 
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
        , new Map()).values()];
    }

    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
    
    render(){  
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });

        const bigDetailArray = this.ingredientPortionArray();

        if(bigDetailArray.length === 0){
            return "";
        }

        let ingreBigArray = [];
        
        const timeObj = this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect?.value)[0];

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        let totalMoney = 0; // Tổng tiền chi
        let totalMarketMoney = 0; // Tổng tiền chi đi chợ
        let totalStoreMoney = 0; // Tổng tiền chi nhập kho

        let ingredientIndex = 0;

        let totalChild = this.props.totalChild;

        if(bigDetailArray.length !== 0){
            this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect.value)[0].detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
                detailItem.ingrendent.map(ingredientItem => {
                    const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                    if(selectedGroup && Number(ingredientItem.nutrients.redemption_value) !== 0){                        
                        // Tính tổng tiền chi
                        if(ingredientItem.unit.is_root === 0){
                            totalMoney += this.props.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                        } else {
                            totalMoney += this.props.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price)
                        }
                    }                    
                    return ingredientItem;
                })
                return detailItem;
            })

            totalMoney = Math.round(totalMoney);
        }

        const timePoetry = Number(this.props.quantitative?.time?.find(item => Number(item.id_time) === this.props.timeSelect.value).poetry)

        return(
            <Table 
                bordered 
                responsive
                id="market-bill-table"
            >
                <thead>
                    <tr>
                        <td colSpan={3}></td>
                        <td colSpan={2} className="text-end align-top">
                            {this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day_name}, ngày {this.stringLastDate(this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day || new Date())}
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={5} className="text-center align-middle">
                            HÓA ĐƠN ĐI CHỢ
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            Bữa ăn: Ăn {this.props.timeSelect.label}
                            <br />
                            Độ tuổi: {this.props.groupSelect.label}
                            <br />
                            Tổng số {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"}: {totalChild}
                        </td>
                        <td colSpan={2}>
                            Ăn {this.props.timeSelect.label}: 
                            <br />
                            {
                                this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect.value)[0].detail?.map(detailItem => {
                                    if(detailItem.ingrendent.some(ingredient => ingredient.group.some(group => Number(group.id) === Number(this.props.groupSelect.value)))){
                                        return(
                                            <div key={detailItem.id_food}>
                                                - {detailItem.food_name}
                                                <br />
                                            </div>
                                        )
                                    }
                                    return "";
                                })
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            Số Tiền Trên Mỗi Trẻ: &nbsp;
                            <CurrencyFormat 
                                suffix="(đ)" 
                                value={totalChild !== 0 ? Math.round((Number(this.props.ortherFee) * totalChild + totalMoney)/totalChild) : 0}
                                displayType={'text'} 
                                thousandSeparator={true} 
                            />
                        
                        </td>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>STT</th>
                        <th>Tên thực phẩm</th>
                        <th>Khối lượng(g)</th>
                        <th>Đơn giá(đ/100g)</th>
                        <th>Thành tiền(đ)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>empty</th>
                        <th>*CHỢ</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                    {
                        aIngredientArray.filter(ingredientArray => ingredientArray[0].ingredent_type === 1).map((arrayItem, index) => {
                            let realMassOfOneChild = 0; // KL đi chợ 1 trẻ
                            let exchangeValue = 0;

                            let realMass = 0; // Tổng KL đi chợ

                            let hunGramPrice = 0; // Đơn giá
                            let ingredientPrice = 0; // Thành tiền

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];
                                
                                // Tính KL đi chợ  1 trẻ
                                if(selectedGroup){
                                    if(ingredientItem.unit.is_root === 0){
                                        realMassOfOneChild += Number(selectedGroup.amount);
                                    } else {
                                        realMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                    }
                                    
                                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                }

                                return ingredientItem;
                            })

                            if(realMassOfOneChild === 0){
                                return "";  
                            }

                            arrayItem.map(ingredientItem =>{
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                                if(selectedGroup){
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneChild * this.props.totalChild;
                                }

                                return ingredientItem;
                            })
                            
                            // Tính thành tiền
                            if(exchangeValue !== 0){
                                ingredientPrice = Math.round( (realMass / exchangeValue) * Number(arrayItem[0].nutrients.price));
                            }

                            // Tính đơn giá / 100g
                            if(realMass !== 0){
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMass);
                            }

                            totalMarketMoney += Math.round(ingredientPrice);

                            ingredientIndex++;

                            return(
                                <tr key={index} className="align-middle text-end">
                                    <td>{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass) || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ hunGramPrice || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ ingredientPrice|| "empty" } displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <th className="text-center">Cộng</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-end">
                            <CurrencyFormat value={totalMarketMoney|| "empty"} displayType={'text'} thousandSeparator={true} />
                        </th>
                    </tr>

                    <tr>
                        <th>empty</th>
                        <th>*XUẤT KHO</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                    {
                        aIngredientArray.filter(ingredientArray => ingredientArray[0].ingredent_type === 2).map((arrayItem, index) => {
                            let realMassOfOneChild = 0; // KL đi chợ 1 trẻ
                            let exchangeValue = 0;

                            let realMass = 0; // Tổng KL đi chợ

                            let hunGramPrice = 0; // Đơn giá
                            let ingredientPrice = 0; // Thành tiền

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];
                                
                                // Tính KL đi chợ 1 trẻ
                                if(selectedGroup){
                                    if(ingredientItem.unit.is_root === 0){
                                        realMassOfOneChild += Number(selectedGroup.amount);
                                    } else {
                                        realMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                    }
                                    
                                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                }

                                return ingredientItem;
                            })

                            if(realMassOfOneChild === 0){
                                return "";  
                            }

                            arrayItem.map(ingredientItem =>{
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                                if(selectedGroup){                         
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneChild * this.props.totalChild;
                                }

                                return ingredientItem;
                            })
                            
                            // Tính thành tiền
                            if(exchangeValue !== 0){
                                ingredientPrice = Math.round( (realMass / exchangeValue) * Number(arrayItem[0].nutrients.price));
                            }

                            // Tính đơn giá / 100g
                            if(realMass !== 0){
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMass);
                            }

                            totalStoreMoney += Math.round(ingredientPrice);

                            ingredientIndex++;

                            return(
                                <tr key={index} className="align-middle text-end">
                                    <td>{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass)|| "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ hunGramPrice || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ ingredientPrice || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <th className="text-center">Cộng</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-end">
                            <CurrencyFormat value={totalStoreMoney|| "empty"} displayType={'text'} thousandSeparator={true} />
                        </th>
                    </tr>

                    <tr>
                        <th>Tổng tiền thực phẩm</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat value={totalMoney|| "empty"} displayType={'text'} thousandSeparator={true} suffix="đ" />
                        </th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <th>Chi phí khác</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat value={this.props.ortherFee * totalChild} displayType={'text'} thousandSeparator={true} suffix="(đ)" />
                        </th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <th>Tiền được chi trong buổi</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat value={totalMoney+ Number(this.props.ortherFee) * totalChild} displayType={'text'} thousandSeparator={true} suffix="(đ)" />
                        </th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <th>Số dư đầu kì</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ this.props.moneySoonDay } displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <th>Số dư cuối kì</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ this.props.moneySoonDay - (Number(this.props.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild)} displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                    </tr>
                    {/* <tr>
                        <th colSpan={3}>Suất ăn lũy kế từ đầu tháng</th>
                        <th className="text-end" colSpan={2}></th>
                    </tr>
                    <tr>
                        <th colSpan={3}>Tiêu chuẩn lũy kế từ đầu tháng</th>
                        <th className="text-end" colSpan={2}></th>
                    </tr>
                    <tr>
                        <th colSpan={3}>Tiền chi lũy kế từ đầu tháng</th>
                        <th className="text-end" colSpan={2}></th>
                    </tr> */}
                </tbody>
            </Table>
        )
    }
}

export default MarketBill;