import React, { Component } from "react";
import axios from "axios";
import { Button, Container, Breadcrumb, Card, Row, Col, Form, Table } from "react-bootstrap";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";

// Components
import NutritionTable from "./NutritionTable";
import MarketBill from "./MarketBill";
import NutritionResult from "./NutritionResult";
import NutritionSetupResult from "./NutritionSetupResult";

const initialDay = [
    { id: 1, day_name: "Thứ 2" },
    { id: 2, day_name: "Thứ 3" },
    { id: 3, day_name: "Thứ 4" },
    { id: 4, day_name: "Thứ 5" },
    { id: 5, day_name: "Thứ 6" },
    { id: 6, day_name: "Thứ 7" },
    { id: 7, day_name: "Chủ nhật" }
]

class PortionCalculate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantitative: {},
            tmpQuantitative: {},
            isChangeQuantitative: false,
            isChangeIngredient: false,
            isChangePrice: false,
            wasChangedPrice: false,

            isLoading: false,

            listDay: [],
            daySelect: 0,

            listTimeSetting: [],
            timeOption: [],
            timeSelect: { value: 0, label: "" },

            listUnit: [],

            groupOption: [],
            groupSelect: { value: 0, label: "" },

            ortherFee: 0,
            moneySoonDay: 0,
            totalChild: 0,

            start: new Date(),
            term: new Date(),

            weekInMonth: ""
        };
    }

    async componentDidMount() {
        document.title = 'Tính khẩu phần ăn';

        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        if (todayIs === 0) {
            todayIs = 7;
        }

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: initialDay.map((day, index) => {
                return {
                    id: day.id,
                    day_name: day.day_name,
                    day: initialWeek[index]
                }
            })
        });

        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length - 1], todayIs, { value: 0, label: "" }, { value: 0, label: "" });
        this.getStudentListsetting();
        this.getlistunit();
    }

    //#region Fetch API
    getQuantitativeVoucher = (startDay, endDay, daysId, timeSelect, groupSelect) => {
        this.setState({ isLoading: true });

        axios.post(`/voucher?is_real=0&type=2&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}&id_day=${daysId}`)
            .then(res => {
                this.setState({ isLoading: false });
                if (res.data.status === true) {
                    if (res.data.data.length !== 0 && res.data.data[0].idGroup.length !== 0) {
                        if (this.state.daySelect === res.data.data[0].stt_day) {
                            const newTimeOption = res.data.data[0].time.map(item => {
                                if (item.detail.length > 0) {
                                    return {
                                        value: Number(item.id_time),
                                        label: item.time_name
                                    }
                                }
                                return undefined
                            }).filter(item => item !== undefined);

                            const newGroupOption = res.data.data[0].idGroup
                                .sort(function (a, b) {
                                    return b.id - a.id;
                                }
                                )
                                .map(item => {
                                    if (item.is_adult === 1) {
                                        return {
                                            value: Number(item.id),
                                            label: `${item.group_name} (nv)`,
                                            is_adult: true,
                                        }
                                    }

                                    return {
                                        value: Number(item.id),
                                        label: `${item.group_name} (hs)`,
                                        is_adult: false,
                                    }
                                })

                            let orther = 0;
                            let money = 0;
                            let totalChild = 0;

                            const timeFilter = timeSelect.value !== 0 ? timeSelect.value : Number(newTimeOption[0].value);
                            const groupFilter = groupSelect.value !== 0 ? groupSelect.value : Number(newGroupOption[0].value);

                            res.data.data[0].time.map(timeItem => {
                                if (timeItem.is_calcultor.length !== 0 && Number(timeItem.id_time) === timeFilter) {
                                    return timeItem.is_calcultor.map(calculateItem => {
                                        if (Number(calculateItem.id_time) === Number(res.data.data[0].time?.filter(item => Number(item.id_time) === timeFilter)[0]?.stt_time) && Number(calculateItem.id_group) === groupFilter) {
                                            orther = Number(calculateItem.price_orther);
                                            money = Number(calculateItem.voucher_price);
                                            totalChild = Number(calculateItem.count_student);
                                        }
                                        return calculateItem;
                                    })
                                }
                                return timeItem;
                            })

                            this.setState({
                                quantitative: res.data.data[0],
                                tmpQuantitative: res.data.data[0],
                                timeOption: newTimeOption,
                                timeSelect: timeSelect.value !== 0 ? timeSelect : newTimeOption[0],
                                groupOption: newGroupOption,
                                groupSelect: groupSelect.value !== 0 ? groupSelect : newGroupOption[0],
                                ortherFee: Number(orther),
                                moneySoonDay: Number(money),
                                totalChild: Number(totalChild)
                            })
                        }
                    } else {
                        this.setState({
                            quantitative: {},
                            tmpQuantitative: {},
                            timeOption: [],
                            timeSelect: { value: 0, label: "" },
                            groupOption: [],
                            groupSelect: { value: 0, label: "" },
                            ortherFee: 0,
                            moneySoonDay: 0,
                            totalChild: 0
                        })
                    }

                } else {
                    this.setState({
                        quantitative: {},
                        tmpQuantitative: {},
                        timeOption: [],
                        timeSelect: { value: 0, label: "" },
                        groupOption: [],
                        groupSelect: { value: 0, label: "" },
                        ortherFee: 0,
                        moneySoonDay: 0,
                        totalChild: 0
                    })
                }
            });
    }

    getStudentListsetting = () => {
        axios
            .get(`/viewSetting?id_classify=1`)
            .then((res) => {
                if (res.data.status === true) {
                    this.setState({
                        listTimeSetting: res.data.data.time
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getlistunit() {
        axios.post(`/viewUnit`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        listUnit: res.data.data
                    });
                }
            });
    }

    savePortion = () => {
        let stt_time = 0;

        this.state.quantitative.time.map(timeItem => {

            if (Number(timeItem.id_time) === this.state.timeSelect.value) {
                stt_time = Number(timeItem.stt_time);
            }

            return timeItem;
        })

        axios
            .post(`/createVoucher`, null, {
                params: {
                    type_voucher: 3,
                    id_menu: Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu),
                    stt_day: this.state.daySelect,
                    voucher_price: Number(this.state.moneySoonDay),
                    price_orther: this.state.ortherFee,
                    count_student: this.state.totalChild,
                    id_time: stt_time,
                    id_group: this.state.groupSelect.value
                }
            })
            .then((res) => {
                if (res.data.status === true) {
                    this.getQuantitativeVoucher(this.state.start, this.state.term, this.state.daySelect, this.state.timeSelect, this.state.groupSelect);
                    toast(res.data.data.mess, { type: "success", autoClose: 2000 });
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 2000 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updatePortion = () => {
        const calculator = this.state.quantitative.time?.find(item => Number(item.id_time) === Number(this.state.timeSelect.value))?.is_calcultor
            .find(item => Number(item.id_time) === Number(this.state.quantitative.time?.find(item => Number(item.id_time) === Number(this.state.timeSelect.value))?.stt_time)
                && Number(this.state.quantitative.idGroup.find(group => Number(group.id) === this.state.groupSelect.value).id_menu) === Number(item.id_menu)
                && Number(item.id_group) === Number(this.state.groupSelect.value))

        axios
            .post(`/updateVoucher`, null, {
                params: {
                    id_voucher: calculator.id,
                    voucher_price: Number(this.state.moneySoonDay),
                    price_orther: Number(this.state.ortherFee),
                    count_student: Number(this.state.totalChild)
                }
            })
            .then((res) => {
                if (res.data.status === true) {
                    this.getQuantitativeVoucher(this.state.start, this.state.term, this.state.daySelect, this.state.timeSelect, this.state.groupSelect);
                    toast(res.data.data.mess, { type: "success", autoClose: 2000 });
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 2000 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updateQuantitiveFoods = () => {
        const newQuantitive = this.state.quantitative.time.filter(timeItem => Number(timeItem.id_time) === Number(this.state.timeSelect.value))[0].detail.map(detailItem => {
            return {
                id_food: Number(detailItem.id_food),
                ingredent: detailItem.ingrendent.filter(ingredientItem => ingredientItem.group.some(groupItem => Number(groupItem.id) === Number(this.state.groupSelect.value))).map(ingredientItem => {
                    return {
                        id_ingredent: ingredientItem.id_ingredent,
                        amount: Number(ingredientItem.group.filter(groupItem => Number(groupItem.id) === Number(this.state.groupSelect.value))[0].amount)
                    }
                })
            }
        })

        axios
            .post(`/changeQuantily`, null, {
                params: {
                    id_menu: Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu),
                    id_group: this.state.groupSelect.value,
                    food: JSON.stringify(newQuantitive.filter(foodItem => foodItem.ingredent.length !== 0)),
                    stt_day: this.state.daySelect
                }
            })
            .then((res) => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 2000 });
                    this.getQuantitativeVoucher(this.state.start, this.state.term, this.state.daySelect, this.state.timeSelect, this.state.groupSelect)

                    this.setState({
                        isChangeQuantitative: false,
                        isChangeIngredient: false,
                    })
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 2000 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updatePriceIngredient = () => {
        let ingreBigArray = [];
        const timeObj = this.state.quantitative.time.filter(timeItem => Number(timeItem.id_time) === Number(this.state.timeSelect.value))[0];

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const uniqueIngredient = ingreBigArray
            .filter((value, index, self) => self.findIndex(v => v.id_ingredent === value.id_ingredent) === index)
            .map(item => {
                return {
                    id_ingredent: Number(item.id_ingredent),
                    id_supplier: Number(item.supplier.id_supplier),
                    price: Number(item.nutrients.price)
                }
            });

        axios
            .post(`/changeQuantily`, null, {
                params: {
                    id_menu: Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu),
                    id_group: this.state.groupSelect.value,
                    ingredent: JSON.stringify(uniqueIngredient),
                    stt_day: this.state.daySelect
                }
            })
            .then((res) => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 2000 });
                    this.getQuantitativeVoucher(this.state.start, this.state.term, this.state.daySelect, this.state.timeSelect, this.state.groupSelect)

                    this.setState({
                        isChangePrice: false,
                        wasChangedPrice: false,
                    })
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 2000 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //#endregion

    //#region Handle Logic
    handleSavePortion = () => {
        swal({
            title: `Bạn muốn lưu số liệu khẩu phần ăn này`,
            icon: "info",
            buttons: ["Đóng", "Lưu"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.savePortion();
            }
        });
    }

    handleUpdatePortion = () => {
        swal({
            title: `Bạn muốn cập nhật lại số liệu khẩu phần ăn này`,
            icon: "info",
            buttons: ["Đóng", "Lưu"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.updatePortion();
            }
        });
    }

    handleXportPortion = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn xuất file khẩu phần ăn này`,
            icon: "info",
            buttons: ["Đóng", "Xuất"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }

    handleUpdateIngredientFood = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn cập nhật định lượng nguyên liệu của các món ăn`,
            icon: "info",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.updateQuantitiveFoods();
            }
        });
    }

    handleUpdateIngredientPrice = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn cập nhật lại giá của các nguyên liệu`,
            icon: "info",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.updatePriceIngredient();
            }
        });
    }

    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day),
            isChangeQuantitative: false,
            isChangeIngredient: false,
            isChangePrice: false,
            wasChangedPrice: false,
        });

        this.getQuantitativeVoucher(this.state.start, this.state.term, day, { value: 0, label: "" }, { value: 0, label: "" })
    }

    //#region Handle export excel
    xport = () => {
        const wb = XLSX.utils.book_new();

        const bigDetailArray = this.ingredientPortionArray();

        if (bigDetailArray.length === 0) {
            toast("Không có dữ liệu", { type: "error", autoClose: 1000 });
        }

        let ingreBigArray = [];

        const timeObj = this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect?.value)[0];

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        const ws1 = this.marketBill(aIngredientArray);
        const ws2 = this.nutritionResult(aIngredientArray);
        const w3 = this.nutritionSetupResult(aIngredientArray);

        XLSX.utils.book_append_sheet(wb, ws1, "Hóa đơn đi chợ");
        XLSX.utils.book_append_sheet(wb, ws2, "Kết quả dưỡng chất");
        XLSX.utils.book_append_sheet(wb, w3, "Kết quả thiết lập dưỡng chất");

        XLSX.writeFile(wb, "Tính khẩu phần ăn.xlsx");
    }

    marketBill = (aIngredientArray) => {
        const table = document.getElementById("market-bill-table");

        var ws = XLSX.utils.table_to_sheet(table)

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E"];

        ws[`A1`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        ws[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 16
            },
            alignment: {
                vertical: "center",
                horizontal: "center",
            }
        };

        ws[`A3`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`D1`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "right"
            }
        };

        ws[`D3`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`A4`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "center",
                horizontal: "left",
            }
        };

        colAlpha.map((alpha) => {
            ws[`${alpha}5`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
            };
            return alpha;
        })

        let marketIngredientLength = 0;
        let storeIngredientLength = 0;

        aIngredientArray.filter(ingredientArray => ingredientArray[0].ingredent_type === 1).map((arrayItem) => {
            let massOfOneChild = 0; // KL 1 trẻ

            arrayItem.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                // Tính KL 1 trẻ
                if (selectedGroup) {
                    if (ingredientItem.unit.is_root === 0) {
                        massOfOneChild += Number(selectedGroup.amount);
                    } else {
                        massOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }
                }

                return ingredientItem;
            })

            if (massOfOneChild === 0) {
                return "";
            }

            marketIngredientLength++;

            return arrayItem;
        })

        aIngredientArray.filter(ingredientArray => ingredientArray[0].ingredent_type === 2).map((arrayItem) => {
            let massOfOneChild = 0; // KL 1 trẻ

            arrayItem.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                // Tính KL 1 trẻ
                if (selectedGroup) {
                    if (ingredientItem.unit.is_root === 0) {
                        massOfOneChild += Number(selectedGroup.amount);
                    } else {
                        massOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }
                }

                return ingredientItem;
            })

            if (massOfOneChild === 0) {
                return "";
            }

            storeIngredientLength++;

            return arrayItem;
        })

        colAlpha.map((alpha) => {
            if (ws[`${alpha}6`].v === "empty") {
                ws[`${alpha}6`].v = ' '
            }

            ws[`${alpha}6`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left"
                },
            };
            return alpha;
        })

        const moneyFormat = "#,###,###,###";
        const amountFormat = "#,###,###,##0.00";

        // Market table
        const firstLen = 7;

        for (let i = 0; i < marketIngredientLength; i++) {

            ws[`A${firstLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                }
            };

            ws[`B${firstLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left"
                }
            };

            ws[`C${firstLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`D${firstLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`E${firstLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`C${firstLen + i}`].z = amountFormat;
            delete ws[`C${firstLen + i}`].w;
            XLSX.utils.format_cell(ws[`C${firstLen + i}`]);

            ws[`D${firstLen + i}`].z = moneyFormat;
            delete ws[`D${firstLen + i}`].w;
            XLSX.utils.format_cell(ws[`D${firstLen + i}`]);

            ws[`E${firstLen + i}`].z = moneyFormat;
            delete ws[`E${firstLen + i}`].w;
            XLSX.utils.format_cell(ws[`E${firstLen + i}`]);
        }

        colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
            ws[`${alpha}${firstLen + marketIngredientLength}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        ws[`E${firstLen + marketIngredientLength}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "right"
            },
            border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
            },
        };

        ws[`E${firstLen + marketIngredientLength}`].z = moneyFormat;
        delete ws[`E${firstLen + marketIngredientLength}`].w;
        XLSX.utils.format_cell(ws[`E${firstLen + marketIngredientLength}`]);

        colAlpha.map((alpha) => {
            if (ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v === "empty") {
                ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v = ' '
            }

            ws[`${alpha}${firstLen + marketIngredientLength + 1}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left"
                },
            };
            return alpha;
        })

        // Store table
        const secondLen = firstLen + marketIngredientLength + 2;

        for (let i = 0; i < storeIngredientLength; i++) {

            ws[`A${secondLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                }
            };

            ws[`B${secondLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left"
                }
            };

            ws[`C${secondLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`D${secondLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`E${secondLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`C${secondLen + i}`].z = amountFormat;
            delete ws[`C${secondLen + i}`].w;
            XLSX.utils.format_cell(ws[`C${secondLen + i}`]);

            ws[`D${secondLen + i}`].z = moneyFormat;
            delete ws[`E${secondLen + i}`].w;
            XLSX.utils.format_cell(ws[`E${secondLen + i}`]);

            ws[`E${secondLen + i}`].z = moneyFormat;
            delete ws[`E${secondLen + i}`].w;
            XLSX.utils.format_cell(ws[`E${secondLen + i}`]);
        }

        colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
            ws[`${alpha}${secondLen + storeIngredientLength}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        ws[`E${secondLen + storeIngredientLength}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "right"
            },
            border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
            },
        };

        ws[`E${secondLen + storeIngredientLength}`].z = moneyFormat;
        delete ws[`E${secondLen + storeIngredientLength}`].w;
        XLSX.utils.format_cell(ws[`E${secondLen + storeIngredientLength}`]);

        ws['!cols'] = [
            { wch: 5 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];

        const row = [{ hpt: 30 }, { hpt: 30 }, { hpt: 60 }];

        ws['!rows'] = row;

        // Sumary Statistic
        const thirdLen = secondLen + storeIngredientLength + 1;

        for (let i = 0; i < 5; i++) {

            ws[`A${thirdLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left"
                },
                border: {
                    left: { style: "thin" },
                },
            };

            ws[`D${thirdLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                }
            };

            ws[`E${thirdLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    right: { style: "thin" },
                },
            };

            ws[`D${thirdLen + i}`].z = moneyFormat;
            delete ws[`D${thirdLen + i}`].w;
            XLSX.utils.format_cell(ws[`D${thirdLen + i}`]);

            if (i === 4) {
                ws[`A${thirdLen + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "left"
                    },
                    border: {
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                    },
                };

                ws[`B${thirdLen + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "left"
                    },
                    border: {
                        bottom: { style: "thin" },
                    }
                };

                ws[`C${thirdLen + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "left"
                    },
                    border: {
                        bottom: { style: "thin" },
                    }
                };

                ws[`D${thirdLen + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "right"
                    },
                    border: {
                        bottom: { style: "thin" },
                    },
                };

                ws[`E${thirdLen + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "right"
                    },
                    border: {
                        right: { style: "thin" },
                        bottom: { style: "thin" },
                    },
                };
            }
        }

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
            { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },

            { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
            { s: { r: 2, c: 3 }, e: { r: 2, c: 4 } },

            { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
            // Title

            // Body
            { s: { r: 5, c: 1 }, e: { r: 5, c: 4 } },
            { s: { r: firstLen + marketIngredientLength - 1, c: 0 }, e: { r: firstLen + marketIngredientLength - 1, c: 3 } },

            { s: { r: firstLen + marketIngredientLength, c: 1 }, e: { r: firstLen + marketIngredientLength, c: 4 } },
            { s: { r: secondLen + storeIngredientLength - 1, c: 0 }, e: { r: secondLen + storeIngredientLength - 1, c: 3 } },
            // Body


            // Footer
            { s: { r: thirdLen - 1, c: 0 }, e: { r: thirdLen - 1, c: 2 } },
            { s: { r: thirdLen - 1, c: 3 }, e: { r: thirdLen - 1, c: 4 } },

            { s: { r: thirdLen, c: 0 }, e: { r: thirdLen, c: 2 } },
            { s: { r: thirdLen, c: 3 }, e: { r: thirdLen, c: 4 } },

            { s: { r: thirdLen + 1, c: 0 }, e: { r: thirdLen + 1, c: 2 } },
            { s: { r: thirdLen + 1, c: 3 }, e: { r: thirdLen + 1, c: 4 } },

            { s: { r: thirdLen + 2, c: 0 }, e: { r: thirdLen + 2, c: 2 } },
            { s: { r: thirdLen + 2, c: 3 }, e: { r: thirdLen + 2, c: 4 } },

            { s: { r: thirdLen + 3, c: 0 }, e: { r: thirdLen + 3, c: 2 } },
            { s: { r: thirdLen + 3, c: 3 }, e: { r: thirdLen + 3, c: 4 } },

            { s: { r: thirdLen + 4, c: 0 }, e: { r: thirdLen + 4, c: 2 } },
            { s: { r: thirdLen + 4, c: 3 }, e: { r: thirdLen + 4, c: 4 } },
            // Footer
        ];
        ws["!merges"] = merge;

        return ws;
    }

    nutritionResult = (aIngredientArray) => {
        const table = document.getElementById("nutrition-result-table");

        var ws = XLSX.utils.table_to_sheet(table)

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
        const styleCol = ["B", "C", "D", "E", "F", "G", "H", "I", "J"];
        const amountColAlpha = ["I", "J"];

        ws[`A1`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        ws[`F1`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "right"
            }
        };

        ws[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 16
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`A3`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`F3`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        colAlpha.map((alpha) => {
            ws[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}5`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        let ingredientIndex = 0;

        aIngredientArray.map((arrayItem) => {
            // Nutrition
            let energyOfOneChild = 0;

            // Mass
            let massOfOneChild = 0; // KL 1 trẻ

            arrayItem.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                // Tính KL 1 trẻ
                if (selectedGroup) {
                    if (ingredientItem.unit.is_root === 0) {
                        massOfOneChild += Number(selectedGroup.amount);
                    } else {
                        massOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }

                    energyOfOneChild = massOfOneChild * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                }

                return ingredientItem;
            })

            if (energyOfOneChild === 0 || massOfOneChild === 0) {
                return "";
            }

            ingredientIndex++;

            return arrayItem;
        })

        let len = 6;

        const amountFormat = "#,###,###,##";

        for (let i = 0; i < ingredientIndex; i++) {

            ws[`A${len + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            styleCol.map(alpha => {
                if (ws[`${alpha}${len + i}`].v === "empty") {
                    ws[`${alpha}${len + i}`].v = ' '
                }

                ws[`${alpha}${len + i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            amountColAlpha.map(alpha => {
                ws[`${alpha}${len + i}`].z = amountFormat;
                delete ws[`${alpha}${len + i}`].w;
                XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
                return alpha;
            })
        }

        colAlpha.map((alpha) => {
            if (ws[`${alpha}${len + ingredientIndex}`].v === "empty") {
                ws[`${alpha}${len + ingredientIndex}`].v = ' '
            }

            ws[`${alpha}${len + ingredientIndex}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        let lenOne = len + ingredientIndex + 1;

        for (let i = 0; i < 6; i++) {
            colAlpha.map(alpha => {
                if (ws[`${alpha}${lenOne + i}`].v === "empty") {
                    ws[`${alpha}${lenOne + i}`].v = ' '
                }

                ws[`${alpha}${lenOne + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })
        }

        ws['!cols'] = [
            { wch: 3.5 },
            { wch: 20 },
            { wch: 7 },
            { wch: 6 },
            { wch: 6 },
            { wch: 6 },
            { wch: 6 },
            { wch: 8 },
            { wch: 8 },
            { wch: 8 },
        ];

        const row = [{ hpt: 30 }, { hpt: 30 }, { hpt: 60 }, { hpt: 30 }];

        for (let i = 0; i < lenOne - 3; i++) {
            row.push({})
        }

        row.push({ hpt: 30 })

        ws['!rows'] = row;

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
            { s: { r: 0, c: 5 }, e: { r: 0, c: 9 } },

            { s: { r: 1, c: 0 }, e: { r: 1, c: 9 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
            { s: { r: 2, c: 5 }, e: { r: 2, c: 9 } },
            // Title

            // Header
            { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
            { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
            { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

            { s: { r: 3, c: 3 }, e: { r: 3, c: 4 } },
            { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },

            { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
            { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
            { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
            // Header

            // Footer
            { s: { r: len + ingredientIndex - 1, c: 0 }, e: { r: len + ingredientIndex - 1, c: 2 } },
            { s: { r: len + ingredientIndex - 1, c: 7 }, e: { r: len + ingredientIndex - 1, c: 9 } },

            { s: { r: len + ingredientIndex, c: 0 }, e: { r: len + ingredientIndex, c: 1 } },
            { s: { r: len + ingredientIndex, c: 3 }, e: { r: len + ingredientIndex, c: 4 } },
            { s: { r: len + ingredientIndex, c: 5 }, e: { r: len + ingredientIndex, c: 6 } },
            { s: { r: len + ingredientIndex, c: 8 }, e: { r: len + ingredientIndex, c: 9 } },

            { s: { r: len + ingredientIndex + 1, c: 0 }, e: { r: len + ingredientIndex + 1, c: 1 } },
            { s: { r: len + ingredientIndex + 1, c: 3 }, e: { r: len + ingredientIndex + 1, c: 4 } },
            { s: { r: len + ingredientIndex + 1, c: 5 }, e: { r: len + ingredientIndex + 1, c: 6 } },
            { s: { r: len + ingredientIndex + 1, c: 8 }, e: { r: len + ingredientIndex + 1, c: 9 } },

            { s: { r: len + ingredientIndex + 2, c: 0 }, e: { r: len + ingredientIndex + 2, c: 1 } },
            { s: { r: len + ingredientIndex + 2, c: 3 }, e: { r: len + ingredientIndex + 2, c: 4 } },
            { s: { r: len + ingredientIndex + 2, c: 5 }, e: { r: len + ingredientIndex + 2, c: 6 } },
            { s: { r: len + ingredientIndex + 2, c: 8 }, e: { r: len + ingredientIndex + 2, c: 9 } },

            { s: { r: len + ingredientIndex + 3, c: 0 }, e: { r: len + ingredientIndex + 3, c: 1 } },
            { s: { r: len + ingredientIndex + 3, c: 3 }, e: { r: len + ingredientIndex + 3, c: 4 } },
            { s: { r: len + ingredientIndex + 3, c: 5 }, e: { r: len + ingredientIndex + 3, c: 6 } },
            { s: { r: len + ingredientIndex + 3, c: 8 }, e: { r: len + ingredientIndex + 3, c: 9 } },

            { s: { r: len + ingredientIndex + 4, c: 0 }, e: { r: len + ingredientIndex + 4, c: 1 } },
            { s: { r: len + ingredientIndex + 4, c: 3 }, e: { r: len + ingredientIndex + 4, c: 4 } },
            { s: { r: len + ingredientIndex + 4, c: 5 }, e: { r: len + ingredientIndex + 4, c: 6 } },
            { s: { r: len + ingredientIndex + 4, c: 8 }, e: { r: len + ingredientIndex + 4, c: 9 } },

            { s: { r: len + ingredientIndex + 5, c: 0 }, e: { r: len + ingredientIndex + 5, c: 1 } },
            { s: { r: len + ingredientIndex + 5, c: 3 }, e: { r: len + ingredientIndex + 5, c: 4 } },
            { s: { r: len + ingredientIndex + 5, c: 5 }, e: { r: len + ingredientIndex + 5, c: 6 } },
            { s: { r: len + ingredientIndex + 5, c: 8 }, e: { r: len + ingredientIndex + 5, c: 9 } },
            // Footer
        ];
        ws["!merges"] = merge;

        return ws;
    }

    nutritionSetupResult = (aIngredientArray) => {
        const table = document.getElementById("nutrition-setup-result-table");

        var ws = XLSX.utils.table_to_sheet(table)

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", 'K', "L", "M"];

        const styleCol = ["B", "C", "D", "E", "F", "G", "H", "I", "J", 'K', "L", "M"];
        const amountColAlpha = ["I", "J", "K"];
        const moneyColAlpha = ["L", "M"];
        const sumaryColAlpha = ["C", "D", "F", "H", "M"];

        if (ws[`A1`].v === "empty") {
            ws[`A1`].v = ' '
        }

        ws[`A1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        ws[`H1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "right"
            }
        };

        ws[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 16
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`A3`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`H3`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`A4`].s = {
            font: {
                name: "Times New Roman"
            },
            alignment: {
                vertical: "center",
                horizontal: "left",
            }
        };

        colAlpha.map((alpha) => {
            ws[`${alpha}5`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}6`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        let ingredientIndex = 0;

        aIngredientArray.map((arrayItem) => {
            // Nutrition
            let energyOfOneChild = 0;

            // Mass
            let massOfOneChild = 0; // KL 1 trẻ

            arrayItem.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                // Tính KL 1 trẻ
                if (selectedGroup) {
                    if (ingredientItem.unit.is_root === 0) {
                        massOfOneChild += Number(selectedGroup.amount);
                    } else {
                        massOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }

                    energyOfOneChild = massOfOneChild * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                }

                return ingredientItem;
            })

            if (energyOfOneChild === 0 || massOfOneChild === 0) {
                return "";
            }

            ingredientIndex++;

            return arrayItem;
        })

        let len = 7;
        const moneyFormat = "#,###,###,###";
        const amountFormat = "#,###,###,##";

        for (let i = 0; i < ingredientIndex; i++) {
            ws[`A${len + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            styleCol.map(alpha => {
                if (ws[`${alpha}${len + i}`].v === "empty") {
                    ws[`${alpha}${len + i}`].v = ' '
                }

                ws[`${alpha}${len + i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                    alignment: {
                        vertical: "center",
                        wrapText: true
                    },
                };
                return alpha;
            })

            amountColAlpha.map(alpha => {
                ws[`${alpha}${len + i}`].z = amountFormat;
                delete ws[`${alpha}${len + i}`].w;
                XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
                return alpha;
            })

            moneyColAlpha.map(alpha => {
                ws[`${alpha}${len + i}`].z = moneyFormat;
                delete ws[`${alpha}${len + i}`].w;
                XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
                return alpha;
            })
        }

        ws[`A${len + ingredientIndex}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "left"
            },
            border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
            },
        };

        colAlpha.slice(1, colAlpha.length).map((alpha) => {
            if (ws[`${alpha}${len + ingredientIndex}`].v === "empty") {
                ws[`${alpha}${len + ingredientIndex}`].v = ' '
            }

            ws[`${alpha}${len + ingredientIndex}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        let lenOne = len + ingredientIndex + 1;

        for (let i = 0; i < 6; i++) {
            ws[`A${lenOne + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left",
                    wrapText: true
                },
                border: {
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            sumaryColAlpha.map(alpha => {
                if (ws[`${alpha}${lenOne + i}`].v === "empty") {
                    ws[`${alpha}${lenOne + i}`].v = ' '
                }

                ws[`${alpha}${lenOne + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    border: {
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };
                return alpha;
            })

            if (ws[`I${lenOne + i}`].v === "empty") {
                ws[`I${lenOne + i}`].v = ' '
            }

            if (ws[`K${lenOne + i}`].v === "empty") {
                ws[`K${lenOne + i}`].v = ' '
            }

            ws[`I${lenOne + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "left",
                    wrapText: true
                },
                border: {
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws[`K${lenOne + i}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right",
                    wrapText: true
                },
                border: {
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            if (i === 5) {
                ws[`A${lenOne + i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "left",
                        wrapText: true
                    },
                    border: {
                        left: { style: "thin" },
                        right: { style: "thin" },
                        bottom: { style: "thin" },
                    },
                };

                colAlpha.slice(1, colAlpha.length).map(alpha => {
                    if (ws[`${alpha}${lenOne + i}`].v === "empty") {
                        ws[`${alpha}${lenOne + i}`].v = ' '
                    }

                    ws[`${alpha}${lenOne + i}`].s = {
                        font: {
                            name: "Times New Roman",
                            bold: true,
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center",
                            wrapText: true
                        },
                        border: {
                            left: { style: "thin" },
                            right: { style: "thin" },
                            bottom: { style: "thin" },
                        },
                    };
                    return alpha;
                })
            }
        }

        const row = [{ hpt: 30 }, { hpt: 30 }, { hpt: 60 }, {}, { hpt: 45 }];

        for (let i = 0; i < lenOne - 6; i++) {
            row.push({})
        }

        ws['!cols'] = [
            { wch: 3.5 },
            { wch: 11.5 },
            { wch: 7 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 5 },
            { wch: 6 },
            { wch: 4 },
            { wch: 6 },
            { wch: 6 },
            { wch: 6 },
            { wch: 7 }
        ];

        row.push({ hpt: 30 }, { hpt: 30 }, { hpt: 45 }, { hpt: 30 }, { hpt: 30 })

        ws['!rows'] = row;

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
            { s: { r: 0, c: 7 }, e: { r: 0, c: 12 } },

            { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 6 } },
            { s: { r: 2, c: 7 }, e: { r: 2, c: 12 } },

            { s: { r: 3, c: 0 }, e: { r: 3, c: 12 } },
            // Title

            // Header
            { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
            { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
            { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },

            { s: { r: 4, c: 3 }, e: { r: 4, c: 4 } },
            { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },

            { s: { r: 4, c: 7 }, e: { r: 5, c: 7 } },
            { s: { r: 4, c: 8 }, e: { r: 5, c: 8 } },
            { s: { r: 4, c: 9 }, e: { r: 5, c: 9 } },
            { s: { r: 4, c: 10 }, e: { r: 5, c: 10 } },
            { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },
            { s: { r: 4, c: 12 }, e: { r: 5, c: 12 } },
            // Header

            // Footer
            { s: { r: len + ingredientIndex - 1, c: 0 }, e: { r: len + ingredientIndex - 1, c: 2 } },
            { s: { r: len + ingredientIndex - 1, c: 7 }, e: { r: len + ingredientIndex - 1, c: 12 } },

            { s: { r: len + ingredientIndex, c: 0 }, e: { r: len + ingredientIndex, c: 1 } },
            { s: { r: len + ingredientIndex, c: 3 }, e: { r: len + ingredientIndex, c: 4 } },
            { s: { r: len + ingredientIndex, c: 5 }, e: { r: len + ingredientIndex, c: 6 } },
            { s: { r: len + ingredientIndex, c: 8 }, e: { r: len + ingredientIndex, c: 9 } },
            { s: { r: len + ingredientIndex, c: 10 }, e: { r: len + ingredientIndex, c: 12 } },

            { s: { r: len + ingredientIndex + 1, c: 0 }, e: { r: len + ingredientIndex + 1, c: 1 } },
            { s: { r: len + ingredientIndex + 1, c: 3 }, e: { r: len + ingredientIndex + 1, c: 4 } },
            { s: { r: len + ingredientIndex + 1, c: 5 }, e: { r: len + ingredientIndex + 1, c: 6 } },
            { s: { r: len + ingredientIndex + 1, c: 8 }, e: { r: len + ingredientIndex + 1, c: 9 } },
            { s: { r: len + ingredientIndex + 1, c: 10 }, e: { r: len + ingredientIndex + 1, c: 12 } },

            { s: { r: len + ingredientIndex + 2, c: 0 }, e: { r: len + ingredientIndex + 2, c: 1 } },
            { s: { r: len + ingredientIndex + 2, c: 3 }, e: { r: len + ingredientIndex + 2, c: 4 } },
            { s: { r: len + ingredientIndex + 2, c: 5 }, e: { r: len + ingredientIndex + 2, c: 6 } },
            { s: { r: len + ingredientIndex + 2, c: 8 }, e: { r: len + ingredientIndex + 2, c: 9 } },
            { s: { r: len + ingredientIndex + 2, c: 10 }, e: { r: len + ingredientIndex + 2, c: 12 } },

            { s: { r: len + ingredientIndex + 3, c: 0 }, e: { r: len + ingredientIndex + 3, c: 1 } },
            { s: { r: len + ingredientIndex + 3, c: 3 }, e: { r: len + ingredientIndex + 3, c: 4 } },
            { s: { r: len + ingredientIndex + 3, c: 5 }, e: { r: len + ingredientIndex + 3, c: 6 } },
            { s: { r: len + ingredientIndex + 3, c: 8 }, e: { r: len + ingredientIndex + 3, c: 9 } },
            { s: { r: len + ingredientIndex + 3, c: 10 }, e: { r: len + ingredientIndex + 3, c: 12 } },

            { s: { r: len + ingredientIndex + 4, c: 0 }, e: { r: len + ingredientIndex + 4, c: 1 } },
            { s: { r: len + ingredientIndex + 4, c: 3 }, e: { r: len + ingredientIndex + 4, c: 4 } },
            { s: { r: len + ingredientIndex + 4, c: 5 }, e: { r: len + ingredientIndex + 4, c: 6 } },
            { s: { r: len + ingredientIndex + 4, c: 8 }, e: { r: len + ingredientIndex + 4, c: 9 } },
            { s: { r: len + ingredientIndex + 4, c: 10 }, e: { r: len + ingredientIndex + 4, c: 12 } },

            { s: { r: len + ingredientIndex + 5, c: 0 }, e: { r: len + ingredientIndex + 5, c: 1 } },
            { s: { r: len + ingredientIndex + 5, c: 3 }, e: { r: len + ingredientIndex + 5, c: 4 } },
            { s: { r: len + ingredientIndex + 5, c: 5 }, e: { r: len + ingredientIndex + 5, c: 6 } },
            { s: { r: len + ingredientIndex + 5, c: 8 }, e: { r: len + ingredientIndex + 5, c: 9 } },
            { s: { r: len + ingredientIndex + 5, c: 10 }, e: { r: len + ingredientIndex + 5, c: 12 } },
            // Footer
        ];
        ws["!merges"] = merge;

        return ws;
    }
    //#endregion

    ingredientPortionArray = () => {
        let detailArray = [];

        if (this.state.quantitative) {
            this.state.quantitative.time?.map(timeItem => {
                detailArray = detailArray.concat(timeItem.detail);
                return timeItem;
            })
        }

        return detailArray;
    }

    dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    setDate = (theDate) => {
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length - 1]
        })

        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

    group = (arr, key) => {
        return [...arr.reduce((acc, o) =>
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
            , new Map()).values()];
    }
    //#endregion

    // Render
    RenderIngredientTable() {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const bigDetailArray = this.ingredientPortionArray();
        let ingreBigArray = [];
        const timeObj = this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect?.value)[0];

        if (bigDetailArray.length === 0) {
            return (
                <div className="d-flex justify-content-center">
                    Không có dữ liệu
                </div>
            )
        }

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        let totalMoney = 0;
        let ingredientIndex = 0;

        return (
            <Table bordered responsive>
                <thead className="align-middle text-center">
                    <tr>
                        <th>STT</th>
                        <th>Tên thực phẩm</th>
                        <th>ĐVT</th>
                        <th>Giá tiền (đ)</th>
                        <th>1 {this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"}(g)</th>
                        <th>Ăn được(g)</th>
                        <th>Đi chợ(g)</th>
                        <th>Giá 100g (đ)</th>
                        <th>Thành tiền (đ)</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        aIngredientArray.map((arrayItem, index) => {
                            let realMassOfOneChild = 0; // KL đi chợ 1 trẻ
                            let pureMassOfOneChild = 0; // KL ăn được 1 trẻ
                            let exchangeValue = 0;

                            let realMass = 0; // Tổng KL đi chợ
                            let pureMass = 0; // Tổng KL ăn được

                            let hunGramPrice = 0; // Đơn giá trên 100g
                            let ingredientPrice = 0; // Thành tiền

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                                // KL đi chợ, ăn được 1 trẻ
                                if (selectedGroup) {
                                    if (ingredientItem.unit.is_root === 0) {
                                        realMassOfOneChild += Number(selectedGroup.amount);
                                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    } else {
                                        realMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass);
                                    }

                                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                }

                                return ingredientItem;
                            })

                            if (realMassOfOneChild === 0) {
                                return "";
                            }

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                                if (selectedGroup) {
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneChild * this.state.totalChild;

                                    // Tính tổng KL ăn được
                                    pureMass = pureMassOfOneChild * this.state.totalChild;
                                }

                                return ingredientItem;
                            })

                            // Tính thành tiền
                            if (exchangeValue !== 0) {
                                ingredientPrice = Math.round((realMass / exchangeValue) * Number(arrayItem[0].nutrients.price));
                            }

                            // Tính đơn giá / 100g
                            if (realMass !== 0) {
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMass);
                            }

                            totalMoney += Math.round(ingredientPrice);

                            ingredientIndex++;

                            return (
                                <tr key={index} className="align-middle text-end">
                                    <td className="text-center">{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>
                                        {this.state.listUnit?.filter(item => Number(item.id) === Number(arrayItem[0].unit.id_unit))[0]?.unit_name}
                                    </td>
                                    <td style={{ minWidth: 100 }}>
                                        <CurrencyFormat
                                            value={arrayItem[0].nutrients.price || ""}
                                            displayType={this.state.isChangePrice ? 'input' : 'text'}
                                            thousandSeparator={true}
                                            onValueChange={(values) => {
                                                this.setState({
                                                    quantitative: {
                                                        ...this.state.quantitative,
                                                        time: this.state.quantitative.time.map(timeObj => {
                                                            return {
                                                                ...timeObj,
                                                                detail: timeObj.detail.map(detailObj => {
                                                                    return {
                                                                        ...detailObj,
                                                                        ingrendent: detailObj.ingrendent.map(ingredientObj => {

                                                                            if (Number(ingredientObj.id_ingredent) === Number(arrayItem[0].id_ingredent)) {
                                                                                return {
                                                                                    ...ingredientObj,
                                                                                    nutrients: {
                                                                                        ...ingredientObj.nutrients,
                                                                                        price: values.value
                                                                                    }
                                                                                }
                                                                            }

                                                                            return ingredientObj;
                                                                        })
                                                                    }
                                                                })
                                                            };
                                                        })
                                                    },
                                                    wasChangedPrice: true
                                                })
                                            }}
                                            className={this.state.isChangePrice ? "form-control text-end" : ""}
                                            allowNegative={false}
                                            isNumericString
                                        />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMassOfOneChild) || ""} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(pureMass) || ""} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass)|| ""} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={hunGramPrice || ""} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ingredientPrice || ""} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr className="align-middle text-end">
                        <th></th>
                        <th className="text-start">Tổng tiền</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            <CurrencyFormat value={totalMoney || ""} displayType={'text'} thousandSeparator={true} />
                        </th>
                    </tr>
                </tbody>
            </Table>
        )
    }

    RenderOption() {
        let bigDetailArray = this.ingredientPortionArray();

        return (
            <>
                <Card.Title>Chọn ngày tính khẩu phần ăn</Card.Title>

                <div className="d-none d-md-flex justify-content-start my-3">
                    {
                        this.state.listDay.map((dayItem, i) => {
                            let toDayIs = dayItem.id % 7;

                            if (toDayIs === 0) {
                                toDayIs = 7;
                            }

                            return (
                                <div key={i} className="d-flex me-md-4 me-xl-5">
                                    <Form.Check
                                        type="radio"
                                        value={dayItem.id}
                                        checked={this.state.daySelect === Number(dayItem.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleCheck(e.target.value)
                                            }
                                        }}
                                        name="day"
                                        className="me-2"
                                    />
                                    {dayItem.day_name}
                                </div>

                            );
                        })}
                </div>

                <Row className="my-3 d-md-none">
                    <Col xs={6} md={4}>
                        {this.state.listDay
                            .slice(
                                0,
                                Math.round(this.state.listDay.length / 2)
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value)
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>

                    <Col xs={6} md={4}>
                        {this.state.listDay
                            .slice(
                                Math.round(this.state.listDay.length / 2),
                                this.state.listDay.length
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === Number(dayItem.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value)
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>

                {
                    !this.state.isLoading
                        ?
                        <div className="d-block d-sm-flex justify-content-between mb-3">
                            <div className="d-flex">
                                {
                                    this.state.timeOption.length !== 0
                                        ?
                                        <Select
                                            options={this.state.timeOption}
                                            value={this.state.timeSelect}
                                            onChange={(choice) => {

                                                let orther = 0;
                                                let money = 0;
                                                let totalChild = 0;

                                                this.state.quantitative.time.map(timeItem => {
                                                    if (timeItem.is_calcultor.length !== 0 && Number(timeItem.id_time) === Number(choice.value)) {
                                                        return timeItem.is_calcultor.map(calculateItem => {
                                                            if (Number(calculateItem.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(choice.value))[0]?.stt_time) && Number(calculateItem.id_group) === this.state.groupSelect.value) {
                                                                orther = Number(calculateItem.price_orther);
                                                                money = Number(calculateItem.voucher_price);
                                                                totalChild = Number(calculateItem.count_student);
                                                            }

                                                            return calculateItem;
                                                        })
                                                    }

                                                    return timeItem;
                                                })

                                                this.setState({
                                                    timeSelect: choice,
                                                    ortherFee: orther,
                                                    moneySoonDay: money,
                                                    totalChild: totalChild
                                                })
                                            }}
                                            className="me-3"
                                            isDisabled={this.state.isChangeQuantitative || this.state.isChangePrice}
                                        />
                                        : ""
                                }

                                {
                                    this.state.groupOption.length !== 0
                                        ?
                                        <Select
                                            options={this.state.groupOption}
                                            value={this.state.groupSelect}
                                            onChange={(choice) => {
                                                let orther = 0;
                                                let money = 0;
                                                let totalChild = 0;

                                                this.state.quantitative.time.map(timeItem => {
                                                    if (timeItem.is_calcultor.length !== 0 && Number(timeItem.id_time) === this.state.timeSelect.value) {
                                                        return timeItem.is_calcultor.map(calculateItem => {
                                                            if (Number(calculateItem.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === this.state.timeSelect.value)[0]?.stt_time) && Number(calculateItem.id_group) === Number(choice.value)) {
                                                                orther = Number(calculateItem.price_orther);
                                                                money = Number(calculateItem.voucher_price);
                                                                totalChild = Number(calculateItem.count_student);
                                                            }

                                                            return calculateItem;
                                                        })
                                                    }

                                                    return timeItem;
                                                })

                                                this.setState({
                                                    groupSelect: choice,
                                                    ortherFee: orther,
                                                    moneySoonDay: money,
                                                    totalChild: totalChild
                                                })
                                            }}
                                            isDisabled={this.state.isChangeQuantitative || this.state.isChangePrice}
                                        />
                                        : ""
                                }
                            </div>

                            <div className="d-flex mt-3 mt-sm-0">
                                {
                                    bigDetailArray.length !== 0 && !this.state.isChangeQuantitative
                                        ?
                                        this.state.isChangePrice ? (
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="me-3"
                                                onClick={() =>
                                                    this.setState({
                                                        isChangePrice: false,
                                                        wasChangedPrice: false,
                                                        quantitative: this.state.tmpQuantitative,
                                                    })
                                                }
                                            >
                                                Hủy thay đổi
                                            </Button>
                                        ) : (
                                            this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                                ?
                                                ""
                                                :
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="me-3"
                                                    onClick={() => {
                                                        this.setState({
                                                            isChangePrice: true,
                                                        });
                                                    }}
                                                >
                                                    Thay đổi giá
                                                </Button>
                                        )
                                        : ""
                                }

                                {
                                    this.state.wasChangedPrice
                                        ?
                                        <Button
                                            variant="success"
                                            size="sm"
                                            className="me-3"
                                            onClick={() => {
                                                this.handleUpdateIngredientPrice();
                                            }}
                                        >
                                            Lưu thay đổi
                                        </Button>
                                        : ""
                                }

                                {
                                    bigDetailArray.length !== 0 && !this.state.isChangePrice
                                        ?
                                        this.state.isChangeQuantitative ? (
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="me-3"
                                                onClick={() =>
                                                    this.setState({
                                                        isChangeQuantitative: false,
                                                        quantitative: this.state.tmpQuantitative,
                                                        isChangeIngredient: false
                                                    })
                                                }
                                            >
                                                Hủy thay đổi
                                            </Button>
                                        ) : (
                                            this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                                ?
                                                ""
                                                :
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="me-3"
                                                    onClick={() => {
                                                        this.setState({
                                                            isChangeQuantitative: true,
                                                        });
                                                    }}
                                                >
                                                    Thay đổi định lượng
                                                </Button>
                                        )
                                        : ""
                                }

                                {
                                    this.state.isChangeIngredient
                                        ?
                                        <Button
                                            variant="success"
                                            size="sm"
                                            className="me-3"
                                            onClick={() => {
                                                this.handleUpdateIngredientFood();
                                            }}
                                        >
                                            Lưu thay đổi
                                        </Button>
                                        : ""
                                }
                            </div>
                        </div>
                        : ""
                }
            </>
        )
    }

    RenderQuantitativeChangeTable() {
        let foodIndex = 0;

        return (
            <Table bordered responsive>
                <thead className="align-middle text-center">
                    <tr>
                        <th>STT</th>
                        <th>Tên món ăn</th>
                        <th>Nguyên liệu</th>
                        <th>ĐVT</th>
                        <th>Định lượng chuẩn</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect.value)[0].detail?.map((detailItem) => {
                            if (!detailItem.ingrendent[0].group.some(groupItem => groupItem.id === this.state.groupSelect.value)) {
                                return "";
                            }
                            foodIndex++;
                            return (
                                <React.Fragment key={detailItem.id_food}>
                                    <tr className="align-middle">
                                        <td className="text-center" rowSpan={detailItem.ingrendent.length}>{foodIndex}</td>
                                        <td rowSpan={detailItem.ingrendent.length}>{detailItem.food_name}</td>
                                        <td>{detailItem.ingrendent[0].ingredent_name}</td>
                                        <td>{detailItem.ingrendent[0].unit.unit_name}</td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={detailItem.ingrendent[0].group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0]?.amount || ""}
                                                thousandSeparator={true}
                                                onValueChange={(values) => {
                                                    this.setState({
                                                        quantitative:
                                                        {
                                                            ...this.state.quantitative,
                                                            time: this.state.quantitative.time.map(timeObj => {
                                                                return {
                                                                    ...timeObj,
                                                                    detail: timeObj.detail.map(detailObj => {
                                                                        if (detailObj.id_food === detailItem.id_food) {
                                                                            return {
                                                                                ...detailObj,
                                                                                ingrendent: detailObj.ingrendent.map(ingredientObj => {
                                                                                    if (ingredientObj.id_ingredent === detailItem.ingrendent[0].id_ingredent) {
                                                                                        return {
                                                                                            ...ingredientObj,
                                                                                            group: ingredientObj.group.map(groupObj => {
                                                                                                if (groupObj.id === this.state.groupSelect.value) {
                                                                                                    return {
                                                                                                        ...groupObj,
                                                                                                        amount: values.value
                                                                                                    }
                                                                                                }
                                                                                                return groupObj;
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    return ingredientObj;
                                                                                })
                                                                            }
                                                                        }
                                                                        return detailObj;
                                                                    })
                                                                };
                                                            })
                                                        },
                                                        isChangeIngredient: true
                                                    })
                                                }}
                                                className="form-control text-end"
                                                allowNegative={false}
                                                isNumericString
                                            />
                                        </td>
                                    </tr>

                                    {
                                        detailItem.ingrendent.slice(1, detailItem.ingrendent.length)?.map(ingredientItem => {
                                            return (
                                                <tr key={ingredientItem.id_ingredent}>
                                                    <td>{ingredientItem.ingredent_name}</td>
                                                    <td>{ingredientItem.unit.unit_name}</td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0]?.amount || ""}
                                                            thousandSeparator={true}
                                                            onValueChange={(values) => {
                                                                this.setState({
                                                                    quantitative:
                                                                    {
                                                                        ...this.state.quantitative,
                                                                        time: this.state.quantitative.time.map(timeObj => {
                                                                            return {
                                                                                ...timeObj,
                                                                                detail: timeObj.detail.map(detailObj => {
                                                                                    if (detailObj.id_food === detailItem.id_food) {
                                                                                        return {
                                                                                            ...detailObj,
                                                                                            ingrendent: detailObj.ingrendent.map(ingredientObj => {
                                                                                                if (ingredientObj.id_ingredent === ingredientItem.id_ingredent) {
                                                                                                    return {
                                                                                                        ...ingredientObj,
                                                                                                        group: ingredientObj.group.map(groupObj => {
                                                                                                            if (groupObj.id === this.state.groupSelect.value) {
                                                                                                                return {
                                                                                                                    ...groupObj,
                                                                                                                    amount: values.value
                                                                                                                }
                                                                                                            }
                                                                                                            return groupObj;
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                                return ingredientObj;
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    return detailObj;
                                                                                })
                                                                            };
                                                                        })
                                                                    },
                                                                    isChangeIngredient: true
                                                                })
                                                            }}
                                                            className="form-control text-end"
                                                            allowNegative={false}
                                                            isNumericString
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    RenderFilterForm() {
        let totalChild = this.state.totalChild;
        let totalMoney = 0;

        const bigDetailArray = this.ingredientPortionArray();

        if (bigDetailArray.length !== 0) {
            this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect.value)[0].detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
                detailItem.ingrendent.map(ingredientItem => {
                    const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                    if (selectedGroup && Number(ingredientItem.nutrients.redemption_value) !== 0) {
                        if (ingredientItem.unit.is_root === 0) {
                            totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                        } else {
                            totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price)
                        }
                    }
                    return ingredientItem;
                })
                return detailItem;
            })

            totalMoney = Math.round(totalMoney);
        }

        const timePoetry = Number(this.state.quantitative?.time?.find(item => Number(item.id_time) === this.state.timeSelect.value).poetry)


        return (
            <>
                <Card className="px-2 py-1">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng số {this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"}:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={totalChild }
                                    thousandSeparator={true}
                                    onValueChange={(values) => {
                                        this.setState({
                                            totalChild: Number(values.value)
                                        })
                                    }}
                                    className="form-control text-end"
                                    allowNegative={false}
                                    isNumericString
                                    disabled={
                                        this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                    }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số tiền cho mỗi {this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"}:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={timePoetry}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng số tiền thu:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    value={(timePoetry * totalChild)}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                    isNumericString

                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Các chi phí khác / 1 {this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"}
                            </Form.Label>
                            <Col xs="5" sm="5">
                                <CurrencyFormat
                                    value={
                                        this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.is_calcultor
                                            .some(item => Number(item.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.stt_time)
                                                && Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu) === Number(item.id_menu)
                                                && Number(item.id_group) === Number(this.state.groupSelect.value)
                                            )
                                            ?
                                            this.state.ortherFee
                                            :
                                            this.state.ortherFee === 0 ? "" : this.state.ortherFee
                                    }
                                    thousandSeparator={true}
                                    onValueChange={(values) => {
                                        this.setState({
                                            ortherFee: Number(values.value)
                                        })
                                    }}
                                    className="form-control text-end"
                                    allowNegative={false}
                                    isNumericString
                                    disabled={
                                        this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                    }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng chi phí khác:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={(Number(this.state.ortherFee) * totalChild)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString
                                    displayType="text"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số dư đầu kì:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={
                                        this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.is_calcultor
                                            .some(item => Number(item.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.stt_time)
                                                && Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu) === Number(item.id_menu)
                                                && Number(item.id_group) === Number(this.state.groupSelect.value)
                                            )
                                            ?
                                            this.state.moneySoonDay
                                            :
                                            this.state.moneySoonDay === 0 ? "" : this.state.moneySoonDay
                                    }
                                    thousandSeparator={true}
                                    onValueChange={(values) => {
                                        this.setState({
                                            moneySoonDay: values.value
                                        })
                                    }}
                                    className="form-control text-end"
                                    allowNegative={true}
                                    isNumericString
                                    disabled={
                                        this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                    }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tiền chi trong buổi:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    value={Number(this.state.ortherFee) * totalChild + totalMoney}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                    isNumericString
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tiền chi cho mỗi {this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"}:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    value={totalChild !== 0 ? Math.round((Number(this.state.ortherFee) * totalChild + totalMoney) / totalChild) : 0}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                    isNumericString
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số dư cuối kì:
                            </Form.Label>
                            <Col xs="4" sm="4" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={Number(this.state.moneySoonDay) - (Number(this.state.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild)}
                                    thousandSeparator={true}
                                    displayType="text"
                                    isNumericString
                                />
                            </Col>
                            <Col xs="1" sm="1" className="d-flex align-items-center p-0" title="Sao chép số dư cuối kì" style={{ cursor: "pointer" }}
                                onClick={() => {
                                    navigator.clipboard.writeText(Number(this.state.moneySoonDay) - (Number(this.state.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild));
                                    toast("Đã sao chép số dư cuối kì", { type: "success", autoClose: 1000 });
                                }}
                            >
                                <i className="fa-solid fa-copy"></i>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng tiền chênh lệch:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={(timePoetry * totalChild) - (Number(this.state.ortherFee) * totalChild + totalMoney)}
                                    thousandSeparator={true}
                                    displayType="text"
                                    isNumericString
                                    style={
                                        (timePoetry * totalChild) >= (Number(this.state.ortherFee * totalChild) + totalMoney)
                                            ? { color: "green" }
                                            : { color: "red" }}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Card>
            </>
        )
    }

    render() {
        let bigDetailArray = this.ingredientPortionArray();

        let totalMoney = 0;

        if (bigDetailArray.length !== 0) {
            this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect.value)[0].detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
                detailItem.ingrendent.map(ingredientItem => {
                    const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                    if (selectedGroup && Number(ingredientItem.nutrients.redemption_value) !== 0) {
                        if (ingredientItem.unit.is_root === 0) {
                            totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                        } else {
                            totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price)
                        }
                    }
                    return ingredientItem;
                })
                return detailItem;
            })

            totalMoney = Math.round(totalMoney);
        }

        return (
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tính khẩu phần ăn</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-sm-flex justify-content-between">
                            <div className="d-block d-sm-flex">
                                <Card.Title className="me-3 pt-1">
                                    Tính khẩu phần ăn {this.stringLastDate(this.state.listDay.filter((item) => item.id === this.state.daySelect)[0]?.day || new Date())}
                                </Card.Title>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{
                                            border: "none",
                                            width: 39,
                                            backgroundColor: "rgb(16,108,252)",
                                            borderRadius: 5,
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(
                                                    e.target.valueAsNumber
                                                );
                                                const week =
                                                    this.setDate(
                                                        firstDayOfWeek
                                                    );

                                                this.setState({
                                                    weekInMonth: (0 | (week[0].getDate() / 7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                    isChangeQuantitative: false,
                                                    isChangeIngredient: false,
                                                    isChangePrice: false,
                                                    wasChangedPrice: false,
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    { value: 0, label: "" },
                                                    { value: 0, label: "" }
                                                );
                                            } else {
                                                const today = new Date();
                                                const week =
                                                    this.setDate(today);

                                                this.setState({
                                                    weekInMonth:
                                                        (0 | (week[0].getDate() / 7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                    isChangeQuantitative: false,
                                                    isChangeIngredient: false,
                                                    isChangePrice: false,
                                                    wasChangedPrice: false,
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    { value: 0, label: "" },
                                                    { value: 0, label: "" }
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="d-block d-sm-flex">
                                {
                                    this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                        ?
                                        <Button
                                            variant="success"
                                            size="sm"
                                            className="me-3 mt-3 mt-sm-0"
                                            onClick={() => {
                                                if (bigDetailArray.length === 0) {
                                                    toast("Không có dữ liệu", { type: "error", autoClose: 2000 });
                                                    return;
                                                }

                                                this.handleXportPortion();
                                            }}
                                            disabled={bigDetailArray.length === 0}
                                        >
                                            Xuất file <i className="fa-solid fa-file-export"></i>
                                        </Button>
                                        :
                                        this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.is_calcultor
                                            .some(item => Number(item.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.stt_time)
                                                && Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu) === Number(item.id_menu)
                                                && Number(item.id_group) === Number(this.state.groupSelect.value)
                                            )
                                            ?
                                            <>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="me-3 mt-3 mt-sm-0"
                                                    onClick={() => {
                                                        if (bigDetailArray.length === 0) {
                                                            toast("Không có dữ liệu", { type: "error", autoClose: 2000 });
                                                            return;
                                                        }

                                                        this.handleXportPortion();
                                                    }}
                                                    disabled={bigDetailArray.length === 0}
                                                >
                                                    Xuất file <i className="fa-solid fa-file-export"></i>
                                                </Button>

                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="mt-3 mt-sm-0"
                                                    onClick={() => {
                                                        if (bigDetailArray.length === 0) {
                                                            toast("Không có dữ liệu", { type: "error", autoClose: 2000 });
                                                            return;
                                                        }

                                                        if (this.state.totalChild === 0) {
                                                            toast(`Số ${this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"} phải lớn hơn 0`, { type: "error", autoClose: 2000 });
                                                            return;
                                                        }

                                                        if (totalMoney === 0) {
                                                            toast("Số tiền chi thực phẩm phải lớn hơn 0", { type: "error", autoClose: 2000 });
                                                            return;
                                                        }


                                                        this.handleUpdatePortion();
                                                    }}
                                                    disabled={this.state.isChangeQuantitative || this.state.isChangePrice || bigDetailArray.length === 0 || this.state.isLoading}
                                                >
                                                    Lưu số liệu <i className="fa-solid fa-pencil"></i>
                                                </Button>
                                            </>
                                            :
                                            bigDetailArray.length !== 0
                                                ?
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="mt-3 mt-sm-0"
                                                    onClick={() => {
                                                        if (bigDetailArray.length === 0) {
                                                            toast("Không có dữ liệu", { type: "error", autoClose: 2000 });
                                                            return;
                                                        }

                                                        if (this.state.totalChild === 0) {
                                                            toast(`Số ${this.state.groupSelect.is_adult ? "nhân viên" : "trẻ"} phải lớn hơn 0`, { type: "error", autoClose: 2000 });
                                                            return;
                                                        }

                                                        if (totalMoney === 0) {
                                                            toast("Số tiền chi thực phẩm phải lớn hơn 0", { type: "error", autoClose: 2000 });
                                                            return;
                                                        }


                                                        this.handleSavePortion();
                                                    }}
                                                    disabled={this.state.isChangeQuantitative || this.state.isChangePrice || bigDetailArray.length === 0 || this.state.isLoading}
                                                >
                                                    Lưu số liệu <i className="fa-solid fa-save"></i>
                                                </Button>
                                                : ""
                                }
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} md={8} >
                                {this.RenderOption()}

                                {
                                    !this.state.isLoading
                                        ?
                                        bigDetailArray.length !== 0 ? (
                                            <>
                                                {this.RenderIngredientTable()}

                                                {
                                                    this.state.isChangeQuantitative
                                                        ?
                                                        this.RenderQuantitativeChangeTable()
                                                        : ""
                                                }
                                            </>
                                        ) : ""
                                        :
                                        ""
                                }
                            </Col>

                            <Col xs={12} md={4}>
                                {
                                    !this.state.isLoading
                                        ?
                                        bigDetailArray.length !== 0 ? (
                                            <div className="sticky-md-top" style={{ top: "5rem" }}>
                                                <NutritionTable
                                                    quantitative={this.state.quantitative}
                                                    timeSelect={this.state.timeSelect}
                                                    groupSelect={this.state.groupSelect}
                                                />
                                                {this.RenderFilterForm()}
                                            </div>
                                        ) : (
                                            ""
                                        )
                                        : ""
                                }
                            </Col>
                        </Row>

                        {!this.state.isLoading
                            ?
                            bigDetailArray.length !== 0
                                ? ""
                                :
                                <div className="d-flex justify-content-center">
                                    Chưa có thực đơn
                                </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                    <span className="sr-only">Đang tải bảng tính khẩu phần ăn...</span>
                                </div>
                            </div>
                        }

                        <div
                            className="d-none"
                        >
                            {
                                bigDetailArray.length !== 0
                                    ?
                                    <>
                                        <MarketBill
                                            quantitative={this.state.quantitative}
                                            listTimeSetting={this.state.listTimeSetting}
                                            timeSelect={this.state.timeSelect}
                                            groupSelect={this.state.groupSelect}
                                            ortherFee={this.state.ortherFee}
                                            moneySoonDay={Number(this.state.moneySoonDay)}
                                            totalChild={this.state.totalChild}
                                            listDay={this.state.listDay}
                                            daySelect={this.state.daySelect}
                                        />

                                        <NutritionResult
                                            quantitative={this.state.quantitative}
                                            timeSelect={this.state.timeSelect}
                                            groupSelect={this.state.groupSelect}
                                            totalChild={this.state.totalChild}
                                            listDay={this.state.listDay}
                                            daySelect={this.state.daySelect}
                                        />

                                        <NutritionSetupResult
                                            quantitative={this.state.quantitative}
                                            listTimeSetting={this.state.listTimeSetting}
                                            timeSelect={this.state.timeSelect}
                                            groupSelect={this.state.groupSelect}
                                            ortherFee={this.state.ortherFee}
                                            moneySoonDay={Number(this.state.moneySoonDay)}
                                            totalChild={this.state.totalChild}
                                            listDay={this.state.listDay}
                                            daySelect={this.state.daySelect}
                                        />
                                    </>
                                    : ""
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default PortionCalculate;