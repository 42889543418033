import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment/moment";

class MedicEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.datarole },
      err: {},
    };
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    this.updateEvaluation();
  };

  updateEvaluation = () => {
    const data = {
      dateTime: this.state.dataInput?.date_time,
      description: this.state.dataInput?.description,
    };

    axios
      .put(`medication-reminder-update?id=${this.state.dataInput?.id}`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };

  handleHide = () => {
    return this.props.onHide();
  };

  handleAddTime = () => {
    const newTime = { time: moment().unix() };
    const newTimes = [...this.state.dataInput.description.times, newTime];
    this.setState((prevState) => ({
      dataInput: {
        ...prevState.dataInput,
        description: {
          ...prevState.dataInput.description,
          times: newTimes,
        },
      },
    }));
  };

  handleRemoveTime = (index) => {
    const newTimes = this.state.dataInput.description.times.filter(
      (_, i) => i !== index
    );
    this.setState((prevState) => ({
      dataInput: {
        ...prevState.dataInput,
        description: {
          ...prevState.dataInput.description,
          times: newTimes,
        },
      },
    }));
  };
  convertToTimestamp(timeString, unixTimestamp) {
    const [hours, minutes] = timeString?.split(":").map(Number);

    const date = new Date(unixTimestamp * 1000);

    date.setUTCHours(hours, minutes, 0, 0);

    const newTimestamp = Math.floor(date.getTime() / 1000);

    return newTimestamp;
  }
  handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "date_time") {
      delete this.state.err["dateTime"];
      this.setState((prevState) => ({
        dataInput: {
          ...prevState.dataInput,
          date_time: new Date(value) / 1000,
        },
      }));
    }
    if (name === "time") {
      const newTimes = this.state.dataInput?.description?.times?.map(
        (time, i) =>
          i === index
            ? {
                ...time,
                time: this.convertToTimestamp(
                  value,
                  this.state.dataInput?.date_time
                ),
              }
            : time
      );
      this.setState((prevState) => ({
        dataInput: {
          ...prevState.dataInput,
          description: {
            ...prevState.dataInput?.description,
            times: newTimes,
          },
        },
      }));
    } else if (name === "note") {
      delete this.state.err["note"];

      this.setState((prevState) => ({
        dataInput: {
          ...prevState.dataInput,
          description: {
            ...prevState.dataInput?.description,
            note: value,
          },
        },
      }));
    }
    //  else {
    //   this.setState((prevState) => ({
    //     dataInput: {
    //       ...prevState.dataInput,
    //       [name]: value,
    //     },
    //   }));
    // }
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    const { dataInput } = this.state;

    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chi tiết</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      name="date_time"
                      value={moment
                        .unix(dataInput.date_time)
                        .format("YYYY-MM-DD")}
                      onChange={this.handleChange}
                      // required
                    />
                    <Form.Label>Ngày</Form.Label>
                    <span className="text-form-err">
                      {this.state.err.dateTime}
                    </span>
                  </Form.Floating>
                </Col>{" "}
              </Row>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      // as="textarea"
                      name="note"
                      placeholder="Ghi chú"
                      value={dataInput?.description?.note}
                      onChange={this.handleChange}
                      rows={3}
                      // required
                    />
                    <Form.Label>Ghi chú</Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`description.note`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              {dataInput?.description?.times.map((time, index) => (
                <>
                  <InputGroup className="me-2 mb-2">
                    <InputGroup.Text>Lần {index + 1}</InputGroup.Text>
                    <Form.Control
                      type="time"
                      name="time"
                      className="form-control"
                      value={moment.unix(time.time).format("HH:mm")}
                      // onChange={(e) => {
                      //   this.handleChange(e, index);
                      //   delete this.state.err[
                      //     `description.times[${index}].time`
                      //   ];
                      // }}
                    />{" "}
                    <Button
                      variant="outline-success"
                      onClick={this.handleAddTime}
                      className="mb-2"
                      // hidden={
                      //   dataInput?.description?.times?.length !== index + 1
                      // }
                      hidden
                      title="Thêm"
                    >
                      <i className="fa-solid fa-plus" />
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        this.handleRemoveTime(index);

                        delete this.state.err[
                          `description.times[${index}].time`
                        ];
                      }}
                      hidden
                      disabled={dataInput?.description?.times?.length === 1}
                    >
                      X
                    </Button>{" "}
                  </InputGroup>
                  <span className="text-form-err">
                    {this.state.err[`description.times[${index}].time`]}
                  </span>
                </>
              ))}
              <div className="text-end"></div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button size="sm" variant="success" type="submit" hidden>
                Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default MedicEditModal;
