import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";

class RoleFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissions: [],
            dataInput: { ...this.props.datarole },
            isCheckGroup: [],
        };
    }

    componentDidMount() {
        this.getPermissions();
    }

    getPermissions = () => {
        axios.get("/viewPermission")
            .then((res) => {
                this.setState({ permissions: res.data.data });
            });
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        this.setState({ dataInput: dataInput });
    }

    hanldeCheck = (e) => {
        const { value, checked } = e.target;

        const dataInput = { ...this.state.dataInput };

        dataInput.permission = [...dataInput.permission, value];

        if (!checked) {
            dataInput.permission = dataInput.permission.filter(
                item => item !== value
            );
        }

        this.setState({ dataInput: dataInput });
    }

    handleSelectGroup = (e) => {
        const { value, checked } = e.target;
        const idChildArr = (e.target.dataset.id_child).split(',');

        if (idChildArr.length > 0) {
            const dataInput = { ...this.state.dataInput };

            dataInput.permission = [...dataInput.permission, ...idChildArr];
            this.setState({ dataInput: dataInput });


            if (!checked) {
                idChildArr.map((id, i) => {
                    dataInput.permission = dataInput.permission.filter(item => item !== id);

                    this.setState({ dataInput: dataInput });

                    return id;
                });


            }


        }

        this.setState({ isCheckGroup: [...this.state.isCheckGroup, String(value)] });

        if (!checked) {
            this.setState({ isCheckGroup: this.state.isCheckGroup.filter(item => item !== String(value)) });
        }
    }

    createRole = () => {
        let data = this.state.dataInput;
        axios.post(`/createRole?role_name=${data.role_name}&permission=${data.permission.toString()}`)
            .then((res) => {
                if (res.data.status) {
                    toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                    return this.props.submit();
                } else {
                    toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateRole = () => {
        let data = this.state.dataInput;
        swal({
            title: "Bạn muốn cập nhật chức vụ?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(`/updateRole?role_name=${data.role_name}&id_role=${data.id}&permission=${data.permission.toString()}`)
                    .then((res) => {
                        if (res.data.status) {
                            toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createRole();
        } else {
            this.updateRole();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }

    RenderPermission = () => {
        return (
            <Row>
                <Modal.Title>Quyền truy cập</Modal.Title>

                {this.state.permissions.length > 0 ? this.state.permissions.map((item, index) => {
                    let perChild = [];
                    let idPerChild = [];
                    let checkedParent = false;

                    if (this.state.isCheckGroup.includes(String(item.id))) { checkedParent = true; }

                    if (item.child.length > 0) {
                        let n = 0;
                        item.child.map((itemChild, i) => {
                            idPerChild.push(String(itemChild.id));
                            let checkedChild = this.state.dataInput.permission.includes(String(itemChild.id));

                            n = checkedChild ? n + 1 : n;

                            perChild.push(
                                <Form.Check key={'per-child-' + itemChild.id} className="ms-4" type="checkbox"
                                    label={itemChild.permission_display}
                                    name="permission"
                                    value={itemChild.id}
                                    onChange={this.hanldeCheck}
                                    checked={checkedChild}
                                ></Form.Check>
                            );
                        });

                        if (item.child.length === n) { checkedParent = true; }
                    }

                    return (
                        <Col md={4} className='mt-1' key={'per-parent-' + item.id}>
                            <h5>
                                <Form.Check type="checkbox"
                                    label={item.permission_display}
                                    value={String(item.id)}
                                    data-id_child={idPerChild}
                                    onChange={this.handleSelectGroup}
                                    checked={checkedParent}
                                ></Form.Check>
                            </h5>

                            {perChild}
                        </Col>
                    );
                }) : null}
            </Row>
        );
    }

    render() {
        const { onHide, ...other } = { ...this.props };
        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title ><i className="fa-solid fa-user-tag" /> {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-1">
                            <Form.Control type="text" name="role_name" defaultValue={this.state.dataInput?.role_name || ''} placeholder="Tên chức vụ"
                                onChange={this.handleInput}
                            />
                            <Form.Label>Tên chức vụ<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>

                        {this.RenderPermission()}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit" disabled = {!new Check().permission(['63','64'])}>
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
        );
    }
}

export default RoleFormModal;