import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Modal, Form, Button, Row, Col, Table } from "react-bootstrap";
import swal from "sweetalert";
import { dateFormatter } from "../utils/helpers";
import StudentsPicker from "./StudentsPicker";

const roles = [
  { role: "standard", name: "Cơ bản" },
  { role: "advanced", name: "Nâng cao" },
];

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const ParentAccountEdit = ({ show, onHide, account, studentIdsHadParent }) => {
  const [accountDetail, setAccountDetail] = useState(account);
  const [exclusionStudentIds, setExclusionStudentIds] =
    useState(studentIdsHadParent);
  const [isLoading, setIsLoading] = useState(false);
  const [studentsPickerShow, setStudentsPickerShow] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    phone_number: "",
    full_name: "",
    password: null,
    email: "",
    type: "",
  });

  useEffect(() => {}, []);

  //#region API
  const updateParentAccount = async () => {
    setIsSending(true);
    await axios({
      method: "put",
      url: "parent-account-update",
      data: {
        id: accountDetail.id, // required
        username: accountDetail.username, // required, string 3-50 characters
        email: accountDetail.email, // required
        full_name: accountDetail.full_name, // required, string lte: 50
        is_status: accountDetail.is_status, // required, only accept: active, blocked
        phone_number: accountDetail.phone_number, // required, phone number format
        role: accountDetail.type, // required, only accept: free, paid
        password: accountDetail.password ?? null, // nullable, or required 6-50 character
        confirm_password: accountDetail.confirm_password ?? null, // nullable or required, match with password
        ids_student: accountDetail.children.map((student) => student.id),
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsSending(false);
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        onHide(true);
      })
      .catch((err) => {
        setIsSending(false);
        toast("Thêm thất bại", {
          type: "error",
          autoClose: 1000,
        });
        setErrorMessage(err.response.data.messages);
        console.log(err);
      });
  };
  //#endregion API

  //#region Logic
  const checkInput = () => {
    let hasError = false;
    const errorMessage = {
      code: "",
      name: "",
      id_teacher: "",
      id_subject: "",
      start_date: "",
      end_date: "",
      lesson_time: "",
      lesson_number: "",
    };

    setErrorMessage(errorMessage);
    if (!hasError) {
      updateParentAccount();
    }
  };

  const handleShowStudentsPicker = () => {
    setStudentsPickerShow(true);
  };
  const handleCloseStudentsPicker = (listStudents) => {
    if (listStudents) {
      setExclusionStudentIds([
        ...exclusionStudentIds,
        ...listStudents.map((student) => student.id),
      ]);
      setAccountDetail({
        ...accountDetail,
        children: [...accountDetail.children, ...listStudents],
      });
    }
    setStudentsPickerShow(false);
  };

  const removeChild = (student) => {
    swal({
      title: "Bạn muốn xóa trẻ khỏi tài khoản?",
      text: `${student.student_name}`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        setExclusionStudentIds(
          exclusionStudentIds.filter((item) => item != student.id)
        );
        setAccountDetail({
          ...accountDetail,
          children: [
            ...accountDetail.children.filter((child) => child.id != student.id),
          ],
        });
      }
    });
  };
  //#endregion Logic

  //#region Render
  const RenderChildrenTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr className="align-middle text-center">
              <th style={{ width: "80px" }}>STT</th>
              <th>Tên</th>
              <th>Năm sinh</th>
              <th style={{ width: "120px" }}>Phương thức</th>
            </tr>
          </thead>
          <tbody>{RenderChildren()}</tbody>
        </Table>
      </div>
    );
  };

  const RenderChildren = () => {
    return accountDetail.children?.map((child, index) => {
      return (
        <>
          <tr key={index} className="align-middle">
            <td className="text-center">{index + 1}</td>
            <td>{child.student_name}</td>
            <td className="text-center">
              {dateFormatter(new Date(child.student_birthday * 1000))}
            </td>
            <td className="text-center">
              <Button
                variant="danger"
                size="sm"
                type="button"
                onClick={() => removeChild(child)}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </td>
          </tr>
        </>
      );
    });
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={() => onHide()}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-graduation-cap me-2"></i>
            Cập nhật tài khoản phụ huynh
          </Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body>
            <Row>
              <Col>
                {/* Tên tài khoản */}
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={accountDetail.username}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        username: e.target.value,
                      });
                    }}
                    placeholder="Nhập tên tài khoản"
                    required
                  />
                  <Form.Label>
                    Tên Tài khoản
                    <i className="text-danger">*</i>
                  </Form.Label>

                  {errorMessage.username && (
                    <p style={errorTextStyle}>{errorMessage.username}</p>
                  )}
                </Form.Floating>
              </Col>
              <Col>
                {/* Loại tài khoản */}
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={accountDetail.type}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        type: e.target.value,
                      });
                    }}
                  >
                    {roles.map((item) => {
                      return (
                        <option key={item.role} value={item.role}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Label>
                    Loại tài khoản<i className="text-danger">*</i>
                  </Form.Label>

                  {errorMessage.role && (
                    <p style={errorTextStyle}>{errorMessage.role}</p>
                  )}
                </Form.Floating>
              </Col>
            </Row>
            {/* Tên phụ huynh */}
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={accountDetail.full_name}
                onChange={(e) => {
                  setAccountDetail({
                    ...accountDetail,
                    full_name: e.target.value,
                  });
                }}
                placeholder="Nhập tên phụ huynh"
                required
              />
              <Form.Label>
                Tên phụ huynh
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.full_name && (
                <p style={errorTextStyle}>{errorMessage.full_name}</p>
              )}
            </Form.Floating>
            <Row>
              <Col>
                {/* Số điện thoại */}
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={accountDetail.phone_number}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        phone_number: e.target.value,
                      });
                    }}
                    placeholder="Nhập số điện thoại"
                    required
                  />
                  <Form.Label>
                    Số điện thoại
                    <i className="text-danger">*</i>
                  </Form.Label>

                  {errorMessage.phone_number && (
                    <p style={errorTextStyle}>{errorMessage.phone_number}</p>
                  )}
                </Form.Floating>
              </Col>
              <Col>
                {/* Email */}
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={accountDetail.email}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        email: e.target.value,
                      });
                    }}
                    placeholder="Nhập email để khôi phục mật khẩu"
                    required
                  />
                  <Form.Label>Email</Form.Label>

                  {errorMessage.email && (
                    <p style={errorTextStyle}>{errorMessage.email}</p>
                  )}
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* Mật khẩu */}
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="password"
                    value={accountDetail.password}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        password: e.target.value,
                      });
                    }}
                    placeholder="Nhập lại mật khẩu"
                    required
                  />
                  <Form.Label>Mật khẩu</Form.Label>

                  {errorMessage.password && (
                    <p style={errorTextStyle}>{errorMessage.password}</p>
                  )}
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="password"
                    value={accountDetail.confirm_password}
                    onChange={(e) => {
                      setAccountDetail({
                        ...accountDetail,
                        confirm_password: e.target.value,
                      });
                    }}
                    placeholder="Nhập mật khẩu"
                    required
                  />
                  <Form.Label>Xác nhận mật khẩu</Form.Label>

                  {errorMessage.confirm_password && (
                    <p style={errorTextStyle}>
                      {errorMessage.confirm_password}
                    </p>
                  )}
                </Form.Floating>
              </Col>
            </Row>
            <Form.Check
              className="mb-3"
              type="switch"
              label={
                accountDetail.is_status === "active"
                  ? "Tài khoản đang hoạt động"
                  : "Tài khoản đã bị khóa"
              }
              checked={accountDetail.is_status === "active"}
              onChange={(e) => {
                setAccountDetail({
                  ...accountDetail,
                  is_status: e.target.checked ? "active" : "blocked",
                });
              }}
              placeholder="Nhập tên tài khoản"
            />
            <Form.Label>
              <strong>Danh sách các con</strong>
            </Form.Label>
            <Button
              size="sm"
              variant="link fst-italic float-end"
              onClick={() => handleShowStudentsPicker()}
            >
              +Thêm con
            </Button>
            <RenderChildrenTable />
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>

          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => checkInput()}
            disabled={isLoading || isSending}
          >
            <i className="fa-solid fa-check"></i> Lưu
          </Button>
        </Modal.Footer>
      </Modal>

      {studentsPickerShow && (
        <StudentsPicker
          show={studentsPickerShow}
          onClose={handleCloseStudentsPicker}
          exclusionStudentIds={exclusionStudentIds}
        />
      )}
    </>
  );
  //#endregion Render
};

export default ParentAccountEdit;
