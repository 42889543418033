import axios from "axios";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FCMService from "../../../fcm/service";

const NotificationSwitch = ({ topic, isSubscribed, onSwitchChange }) => {
  const handleSwitchChange = async (event) => {
    const checked = event.target.checked;

    if (checked) {
      onSwitchChange(topic, checked);
      return await subTopic(topic);
    }

    onSwitchChange(topic, checked);
    return await unSubTopic(topic);
  };

  const subTopic = async (topic) => {
    try {
      const token = await FCMService.getFCMToken();

      console.log('token>>', token);

      const data = { token, topic };

      const response = await axios({
        method: "post",
        url: "/sub",
        data,
        headers: { "Content-Type": "application/json" },
      });

      console.log("check response from api: ", response.data.message);
      toast.success("Cập nhật thành công");
    } catch (error) {
      toast.error("Lỗi trong quá trình đăng ký nhận thông báo");
    }
  };

  const unSubTopic = async (topic) => {
    try {
      const token = await FCMService.getFCMToken();
      const data = { token, topic };

      console.log('token>>', token);

      const response = await axios({
        method: "post",
        url: "/un-sub",
        data,
        headers: { "Content-Type": "application/json" },
      });

      console.log("check response from api: ", response.data.message);
      toast.success("Cập nhật thành công");
    } catch (error) {
      toast.error("Lỗi trong quá trình hủy đăng ký nhận thông báo");
    }
  };

  return (
    <Form>
      <Form.Check
        type="switch"
        id={`notification-switch-${topic}`}
        checked={isSubscribed}
        onChange={handleSwitchChange}
      />
    </Form>
  );
};

export default NotificationSwitch;
