import React, { useEffect, useState } from "react";
import axios from "axios";
import { Breadcrumb, Button, Card, Container, Form, InputGroup, Modal, Table } from "react-bootstrap";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

export default function MenuWeek() {
    const [start, setStartDate] = useState(new Date());
    const [term, setTermDate] = useState(new Date());
    const [weekInMonth, setWeekInMonth] = useState("");
    const [dayDatas, setDayDatas] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState({
        id_day: '',
        day_name: '',
        id_time: '',
        time_name: '',
        foods: []
    });

    const [menuData, setMenuData] = useState([]);
    const [foodOptions, setFoodOptions] = useState([]);
    const [groupDatas, setGroupDatas] = useState([]);
    const [timeDatas, setTimeDatas] = useState([]);

    useEffect(() => {
        document.title = "Thực đơn tuần";
        const week = setDate(new Date());
        getSettingData(start);
        getFoodData();
        getClassGroupData();
        getMenuData(week[0], week[6]);
    }, []);

    const getSettingData = (startDate) => {
        axios
            .get(`setting-show?setting_date=${parseInt(Math.floor(startDate.setHours(0, 0, 0, 0)) / 1000)}`)
            .then((res) => {
                // console.log(res.data);
                // if (res.data.status) {
                //     setlistday(res.data.data.day);
                //     setlisttime(res.data.data.time);

                //     getMenuData(type, res.data.data.day, startDay, endDay);
                // }

                let initialDate = new Date();
                let initialWeek = setDate(initialDate);

                setWeekInMonth((0 | initialWeek[0].getDate() / 7) + 1);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getMenuData = (startDate, endDate) => {
        axios
            .get(`menu-show?start_at=${parseInt(Math.floor(startDate.setHours(0, 0, 0, 0)) / 1000)}&term_at=${parseInt(Math.floor(endDate.setHours(0, 0, 0, 0)) / 1000)}`)
            .then((res) => {
                console.log(res.data);
                if (res.data.errCode === 0) {
                    setMenuData(res.data.menu);
                    setDayDatas(res.data.days);
                    setTimeDatas(res.data.times)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getFoodData = () => {
        axios.get(`/food-show`).then((res) => {
            if (res.data.errCode === 0) {
                let foods = [];

                res.data.foods.map(food => {
                    foods.push({
                        value: food.id,
                        label: food.food_name,
                    });

                    return food
                })

                setFoodOptions(foods);
            }
        });
    }

    const getClassGroupData = () => {
        axios
            .get(`/group-show`)
            .then((res) => {
                if (res.data.errCode === 0) {
                    setGroupDatas(res.data.groups);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    const setDate = (theDate) => {
        const week = dates(theDate);

        setStartDate(week[0]);
        setTermDate(week[week.length - 1]);
        return week;
    }

    const stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

    const eventModal = (data) => {
        setDataModal({
            id_day: data.id_day,
            day_name: data.day_name,
            id_time: data.id_time,
            time_name: data.time_name,
            foods: data.foods
        });

        setShowModal(true);
    };

    // const handleSaveDataModal = () => {
    //     const indexOfDay = dayDatas.findIndex(day => day.id_day === dataModal.id_day);
    //     let day = dayDatas[indexOfDay];
    //     const indexOfTime = day.times.findIndex(time => time.id_time === dataModal.id_time);
    //     setDayDatas(dayDatas);
    // }

    const handleUpdateMenu = () => {
        const daysArrayString = JSON.stringify(dayDatas);
        console.log('menuData>', menuData);

        let formData = new FormData();
        formData.append('id_menu', menuData.id_menu);
        formData.append('start_at', menuData.start_at);
        formData.append('term_at', menuData.term_at);
        formData.append('days', daysArrayString);

        axios.put(`/menu-update`, formData)
            .then((res) => {
                if (res.data.errCode === 0) {
                    const week = setDate(new Date());
                    getMenuData(week[0], week[6]);
                    setDayDatas([]);
                    toast(res.data.message, { type: "success", autoClose: 1000 });
                } else {
                    toast('Cập nhật thất bại', { type: "error", autoClose: 1000 });
                }
            });
    }

    const handleUnSavedMealDaily = (id_menu_day) => {
        const daysArrayString = JSON.stringify(dayDatas);
        let formData = new FormData();
        formData.append('id_menu', menuData.id_menu);
        formData.append('start_at', menuData.start_at);
        formData.append('term_at', menuData.term_at);
        formData.append('days', daysArrayString);

        axios.get(`/meals-daily-unsaved?id_menu_day=${id_menu_day}`)
            .then((res) => {
                if (res.data.errCode === 0) {
                    const week = setDate(new Date());
                    getMenuData(week[0], week[6]);
                    setDayDatas([]);
                    toast(res.data.message, { type: "success", autoClose: 1000 });
                } else {
                    toast('Cập nhật thất bại', { type: "error", autoClose: 1000 });
                }
            });
    }


    return (
        <>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Thực đơn tuần</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-md-flex justify-content-between">
                            <div className="d-block d-md-flex">
                                <Card.Title className="me-3 pt-0 pt-sm-1">
                                    Thực đơn Tháng
                                    {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
                                </Card.Title>
                            </div>

                            <Button variant="success" onClick={() => handleUpdateMenu()}>Lưu thực đơn</Button>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <Table striped bordered size="sm" style={{ minWidth: 999 }}>
                            <thead>

                                <tr className="text-center">
                                    {dayDatas.length > 0 ? <>
                                        <th>Buổi</th>
                                        {dayDatas.map((day, index) => {
                                            return <th key={`thCard${index}`} >
                                                {day.day_name}

                                                {day.is_meal_saved === 1
                                                    ? <Button onClick={() => handleUnSavedMealDaily(day.id)} className="ms-2" variant="outline-danger" size="sm" title="Hủy tính KPA">X</Button>
                                                    : <></>
                                                }
                                            </th>
                                        })}
                                    </>
                                        : <th>Đang tải...</th>
                                    }
                                </tr>

                            </thead>
                            <tbody>
                                {timeDatas.length > 0 && dayDatas.length > 0
                                    ?
                                    timeDatas.map((time, timeIndex) => {
                                        return <tr key={`trCard${timeIndex}`}>
                                            <th className="text-center">{time.time_name}</th>

                                            {dayDatas.length > 0
                                                ?
                                                dayDatas.map((day, dayIndex) => {
                                                    let timesWithDay = day.times.filter(timeItem => timeItem.id_time === time.id_time);
                                                    return <td key={`tdCard${timeIndex}${dayIndex}`}
                                                        onClick={() => {
                                                            if (day.is_meal_saved === 0)
                                                                eventModal({
                                                                    id_day: day.id_day,
                                                                    day_name: day.day_name,
                                                                    id_time: time.id_time,
                                                                    time_name: time.time_name,
                                                                    foods: timesWithDay[0].foods
                                                                });
                                                            else
                                                                toast('Đã lưu Tính khẩu phần ăn.', { type: "error", autoClose: 1000 });
                                                        }}
                                                    >
                                                        {timesWithDay[0].foods.length > 0
                                                            ? timesWithDay[0]?.foods.map((food, foodIndex) => {
                                                                const countGroup = food.groups.length
                                                                const countEmpty = countGroup - food.groups.filter(String).length;
                                                                const groupUse = food.groups.filter(item => item.id > 0);

                                                                return <>
                                                                    <div key={`childTdCard${timeIndex}${dayIndex}${foodIndex}`}>
                                                                        <strong>{food.food_name}</strong>
                                                                        {countGroup - countEmpty === 1
                                                                            ?
                                                                            ` (${groupUse[0].group_name}${Number(groupUse[0].more_meal) > 0 ? '; SL: ' + groupUse[0].more_meal : ''})`
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                </>
                                                            })
                                                            : <></>
                                                        }
                                                    </td>
                                                })
                                                : <></>
                                            }
                                        </tr>
                                    })
                                    : <></>
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal
                    show={showModal}
                    size={"lg"}
                    onHide={() => { setShowModal(false) }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{dataModal.day_name} - Buổi {dataModal.time_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Món ăn</th>
                                    {groupDatas?.map((group, index) => {
                                        return <th key={`thModal${index}`}>{group.group_name}</th>
                                    })}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataModal.foods.map((food, keyFood) => {
                                        return (
                                            <tr key={`trModal${keyFood}`}>
                                                <td>{food.food_name}</td>

                                                {groupDatas?.map((group, keyGroup) => {
                                                    let groupWithFood = [];
                                                    let keyGroupWithFood = -1;
                                                    food?.groups.map((groupOfFood, iGroupOfFood) => {
                                                        if (Number(groupOfFood.id) === group.id) {
                                                            groupWithFood[iGroupOfFood] = groupOfFood;
                                                            keyGroupWithFood = iGroupOfFood;
                                                        }

                                                        return groupOfFood;
                                                    });

                                                    const groupUse = food.groups.filter(item => item.id > 0);

                                                    // let more_meal = food.groups[keyGroup] ? food.groups[keyGroup].more_meal : '';

                                                    let more_meal = groupWithFood.length > 0 ? groupWithFood[keyGroupWithFood].more_meal : '';

                                                    return (
                                                        <td key={`tdModal${keyFood}${keyGroup}`}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={food.groups?.some(item => Number(item.id) === Number(group.id))}
                                                                value={Number(group.id)}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        food.groups[keyGroup] = {
                                                                            id: group.id,
                                                                            group_name: group.group_name,
                                                                            more_meal: '',
                                                                            ingredents: []
                                                                        }
                                                                    } else {
                                                                        delete (food.groups[keyGroup])
                                                                    }

                                                                    dataModal.foods[keyFood] = food;
                                                                    setDataModal({ ...dataModal });

                                                                    if (groupUse.length === food.groups.length) {
                                                                        food.groups.map((groupUpdate, indexUpdate) => {
                                                                            food.groups[indexUpdate].more_meal = '';
                                                                            dataModal.foods[keyFood] = food;
                                                                            setDataModal({ ...dataModal });

                                                                            return groupUpdate
                                                                        })
                                                                    }
                                                                }}
                                                            />

                                                            {groupWithFood.length > 0 && groupUse.length === 1 ?
                                                                <CurrencyFormat
                                                                    style={{ width: 69, height: 27 }}
                                                                    className="form-control text-end"
                                                                    thousandSeparator
                                                                    value={more_meal === null ? '' : more_meal}
                                                                    allowNegative={false}
                                                                    onValueChange={(values) => {
                                                                        dataModal.foods[keyFood].groups[keyGroupWithFood].more_meal = values.value;
                                                                        setDataModal({ ...dataModal });
                                                                    }}
                                                                />
                                                                : <></>
                                                            }
                                                        </td>
                                                    )
                                                })}

                                                <td>
                                                    <Button variant="outline-danger" size="sm"
                                                        onClick={() => {
                                                            delete (dataModal.foods[keyFood])
                                                            setDataModal({ ...dataModal });
                                                        }}
                                                    >
                                                        X
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>

                        <InputGroup >
                            <InputGroup.Text>Chọn món<i className="text-danger">*</i></InputGroup.Text>

                            <Select
                                options={foodOptions.filter(foodData => !dataModal.foods.some(food => food.id === foodData.value))}
                                placeholder={<div>Nhập tên món ăn</div>}
                                onChange={(choice) => {
                                    let foods = dataModal.foods;
                                    let groups = [];

                                    groupDatas.map(group => {
                                        groups.push({
                                            id: group.id,
                                            group_name: group.group_name,
                                            more_meal: '',
                                            ingredents: []
                                        });
                                        return group
                                    })
                                    // let groups = groupDatas;

                                    if (!foods.some(food => Number(food.id) === Number(choice.value))) {
                                        foods.push({
                                            id: choice.value,
                                            food_name: choice.label,
                                            priority: 1,
                                            groups: groups
                                        });

                                        dataModal.foods = foods;

                                        setDataModal({ ...dataModal });
                                    } else {
                                        toast("Bạn đã chọn món ăn này rồi", {
                                            type: "error",
                                            autoClose: 1000,
                                        });
                                    }
                                }}
                            />

                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button
                            size="sm"
                            type="submit"
                            variant="success"
                            onClick={() => handleSaveDataModal()}
                        >
                            Thêm
                        </Button> */}

                        <Button
                            size="sm"
                            type="submit"
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Đóng
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        </>
    );
}