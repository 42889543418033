import React, { Component } from "react";
import axios from "axios";
import { Button, Breadcrumb, Card, Container, Table, Tabs, Tab, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select';
import Check from "../../other/Check";
import vi from "date-fns/locale/vi";
import swal from "sweetalert";
registerLocale('vi', vi)

const classifyOptions = [
    {
        label: "Tất cả",
        value: 3
    },
    {
        label: "Học sinh",
        value: 1
    },
    {
        label: "Nhân viên",
        value: 2
    },
]

class MarketListReportIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolInfo: {},
            classifies: [],

            showMarketList: [],
            detailMarket: {
                market_date: 0,
                ingredent: []
            },
            isLoading: false,

            selectedTab: 0,

            storeMaterialList: [],

            selectedClassify: {
                label: "Tất cả",
                value: 3
            },

            startDate: new Date(),
            endDate: new Date(),
            tmpStartDate: new Date(),
            tmpEndDate: new Date(),

            modalShow: false,
            show: false,
        };
    }

    async componentDidMount() {
        document.title = 'Báo cáo chi phí';
        this.getDetailschool();

        const date = new Date();

        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        start.setHours(0, 0, 0, 0);

        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        end.setHours(23, 59, 59, 0);

        this.setState({
            startDate: start,
            endDate: end,

            tmpStartDate: start,
            tmpEndDate: end,
        });

        this.getListVoucher(start, end);
    }

    //#region Fetch Api
    getDetailschool = () => {
        axios.get(`/detailSchool`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        schoolInfo: res.data.data[0]
                    })
                }
            });
    }

    getListVoucher = (startDate, endDate) => {
        this.setState({
            isLoading: true
        })

        axios.get(`/viewVoucher?type_voucher=1&start_at=${startDate.getTime() / 1000}&term_at=${endDate.getTime() / 1000}`)
            .then(res => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status === true) {
                    this.setState({
                        showMarketList: res.data.data
                    })
                }
            });
    }
    //#endregion

    //#region Handle Logic
    xport = () => {
        const wb = XLSX.utils.book_new();

        const table = document.getElementById("market-list-detail");

        var ws = XLSX.utils.table_to_sheet(table)

        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];

        colAlpha.map(alpha => {
            ws[`${alpha}1`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true
                },
                alignment: {
                    vertical: "top",
                    horizontal: "left"
                },
            };

            return alpha;
        })

        colAlpha.map(alpha => {
            ws[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                    sz: 16,
                    bold: true
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
            };

            return alpha;
        })

        colAlpha.map(alpha => {
            if (ws[`${alpha}3`].v === "empty") {
                ws[`${alpha}3`].v = ' '
            }

            ws[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        // Format number Price, Unit, Will Money
        const numberFormat = "#,###,###,###";
        let index = 5;

        this.state.detailMarket.ingredent?.filter(item => Number(item.ingredent_type) === 1).map(() => {
            let priceCol = `E${index}`;
            let unitCol = `F${index}`;
            let willMoneyCol = `G${index}`;

            colAlpha.map(alpha => {

                if (ws[`${alpha}${index}`].v === "empty") {
                    ws[`${alpha}${index}`].v = ' '
                }

                ws[`${alpha}${index}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            ws[priceCol.toString()].z = numberFormat;
            delete ws[priceCol.toString()].w;
            XLSX.utils.format_cell(ws[priceCol.toString()]);

            ws[unitCol.toString()].z = "#,###,###,##0.00";
            delete ws[unitCol.toString()].w;
            XLSX.utils.format_cell(ws[unitCol.toString()]);

            ws[willMoneyCol.toString()].z = numberFormat;
            delete ws[willMoneyCol.toString()].w;
            XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

            index += 1;

            return "";
        });

        if (this.state.detailMarket.ingredent?.filter(item => Number(item.ingredent_type) === 1).length !== 0) {
            ws[`G${index}`].z = numberFormat;
            delete ws[`G${index}`].w;
            XLSX.utils.format_cell(ws[`G${index}`]);

            colAlpha.map(alpha => {
                if (ws[`${alpha}${index}`].v === "empty") {
                    ws[`${alpha}${index}`].v = ' '
                }

                ws[`${alpha}${4 + this.state.detailMarket.ingredent.filter(item => Number(item.ingredent_type) === 1).length + 1}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center"
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            ws[`B${4 + this.state.detailMarket.ingredent.filter(item => Number(item.ingredent_type) === 1).length + 1}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws[`G${4 + this.state.detailMarket.ingredent.filter(item => Number(item.ingredent_type) === 1).length + 1}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
        }

        index++;

        this.state.detailMarket.ingredent?.filter(item => Number(item.ingredent_type) === 2).map(() => {
            let priceCol = `E${index}`;
            let unitCol = `F${index}`;
            let willMoneyCol = `G${index}`;

            colAlpha.map(alpha => {

                if (ws[`${alpha}${index}`].v === "empty") {
                    ws[`${alpha}${index}`].v = ' '
                }

                ws[`${alpha}${index}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            ws[priceCol.toString()].z = numberFormat;
            delete ws[priceCol.toString()].w;
            XLSX.utils.format_cell(ws[priceCol.toString()]);

            ws[unitCol.toString()].z = "#,###,###,##0.00";
            delete ws[unitCol.toString()].w;
            XLSX.utils.format_cell(ws[unitCol.toString()]);

            ws[willMoneyCol.toString()].z = numberFormat;
            delete ws[willMoneyCol.toString()].w;
            XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

            index += 1;

            return "";
        });

        if (this.state.detailMarket.ingredent?.filter(item => Number(item.ingredent_type) === 2).length !== 0) {
            ws[`G${index}`].z = numberFormat;
            delete ws[`G${index}`].w;
            XLSX.utils.format_cell(ws[`G${index}`]);

            colAlpha.map(alpha => {
                if (ws[`${alpha}${index}`].v === "empty") {
                    ws[`${alpha}${index}`].v = ' '
                }

                ws[`${alpha}${index}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center"
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            ws[`B${index}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws[`G${index}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
        }

        // Set Column Width
        var wscols = [
            { wch: 3.5 },
            { wch: 20 },
            { wch: 20 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 15 },
            { wch: 10 },
        ];

        ws['!cols'] = wscols;
        ws['!rows'] = [{ hpt: 20 }, { hpt: 35 }, {}, { hpt: 40 }]

        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
        ];
        ws["!merges"] = merge;

        XLSX.utils.book_append_sheet(wb, ws, "Đi chợ");

        XLSX.writeFile(wb, "Danh sách đi chợ.xlsx");
    };

    stringDate = (dateNumber) => {
        const date = new Date(dateNumber * 1000);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    }

    groupArrayBy = (arr, key) => {
        return [...arr.reduce((acc, o) =>
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
            , new Map()).values()];
    }
    //#endregion
    deleteVoucher=(item)=>{
        swal({
            title: "Bạn muốn xóa phiếu đi chợ?",
            text: `Ngày đi chợ ${this.stringDate(item.market_date)}`,
            icon: "error",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
          }).then((ok) => {
            if (ok) {
                axios.post(`/deletevoucher?id=${item.id}`)
                .then(res => {
                    if (res.data.status === true) {
                        this.getListVoucher(this.state.startDate,this.state.endDate)
                    }
                });
             }})
      
    }
    // Render
    RenderMarketList() {
        let totalMoney = 0;

        let showMarketList = Number(this.state.selectedClassify.value) === 3 ? this.state.showMarketList : this.state.showMarketList.filter(item => Number(item.id_classify) === Number(this.state.selectedClassify.value));

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table style={{ minWidth: 777 }}>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Ngày đi chợ</th>
                            <th>Ngày lưu</th>
                            {
                                Number(this.state.selectedClassify.value) === 3
                                    ?
                                    <th>Nhóm</th>
                                    : ""
                            }
                            <th className="text-end">Tổng tiền</th>
                            <th>Xem chi tiết</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            showMarketList.length !== 0
                                ?
                                <>
                                    {showMarketList.sort(function (a, b) {
                                        return b.market_date - a.market_date;
                                    }).map((item, index) => {

                                        totalMoney += Number(item.voucher_price);

                                        return (
                                            <tr key={index + 1} className="text-center">
                                                <td>{index + 1}</td>
                                                <td>{this.stringDate(item.market_date)}</td>
                                                <td>{this.stringDate(item.created_at)}</td>
                                                {
                                                    Number(this.state.selectedClassify.value) === 3
                                                        ?
                                                        <td>{classifyOptions?.filter(classify => Number(classify.value) === Number(item.id_classify))[0]?.label}</td>
                                                        : ""
                                                }
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(item.voucher_price).toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td>

                                                    <Button size='sm'
                                                        onClick={() => {
                                                            this.setState({
                                                                modalShow: true,
                                                                detailMarket: item
                                                            })
                                                        }}
                                                    >
                                                        Chi tiết
                                                    </Button>
                                                    {
                                                        new Check().permission(['96'])
                                                            ?
                                                             <Button size='sm' variant="danger" title="Xóa phiếu đi chợ"
                                                                onClick={() => {
                                                                  this.deleteVoucher(item)
                                                                }}
                                                                className="ms-1"
                                                            >
                                                                Xóa
                                                            </Button> 
                                                            : null
                                                            }
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    <tr>
                                        <th></th>
                                        <th className="text-center">Tổng cộng</th>
                                        <th></th>
                                        <th className="text-end">
                                            <CurrencyFormat
                                                value={totalMoney.toFixed(0)}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                displayType="text"
                                            />
                                        </th>
                                        <th colSpan={Number(this.state.selectedClassify.value) === 3 ? 2 : 1}></th>
                                    </tr>
                                </>
                                :
                                <tr className="text-center">
                                    <td colSpan={Number(this.state.selectedClassify.value) === 3 ? 6 : 5}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    RenderDetailMarketModal() {
        let marketWillMoney = 0;
        let storeWillMoney = 0;
        let ingredientIndex = 0;
        let ingredientExIndex = 0;

        return (
            <Modal
                show={this.state.modalShow}
                onHide={() => this.setState({
                    modalShow: false
                })}
                size="xl"

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Chi tiết phiếu đi chợ
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div></div>


                    </div>

                    <div style={{ overflowX: "scroll" }}>
                        <Table striped bordered style={{ minWidth: 999 }}>
                            <thead>
                                <tr>
                                    <th colSpan="7">
                                        {this.state.schoolInfo.school_name?.toUpperCase()}
                                    </th>
                                    <th>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => this.xport()}

                                        >
                                            Xuất file <i className="fa-solid fa-file-export"></i>
                                        </Button>
                                    </th>
                                </tr>
                                <tr className="text-center">
                                    <th colSpan="8">
                                        {`Tổng hợp định lượng đi chợ ngày ${this.stringDate(this.state.detailMarket.market_date)}`.toUpperCase()}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="8" className="d-none"></th>
                                </tr>
                                <tr className="text-center">
                                    <th>STT</th>
                                    <th>NGUYÊN LIỆU</th>
                                    <th>NHÀ CUNG CẤP</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                    <th>SỐ LƯỢNG</th>
                                    <th>THÀNH TIỀN DỰ KIẾN</th>
                                    <th>GHI CHÚ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.detailMarket?.ingredent
                                        ?.filter(item => Number(item.ingredent_type) === 1)
                                        .map((item, index) => {

                                            marketWillMoney += (Number(item.ingredent_price) * Number(item.count).toFixed(2));
                                            ingredientIndex -= -1;

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{ingredientIndex}</td>
                                                    <td>{item.ingredent_name}</td>
                                                    <td>{item.supplier_name}</td>
                                                    <td>{item.unit_name}</td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={Number(item.ingredent_price)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={Number(item.count).toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={(Number(item.ingredent_price) * Number(item.count).toFixed(2)).toFixed(0)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                        />
                                                    </td>
                                                    <td>{item.note}</td>
                                                </tr>
                                            );
                                        })}

                                <tr>
                                    <th></th>
                                    <th className="text-center">TỔNG CỘNG</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-end">
                                        <CurrencyFormat
                                            value={marketWillMoney.toFixed(0)}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </th>
                                    <th></th>
                                </tr>

                                {
                                    this.state.detailMarket?.ingredent
                                        ?.filter(item => Number(item.ingredent_type) === 2)
                                        .map((item, index) => {

                                            storeWillMoney += (Number(item.ingredent_price) * Number(item.count).toFixed(2));
                                            ingredientIndex -= -1;

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{ingredientIndex}</td>
                                                    <td>{item.ingredent_name} (kho)</td>
                                                    <td>{item.supplier_name}</td>
                                                    <td>{item.unit_name}</td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={Number(item.ingredent_price)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={Number(item.count).toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={(Number(item.ingredent_price) * Number(item.count).toFixed(2)).toFixed(0)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                        />
                                                    </td>
                                                    <td>{item.note}</td>
                                                </tr>
                                            );
                                        })}

                                <tr>
                                    <th></th>
                                    <th className="text-center">TỔNG CỘNG</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-end">
                                        <CurrencyFormat
                                            value={storeWillMoney.toFixed(0)}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </th>
                                    <th></th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <Table striped bordered className="d-none" style={{ minWidth: 999 }} id="market-list-detail">
                        <thead>
                            <tr>
                                <th>
                                    {this.state.schoolInfo.school_name?.toUpperCase()}
                                </th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                            </tr>
                            <tr className="text-center">
                                <th>
                                    {`Tổng hợp định lượng đi chợ ngày ${this.stringDate(this.state.detailMarket.market_date)}`.toUpperCase()}
                                </th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                            </tr>
                            <tr>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                            </tr>
                            <tr className="text-center">
                                <th>STT</th>
                                <th>NGUYÊN LIỆU</th>
                                <th>NHÀ CUNG CẤP</th>
                                <th>ĐƠN VỊ TÍNH</th>
                                <th>ĐƠN GIÁ</th>
                                <th>SỐ LƯỢNG</th>
                                <th>THÀNH TIỀN DỰ KIẾN</th>
                                <th>GHI CHÚ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.detailMarket?.ingredent
                                    ?.filter(item => Number(item.ingredent_type) === 1)
                                    .map((item, index) => {

                                        ingredientExIndex -= -1;

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{ingredientExIndex}</td>
                                                <td>{item.ingredent_name}</td>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(item.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(item.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(item.ingredent_price) * Number(item.count).toFixed(2)).toFixed(0)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </td>
                                                <td>{item.note ? item.note : "empty"}</td>
                                            </tr>
                                        );
                                    })}

                            <tr>
                                <th>empty</th>
                                <th className="text-center">TỔNG CỘNG</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th className="text-end">
                                    <CurrencyFormat
                                        value={marketWillMoney.toFixed(0)}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                </th>
                                <th>empty</th>
                            </tr>

                            {
                                this.state.detailMarket?.ingredent
                                    ?.filter(item => Number(item.ingredent_type) === 2)
                                    .map((item, index) => {
                                        ingredientExIndex -= -1;

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{ingredientExIndex}</td>
                                                <td>{item.ingredent_name} (kho)</td>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(item.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(item.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(item.ingredent_price) * Number(item.count).toFixed(2)).toFixed(0)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </td>
                                                <td>{item.note ? item.note : "empty"}</td>
                                            </tr>
                                        );
                                    })
                            }

                            <tr>
                                <th>empty</th>
                                <th className="text-center">TỔNG CỘNG</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th>empty</th>
                                <th className="text-end">
                                    <CurrencyFormat
                                        value={storeWillMoney.toFixed(0)}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                </th>
                                <th>empty</th>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        )
    }

    RenderMarketIngredientPrice() {
        let ingredientList = [];
        let showMarketList = Number(this.state.selectedClassify.value) === 3 ? this.state.showMarketList : this.state.showMarketList.filter(item => Number(item.id_classify) === Number(this.state.selectedClassify.value));

        showMarketList.map(item => {
            ingredientList = ingredientList.concat(item.ingredent);
            return item;
        })

        const ingredientTotalData = this.groupArrayBy(ingredientList.filter(item => Number(item.ingredent_type) === 1), "id");
        const ingredientTotalDataT = this.groupArrayBy(ingredientList.filter(item => Number(item.ingredent_type) === 2), "id");

        let totalMarketMoney = 0;
        const showMarketIngredient = [];

        ingredientTotalData.map(item => {
            let itemMoney = 0;
            let itemAmount = 0;

            item.map(obj => {
                itemMoney += (Number(obj.ingredent_price) * Number(obj.count).toFixed(2));
                itemAmount += Number(obj.count);

                return obj;
            })

            totalMarketMoney += itemMoney;

            showMarketIngredient.push({
                ingredientName: item[0].ingredent_name,
                unitName: item[0].unit_name,
                ingredientMoney: itemMoney,
                ingredientAmount: itemAmount
            })

            return item;
        })

        let totalStoreMoney = 0;
        const showStoreIngredient = [];

        ingredientTotalDataT.map(item => {
            let itemMoney = 0;
            let itemAmount = 0;

            item.map(obj => {
                itemMoney += (Number(obj.ingredent_price) * Number(obj.count).toFixed(2));
                itemAmount += Number(obj.count);

                return obj;
            })

            totalStoreMoney += itemMoney;

            showStoreIngredient.push({
                ingredientName: item[0].ingredent_name,
                unitName: item[0].unit_name,
                ingredientMoney: itemMoney,
                ingredientAmount: itemAmount
            })

            return item;
        })

        return (
            <div className="d-flex justify-content-center">
                <Table>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th className="text-start">Tên nguyên liệu</th>
                            <th className="text-start">Đơn vị tính</th>
                            <th className="text-end">Số lượng</th>
                            <th className="text-end">Tổng tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            showMarketIngredient.length !== 0 && showStoreIngredient.length !== 0
                                ?
                                <>
                                    {showMarketIngredient
                                        .sort(function (a, b) {
                                            return b.ingredientAmount - a.ingredientAmount;
                                        })
                                        .map((item, index) => {

                                            return (
                                                <tr key={index + 1}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.ingredientName}</td>
                                                    <td className="text-start">{item.unitName}</td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={item.ingredientAmount.toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={item.ingredientMoney.toFixed(0)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <th></th>
                                        <th className="text-center">Tổng cộng</th>
                                        <th></th>
                                        <th></th>
                                        <th className="text-end">
                                            <CurrencyFormat
                                                value={totalMarketMoney.toFixed(0)}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                isNumericString
                                                displayType="text"
                                            />
                                        </th>
                                    </tr>

                                    {showStoreIngredient
                                        .sort(function (a, b) {
                                            return b.ingredientAmount - a.ingredientAmount;
                                        })
                                        .map((item, index) => {

                                            return (
                                                <tr key={index + 1}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.ingredientName} (kho)</td>
                                                    <td className="text-start">{item.unitName}</td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={item.ingredientAmount.toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={item.ingredientMoney.toFixed(0)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            isNumericString
                                                            displayType="text"
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <th></th>
                                        <th className="text-center">Tổng cộng</th>
                                        <th></th>
                                        <th></th>
                                        <th className="text-end">
                                            <CurrencyFormat
                                                value={totalStoreMoney.toFixed(0)}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                isNumericString
                                                displayType="text"
                                            />
                                        </th>
                                    </tr>
                                </>
                                :
                                <tr className="text-center">
                                    <td colSpan="5">Không có dữ liệu</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Chi phí nguyên liệu
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header className="d-block d-md-flex">
                        <Card.Title>
                            <i className="fas fa-list me-1" />
                            Báo cáo chi phí nguyên liệu {this.stringDate(this.state.startDate / 1000)} - {this.stringDate(this.state.endDate / 1000)}
                        </Card.Title>

                        <Button
                            size="sm"
                            className="ms-2"
                            onClick={() => { this.setState({ show: true }) }}
                        >
                            <i className="fa-solid fa-calendar-days" />
                        </Button>
                    </Card.Header>

                    <Card.Body>
                        {
                            !this.state.isLoading
                                ?
                                <>
                                    <div
                                        className="d-flex justify-content-between mt-3 mt-sm-0"
                                    >
                                        <div></div>

                                        <Select
                                            options={classifyOptions}
                                            placeholder={<div>Tất cả</div>}
                                            value={this.state.selectedClassify}
                                            onChange={(choice) => {
                                                this.setState({
                                                    selectedClassify: choice
                                                })
                                            }}
                                            isDisabled={this.state.selectedTab === 2 || this.state.selectedTab === 3}
                                        />
                                    </div>

                                    <Tabs
                                        unmountOnExit={true}
                                        transition={false}
                                        onSelect={(key) => {
                                            this.setState({
                                                selectedTab: Number(key)
                                            })
                                        }}
                                        activeKey={this.state.selectedTab}
                                        className="mb-3"
                                    >
                                        <Tab
                                            key={0}
                                            title="Phiếu đi chợ hằng ngày"
                                            eventKey={0}
                                        >
                                            {this.RenderMarketList()}
                                        </Tab>

                                        <Tab
                                            key={1}
                                            title="Nguyên liệu chế biến"
                                            eventKey={1}
                                        >
                                            {this.RenderMarketIngredientPrice()}
                                        </Tab>
                                    </Tabs>
                                </>

                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải báo cáo chi phí nguyên liệu...</span>
                                    </div>
                                </div>
                        }

                    </Card.Body>
                </Card>
                {this.RenderDetailMarketModal()}

                <Modal show={this.state.show} size={"sm"} >
                    <Modal.Header>
                        <Modal.Title>
                            Chọn thời gian
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <DatePicker
                                size={{ height: "600px" }}
                                onChange={(update) => {
                                    this.setState({
                                        tmpStartDate: update[0],
                                        tmpEndDate: update[1]
                                    })
                                }}
                                startDate={this.state.tmpStartDate}
                                endDate={this.state.tmpEndDate}
                                calendarStartDay={1}
                                selectsRange
                                inline
                            />
                        </center>
                        <br />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            onClick={() => {
                                this.setState({ show: false })
                            }}
                        >
                            Hủy
                        </Button>
                        <Button
                            size="sm"
                            disabled={this.state.tmpStartDate === null || this.state.tmpEndDate === null}
                            onClick={() => {
                                const start = this.state.tmpStartDate;
                                start.setHours(0, 0, 0, 0);

                                const end = this.state.tmpEndDate;
                                end.setHours(23, 59, 59, 0);

                                this.setState({
                                    show: false,
                                    startDate: start,
                                    endDate: end
                                })

                                this.getListVoucher(start, end);
                            }}
                        >
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

export default MarketListReportIndex;