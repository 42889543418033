import axios from "axios";
import React, { Component } from "react";
import { Button, Form, Modal, Col, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import swal from "sweetalert";
import ClassCreate from "../class/ClassCreate";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
// import { TextField } from "@mui/material";
class StudentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      dataInput: {
        id: "",
        id_class: 0,
        student_gender: 0,
        student_name: "",
        is_meal: 0,
        student_birthday: null,
        student_identifier: "",
        student_note: "",
        student_status: 1,
        student_contact: null,
        student_population: null,
        start_at: null,
        end_at: null,
        student_ethnic: 0,
        address: {
          number: "",
          group: "",
          zone: "",
          street: "",
          ward: "",
          district: "",
          city: ""
        },
        parent: {
          name: "",
          job: "",
          phone: ""

        },
        mother: {
          name: "",
          job: "",
          phone: ""
        },

      },
      btnClick: false,
      show: false,
      type: "",
      listclass: [],
      tabkey: 1,
      listEthnic: [], abledForm: props?.abledForm?.value, btnClick: false, abledFormPause: !props?.abledForm?.value
    };
  }
  async componentDidMount() {

    this.getEthnic();
    this.getlistuser();
    this.setState({ btnClick: false})
  }
  getEthnic = () => {
    axios.get('/viewEthnic').then(res =>
      this.setState({ listEthnic: res.data.data })
    )
  }
  getlistclass = () => {
    axios
      .get(`/viewClassRoom`)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listclass: res.data.data,
          });
          this.setState({ abledForm: false, abledFormPause: true })

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    if (event.target.name === "is_meal") {
      if (event.target.checked) { value = 1 }
      else { value = 0 }
    }
    if (event.target.name === "student_status") {
      if (event.target.value == 1) { this.setState({ abledForm: false, abledFormPause: true }) }
      else { this.setState({ abledForm: true, abledFormPause: false }) }
    }
    if (event.target.name === "student_status") {
      if (event.target.value > 2 || event.target.value == 0) {
        toast("Trạng thái không đúng!", { type: "error", autoClose: 1000 });
      }

    }
    let dataInput = { ...this.state.dataInput };

    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
  };
  handleInputaddress = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput.address[field] = value;
    this.setState({ dataInput: dataInput });
  };
  handleInputparent = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput.parent[field] = value;
    this.setState({ dataInput: dataInput });
  };
  handleInputmother = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput.mother[field] = value;
    this.setState({ dataInput: dataInput });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // if (this.state.dataInput.student_identifier.length <= 9 || this.state.dataInput.student_identifier.length >= 22
    //   // ||!(Number.isNaN(this.state.dataInput.student_identifier))
    // )
    //  {
    //   swal({
    //     title: "Vui lòng nhập đúng định dạng mã định danh",
    //     icon: "error",
    //     successMode: true,
    //   });
    // } else 
if(new Date(this.state.dataInput.start_at)/1000>new Date(new Date().setFullYear( new Date().getFullYear() +10))/1000){
  toast("Ngày nhập học không hợp lệ", { type: "error", autoClose: 1000 });
}
else
    {
      let student_name = this.state.dataInput.student_name;
      let id_class = this.state.dataInput.id_class;
      let student_gender = this.state.dataInput.student_gender;
      let student_birthday = this.state.dataInput.student_birthday;
      let is_meal = this.state.dataInput.is_meal;
      // console.log()
      this.setState({ btnClick: true })
      axios
        .post(
          `/createStudent?student_name=${student_name}&student_gender=${student_gender}&student_birthday=${new Date(student_birthday) / 1000}&id_class=${id_class}&student_identifier=${this.state.dataInput.student_identifier}&student_note=${this.state.dataInput.student_note}&is_meal=${is_meal}&address=${JSON.stringify(this.state.dataInput.address)}&parent=${JSON.stringify(this.state.dataInput.parent)}&mother=${JSON.stringify(this.state.dataInput.mother)}&student_ethnic=${this.state.dataInput.student_ethnic}&student_population=${this.state.dataInput.student_population}&student_contact=${this.state.dataInput.student_contact}&student_status=${this.state.dataInput.student_status}&start_at=${new Date(this.state.dataInput.start_at) / 1000}&end_at=${new Date(this.state.dataInput.end_at) / 1000}`
        )
        .then((res) => {
          if (res.data.status === true) {
            this.setState({ btnClick: false })
            this.props.onHide();
            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
            this.setState({
              id: "",
              id_class: "",
              student_gender: "1",
              student_name: "",
              student_birthday: "",
              student_identifier: null,
              student_contact: null,
              student_population: null,
              student_status: 1,
              student_ethnic: "",
              address: { number: "", group: "", zone: "", street: "", ward: "", district: "", city: "" },
              parent: { name: "", job: "", phone: "" },
              mother: { name: "", job: "", phone: "" },
            });
          } else {
            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
          }
        });
    }

  };

  dataClass() {
    if (this.state.listclass.length > 0) {
      return this.state.listclass.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    } else {
      return this.props.listclass.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    }
  }

  getlistuser = () => {
    axios
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listUser: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formclose = () => {
    this.setState({ btnClick: false })
    this.setState({ show: false });
    this.getlistclass();
  };

  renderClassAdd() {
    return (
      <ClassCreate
        listuser={this.state.listUser}
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
        _class={[]}
      />
    );
  }

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="md"
          backdrop="static"
          keyboard={false}
          id="addStudentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" >
              {/* <i className="fa-solid fa-plus"></i> */}
              Thêm học sinh

            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Tabs
                // activeKey={this.state.tabkey}
                onSelect={(k) => {
                  this.setState({ tabkey: k });
                }}
                defaultActiveKey={1}
                className="mb-3"
              >
                <Tab
                  key={1}
                  title="Thông tin học sinh"
                  eventKey={1}
                >

                  <Form.Floating className="mb-3">
                    <Form.Control

                      // {/* <CurrencyFormat */}
                      //   {/* customInput={Form.Control} format="###################################" */}
                      type="text"
                      defaultValue={this.state.student_identifier}
                      onChange={this.handleInput}
                      name="student_identifier"
                      id="student_identifier"
                      placeholder="Mã định danh Bộ GD&ĐT"
                    // required

                    />
                    <Form.Label>
                      Mã định danh Bộ GD&ĐT<i className="text-danger"></i>
                    </Form.Label>

                  </Form.Floating>
                  <Row>
                    <Col md={9}>
                      {new Check().permission(['13']) ? <br /> : null}
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_name}
                          onChange={this.handleInput}
                          name="student_name"
                          id="student_name"
                          placeholder="Tên học sinh"
                          required
                        />
                        <Form.Label>
                          Tên học sinh<i className="text-danger">*</i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={3}>
                      {new Check().permission(['13']) ?
                        <Form.Group className="text-end">
                          <Link
                            className="fst-italic"
                            onClick={() => {
                              this.setState({
                                show: true,
                                type: "TM",
                              });
                            }}
                          >
                            +Thêm lớp
                          </Link>
                        </Form.Group> : null}
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={this.state.id_class}
                          onChange={this.handleInput}
                          name="id_class"
                          required
                        >
                          <option value="">--Chọn--</option>
                          {this.dataClass()}
                        </Form.Select>
                        <Form.Label>
                          Lớp<i className="text-danger">*</i>
                        </Form.Label>

                      </Form.Floating>

                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={this.state.student_gender}
                          onChange={this.handleInput}
                          name="student_gender"
                        // required
                        >
                          <option value="">--Chọn--</option>
                          <option value="1">Nam</option>
                          <option value="2">Nữ</option>
                          <option value="3">Khác</option>
                        </Form.Select>
                        <Form.Label>
                          Giới tính
                          {/* <i className="text-danger">*</i> */}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          // required
                          name="student_birthday"
                          onChange={this.handleInput}
                        />
                        <Form.Label>
                          Ngày sinh
                          {/* <i className="text-danger">*</i> */}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">

                        <Form.Select
                          defaultValue={this.state.student_ethnic
                          }
                          onChange={this.handleInput}
                          name="student_ethnic"
                        // required
                        >
                          <option value="">--Chọn--</option>
                          {this.state.listEthnic.length > 0 ? this.state.listEthnic.map(ethnic_ => {
                            return (<option value={ethnic_.id}>{ethnic_.ethnic_name}</option>)
                          }) : null}

                        </Form.Select>
                        <Form.Label>
                          Dân tộc
                          {/* <i className="text-danger">*</i> */}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      defaultValue={this.state.student_population}
                      onChange={this.handleInput}
                      name="student_population"
                      id="student_population"
                      placeholder="Mã định danh dân cư"
                    // required

                    />
                    <Form.Label>
                      Mã định danh dân cư
                      <i className="text-danger"></i>
                    </Form.Label>

                  </Form.Floating>

                  <Row>
                    <Col md={7}>
                      <Form.Floating className="mb-3">

                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_note}
                          onChange={this.handleInput}
                          name="student_note"
                          // id="student_name"
                          placeholder="Ghi chú"
                        // required
                        />
                        <Form.Label>
                          Ghi chú<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>

                    <Col md={5}>
                      <Form.Floating className="mb-3" >
                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_contact}
                          onChange={this.handleInput}
                          name="student_contact"
                          placeholder="SĐT liên hệ"
                        />
                        <Form.Label>
                          SĐT liên hệ<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>


                  <Row >
                    <Col md={4}>
                      <Form.Floating className="mb-3" >
                        <Form.Select
                          defaultValue={this.state.student_status}
                          onChange={this.handleInput}
                          name="student_status"
                          required
                        >
                          <option value="1">Đang học</option>
                          <option value="2">Tạm nghỉ</option>

                        </Form.Select>
                        <Form.Label>
                          Trạng thái<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>

                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          // required
                          name="start_at"
                          onChange={this.handleInput}
                          required={!this.state.abledForm}
                          disabled={this.state.abledForm}
                        />
                        <Form.Label>
                          Ngày nhập học
                          {this.state.abledForm ? "" : <i className="text-danger">*</i>}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          // required
                          name="end_at"
                          onChange={this.handleInput}
                          required={this.state.abledFormPause}
                          disabled={this.state.abledFormPause}
                        />
                        <Form.Label>
                          Ngày nghỉ học
                          {this.state.abledFormPause ? "" : <i className="text-danger">*</i>}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Form.Check className="mb-3" type="checkbox" label="Không ăn ở trường"
                    name="is_meal"
                    onChange={this.handleInput}

                    checked={this.state.dataInput.is_meal}
                  />
                </Tab>
                <Tab
                  key={2}
                  title="Thông tin gia đình"
                  eventKey={2}
                >
                  <Row>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin cha</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.parent.name}
                          onChange={this.handleInputparent}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"

                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* <Row> */}
                      {/* <Col md={6}> */}
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.parent.job}
                          onChange={this.handleInputparent}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"

                        />
                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* </Col>
                <Col md={6}> */}
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.parent.phone}
                          onChange={this.handleInputparent}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* </Col>
              </Row> */}
                    </Col>
                    <Col md={6}>

                      <p style={{ fontWeight: "bold" }}>Thông tin mẹ</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.mother.name}
                          onChange={this.handleInputmother}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* <Row> */}
                      {/* <Col md={6}> */}
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.mother.job}
                          onChange={this.handleInputmother}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"

                        />

                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* </Col>
  <Col md={6}> */}
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.mother.phone}
                          onChange={this.handleInputmother}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      {/* </Col>

</Row> */}
                    </Col>
                  </Row>



                  <p style={{ fontWeight: "bold" }}>Địa chỉ gia đình</p>
                  <Row>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.number}
                          onChange={this.handleInputaddress}
                          name="number"
                          id="number"
                          placeholder="Số nhà"

                        />
                        <Form.Label>
                          Số nhà<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={2}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.group}
                          onChange={this.handleInputaddress}
                          name="group"
                          id="group"
                          placeholder="Tổ"

                        />
                        <Form.Label>
                          Tổ<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.zone}
                          onChange={this.handleInputaddress}
                          name="zone"
                          id="zone"
                          placeholder="Khu vực"

                        />
                        <Form.Label>
                          Khu vực<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.street}
                          onChange={this.handleInputaddress}
                          name="street"
                          id="street"
                          placeholder="Đường"

                        />
                        <Form.Label>
                          Đường<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>



                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.ward}
                          onChange={this.handleInputaddress}
                          name="ward"
                          id="ward"
                          placeholder="Phường"

                        />
                        <Form.Label>
                          Phường<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.district}
                          onChange={this.handleInputaddress}
                          name="distric"
                          id="distric"
                          placeholder="Quận"

                        />
                        <Form.Label>
                          Quận<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          // defaultValue={this.state.address.city}
                          onChange={this.handleInputaddress}
                          name="city"
                          id="city"
                          placeholder="Tỉnh, TP"

                        />
                        <Form.Label>
                          Tỉnh, TP<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddstudent"
                // onClick={this.props.onHide()
                // ,  this.setState({ tabkey: 1 })
                // }
                onClick={() => {
                  this.setState({ tabkey: 1, abledForm: false, abledFormPause: true })
                  this.props.onHide()



                }}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button size="sm" variant="success" type="submit"
                //  onClick={()=>this.setState({
                //   btnClick:true
                // })}
                disabled={this.state.btnClick}

              >


                <i className="fa-solid fa-check"></i> Lưu
              </Button>



            </Modal.Footer>
          </Form>
        </Modal>
        {this.renderClassAdd()}
      </>
    );
  }
}

export default StudentCreate;
