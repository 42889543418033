import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import TextEditor from "./TextEditor";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const PostModalAdd = ({ show, onHide, topic }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [solutionImage, setSolutionImage] = useState("");
  const [content, setContent] = useState("");

  const [postDetail, setPostDetail] = useState({
    post_title: "",
    id_category: "",
    short_content: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    post_title: "",
    id_category: "",
    id_unit: "",
    state_unit: "",
    short_content: "",
  });

  useEffect(() => {
    getListCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setSolutionImage(event.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const getListCategories = async () => {
    await axios
      .get(`/new-category-shows`)
      .then((res) => {
        setCategoryOptions(res.data.data.data?.docs || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createNewFood();
  };
  const createNewFood = async () => {
    setErrorMessage({});
    var bodyFormData = new FormData();
    bodyFormData.append("post_title", postDetail.post_title);
    bodyFormData.append("short_content", postDetail.short_content);
    bodyFormData.append("content", content);
    bodyFormData.append("is_status", 1);
    bodyFormData.append("id_category", postDetail.id_category || topic);
    bodyFormData.append("image", solutionImage);

    await axios({
      method: "post",
      url: "post-create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast(res.data.messages || "Thành công", {
          type: "success",
          autoClose: 1000,
        });

        onHide(true);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.messages);
      });
  };
  // API

  // Render

  return (
    <Modal
      size="lg"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Thêm bài viết</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  required
                  value={postDetail.post_title}
                  onChange={(e) => {
                    setPostDetail({
                      ...postDetail,
                      post_title: e.target.value,
                    });
                    delete errorMessage["post_title"];
                  }}
                  placeholder="Tên bài viết"
                />
                <Form.Label>
                  Tên bài viết
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.post_title && (
                  <p style={errorTextStyle}>{errorMessage.post_title}</p>
                )}
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Select
                  value={postDetail.id_category || topic}
                  onChange={(e) => {
                    setPostDetail({
                      ...postDetail,
                      id_category: e.target.value,
                    });
                    delete errorMessage["id_category"];
                  }}
                  required
                >
                  <option value="">--Chọn--</option>
                  {categoryOptions.length > 0
                    ? categoryOptions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.category_name}
                        </option>
                      );
                    })
                    : "Không có lựa chọn"}
                </Form.Select>
                <Form.Label>
                  Chủ đề<i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.id_category && (
                  <p style={errorTextStyle}>{errorMessage.id_category}</p>
                )}
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={postDetail.short_content}
                  onChange={(e) => {
                    setPostDetail({
                      ...postDetail,
                      short_content: e.target.value,
                    });
                    delete errorMessage["short_content"];
                  }}
                  placeholder="Mô tả"
                  required
                />
                <Form.Label>
                  Mô tả<i className="text-danger">*</i>
                </Form.Label>
                {errorMessage.short_content && (
                  <p style={errorTextStyle}>{errorMessage.short_content}</p>
                )}
              </Form.Floating>

              <div className="mb-3">
                <Form.Label>
                  Nội dung<i className="text-danger">*</i>
                </Form.Label>
                <TextEditor
                  setContents={content}
                  required
                  onChange={(value) => {
                    delete errorMessage["content"];
                    setContent(value);
                  }}
                  placeholder={"Nội dung"}
                  style={{ border: "1px solid #dadada", padding: 16 }}
                />
                {errorMessage.content && (
                  <p style={errorTextStyle}>{errorMessage.content}</p>
                )}
              </div>
              <div>
                {solutionImage ? (
                  <div className="d-flex justify-content-center">
                    <div
                      style={{
                        aspectRatio: "900/600",
                        backgroundImage: `url("${URL.createObjectURL(
                          solutionImage
                        )}")`,
                        backgroundSize: "cover",
                        width: "70%",
                      }}
                      className="image-default position-relative mb-3"
                    />
                  </div>
                ) : (
                  ""
                )}

                <input
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={(e) => handleUploadImage(e)}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>

          <Button size="sm" variant="success" type="submit">
            <i className="fa-solid fa-check"></i> Lưu
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PostModalAdd;
