import { useState } from "react";

import { Button, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import DetailVoucherMarket from "./DetailVoucherMarket";

import { dateFormatter } from "../../../utils/helpers";
import Check from "../../../other/Check";

const DateVoucherMarket = ({ listVoucher, school, deleteVoucherMarket }) => {
	const [showDetail, setShowDetail] = useState(false);
	const [selectedVoucher, setSelectedVoucher] = useState({});

	const handleShowDetailModal = (voucherItem) => {
		setShowDetail(true);
		setSelectedVoucher(voucherItem);
	};
	const handleCloseDetailModal = () => {
		setShowDetail(false);
		setSelectedVoucher({});
	};

	let totalMoney = 0;

	return (new Check().permission([116]) ?
		<div style={{ overflowX: "scroll" }}>
			<Table striped hover style={{ minWidth: 777 }}>
				<thead className="text-center">
					<tr>
						<th className="text-center">STT</th>
						<th className="text-end">Ngày đi chợ</th>
						<th className="text-end">Ngày lưu</th>
						<th className="text-end">Tổng tiền</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{listVoucher.map((voucherItem, voucherIndex) => {
						totalMoney += Math.round(voucherItem.vou_price);

						return (
							<tr key={voucherItem.id} className="text-center">
								<td className="text-center">{voucherIndex + 1}</td>
								<td className="text-end">{dateFormatter(new Date(voucherItem.vou_date * 1000))}</td>
								<td className="text-end">{dateFormatter(new Date(voucherItem.updated_at * 1000))}</td>
								<td className="text-end">
									<CurrencyFormat
										className="text-end"
										value={Math.round(voucherItem.vou_price)}
										thousandSeparator={true}
										allowNegative={false}
										isNumericString
										displayType="text"
									/>
								</td>
								<td>
									{new Check().permission([116]) ?
										<Button size="sm" onClick={() => handleShowDetailModal(voucherItem)} className="me-2">
											<i className="fa-solid fa-info-circle" />
										</Button>
										: <></>}

									{new Check().permission([118]) ?
										<Button size="sm"
											variant="danger"
											title="Xóa phiếu đi chợ"
											onClick={() => deleteVoucherMarket(voucherItem)}
										>
											<i className="fa-solid fa-trash" />
										</Button>
										: <></>}
								</td>
							</tr>
						);
					})}

					<tr>
						<th></th>
						<th></th>
						<th className="text-end">Tổng cộng</th>
						<th className="text-end">
							<CurrencyFormat value={totalMoney} thousandSeparator={true} allowNegative={false} displayType="text" />
						</th>
						<th></th>
					</tr>
				</tbody>
			</Table>

			{showDetail ? <DetailVoucherMarket show={showDetail} onHide={handleCloseDetailModal} voucherMarket={selectedVoucher} school={school} /> : ""}
		</div>
		: <h6 className="text-muted">Bạn không có quyền truy cập!</h6>
	);
};

export default DateVoucherMarket;
