import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../admin/other/Check";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import moment from "moment/moment";

class NotEatTimeEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: props?.notEatTime,
      err: {},
      times: [],
    };
  }

  async componentDidMount() {
    await this.getTimeOfDays();
  }

  getTimeOfDays = async () => {
    await axios.get(`getTimeOfDays`).then((res) => {
      if (res.data.errCode == 0) {
        this.setState({
          times: res.data?.times
        });
      }
    });
  };

  handleHide = () => {
    return this.props.onHide();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    swal({
      title: `Bạn chắc muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        if (this.state.dataInput.id_times.length === 0)
          return this.setState({ err: { ...this.state.err, id_times: 'Hãy chọn buổi không ăn.' } });

        let data = {
          id: this.state.dataInput.id,
          id_student: this.state.dataInput.id_student,
          id_times: this.state.dataInput.id_times,
          start_at: this.state.dataInput.start_at,
          end_at: this.state.dataInput.end_at,
          price_return: this.state.dataInput.price_return,
          is_all_day: this.state.dataInput.is_all_day,
        };

        axios.put(`/not-eat-time-update`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          toast(res.data.messages, { type: "success" });
          this.props.onSave();
        }).catch((error) => {
          this.setState({ err: error.response.data.messages });
        });
      }
    })
  };

  handleCheckboxChange = (event) => {
    const id = event.target.value;
    this.setState((prevState) => {
      if (event.target.checked) {
        return {
          dataInput: {
            ...prevState.dataInput,
            id_times: [...prevState.dataInput.id_times, id]
          },
          err: { ...this.state.err, id_times: '' }
        };
      } else {


        return {
          dataInput: {
            ...prevState.dataInput,
            id_times: prevState.dataInput.id_times.filter(selectedId => selectedId != id)
          },
        };
      }
    });
  };

  handleInput = (event) => {
    let value = event.target.value;
    const field = event.target.name;
    const type = event.target.type;

    if (type === 'date' && event.target.value !== '')
      value = new Date(event.target.value) / 1000;

    this.setState((prevState) => {
      return {
        dataInput: {
          ...prevState.dataInput,
          [field]: value
        }
      };
    });
  };


  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Chi tiết không ăn tại trường
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={12}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      defaultValue={this.state.dataInput?.student_name}
                      disabled
                    />
                    <Form.Label>
                      Tên học sinh
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      name="start_at"
                      defaultValue={this.state.dataInput.start_at ? moment(new Date(this.state.dataInput.start_at * 1000)).format("YYYY-MM-DD") : ''}
                      onChange={this.handleInput}
                    />
                    <Form.Label>
                      Ngày bắt đầu<i className="text-danger">*</i>
                    </Form.Label>
                    <i className="text-danger"> {this.state.err?.start_at} </i>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      name="end_at"
                      defaultValue={this.state.dataInput.end_at ? moment(new Date(this.state.dataInput.end_at * 1000)).format("YYYY-MM-DD") : ''}
                      onChange={this.handleInput}
                    />
                    <Form.Label>
                      Ngày kết thúc
                    </Form.Label>
                    <i className="text-danger"> {this.state.err?.end_at} </i>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <CurrencyFormat
                      className="form-control text-end"
                      thousandSeparator={true}
                      value={this.state.dataInput?.price_return}
                      name="price_return"
                      onValueChange={(values) => {
                        const { value } = values;
                        this.setState((prevState) => {
                          return {
                            dataInput: {
                              ...prevState.dataInput,
                              price_return: value
                            }
                          };
                        });
                      }}
                      required
                    />
                    <Form.Label>
                      Tiền trả lại<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col sm={12} className="d-flex">
                  <h6> Buổi ăn<i className="text-danger">*</i> </h6>

                  <Form.Check
                    className="ms-3"
                    type="switch"
                    value="all"
                    onChange={(e) => {
                      this.setState((prevState) => {
                        if (e.target.checked) {
                          return {
                            dataInput: {
                              ...prevState.dataInput,
                              id_times: this.state?.times.filter(item => item.time_name_now != null).map(itemMap => itemMap.id)

                            },
                          };
                        } else {
                          return {
                            dataInput: {
                              ...prevState.dataInput,
                              id_times: []
                            },
                          };
                        }
                      });
                    }}

                    onClick={() => {
                      const isCheck = this.state.dataInput.is_all_day === 1 ? true : false;

                      this.setState((prevState) => {
                        if (!isCheck) {
                          return {
                            dataInput: {
                              ...prevState.dataInput,
                              id_times: this.state?.times.filter(item => item.time_name_now != null).map(itemMap => itemMap.id),
                              is_all_day: 1
                            },
                          };
                        } else {
                          return {
                            dataInput: {
                              ...prevState.dataInput,
                              id_times: [],
                              is_all_day: 0
                            },
                          };
                        }
                      });
                    }}

                    checked={this.state.dataInput.is_all_day === 1 ? true : false}
                    label="Nguyên ngày"
                  />
                </Col>

                <i className="text-danger"> {this.state.err?.id_times} </i>

                {this.state.times.length > 0 ?
                  this.state.times.map((time, timeIndex) => {
                    if (time.time_name_now != null || this.state.dataInput.id_times.some(timeDt => timeDt === time.id))
                      return (
                        <Col key={`time_${timeIndex}`}>
                          <Form.Check
                            type="switch"
                            value={time.id}
                            label={time.time_name_now || time.time_name}
                            onClick={() => {
                              const isCheck = this.state.dataInput.id_times.some(timeDt => timeDt == time.id);
                              const id = time.id
                              const id_times = !isCheck ? [...this.state.dataInput.id_times, id] : this.state.dataInput.id_times.filter(timeDt => timeDt != id);
                              this.setState({
                                dataInput: {
                                  ...this.state.dataInput,
                                  id_times: id_times
                                }
                              })
                            }}
                            checked={this.state.dataInput.id_times.some(timeDt => timeDt == time.id) ? true : false}
                          />
                        </Col>
                      )
                  })
                  : <></>
                }
              </Row>

            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={onHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button
                size="sm"
                variant="success"
                type="submit"
              >
                <i className="fa-solid fa-check"></i> Cập nhật
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default NotEatTimeEditModal;
