import React, { Component } from "react";
import { Card, Table, Form, Col, Row, Button } from "react-bootstrap";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";

const initialDay = [
    { id: 1, day_name: "Thứ 2" },
    { id: 2, day_name: "Thứ 3" },
    { id: 3, day_name: "Thứ 4" },
    { id: 4, day_name: "Thứ 5" },
    { id: 5, day_name: "Thứ 6" },
    { id: 6, day_name: "Thứ 7" },
    { id: 7, day_name: "Chủ nhật" }
]

class FinanceDayBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolInfo: {},

            voucher: {},
            isLoading: false,

            takeMoney: 0,

            listClass: [],
            listStudentNotEat: [],
            studentAmount: 0,
            listmealbyclass: [],

            listDay: [],
            daySelect: 0,

            start: new Date(),
            term: new Date(),

            weekInMonth: ""
        };
    }

    async componentDidMount() {
        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        if (todayIs === 0) {
            todayIs = 7;
        }

        const newListDay = initialDay.map((day, index) => {
            return {
                id: day.id,
                day_name: day.day_name,
                day: initialWeek[index]
            }
        });

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: newListDay
        });

        this.getListMealByClassData();
        this.getClassData(today);
        this.getStudentData();
        this.getDetailschool();
        this.getListVoucher(newListDay.filter(day => day.id === todayIs)[0].day, newListDay.filter(day => day.id === todayIs)[0].day);
        this.getlistsetting();
    }

    //#region Fetch Data
    getDetailschool = () => {
        axios.get(`/detailSchool`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        schoolInfo: res.data.data[0]
                    })
                }
            });
    }

    getListVoucher = (startDate, endDate) => {
        this.setState({
            isLoading: true
        })

        axios.get(`/viewVoucher?type_voucher=1&start_at=${startDate.getTime() / 1000}&term_at=${endDate.getTime() / 1000}`)
            .then(res => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status === true) {
                    this.setState({
                        voucher: res.data.data.filter(item => Number(item.id_classify) === 1)[0],
                    })
                }
            });
    }

    getlistsetting = () => {
        axios
            .get(`/viewSetting?id_classify=1`)
            .then((res) => {
                if (res.data.status === true) {
                    this.setState({
                        listTime: res.data.data.time.filter(item => Number(item.is_active) === 1),
                        takeMoney: Number(res.data.data.main[0].price_proceed)
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getClassData = (date) => {
        axios.get(`/viewClassRoom?start=${date.setHours(0, 0, 0) / 1000}&end=${date.setHours(23, 59, 59) / 1000}`).then(res => {
            if (res.data.status === true) {

                let totalStudent = 0;

                res.data.data.map(student => {
                    totalStudent += student.count_student;
                    return student;
                })

                this.setState({
                    listClass: res.data.data,
                    studentAmount: totalStudent
                })
            }
        })
    }

    getListMealByClassData() {
        axios
            .post(`/viewMeal?id_classify=1`)
            .then((res) => {
                if (res.data.status) {
                    this.setState({
                        listmealbyclass: res.data.data
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getStudentData() {
        axios
            .get("/viewStudent")
            .then((res) => {
                if (res.data.status) {
                    this.setState({
                        listStudentNotEat: res.data.data.filter(student => student.is_meal === 1)
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //#endregion

    //#region Handle Logic
    xport = () => {
        const wb = XLSX.utils.book_new();

        const ws = this.book();

        XLSX.utils.book_append_sheet(wb, ws, "Bảng công khai chi chợ ngày");

        XLSX.writeFile(wb, "Bảng công khai chi chợ ngày.xlsx");
    };

    book = () => {
        const table = document.getElementById("finance-day-book");

        var ws = XLSX.utils.table_to_sheet(table, { raw: true })

        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

        const borderStyle = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
        }

        // Style cell
        colAlpha.map((alpha) => {
            ws[`${alpha}1`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "start"
                }
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                    sz: 16,
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                }
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                }
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                border: borderStyle,
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                }
            };

            return alpha;
        })

        const storeItems = this.state.voucher.ingredent?.filter(item => item.ingredent_type === 2) || [];
        const marketItems = this.state.voucher.ingredent?.filter(item => item.ingredent_type === 1) || [];

        let bigArray = [];

        const rows = [{}, { hpt: 50 }, {}, { hpt: 30 }];

        if (storeItems.length > marketItems.length) {
            bigArray = storeItems;
        } else {
            bigArray = marketItems;
        }

        for (let i = 0; i < bigArray.length; i++) {
            colAlpha.map(alpha => {
                if (ws[`${alpha}${5 + i}`].v === "empty") {
                    ws[`${alpha}${5 + i}`].v = ' '
                }

                if (alpha === "A" || alpha === "G") {
                    ws[`${alpha}${5 + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: borderStyle,
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                }

                if (alpha === "B" || alpha === "H" || alpha === "C" || alpha === "I") {
                    ws[`${alpha}${5 + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: borderStyle,
                        alignment: {
                            vertical: "center",
                            horizontal: "left"
                        }
                    };
                }

                if (alpha === "D" || alpha === "E" || alpha === "F" || alpha === "J" || alpha === "K" || alpha === "L") {
                    ws[`${alpha}${5 + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: borderStyle,
                        alignment: {
                            vertical: "center",
                            horizontal: "right"
                        }
                    };
                }

                return alpha;
            })

            rows.push({})
        }

        // Near last row
        colAlpha.map(alpha => {
            if (alpha === "A" || alpha === "G") {
                ws[`${alpha}${5 + bigArray.length}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    border: borderStyle,
                    alignment: {
                        vertical: "center",
                        horizontal: "center"
                    },
                };
            } else if (alpha === "F" || alpha === "L") {
                ws[`${alpha}${5 + bigArray.length}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    border: borderStyle,
                    alignment: {
                        vertical: "center",
                        horizontal: "right"
                    }
                };
            } else {
                ws[`${alpha}${5 + bigArray.length}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: borderStyle,
                    alignment: {
                        vertical: "center",
                        horizontal: "center"
                    }
                };
            }

            return alpha;
        })

        rows.push({})

        // Last row
        colAlpha.map(alpha => {
            if (alpha === "A" || alpha === "D" || alpha === "G" || alpha === "J") {
                ws[`${alpha}${5 + bigArray.length + 1}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    border: borderStyle,
                    alignment: {
                        wrapText: true,
                        vertical: "center",
                        horizontal: "center"
                    },
                };
            } else if (alpha === "C" || alpha === "F" || alpha === "I" || alpha === "L") {
                ws[`${alpha}${5 + bigArray.length + 1}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    border: borderStyle,
                    alignment: {
                        wrapText: true,
                        vertical: "center",
                        horizontal: "right"
                    }
                };
            } else {
                ws[`${alpha}${5 + bigArray.length + 1}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: borderStyle,
                    alignment: {
                        wrapText: true,
                        vertical: "center",
                        horizontal: "center"
                    }
                };
            }

            return alpha;
        })

        rows.push({ hpt: 30 })

        ws['!cols'] = [
            { wch: 3.5 },
            { wch: 15 },
            { wch: 10 },
            { wch: 5.5 },
            { wch: 8.5 },
            { wch: 9.5 },
            { wch: 3.5 },
            { wch: 15 },
            { wch: 10 },
            { wch: 5.5 },
            { wch: 8.5 },
            { wch: 10.5 },
        ];

        ws['!rows'] = rows;

        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
            { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
            { s: { r: 2, c: 6 }, e: { r: 2, c: 8 } },
            { s: { r: 2, c: 9 }, e: { r: 2, c: 11 } },

            { s: { r: 5 + bigArray.length - 1, c: 0 }, e: { r: 5 + bigArray.length - 1, c: 4 } },
            { s: { r: 5 + bigArray.length - 1, c: 6 }, e: { r: 5 + bigArray.length - 1, c: 10 } },

            { s: { r: 5 + bigArray.length, c: 0 }, e: { r: 5 + bigArray.length, c: 1 } },
            { s: { r: 5 + bigArray.length, c: 3 }, e: { r: 5 + bigArray.length, c: 4 } },
            { s: { r: 5 + bigArray.length, c: 6 }, e: { r: 5 + bigArray.length, c: 7 } },
            { s: { r: 5 + bigArray.length, c: 9 }, e: { r: 5 + bigArray.length, c: 10 } },
        ];
        ws["!merges"] = merge;

        return ws;
    }

    handleExport = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn export file sổ tài chính công khai`,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }

    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day)
        });

        this.getListVoucher(this.state.listDay.filter(dayItem => dayItem.id === Number(day))[0].day, this.state.listDay.filter(dayItem => dayItem.id === Number(day))[0].day);
        this.getClassData(this.state.listDay.filter(dayItem => dayItem.id === Number(day))[0].day);
    }

    dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    setDate = (theDate) => {
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length - 1]
        })

        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    //#endregion

    // Render
    RenderDayOption() {
        return (
            <>
                <Card.Title>Chọn ngày in sổ tài chính công khai</Card.Title>

                <div className="d-none d-md-flex justify-content-start my-3">
                    {
                        this.state.listDay.map((dayItem, i) => {
                            let toDayIs = dayItem.id % 7;

                            if (toDayIs === 0) {
                                toDayIs = 7;
                            }

                            return (
                                <div key={i} className="d-flex me-md-4 me-xl-5">
                                    <Form.Check
                                        type="radio"
                                        value={dayItem.id}
                                        checked={this.state.daySelect === dayItem.id}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleCheck(e.target.value);
                                            }
                                        }}
                                        name="day"
                                        className="me-2"
                                    />
                                    {dayItem.day_name}
                                </div>

                            );
                        })}
                </div>

                <Row className="my-3 d-md-none">
                    <Col>
                        {this.state.listDay
                            .slice(
                                0,
                                Math.round(this.state.listDay.length / 2)
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>

                    <Col>
                        {this.state.listDay
                            .slice(
                                Math.round(this.state.listDay.length / 2,
                                    this.state.listDay.length
                                )
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </>
        )
    }

    RenderPublicMarketTable() {
        if (this.state.voucher?.ingredent?.length === 0) {
            return (
                <div className="d-flex justify-content-center mt-3">
                    Không có dữ liệu
                </div>
            )
        }

        const storeItems = this.state.voucher.ingredent?.filter(item => item.ingredent_type === 2) || [];
        const marketItems = this.state.voucher.ingredent?.filter(item => item.ingredent_type === 1) || [];

        let bigArray = [];

        if (storeItems.length > marketItems.length) {
            bigArray = storeItems;
        } else {
            bigArray = marketItems;
        }

        let totalStoreMoney = 0;
        let totalMarketMoney = 0;

        let moneyPerChild = this.state.takeMoney;

        const dateShow = this.state.listDay.filter(day => day.id === this.state.daySelect)[0]?.day || new Date();

        let start = dateShow.setHours(0, 0, 0) / 1000;
        let end = dateShow.setHours(23, 59, 59) / 1000;

        const mealCutInDay = this.state.listmealbyclass.filter(mealCut => start <= mealCut.meal_day
            && mealCut.meal_day <= end
            && Number(mealCut.is_status) === 1
        );

        dateShow.setHours(0, 0, 0);

        let notEatStudent = 0;

        this.state.listStudentNotEat.map(student => {
            if (this.state.listClass.some(classItem => Number(classItem.id) === Number(student.id_class))) {
                notEatStudent++;
            }

            return student;
        })

        const totalPerson = this.state.studentAmount - notEatStudent;
        const totalMeal = this.state.studentAmount - mealCutInDay.filter(mealCut => Number(mealCut.type_meal) === 1).length - notEatStudent;

        let returnMoney = 0;

        mealCutInDay.map(meal => {
            if (Number(meal.is_term) === 1) {
                if (Number(meal.type_meal) === 1) {
                    returnMoney += Number(meal.price_return);
                } else {
                    meal.detail.map(detailItem => {
                        returnMoney += Number(detailItem.price_return);
                        return detailItem;
                    })
                }
            }
            return meal;
        })

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr className="align-middle">
                            <th colSpan={12}>{this.state.schoolInfo.school_name}</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th colSpan={12}>BẢNG CÔNG KHAI CHI CHỢ</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th colSpan={3}> {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day_name} Ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} Tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} Năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()} </th>
                            <th colSpan={3}>
                                Danh sách học sinh:&nbsp;
                                <CurrencyFormat
                                    value={this.state.studentAmount - notEatStudent}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th colSpan={3}>
                                Tổng số suất ăn:&nbsp;
                                <CurrencyFormat
                                    value={totalMeal}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th colSpan={3}>
                                Định mức chi:&nbsp;
                                <CurrencyFormat
                                    value={moneyPerChild.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                /> &nbsp;
                                đồng/bé
                            </th>
                        </tr>
                        <tr className="text-center">
                            <th className="align-middle">STT</th>
                            <th className="align-middle">Thực phẩm kho</th>
                            <th className="align-middle">ĐVT</th>
                            <th className="align-middle">Số lượng</th>
                            <th className="align-middle">Đơn giá</th>
                            <th className="align-middle">Thành tiền</th>
                            <th className="align-middle">STT</th>
                            <th className="align-middle">Thực phẩm chợ</th>
                            <th className="align-middle">ĐVT</th>
                            <th className="align-middle">Số lượng</th>
                            <th className="align-middle">Đơn giá</th>
                            <th className="align-middle">Thành tiền</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            bigArray.length !== 0
                                ?
                                bigArray.map((_, index) => {
                                    const storeItem = storeItems[index];
                                    const marketItem = marketItems[index];

                                    if (!storeItem) {
                                        totalMarketMoney += (Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2));

                                        return (
                                            <tr className="align-middle" key={index}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{marketItem.ingredent_name}</td>
                                                <td>{marketItem.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(marketItem.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(marketItem.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2)).toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    if (!marketItem) {
                                        totalStoreMoney += (Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2));

                                        return (
                                            <tr className="align-middle" key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{storeItem.ingredent_name}</td>
                                                <td>{storeItem.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(storeItem.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(storeItem.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2)).toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    }

                                    totalStoreMoney += (Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2));
                                    totalMarketMoney += (Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2));

                                    return (
                                        <tr className="align-middle" key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{storeItem.ingredent_name}</td>
                                            <td>{storeItem.unit_name}</td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(storeItem.count).toFixed(2)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(storeItem.ingredent_price)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={(Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2)).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{marketItem.ingredent_name}</td>
                                            <td>{marketItem.unit_name}</td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(marketItem.count).toFixed(2)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(marketItem.ingredent_price)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={(Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2)).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center align-middle" colSpan={12}>Không có dữ liệu</td>
                                </tr>
                        }
                    </tbody>

                    <tfoot>
                        <tr>
                            <th className="text-center align-middle" colSpan={5}>Tổng cộng</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={totalStoreMoney.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th className="text-center align-middle" colSpan={5}>Tổng cộng</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={totalMarketMoney.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center align-middle" colSpan={2}>
                                Tổng thu theo danh sách
                            </th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(totalPerson * moneyPerChild).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th className="text-center align-middle" colSpan={2}>
                                Tổng tiền trả lại
                            </th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(returnMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th className="text-center align-middle" colSpan={2}>Đã chi:</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(totalMarketMoney + totalStoreMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th className="text-center align-middle" colSpan={2}>Thừa:</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={((totalPerson * moneyPerChild) - (totalMarketMoney + totalStoreMoney) - returnMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                    displayType="text"
                                />
                            </th>
                        </tr>
                    </tfoot>
                </Table>

                <Table bordered id="finance-day-book" className="d-none">
                    <thead>
                        <tr className="align-middle">
                            <th>{this.state.schoolInfo.school_name}</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th>BẢNG CÔNG KHAI CHI CHỢ</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th> {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day_name} Ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} Tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} Năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()} </th>
                            <th>empty</th>
                            <th>empty</th>

                            <th>
                                Danh sách học sinh:&nbsp;
                                <CurrencyFormat
                                    value={this.state.studentAmount - notEatStudent}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th>empty</th>
                            <th>empty</th>

                            <th>
                                Tổng số suất ăn:&nbsp;
                                <CurrencyFormat
                                    value={totalMeal}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th>empty</th>
                            <th>empty</th>

                            <th>
                                Định mức chi:&nbsp;
                                <CurrencyFormat
                                    value={moneyPerChild.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                /> &nbsp;
                                đồng/bé
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr className="text-center">
                            <th className="align-middle">STT</th>
                            <th className="align-middle">Thực phẩm kho</th>
                            <th className="align-middle">ĐVT</th>
                            <th className="align-middle">Số lượng</th>
                            <th className="align-middle">Đơn giá</th>
                            <th className="align-middle">Thành tiền</th>
                            <th className="align-middle">STT</th>
                            <th className="align-middle">Thực phẩm chợ</th>
                            <th className="align-middle">ĐVT</th>
                            <th className="align-middle">Số lượng</th>
                            <th className="align-middle">Đơn giá</th>
                            <th className="align-middle">Thành tiền</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            bigArray.length !== 0
                                ?
                                bigArray.map((_, index) => {
                                    const storeItem = storeItems[index];
                                    const marketItem = marketItems[index];

                                    if (!storeItem) {

                                        return (
                                            <tr className="align-middle" key={index}>
                                                <td>empty</td>
                                                <td>empty</td>
                                                <td>empty</td>
                                                <td>empty</td>
                                                <td>empty</td>
                                                <td>empty</td>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{marketItem.ingredent_name}</td>
                                                <td>{marketItem.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(marketItem.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(marketItem.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2)).toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    if (!marketItem) {

                                        return (
                                            <tr className="align-middle" key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{storeItem.ingredent_name}</td>
                                                <td>{storeItem.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(storeItem.count).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(storeItem.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={(Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2)).toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    }

                                    return (
                                        <tr className="align-middle" key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{storeItem.ingredent_name}</td>
                                            <td>{storeItem.unit_name}</td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(storeItem.count).toFixed(2)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(storeItem.ingredent_price)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={(Number(storeItem.ingredent_price) * Number(storeItem.count).toFixed(2)).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{marketItem.ingredent_name}</td>
                                            <td>{marketItem.unit_name}</td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(marketItem.count).toFixed(2)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={Number(marketItem.ingredent_price)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                            <td className="text-end">
                                                <CurrencyFormat
                                                    value={(Number(marketItem.ingredent_price) * Number(marketItem.count).toFixed(2)).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    isNumericString
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center align-middle" colSpan={12}>Không có dữ liệu</td>
                                </tr>
                        }
                    </tbody>

                    <tfoot>
                        <tr>
                            <th className="text-center align-middle">Tổng cộng</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={totalStoreMoney.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                            <th className="text-center align-middle">Tổng cộng</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={totalMarketMoney.toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>
                        </tr>

                        <tr>
                            <th className="text-center align-middle">
                                Tổng thu theo danh sách
                                {/* Tiêu chuẩn được chi: &nbsp;
                                <CurrencyFormat 
                                    value={moneyPerChild.toFixed(0)} 
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                /> &nbsp;
                                đồng/bé */}
                            </th>
                            <th>empty</th>

                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(totalPerson * moneyPerChild).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>

                            <th className="text-center align-middle">
                                Tổng tiền trả lại
                            </th>
                            <th>empty</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(returnMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>

                            <th className="text-center align-middle">Đã chi:</th>
                            <th>empty</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={(totalMarketMoney + totalStoreMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </th>

                            <th className="text-center align-middle">Thừa:</th>
                            <th>empty</th>
                            <th className="text-end align-middle">
                                <CurrencyFormat
                                    value={((totalPerson * moneyPerChild) - (totalMarketMoney + totalStoreMoney) - returnMoney).toFixed(0)}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                    displayType="text"
                                />
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    <div className="d-block d-md-flex justify-content-between">
                        <div className="d-block d-sm-flex mb-3 mb-sm-0">
                            <Card.Title className="me-3 pt-1">
                                <i className="fas fa-list me-1"></i> Sổ tài chính công khai Tháng {this.state.start.getMonth() + 1} (Tuần {this.state.weekInMonth}) {this.stringFirstDate(this.state.start)} - {this.stringLastDate(this.state.term)}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                                <input
                                    type="week"
                                    name="week"
                                    style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            const firstDayOfWeek = new Date(e.target.valueAsNumber);
                                            const week = this.setDate(firstDayOfWeek);

                                            const newListDay = initialDay.map((day, index) => {
                                                return {
                                                    id: day.id,
                                                    day_name: day.day_name,
                                                    day: week[index]
                                                }
                                            })

                                            this.setState({
                                                weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                listDay: newListDay
                                            })

                                            this.getListVoucher(newListDay.filter(day => day.id === this.state.daySelect)[0].day, newListDay.filter(day => day.id === this.state.daySelect)[0].day);
                                            this.getClassData(newListDay.filter(dayItem => dayItem.id === this.state.daySelect)[0].day);

                                        } else {
                                            const today = new Date();
                                            const week = this.setDate(today);

                                            const newListDay = initialDay.map((day, index) => {
                                                return {
                                                    id: day.id,
                                                    day_name: day.day_name,
                                                    day: week[index]
                                                }
                                            })

                                            this.setState({
                                                weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                listDay: newListDay
                                            })

                                            this.getListVoucher(newListDay.filter(day => day.id === this.state.daySelect)[0].day, newListDay.filter(day => day.id === this.state.daySelect)[0].day);
                                            this.getClassData(newListDay.filter(dayItem => dayItem.id === this.state.daySelect)[0].day);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <Button
                            variant="success"
                            size="sm"
                            onClick={() => {
                                this.handleExport();
                            }}
                            disabled={!this.state.voucher || this.state.voucher?.ingredent?.length === 0}
                        >
                            Xuất file <i className="fa-solid fa-file-export"></i>
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    {this.RenderDayOption()}
                    {
                        !this.state.isLoading
                            ?
                            this.state.voucher
                                ?
                                this.RenderPublicMarketTable()
                                :
                                <div className="d-flex justify-content-center mt-3">
                                    Không có dữ liệu
                                </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                    <span className="sr-only">Đang tải sổ tài chính...</span>
                                </div>
                            </div>
                    }
                </Card.Body>
            </Card>
        )
    }
}

export default FinanceDayBook;