import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import RoleFormModal from "./RoleFormModal";
import Check from "../other/Check";

class RoleIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: '',
        role_name: '',
        is_rank: '',
        permission: [],
      },
      selectedRows: [],
      checkload: true
    };
  }

  componentDidMount() {
    document.title = "Chức vụ";
    this.getRoles();
  }

  getRoles = () => {
    axios.post(`/viewRole`).then((res) => {
      if (res.data.status === true) {
        this.setState({ checkload: false })
        this.setState({ roles: res.data.data });

      }
    });
  };

  getRole = (id) => {
    if (id !== '') {
      axios.post(`/viewRole?id_role=${id}`).then((res) => {
        if (res.data.status === true) {
          const data = res.data.data[0];

          this.setState({
            role: {
              id: data.id,
              role_name: data.role_name,
              is_rank: data.is_rank,
              permission: data.permission !== null ? data.permission.split(',') : [],
            }
          });

          this.setState({ modalRoleShow: true });
        }
      });
    }
  }

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa chức vụ?",
      text: `"${item.role_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(`/deleteRole?id_role=${item.id}`)
          .then((res) => {
            if (res.data.status === true) {
              toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }
  dataTable() {
    const columns = [
      {
        name: "Tên chức vụ",
        selector: (row) => row.role_name,
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.roles.length > 0) {
      this.state.roles.map((item, i) => {
        if (item.is_rank !== 1) {
          data.push({
            id: item.id,
            is_rank: item.is_rank,
            permission: item.permission,
            role_name: item.role_name,
            setting: (
              <>
                {new Check().permission(['62']) ?
                  <Button size='sm' variant="warning me-2" type="button" onClick={() => this.getRole(item.id)} title="Chi tiết chức vụ" >
                    <i className="fa-solid fa-pencil"></i>
                  </Button>
                  : null}
                {new Check().permission(['65']) ?
                  <Button size='sm' variant="danger" type="button" onClick={() => this.handleDelete(item)} title="Xóa chức vụ" >
                    <i className="fas fa-trash"></i>
                  </Button>
                  : null}
              </>
            ),
          });
        }

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: '',
        role_name: '',
        is_rank: '',
        permission: [],
      }
    });
  }




  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(['63']) ?
            <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ modalRoleShow: true })} >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
            : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách chức vụ
          </Card.Title>
        </Card.Header>

        <Card.Body>

          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? <>
          <RoleFormModal
            show={this.state.modalRoleShow}
            onHide={modalClose}
            modal={this.state.role.id === ''
              ? { title: 'Thêm chức vụ', button: <><i className="fa-solid fa-check" /> Lưu</> }
              : { title: 'Chi tiết chức vụ', button: <><i className="fa-solid fa-check" /> Cập nhật</> }}
            datarole={this.state.role}
            submit={modalSubmit}
          />
        </> : null
        }

      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
            <Breadcrumb.Item active>Chức vụ</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={6}>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RoleIndex;
