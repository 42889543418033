import React, { Component } from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {Modal, Button, Form} from "react-bootstrap";
import swal from "sweetalert";

class CategoryEdit extends Component {
    constructor(props){
        super(props);

        this.state = {
            dataInput: {
                id: '',
                category_name: '',
            }, 
        };
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        this.props.obj[field] = value;
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        let category_name = this.props.obj.category_name;
        let id_category = this.props.obj.id;
        swal({
            title: "Bạn muốn cập nhật danh mục?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if(ok){
                axios.post(`/updateCategory?id_category=${id_category}&category_name=${category_name}`)
                .then(res => {
                    if(res.data.status === true){
                        toast(res.data.data.mess, { type: "success" });
                        const btnClose = document.getElementById('btnCloseEditCategory');
                        btnClose.click();
                    }else{
                        toast(res.data.data.mess, { type: "error" });
                    }
                });
            }
        });
    }

    handleHide = () => {
        return  this.props.onHide();
    }

    render(){
        return (<>

            <Modal 
                {...this.props} 
                show={this.props.show}
                size="sm" 
                backdrop="static"
                keyboard={false} 
                id="editCategoryModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <i className="fa-solid fa-tag"></i> Chi tiết danh mục
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={this.handleInput} defaultValue={this.props.obj.category_name} name="category_name" id="category_name" placeholder="Tên danh mục" required />
                            <Form.Label>Tên danh mục<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseEditCategory" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit" >
                            <i className="fa-solid fa-pencil"></i> Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            </>
        );
    }
}

export default CategoryEdit;