import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import swal from "sweetalert";
dayjs.extend(customParseFormat);

export default function MealcutIndex() {
  let [dateRange, setDateRange] = useState([new Date(), new Date()]);
  let [startDate, endDate] = dateRange;
  const [show, setShow] = useState(false);
  const [idCut, setIdcut] = useState(0);
  const [ismeal, setIsmael] = useState(0);
  const [ListCut, setListCut] = useState([]);
  const [dataListStudent, setDataListStudent] = useState([]);
  const [dataListSearch, setDataListSearch] = useState([]);
  const [keyClass, setKeyClass] = useState({ value: 0, label: "Tất cả lớp" });
  const [keySearch, setKeySearch] = useState([]);
  const [optionsClass, setOptionsClass] = useState([
    { value: 0, label: "Tất cả lớp" },
  ]);

  const [selectCut, setSelectCut] = useState(1);
  const [NoteCut, setNoteCut] = useState("");
  const [selectOption, setselectoption] = useState([
    { label: "Nghỉ", value: 1 },
  ]);
  const [checkloading, setCheckloading] = useState(true);
  const [showCutMealDetail, setshowCutMealDetail] = useState(false);
  const [cutMealDetail, setcutMealDetail] = useState([]);
  const [showloading, setshowloading] = useState(false);
  const [startDatee, setStartDatee] = useState(
    new Date(new Date().setHours(0, 0, 0))
  );
  const [endDatee, setEndDate] = useState(
    new Date(new Date().setHours(23, 59, 59))
  );
  // update
  const [startDateFilterIdStudent, setStartDateFilterIdStudent] = useState(
    new Date()
  );
  const [endDateFilterIdStudent, setEndDateFilterIdStudent] = useState(
    new Date()
  );
  const [idStudentFilter, setIdStudentFilter] = useState(0);

  useEffect(() => {
    document.title = "Cắt suất ăn";
    getClassRoom(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      lastDayy(new Date().getFullYear(), new Date().getMonth() + 1)
    );
    setStartDateFilterIdStudent(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0))
    );
    setStartDatee(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)));
    setEndDateFilterIdStudent(
      lastDayy(new Date().getFullYear(), new Date().getMonth() + 1)
    );
    setEndDate(lastDayy(new Date().getFullYear(), new Date().getMonth() + 1));
  }, []);

  const getOption = () => {
    let option = [
      { label: "Nghỉ", value: 1 },
      { label: "Cắt suất", value: 2 },
    ];
    return (
      <Row>
        <i className="text-muted">
          <span className="text-danger">**</span>Lưu ý: Chọn{" "}
          <strong>"Cắt suất"</strong> nếu bé đi học nhưng không ăn.
        </i>

        <Col md={12} className="mb-3">
          <Select
            styles={{ height: "20px" }}
            options={ismeal ? option.filter((e) => e.value !== 2) : option}
            defaultValue={option[1]}
            value={selectOption}
            onChange={(choice) => {
              setSelectCut(choice.value);
              setselectoption(choice);
            }}
          ></Select>
        </Col>
        <Col>
          <Form.Floating>
            <Form.Control
              type="text"
              style={{ height: "40px" }}
              onChange={(e) => {
                setNoteCut(e.target.value);
              }}
              placeholder="Lý do cắt suất"
            />
            <Form.Label style={{ padding: "10px" }}>
              Lý do<i className="text-danger"></i>
            </Form.Label>
          </Form.Floating>
        </Col>
      </Row>
    );
  };

  const getTime = (data) => {
    let date = new Date(data * 1000);
    let result =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);

    return result;
  };

  const getClassRoom = (start, end) => {
    let dataOptionsClass = [];
    dataOptionsClass.push({
      value: 0,
      lable: "Tất cả lớp",
    });
    axios
      .get(
        `/classroom-shows?start_date=${parseInt(start / 1000)}&end_date=${
          end / 1000
        }`
      )
      .then((res) => {
        let dataRes = res.data.data?.classrooms;

        dataRes.map((item, index) => {
          dataOptionsClass.push({ value: item.id, label: item.class_name });
          return item;
        });
        setCheckloading(false);
        setOptionsClass(dataOptionsClass);
        // getMealByClasses(dataRes[0]?.id ? dataRes[0].id : 0, start, end);
        getStudentbyClass(
          {
            value: dataRes[0]?.id ? dataRes[0].id : 0,
            label: dataRes[0]?.class_name
              ? dataRes[0]?.class_name
              : " Tất cả lớp",
          },
          start,
          end
        );
      })
      .catch((err) => console.log(err));
  };

  const getStudentbyClass = async (data, start, end) => {
    setCheckloading(true);
    setKeyClass(data);

    const id_class = data.value;

    await axios
      .get(`/student-shows?id_class=${id_class}`)
      .then((res) => {
        setDataListStudent(res.data.data?.students);
        setCheckloading(false);
        getMealByClasses(id_class, start, end);
        handleSearch(res.data.data?.students, "", id_class);
      })
      .catch((err) => console.log(err));
  };

  const getMealByClasses = (classId, start, end) => {
    axios
      .get(
        `day-off-shows?id_class=${classId}&start_date=${parseInt(
          start / 1000
        )}&end_date=${end / 1000}`
      )
      .then((res) => {
        setListCut(res.data.data);
        // setCheckloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCutMealDetailData = (id_person, startDate, endDate) => {
    setshowCutMealDetail(true);
    setIdStudentFilter(id_person);

    axios
      .get(
        `/day-off-shows?id_student=${id_person}&start_date=${parseInt(
          startDate / 1000
        )}&end_date=${parseInt(endDate / 1000)}`
      )
      .then((res) => {
        setcutMealDetail(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const dataTable = (key) => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
        cell: (row) => <div className="text-wrap">{row.student_name}</div>,
      },
      {
        name: "Thời gian",
        selector: (row) => row.time,
        center: true,
      },

      {
        name: "SL duyệt / Chưa duyệt",
        selector: (row) => row.count,
        center: true,
      },
      {
        name: "Chi tiết",
        selector: (row) => row.setting,
        // sortable: true,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (dataListSearch.length > 0) {
      dataListSearch.map((item, i) => {
        data.push({
          student_name: item.student_name,
          count: (
            <>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {ListCut.length > 0
                  ? ListCut.filter(
                      (e) => e.id_student == item.id && e.is_status == 1
                    ).length
                  : 0}
              </span>{" "}
              /{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {ListCut.length > 0
                  ? ListCut.filter(
                      (e) => e.id_student == item.id && e.is_status == 0
                    ).length
                  : 0}
              </span>
            </>
          ),
          time: (
            <>
              <Button
                size="sm"
                disabled={
                  !new Check().permission(["73"]) ||
                  !(
                    new Date() / 1000 <
                      item.classHistories?.filter(
                        (e) => e.id_class == keyClass.value
                      )[0]?.end_at ||
                    item.classHistories?.filter(
                      (e) => e.id_class == keyClass.value
                    )[0]?.end_at == null
                  )
                }
                onClick={() => {
                  setShow(true);
                  setIdcut(item.id);

                  setDateRange([new Date(), new Date()]);
                }}
              >
                <i className="fa-solid fa-calendar-days" />
              </Button>
            </>
          ),
          count_student: item.count_student,

          setting: (
            <>
              <Button
                size="sm"
                title="Xem chi tiết"
                onClick={() => {
                  getCutMealDetailData(
                    item.id,
                    startDateFilterIdStudent,
                    endDateFilterIdStudent
                  );
                }}
              >
                <i className="fa-solid fa-eye" /> Xem
              </Button>
            </>
          ),
        });

        return item;
      });
    }

    return (
      <>
        <DataTables data={data} columns={columns} selectedRows={selectedRows} />
      </>
    );
  };

  const handleOnHideShow = () => {
    getMealByClasses(keyClass?.value, startDatee, endDatee);
    setshowCutMealDetail(false);
  };

  const renderDetailCutMealList = () => {
    return (
      <>
        <Modal
          size="xl"
          show={showCutMealDetail}
          onHide={handleOnHideShow}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-block d-md-flex my-xl-0 align-items-center">
              Chi tiết cắt suất {cutMealDetail[0]?.student_name}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="align-items-center">
              <Col md={3} xs={6} className="mb-2" style={{ zIndex: 3 }}>
                <DatePicker
                  className="form-control"
                  selected={startDateFilterIdStudent}
                  onChange={(date) => {
                    if (date) {
                      setStartDateFilterIdStudent(
                        new Date(date.setHours(0, 0, 0))
                      );

                      getCutMealDetailData(
                        idStudentFilter,
                        new Date(date.setHours(0, 0, 0)),
                        endDateFilterIdStudent
                      );
                    }
                  }}
                  selectsStart
                  maxDate={endDateFilterIdStudent}
                  dateFormat="dd/MM/yyyy"
                />
              </Col>

              <Col md={3} xs={6} className="mb-2" style={{ zIndex: 3 }}>
                <DatePicker
                  selected={endDateFilterIdStudent}
                  className="form-control"
                  onChange={(date) => {
                    if (date) {
                      setEndDateFilterIdStudent(
                        new Date(date.setHours(23, 59, 59))
                      );
                      getCutMealDetailData(
                        idStudentFilter,
                        startDateFilterIdStudent,
                        new Date(date.setHours(23, 59, 59))
                      );
                    }
                  }}
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  minDate={startDateFilterIdStudent}
                />
              </Col>
            </Row>

            {cutMealDetail.length > 0 ? (
              <>
                <Table responsive striped style={{ position: "relative" }}>
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      <th style={{ width: "50px" }}>Ngày</th>
                      <th className="text-center" style={{ width: "140px" }}>
                        Loại
                      </th>
                      <th style={{ width: "200px" }}>Lí do</th>
                      <th className="text-center" style={{ width: "150px" }}>
                        Ngày báo
                      </th>
                      <th className="text-center" style={{ width: "80px" }}>
                        Trạng thái
                      </th>
                      <th className="text-center" style={{ width: "150px" }}>
                        Người duyệt
                      </th>
                      <th className="text-center" style={{ width: "150px" }}>
                        Ngày duyệt
                      </th>
                      <th className="text-center" style={{ width: "100px" }}>
                        Hủy
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cutMealDetail.map((mealDetail) => (
                      <tr key={mealDetail.id}>
                        <td style={{ width: "50px" }} className="text-end">
                          {" "}
                          {getDate(mealDetail.date_off)}
                        </td>
                        <td className="text-center" style={{ width: "140px" }}>
                          {mealDetail.is_day_off == 1 ? "Xin nghỉ" : "Cắt suất"}
                        </td>
                        <td style={{ width: "200px" }}>
                          <span
                            style={{
                              width: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "block",
                            }}
                            title={mealDetail.notes}
                          >
                            {mealDetail.is_status == 1 &&
                            mealDetail.is_valid == 2
                              ? `${
                                  mealDetail.notes || ""
                                } (Báo trễ, duyệt bởi ${mealDetail.updater})`
                              : mealDetail.notes}
                          </span>
                        </td>
                        <td style={{ width: "150px" }}>
                          {getTime(mealDetail.created_at)}
                        </td>
                        <td className="text-center" style={{ width: "80px" }}>
                          {mealDetail.is_valid == 0 ? (
                            <span className="text-danger">Báo trễ</span>
                          ) : mealDetail.is_valid == 1 &&
                            mealDetail.is_status == 1 ? (
                            <i
                              className="fa-solid fa-check fa-lg text-success"
                              title="Đã duyệt"
                            />
                          ) : mealDetail.is_valid == 2 ? (
                            <span className="text-primary">Duyệt ngoại lệ</span>
                          ) : null}
                        </td>

                        <td className="text-center" style={{ width: "150px" }}>
                          {mealDetail.is_status
                            ? !mealDetail.is_valid
                              ? ""
                              : mealDetail.updater
                            : ""}
                        </td>
                        <td className="text-center" style={{ width: "150px" }}>
                          {mealDetail.is_status
                            ? !mealDetail.is_valid
                              ? ""
                              : getTime(mealDetail.updated_at)
                            : ""}
                        </td>

                        <td className="text-center">
                          <Button
                            variant="danger"
                            size="sm"
                            title="Xóa cắt suất"
                            onClick={() => delCut(mealDetail.id)}
                            hidden={mealDetail.is_status}
                          >
                            {" "}
                            <i className="fas fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                      // : null
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <p className="text-center">Không có dữ liệu</p>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleOnHideShow}>
              <i className="fa-solid fa-times" /> Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const selectClass = (value) => {
    getStudentbyClass(value, startDatee, endDatee);
    setKeySearch("");
  };

  const handleSearch = (root_data, text, id_class = keyClass.value) => {
    setKeySearch(text);
    let root_ = root_data;

    const today = Math.floor(Date.now() / 1000);

    root_ = root_.filter((student) => {
      return (
        student.classHistories.some((classHistory) => {
          const startAt = classHistory.start_at;
          const endAt = classHistory.end_at || Infinity;

          return (
            today >= startAt &&
            today <= endAt &&
            id_class === classHistory.id_class
          );
        }) && student.student_status === "1"
      );
    });

    if (text !== "") {
      root_ = root_.filter((e) =>
        toSlug(e.student_name).includes(toSlug(text.trim()))
      );
    }

    setDataListSearch(root_);
  };

  const handlCut = (idN) => {
    let since_day = startDate.setHours(0, 0, 0) / 1000;
    let to_day = endDate.setHours(23, 59, 59) / 1000;

    let data = {
      id_student: idN,
      is_day_off: selectCut == 1 ? 1 : 0,
      is_meal_cut: selectCut == 2 ? 1 : 0,
      start_date: since_day,
      end_date: to_day,
      notes: NoteCut,
    };

    axios
      .post("day-off-create", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        res.data.data?.map((data) => {
          return toast(getDate(data.day_off) + " " + data.message, {
            type: data.status ? "success" : "error",
            autoClose: 1500,
          });
        });
        setShow(false);
        setselectoption({ label: "Nghỉ", value: 1 });
        getMealByClasses(keyClass?.value, startDatee, endDatee);

        setSelectCut(1);
        setNoteCut("");
        setshowloading(false);
      })
      .catch((err) => {
        toast(
          err.response.data?.messages?.start_date ||
            err.response.data?.messages?.end_date ||
            "Hệ thống xảy ra lỗi",
          {
            type: "error",
            autoClose: 1500,
          }
        );
      });
  };

  const getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; //update 20230915
  };

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường

    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

    str = str.replace(/(\s+)/g, "-"); // Xóa khoảng trắng thay bằng ký tự -

    str = str.replace(/-+/g, "-"); // Xóa ký tự - liên tiếp

    str = str.replace(/^-+|-+$/g, ""); // xóa phần dư - ở đầu & cuối

    return str;
  };

  const loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const classify_1 = () => {
    return (
      <>
        {checkloading ? (
          loading()
        ) : dataListSearch.length > 0 ? (
          dataTable(1)
        ) : (
          <p className="text-center">Không có dữ liệu</p>
        )}
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectCut(1);
    setselectoption({ label: "Nghỉ", value: 1 });
  };

  const lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };

  const delCut = (id) => {
    swal({
      title: `Bạn chắc muốn xóa cắt xuất?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios.delete(`/day-off-destroy?id=${id}`).then((res) => {
          setcutMealDetail(cutMealDetail.filter((e) => e.id != id));
          toast(res.data.messages, { type: "success", autoClose: 1000 });
        });
      }
    });
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
        <Breadcrumb.Item active>Cắt suất ăn V2</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center ">
            <h5
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fas fa-list me-1 mt-1" />
              Cắt suất ăn tháng {startDatee.getMonth() + 1}/
              {startDatee.getFullYear()}
            </h5>

            <input
              type="month"
              name="month"
              style={{
                border: "none",
                width: 39,
                backgroundColor: "rgb(16,108,252)",
                borderRadius: 5,
              }}
              className="me-2"
              onChange={(e) => {
                const [year, month] = e.target.value.split("-");
                const startDate = new Date(year, month - 1);
                const endDate = lastDayy(
                  new Date(year, month - 1).getFullYear(),
                  Number(new Date(year, month - 1).getMonth()) + 1
                );

                setStartDateFilterIdStudent(startDate);
                setEndDateFilterIdStudent(endDate);

                setStartDatee(startDate);
                setEndDate(endDate);

                getStudentbyClass(keyClass, startDate, endDate);
              }}
            />
          </div>
        </Card.Header>
        <Card.Body>
          {checkloading ? (
            loading()
          ) : (
            <div>
              <Row className="row d-flex col-md-12 col-sm-12 px-0 mb-2 ms-0">
                <Col
                  md={3}
                  className="pe-1 ps-0"
                  style={{ minWidth: "150px", zIndex: 3 }}
                >
                  <Select
                    defaultValue={optionsClass[0]}
                    options={optionsClass.filter((e) => e.value > 0)}
                    placeholder="Chọn Lớp"
                    value={keyClass}
                    onChange={(choice) => {
                      selectClass(choice);
                    }}
                  />
                </Col>

                <Col md={4} className="ps-1" style={{ minWidth: "150px" }}>
                  <Form.Control
                    style={{ height: 39 }}
                    type="text"
                    placeholder="Nhập tên học sinh..."
                    value={keySearch}
                    onChange={(e) => {
                      handleSearch(dataListStudent, e.target.value);
                    }}
                  />
                </Col>
              </Row>

              {classify_1()}
            </div>
          )}
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose} size={"md"} centered>
        <Modal.Header closeButton>
          <Modal.Title>Chọn thời gian cắt suất</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <center>
                <DatePicker
                  size={{ height: "600px" }}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={!new Check().permission(["74"]) ? new Date() : 0}
                  selectsRange
                  selectsDisabledDaysInRange
                  inline
                />
              </center>
            </Col>

            <Col md={6}>{getOption()}</Col>
          </Row>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setSelectCut(1);
              setselectoption({ label: "Nghỉ", value: 1 });
              setShow(false);
            }}
          >
            <i className="fa-solid fa-times" /> Đóng
          </Button>
          <Button
            size="sm"
            disabled={startDate === null || endDate === null || showloading}
            onClick={() => {
              handlCut(idCut);
            }}
            variant="success"
          >
            <i className="fa-solid fa-check" /> Lưu
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showloading}
        onHide={handleClose}
        size={"md"}
        style={{ marginTop: "200px" }}
      >
        <Modal.Body>{loading()}</Modal.Body>
      </Modal>

      {renderDetailCutMealList()}
    </Container>
  );
}
