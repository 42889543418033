import axios from "axios";
import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GroupCreate from "../group/GroupCreate";
import Check from "../other/Check";

class ClassCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listgroup: [],
      classname: "",
      idgroup: this.props.id_group,
      iduser: "",
      show: false,
      type: "",
    };
  }
  componentDidMount() {
    this.getlistgroup();

  }
  componentDidUpdate(prevProps, prevState) {

    if (this.props.show != prevProps.show) {
      this.getlistgroup()
    }
  }

  getlistgroup = () => {
    axios
      .get("/viewClassGroup")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listgroup: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createclass() {
    var group = this.props._class.id_group == null ? this.props.id_group : this.props._class.id_group
    var id_user = this.state.iduser == null || this.state.iduser == 'null' ? "" : this.state.iduser
    axios
      .post(
        `/createClassRoom?class_name=${this.state.classname}&id_group=${group}&id_user=${id_user}`
      )
      .then((res) => {
        if (res.data.status) {
          this.props.close();
          toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
        } else {
          toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateclass() {
    if (this.props._class.id_group == null || this.props._class.id_group == 0) {
      toast(`Vui lòng chọn nhóm học sinh`, { type: "error", autoClose: 1000 });
    }
    else {
      axios
        .post(
          `/updateClassRoom?id_class=${this.props._class.id}&class_name=${this.props._class.class_name}&id_group=${this.props._class.id_group}&id_user=${Number(this.props._class.id_user) >= 1 ? this.props._class.id_user : ''}&user_now=${this.props.user_now}`
        )
        .then((res) => {
          if (res.data.status) {
            this.props.close();
            toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
          } else {
            toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }

  formclose = () => {
    this.setState({ show: false });
    this.getlistgroup();
  };

  renderGroupAdd = () => {

    return (
      <GroupCreate
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
      />
    );
  };

  render() {
    // console.log('props>>', this.props);
    return (
      <>
        <Modal show={this.props.show} onHide={this.props.close}  >
          <Modal.Header closeButton>
            {/* { this.getlistgroup()} */}
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              {this.props.PT === "TM" || this.props.PT === ""
                ? "Thêm lớp học"
                : "Cập nhật lớp học"}
            </h1>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  id="floatingInputClassName"
                  placeholder="Tên lớp học"
                  defaultValue={
                    this.props.PT === "TM" ||
                      this.props.PT === "" ||
                      this.props.PT === ""
                      ? ""
                      : this.props._class.class_name
                  }
                  onChange={(e) => {
                    if (this.props.PT === "TM" || this.props.PT === "") {
                      this.setState({ classname: e.target.value });
                    } else {
                      this.props._class.class_name = e.target.value;
                    }
                  }}
                />
                <Form.Label for="floatingInputClassName">
                  Tên lớp học<i className="text-danger">*</i>
                </Form.Label>
                <div className="invalid-feedback"></div>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Select
                  name="user_id"
                  id="selectUserId"
                  placeholder="Giáo viên"
                  defaultValue={
                    this.props.PT === "TM" || this.props.PT === ""
                      ? ""
                      : this.props._class.id_user
                  }
                  onChange={(e) => {
                    if (Number(e.target.value) >= 1) {
                      if (this.props.PT === "TM" || this.props.PT === "") {
                        this.setState({ iduser: e.target.value });
                      }
                      else {
                        this.props._class.id_user = e.target.value;
                      }
                    }
                    else {
                      if (this.props.PT === "TM" || this.props.PT === "") {
                        this.setState({ iduser: null });
                      }
                      else {
                        this.props._class.id_user = null;

                      }
                    }


                  }}
                >
                  <option value={0}>--Chọn--</option>

                  {this.props.listuser.map((user) => {
                    return user.role_name === "Giáo viên"
                      //  && (user.id_class==null || user.id_class==  this.props._class?.id )
                      ?
                      (
                        <option key={user.id} value={user.id}>
                          {user.user_name} -
                          {this.props.listclass?.filter(e => e.id == user.id_class)[0]?.class_name ?? " Chưa có lớp"
                          }
                        </option>
                      )
                      :
                      (
                        <div key={user.id}></div>
                      );
                  })}
                </Form.Select>
                <Form.Label for="selectUserId">
                  Giáo viên<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>
              {new Check().permission(['8',]) ?
                <Form.Group className="text-end">
                  <Link
                    className="fst-italic"
                    onClick={() => {
                      this.setState({
                        show: true,
                        type: "TM",
                      });
                    }}
                  >
                    +Thêm nhóm
                  </Link>
                </Form.Group> : null}

              {new Check().permission(['7',]) ?
                <Form.Floating className="mb-3">
                  <Form.Select
                    name="group_id"
                    id="selectGroupId"
                    placeholder="Nhóm học sinh"
                    defaultValue={
                      // this.props.PT === "TM" || this.props.PT === ""
                      //   ?  this.props.id_group
                      //   : 
                      // this.props._class.id_group
                      this.props.id_group
                    }

                    onChange={(e) => {
                      this.getlistgroup()
                      this.props._class.id_group = e.target.value;
                      // if (Number(e.target.value)>=1){
                      //   if (this.props.PT === "TM" || this.props.PT === "") {
                      //     // this.setState({ idgroup: e.target.value });
                      //     this.props.id_group= e.target.value 
                      //   } 
                      //   else {
                      //     // this.props._class.id_group = e.target.value;
                      //   }
                      // }else{
                      //   if (this.props.PT === "TM" || this.props.PT === "") {
                      //     // this.setState({ idgroup: null );
                      //     // this.props.id_group=0
                      //   } else {
                      //     // this.props._class.id_group = 0;
                      //   }
                      // }

                    }}
                  >
                    <option value={0}>--Chọn--</option>
                    {this.state.listgroup.map((group, i) => {
                      if (group.is_adult == 0 && group.is_main == 1) {
                        return (
                          <option key={group.id} value={group.id}>
                            {group.group_name}
                          </option>
                        );
                      } else {
                        return null
                      }
                    })}
                  </Form.Select>
                  <Form.Label for="selectGroupId">
                    Nhóm học sinh<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating> : null}
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="secondary"

              onClick={this.props.close}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              size="sm"
              type="submit"
              variant="success"
              onClick={() => {
                if (this.props.PT === "TM" || this.props.PT === "") {
                  this.createclass();
                } else {
                  this.updateclass();
                }
              }}
            >
              <i className="fa-solid fa-check"></i> Lưu
            </Button>

          </Modal.Footer>
        </Modal>
        {this.renderGroupAdd()}
      </>
    );
  }
}

export default ClassCreate;
