import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";

const FoodQuantitativeTable = ({ quantitative, setQuantitative, selectedClassGroup, ingredients }) => {
	const [selectedIngredients, setSelectedIngredients] = useState([]);

	if (!quantitative)
		return (<></>);

	const times = quantitative?.times;

	return (
		<Table bordered responsive>
			<thead className="align-middle text-center bg-light">
				<tr>
					<th>Buổi</th>
					<th>Món ăn</th>
				</tr>
			</thead>

			<tbody>
				{times?.map((timeItem, timeIndex) => {
					const groupId = selectedClassGroup.id;

					const foods = timeItem.foods
						.filter(food =>
							food.groups.some(group => group.id === groupId)
						);

					return (
						<React.Fragment key={`time-item-${timeIndex}`}>
							{foods.length === 0
								?
								<tr key={`time-item-${timeIndex}`} className="align-middle">
									<td width="15%" className="text-center fw-bold">
										{timeItem.time_name}
									</td>
									<td></td>
								</tr>
								:
								foods.map((foodItem, foodIndex) => {
									const foodId = foodItem.id;
									const group = foodItem?.groups.find((groupItem) => groupItem.id === groupId) || {};

									const ingredents = group?.ingredents || [];

									if (ingredents.length > 0) {
										return (
											<React.Fragment key={`time-item-${timeIndex}-${foodIndex}`} >
												<tr className="align-middle">
													{foodIndex === 0
														?
														<td rowSpan={foods.length} width="15%" className="text-center fw-bold">
															{timeItem.time_name}
														</td>
														:
														(<></>)
													}

													<td>
														<Link
															className="d-flex justify-content-between align-content-center text-dark "
															data-bs-toggle="collapse"
															to={`#ingredients-${timeItem.id}-${foodItem.id}`}
															title="Xem chi tiết nguyên liệu"
														>
															<span>
																<strong>{foodItem.food_name}</strong>
																{group?.more_meal != null && group?.more_meal > 0
																	? <i>&nbsp;(Số lượng: {group?.more_meal})</i>
																	: <></>
																}
															</span>
															<i className="fas fa-angle-down" />
														</Link>

														<span id={`ingredients-${timeItem.id}-${foodItem.id}`} className="collapse">
															<Table bordered className="mt-2 mb-0">
																<thead>
																	<tr className="text-center bg-light">
																		<th>STT</th>
																		<th>Nguyên liệu</th>
																		<th>ĐVT</th>
																		<th style={{ width: "10rem" }}>Định lượng</th>
																	</tr>
																</thead>
																<tbody>
																	{ingredents.map((ingredientItem, ingredientIndex) => {
																		const ingredientId = ingredientItem.id;
																		const ing_quantity = ingredientItem.ing_quantity;

																		return (
																			<tr key={`ingredient-item-${timeIndex}-${foodIndex}-${ingredientIndex}`} className="align-middle">
																				<td className="p-1 text-center">{ingredientIndex + 1}</td>
																				<td className="p-1">{ingredientItem.ingredent_name}</td>
																				<td className="p-1 text-center">{ingredientItem.unit_cook_name}</td>
																				<td className="p-1 text-end">
																					<CurrencyFormat
																						value={ing_quantity}
																						thousandSeparator={true}
																						displayType="input"
																						allowNegative={false}
																						decimalScale={2}
																						className="form-control text-end"
																						onValueChange={(values) => {
																							setQuantitative({
																								...quantitative,
																								times: times?.map((timeObj) => {
																									const timeId = timeObj.id;
																									const findGroup = times.filter(time => time.id === timeId)
																										.flatMap(time => time.foods)
																										.filter(food => food.id === foodId)
																										.flatMap(food => food.groups)
																										.find(group => group.id === groupId);

																									if (findGroup) {
																										return {
																											...timeObj,
																											foods: timeObj.foods?.map((foodObj, foodIndex) => {
																												if (foodObj.id === foodId) {
																													return {
																														...foodItem,
																														groups: foodItem.groups?.map((groupObj) => {
																															if (groupObj.id === groupId) {
																																return {
																																	...groupObj,
																																	ingredents: groupObj.ingredents?.map((ingredientObj) => {
																																		if (ingredientObj.id === ingredientId) {
																																			return {
																																				...ingredientObj,
																																				ing_quantity: values.value,
																																			};
																																		}

																																		return ingredientObj;
																																	}),

																																	more_meal: findGroup.more_meal
																																};
																															}

																															return groupObj;
																														})
																													};
																												}

																												return foodObj;
																											})
																										};
																									}

																									return timeObj;
																								})
																							})
																						}}
																					/>
																				</td>
																				<td className="p-1 text-center">
																					<Button
																						variant="outline-danger"
																						size="sm"
																						onClick={() => {
																							swal({
																								title: `Bạn muốn xóa nguyên liệu?`,
																								icon: "error",
																								buttons: ["Đóng", "Xóa"],
																								dangerMode: true,
																							}).then((ok) => {
																								if (ok) {
																									setQuantitative({
																										...quantitative,
																										times: times?.map((timeObj) => {
																											const timeId = timeObj.id;
																											const findGroup = times.filter(time => time.id === timeId)
																												.flatMap(time => time.foods)
																												.filter(food => food.id === foodId)
																												.flatMap(food => food.groups)
																												.find(group =>
																													group.id === groupId &&
																													group.ingredents.some(ingredient => ingredient.id === ingredientId)
																												);

																											if (findGroup) {
																												return {
																													...timeObj,
																													foods: timeObj.foods?.map((foodObj, foodIndex) => {
																														if (foodObj.id === foodId) {
																															return {
																																...foodItem,
																																groups: foodItem.groups?.map((groupObj) => {
																																	if (groupObj.id === groupId) {
																																		if (groupObj?.ingredents?.length > 1) {
																																			return {
																																				...groupObj,
																																				ingredents: groupObj.ingredents?.filter((ingredientObj) => ingredientObj.id !== ingredientId),
																																				more_meal: findGroup.more_meal
																																			};
																																		} else {
																																			toast('Món ăn phải có ít nhất 1 nguyên liệu.', { type: "error" });
																																			return groupObj;
																																		}
																																	}

																																	return groupObj;
																																})
																															};
																														}

																														return foodObj;
																													})
																												};
																											}
																											return timeObj;
																										})
																									});
																								}
																							})
																						}}

																						title="Xóa"
																					>
																						<i className="fa-solid fa-times" />
																					</Button>
																				</td>
																			</tr>
																		);
																	})
																	}

																	<tr>
																		<td></td>
																		<td >
																			<Select
																				options={ingredients.filter(a => !ingredents.some(b => a.id === b.id))}
																				className="text-start"
																				getOptionLabel={(option) => option.ingredent_name}
																				getOptionValue={(option) => option.id}
																				placeholder={<>Chọn nguyên liệu</>}
																				value={"Chọn nguyên liệu"}
																				onChange={(choice) => {
																					const ingredientId = choice.id;
																					const ingredientAdd = {
																						...choice,
																						ing_quantity: 0,
																						tmp_ing_quantity: 0,
																					};

																					setQuantitative({
																						...quantitative,
																						times: times?.map((timeObj) => {
																							const timeId = timeObj.id;

																							const findGroup = times.filter(time => time.id === timeId)
																								.flatMap(time => time.foods)
																								.filter(food => food.id === foodId)
																								.flatMap(food => food.groups)
																								.find(group =>
																									group.id === groupId
																								);

																							if (findGroup) {
																								return {
																									...timeObj,
																									foods: timeObj.foods?.map((foodObj, foodIndex) => {
																										if (foodObj.id === foodId) {
																											return {
																												...foodItem,
																												groups: foodItem.groups?.map((groupObj) => {
																													const checkIssetIngredient = groupObj.ingredents?.some(ingredient =>
																														ingredient.id === ingredientId
																													);

																													if (!checkIssetIngredient) {
																														let ingredentsAdd = groupObj.ingredents;

																														ingredentsAdd.push(ingredientAdd)

																														return {
																															...groupObj,
																															ingredents: ingredentsAdd,
																															more_meal: findGroup.more_meal
																														}
																													}
																													return {
																														...groupObj,
																														more_meal: findGroup.more_meal
																													}
																												})
																											};
																										}
																										return foodObj;
																									})
																								};
																							}

																							return timeObj;
																						})
																					});

																				}}

																				menuPortalTarget={document.querySelector("body")}
																				styles={{
																					menuPortal: (base) => ({
																						...base,
																						zIndex: 9999,
																					}),
																				}}
																			/>
																		</td>
																		<td></td>
																		<td></td>
																	</tr>
																</tbody>
															</Table>


														</span>
													</td>
												</tr>
											</React.Fragment>
										);
									}

									return (
										<React.Fragment key={`time-item-${timeIndex}-${foodIndex}`} >
											<tr className="align-middle">
												{foodIndex === 0
													?
													<td rowSpan={foods.length} width="15%" className="text-center fw-bold">
														{timeItem.time_name}
													</td>
													:
													(<></>)
												}

												<td className="fw-bold text-muted" style={{ cursor: "help" }} title={`Không có nguyên liệu.`}>
													<span>
														<strong>{foodItem.food_name}</strong>
														{group?.more_meal != null && group?.more_meal > 0
															?
															<i>&nbsp;(Số lượng: {group?.more_meal})</i>
															: <></>
														}
													</span>
												</td>
											</tr>
										</React.Fragment>
									)
								})
							}
						</React.Fragment>
					);
				})}
			</tbody>
		</Table >
	);
};

export default FoodQuantitativeTable;
