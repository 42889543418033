import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab, Form
} from "react-bootstrap";
import IngredentAdd from "./IngredentAdd";


import DataTables from "../datatable/DataTables";
import FormatData from "../FormatData";
import Check from "../other/Check";

class IngredentIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredents: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      deps: [],
      addModalShow: false,
      alertShow: false,
      ingredent: [],
      suppliers: [],
      units: [],
      selectedRows: [],
      tabkey: "1",
      typeModal: ""

    };
  }

  componentDidMount() {
    document.title = "Nguyên liệu";
    this.getIngredentsData(this.state.tabkey);
  }

  changeTab = (key) => {
   
    this.setState({ tabkey: key })
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" })
  }

  getIngredentsData = (key) => {
    let limit = 1000;
    axios.post(`/viewIngredent?ingredent_type=${key}&limit=${limit}`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          ingredents: res.data.data.sort(function (a, b) {
            return (b.updated_at - a.updated_at)
          })
        });
        this.handleIngredentSearch(res.data.data.sort(function (a, b) {
          return (b.updated_at - a.updated_at)
        }), this.state.ingredentTextSearch)
      }
    });
  };

  handleDelete = (event) => {
    let id_ingredent = event.currentTarget.dataset.ingredent_id;

    swal({
      title: "Bạn muốn xóa nguyên liệu?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(`/deleteIngredent?id_ingredent=${id_ingredent}`)
          .then((res) => {
            if (res.data.status === true) {
              toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              this.getIngredentsData(this.state.tabkey);
            } else {
              toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  delete(id) {
    axios.post(`/deleteIngredent?id_ingredent=${id}`).then((res) => {
      return res.data.status;
    });
  }

  handleDeleteList = () => {
    let selectedRows = this.state.selectedRows;

    if (selectedRows.length <= 0) {
      toast("Vui lòng chọn mục bạn muốn xóa.", { type: "warning" });
    } else {
      swal({
        title: "Bạn có chắc muốn xóa?",
        text: `${selectedRows.map((r) => r.title)}.`,
        icon: "error",
        buttons: ["Đóng", "Xóa"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          selectedRows.map((r) =>
            axios.post(`/deleteIngredent?id_ingredent=${r.id}`).then((res) => {
              if (res.data.status === true) {
                toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              }
            })
          );
          return this.getIngredentsData(this.state.tabkey);
        }
      });
    }
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);

  }

  dataTable(key) {
    const columns = [
      {
        name: "Nguyên liệu",
        selector: (row) => row.title,
        sortable: true,
        
        // style: {
				// 	div: {		  
				// 	  overflow: 'inherit !important ',
				// 	  width: '350px',
				// 	}
				//   }
      },
      {
        name: "Loại hàng",
        selector: (row) => row.ingredentTypeName,
        sortable: true,
        style: {
					div: {		  
					  overflow: 'inherit !important ',
					  width: '100px',
					}
				  }
      },
      {
        name: "Nhà cung cấp",
        selector: (row) => row.supplierName,
        sortable: true,
        cell: (row) =>
          <div style={{ width: "100%" }} className="text-wrap">

            {row.supplierName}</div>,
            style:{div:{width:'200px !important' }}
      },
      {
        name: "Đơn vị",
        selector: (row) => row.unitName,
        sortable: true,
      },
      {
        name: "Giá (VND)",
        selector: (row) => row.ingredentPrice,
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updatedAt,
        sortable: true,
        center: true,
        style: {
          div: {
            overflow: 'inherit !important ',
            width: '200px'
          }
        }
      },
      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item, i) => {
        const addModalShow = () => {
          this.setState({ addModalShow: true, typeModal: "CN" });
          this.setState({
            ingredent: {
              id: item.id,
              id_unit: item.id_unit,
              id_supplier: item.has_supplier === null ? "" : item.has_supplier.id_supplier,
              ingredent_name:
              
              item.ingredent_name,
           
              // ingredent_title:  
              //     <div style={{width: "100%"}} className="text-wrap">{item.ingredent_name}</div>,
              ingredent_price: item.has_supplier === null ? 0 : item.has_supplier.ingredent_price, ingredent_type: item.ingredent_type,

              ingredent_code: item.ingredent_code,
              type_subspecies: item.type_subspecies,
              ingredent_ratio: item.ingredent_ratio,
              ingredent_RealVolume: item.ingredent_RealVolume,
              ingredent_FineMass: item.ingredent_FineMass,
              ingredent_energy: item.ingredent_energy,
              ingredent_protein: item.ingredent_protein,
              ingredent_lipid: item.ingredent_lipid,
              ingredent_glucid: item.ingredent_glucid,
              ingredent_alarm: item.ingredent_alarm,
              is_bank : item.is_bank,
            },
          });
        };

        let updated_at = this.getDate(item.has_supplier?.created_at)
        let ingredent_price = new FormatData().currency(
          item.has_supplier === null ? 0 : item.has_supplier?.ingredent_price
        );
        data.push({
          id: item.id,

          title: <div style={{width: "100%"}} className="text-wrap"> 
          {item.is_bank ? <i className="fa-solid fa-check-circle" style={{color:"green"}}></i> : ""} {item.ingredent_name}</div>,
          ingredentTypeName: item.ingredentType_name,
          supplierName: item.has_supplier === null ? "" : item.has_supplier?.supplier_name,
          unitName: item.unit_name,
          ingredentPrice: ingredent_price,
          updatedAt: updated_at,
          setting: (
            <>
              {new Check().permission(['40']) ?
                <Button
                  variant="warning me-2"
                  type="button"
                  size="sm"
                  onClick={addModalShow}
                  title="Cập nhật nguyên liệu"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
                : null}
              {new Check().permission(['43']) ?
                <Button
                  variant="danger"
                  type="button"
                  size="sm"
                  onClick={this.handleDelete}
                  data-ingredent_id={item.id}
                  title="Xóa nguyên liệu"
                >
                  <i className="fas fa-trash"></i>
                </Button> : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text
    })
    if (text !== "") {
      root_ = this.state.ingredents.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim())));
    }
    this.setState({
      ingredents_search: root_
    })
  }

  boxSearch(){
    return (
      <Col className="mb-2">
        <Form.Control style={{ height: 39 }}
          type="text"
          placeholder="Tìm nguyên liệu"

          value={this.state.ingredentTextSearch}
          onChange={
            (e) => {
              this.handleIngredentSearch(this.state.ingredents, e.target.value)
            }
          }
        />
      </Col>
    )
  }

  renderIngredentList() {
    const addModalClose = () => {
      this.setState({ addModalShow: false });
      this.getIngredentsData(this.state.tabkey);
    };
    
    return (
      <>
        <Card.Header>
          {new Check().permission(['41']) ?
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ addModalShow: true, typeModal: "TM" })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button> : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách nguyên liệu
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {this.boxSearch()}

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              this.changeTab(k)            
            }}
            className="mb-3"
          >

            <Tab
              style={{ height: "650px", overflowY: "auto" }}
              key={1}
              title="Chợ"
              eventKey={1}
            >
              {this.dataTable(1)}
            </Tab>
            <Tab
              style={{ height: "650px", overflowY: "auto" }}
              key={2}
              title="Kho"
              eventKey={2}
            >
              {this.dataTable(2)}
            </Tab>
            <Tab
              style={{ height: "650px", overflowY: "auto" }}
              key={3}
              title="Vật tư"
              eventKey={3}
            >
              {this.dataTable(3)}
            </Tab>
          </Tabs>
        </Card.Body>

        {

          this.state.addModalShow ?
            <IngredentAdd show={this.state.addModalShow} onHide={addModalClose} keyTab={this.state.tabkey} typeModal={this.state.typeModal}
              obj={{ ingredent: this.state.ingredent }} />
            : ""
        }
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
						<Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Nguyên liệu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderIngredentList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IngredentIndex;
