import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment/moment";
class MedicModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: [],
      err: {},
      description: {
        times: [{ time: moment().unix() }],
        note: "",
      },
      start_date: moment().unix(),
      end_date: moment().unix(),
    };
  }

  componentDidMount() {
    this.setState({
      dataInput: this.props.datarole,
    });
  }
  convertToTimestamp(timeString, unixTimestamp) {
    const [hours, minutes] = timeString?.split(":").map(Number);

    const date = new Date(unixTimestamp * 1000);

    date.setUTCHours(hours, minutes, 0, 0);

    const newTimestamp = Math.floor(date.getTime() / 1000);

    return newTimestamp;
  }
  handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "time") {
      const newTimes = this.state.description?.times?.map((time, i) =>
        i === index
          ? {
              ...time,
              time: this.convertToTimestamp(value, moment().unix()),
            }
          : time
      );
      this.setState((prevState) => ({
        description: {
          ...prevState.description,
          times: newTimes,
        },
      }));
    } else if (name === "note") {
      delete this.state.err["note"];

      this.setState((prevState) => ({
        description: {
          ...prevState.description,
          note: value,
        },
      }));
    }
    //  else {
    //   this.setState((prevState) => ({
    //     dataInput: {
    //       ...prevState.dataInput,
    //       [name]: value,
    //     },
    //   }));
    // }
  };

  handleAddTime = () => {
    const newTime = { time: moment().unix() }; // Add a default current time
    const newTimes = [...this.state.description?.times, newTime];
    this.setState((prevState) => ({
      description: {
        ...prevState.description,
        times: newTimes,
      },
    }));
  };
  handleRemoveTime = (index) => {
    const newTimes = this.state.description?.times?.filter(
      (_, i) => i != index
    );
    this.setState((prevState) => ({
      description: {
        ...prevState.description,
        times: newTimes,
      },
    }));
  };
  generateMedicationDays(start, end, arr) {
    const medicationDay = [];
    let start_ = new Date(start * 1000).setHours(0, 0, 0) / 1000;
    let end_ = new Date(end * 1000).setHours(0, 0, 0) / 1000;

    const dayInSeconds = 24 * 60 * 60;
    for (let current = start_; current <= end_; current += dayInSeconds) {
      const times = arr?.times?.map((time) => ({
        time: time.time,
      }));

      medicationDay.push({
        dateTime: current,
        status: "",
        times: times,
        note: arr.note || "",
      });
    }

    return medicationDay;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.createEvaluation();
  };

  createEvaluation = () => {
    let data = {
      id_student: this.props.id_student,
      medicationDay: this.generateMedicationDays(
        this.state.start_date,
        this.state.end_date,
        this.state.description
      ),
    };

    axios
      .post("medication-reminder-create", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thêm thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }

  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Thêm nhắc nhỡ</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      value={moment
                        .unix(this.state.start_date)
                        .format("YYYY-MM-DD")}
                      name="start_date"
                      max={moment
                        .unix(this.state.end_date)
                        .format("YYYY-MM-DD")}
                      onChange={(e) => {
                        if (e.target.value) {
                          this.setState({
                            start_date: new Date(e.target.value) / 1000,
                          });
                          delete this.state.err[`payload.start_date`];
                        }
                      }}
                    />
                    <Form.Label>
                      Ngày bắt đầu
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`payload.start_date`]}
                    </span>
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      min={moment
                        .unix(this.state.start_date)
                        .format("YYYY-MM-DD")}
                      required
                      value={moment
                        .unix(this.state.end_date)
                        .format("YYYY-MM-DD")}
                      onChange={(e) => {
                        if (e.target.value) {
                          this.setState({
                            end_date: new Date(e.target.value) / 1000,
                          });
                          delete this.state.err[`payload.start_date`];
                        }
                      }}
                    />
                    <Form.Label>
                      Ngày kết thúc
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`payload.end_date`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              {/* <p className="text-center fw-bold">DANH SÁCH </p> */}

              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      // as="textarea"
                      name="note"
                      placeholder="Ghi chú"
                      value={this.state?.description?.note}
                      onChange={this.handleChange}
                      rows={3}
                      // required
                    />
                    <Form.Label>Ghi chú</Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`description.note`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              {this.state.description?.times.map((time, index) => (
                <>
                  <InputGroup className="me-2 mb-2">
                    <InputGroup.Text>Lần {index + 1}</InputGroup.Text>
                    <Form.Control
                      type="time"
                      name="time"
                      className="form-control"
                      value={moment.unix(time.time).format("HH:mm")}
                      onChange={(e) => {
                        this.handleChange(e, index);
                        delete this.state.err[
                          `description.times[${index}].time`
                        ];
                      }}
                      required
                    />{" "}
                    <Button
                      variant="outline-success"
                      onClick={this.handleAddTime}
                      // className="mb-2"
                      hidden={
                        this.state.description?.times?.length !== index + 1
                      }
                      title="Thêm"
                    >
                      <i className="fa-solid fa-plus" />
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        this.handleRemoveTime(index);

                        delete this.state.err[
                          `description.times[${index}].time`
                        ];
                      }}
                      disabled={this.state.description?.times?.length === 1}
                    >
                      X
                    </Button>{" "}
                  </InputGroup>
                  <span className="text-form-err">
                    {this.state.err[`description.times[${index}].time`]}
                  </span>
                </>
              ))}
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button size="sm" variant="success" type="submit">
                Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default MedicModal;
