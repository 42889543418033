import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Check from "../../admin/other/Check";
import swal from "sweetalert";

import {
  Breadcrumb,
  Button,
  Container,
  Card,
  Nav,
  Spinner,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import CategoryAdd from "./categoryPost/CategoryAdd";
import CategoryEdit from "./categoryPost/CategoryEdit";
import PostModal from "./post/PostModalAdd";
import PostModalEdit from "./post/PostModalEdit";

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

  return str;
};

const PostIndex = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [addCategoryModalShow, setAddCategoryModalShow] = useState(false);
  const [editCategoryModalShow, setEditCategoryModalShow] = useState(false);

  const [selectedPost, setSelectedPost] = useState(0);
  const [rootPosts, setRootPosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [addPostModalShow, setAddPostModalShow] = useState(false);
  const [editPostModalShow, setEditPostModalShow] = useState(false);
  const [dataPost, setDataPost] = useState({});
  const [dataImage, setDataImage] = useState();

  const [keyword, setKeyword] = useState("");
  const [isPostLoading, setIsPostLoading] = useState(false);

  useEffect(() => {
    document.title = "Bài viết";
    getListCategories();
  }, []);

  useEffect(() => {
    getListPosts(selectedCategory);
    setKeyword("");
  }, [selectedCategory]);

  useEffect(() => {
    setPosts(
      rootPosts.filter((postItem) =>
        toSlug(postItem.post_title.trim())?.includes(toSlug(keyword.trim()))
      )
    );
  }, [keyword]);

  const getListCategories = async () => {
    await axios
      .get(`/new-category-shows`)
      .then((res) => {
        setCategories(res.data.data.data?.docs || []);
        getListPosts(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hanldDeleteCategory = () => {
    swal({
      title: "Bạn muốn xóa chủ đề?",
      text: `"${categories.find((category) => category.id == selectedCategory)
        .category_name
        }"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/new-category-delete?id=${selectedCategory}`)
          .then((res) => {
            toast(res.data.messages || "TC", {
              type: "success",
              autoClose: 1000,
            });

            getListCategories();
            setSelectedCategory(0);
          })
          .catch((err) => {
            toast(
              err.response.data.messages.id ||
              err.response.data.messages ||
              "Lỗi",
              {
                type: "error",
                autoClose: 1000,
              }
            );
          });
      }
    });
  };

  const getListPosts = async (categoryId) => {
    setIsPostLoading(true);
    await axios
      .get(`/post-shows`, {})
      .then((res) => {
        setIsPostLoading(false);

        setPosts(
          res.data.data.data.docs?.filter(
            (e) => e.category?.id == categoryId || categoryId == 0
          )
        );
        setRootPosts(
          res.data.data.data.docs?.filter(
            (e) => e.category?.id == categoryId || categoryId == 0
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeletePost = (post) => {
    swal({
      title: "Bạn muốn xóa bài viết?",
      text: `"${post.post_title}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await axios
          .delete(`/post-delete`, {
            params: {
              id: post.id,
            },
          })
          .then((res) => {
            toast(res.data.messages || "TC", {
              type: "success",
              autoClose: 1000,
            });

            getListPosts(selectedCategory);
          })
          .catch((err) => {
            toast(
              err.response.data.messages.id ||
              err.response.data.messages ||
              "Lỗi",
              {
                type: "error",
                autoClose: 1000,
              }
            );
          });
      }
    });
  };
  // API

  // Logic
  const handleShowAddCategoryModal = () => {
    setAddCategoryModalShow(true);
  };
  const handleCloseAddCategoryModal = (isReload) => {
    setAddCategoryModalShow(false);

    if (isReload) {
      getListCategories();
    }
  };
  const handleShowEditCategoryModal = () => {
    setEditCategoryModalShow(true);
  };
  const handleCloseEditCategoryModal = (isReload) => {
    setEditCategoryModalShow(false);

    if (isReload) {
      getListCategories();
    }
  };

  const handleShowAddPostModal = () => {
    setAddPostModalShow(true);
  };
  const handleCloseAddPostModal = (isReload) => {
    setAddPostModalShow(false);

    if (isReload) {
      getListPosts(selectedCategory);
    }
  };
  const getPost = (id) => {
    axios
      .get(`/post-show?id=${id}`, {})
      .then(async (res) => {
        setEditPostModalShow(true);
        setSelectedPost(id);
        setDataPost(res.data.data.data);
        if (res.data.data.data.image) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL_IMAGE}${res.data.data.data.image?.url}`
          );
          const blob = await response.blob();
          const file = new File([blob], "image.jpg", {
            type: blob.type,
          });

          setDataImage(file);
        } else setDataImage("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseEditPostModal = (isReload) => {
    setEditPostModalShow(false);

    if (isReload) {
      getListPosts(selectedCategory);
    }
  };

  const columns = [
    {
      name: "Tên bài viết",
      selector: (row) => row.post_title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Chủ đề",
      selector: (row) => row.category?.id,
      sortable: true,
      cell: (row) => <div>{row.category?.category_name}</div>,
      wrap: true,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          {new Check().permission([155, 156], "or") ? (
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              title="Chi tiết"
              onClick={() => getPost(row.id)}
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : (
            ""
          )}

          {new Check().permission([157]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              title="Xóa"
              onClick={() => handleDeletePost(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <Container fluid className="px-3">
      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Bài viết</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh sách bài viết</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1" /> Danh sách bài viết

            <span className="float-end mt-1 mt-md-0">
              {new Check().permission([150]) ? (
                <Button
                  variant="success"
                  size="sm"
                  className="float-end ms-2"
                  onClick={() => handleShowAddCategoryModal()}
                  title="Thêm mới"
                >
                  <i className="fa-solid fa-plus" /> Thêm chủ đề
                </Button>
              ) : null}

              {new Check().permission([149, 151], "or") ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="float-end ms-2"
                  onClick={() => handleShowEditCategoryModal()}
                  hidden={selectedCategory === 0}
                  title="Xem chi tiết"
                >
                  <i className="fa-solid fa-pencil" /> Sửa chủ đề
                </Button>
              ) : null}

              {new Check().permission([152]) ? (
                <Button
                  variant="danger"
                  size="sm"
                  className="float-end ms-2"
                  onClick={() => hanldDeleteCategory()}
                  hidden={selectedCategory === 0}
                >
                  <i className="fa-solid fa-trash" /> Xóa chủ đề
                </Button>
              ) : null}
            </span>
          </Card.Title>
        </Card.Header>
        <Card.Body>


          <Nav variant="tabs" defaultActiveKey={0} className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey={0} onClick={() => setSelectedCategory(0)}>
                Tất cả
              </Nav.Link>
            </Nav.Item>

            {categories?.map((category) => {
              return (
                <Nav.Item key={category.id}>
                  <Nav.Link
                    eventKey={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.category_name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>

          <Row className="mb-2">
            <Col xs={12} md={4} >
              <Form.Control
                type="text"
                placeholder="Tìm bài viết"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Col>

            <Col xs={12} md={8} >
              {new Check().permission([155]) ? (
                <Button
                  variant="success"
                  size="sm"
                  className="float-end"
                  onClick={() => handleShowAddPostModal()}
                >
                  <i className="fa-solid fa-plus"></i> Thêm bài viết
                </Button>
              ) : <></>}
            </Col>
          </Row>

          {new Check().permission([154]) ?
            <DataTables
              data={posts}
              columns={columns}
              progressPending={isPostLoading}
              progressComponent={
                <Spinner
                  animation="border"
                  role="status"
                  variant="primary"
                  style={{ width: "3rem", height: "3rem" }}
                  className="mt-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              }
            />
            : <h6 className="text-muted">Bạn không có quyền truy cập!</h6>
          }

        </Card.Body>
      </Card>

      {addCategoryModalShow ? (
        <CategoryAdd
          show={addCategoryModalShow}
          onHide={handleCloseAddCategoryModal}
        />
      ) : null}

      {editCategoryModalShow ? (
        <CategoryEdit
          show={editCategoryModalShow}
          onHide={handleCloseEditCategoryModal}
          selectedCategory={categories.find(
            (category) => category.id == selectedCategory
          )}
        />
      ) : null}

      {addPostModalShow ? (
        <PostModal
          show={addPostModalShow}
          onHide={handleCloseAddPostModal}
          topic={selectedCategory}
        />
      ) : null}

      {editPostModalShow ? (
        <PostModalEdit
          show={editPostModalShow}
          onHide={handleCloseEditPostModal}
          selectedPost={selectedPost}
          data={dataPost}
          dataImage={dataImage}
        />
      ) : null}
    </Container>
  );
};

export default PostIndex;
