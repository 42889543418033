import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb, Form } from "react-bootstrap";


class BankFile extends Component {
    constructor(props) {
        super(props);

        this.state = {

            isAddIngredentListLoading: false,
            ingredentListFile: null,
            isAddFoodListLoading: false,
            foodListFile: null,
            isAddPermissionListLoading: false,
            permissionListFile: null,
            isAddEthnicListLoading: false,
            ethnicListFile: null,
            library: []
        };
    }

    componentDidMount() {
        document.title = 'Thư viện File';
        this.getLibrary();
    }
    handleImportStudentListExcel = (type) => {
        var bodyFormData = new FormData();
        if (type == 1) {
            bodyFormData.append('ingredent_excel', this.state.ingredentListFile);
            this.setState({
                isAddIngredentListLoading: true
            })
        }
        if (type == 2) {
            bodyFormData.append('permission_excel', this.state.permissionListFile);
            this.setState({
                isAddPermissionListLoading: true
            })
        }
        if (type == 3) {
            bodyFormData.append('ethnic_excel', this.state.ethnicListFile);
            this.setState({
                isAddEthnicListLoading: true
            })
        }
        if (type == 4) {
            bodyFormData.append('food_excel', this.state.foodListFile);
            this.setState({
                isAddFoodListLoading: true
            })
        }
        axios.post(`/importExcel?type=${type}`, bodyFormData)
            .then((res) => {
                if (res.data.status === true) {

                    toast("Lưu thành công", {
                        type: "success",
                        autoClose: 2000,
                    });
                    if (type == 1) {
                        this.setState({
                            isAddIngredentListLoading: false,
                            ingredentListFile: null
                        })
                        document.getElementById("ingredentListFile").value = "";
                    }
                    if (type == 4) {
                        this.setState({
                            isAddFoodListLoading: false,
                            foodListFile: null
                        })
                        document.getElementById("foodListFile").value = "";
                    }
                    if (type == 3) {
                        this.setState({
                            isAddEthnicListLoading: false,
                            ethnicListFile: null
                        })
                        document.getElementById("ethnicListFile").value = "";
                    }
                    if (type == 2) {
                        this.setState({
                            isAddPermissionListLoading: false,
                            permissionListFile: null
                        })
                        document.getElementById("permissionListFile").value = "";
                    }
                } else {
                    toast(res.data.data.mess, {
                        type: "error",
                        autoClose: 2000,
                    });
                    if (type == 1) {
                        this.setState({
                            isAddIngredentListLoading: false,
                        })
                    }
                    if (type == 4) {
                        this.setState({
                            isAddFoodListLoading: false,
                        })
                    }
                    if (type == 3) {
                        this.setState({
                            isAddEthnicListLoading: false,
                        })
                    }
                    if (type == 2) {
                        this.setState({
                            isAddPermissionListLoading: false,
                        })
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    handleSelectFile = (type, e) => {
        const fileExtention = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

        if (!fileExtention.some(ex => ex === e.target.files[0].type)) {
            toast("Sai định dạng tệp Excel !!!", {
                type: "error",
                autoClose: 3000,
            });
            return;
        }
        if (type == 1) {
            this.setState({
                ingredentListFile: e.target.files[0]
            })
        }
        if (type == 2) {
            this.setState({
                permissionListFile: e.target.files[0]
            })
        }
        if (type == 3) {
            this.setState({
                ethnicListFile: e.target.files[0]
            })
        }
        if (type == 4) {
            this.setState({
                foodListFile: e.target.files[0]
            })
        }

    }
    getLibrary = () => {
        axios.get(`/viewLibrary`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        library: res.data.data
                    });
                }
            });
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        if (event.target.name === "changePhone") { value = event.target.checked }
        else { value = event.target.value };
        let school = { ...this.state.school };
        school[field] = value;
        this.setState({ school: school });
    }

    updateSubmit = (e) => {
        e.preventDefault();
        let changePhoneT = ""
        const id_school = this.state.school.id_school;
        const school_name = this.state.school.school_name;
        const school_author = this.state.school.school_author;
        const school_phone = this.state.school.school_phone;
        const school_email = this.state.school.school_email;
        const school_address = this.state.school.school_address;
        const school_logo = this.state.school.school_logo;
        if (this.state.school.changePhone === true) { changePhoneT = "1" } else { changePhoneT = "0"; }
        const changePhone = changePhoneT;
        swal({
            title: "Bạn muốn cập nhật trường học?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(`/updatedSchool?id_school=${id_school}&school_name=${school_name}&school_email=${school_email}&school_phone=${school_phone}&school_address=${school_address}&school_author=${school_author}&changePhone=${changePhone}&school_logo${school_logo}`
                ).then(res => {
                    if (res.data.status === true) {
                        toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                    } else {
                        toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                    }
                });
            }
        });
    }

    renderFile() {
        return (<>
            <Card.Header>
                <Card.Title>
                    <i className="fa-solid fa-file-pen"></i> Thư viện File
                </Card.Title>
            </Card.Header>

            <Form onSubmit={this.updateSubmit}>
                <Card.Body>
                    <Row>
                        <Col>Thư viện nguyên liệu</Col>
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Tên file"
                                    required
                                    disabled
                                />
                                <Form.Label>
                                    {this.state.library.length > 0 && this.state.library.filter(e => e.type_module == 1).length > 0 ? "Đã có File" : "Chưa có File"}
                                </Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col>
                            {
                                this.state.isAddIngredentListLoading
                                    ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="me-3">
                                            <div>Đang thêm mới ngân hàng nguyên liệu...</div>
                                        </div>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-block d-xl-flex my-3 my-md-0">
                                        <div className="d-block d-md-flex my-3 my-xl-0">

                                            <label className="btn btn-success me-3" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="ingredentListFile"><i className="fas fa-file-import"></i> Chọn File</label>
                                            <input
                                                type="file"
                                                id="ingredentListFile"
                                                className="d-none"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                multiple={false}
                                                onChange={(e) => this.handleSelectFile(1, e)}
                                                disabled={this.state.isAddIngredentListLoading}
                                            />

                                            {
                                                this.state.ingredentListFile
                                                    ?
                                                    <div className="d-flex mt-3 mt-md-0">
                                                        <div style={{ minWidth: 100 }} className="d-flex align-items-center text-truncate me-3">Đã chọn: {this.state.ingredentListFile.name}</div>

                                                        <Button
                                                            type="button"
                                                            variant="success"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => this.handleImportStudentListExcel(1)}
                                                            disabled={this.state.isAddIngredentListLoading}
                                                        >
                                                            Lưu
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => {
                                                                document.getElementById("ingredentListFile").value = "";
                                                                this.setState({
                                                                    ingredentListFile: null
                                                                })
                                                            }}
                                                            disabled={this.state.isAddIngredentListLoading}
                                                        >
                                                            Hủy
                                                        </Button>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                            }

                        </Col>
                    </Row>
                    <Row>
                        <Col>Thư viện món ăn</Col>
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Tên file"
                                    required
                                    disabled
                                />
                                <Form.Label>
                                    {this.state.library.length > 0 && this.state.library.filter(e => e.type_module == 4).length > 0 ? "Đã có File" : "Chưa có File"}
                                </Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col>
                            {
                                this.state.isAddFoodListLoading
                                    ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="me-3">
                                            <div>Đang thêm mới ngân hàng món ăn...</div>
                                        </div>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-block d-xl-flex my-3 my-md-0">
                                        <div className="d-block d-md-flex my-3 my-xl-0">

                                            <label className="btn btn-success me-3" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="foodListFile"><i className="fas fa-file-import"></i> Chọn File</label>
                                            <input
                                                type="file"
                                                id="foodListFile"
                                                className="d-none"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                multiple={false}
                                                onChange={(e) => this.handleSelectFile(4, e)}
                                                disabled={this.state.isAddFoodListLoading}
                                            />

                                            {
                                                this.state.foodListFile
                                                    ?
                                                    <div className="d-flex mt-3 mt-md-0">
                                                        <div style={{ minWidth: 100 }} className="d-flex align-items-center text-truncate me-3">Đã chọn: {this.state.foodListFile.name}</div>

                                                        <Button
                                                            type="button"
                                                            variant="success"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => this.handleImportStudentListExcel(4)}
                                                            disabled={this.state.isAddFoodListLoading}
                                                        >
                                                            Lưu
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => {
                                                                document.getElementById("foodListFile").value = "";
                                                                this.setState({
                                                                    foodListFile: null
                                                                })
                                                            }}
                                                            disabled={this.state.isAddFoodListLoading}
                                                        >
                                                            Hủy
                                                        </Button>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                            }

                        </Col>
                    </Row>
                    <Row>
                        <Col>Thư viện phân quyền</Col><Col>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Tên file"
                                    required
                                    disabled
                                />
                                <Form.Label>
                                    {this.state.library.length > 0 && this.state.library.filter(e => e.type_module == 2).length > 0 ? "Đã có File" : "Chưa có file"}
                                </Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col>   <Col>
                            {
                                this.state.isAddPermissionListLoading
                                    ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="me-3">
                                            <div>Đang thêm mới phân quyền...</div>
                                        </div>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-block d-xl-flex my-3 my-md-0">
                                        <div className="d-block d-md-flex my-3 my-xl-0">

                                            <label className="btn btn-success me-3" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="permissionListFile"><i className="fas fa-file-import"></i> Chọn File</label>
                                            <input
                                                type="file"
                                                id="permissionListFile"
                                                className="d-none"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                multiple={false}
                                                onChange={(e) => this.handleSelectFile(2, e)}
                                                disabled={this.state.isAddPermissionListLoading}
                                            />

                                            {
                                                this.state.permissionListFile
                                                    ?
                                                    <div className="d-flex mt-3 mt-md-0">
                                                        <div style={{ minWidth: 100 }} className="d-flex align-items-center text-truncate me-3">Đã chọn: {this.state.permissionListFile.name}</div>

                                                        <Button
                                                            type="button"
                                                            variant="success"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => this.handleImportStudentListExcel(2)}
                                                            disabled={this.state.isAddPermissionListLoading}
                                                        >
                                                            Lưu
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => {
                                                                document.getElementById("permissionListFile").value = "";
                                                                this.setState({
                                                                    permissionListFile: null
                                                                })
                                                            }}
                                                            disabled={this.state.isAddPermissionListLoading}
                                                        >
                                                            Hủy
                                                        </Button>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                            }

                        </Col></Col>
                    </Row>
                    <Row className="align-middle ">
                        <Col >Thư viện dân tộc</Col>
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    type="text"
                                    // value={this.state.foodDetail.food_name}


                                    placeholder="Tên file"
                                    required
                                    disabled
                                />
                                <Form.Label>
                                    {this.state.library.length > 0 && this.state.library.filter(e => e.type_module == 3).length > 0 ? "Đã có File" : "Chưa có file"}
                                </Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col>   <Col>
                            {
                                this.state.isAddEthnicListLoading
                                    ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="me-3">
                                            <div>Đang thêm mới dân tộc...</div>
                                        </div>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-block d-xl-flex my-3 my-md-0">
                                        <div className="d-block d-md-flex my-3 my-xl-0">

                                            <label className="btn btn-success me-3" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="ethnicListFile"><i className="fas fa-file-import"></i> Chọn File</label>
                                            <input
                                                type="file"
                                                id="ethnicListFile"
                                                className="d-none"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                multiple={false}
                                                onChange={(e) => this.handleSelectFile(3, e)}
                                                disabled={this.state.isAddEthnicListLoading}
                                            />

                                            {
                                                this.state.ethnicListFile
                                                    ?
                                                    <div className="d-flex mt-3 mt-md-0">
                                                        <div style={{ minWidth: 100 }} className="d-flex align-items-center text-truncate me-3">Đã chọn: {this.state.ethnicListFile.name}</div>

                                                        <Button
                                                            type="button"
                                                            variant="success"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => this.handleImportStudentListExcel(3)}
                                                            disabled={this.state.isAddEthnicListLoading}
                                                        >
                                                            Lưu
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            size="sm"
                                                            className="me-3"
                                                            onClick={() => {
                                                                document.getElementById("ethnicListFile").value = "";
                                                                this.setState({
                                                                    ethnicListFile: null
                                                                })
                                                            }}
                                                            disabled={this.state.isAddEthnicListLoading}
                                                        >
                                                            Hủy
                                                        </Button>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                            }

                        </Col></Col>
                    </Row>
                </Card.Body>


            </Form>
        </>);
    }

    render() {
        return (<>
            <Container fluid className="px-3">
                <Breadcrumb className="mb-2 mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                        Tổng quan
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Thư viện file</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    {/* <Col md={5}> */}
                    <Card className="mb-4">
                        {this.renderFile()}
                    </Card>
                    {/* </Col> */}
                </Row>
            </Container>
        </>);
    }
}

export default BankFile;