import React, { Component } from "react";
import axios from "axios";
import { Button, Container, Card, Row, Col, Form, Table } from "react-bootstrap";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";


const initialDay = [
    {id: 1, day_name: "Thứ 2"},
    {id: 2, day_name: "Thứ 3"},
    {id: 3, day_name: "Thứ 4"},
    {id: 4, day_name: "Thứ 5"},
    {id: 5, day_name: "Thứ 6"},
    {id: 6, day_name: "Thứ 7"},
    {id: 7, day_name: "Chủ nhật"}
]

class NutritionPortionDayReport extends Component {
    constructor(state) {
		super(state);
		this.state = {
            quantitative: {},

            isLoading: false,

            listDay: [],
            daySelect: 0,

            groupOption: [],
            groupSelect: {value: 0, label: ""},

            start: new Date(),
            term: new Date(),

            weekInMonth: ""
		};
	}

	async componentDidMount() {
        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        if(todayIs === 0){
            todayIs = 7;
        }

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: initialDay.map((day, index) =>{
                return{
                    id: day.id,
                    day_name: day.day_name,
                    day: initialWeek[index]
                }
            })
        });

        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length-1], todayIs, {value: 0, label: ""}, {value: 0, label: ""});
	}

    getQuantitativeVoucher = (startDay, endDay, daysId, groupSelect) => {
        this.setState({
            isLoading: true
        })

        axios.post(`/voucher?is_real=0&type=2&start_at=${startDay.getTime()/1000}&term_at=${endDay.getTime()/1000}&id_day=${daysId}`)
        .then(res => {
            this.setState({
                isLoading: false
            })

            if (res.data.status === true) {
                if(res.data.data.length !==0 && res.data.data[0].idGroup.length !== 0 ){

                    const newGroupOption = res.data.data[0].idGroup
                    .sort(function(a, b) { 
                            return b.id - a.id;
                        }
                    )
                    .map(item => {
                            return{
                                value: Number(item.id),
                                label: `${item.group_name}`,
                            }
                        })
                    .filter(item => !isNaN(item.value))

                    this.setState({
                        quantitative: res.data.data[0],
                        groupOption: newGroupOption,
                        groupSelect: groupSelect.value !== 0 ? groupSelect : newGroupOption[0],
                    })
                }  else {
                    this.setState({
                        quantitative: {},
                        groupOption: [],
                        groupSelect: {value: 0, label: ""},
                    })
                }
            } else {
                this.setState({
                    quantitative: {},
                    groupOption: [],
                    groupSelect: {value: 0, label: ""},
                })
            }
        });
    }

    //#region Handle Logic
    handleXportPortion = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn xuất file dưỡng chất 1 trẻ`,
            icon: "info",
            buttons: ["Đóng", "Xuất"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }
    
    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day),
        });

        this.getQuantitativeVoucher(this.state.start, this.state.term, day, {value: 0, label: ""}, {value: 0, label: ""})
    }

    xport = () => {
        const wb = XLSX.utils.book_new();
        const ws = this.dayNutritionPortion();
        
        XLSX.utils.book_append_sheet(wb, ws, "Dưỡng chất 1 trẻ");
        XLSX.writeFile(wb, "Dưỡng chất 1 trẻ.xlsx");
    }

    dayNutritionPortion = () => {
        const table = document.getElementById("day-nutrition-table");

        var ws = XLSX.utils.table_to_sheet(table)

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", 'K', "L"];

        if(ws[`A1`].v === "empty"){
            ws[`A1`].v = ' '
        }

        ws[`A1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        ws[`G1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "right"
            }
        };

        ws[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
                sz: 16
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws[`A3`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws[`G3`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        colAlpha.map((alpha) => {
            ws[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {
            ws[`${alpha}5`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
            return alpha;
        })

        let allIngredient = [];

        this.state.quantitative?.time?.map(timeItem => {
            return timeItem.detail.map(foodItem => {
                return foodItem.ingrendent.map(ingredientItem => {
                    allIngredient.push({
                        ...ingredientItem,
                        calculator: timeItem.is_calcultor,
                    })
                    return ingredientItem;
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");

        let ingredientIndex = 0;
        const moneyFormat = "#,###,###,###";

        aIngredientArray.map((ingredientArray) => {
            let realMassOne = 0;

            ingredientArray.map(ingredientItem => {
                const selectedGroup = ingredientItem.group?.find(groupItem => Number(groupItem.id) === this.state.groupSelect.value);

                if(selectedGroup){
                    if(ingredientItem.unit.is_root === 0){
                        realMassOne += Number(selectedGroup.amount);
                    } else {
                        realMassOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }
                }
                return ingredientItem;
            })

            if(realMassOne === 0){
                return "";
            }

            ingredientIndex++;

            return ingredientArray;
        })

        for(let i=6; i<ingredientIndex+6; i++){
            colAlpha.map(alpha => {
                if(ws[`${alpha}${i}`].v === "empty"){
                    ws[`${alpha}${i}`].v = ' '
                }

                ws[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                ws[`K${i}`].z = moneyFormat;
                delete ws[`K${i}`].w;
                XLSX.utils.format_cell(ws[`K${i}`]);

                ws[`L${i}`].z = moneyFormat;
                delete ws[`L${i}`].w;
                XLSX.utils.format_cell(ws[`L${i}`]);
                return alpha;
            })
        }

        for(let i=ingredientIndex+6; i<ingredientIndex+9; i++){
            colAlpha.map(alpha => {
                if(ws[`${alpha}${i}`].v === "empty"){
                    ws[`${alpha}${i}`].v = ' '
                }

                ws[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: alpha === "A" ||  alpha === "D" || alpha === "F" ? "center" : "right",
                        wrapText: true
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })
        }

        ws[`I${ingredientIndex+6}`].z = moneyFormat;
        delete ws[`I${ingredientIndex+6}`].w;
        XLSX.utils.format_cell(ws[`I${ingredientIndex+6}`]);

        ws[`C${ingredientIndex+8}`].z = moneyFormat;
        delete ws[`C${ingredientIndex+8}`].w;
        XLSX.utils.format_cell(ws[`C${ingredientIndex+8}`]);

        ws['!cols'] = [
            {wch:3.5},
            {wch:16.5},
            {wch:6},
            {wch:5},
            {wch:5},
            {wch:5},
            {wch:5},
            {wch:6},
            {wch:4.5},
            {wch:6},
            {wch:8},
            {wch:7},
        ];

        const row = [{hpt: 30}, {hpt: 30}, {hpt: 60}, {hpt: 30}, {hpt: 30}];
        ws['!rows'] = row;

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
            { s: { r: 0, c: 6 }, e: { r: 0, c: 11 } },

            { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 5 } },
            { s: { r: 2, c: 6 }, e: { r: 2, c: 11 } },
            // Title

            // Header
            { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
            { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
            { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

            { s: { r: 3, c: 3 }, e: { r: 3, c: 4 } },
            { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },

            { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
            { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
            { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
            { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } },
            { s: { r: 3, c: 11 }, e: { r: 4, c: 11 } },

            // Footer
            { s: { r: 6+ingredientIndex-1, c: 0 }, e: { r: 6+ingredientIndex-1, c: 1 } },
            { s: { r: 6+ingredientIndex-1, c: 3 }, e: { r: 6+ingredientIndex-1, c: 4 } },
            { s: { r: 6+ingredientIndex-1, c: 5 }, e: { r: 6+ingredientIndex-1, c: 6 } },
            { s: { r: 6+ingredientIndex-1, c: 8 }, e: { r: 6+ingredientIndex-1, c: 11 } },

            { s: { r: 6+ingredientIndex, c: 0 }, e: { r: 6+ingredientIndex, c: 1 } },
            { s: { r: 6+ingredientIndex, c: 3 }, e: { r: 6+ingredientIndex, c: 4 } },
            { s: { r: 6+ingredientIndex, c: 5 }, e: { r: 6+ingredientIndex, c: 6 } },
            { s: { r: 6+ingredientIndex, c: 8 }, e: { r: 6+ingredientIndex, c: 11 } },

            { s: { r: 6+ingredientIndex+1, c: 0 }, e: { r: 6+ingredientIndex+1, c: 1 } },
            { s: { r: 6+ingredientIndex+1, c: 2 }, e: { r: 6+ingredientIndex+1, c: 11 } },
        ];
        ws["!merges"] = merge;

        return ws;
    }

    dates = (current) => {
        var week= []; 
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() +1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            ); 
            current.setDate(current.getDate() +1);
            current.setHours(0, 0, 0, 0);
        }
        return week; 
    }

    setDate = (theDate) =>{
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length-1]
        })

        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();

    group = (arr, key) => {
        return [...arr.reduce( (acc, o) => 
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
        , new Map()).values()];
    }
    //#endregion

    // Render
    RenderOption() {
        return(
            <>
                <Card.Title>Chọn ngày tính dưỡng chất 1 trẻ</Card.Title>

                <div className="d-none d-md-flex justify-content-between align-items-center my-3">
                    <div className="d-flex justify-content-start">
                        {
                            this.state.listDay.map((dayItem, i) => {
                                let toDayIs = dayItem.id % 7;

                                if(toDayIs === 0 ){
                                    toDayIs = 7;
                                }
                                
                                return (
                                    <div key={i} className="d-flex me-md-4 me-xl-5">
                                        <Form.Check
                                            type="radio"
                                            value={dayItem.id}
                                            checked={this.state.daySelect === Number(dayItem.id)}
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    this.handleCheck(e.target.value)
                                                }
                                            }}
                                            name="day"
                                            className="me-2"
                                        />
                                        {dayItem.day_name}
                                    </div>

                                );
                        })}
                    </div>

                    {
                        !this.state.isLoading
                        ?
                            this.state.groupOption.length !== 0
                            ?
                                <Select
                                    options={this.state.groupOption}
                                    value={this.state.groupSelect}
                                    onChange={(choice) => {
                                        this.setState({
                                            groupSelect: choice,
                                        })
                                    }}
                                />
                            : ""
                        : ""
                    }
                </div>

                <Row className="my-3 d-md-none">
                    <Col xs={6} md={4}>
                        {this.state.listDay
                            .slice(
                                0,
                                Math.round(this.state.listDay.length / 2)
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if(toDayIs === 0 ){
                                    toDayIs = 7;
                                }
                                
                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div  className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if(e.target.checked){
                                                            this.handleCheck(e.target.value)
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                        })}
                    </Col>

                    <Col xs={6} md={4}>
                        {this.state.listDay
                        .slice(
                            Math.round(this.state.listDay.length / 2),
                            this.state.listDay.length
                        )
                        .map((dayItem, i) => {

                            let toDayIs = dayItem.id % 7;

                            if(toDayIs === 0 ){
                                toDayIs = 7;
                            }

                            return (
                                <Row key={i}
                                >
                                    <Col xs={1} md={1}>
                                    <div className="text-center">
                                        <Form.Check
                                            type="radio"
                                            value={dayItem.id}
                                            checked={this.state.daySelect === Number(dayItem.id)}
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    this.handleCheck(e.target.value)
                                                }
                                            }}
                                            name="dayM"
                                        />
                                    </div>
                                    </Col>
                                    <Col xs={10} md={6}>
                                        {dayItem.day_name}
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>

                <div className="d-flex d-md-none mb-3">
                        {
                            !this.state.isLoading
                            ?
                                this.state.groupOption.length !== 0
                                ?
                                    <Select
                                        options={this.state.groupOption}
                                        value={this.state.groupSelect}
                                        onChange={(choice) => {
                                            this.setState({
                                                groupSelect: choice,
                                            })
                                        }}
                                    />
                                : ""
                            : ""
                        }
                </div>
            </>
        )
    }

    RenderDayNutritionPortion(aIngredientArray, totalCostMoney) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });

        if(aIngredientArray.length === 0){
            return(
                <div className="d-flex justify-content-center align-items-center my-3">
                    Chưa có thực đơn hoặc bạn chưa lưu khẩu phần ăn
                </div>
            )
        }

        let ortherFee = 0;

        let recommendFrom = 0;

        this.state.quantitative?.time?.map(timeItem => {
            if(timeItem.is_calcultor.length !== 0 ){
                timeItem.is_calcultor.map(calculateItem => {
                    if( Number(calculateItem.id_group) === this.state.groupSelect.value){
                        ortherFee += Number(calculateItem.price_orther);
                    }

                    return calculateItem;
                })
            }

            if(timeItem.idGroup.length !== 0){
                timeItem.idGroup.map(groupItem => {
                    if( Number(groupItem.id) === Number(this.state.groupSelect.value)){
                        recommendFrom = Number(groupItem.recommended_from) !== 0 ? Number(groupItem.recommended_from) : recommendFrom;
                    }

                    return groupItem;
                })
            }

            return timeItem;
        })

        let energyStat = 0; 
        let proteinStat = 0; 
        let lipidStat = 0;
        let glucidStat = 0;
        
        let ingredientIndex = 0;
        let ingredientIndex1 = 0;
        return(
            <>
                <Table 
                    bordered 
                    responsive
                >
                    <thead>
                        <tr className="text-center align-middle">
                            <th rowSpan={2}>STT</th>
                            <th rowSpan={2}>Tên thực phẩm</th>
                            <th rowSpan={2}>Năng lượng(Kcal)</th>
                            <th colSpan={2}>Đạm(g)</th>
                            <th colSpan={2}>Béo(g)</th>
                            <th rowSpan={2}>Bột Đường(g)</th>
                            <th rowSpan={2}>Ăn được (g)</th>
                            <th rowSpan={2}>Đi chợ (g)</th>
                            <th rowSpan={2}>Đơn giá (đ/100g)</th>
                            <th rowSpan={2}>Thành tiền (đ)</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th>ĐV</th>
                            <th>TV</th>
                            <th>ĐV</th>
                            <th>TV</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            aIngredientArray.map((ingredientArray) => {
                                // Nutrition
                                let energy = 0;
                                let animalProtein = 0; 
                                let plantProtein = 0; 
                                let animalLipid = 0;
                                let plantLipid = 0;
                                let glucid = 0;

                                // Mass
                                let realMassOne = 0;
                                let pureMassOfOne = 0;

                                let hunGramPrice = 0; // Đơn giá
                                let ingredientPrice = 0; // Thành tiền
                                let exchangeValue = 0;

                                ingredientArray.map(ingredientItem => {
                                    const selectedGroup = ingredientItem.group?.find(groupItem => Number(groupItem.id) === this.state.groupSelect.value);

                                    if(selectedGroup){
                                        if(ingredientItem.unit.is_root === 0){
                                            realMassOne += Number(selectedGroup.amount);
                                            pureMassOfOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                        } else {
                                            realMassOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                            pureMassOfOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass);
                                        }

                                        if(ingredientItem.type_subspecies === 1){
                                            animalProtein = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                            animalLipid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                        } else {
                                            plantProtein = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                            plantLipid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                        }

                                        energy = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                                        glucid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_glucid) / 100;

                                        exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    }
                                    return ingredientItem;
                                })

                                if(realMassOne === 0){
                                    return "";
                                }

                                // Tính thành tiền
                                if(exchangeValue !== 0){
                                    ingredientPrice = Math.round( (realMassOne / exchangeValue) * Number(ingredientArray[0].nutrients.price));
                                }

                                // Tính đơn giá / 100g
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMassOne);

                                energyStat += energy;
                                glucidStat += glucid;
                                proteinStat += animalProtein + plantProtein;
                                lipidStat += animalLipid + plantLipid;

                                ingredientIndex++;

                                return(
                                    <tr key={ingredientIndex} className="text-end align-middle">
                                        <td className="text-center">{ingredientIndex}</td>
                                        <td className="text-start">{ingredientArray[0].ingredent_name}</td>
                                        <td>{energy !== 0 ? formatter.format(energy) : ""}</td>
                                        <td>{animalProtein !== 0 ? formatter.format(animalProtein) : ""}</td>
                                        <td>{plantProtein !== 0 ? formatter.format(plantProtein) : ""}</td>
                                        <td>{animalLipid !== 0 ? formatter.format(animalLipid) : ""}</td>
                                        <td>{plantLipid !== 0 ? formatter.format(plantLipid) : ""}</td>
                                        <td>{glucid !== 0 ? formatter.format(glucid) : ""}</td>
                                        <td>
                                            <CurrencyFormat value={formatter.format(pureMassOfOne) || ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={formatter.format(realMassOne) || ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={ hunGramPrice || ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={ ingredientPrice || ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr className="text-center align-middle">
                            <th colSpan={2}>Tổng cộng</th>
                            <th>
                                {energyStat !== 0 
                                    ? 
                                        <div> 
                                            {formatter.format(energyStat)}{` `} 
                                            <div
                                                style={
                                                    60 <= (energyStat*100)/recommendFrom
                                                        ? { color: "green" }
                                                        : { color: "red" }
                                                } 
                                                className="d-inline"
                                            >
                                                (Đạt: {formatter.format((energyStat*100)/recommendFrom)}%)
                                            </div>
                                        </div> 
                                    : ""
                                }
                            </th>
                            <th colSpan={2}>{ proteinStat !== 0 ? formatter.format(proteinStat) : ""}</th>
                            <th colSpan={2}>{ lipidStat !== 0 ? formatter.format(lipidStat) : ""}</th>
                            <th>{ glucidStat !== 0 ? formatter.format(glucidStat) : ""}</th>
                            <th colSpan={4} className="text-end">
                                <CurrencyFormat value={ totalCostMoney || ""} displayType={'text'} thousandSeparator={true} />
                            </th>
                        </tr>

                        <tr className="text-center align-middle">
                            <th colSpan={2}>P : L : G</th>
                            <th></th>
                            <th colSpan={2}>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(proteinStat*100*4/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th colSpan={2}>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(lipidStat*100*9/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(glucidStat*100*4/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th colSpan={4}></th>
                        </tr>

                        <tr className="text-center align-middle">
                            <th colSpan={2}>Chi phí khác cho 1 trẻ</th>
                            <th colSpan={10} className="text-end">
                                <CurrencyFormat value={ ortherFee } displayType={'text'} thousandSeparator={true} />
                            </th>
                        </tr>
                    </tbody>
                </Table>

                <Table 
                    bordered 
                    responsive
                    id="day-nutrition-table"
                    className="d-none"
                >
                    <thead>
                        <tr>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="text-end align-top">
                                {this.state.listDay.filter((item) =>item.id === this.state.daySelect)[0]?.day_name}, ngày {this.stringLastDate(this.state.listDay.filter((item) =>item.id === this.state.daySelect)[0]?.day || new Date())}
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                        <tr className="text-center align-middle">
                            <th>
                                DƯỠNG CHẤT DÀNH CHO MỘT TRẺ             
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr>
                            <td>
                                Độ tuổi: {this.state.groupSelect.label} 
                                <br />
                                Số Tiền Trên Mỗi Trẻ: &nbsp; <CurrencyFormat value={ totalCostMoney + ortherFee } displayType={'text'} thousandSeparator={true} />
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>
                                {
                                    this.state.quantitative?.time?.map((timeItem) => {
                                        if(!timeItem.idGroup?.some(groupItem => groupItem.id === this.state.groupSelect.value)){
                                            return "";
                                        }
                        
                                        const foodInSelectedGroup = [];
                                        timeItem.detail.map(foodItem => {
                                            if(foodItem.ingrendent.some(ingredientItem => ingredientItem.group.some(groupItem => groupItem.id === this.state.groupSelect.value))){
                                                foodInSelectedGroup.push(foodItem)
                                            }
                                            return foodItem;
                                        })
                        
                                        return (
                                            <div key={timeItem.id_time}>
                                                {timeItem.time_name}: {
                                                    foodInSelectedGroup.map((foodItem, index) => {

                                                        if(foodInSelectedGroup.length-1 === index){
                                                            return `${foodItem.food_name}`;
                                                        }

                                                        return `${foodItem.food_name} + `;
                                                    })
                                                }
                                                <br />
                                            </div>
                                        );
                                    })
                                }
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                        <tr className="text-center align-middle">
                            <th>STT</th>
                            <th>Tên thực phẩm</th>
                            <th>Năng lượng (Kcal)</th>
                            <th>Đạm(g)</th>
                            <th>empty</th>
                            <th>Béo(g)</th>
                            <th>empty</th>
                            <th>Bột Đường(g)</th>
                            <th>Ăn được (g)</th>
                            <th>Đi chợ (g)</th>
                            <th>Đơn giá (đ/100g)</th>
                            <th>Thành tiền (đ)</th>
                        </tr>
                        <tr className="text-center align-middle">
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                            <th>ĐV</th>
                            <th>TV</th>
                            <th>ĐV</th>
                            <th>TV</th>

                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            aIngredientArray.map((ingredientArray) => {
                                // Nutrition
                                let energy = 0;
                                let animalProtein = 0; 
                                let plantProtein = 0; 
                                let animalLipid = 0;
                                let plantLipid = 0;
                                let glucid = 0;

                                // Mass
                                let realMassOne = 0;
                                let pureMassOfOne = 0;

                                let hunGramPrice = 0; // Đơn giá
                                let ingredientPrice = 0; // Thành tiền
                                let exchangeValue = 0;

                                ingredientArray.map(ingredientItem => {
                                    const selectedGroup = ingredientItem.group?.find(groupItem => Number(groupItem.id) === this.state.groupSelect.value);

                                    if(selectedGroup){
                                        if(ingredientItem.unit.is_root === 0){
                                            realMassOne += Number(selectedGroup.amount);
                                            pureMassOfOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                        } else {
                                            realMassOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                            pureMassOfOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass);
                                        }

                                        if(ingredientItem.type_subspecies === 1){
                                            animalProtein = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                            animalLipid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                        } else {
                                            plantProtein = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                            plantLipid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                        }

                                        energy = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                                        glucid = pureMassOfOne * Number(ingredientItem.nutrients.ingredent_glucid) / 100;

                                        exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    }
                                    return ingredientItem;
                                })

                                if(realMassOne === 0){
                                    return "";
                                }

                                // Tính thành tiền
                                if(exchangeValue !== 0){
                                    ingredientPrice = Math.round( (realMassOne / exchangeValue) * Number(ingredientArray[0].nutrients.price));
                                }

                                // Tính đơn giá / 100g
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMassOne);

                                ingredientIndex1++;

                                return(
                                    <tr key={ingredientIndex1} className="text-end align-middle">
                                        <td className="text-center">{ingredientIndex1}</td>
                                        <td className="text-start">{ingredientArray[0].ingredent_name}</td>
                                        <td>{energy !== 0 ? formatter.format(energy) : "empty"}</td>
                                        <td>{animalProtein !== 0 ? formatter.format(animalProtein) : "empty"}</td>
                                        <td>{plantProtein !== 0 ? formatter.format(plantProtein) : "empty"}</td>
                                        <td>{animalLipid !== 0 ? formatter.format(animalLipid) : "empty"}</td>
                                        <td>{plantLipid !== 0 ? formatter.format(plantLipid) : "empty"}</td>
                                        <td>{glucid !== 0 ? formatter.format(glucid) : "empty"}</td>
                                        <td>
                                            <CurrencyFormat value={formatter.format(pureMassOfOne)|| ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={formatter.format(realMassOne)|| ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={ hunGramPrice|| "" } displayType={'text'} thousandSeparator={true} />
                                        </td>
                                        <td>
                                            <CurrencyFormat value={ ingredientPrice || ""} displayType={'text'} thousandSeparator={true} />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr className="text-center align-middle">
                            <th>Tổng cộng</th>
                            <th>empty</th>

                            <th>{energyStat !== 0 ? formatter.format(energyStat) : "empty"}</th>
                            <th>{ proteinStat !== 0 ? formatter.format(proteinStat) : "empty"}</th>
                            <th>empty</th>

                            <th>{ lipidStat !== 0 ? formatter.format(lipidStat) : "empty"}</th>
                            <th>empty</th>

                            <th>{ glucidStat !== 0 ? formatter.format(glucidStat) : "empty"}</th>
                            <th className="text-end">
                                <CurrencyFormat value={ totalCostMoney } displayType={'text'} thousandSeparator={true} />
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>

                        <tr className="text-center align-middle">
                            <th>P : L : G</th>
                            <th>empty</th>

                            <th>empty</th>
                            <th>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(proteinStat*100*4/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th>empty</th>
                            <th>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(lipidStat*100*9/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th>empty</th>
                            <th>{ (proteinStat*4 + lipidStat*9 + glucidStat*4) !== 0 ? formatter.format(glucidStat*100*4/(proteinStat*4 + lipidStat*9 + glucidStat*4)) : ""}</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>

                        <tr className="text-center align-middle">
                            <th>Chi phí khác cho 1 trẻ</th>
                            <th>empty</th>
                            <th>
                                <CurrencyFormat value={ ortherFee } displayType={'text'} thousandSeparator={true} />
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    render() {       
        let allIngredient = [];

        this.state.quantitative?.time?.map(timeItem => {
            return timeItem.detail.map(foodItem => {
                return foodItem.ingrendent.map(ingredientItem => {
                    allIngredient.push({
                        ...ingredientItem,
                        calculator: timeItem.is_calcultor,
                    })
                    return ingredientItem;
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");

        let totalCostMoney = 0;

        aIngredientArray.map((ingredientArray) => {
            let realMassOne = 0;
            let ingredientPrice = 0;
            let exchangeValue = 0;

            ingredientArray.map(ingredientItem => {
                const selectedGroup = ingredientItem.group?.find(groupItem => Number(groupItem.id) === this.state.groupSelect.value);

                if(selectedGroup){
                    if(ingredientItem.unit.is_root === 0){
                        realMassOne += Number(selectedGroup.amount);
                    } else {
                        realMassOne += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                    }

                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                }
                return ingredientItem;
            })

            if(realMassOne === 0){
                return "";
            }

            // Tính thành tiền
            if(exchangeValue !== 0){
                ingredientPrice = Math.round( (realMassOne / exchangeValue) * Number(ingredientArray[0].nutrients.price));
            }

            totalCostMoney += ingredientPrice;

            return ingredientArray;
        }) 

        return (
            <Container fluid>
                <Card>
                    <Card.Header>
                        <div className="d-block d-sm-flex justify-content-between">
                            <div className="d-block d-sm-flex">
                                <Card.Title className="me-3 pt-1">
                                    Dưỡng chất 1 trẻ ngày {this.stringLastDate(this.state.listDay.filter((item) =>item.id === this.state.daySelect)[0]?.day || new Date())}
                                </Card.Title>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{
                                            border: "none",
                                            width: 39,
                                            backgroundColor: "rgb(16,108,252)",
                                            borderRadius: 5,
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(
                                                    e.target.valueAsNumber
                                                );
                                                const week =
                                                    this.setDate(
                                                        firstDayOfWeek
                                                    );

                                                this.setState({
                                                    weekInMonth:(0 | (week[0].getDate()/7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    {value: 0, label: ""},
                                                    {value: 0, label: ""}
                                                );
                                            } else {
                                                const today = new Date();
                                                const week =
                                                    this.setDate(today);

                                                this.setState({
                                                    weekInMonth:
                                                        (0 | (week[0].getDate() / 7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    {value: 0, label: ""},
                                                    {value: 0, label: ""}
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="d-block d-sm-flex">
                                <Button 
                                    variant="success" 
                                    size="sm"
                                    className="me-3 mt-3 mt-sm-0"
                                    onClick={() => {
                                        this.handleXportPortion();
                                    }}
                                    disabled={this.state.isLoading || aIngredientArray.length === 0}
                                >
                                    Xuất file <i className="fa-solid fa-file-export"></i>                            
                                </Button>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {this.RenderOption()}
                        
                        {
                            this.state.isLoading
                            ?
                                <div className="d-flex justify-content-center align-items-center my-3">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải dưỡng chất 1 trẻ...</span>
                                    </div>
                                </div>
                            : 
                                this.RenderDayNutritionPortion(aIngredientArray, totalCostMoney)
                        }
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default NutritionPortionDayReport;