import React, { Component } from "react";
import axios from "axios";
import { Card, Container, Table, Breadcrumb, Button, Row, Col, Form } from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import moment from "moment";
import swal from "sweetalert";

const initialDay = [
    { id: 1, day_name: "Thứ 2" },
    { id: 2, day_name: "Thứ 3" },
    { id: 3, day_name: "Thứ 4" },
    { id: 4, day_name: "Thứ 5" },
    { id: 5, day_name: "Thứ 6" },
    { id: 6, day_name: "Thứ 7" },
    { id: 7, day_name: "Chủ nhật" }
]

class SampleBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolInfo: {},
            quantitative: {},
            formSetting: {
                // Sample book
                book_3: [],
                book_6: "",
                book_7: "",
                book_8: [],
                book_9: [],
                book_11: "",
                book_12: "",

                // Step 1
                step1_m1_3: new Date(),
                step1_m2_5: new Date(),
                step1_m2_11: "",

                // Step 2
                step2_6: [],
                step2_7: [],
                step2_8: [],
                step2_9: "",
                step2_10: "",

                // Step 3
                step3_2: [],
                step3_5: [],
                step3_6: [],
                step3_7: "",
            },
            isLoading: false,

            listDay: [],
            daySelect: 0,

            start: new Date(),
            term: new Date(),

            weekInMonth: ""
        };
    }

    async componentDidMount() {
        document.title = 'Sổ lưu mẫu ngày';

        this.getDetailschool();

        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        if (todayIs === 0) {
            todayIs = 7;
        }

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: initialDay.map((day, index) => {
                return {
                    id: day.id,
                    day_name: day.day_name,
                    day: initialWeek[index]
                }
            })
        })

        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length - 1], todayIs);
        this.getSettingFormData();
    }

    //#region Fetch data
    getDetailschool = () => {
        axios.get(`/detailSchool`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        schoolInfo: res.data.data[0]
                    })
                }
            });
    }

    getQuantitativeVoucher = (startDay, endDay, daysId) => {
        this.setState({
            isLoading: true
        })

        axios.post(`/voucher?is_real=1&type=2&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}&id_day=${daysId}`)
            .then(res => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status === true) {
                    this.setState({
                        quantitative: res.data.data[0]
                    })
                }
            });

    }

    getSettingFormData() {
        axios
            .get("/viewSettingForm")
            .then((res) => {
                if (res.data.status) {
                    let initialFormSetting = res.data.data[0];

                    initialFormSetting.step2_8 = JSON.parse(initialFormSetting.step2_8);

                    if (initialFormSetting.step2_6.toString().toLowerCase() === "null" || initialFormSetting.step2_6 === "" || initialFormSetting.step2_6.length === 0) {
                        initialFormSetting.step2_6 = [];
                    }

                    if (initialFormSetting.step2_7.toString().toLowerCase() === "null" || initialFormSetting.step2_7 === "" || initialFormSetting.step2_7.length === 0) {
                        initialFormSetting.step2_7 = [];
                    }

                    if (initialFormSetting.step3_2.toString().toLowerCase() === "null" || initialFormSetting.step3_2 === "" || initialFormSetting.step3_2.length === 0) {
                        initialFormSetting.step3_2 = [];
                    }

                    if (initialFormSetting.step3_5.toString().toLowerCase() === "null" || initialFormSetting.step3_5 === "" || initialFormSetting.step3_5.length === 0) {
                        initialFormSetting.step3_5 = [];
                    }

                    if (initialFormSetting.step3_6.toString().toLowerCase() === "null" || initialFormSetting.step3_6 === "" || initialFormSetting.step3_6.length === 0) {
                        initialFormSetting.step3_6 = [];
                    }

                    if (initialFormSetting.book_3.toString().toLowerCase() === "null" || initialFormSetting.book_3 === "" || initialFormSetting.book_3.length === 0) {
                        initialFormSetting.book_3 = [];
                    }

                    if (initialFormSetting.book_8.toString().toLowerCase() === "null" || initialFormSetting.book_8 === "" || initialFormSetting.book_8.length === 0) {
                        initialFormSetting.book_8 = [];
                    }

                    if (initialFormSetting.book_9.toString().toLowerCase() === "null" || initialFormSetting.book_9 === "" || initialFormSetting.book_9.length === 0) {
                        initialFormSetting.book_9 = [];
                    }

                    this.setState({
                        formSetting: initialFormSetting
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //#endregion

    //#region Handle Logic
    xport = () => {
        const table = document.getElementById("sample-book");

        const wb = XLSX.utils.book_new();

        var ws = XLSX.utils.table_to_sheet(table, { raw: true });

        // Style cell
        const colAlpha_title1 = ["A", "B", "C", "D", "E", "F", "G", "H"];
        const colAlpha_title2 = ["I", "J", "K", "L"];

        colAlpha_title1.map(alpha => {
            ws[`${alpha}1`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "top",
                    horizontal: "left",
                    wrapText: true
                },
                // border: {
                //     top: { style: "thin" },
                //     bottom: { style: "thin" },
                //     left: { style: "thin" },
                //     right: { style: "thin" },
                // },
            };

            return alpha;
        })

        colAlpha_title2.map(alpha => {
            ws[`${alpha}1`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "top",
                    horizontal: "right",
                    wrapText: true
                },
                // border: {
                //     top: { style: "thin" },
                //     bottom: { style: "thin" },
                //     left: { style: "thin" },
                //     right: { style: "thin" },
                // },
            };
            return alpha;
        })

        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

        colAlpha.map((alpha) => {
            ws[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        let lengthList = 0;

        let rowWidth = [{ hpt: 40 }, { hpt: 55 }, {}];

        this.state.quantitative.time?.map(timeItem => {
            lengthList += timeItem.detail.length;
            return timeItem;
        })

        const intLen = 4;

        for (let i = 0; i < lengthList; i++) {

            rowWidth.push({ hpt: 35 })

            colAlpha.map(alpha => {
                if (ws[`${alpha}${intLen + i}`].v === "empty") {
                    ws[`${alpha}${intLen + i}`].v = ' '
                }

                if (alpha === "A" || alpha === "C" || alpha === "D" || alpha === "G" || alpha === "H" || alpha === "I" || alpha === "K" || alpha === "L") {
                    ws[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                } else if (alpha === "B" || alpha === "F") {
                    ws[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "left"
                        }
                    };
                } else {
                    ws[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                }

                return alpha;
            })

            const str = ws[`C${intLen + i}`.toString()].v;
            ws[`C${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "")

            const str1 = ws[`H${intLen + i}`.toString()].v;
            ws[`H${intLen + i}`.toString()].v = str1.split('#')[0];
        }

        var wscols =
            [
                { wch: 3.5 },
                { wch: 15 },
                { wch: 6 },
                { wch: 6 },
                { wch: 8 },
                { wch: 12 },
                { wch: 6 },
                { wch: 11 },
                { wch: 11 },
                { wch: 12 },
                { wch: 12 },
                { wch: 19 },
            ];

        ws['!cols'] = wscols;
        ws['!rows'] = rowWidth;

        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
            { s: { r: 0, c: 8 }, e: { r: 0, c: 11 } },
        ];
        ws["!merges"] = merge;

        XLSX.utils.book_append_sheet(wb, ws, "Sổ lưu mẫu ngày");

        XLSX.writeFile(wb, "Sổ lưu mẫu ngày.xlsx");
    };

    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day)
        });

        this.getQuantitativeVoucher(this.state.start, this.state.term, day);
    }

    handleExport = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn export file sổ lưu mẫu ngày`,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }

    dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    setDate = (theDate) => {
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length - 1]
        })

        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    stringDateBuNumber = (number) => {
        const date = new Date(number);

        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    };
    //#endregion

    // Render
    RenderDayOption() {
        return (
            <>
                <Card.Title>Chọn ngày điền sổ lưu mẫu ngày</Card.Title>

                <div className="d-none d-md-flex justify-content-start my-3">
                    {
                        this.state.listDay.map((dayItem, i) => {
                            let toDayIs = dayItem.id % 7;

                            if (toDayIs === 0) {
                                toDayIs = 7;
                            }

                            return (
                                <div key={i} className="d-flex me-md-4 me-xl-5">
                                    <Form.Check
                                        type="radio"
                                        value={dayItem.id}
                                        checked={this.state.daySelect === dayItem.id}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleCheck(e.target.value);
                                            }
                                        }}
                                        name="day"
                                        className="me-2"
                                    />
                                    {dayItem.day_name}
                                </div>

                            );
                        })}
                </div>

                <Row className="my-3 d-md-none">
                    <Col>
                        {this.state.listDay
                            .slice(
                                0,
                                Math.round(this.state.listDay.length / 2)
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>

                    <Col>
                        {this.state.listDay
                            .slice(
                                Math.round(this.state.listDay.length / 2,
                                    this.state.listDay.length
                                )
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </>
        )
    }

    RenderSampleBook() {
        if (!this.state.quantitative) {
            return (
                <div className="d-flex justify-content-center mt-3">
                    Không có dữ liệu
                </div>
            )
        }

        let foodAmount = 0;

        this.state.quantitative.time?.map(timeItem => {
            foodAmount += timeItem.detail.length;
            return timeItem;
        })

        let itemIndex = 0;

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered>
                    <thead>
                        <tr >
                            <td className="align-top" colSpan={8}>
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Địa điểm kiểm tra: ........................................... (Ngày {this.stringLastDate(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day || new Date())})
                            </td>
                            <th className="align-top text-end" colSpan={4}>
                                Mẫu số theo dõi lưu và hủy thức ăn lưu
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Tên mẫu thức ăn</td>
                            <td className="align-middle">Bữa ăn (giờ ăn...)</td>
                            <td className="align-middle">Số lượng suất ăn</td>
                            <td className="align-middle">Khối lượng/thể tích mẫu (gam/ml)</td>
                            <td className="align-middle">Dụng cụ chứa mẫu thức ăn lưu</td>
                            <td className="align-middle">Nhiệt độ bảo quản mẫu (℃)</td>
                            <td className="align-middle">Thời gian lấy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Thời gian hủy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Ghi chú (chất lượng mẫu thức ăn lưu...)</td>
                            <td className="align-middle">Người lưu mẫu (ký và ghi rõ họ tên)</td>
                            <td className="align-middle">Người hủy mẫu (ký và ghi rõ họ tên)</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                        </tr>

                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map((timeItem) => {
                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.map((foodItem) => {
                                                    itemIndex += 1;

                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }
                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;
                                                        countStudent += parseInt(studentAmount);
                                                        return group;
                                                    });

                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{itemIndex}</td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.book_3?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center"> {countStudent} </td>
                                                            <td></td>
                                                            <td className="text-center">{this.state.formSetting.book_6}</td>
                                                            <td className="text-center">{this.state.formSetting.book_7} ℃</td>
                                                            <td className="text-center">
                                                                {`${timeItem.time_name} ${moment(this.state.formSetting.book_8?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")} (${this.stringLastDate(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day || new Date())})`}
                                                            </td>
                                                            <td className="text-center">
                                                                {`${timeItem.time_name} ${moment(this.state.formSetting.book_9?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")} (${this.stringDateBuNumber(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getTime() + 86400000 || new Date())})`}
                                                            </td>
                                                            <td></td>
                                                            <td>{this.state.formSetting.book_11}</td>
                                                            <td>{this.state.formSetting.book_12}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={12}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>

                <Table className="d-none" bordered id="sample-book">
                    <thead>
                        <tr >
                            <td className="align-top">
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Địa điểm kiểm tra: ........................................... (Ngày {this.stringLastDate(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day || new Date())})
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>


                            <th className="align-top text-end">
                                Mẫu số theo dõi lưu và hủy thức ăn lưu
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Tên mẫu thức ăn</td>
                            <td className="align-middle">Bữa ăn (giờ ăn...)</td>
                            <td className="align-middle">Số lượng suất ăn</td>
                            <td className="align-middle">Khối lượng/thể tích mẫu (gam/ml)</td>
                            <td className="align-middle">Dụng cụ chứa mẫu thức ăn lưu</td>
                            <td className="align-middle">Nhiệt độ bảo quản mẫu (℃)</td>
                            <td className="align-middle">Thời gian lấy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Thời gian hủy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Ghi chú (chất lượng mẫu thức ăn lưu...)</td>
                            <td className="align-middle">Người lưu mẫu (ký và ghi rõ họ tên)</td>
                            <td className="align-middle">Người hủy mẫu (ký và ghi rõ họ tên)</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                        </tr>

                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map((timeItem) => {
                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.map((foodItem) => {
                                                    itemIndex += 1;
                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }
                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;
                                                        countStudent += parseInt(studentAmount);
                                                        return group;
                                                    });
                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{itemIndex}</td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.book_3?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center"> {countStudent} </td>                                                            <td>empty</td>
                                                            <td className="text-center">{this.state.formSetting.book_6}</td>
                                                            <td className="text-center">{this.state.formSetting.book_7} ℃</td>
                                                            <td className="text-center">
                                                                {`${timeItem.time_name} ${moment(this.state.formSetting.book_8?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")} (${this.stringLastDate(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day || new Date())})`}
                                                            </td>
                                                            <td className="text-center">
                                                                {`${timeItem.time_name} ${moment(this.state.formSetting.book_9?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")} (${this.stringDateBuNumber(this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getTime() + 86400000 || new Date())})`}
                                                            </td>
                                                            <td>empty</td>
                                                            <td>{this.state.formSetting.book_11}</td>
                                                            <td>{this.state.formSetting.book_12}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={12}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
                    <Breadcrumb.Item active>Sổ lưu mẫu ngày</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-sm-flex justify-content-between">
                            <div className="d-block d-sm-flex mb-3 mb-sm-0">
                                <Card.Title className="me-3 pt-1">
                                    <i className="fas fa-list me-1"></i> Sổ lưu mẫu ngày
                                    Tháng {this.state.start.getMonth() + 1} (Tuần {this.state.weekInMonth}) {this.stringFirstDate(this.state.start)} - {this.stringLastDate(this.state.term)}
                                </Card.Title>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(e.target.valueAsNumber);
                                                const week = this.setDate(firstDayOfWeek);

                                                const newListDay = initialDay.map((day, index) => {
                                                    return {
                                                        id: day.id,
                                                        day_name: day.day_name,
                                                        day: week[index]
                                                    }
                                                })

                                                this.setState({
                                                    weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                    listDay: newListDay
                                                })

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1], this.state.daySelect);
                                            } else {
                                                const today = new Date();
                                                const week = this.setDate(today);

                                                const newListDay = initialDay.map((day, index) => {
                                                    return {
                                                        id: day.id,
                                                        day_name: day.day_name,
                                                        day: week[index]
                                                    }
                                                })

                                                this.setState({
                                                    weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                    listDay: newListDay
                                                })

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1], this.state.daySelect);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <Button variant="success" size="sm" onClick={() => {
                                let lengthList = 0;

                                this.state.quantitative?.time?.map(timeItem => {
                                    lengthList += timeItem.detail.length;
                                    return timeItem;
                                })

                                if (this.state.quantitative && lengthList > 0) {
                                    this.handleExport();
                                } else {
                                    toast("Sổ đang không có dữ liệu", { type: "error", autoClose: 2000 });
                                }
                            }}>
                                Xuất file <i className="fa-solid fa-file-export"></i>
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {this.RenderDayOption()}

                        {
                            !this.state.isLoading
                                ?
                                this.RenderSampleBook()
                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải sổ lưu mẫu ngày...</span>
                                    </div>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default SampleBook;