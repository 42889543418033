import React, { Component } from "react";
import { Button, Card, Col, Container, Modal, Row, Table, Form } from "react-bootstrap";
import swal from "sweetalert";

const schoolList = [
    {   
        id: 1,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
    {   
        id: 2,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
    {   
        id: 3,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
    {   
        id: 4,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
    {   
        id: 5,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
    {   
        id: 6,
        school_name: "Quan khu 9",
        school_author: "Tuyet Lan",
        school_email: "lan@123.comn",
        school_phone: "0986453762",
        school_address: "Trần Quang Diệu, P. An Thới, Q. Bình Thủy, TP. Cần Thơ",
        is_delete: 0,
    },
]

class SchoolManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schoolList: [],
            schoolDetail: {},

            isShowModal: false,
        };
    }

    componentDidMount() {
        document.title = 'Quản lý trường học';

        this.getSchoolList();
    }

    //#region Fetch API
    getSchoolList(){
        this.setState({
            schoolList: schoolList
        })
    }

    updateSchool = () => {    }

    softDeleteSchool = (school_id) => {
        this.setState({
            schoolList: this.state.schoolList.map(school => {
                if(school.id === school_id){
                    return{
                        ...school,
                        is_delete: 1,
                    }
                }
                return school;
            })
        })
    }

    deleteSchool = (school_id) => {
        this.setState({
            schoolList: this.state.schoolList.filter(school => school.id !== school_id)
        })
    }

    revertSchool = (school_id) => {
        this.setState({
            schoolList: this.state.schoolList.map(school => {
                if(school.id === school_id){
                    return{
                        ...school,
                        is_delete: 0,
                    }
                }
                return school;
            })
        })
    }

    resetSchoolDatabase = (school_id) => {

    }
    //#endregion

    //#region Handle Logic
    handleUpdate = (event) => {
        event.preventDefault();

        swal({
            title: `Bạn muốn cập nhật thông tin trường học này!!!`,
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.updateSchool();
            }
        });
    }

    handleSoftDelete = (school_id) => {
        swal({
            title: `Bạn muốn xóa tạm trường học này!!!`,
            text: "Lưu ý: hành động có thẻ khôi phục lại",
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.softDeleteSchool(school_id);
            }
        });
    }

    handleDelete = (school_id) => {
        swal({
            title: `Bạn muốn xóa trường học này!!!`,
            text: "Lưu ý: Hành động sẽ không thể khôi phục lại",
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.deleteSchool(school_id);
            }
        });
    }

    handleRevert = (school_id) => {
        swal({
            title: `Bạn muốn khôi phục lại trường học này`,
            icon: "success",
            buttons: ["Đóng", "Xóa"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.revertSchool(school_id);
            }
        });
    }

    handleReset = (school_id) => {
        swal({
            title: `Bạn muốn reset lại toàn bộ dữ liệu trường học này!!!`,
            text: "Lưu ý: Hành động sẽ không thể khôi phục lại",
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
            }
        });
    }

    handleHideModal = () => {
        this.setState({
            isShowModal: false
        })
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = {...this.state.schoolDetail};
        dataInput[field] = value;
        this.setState({schoolDetail: dataInput});
    }
    //#endregion

    // Render
    RenderSChoolModal() {
        return(
            <Modal 
                show={this.state.isShowModal} 
                onHide={this.handleHideModal}
                size="md" 
                backdrop="static" 
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa-solid fa-school"></i> Thông tin trường học
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleUpdate}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control onChange={this.handleInput} value={this.state.schoolDetail.school_name} name="school_name" placeholder="Tên trường học" />
                                    <Form.Label>Tên trường học<i className="text-danger">*</i> </Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control onChange={this.handleInput} value={this.state.schoolDetail.school_author} name="school_author" placeholder="Đại diện" />
                                    <Form.Label>Đại diện<i className="text-danger">*</i> </Form.Label>
                                </Form.Floating>
                            </Col>
                            <Row>
                                <Col>
                                    <Form.Floating className="mb-3">
                                        <Form.Control onChange={this.handleInput} value={this.state.schoolDetail.school_phone} name="school_phone" placeholder="Số điện thoại" />
                                        <Form.Label>Số điện thoại<i className="text-danger">*</i> </Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                    <Form.Floating className="mb-3">
                                        <Form.Control onChange={this.handleInput} value={this.state.schoolDetail.school_email} name="school_email" placeholder="Email" />
                                        <Form.Label>Email<i className="text-danger">*</i> </Form.Label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control onChange={this.handleInput} value={this.state.schoolDetail.school_address} name="school_address" placeholder="Địa chỉ" />
                                    <Form.Label>Địa chỉ<i className="text-danger">*</i> </Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button type="button" variant="secondary" onClick={this.handleHideModal}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button> */}

                        <Button size="sm" variant="success" type="submit" >
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    RenderSchoolTable() {
        return(
            <div style={{overflowX: "scroll"}} >
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr>
                            <th>Tên trường học</th>
                            <th>Đại diện</th>
                            <th>Email</th>
                            <th>SĐT</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.schoolList.map((school) => {
                                return(
                                    <tr key={school.id}>
                                        <td>{school.school_name}</td>
                                        <td>{school.school_author}</td>
                                        <td>{school.school_email}</td>
                                        <td>{school.school_phone}</td>
                                        <td className="text-center">
                                            <Button
                                                size="sm"
                                                variant="success"
                                                className="me-3"
                                                onClick={() => {
                                                    this.setState({
                                                        isShowModal: true,
                                                        schoolDetail: school
                                                    });
                                                }}
                                            >
                                                Cập nhật
                                            </Button>

                                            {
                                                school.is_delete === 0 
                                                ?
                                                    <Button
                                                        size="sm"
                                                        variant="warning"
                                                        className="me-3"
                                                        onClick={() => this.handleSoftDelete(school.id)}
                                                    >
                                                        Xóa tạm
                                                    </Button>
                                                :
                                                    <Button
                                                        size="sm"
                                                        variant="success"
                                                        className="me-3"
                                                        onClick={() => this.handleRevert(school.id)}
                                                    >
                                                        Khôi phục
                                                    </Button>
                                            }

                                            <Button
                                                size="sm"
                                                variant="danger"
                                                className="me-3"
                                                onClick={() => this.handleDelete(school.id)}
                                            >
                                                Xóa vĩnh viễn
                                            </Button>

                                            <Button
                                                size="sm"
                                                variant="success"
                                                onClick={() => this.handleReset(school.id)}
                                            >
                                                Reset
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return(
            <Container fluid>
                <Card className="mt-3">
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <Card.Title>
                                Quản lý trường học
                            </Card.Title>

                            <Button
                                size="sm"
                                variant="success"
                            >
                                Thêm
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {this.RenderSchoolTable()}

                        {this.RenderSChoolModal()}
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default SchoolManagement;