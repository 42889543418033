import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import DataTables from "../datatable/DataTables";
import { dateFormatter, toSlug, toastErrorMessage } from "../utils/helpers";

const StudentsPicker = ({ show, onClose, exclusionStudentIds }) => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSchoolYears();
  }, []);

  //#region API
  const getSchoolYears = async () => {
    setIsLoading(true);
    await axios
      .get("/school-year-shows")
      .then((res) => {
        if (res.status === 200) {
          const schoolYears = res.data.data.schoolYears || [];

          const currentTime = Math.floor(Date.now() / 1000);
          const filteredData = schoolYears.filter(
            (item) =>
              item.start_date <= currentTime && item.end_date >= currentTime
          );
          const yearId = filteredData.length > 0 ? filteredData[0].id : "";

          setSchoolYears(schoolYears);
          setSelectedSchoolYearId(yearId);
          getStudents(yearId);
        }
      })
      .catch((err) => {
        toastErrorMessage(err);
      });
  };

  const getStudents = async (yearId) => {
    setSelectedStudents([]);
    setIsLoading(true);
    await axios
      .get(`/student-shows?id_year=${yearId == "--Tất cả--" ? "" : yearId}`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const studentsCanPick = exclusionStudentIds
            ? res.data.data.students.filter(
                (student) => !exclusionStudentIds.includes(student.id)
              )
            : res.data.data.students;
          setStudents(studentsCanPick);
          setFilteredStudents(studentsCanPick);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };
  //#endregion API

  //#region Logic
  const onSelectedRowsChange = (rows) => {
    setSelectedStudents(rows);
  };

  const searchName = (keyword) => {
    const listStudents = students.filter((student) =>
      toSlug(student.student_name).includes(toSlug(keyword))
    );
    setFilteredStudents(listStudents);
  };

  //#endregion Logic

  //#region Render
  const columns = [
    {
      name: "Lớp",
      selector: (row) => row.class_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Họ và tên",
      selector: (row) => row.student_name,
      sortable: true,
    },

    {
      name: "Giới tính",
      width: "130px",
      selector: (row) => row.gender_name,
      sortable: true,
      center: true,
    },
    {
      name: "Ngày sinh",
      width: "130px",
      selector: (row) => row.student_birthday,
      cell: (row) => dateFormatter(new Date(row.student_birthday * 1000)),
    },
  ];

  return (
    <Modal size="lg" show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-graduation-cap me-2"></i>
          Chọn học sinh
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: "500px" }}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between mb-3">
              <Form.Control
                className="me-3"
                type="text"
                onChange={(e) => {
                  searchName(e.target.value);
                }}
                placeholder="Tên học sinh"
              ></Form.Control>
              <div className="d-flex content-center" style={{ width: "350px" }}>
                <span style={{ padding: "10px", whiteSpace: "nowrap" }}>
                  Năm học:
                </span>
                <Form.Select
                  className="me-3"
                  value={selectedSchoolYearId}
                  onChange={(e) => {
                    setSelectedSchoolYearId(e.target.value);
                    getStudents(e.target.value);
                  }}
                  title="Năm học"
                >
                  <option>--Tất cả--</option>
                  {schoolYears.map((item, indexYear) => {
                    return (
                      <option key={`keyYear_${indexYear}`} value={item.id}>
                        {item.year_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>

            <i> Đã chọn {selectedStudents.length}</i>
            <DataTables
              data={filteredStudents}
              columns={columns}
              selectableRows={true}
              onSelectedRowsChange={(item) =>
                onSelectedRowsChange(item.selectedRows)
              }
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onClose()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => onClose(selectedStudents)}
          disabled={isLoading}
        >
          <i className="fa-solid fa-check"></i> Chọn
        </Button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion Render
};

export default StudentsPicker;
