import * as XLSX from "xlsx-js-style";
import { Table, Button, Container, Breadcrumb, Card, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

const MarketKitchen = () => {
  const [groups, setgroups] = useState([])
  const [groupsDefault, setgroupsDefault] = useState([])

  const [listday, setlistday] = useState([]);
  const [rootvoucherMar, setrootvoucherMar] = useState([]);
  const [voucherMar, setVoucherMar] = useState([]);
  const [daySelect, setDaySelect] = useState([]);
  const [start, setStartDate] = useState(new Date());
  const [term, setTermDate] = useState(new Date());
  const [weekInMonth, setWeekInMonth] = useState("");
  const [tabkey, setTabkey] = useState(1);
  const [checkload, setCheckload] = useState(false)

  useEffect(() => {
    document.title = "Định lượng cho bếp";
    getClassGroup();
    getSettingData();
    let initialDate = new Date();
    setDate(initialDate);
    setWeekInMonth((0 | initialDate.getDate() / 7) + 1);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //#region Fetch API
  const getVoucherMark = (start, end, day, daysId) => {
    var strDay = "";
    daysId.forEach((e, index) => {
      strDay += e.id;
      if (index + 1 < daysId.length) strDay += ",";
    })
    axios.post(`/voucher?is_real=0&type=2&start_at=${start / 1000}&term_at=${end / 1000}&id_day=${strDay}`)
      .then(res => {
        if (res.data.status === true) {
          setrootvoucherMar(res.data.data)
          setCheckload(false)
          setVoucherMar(res.data.data.filter(e => Number(e.stt_day) === Number(day)));
          getGroup_(res.data.data, day)
        }
      });
  }

  const getClassGroup = () => {
    axios
      .get("/viewClassGroup")
      .then((res) => {
        if (res.data.status) {
          setgroupsDefault(res.data.data.sort(function (a, b) {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getSettingData = () => {
    axios
      .get(`/viewSetting?id_classify=1`)
      .then((res) => {
        if (res.data.status) {
          setlistday(res.data.data.day
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveIngredientAmount = () => {
    const bigDetailArray = ingredientPortionArray(tabkey);

    if (bigDetailArray.length === 0) {
      toast("Không có dữ liệu", { type: "error", autoClose: 2000 });
    }

    let ingreBigArray = [];
    voucherMar.filter(e => Number(e.stt_day) === Number(tabkey))[0].time.filter(timeItem => timeItem.detail.length !== 0).map(timeItem => {
      timeItem.detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
        ingreBigArray = ingreBigArray.concat(detailItem.ingrendent);
        return detailItem;
      })
      return timeItem;
    });

    const aIngredientArray = group(ingreBigArray, "id_ingredent");

    const submitIngredientArray = aIngredientArray.map(arrayIngredientItem => {
      let amount = 0;
      arrayIngredientItem.map(ingredientItem => {
        ingredientItem.group.map(groupItem => {
          amount += Number(ingredientItem.unit.is_root) === 1 ? Number(groupItem.amount * groupItem.count_person) : Number(groupItem.amount * groupItem.count_person) / Number(ingredientItem.nutrients.ingredent_RealVolume);
          return groupItem;
        })
        return ingredientItem;
      })

      return {
        id_ingredent: arrayIngredientItem[0].id_ingredent,
        ingredent_type: arrayIngredientItem[0].ingredent_type,
        amount: amount
      }
    })

    axios
      .post(`/createVoucher`, null, {
        params: {
          id_menu: Number(voucherMar[0].idGroup[0].id_menu),
          stt_day: tabkey,
          type_voucher: 2,
          ingredent: JSON.stringify(submitIngredientArray),
        }
      })
      .then((res) => {
        if (res.data.status === true) {
          toast(res.data.data.mess, { type: "success", autoClose: 2000 });
          getVoucherMark(start, term, tabkey, daySelect)
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //#endregion

  //#region Handle Logic
  const permission = (data, type = 'and') => {
    if (localStorage.getItem('role_rank') === '1') { return true; }

    const permission = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : [];

    if (data.length === 0) {
      return false;
    } else {
      let result = false;

      data.map((item) => {
        if (permission.length > 0) {
          switch (type) {
            case 'or':
              if (permission.indexOf(item) > -1) { result = true; }
              break;

            case 'and':
              if (permission.indexOf(item) > -1) {
                result = true;
              } else {
                result = false;
              }
              break;
            default: result = false;
          }
        }

        return item;
      });

      return result;
    }
  }

  const handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất file định lượng cho bếp Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth}) ${stringFirstDate(start)} - ${stringLastDate(term)}`,
      icon: "info",
      buttons: ["Đóng", "Export"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        export_();
      }
    });
  }

  const export_ = () => {
    const wb = XLSX.utils.book_new();
    rootvoucherMar?.map((item) => {
      let ingredientAmount = 0;

      item.time.map(timeItem => {
        timeItem.detail.map(detailItem => {
          ingredientAmount += detailItem.ingrendent.length;
          return detailItem;
        })
        return timeItem;
      })

      XLSX.utils.book_append_sheet(wb, handleNewSheet(item.day_name, item.stt_day, ingredientAmount), item.day_name);
      return item;
    })

    XLSX.writeFile(wb, "Danh sách đi chợ cho bếp.xlsx");
  }

  const handleNewSheet = (day_name, dayIndex, ingredientAmount) => {
    const table = document.getElementById(`dinhluong-${day_name}`);
    var ws = XLSX.utils.table_to_sheet(table, { raw: true })

    const colAlpha = [
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
      "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
      "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ",
    ];

    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    colAlpha.slice(0, groups?.length * 3 + 6).map(alpha => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center"
        },
        border: borderStyle
      };

      return alpha;
    })

    colAlpha.slice(0, groups?.length * 3 + 6).map(alpha => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 11
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center"
        },
        border: borderStyle
      };

      return alpha;
    })

    colAlpha.slice(0, groups?.length * 3 + 6).map(alpha => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 11
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center"
        },
        border: borderStyle
      };

      return alpha;
    })

    for (let i = 4; i < ingredientAmount + 4; i++) {
      colAlpha.slice(0, groups?.length * 3 + 6).map(alpha => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = ' '
        }

        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" || alpha === "B" || alpha === "C" || alpha === "D" ? "left" : "right"
          },
          border: borderStyle
        };
        return alpha;
      })
    }

    const cols = [
      { wch: 8 },
      { wch: 13 },
      { wch: 10 },
      { wch: 6 },
    ];

    colAlpha.slice(3, groups?.length * 3 + 3).map(alpha => {

      cols.push({ wch: 7 });

      return alpha;
    })

    cols.push({ wch: 9.5 });
    cols.push({ wch: 9.5 });

    ws['!cols'] = cols;

    ws['!rows'] = [{ hpt: 30 }, { hpt: 40 }, { hpt: 40 }];

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: groups.length * 3 + 5 } },

      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
      { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
      { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },

      { s: { r: 1, c: 4 }, e: { r: 1, c: 4 + groups.length - 1 } },
      { s: { r: 1, c: 4 + groups.length }, e: { r: 1, c: 4 + groups.length * 2 - 1 } },
      { s: { r: 1, c: 4 + groups.length * 2 }, e: { r: 1, c: 4 + groups.length * 3 - 1 } },

      { s: { r: 1, c: groups.length * 3 + 4 }, e: { r: 2, c: groups.length * 3 + 4 } },
      { s: { r: 1, c: groups.length * 3 + 5 }, e: { r: 2, c: groups.length * 3 + 5 } },
    ];

    let timeIndex = 3;
    let foodIndex = 3;

    rootvoucherMar.find(voucher => Number(voucher.stt_day) === Number(dayIndex)).time.map(timeItem => {

      if (timeItem.countIn === 0) {
        return timeItem;
      }

      merge.push({ s: { r: timeIndex, c: 0 }, e: { r: timeIndex + timeItem.countIn - 1, c: 0 } });
      timeIndex += timeItem.countIn;

      timeItem.detail.map(detailItem => {

        merge.push({ s: { r: foodIndex, c: 1 }, e: { r: foodIndex + detailItem.ingrendent.length - 1, c: 1 } });

        foodIndex += detailItem.ingrendent.length;
        return detailItem;
      })

      return timeItem;
    })

    ws["!merges"] = merge;

    return ws;
  }

  const handleSaveIngredient = (tabkey) => {
    swal({
      title: `Bạn muốn xuất kho cho bếp`,
      text: `Lưu ý: Hành động sẽ cập nhật lại nguyên liệu trong kho và không thể khôi phục lại.`,
      icon: "info",
      buttons: ["Đóng", "Lưu"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        saveIngredientAmount(tabkey);
      }
    });
  }

  const ingredientPortionArray = (tabkey) => {
    let detailArray = [];
    voucherMar.filter(e => Number(e.stt_day) === Number(tabkey))[0].time.map(timeItem => {
      detailArray = detailArray.concat(timeItem.detail);
      return timeItem;
    })

    return detailArray;
  }

  const group = (arr, key) => {
    return [...arr.reduce((acc, o) =>
      acc.set(o[key], (acc.get(o[key]) || []).concat(o))
      , new Map()).values()];
  }

  const getGroup_ = (voucherMar, id_time) => {
    if (Number(voucherMar[0]?.stt_day) === Number(id_time))
      if (voucherMar[0].idGroup.length > 0) {
        setgroups(voucherMar[0].idGroup.sort(function (a, b) {
          if (a.id < b.id) return 1;
          if (a.id > b.id) return -1;
          return 0;
        }))
        groups_(voucherMar[0].idGroup)
      }
      else {
        setgroups(groupsDefault)
        groups_(groupsDefault)
      }
    else {
      setgroups(groupsDefault)
      groups_(groupsDefault)
    }
  }

  const groups_ = (group) => {
    if (group !== undefined) {
      return group?.map((item) => {
        return <th key={`group_${item.id}`} className="text-center">{item.group_name.toUpperCase()}</th>
      });
    }
  }

  const changeTabs = (key) => {

    setVoucherMar(rootvoucherMar.filter(e => Number(e.stt_day) === Number(key)));
  }

  const handleCheck = (day) => {
    let currentADay = daySelect;
    currentADay.push({ id: day, name: listday?.filter(item => Number(item.stt_day) === Number(day))[0]?.day_name, stt: listday?.filter(item => Number(item.stt_day) === Number(day))[0]?.stt_day });
    sort_(currentADay)
    setCheckload(true)
    getVoucherMark(start, term, day, currentADay)
    getGroup_(voucherMar, day)
  }

  const handleUnCheck = (day) => {
    setDaySelect(daySelect.filter(dayItem => Number(dayItem.id) !== Number(day)))
    let currentADay = daySelect.filter(dayItem => Number(dayItem.id) !== Number(day));
    currentADay = currentADay.filter(dayItem => Number(dayItem.id) !== Number(day))
    getVoucherMark(start, term, currentADay[0]?.id, currentADay)
  }

  const handleCheckAll = (days) => {
    days.forEach(element => {
      let days_ = { id: element, name: listday?.filter(item => Number(item.stt_day) === Number(element))[0]?.day_name, stt: listday?.filter(item => Number(item.stt_day) === Number(element))[0]?.stt_day }
      if (daySelect.filter(e => Number(e.id) === Number(days_.id)).length === 0) {
        daySelect.push(days_);
      }
    });
    sort_(daySelect)
    setCheckload(true)
    getVoucherMark(start, term, 1, daySelect)

    setDaySelect(daySelect);

  }

  const handleUnCheckAll = () => {
    setDaySelect([])
    setVoucherMar([])
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const sort_ = (data) => {
    data.sort(function (a, b) {
      if (a.stt > b.stt) return 1;
      if (a.stt < b.stt) return -1;
      return 0;
    })
    return data;
  }
  const ChangeDate = (start1) => {
    var daysToAdd = start1 - start.getDay()
    if (daysToAdd < 0) { daysToAdd += 7 }
    var targetDate = new Date(start.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
    return (targetDate.getDate() + "/" + Number(targetDate.getMonth() + 1) + "/" + targetDate.getFullYear())

  }
  const Emaxlement = (array) => {
    let max = 0;
    if (array != null) {
      max = array[0];
      for (let i = 0; i < array.length; ++i) {
        if (max < array[i]) {
          max = array[i];
        }
      }
    } else { max = array }

    return max
  }

  const dates = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay() + 1));
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(
        new Date(current)
      );
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  }

  const setDate = (theDate) => {
    const week = dates(theDate);

    setStartDate(week[0]);
    setTermDate(week[week.length - 1]);
  }

  const stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  //#endregion

  // Render
  const loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    )
  }

  const MenuInfo = () => {
    return (
      <>
        <Card.Title>Chọn ngày xuất phiếu định lượng cho bếp</Card.Title>
        <div className="d-none d-md-flex justify-content-start my-3" >
          <Form.Check className="me-md-4 me-xl-5 form-check form-switch"
            type="switch"
            checked={daySelect.length === 7}
            onChange={(e) => {
              if (e.target.checked) {
                let tmpDay = listday
                  .map((day) => {
                    return day.stt_day;
                  })
                handleCheckAll(tmpDay);
                setTabkey(1)
              } else {
                handleUnCheckAll();
              }
            }}
            label="Chọn tất cả"
          />

          <div className="d-flex">
            {listday
              .map((dayItem, i) => {
                let toDayIs = dayItem.stt_day % 7;
                if (toDayIs === 0) {
                  toDayIs = 7;
                }
                return (
                  <Row key={i} className="me-md-4 me-xl-5"
                  >
                    <Col md={1}>
                      <div className="text-center">
                        <Form.Check
                          type="checkbox"
                          value={dayItem.stt_day}
                          checked={daySelect?.some(day => Number(day.id) === dayItem.stt_day)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleCheck(e.target.value)
                              setTabkey(dayItem.stt_day)
                            } else {
                              handleUnCheck(e.target.value)
                              setTabkey(daySelect[0].stt_day)
                            }

                          }}
                          name="day"
                        />
                      </div>
                    </Col>

                    <Col>
                      {dayItem.day_name}
                    </Col>
                  </Row>
                );
              })}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
          <Breadcrumb.Item active>
            Định lượng cho bếp
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <div className="d-block d-lg-flex justify-content-between">
              <div className="d-block d-md-flex">
                <Card.Title className="me-2">
                  <i className="fas fa-list me-1"></i>Danh sách định lượng cho bếp Tháng {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
                </Card.Title>

                <input
                  type="week"
                  name="week"
                  style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                  onChange={(e) => {
                    if (e.target.value) {
                      getVoucherMark(new Date(new Date(e.target.valueAsNumber).setHours(0, 0, 0)), new Date(new Date(new Date(e.target.valueAsNumber).setDate(new Date(e.target.valueAsNumber).getDate() + 6)).setHours(0, 0, 0)), daySelect[0]?.id, daySelect)
                      const firstDayOfWeek = new Date(e.target.valueAsNumber);
                      setWeekInMonth((0 | firstDayOfWeek.getDate() / 7) + 1)
                      setDate(firstDayOfWeek);
                    } else {
                      const today = new Date();
                      setDate(today);
                    }
                  }}
                />
              </div>

              <div className="d-flex">
                {
                  daySelect.length > 0 && rootvoucherMar.filter(e => e.idGroup.length > 0).length > 0 ?
                    <>
                      <Button
                        variant="success"
                        size="sm"
                        className="mt-3 mt-md-0 me-2"
                        onClick={() => { handleExport() }}
                      >
                        Xuất file tạm <i className="fa-solid fa-file-export"></i>
                      </Button>
                    </>
                    :
                    ""
                }

                {
                  permission(['56'], 'or')
                    ?
                    Number(rootvoucherMar[0]?.is_kitchen) === 1
                      ?
                      ""
                      :
                      daySelect.length > 0 && voucherMar.filter(e => e.idGroup.length > 0).length > 0 ?
                        <Button
                          variant="success"
                          size="sm"
                          className="mt-3 mt-md-0"
                          onClick={() => {
                            if (!rootvoucherMar[0].idGroup?.every(item => item.is_market)) {
                              toast("Bạn chưa lưu đủ phiếu đi chợ", { type: "error", autoClose: 2000 });
                              return;
                            }

                            handleSaveIngredient(tabkey);
                          }}
                          disabled={voucherMar.filter(e => e.is_kitchen === true).length > 0 ? true : false}
                        >
                          {
                            voucherMar.filter(e => e.is_kitchen === true).length > 0 ? "Đã " : ""
                          }
                          Xuất kho cho bếp <i className="fa-solid fa-file-export"></i>
                        </Button>
                        : ""
                    : ""
                }
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            {MenuInfo()}

            {checkload ? loading() :
              <>
                <Tabs activeKey={tabkey}
                  defaultActiveKey={tabkey}
                  onSelect={(k) => {
                    changeTabs(k)
                    setTabkey(k)
                    getGroup_(voucherMar, k)
                  }}
                >
                  {
                    checkload
                      ?
                      <p className="text-center">Không có dữ liệu</p>
                      :
                      (
                        daySelect?.map((item) => {
                          var tongAll = 0;
                          return (
                            <Tab
                              key={item.id}
                              title={item.name}
                              eventKey={item.id}>
                              <br />
                              {
                                checkload
                                  ?
                                  loading
                                  :
                                  <Table
                                    responsive
                                    bordered
                                  >
                                    <tbody>
                                      <tr>
                                        <th colSpan={groups.length * 3 + 6} className="text-center">
                                          {`Tổng hợp định lượng cho bếp   Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth})  ${stringFirstDate(start)} - ${stringLastDate(term)}`.toUpperCase()}
                                        </th>

                                      </tr>
                                      <tr>
                                        <th colSpan={groups.length * 3 + 6} className="text-center">
                                          {ChangeDate(item.id)}
                                        </th>
                                      </tr>
                                      <tr className="text-center">
                                        <th rowSpan="2" className="align-middle text-center">BUỔI</th>
                                        <th rowSpan="2" className="align-middle text-center">TÊN MÓN ĂN</th>
                                        <th rowSpan="2" className="align-middle text-center">NGUYÊN LIỆU</th>
                                        <th rowSpan="2" className="align-middle text-center"> ĐVT</th>
                                        <th className="text-center" colSpan={groups.length}>ĐỊNH LƯỢNG CHUẨN</th>
                                        <th className="text-center" colSpan={groups.length} >TỔNG SỐ LƯỢNG{'\n(suất ăn)'}</th>
                                        <th className="text-center" colSpan={groups.length}>ĐỊNH LƯỢNG</th>
                                        <th rowSpan="2" className="align-middle text-center">TỔNG ĐỊNH LƯỢNG CẦN MUA</th>
                                        <th rowSpan="2" className="align-middle text-center">GHI CHÚ</th>
                                      </tr>
                                      <tr>
                                        {groups_(groups)}
                                        {groups_(groups)}
                                        {groups_(groups)}
                                      </tr>

                                      {voucherMar?.map((meal, index) => {
                                        return (
                                          meal.idGroup.length === 0
                                            ?
                                            <tr key={index} className="text-center">
                                              <td colSpan={groups.length * 3 + 6} className="text-center">Không có dữ liệu</td>
                                            </tr>
                                            :
                                            (
                                              meal.time.map((time_, indx) => {
                                                let tong = 0;
                                                return time_.detail.length === 0 ? "" :
                                                  (
                                                    <React.Fragment key={indx}>
                                                      <tr>
                                                        <td
                                                          rowSpan={time_.countIn > 0 ? time_.countIn : 1}
                                                          className="align-middle text-center"
                                                        >
                                                          {time_.time_name}
                                                        </td>
                                                        <td
                                                          className="align-middle text-center"
                                                          rowSpan={time_.detail[0]?.countIn > 0 ? time_.detail[0]?.countIn : 1}
                                                        >
                                                          {time_.detail[0]?.food_name}
                                                        </td>
                                                        <td>{time_.detail[0]?.ingrendent[0]?.ingredent_name}</td>
                                                        <td >{time_.detail[0]?.ingrendent[0]?.unit.unit_name}</td>

                                                        {groups.length > 0 ?
                                                          groups.map(e => {
                                                            if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                              return <td key={e.id} style={{ textAlign: "right" }} >{time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                                            } else {
                                                              return <td key={e.id} className="text-right"></td>
                                                            }
                                                          })
                                                          : <td style={{ textAlign: "right" }} ></td>
                                                        }

                                                        {groups.length > 0 ?
                                                          groups.map(e => {
                                                            if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                              return <td key={e.id} style={{ textAlign: "right" }} >{time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                                            } else {
                                                              return <td key={e.id} className="text-right"></td>
                                                            }
                                                          })
                                                          : <td style={{ textAlign: "right" }} ></td>
                                                        }

                                                        {groups.length > 0 ?
                                                          groups.map(e => {
                                                            if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                              return (
                                                                <td key={e.id} style={{ textAlign: "right" }} >
                                                                  {formatter.format(time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal))}
                                                                </td>)
                                                            } else {
                                                              return <td key={e.id} style={{ textAlign: "right" }} ></td>
                                                            }
                                                          })
                                                          : <td style={{ textAlign: "right" }} ></td>
                                                        }

                                                        <td style={{ textAlign: "right" }} >
                                                          {formatter.format(Emaxlement(time_.detail[0]?.ingrendent[0]?.group.map((group_, index_) => {
                                                            tong = tong + group_.amount * (group_.count_person - group_.meal);
                                                            tongAll = tongAll + tong;
                                                            return index_ + 1 === time_.detail[0]?.ingrendent[0]?.group.length ? tong : ""
                                                          })))}
                                                        </td>
                                                        <td></td>
                                                      </tr>

                                                      {time_.detail[0]?.ingrendent.slice(1, time_.detail[0].ingrendent.length).map((ingrendent_, index) => {
                                                        let tong = 0
                                                        return (
                                                          <tr key={index}>
                                                            <td>{ingrendent_.ingredent_name}</td>
                                                            <td>{ingrendent_.unit.unit_name}</td>

                                                            {groups.length > 0 ?
                                                              groups.map(e => {
                                                                if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                  return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                                                } else {
                                                                  return <td key={e.id} className="text-right"></td>
                                                                }
                                                              })
                                                              : <td style={{ textAlign: "right" }} ></td>
                                                            }

                                                            {groups.length > 0 ?
                                                              groups.map(e => {
                                                                if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                  return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                                                } else {
                                                                  return <td key={e.id} className="text-right"></td>
                                                                }
                                                              })
                                                              : <td style={{ textAlign: "right" }} ></td>
                                                            }

                                                            {groups.length > 0 ?
                                                              groups.map(e => {
                                                                if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                  return (
                                                                    <td key={e.id} style={{ textAlign: "right" }} >
                                                                      {formatter.format(ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal))}
                                                                    </td>
                                                                  )
                                                                } else {
                                                                  return <td key={e.id} style={{ textAlign: "right" }} ></td>
                                                                }
                                                              })
                                                              : <td style={{ textAlign: "right" }} ></td>
                                                            }

                                                            <td style={{ textAlign: "right" }}>
                                                              {formatter.format(Emaxlement(ingrendent_?.group.map((group_, index_) => {
                                                                tong = tong + group_.amount * (group_.count_person - group_.meal);
                                                                tongAll = tongAll + tong;
                                                                return index_ + 1 === ingrendent_?.group.length ? tong : ""
                                                              })))}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        )
                                                      }
                                                      )}

                                                      {time_.detail.slice(1, time_.detail.length)?.map((detail_, index) => {
                                                        let tong = 0;
                                                        return (
                                                          <>
                                                            <tr>
                                                              <td className="align-middle text-center" rowSpan={detail_.countIn} >{detail_.food_name}</td>
                                                              <td >{detail_.ingrendent[0]?.ingredent_name}</td>
                                                              <td >{detail_.ingrendent[0]?.unit.unit_name}</td>

                                                              {groups.length > 0 ?
                                                                groups.map(e => {
                                                                  if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                    return <td key={e.id} style={{ textAlign: "right" }} >{detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                                                  } else {
                                                                    return <td key={e.id} style={{ textAlign: "right" }} ></td>
                                                                  }
                                                                })
                                                                : <td style={{ textAlign: "right" }} ></td>
                                                              }

                                                              {groups.length > 0 ?
                                                                groups.map(e => {
                                                                  if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                    return <td key={e.id} style={{ textAlign: "right" }} >{detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                                                  } else {
                                                                    return <td key={e.id} style={{ textAlign: "right" }} ></td>
                                                                  }
                                                                })
                                                                : <td style={{ textAlign: "right" }} ></td>
                                                              }
                                                              {groups.length > 0 ?
                                                                groups.map(e => {
                                                                  if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                    return (
                                                                      <td key={e.id} style={{ textAlign: "right" }} >
                                                                        {formatter.format(detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal))}
                                                                      </td>)
                                                                  } else {
                                                                    return <td key={e.id} style={{ textAlign: "right" }}  ></td>
                                                                  }
                                                                })
                                                                : <td style={{ textAlign: "right" }} ></td>
                                                              }
                                                              <td style={{ textAlign: "right" }} >
                                                                {formatter.format(Emaxlement(detail_.ingrendent[0]?.group.map((group_) => {
                                                                  tong = tong + group_.amount * (group_.count_person - group_.meal);
                                                                  tongAll = tongAll + tong;
                                                                  return tong
                                                                })))}
                                                              </td>
                                                              <td></td>
                                                            </tr>

                                                            {detail_.ingrendent.slice(1, detail_.ingrendent.length).map((ingrendent_, index) => {
                                                              let tong = 0
                                                              return (
                                                                <tr key={index}>
                                                                  <td>{ingrendent_.ingredent_name}</td>
                                                                  <td>{ingrendent_.unit.unit_name}</td>

                                                                  {groups.length > 0 ?
                                                                    groups.map(e => {
                                                                      if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                        return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                                                      } else {
                                                                        return <td key={e.id} className="text-right"></td>
                                                                      }
                                                                    })
                                                                    : <td style={{ textAlign: "right" }} ></td>
                                                                  }

                                                                  {groups.length > 0 ?
                                                                    groups.map(e => {
                                                                      if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                        return <td key={e.id} style={{ textAlign: "right" }} >
                                                                          {ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                                                      } else {
                                                                        return <td key={e.id} className="text-right"></td>
                                                                      }
                                                                    })
                                                                    : <td style={{ textAlign: "right" }} ></td>
                                                                  }

                                                                  {groups.length > 0 ?
                                                                    groups.map(e => {
                                                                      if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                                        return (
                                                                          <td key={e.id} style={{ textAlign: "right" }}>
                                                                            {
                                                                              formatter.format((ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person
                                                                                -
                                                                                ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal) *
                                                                                ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount
                                                                              )}
                                                                          </td>
                                                                        )
                                                                      } else {
                                                                        return <td key={e.id} className="text-right"></td>
                                                                      }
                                                                    })
                                                                    : <td style={{ textAlign: "right" }} ></td>
                                                                  }

                                                                  <td style={{ textAlign: "right" }} >
                                                                    {formatter.format(Emaxlement(ingrendent_?.group.map((group_, index_) => {
                                                                      tong = tong + group_.amount * (group_.count_person - group_.meal);
                                                                      tongAll = tongAll + tong;
                                                                      return index_ + 1 === ingrendent_?.group.length ? tong : ""
                                                                    })))}
                                                                  </td>
                                                                  <td></td>
                                                                </tr>
                                                              )
                                                            })}
                                                          </>
                                                        )
                                                      }
                                                      )}
                                                    </React.Fragment>
                                                  )
                                              })
                                            ))
                                      })}
                                    </tbody>
                                  </Table>
                              }
                            </Tab>
                          )

                        })
                      )}
                </Tabs>

                {voucherMar.length !== 0
                  ?
                  ""
                  :
                  <div className="d-flex justify-content-center">
                    Không có dữ liệu
                  </div>
                }
              </>
            }

            {/* export excel */}
            {rootvoucherMar.map((item, _index) => {
              var tongAll = 0;
              return (
                <Table
                  key={_index}
                  responsive bordered
                  id={`dinhluong-${item.day_name}`}
                  className="d-none"
                >
                  <tbody>
                    <tr>
                      <th className="text-center">
                        {`Tổng hợp định lượng cho bếp   Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth})  ${stringFirstDate(start)} - ${stringLastDate(term)}`.toUpperCase()}
                      </th>

                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                      {
                        groups.map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          );
                        })
                      }
                      {
                        groups.map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          );
                        })
                      }
                      {
                        groups.map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          );
                        })
                      }

                      <th>empty</th>
                      <th>empty</th>
                    </tr>
                    <tr className="text-center">
                      <th className="align-middle text-center">BUỔI</th>
                      <th className="align-middle text-center">TÊN MÓN ĂN</th>
                      <th className="align-middle text-center">NGUYÊN LIỆU</th>
                      <th className="align-middle text-center"> ĐVT</th>

                      <th className="text-center">ĐỊNH LƯỢNG CHUẨN</th>
                      {
                        groups.slice(0, groups.length - 1).map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          )
                        })
                      }

                      <th className="text-center" >TỔNG SỐ LƯỢNG (suất ăn)</th>
                      {
                        groups.slice(0, groups.length - 1).map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          )
                        })
                      }

                      <th className="text-center">ĐỊNH LƯỢNG</th>
                      {
                        groups.slice(0, groups.length - 1).map((_, index) => {
                          return (
                            <th key={index}>empty</th>
                          )
                        })
                      }

                      <th className="align-middle text-center">TỔNG ĐỊNH LƯỢNG CẦN MUA</th>
                      <th className="align-middle text-center">GHI CHÚ</th>
                    </tr>
                    <tr>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>

                      {groups_(groups)}
                      {groups_(groups)}
                      {groups_(groups)}

                      <th>empty</th>
                      <th>empty</th>
                    </tr>

                    {
                      item.time.map((time_, index) => {
                        let tong = 0;
                        return time_.detail.length === 0 ? "" :
                          (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="align-middle text-center">{time_.time_name}</td>
                                <td className="align-middle text-center">{time_.detail[0]?.food_name}</td>
                                <td>{time_.detail[0]?.ingrendent[0]?.ingredent_name}</td>
                                <td>{time_.detail[0]?.ingrendent[0]?.unit.unit_name}</td>

                                {groups.length > 0 ?
                                  groups.map(e => {
                                    if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                      return <td key={e.id} style={{ textAlign: "right" }} >{time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                    } else {
                                      return <td key={e.id} className="text-right">empty</td>
                                    }
                                  })
                                  : <td style={{ textAlign: "right" }} >empty</td>
                                }

                                {groups.length > 0 ?
                                  groups.map(e => {
                                    if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                      return <td key={e.id} style={{ textAlign: "right" }} >{time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                    } else {
                                      return <td key={e.id} className="text-right">empty</td>
                                    }
                                  })
                                  : <td style={{ textAlign: "right" }} >empty</td>
                                }

                                {groups.length > 0 ?
                                  groups.map(e => {
                                    if (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                      return (
                                        <td key={e.id} style={{ textAlign: "right" }} >
                                          {time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - time_.detail[0]?.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal)}
                                        </td>
                                      )
                                    } else {
                                      return <td key={e.id} style={{ textAlign: "right" }} >empty</td>
                                    }
                                  })
                                  : <td style={{ textAlign: "right" }} >empty</td>
                                }

                                <td style={{ textAlign: "right" }} >
                                  {
                                    time_.detail[0]?.ingrendent[0]?.group.map((group_, index_) => {
                                      tong = tong + group_.amount * (group_.count_person - group_.meal);
                                      tongAll = tongAll + tong;
                                      return index_ + 1 === time_.detail[0]?.ingrendent[0]?.group.length ? tong : "empty"
                                    })}
                                </td>
                                <td>empty</td>
                              </tr>

                              {time_.detail[0]?.ingrendent.slice(1, time_.detail[0].ingrendent.length).map((ingrendent_, indx) => {
                                let tong = 0
                                return (
                                  <tr key={indx}>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>{ingrendent_.ingredent_name}</td>
                                    <td>{ingrendent_.unit.unit_name}</td>

                                    {groups.length > 0 ?
                                      groups.map(e => {
                                        if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                          return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                        } else {
                                          return <td key={e.id} className="text-right">empty</td>
                                        }
                                      })
                                      : <td style={{ textAlign: "right" }} >empty</td>
                                    }

                                    {groups.length > 0 ?
                                      groups.map(e => {
                                        if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                          return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                        } else {
                                          return <td key={e.id} className="text-right">empty</td>
                                        }
                                      })
                                      : <td style={{ textAlign: "right" }} >empty</td>
                                    }

                                    {groups.length > 0 ?
                                      groups.map(e => {
                                        if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                          return (
                                            <td key={e.id} style={{ textAlign: "right" }} >
                                              {formatter.format((ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal)))}
                                            </td>)
                                        } else {
                                          return <td key={e.id} style={{ textAlign: "right" }} >empty</td>
                                        }
                                      })
                                      : <td style={{ textAlign: "right" }} >empty</td>
                                    }

                                    <td style={{ textAlign: "right" }} >
                                      {formatter.format(Emaxlement(ingrendent_?.group.map((group_, index_) => {
                                        tong = tong + group_.amount * (group_.count_person - group_.meal);
                                        tongAll = tongAll + tong;
                                        return index_ + 1 === ingrendent_?.group.length ? tong : "empty"
                                      })))}
                                    </td>
                                    <td>empty</td>
                                  </tr>
                                )
                              }
                              )}

                              {time_.detail.slice(1, time_.detail.length)?.map((detail_, index) => {
                                let tong = 0;
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>empty</td>
                                      <td className="align-middle text-center" >{detail_.food_name}</td>
                                      <td >{detail_.ingrendent[0]?.ingredent_name}</td>
                                      <td>{detail_.ingrendent[0]?.unit.unit_name}</td>

                                      {groups.length > 0 ?
                                        groups.map(e => {
                                          if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                            return <td key={e.id} style={{ textAlign: "right" }} >{detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                          } else {
                                            return <td key={e.id} style={{ textAlign: "right" }} >empty</td>
                                          }
                                        })
                                        : <td style={{ textAlign: "right" }} >empty</td>
                                      }

                                      {groups.length > 0 ?
                                        groups.map(e => {
                                          if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                            return <td key={e.id} style={{ textAlign: "right" }} >{detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                          } else {
                                            return <td key={e.id} style={{ textAlign: "right" }} >empty</td>
                                          }
                                        })
                                        : <td style={{ textAlign: "right" }} >empty</td>
                                      }

                                      {groups.length > 0 ?
                                        groups.map(e => {
                                          if (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                            return (
                                              <td key={e.id} style={{ textAlign: "right" }} >
                                                {detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].amount * (detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - detail_.ingrendent[0]?.group.filter(i => Number(i.id) === Number(e.id))[0].meal)}
                                              </td>)
                                          } else {
                                            return <td key={e.id} style={{ textAlign: "right" }}  >empty</td>
                                          }
                                        })
                                        : <td style={{ textAlign: "right" }} >empty</td>
                                      }

                                      <td style={{ textAlign: "right" }} >
                                        {Emaxlement(detail_.ingrendent[0]?.group.map((group_) => {
                                          tong = tong + group_.amount * (group_.count_person - group_.meal);
                                          tongAll = tongAll + tong;
                                          return tong
                                        }))}
                                      </td>
                                      <td>empty</td>
                                    </tr>

                                    {detail_.ingrendent.slice(1, detail_.ingrendent.length).map((ingrendent_, indx) => {
                                      let tong = 0
                                      return (
                                        <tr key={indx}>
                                          <td>empty</td>
                                          <td>empty</td>
                                          <td>{ingrendent_.ingredent_name}</td>
                                          <td>{ingrendent_.unit.unit_name}</td>

                                          {groups.length > 0 ?
                                            groups.map(e => {
                                              if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                return <td key={e.id} style={{ textAlign: "right" }} >{ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount}</td>
                                              } else {
                                                return <td key={e.id} className="text-right">empty</td>
                                              }
                                            })
                                            : <td style={{ textAlign: "right" }} >empty</td>
                                          }

                                          {groups.length > 0 ?
                                            groups.map(e => {
                                              if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                return <td key={e.id} style={{ textAlign: "right" }} >
                                                  {ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person - ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal}</td>
                                              } else {
                                                return <td key={e.id} className="text-right">empty</td>
                                              }
                                            })
                                            : <td style={{ textAlign: "right" }} >empty</td>
                                          }

                                          {groups.length > 0 ?
                                            groups.map(e => {
                                              if (ingrendent_?.group.filter(i => Number(i.id) === Number(e.id)).length > 0) {
                                                return (
                                                  <td key={e.id} style={{ textAlign: "right" }} >
                                                    {
                                                      formatter.format((ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].count_person
                                                        -
                                                        ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].meal) *
                                                        ingrendent_?.group.filter(i => Number(i.id) === Number(e.id))[0].amount
                                                      )}
                                                  </td>
                                                )
                                              } else {
                                                return <td key={e.id} className="text-right">empty</td>
                                              }
                                            })
                                            : <td style={{ textAlign: "right" }} >empty</td>
                                          }

                                          <td style={{ textAlign: "right" }} >
                                            {formatter.format(Emaxlement(ingrendent_?.group.map((group_, index_) => {
                                              tong = tong + group_.amount * (group_.count_person - group_.meal);
                                              tongAll = tongAll + tong;
                                              return index_ + 1 === ingrendent_?.group.length ? tong : "empty"
                                            })))}
                                          </td>
                                          <td>empty</td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                )
                              })}
                            </React.Fragment>
                          )
                      })
                    }
                  </tbody>
                </Table>
              )
            })}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default MarketKitchen;
