import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";

class StoreCreate extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      dataInput: {
        id: "",
        supplier: {
          id_supplier: "",
          ingredent_price: "",
        },
        expriry_date: Date.now() / 1000,
        amount: "",
      },
      inputSuppiler:{
        id_supplier: "",
        ingredent_price: "",
      },
      suppliers: [],
   
    };
  }

  // componentDidMount() {
  //   this.getSuppliersData();
  // }

  // getSuppliersData = (limit = 100) => {
  //   axios.post(`/viewSupplier?limit=${limit}`).then((res) => {
  //     if (res.data.status === true) {
  //       this.setState({ suppliers: res.data.data });
  //     }
  //   });
  // };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
  };

  handleInputsupplier = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    // if( field=='ingredent_price'){ if(value.indexOf(',')!=-1)  value.slipt(',').join('')}
    let dataInput = { ...this.state.dataInput };
    dataInput.supplier[field] = value;
    this.setState({ dataInput: dataInput });
  };
 formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
  createinventory = () => {
    
    let supplier = this.state.inputSuppiler;
    axios
      .post(
        `/createInventory?id_ingredent=${
          this.props.propsdata.id
        }&supplier=[${JSON.stringify(supplier)}]&expriry_date=${
          this.state.dataInput.expriry_date !=null?  this.state.dataInput.expriry_date: (new Date())/1000
        }&amount=${
          this.state.dataInput.amount === ""
            ? 
            Number( this.props.propsdata.amount)
            :
            Number( this.state.dataInput.amount)
          
        }`
      )
      .then((res) => {
        if (res.data.status === true) {

          this.props.getStoreData(this.props.tabkey);
          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      });
  };

  dataSuppliers() {
    if (this.props.suppliers.length > 0) {
      return this.props.suppliers.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.supplier_name}
          </option>
        );
      });
    }
  }

  render() {
    // console.log(this.props)
 
    return (
      <Modal
        size="md"
        show={this.props.show}
        onHide={this.props.onHide}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nhập kho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              defaultValue={this.props.propsdata.ingredent_name}
              placeholder="Tên nguyên liệu"
              disabled
              required
            />
            <Form.Label>
              Tên nguyên liệu<i className="text-danger">*</i>
            </Form.Label>
          </Form.Floating>
          <Row>
            <p className="text-center">
              Chọn nhà cung cấp và nhập giá cho lô hàng
            </p>
            <Col md={6}>
              <Form.Floating className="mb-3">
                <Form.Select
                  onChange={(choice)=>{
                    let dataInput = { ...this.state.inputSuppiler};
                    dataInput.id_supplier = choice.target.value;
                    this.setState({ inputSuppiler: dataInput });
                  }
                  }
                  name="id_supplier"
                  required
                >
                  <option value="">--Chọn--</option>
                  {this.dataSuppliers()}
                </Form.Select>
                <Form.Label>
                  Nhà cung cấp<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>
            </Col>
            <Col md={6}>
                <Form.Floating>
                  <CurrencyFormat
                    className="form-control"
                     name="ingredent_price"
                    thousandSeparator={true}
                    placeholder="Giá nhập"
                    required
                    onValueChange={(values) => {
                      let dataInput = { ...this.state.inputSuppiler};
                      dataInput.ingredent_price = values.value;
                      this.setState({ inputSuppiler: dataInput });

                    }}
                  />
                  <Form.Label>Giá<i className="text-danger">*</i> (VND)</Form.Label>
                </Form.Floating>
            </Col>
          </Row>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              onChange={this.handleInput}
              name="amount"
              placeholder="số lượng"
              required
            />
            <Form.Label>
              Số lượng<i className="text-danger">*</i>
            </Form.Label>
          </Form.Floating>
          <Form.Group className="mb-3">
            <Col md={6}>
            { Number(this.props.tabkey) === 1 ? <>
              <Form.Label>
                Hạn sử dụng<i className="text-danger">*</i>
              </Form.Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={this.state.dataInput.expriry_date * 1000}
                  onChange={(newvalue) => {
                    let dataInput = this.state.dataInput;
                    dataInput.expriry_date = newvalue.toDate().valueOf() / 1000;
                    this.setState({
                      dataInput: dataInput,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </>:""}
             
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={this.props.onHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => {
              if (this.state.inputSuppiler.id_supplier === "") {
                swal({
                  title: "Hãy chọn nhà cung cấp",
                  icon: "error",
                  successMode: true,
                });
              } else if (this.state.dataInput.amount === "") {
                swal({
                  title: "Hãy nhập số lượng",
                  icon: "error",
                  successMode: true,
                });
              } else if (this.state.dataInput.expriry_date === "") {
                swal({
                  title: "Hãy chọn hạn sử dụng",
                  icon: "error",
                  successMode: true,
                });
              } else {
                this.createinventory();
              }
            }}
          >
            <i className="fa-solid fa-check"></i> Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default StoreCreate;
