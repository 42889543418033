import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Select from 'react-select';
import { Modal, Button, Form, Card } from "react-bootstrap";
// import CurrencyFormat from "react-currency-format";

class AddChildUnit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            childUnit:
                this.props.unitData ||
                {
                    id: "",
                    unit_name: "",
                    parent_unit: 0,
                    // redemption_value: 0,
                },
            listUnits: this.props.units
        };
    }

    async componentDidMount() {
    }

    handleSubmit = () => {
        let id = this.state.childUnit.id;
        let unitName = this.state.childUnit.unit_name;
        let parent_unit = this.state.childUnit.parent_unit;
        // let redemption_value = this.state.childUnit.redemption_value;

        if(id === ''){
            // axios.post(`/createUnit?unit_name=${unitName}&parent_unit=${parent_unit}&redemption_value=${redemption_value}`)
            axios.post(`/createUnit?unit_name=${unitName}&parent_unit=${parent_unit}`)
            .then(res => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                    this.props.onSubmit();
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                }
            });
        }else{
            // axios.post(`/updateUnit?id_unit=${id}&unit_name=${unitName}&parent_unit=${parent_unit}&redemption_value=${redemption_value}`)
            axios.post(`/updateUnit?id_unit=${id}&unit_name=${unitName}&parent_unit=${parent_unit}`)
            .then(res => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                    this.props.onSubmit();
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                }
            });
        } 
    }

    render() {
        const {onHide} = {...this.props};

        const unitOptions = this.state.listUnits.filter(item => item.parent_unit === 0).map(item => {
            return{
                value: item.id,
                label: item.unit_name
            }
        })

        return (
            <Modal show={this.props.show} onHide={onHide} size="sm" backdrop="static" keyboard={false} id="addUnitModal" >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <i className="fa-solid fa-weight-scale"></i> {this.state.childUnit.id !== "" ? "Cập nhật đơn vị" : "Thêm đơn vị"}
                    </Modal.Title>
                </Modal.Header>

                <Card className="text-center">
                    <Card.Body>
                        <Form.Floating >
                            <Form.Control 
                                type="text" 
                                onChange={(e) => {
                                    let newUnit = this.state.childUnit;
                                    newUnit.unit_name = e.target.value;

                                    this.setState({
                                        childUnit: newUnit
                                    })
                                }} 
                                name="unit_name" 
                                value={this.state.childUnit.unit_name} 
                                placeholder="Đơn vị đi chợ" 
                                required />
                            <Form.Label>Đơn vị đi chợ<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>

                        {/* <Form.Floating className="mt-2">
                            <CurrencyFormat
                                className="form-control"
                                thousandSeparator={true}
                                placeholder="Giá trị quy đổi"
                                required
                                value={this.state.childUnit.redemption_value}
                                onValueChange={(values) => {
                                    let newUnit = this.state.childUnit;
                                    newUnit.redemption_value = values.value;

                                    this.setState({
                                        childUnit: newUnit
                                    })
                                }}
                            />
                            <Form.Label>Giá trị quy đổi<i className="text-danger">*</i></Form.Label>

                        </Form.Floating> */}

                        <Form.Floating className="mt-2">
                            <Select
                                options={unitOptions}
                                name="unitParents[]"
                                className="text-start"
                                placeholder={<>Đơn vị định lượng<i className="text-danger">*</i></>}
                                value={unitOptions.filter(item => item.value === this.state.childUnit.parent_unit)[0]}
                                onChange={(choice) => {
                                    let newUnit = this.state.childUnit;
                                    newUnit.parent_unit = choice.value;

                                    this.setState({
                                        childUnit: newUnit
                                    })
                                }}
                            />
                        </Form.Floating>
                    </Card.Body>
                </Card>

                <Modal.Footer>
                    <Button size="sm" variant="secondary" id="btnCloseAddUnit" onClick={onHide}>
                        <i className="fa-solid fa-times"></i> Đóng
                    </Button>

                    <Button size="sm" variant="success" type="submit" onClick={() => this.handleSubmit()}>
                            <i className="fa-solid fa-check"></i> Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddChildUnit;