import axios from "axios";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import swal from "sweetalert";
import ParentAccountEdit from "./ParentAccountEdit";
import ParentAccountsCreate from "./ParentAccountsCreate";
import StudentsPicker from "./StudentsPicker";
import { toSlug } from "../utils/helpers";

const ParentAccountsIndex = () => {
  const [accounts, setAccounts] = useState([]);
  const [accountsSearch, setAccountsSearch] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [addAccountsModalShow, setAddAccountsModalShow] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentIdsHadParent, setStudentIdsHadParent] = useState([]);
  const [editAccountModalShow, setEditAccountModalShow] = useState(false);
  const [studentsPickerShow, setStudentsPickerShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fileParent, setFileParent] = useState(null);
  const [isAddParentListLoading, setIsAddParentListLoading] = useState(false);

  const typeOptions = [
    {
      value: "ALL",
      label: "Tất cả",
    },
    {
      value: "standard",
      label: "Cơ bản",
    },
    {
      value: "advanced",
      label: "Nâng cao",
    },
  ];

  const statusOptions = [
    {
      value: "ALL",
      label: "Tất cả",
    },
    {
      value: "active",
      label: "Đang hoạt động",
    },
    {
      value: "blocked",
      label: "Đã bị khóa",
    },
  ];
  useEffect(() => {
    getAllAccounts();
    document.title = "Tài khoản phụ huynh";
  }, []);

  //#region API
  const getAllAccounts = async () => {
    // setIsLoading(true);
    await axios
      .get(`/parent-account-shows`)
      .then((res) => {
        if (res.status === 200) {
          const accounts = res.data.data.accounts.map((item) => {
            return { ...item, type: item.schoolRoles[0]?.role?.role_name };
          });

          let listStudentIds = [];
          for (let i = 0; i < accounts.length; i++) {
            listStudentIds = [
              ...listStudentIds,
              ...accounts[i].children.map((child) => child.id),
            ];
          }

          setAccounts(accounts);
          setAccountsSearch(accounts);
          setStudentIdsHadParent(listStudentIds);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };

  const deleteAccount = (accountId) => {
    axios
      .delete(`/parent-account-delete?id=${accountId}`)
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        getAllAccounts();
      })
      .catch((err) => {
        const msg = err.response.data.messages ?? "Hệ thống xảy ra lỗi";
        toast(msg, {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  //#endregion API

  //#region Logic
  const handleShowAddAccountModal = (listStudents) => {
    setAddAccountsModalShow(true);
    setSelectedStudents(listStudents);
  };
  const handleCloseAddAccountModal = (isReload) => {
    setAddAccountsModalShow(false);
    setSelectedStudents([]);
    if (isReload) {
      getAllAccounts();
    }
  };

  const handleShowEditAccountModal = (account) => {
    setEditAccountModalShow(true);
    setSelectedAccount(account);
  };
  const handleCloseEditAccountModal = (isReload) => {
    setEditAccountModalShow(false);
    setSelectedAccount();
    if (isReload) {
      getAllAccounts();
    }
  };

  const handleShowStudentsPicker = () => {
    setStudentsPickerShow(true);
  };
  const handleCloseStudentsPicker = (listStudents) => {
    setStudentsPickerShow(false);
    if (listStudents) {
      handleShowAddAccountModal(listStudents);
    }
  };

  const handleDeleteAccount = (account) => {
    swal({
      title: "Bạn muốn xóa tài khoản?",
      text: `${account.username}`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        deleteAccount(account.id);
      }
    });
  };
  const handleSelectFile = (e) => {
    const fileExtention = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!fileExtention.some((ex) => ex === e.target.files[0]?.type)) {
      toast("Sai định dạng tệp Excel !!!", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }
    setFileParent(e.target.files[0]);
  };
  const handleImportParentListExcel = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("data", fileParent);
    setIsAddParentListLoading(true);

    axios
      .post(`/parent-account-bulk-import`, bodyFormData)
      .then((res) => {
        if (res.data.data?.invalidData?.length > 0) {
          toast(
            res.data.data?.invalidData?.length +
            " dữ liệu chưa đúng định dạng" || "Lỗi",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        } else
          toast(res.data.messages || "Lưu thành công", {
            type: "success",
            autoClose: 2000,
          });
        getAllAccounts();
        setIsAddParentListLoading(false);
        setFileParent(null);

        document.getElementById("parentlistFile").value = "";
      })
      .catch((error) => {
        document.getElementById("parentlistFile").value = "";
        setIsAddParentListLoading(false);
        setFileParent(null);

        toast(
          error.response.data.messages ||
          error.response.data.messages.data ||
          "Xảy ra lỗi",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };
  const handleSearch = (searchText) => {
    console.log(accounts);

    const listAccounts = accounts.filter(
      (account) =>
        account.children?.some((e) =>
          toSlug(e.student_name)?.includes(toSlug(searchText))
        ) || account?.phone_number?.includes(searchText)
    );
    setAccountsSearch(listAccounts);
  };

  const handleSelectedTypeSearch = (type) => {
    if (type.value === "ALL") {
      setAccountsSearch(accounts);
    } else {
      const listAccounts = accounts.filter(
        (subject) => subject.type === type.value
      );
      setAccountsSearch(listAccounts);
    }
  };

  const handleSelectedStatusSearch = (type) => {
    if (type.value === "ALL") {
      setAccountsSearch(accounts);
    } else {
      const listAccounts = accounts.filter(
        (subject) => subject.is_status === type.value
      );
      setAccountsSearch(listAccounts);
    }
  };
  //#endregion Logic

  //#region Render
  const dataTableColumns = [
    {
      name: "Tên học sinh",
      selector: (row) => row.children,
      wrap: true,
      cell: (row) => (
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: 0,
            paddingBottom: 0,
            marginBottom: 0,
          }}
        >
          {row.children?.map((e, i) => (
            <li key={i}>{e.student_name}</li>
          ))}
        </ul>
      ),
      sortable: true,
    },
    {
      name: "Loại tài khoản",
      selector: (row) => {
        return row.type == "standard" ? "Cơ bản" : "Nâng cao";
      },
      sortable: true,
    },
    {
      name: "Tên phụ huynh",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Trạng thái",
      selector: (row) => row.is_status,
      cell: (row) =>
        row.is_status == "active" ? (
          <div className="text-success">Đang hoạt động</div>
        ) : (
          <div className="text-danger">Đã bị khóa</div>
        ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          {new Check().permission(["28", "35", "67", "40"]) ? (
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              data-toggle="tooltip"
              title="Cập nhật thông tin"
              onClick={() => handleShowEditAccountModal(row)}
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : (
            ""
          )}

          {new Check().permission(["31"]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              data-toggle="tooltip"
              title="Xóa tài khoản"
              onClick={() => handleDeleteAccount(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Breadcrumb className="mb-2 mt-2">
          <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
          <Breadcrumb.Item active>Tài khoản phụ huynh</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-4">
          <Card.Header>
            <Card.Title className="d-block d-xl-flex justify-content-between">
              <div>
                <i className="fas fa-list me-1" /> Danh sách tài khoản phụ huynh
              </div>
              <div className="d-block d-md-flex my-xl-0 align-items-center">
                <label
                  className="btn btn-sm btn-primary me-2 "
                  style={{ cursor: "pointer" }}
                  htmlFor="parentlistFile"
                  title="Nhập DSPH"
                >
                  <i className="fa-solid fa-file-import"></i> Nhập DSPH
                </label>

                <input
                  size="sm"
                  type="file"
                  id="parentlistFile"
                  className="d-none "
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  multiple={false}
                  onChange={(e) => handleSelectFile(e)}
                  disabled={isAddParentListLoading}
                />
                {fileParent ? (
                  isAddParentListLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">
                        <div>Đang thêm mới phụ huynh...</div>
                      </div>
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex mt-3 mt-md-0">
                      <div
                        style={{ minWidth: 100 }}
                        className="d-flex align-items-center text-truncate me-3"
                      >
                        Đã chọn: {fileParent.name}
                      </div>

                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-3"
                        onClick={() => handleImportParentListExcel()}
                        disabled={isAddParentListLoading}
                      >
                        Lưu
                      </Button>

                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        className="me-3"
                        onClick={() => {
                          document.getElementById("parentlistFile").value = "";
                          setFileParent(null);
                          setIsAddParentListLoading(false);
                        }}
                      >
                        Hủy
                      </Button>
                    </div>
                  )
                ) : (
                  <div>
                    {new Check().permission(["18"]) ? (
                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-2 btn-dels"
                        href="/File mẫu danh sách tài khoản phụ huynh.xlsx"
                        target="_blank"
                        download
                        title="Tải file mẫu"
                      >
                        <i className="fa-solid fa-download"></i> Tải file mẫu
                      </Button>
                    ) : (
                      <></>
                    )}
                    {new Check().permission(["17"], "or") ? (
                      <Button
                        variant="success"
                        size="sm"
                        className="float-end "
                        onClick={() => handleShowStudentsPicker()}
                        title="Thêm tài khoản"
                      >
                        <i className="fa-solid fa-plus" /> Thêm
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
            </Card.Title>
          </Card.Header>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Card.Body>
              <Row>
                <Col md={3} sm={4} className="mb-2">
                  <Form.Control
                    style={{ height: "39px" }}
                    onChange={(event) => handleSearch(event.target.value)}
                    // value={classsearch}
                    placeholder="Nhập tên học sinh, số điện thoại..."
                  />
                </Col>
                <Col
                  md={3}
                  sm={5}
                  className="mb-2"
                  style={{ zIndex: 99, display: "flex" }}
                >
                  <span style={{ padding: "10px", whiteSpace: "nowrap" }}>
                    Loại tài khoản:
                  </span>
                  <Select
                    options={typeOptions}
                    defaultValue={typeOptions[0]}
                    onChange={(value) => {
                      handleSelectedTypeSearch(value);
                    }}
                  />
                </Col>

                <Col
                  md={3}
                  sm={3}
                  className="mb-2"
                  style={{ zIndex: 99, display: "flex" }}
                >
                  <span style={{ padding: "10px", whiteSpace: "nowrap" }}>
                    Trạng thái:
                  </span>
                  <Select
                    options={statusOptions}
                    defaultValue={statusOptions[0]}
                    onChange={(value) => {
                      handleSelectedStatusSearch(value);
                    }}
                  />
                </Col>
              </Row>
              <DataTables data={accountsSearch} columns={dataTableColumns} />
            </Card.Body>
          )}
        </Card>
      </Container>

      {addAccountsModalShow && (
        <ParentAccountsCreate
          show={addAccountsModalShow}
          onHide={handleCloseAddAccountModal}
          listStudents={selectedStudents}
        />
      )}

      {editAccountModalShow && (
        <ParentAccountEdit
          show={editAccountModalShow}
          onHide={handleCloseEditAccountModal}
          account={selectedAccount}
          studentIdsHadParent={studentIdsHadParent}
        />
      )}

      {studentsPickerShow && (
        <StudentsPicker
          show={studentsPickerShow}
          onClose={handleCloseStudentsPicker}
          exclusionStudentIds={studentIdsHadParent}
        />
      )}
    </>
  );
  //#endregion Render
};

export default ParentAccountsIndex;
