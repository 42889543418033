import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import Check from "../../admin/other/Check";

class TuitionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredent: [],
      err: {},
      dataInput: {},
      childUnitModalShow: false,
      modalSuppliershow: false,
      keyIngredent: 1,
      bntAdd: false,
      dataInput: {
        ...this.props.obj.ingredent,
        amount: parseInt(this.props.obj.ingredent?.amount) || "",
      },
    };
  }

  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.typeModal == "TM"
      ? this.createIngredent()
      : this.updateIngredent();
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    let dataInput = { ...this.state.dataInput };

    dataInput[field] = value;

    this.setState({ dataInput: dataInput });

    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };

  updateIngredent = () => {
    let data = {
      id: this.state.dataInput?.id,
      name: this.state.dataInput?.name || "",
      description: this.state.dataInput?.description || "",
      type: this.state.dataInput?.type == 1 ? "MONTHLY" : "ANNUAL",
      amount: this.state.dataInput?.amount || "",
    };
    swal({
      title: "Bạn muốn cập nhật khoản thu?",
      icon: "error",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios({
          method: "put",
          url: "tuition-update",
          data: data,
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.handleHide();
          })
          .catch((err) => {
            this.setState({ err: err.response.data.messages });
          });
      }
    })
  };

  checkNull = (item) => {
    return (
      item == null ||
      item == "" ||
      item == 0 ||
      item == undefined ||
      item == "undefined" ||
      item == "null"
    );
  };
  createIngredent = async () => {
    let data = {
      name: this.state.dataInput?.name || "",
      description: this.state.dataInput?.description || "",
      type: this.state.dataInput?.type == 1 ? "MONTHLY" : "ANNUAL",
      amount: this.state.dataInput?.amount || "",
    };

    await axios({
      method: "post",
      url: "tuition-create",
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast(res.data.messages, { type: "success" });
        this.handleHide();
      })
      .catch((err) => {
        this.setState({ err: err.response.data.messages });
      });
  };

  handleHide = () => {
    return this.props.onHide(true);
  };

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="md"
          backdrop="static"
          keyboard={false}
          id="addIngredentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.typeModal === "TM"
                ? " Thêm khoản thu"
                : "Chi tiết khoản thu"}{" "}
              &nbsp;&nbsp;&nbsp;
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Tên khoản thu"
                      name="name"
                      value={this.state.dataInput?.name || ""}
                      onChange={(e) => {
                        this.handleInput(e);
                        delete this.state.err["group_name"];
                      }}
                      required
                    />
                    <Form.Label>
                      Tên khoản thu<i className="text-danger">*</i>
                    </Form.Label>
                    <div className="text-form-err">{this.state.err?.name}</div>
                  </Form.Floating>
                </Col>
              </Row>
              <Form.Floating className="mb-3">
                <CurrencyFormat
                  className="form-control text-end"
                  placeholder="Từ"
                  thousandSeparator={true}
                  name="amount"
                  required
                  value={this.state.dataInput?.amount || ""}
                  onValueChange={(values) => {
                    this.setState({
                      dataInput: {
                        ...this.state.dataInput,
                        amount: values.value,
                      },
                    });
                    delete this.state.err["amount"];
                  }}
                />
                <Form.Label>
                  Giá<i className="text-danger"></i> (VNĐ)
                  <i className="text-danger">*</i>
                </Form.Label>
                <div className="invalid-feedback"></div>
                <div className="text-form-err">{this.state.err?.amount}</div>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Select
                  required
                  id="type"
                  name="type"
                  placeholder="Loại"
                  value={this.state.dataInput?.type || ""}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                >
                  <option value="">--Chọn--</option>
                  <option value="1">Theo tháng</option>
                  <option value="2">Theo năm</option>
                </Form.Select>

                <Form.Label for="idYear">
                  Loại<i className="text-danger">*</i>
                </Form.Label>
                {this.state.err?.type ? (
                  <span className="text-danger">{this.state.err?.type}</span>
                ) : (
                  ""
                )}
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Tên khoản thu"
                  name="description"
                  value={this.state.dataInput?.description || ""}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                />
                <Form.Label>Mô tả</Form.Label>
                <div className="text-form-err">
                  {this.state.err?.description}
                </div>
              </Form.Floating>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.handleHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              {this.props.typeModal == "TM"
                ?
                <Button size="sm" variant="success" type="submit">
                  <i className="fa-solid fa-check"></i> Lưu
                </Button>
                : new Check().permission(["136"])
                  ?
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => this.updateIngredent()}
                  >
                    <i className="fa-solid fa-check"></i> Cập nhật
                  </Button>
                  : null}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default TuitionModal;
