import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Table, Button, Container, Breadcrumb, Card, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Select from "react-select";

const initialDay = [
	{ id: 1, day_name: "Thứ 2" },
	{ id: 2, day_name: "Thứ 3" },
	{ id: 3, day_name: "Thứ 4" },
	{ id: 4, day_name: "Thứ 5" },
	{ id: 5, day_name: "Thứ 6" },
	{ id: 6, day_name: "Thứ 7" },
	{ id: 7, day_name: "Chủ nhật" },
];

const classifyOptions = [
	{
		label: "Tất cả",
		value: "1,2",
	},
	{
		label: "Học sinh",
		value: "1",
	},
	{
		label: "Nhân viên",
		value: "2",
	},
];

const MarketList = () => {
	const navigate = useNavigate();

	const [schoolInfo, setSchoolInfo] = useState({});

	const [voucherMar, setVoucherMar] = useState([]);
	const [voucherMarShow, setVoucherMarShow] = useState([]);
	const [tmpVoucherMar, setTmpVoucherMar] = useState([]);
	const [isChangeVoucherMar, setIsChangeVoucherMar] = useState(false);

	const [listDay, setListDay] = useState([]);
	const [daySelect, setDaySelect] = useState([]);
	const [tabSelect, setTabSelect] = useState(1);

	const [selectedClassify, setSelectedClassify] = useState({
		label: "Học sinh",
		value: "1",
	});

	const [textSearch, setTextSearch] = useState("");

	const [supplierOption, setSupplierOption] = useState([]);

	const [start, setStartDate] = useState(new Date());
	const [term, setTermDate] = useState(new Date());
	const [weekInMonth, setWeekInMonth] = useState("");
	const [checkload, setCheckload] = useState(false);

	useEffect(() => {
		document.title = "Danh sách đi chợ";

		getDetailschool();
		getlistsupplier();

		let initialDate = new Date();
		let initialWeek = setDate(initialDate);

		setListDay(
			initialDay.map((day, index) => {
				return {
					id: day.id,
					day_name: day.day_name,
					day: initialWeek[index],
				};
			}),
		);

		setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (daySelect.length !== 1) {
			setIsChangeVoucherMar(false);
		}

		if (daySelect.length === 0) {
			setVoucherMar([]);
			setVoucherMarShow([]);
		}

		if (!daySelect.some((day) => Number(day) === Number(tabSelect))) {
			setTabSelect(daySelect[0]);
		}
	}, [daySelect]); // eslint-disable-line react-hooks/exhaustive-deps

	//#region Fetch Data
	const getDetailschool = () => {
		axios.get(`/detailSchool`).then((res) => {
			if (res.data.status === true) {
				setSchoolInfo(res.data.data[0]);
			}
		});
	};

	const getlistsupplier = () => {
		axios
			.post("/viewSupplier")
			.then((res) => {
				if (res.data.status) {
					const supplierData = res.data.data.map((item) => {
						return { value: item.id, label: item.supplier_name };
					});

					setSupplierOption(supplierData);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getVoucherMark = (startDay, endDay, dayIds, classifyIds) => {
		setCheckload(true);
		setVoucherMar([]);
		setVoucherMarShow([]);

		const strDaysId = dayIds.join();

		axios
			.post(
				`/voucher?is_real=0&type=1&start_at=${startDay.getTime() / 1000}&term_at=${
					endDay.getTime() / 1000
				}&id_day=${strDaysId}&id_classify=${classifyIds}`,
			)
			.then((res) => {
				if (res.data.status === true) {
					setVoucherMar(res.data.data);
					setVoucherMarShow(
						res.data.data.map((voucher) => {
							return {
								...voucher,
								ingredent: voucher.ingredent.filter((ingredient) => toSlug(ingredient.ingredent_name).includes(toSlug(textSearch))),
							};
						}),
					);
					setCheckload(false);
					if (res.data.data.length === 1) {
						setTmpVoucherMar(res.data.data);
					}
				} else {
					setCheckload(false);
				}
			});
	};

	const saveMarketList = () => {
		const saveVoucher = [];
		const dataIngredents = [];
		const formVoucher = [];

		let totalPrice = 0;

		voucherMar[0].ingredent
			.filter((item) => Number(item.ingredent_type) !== 3)
			.map((item) => {
				if (Number(item.ingredent_type) === 1) {
					totalPrice += Number(item.nutrients.price) * Number(item.count).toFixed(2);

					if (Number(item.nutrients.price) * Number(item.count).toFixed(2) === 0) {
						return item;
					}
				}

				saveVoucher.push({
					id: item.id_ingredent,
					id_supplier: item.supplier.id_supplier,
					id_unit: item.unit.id_unit,
					ingredent_type: item.ingredent_type,
					ingredent_price: Number(item.nutrients.price),
					count: Number(item.count),
					inventory: Number(item.inventory.amount),
					note: item.note ? item.note : "",
				});

				dataIngredents.push({
					id_ingredent: item.id_ingredent,
					id_supplier: item.supplier.id_supplier,
					price: Number(item.nutrients.price),
				});

				return item;
			});

		const expiredDate = new Date();
		expiredDate.setHours(24, 0, 0, 0);

		voucherMar[0].ingredent
			.filter((item) => Number(item.ingredent_type) === 1)
			.map((item) => {
				if (Number(item.count) === 0) {
					return item;
				}

				formVoucher.push({
					id_ingredent: item.id_ingredent,
					supplier: [
						{
							id_supplier: item.supplier.id_supplier,
							ingredent_price: Number(item.nutrients.price),
						},
					],
					amount: Number(item.count),
					expriry_date: expiredDate.getTime() / 1000,
				});

				return item;
			});

		let market_date = listDay.filter((day) => Number(day.id) === Number(daySelect[0]))[0].day.getTime() / 1000;

		axios
			.post(`/createVoucher`, null, {
				params: {
					type_voucher: 1,
					id_menu: voucherMar[0].id_menu,
					market_date: market_date,
					stt_day: voucherMar[0].stt_day,
					voucher_price: totalPrice.toFixed(0),
					ingredent: JSON.stringify(saveVoucher),
					id_classify: Number(selectedClassify.value),
				},
			})
			.then((res) => {
				if (res.data.status === true) {
					axios.post(`/createMoreInventory?inventory=${JSON.stringify(formVoucher)}&market_date=${market_date}`).then((res) => {
						if (res.data.status === true) {
							axios
								.post(`/changeQuantily`, null, {
									params: {
										id_menu: voucherMar[0].id_menu,
										ingredent: JSON.stringify(dataIngredents),
										stt_day: voucherMar[0].stt_day,
									},
								})
								.then((res) => {
									if (res.data.status === true) {
										toast("Lưu phiếu đi chợ thành công", { type: "success", autoClose: 1000 });
										navigate("/admin/market-report");
									} else {
										toast(res.data.data.mess, { type: "error", autoClose: 1000 });
									}
								});
						} else {
							toast(res.data.data.mess, { type: "error", autoClose: 1000 });
						}
					});
				} else {
					toast(res.data.data.mess, { type: "error", autoClose: 1000 });
				}
			});
	};
	//#endregion

	//#region Handle Logic
	const handleCheck = (day) => {
		setDaySelect(
			[...daySelect, Number(day)].sort(function (a, b) {
				return a - b;
			}),
		);
		getVoucherMark(start, term, [...daySelect, Number(day)], selectedClassify.value);
	};

	const handleUnCheck = (day) => {
		setDaySelect(
			daySelect
				.filter((dayItem) => Number(dayItem) !== Number(day))
				.sort(function (a, b) {
					return a - b;
				}),
		);
		getVoucherMark(
			start,
			term,
			daySelect.filter((dayItem) => dayItem !== Number(day)),
			selectedClassify.value,
		);
	};

	const handleCheckAll = (days) => {
		setDaySelect(
			days.sort(function (a, b) {
				return a - b;
			}),
		);
		getVoucherMark(start, term, days, selectedClassify.value);
	};

	const handleUnCheckAll = () => {
		setDaySelect([]);
	};

	const handleSearch = (e) => {
		setTextSearch(e.target.value);

		setVoucherMarShow(
			voucherMar.map((voucher) => {
				return {
					...voucher,
					ingredent: voucher.ingredent.filter((ingredient) => toSlug(ingredient.ingredent_name).includes(toSlug(e.target.value.trim()))),
				};
			}),
		);
	};

	const toSlug = (str) => {
		str = str.toLowerCase(); // Chuyển hết sang chữ thường

		// xóa dấu
		str = str
			.normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
			.replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

		str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

		str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

		return str;
	};

	const xport = () => {
		const wb = XLSX.utils.book_new();

		console.log(voucherMar);

		voucherMar.map((item) => {
			if (item.ingredent && item.ingredent.length !== 0) {
				XLSX.utils.book_append_sheet(wb, handleNewSheet(`market-table-${item.stt_day}`, item.ingredent), item.day_name);
			}

			return item;
		});

		XLSX.writeFile(wb, "Danh sách đi chợ.xlsx");
	};

	const handleNewSheet = (idTable, ingredientList) => {
		const table = document.getElementById(idTable);

		var ws = XLSX.utils.table_to_sheet(table);

		const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
		const colAlpha_Sum = ["A", "C", "D", "E", "F", "G", "I"];

		const fontStyle = {
			name: "Times New Roman",
		};

		const borderStyle = {
			top: { style: "thin" },
			bottom: { style: "thin" },
			left: { style: "thin" },
			right: { style: "thin" },
		};

		const merge = [
			{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
			{ s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
			{ s: { r: 2, c: 0 }, e: { r: 2, c: 8 } },
		];
		ws["!merges"] = merge;

		// Header
		colAlpha.map((alpha) => {
			ws[`${alpha}1`].s = {
				font: {
					name: "Times New Roman",
					bold: true,
				},
				alignment: {
					vertical: "center",
					horizontal: "left",
				},
			};

			return alpha;
		});

		colAlpha.map((alpha) => {
			ws[`${alpha}2`].s = {
				font: {
					name: "Times New Roman",
					sz: 16,
					bold: true,
				},
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			return alpha;
		});

		colAlpha.map((alpha) => {
			ws[`${alpha}3`].v = " ";

			ws[`${alpha}3`].s = {
				alignment: {
					vertical: "center",
					horizontal: "center",
					wrapText: true,
				},
			};

			return alpha;
		});

		colAlpha.map((alpha) => {
			ws[`${alpha}4`].s = {
				font: {
					name: "Times New Roman",
					bold: true,
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
				alignment: {
					vertical: "center",
					horizontal: "center",
					wrapText: true,
				},
			};

			return alpha;
		});
		// Header

		// Set Column Width
		var wscols = [{ wch: 3.5 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 10 }];

		ws["!cols"] = wscols;
		ws["!rows"] = [{ hpt: 20 }, { hpt: 35 }, {}, { hpt: 40 }];

		// Format number Price, Unit, Will Money
		const numberFormat = "#,###,###,###";
		let index = 5;

		ingredientList
			.filter((item) => Number(item.ingredent_type) === 1)
			.map((item) => {
				let priceCol = `E${index}`;
				let unitCol = `F${index}`;
				let inventoryCol = `G${index}`;
				let willMoneyCol = `H${index}`;

				colAlpha.map((alpha) => {
					if (ws[`${alpha}${index}`].v === "-") {
						ws[`${alpha}${index}`].v = " ";
					}

					ws[`${alpha}${index}`].s = {
						font: fontStyle,
						border: borderStyle,
					};

					return alpha;
				});

				ws[priceCol.toString()].z = numberFormat;
				delete ws[priceCol.toString()].w;
				XLSX.utils.format_cell(ws[priceCol.toString()]);

				ws[unitCol.toString()].z = "#,###,###,##0.00";
				delete ws[unitCol.toString()].w;
				XLSX.utils.format_cell(ws[unitCol.toString()]);

				ws[inventoryCol.toString()].z = "#,###,###,##0.00";
				delete ws[inventoryCol.toString()].w;
				XLSX.utils.format_cell(ws[inventoryCol.toString()]);

				ws[willMoneyCol.toString()].z = numberFormat;
				delete ws[willMoneyCol.toString()].w;
				XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

				index += 1;

				return item;
			});

		if (ingredientList.filter((item) => Number(item.ingredent_type) === 1).length !== 0) {
			ws[`H${index}`].z = numberFormat;
			delete ws[`H${index}`].w;
			XLSX.utils.format_cell(ws[`H${index}`]);

			colAlpha_Sum.map((alpha) => {
				ws[`${alpha}${index}`].v = " ";

				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						vertical: "center",
						horizontal: "center",
					},
				};

				return alpha;
			});

			ws[`B${index}`].s = {
				font: {
					...fontStyle,
					bold: true,
				},
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			ws[`H${index}`].s = {
				font: {
					...fontStyle,
					bold: true,
				},
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "right",
				},
			};

			index++;
		} else {
			colAlpha.map((alpha) => {
				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						vertical: "center",
						horizontal: "center",
					},
				};

				return alpha;
			});

			index++;
		}

		ingredientList
			.filter((item) => Number(item.ingredent_type) === 2)
			.map((item) => {
				colAlpha.map((alpha) => {
					if (ws[`${alpha}${index}`].v === "-") {
						ws[`${alpha}${index}`].v = " ";
					}

					ws[`${alpha}${index}`].s = {
						font: fontStyle,
						border: borderStyle,
					};

					return alpha;
				});

				let priceCol = `E${index}`;
				let amountCol = `F${index}`;
				let inventoryCol = `G${index}`;
				let willMoneyCol = `H${index}`;

				ws[priceCol.toString()].z = numberFormat;
				delete ws[priceCol.toString()].w;
				XLSX.utils.format_cell(ws[priceCol.toString()]);

				ws[amountCol.toString()].z = "#,###,###,##0.00";
				delete ws[amountCol.toString()].w;
				XLSX.utils.format_cell(ws[amountCol.toString()]);

				ws[inventoryCol.toString()].z = "#,###,###,##0.00";
				delete ws[inventoryCol.toString()].w;
				XLSX.utils.format_cell(ws[inventoryCol.toString()]);

				ws[willMoneyCol.toString()].z = numberFormat;
				delete ws[willMoneyCol.toString()].w;
				XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

				index += 1;

				return item;
			});

		if (ingredientList.filter((item) => Number(item.ingredent_type) === 2).length !== 0) {
			ws[`H${index}`].z = numberFormat;
			delete ws[`H${index}`].w;
			XLSX.utils.format_cell(ws[`H${index}`]);

			colAlpha_Sum.map((alpha) => {
				ws[`${alpha}${index}`].v = " ";

				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						vertical: "center",
						horizontal: "center",
					},
				};

				return alpha;
			});

			ws[`B${index}`].s = {
				font: {
					...fontStyle,
					bold: true,
				},
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			ws[`H${index}`].s = {
				font: {
					...fontStyle,
					bold: true,
				},
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "right",
				},
			};
		} else {
			colAlpha.map((alpha) => {
				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						vertical: "center",
						horizontal: "center",
					},
				};

				return alpha;
			});
		}

		return ws;
	};

	const handleExportVoucehrMar = () => {
		swal({
			title: `Xác nhận`,
			text:
				voucherMar.length > 1
					? `Bạn muốn xuất phiếu đi chợ Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth}) ${stringFirstDate(start)} - ${stringLastDate(term)}`
					: `Bạn muốn xuất phiếu đi chợ ngày ${stringLastDate(listDay.find((day) => day.id === daySelect[0]).day)}`,
			icon: "info",
			buttons: ["Đóng", "Export"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				xport();
			}
		});
	};

	const handleSaveVoucehrMar = () => {
		swal({
			title: `Xác nhận`,
			text: `Bạn muốn lưu phiếu đi chợ ngày ${stringLastDate(listDay.find((day) => Number(day.id) === Number(daySelect[0])).day)}`,
			icon: "info",
			buttons: ["Đóng", "Lưu"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				saveMarketList();
			}
		});
	};

	const dates = (current) => {
		var week = [];
		// Starting Monday not Sunday
		current.setDate(current.getDate() - current.getDay() + 1);
		current.setHours(0, 0, 0, 0);
		for (var i = 0; i < 7; i++) {
			week.push(new Date(current));
			current.setDate(current.getDate() + 1);
			current.setHours(0, 0, 0, 0);
		}
		return week;
	};

	const setDate = (theDate) => {
		const week = dates(theDate);

		setStartDate(week[0]);
		setTermDate(week[week.length - 1]);

		return week;
	};

	const stringFirstDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2);
	const stringLastDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

	const formatter = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const permission = (data, type = "and") => {
		if (localStorage.getItem("role_rank") === "1") {
			return true;
		}

		const permission = localStorage.getItem("permission") ? localStorage.getItem("permission").split(",") : [];

		if (data.length === 0) {
			return false;
		} else {
			let result = false;

			data.map((item) => {
				if (permission.length > 0) {
					switch (type) {
						case "or":
							if (permission.indexOf(item) > -1) {
								result = true;
							}
							break;

						case "and":
							if (permission.indexOf(item) > -1) {
								result = true;
							} else {
								result = false;
							}
							break;
						default:
							result = false;
					}
				}

				return item;
			});

			return result;
		}
	};
	//#endregion

	// Render
	const RenderDayOption = () => {
		return (
			<>
				<Card.Title>Chọn ngày xuất phiếu đi chợ</Card.Title>

				<div className="d-block d-md-flex justify-content-between align-items-center my-3">
					<div className="d-none d-md-flex justify-content-start">
						<Form.Check
							type="switch"
							onChange={(e) => {
								if (e.target.checked) {
									let tmpDay = listDay.map((day) => {
										return day.id;
									});

									handleCheckAll(tmpDay);
								} else {
									handleUnCheckAll();
								}
							}}
							label="Chọn tất cả"
							className="me-md-4 me-xl-5"
							checked={daySelect.length === 7}
						/>
						{listDay.map((dayItem, i) => {
							let toDayIs = dayItem.id % 7;

							if (toDayIs === 0) {
								toDayIs = 7;
							}

							return (
								<div key={i} className="d-flex me-md-4 me-xl-5">
									<Form.Check
										type="checkbox"
										value={dayItem.id}
										checked={daySelect.some((day) => Number(day) === Number(dayItem.id))}
										onChange={(e) => {
											if (e.target.checked) {
												handleCheck(e.target.value);
											} else {
												handleUnCheck(e.target.value);
											}
										}}
										name="day"
										className="me-2"
									/>
									{dayItem.day_name}
								</div>
							);
						})}
					</div>

					<Row className="d-md-none">
						<Col xs={12} md={4} className="mt-3 mt-md-0">
							<Form.Check
								type="switch"
								onChange={(e) => {
									if (e.target.checked) {
										let tmpDay = listDay.map((day) => {
											return day.id;
										});

										handleCheckAll(tmpDay);
									} else {
										handleUnCheckAll();
									}
								}}
								label="Chọn tất cả"
								checked={daySelect.length === 7}
							/>
						</Col>

						<Col xs={6} md={4}>
							{listDay.slice(0, Math.round(listDay.length / 2)).map((dayItem, i) => {
								let toDayIs = dayItem.id % 7;

								if (toDayIs === 0) {
									toDayIs = 7;
								}

								return (
									<Row key={i}>
										<Col xs={1} md={1}>
											<div className="text-center">
												<Form.Check
													type="checkbox"
													value={dayItem.id}
													checked={daySelect.some((day) => Number(day) === dayItem.id)}
													onChange={(e) => {
														if (e.target.checked) {
															handleCheck(e.target.value);
														} else {
															handleUnCheck(e.target.value);
														}
													}}
													name="day"
												/>
											</div>
										</Col>

										<Col xs={10} md={6}>
											{dayItem.day_name}
										</Col>
									</Row>
								);
							})}
						</Col>

						<Col xs={6} md={4}>
							{listDay.slice(Math.round(listDay.length / 2), listDay.length).map((dayItem, i) => {
								let toDayIs = dayItem.id % 7;

								if (toDayIs === 0) {
									toDayIs = 7;
								}

								return (
									<Row key={i}>
										<Col xs={1} md={1}>
											<div className="text-center">
												<Form.Check
													type="checkbox"
													value={dayItem.id}
													checked={daySelect.some((day) => Number(day) === dayItem.id)}
													onChange={(e) => {
														if (e.target.checked) {
															handleCheck(e.target.value);
														} else {
															handleUnCheck(e.target.value);
														}
													}}
													name="day"
												/>
											</div>
										</Col>
										<Col xs={10} md={6}>
											{dayItem.day_name}
										</Col>
									</Row>
								);
							})}
						</Col>
					</Row>

					<div className="my-3 my-md-0">
						<Select
							options={classifyOptions}
							placeholder={<div>Tất cả</div>}
							value={selectedClassify}
							onChange={(choice) => {
								setSelectedClassify(choice);
								setIsChangeVoucherMar(false);
								getVoucherMark(start, term, daySelect, choice.value);
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	const RenderXportTable = () => {
		return (
			<div className="d-none">
				{voucherMar.map((dayItem) => {
					let marketWillMoney = 0;
					let storeWillMoney = 0;
					let ingredientIndex = 0;

					return (
						<div style={{ overflowX: "scroll" }} key={dayItem.stt_day}>
							<Table id={`market-table-${dayItem.stt_day}`} style={{ minWidth: 1024 }}>
								<thead>
									<tr>
										<th>{schoolInfo.school_name?.toUpperCase()}</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
									</tr>

									<tr className="text-center">
										<th>
											Tổng hợp định lượng đi chợ{" "}
											{stringLastDate(listDay.find((day) => Number(day.id) === Number(dayItem.stt_day)).day).toUpperCase()}
										</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
									</tr>

									<tr>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
										<th>-</th>
									</tr>

									<tr className="text-center">
										<th>STT</th>
										<th>NGUYÊN LIỆU</th>
										<th>NHÀ CUNG CẤP</th>
										<th>ĐƠN VỊ TÍNH</th>
										<th>ĐƠN GIÁ</th>
										<th>SỐ LƯỢNG CẦN MUA</th>
										<th>HÀNG TỒN</th>
										<th>THÀNH TIỀN DỰ KIẾN</th>
										<th>GHI CHÚ</th>
									</tr>
								</thead>
								<tbody>
									{dayItem.ingredent.filter((item) => (Number(item.ingredent_type) === 1).length !== 0) ? (
										<>
											{dayItem.ingredent
												.filter((item) => Number(item.ingredent_type) === 1)
												.map((item) => {
													marketWillMoney += Number(item.nutrients.price) * Number(item.count).toFixed(2);
													const itemPrice = Number(item.nutrients.price) * Number(item.count).toFixed(2);

													ingredientIndex++;

													return (
														<tr key={item.id_ingredent}>
															<td className="text-center">{ingredientIndex}</td>
															<td>
																{item.ingredent_name}
																{/* || {Number(item.ingredent_type)} */}
															</td>
															<td>{item.supplier.supplier_name}</td>
															<td>{item.unit.unit_name}</td>
															<td className="text-end">
																<CurrencyFormat
																	value={Number(item.nutrients.price)}
																	thousandSeparator={true}
																	allowNegative={false}
																	isNumericString
																	disabled={!isChangeVoucherMar}
																	displayType="text"
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat
																	value={formatter.format(item.count)}
																	thousandSeparator={true}
																	allowNegative={false}
																	isNumericString
																	disabled
																	displayType="text"
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat
																	value={Number(item.ingredent_type) === 2 ? formatter.format(item.inventory.amount) : "-"}
																	displayType={"text"}
																	thousandSeparator={true}
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat value={itemPrice.toFixed(0)} displayType={"text"} thousandSeparator={true} />
															</td>
															<td>
																<p className="d-none">{item.note ? item.note : "-"}</p>
															</td>
														</tr>
													);
												})}
											<tr>
												<th>-</th>
												<th>TỔNG CỘNG</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th className="text-end">
													<CurrencyFormat value={marketWillMoney.toFixed(0)} displayType={"text"} thousandSeparator={true} />
												</th>
												<th>-</th>
											</tr>
										</>
									) : (
										""
									)}

									{dayItem.ingredent.filter((item) => Number(item.ingredent_type) === 2).length !== 0 ? (
										<>
											{dayItem.ingredent
												.filter((item) => Number(item.ingredent_type) === 2)
												.map((item) => {
													storeWillMoney += Number(item.nutrients.price) * Number(item.count).toFixed(2);
													const itemPrice = Number(item.nutrients.price) * Number(item.count).toFixed(2);

													ingredientIndex++;

													return (
														<tr key={item.id_ingredent}>
															<td className="text-center">{ingredientIndex}</td>
															<td>{item.ingredent_name} (Kho)</td>
															<td>{item.supplier.supplier_name}</td>
															<td>{item.unit.unit_name}</td>
															<td className="text-end">
																<CurrencyFormat
																	value={Number(item.nutrients.price)}
																	thousandSeparator={true}
																	allowNegative={false}
																	isNumericString
																	displayType="text"
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat
																	value={formatter.format(item.count)}
																	thousandSeparator={true}
																	allowNegative={false}
																	isNumericString
																	displayType="text"
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat
																	value={formatter.format(item.inventory.amount)}
																	displayType={"text"}
																	thousandSeparator={true}
																/>
															</td>
															<td className="text-end">
																<CurrencyFormat value={itemPrice.toFixed(0)} displayType={"text"} thousandSeparator={true} />
															</td>
															<td>{item.note ? item.note : "-"}</td>
														</tr>
													);
												})}
											<tr>
												<th>-</th>
												<th>TỔNG CỘNG</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th>-</th>
												<th className="text-end">
													<CurrencyFormat value={storeWillMoney.toFixed(0)} displayType={"text"} thousandSeparator={true} />
												</th>
												<th>-</th>
											</tr>
										</>
									) : (
										""
									)}
								</tbody>
							</Table>
						</div>
					);
				})}
			</div>
		);
	};

	const loading = () => {
		return (
			<Card>
				<Card.Body className="d-flex justify-content-center align-items-center">
					<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
						<span className="sr-only">Loading...</span>
					</div>
				</Card.Body>
			</Card>
		);
	};

	return (
		<>
			<Container fluid>
				<Breadcrumb>
					{/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                        Tổng quan
                    </Breadcrumb.Item> */}
					<Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
					<Breadcrumb.Item active>Danh sách đi chợ</Breadcrumb.Item>
				</Breadcrumb>
				<Card>
					<Card.Header>
						<div className="d-block d-xl-flex justify-content-between">
							<div className="d-block d-md-flex">
								<Card.Title className="me-3 mt-0 mt-md-2">
									Danh sách đi chợ dự kiến Tháng {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
								</Card.Title>

								<div className="d-flex align-items-center mt-2 mt-md-0">
									<input
										type="week"
										name="week"
										style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
										onChange={(e) => {
											if (e.target.value) {
												const firstDayOfWeek = new Date(e.target.valueAsNumber);
												const week = setDate(firstDayOfWeek);

												setWeekInMonth((0 | (week[0].getDate() / 7)) + 1);

												setListDay(
													initialDay.map((day, index) => {
														return {
															id: day.id,
															day_name: day.day_name,
															day: week[index],
														};
													}),
												);

												getVoucherMark(week[0], week[week.length - 1], daySelect, selectedClassify.value);
											} else {
												const today = new Date();
												const week = setDate(today);

												setWeekInMonth((0 | (week[0].getDate() / 7)) + 1);

												setListDay(
													initialDay.map((day, index) => {
														return {
															id: day.id,
															day_name: day.day_name,
															day: week[index],
														};
													}),
												);

												getVoucherMark(week[0], week[week.length - 1], daySelect, selectedClassify.value);
											}
										}}
									/>
								</div>
							</div>

							<div className="d-flex mt-3 mt-xl-0">
								{daySelect.length !== 0 ? (
									<>
										<Button
											variant="success"
											size="sm"
											onClick={() => {
												handleExportVoucehrMar();
											}}
											disabled={voucherMar.length === 0}
											className="me-3"
										>
											Xuất file <i className="fa-solid fa-file-export"></i>
										</Button>

										{permission(["56"], "or") ? (
											isChangeVoucherMar ? (
												<Button
													variant="success"
													size="sm"
													onClick={() => {
														setIsChangeVoucherMar(false);
														setVoucherMar(tmpVoucherMar);
														setVoucherMarShow(tmpVoucherMar);
													}}
												>
													Hủy thay đổi
												</Button>
											) : (
												<Button
													variant="success"
													size="sm"
													onClick={() => {
														if (voucherMar.length !== 1) {
															toast("Chỉ thay đổi được 1 phiếu đi chợ", { type: "error", autoClose: 2000 });
															return;
														}

														if (voucherMar[0].is_market) {
															toast("Phiếu đi chợ này đã được lưu. Không thể thay đổi", { type: "error", autoClose: 2000 });
															return;
														}

														if (!voucherMar[0].is_calcultor) {
															toast("Bạn chưa lưu số liệu khẩu phần ăn cho phiếu đi chợ này", { type: "error", autoClose: 2000 });
															return;
														}

														setIsChangeVoucherMar(true);
													}}
													disabled={voucherMar.length === 0 || selectedClassify.value === "1,2"}
												>
													Thay đổi phiếu đi chợ
												</Button>
											)
										) : (
											""
										)}
									</>
								) : (
									""
								)}

								{isChangeVoucherMar ? (
									<Button
										variant="success"
										size="sm"
										className="ms-3"
										onClick={() => {
											if (voucherMar.length !== 1) {
												toast("Chỉ lưu được 1 phiếu đi chợ mỗi ngày", { type: "error", autoClose: 2000 });
												return;
											}

											if (voucherMar.some((item) => item.ingredent === undefined || item.ingredent.length === 0)) {
												toast("Phiếu đi chợ đang trống", { type: "error", autoClose: 2000 });
												return;
											}

											handleSaveVoucehrMar();
										}}
										disabled={voucherMar.length === 0}
									>
										Lưu phiếu đi chợ <i className="fas fa-save"></i>
									</Button>
								) : (
									""
								)}
							</div>
						</div>
					</Card.Header>
					<Card.Body>
						{RenderDayOption()}

						<Form.Control type="text" placeholder="Tìm nguyên liệu" className="mb-3" value={textSearch} onChange={(event) => handleSearch(event)} />

						{checkload ? (
							loading()
						) : (
							<>
								<Tabs unmountOnExit={true} transition={false} activeKey={tabSelect} onSelect={(key) => setTabSelect(key)} className="mb-3">
									{voucherMarShow.length !== 0
										? voucherMarShow.map((dayItem) => {
												let marketWillMoney = 0;
												let storeWillMoney = 0;

												let ingredientIndex = 0;

												return (
													<Tab key={dayItem.stt_day} title={dayItem.day_name} eventKey={dayItem.stt_day}>
														<div style={{ overflowX: "scroll" }}>
															<Table style={{ minWidth: 1024 }}>
																<thead>
																	<tr>
																		<th colSpan="9">{schoolInfo.school_name?.toUpperCase()}</th>
																	</tr>

																	<tr className="text-center">
																		<th colSpan="9">
																			Tổng hợp định lượng đi chợ{" "}
																			{stringLastDate(
																				listDay.find((day) => Number(day.id) === Number(dayItem.stt_day)).day,
																			).toUpperCase()}
																		</th>
																	</tr>

																	<tr>
																		<th colSpan="9" className="d-none"></th>
																	</tr>

																	<tr className="text-center">
																		<th>STT</th>
																		<th>NGUYÊN LIỆU</th>
																		<th>NHÀ CUNG CẤP</th>
																		<th>ĐƠN VỊ TÍNH</th>
																		<th>ĐƠN GIÁ</th>
																		<th>SỐ LƯỢNG CẦN MUA</th>
																		<th>HÀNG TỒN</th>
																		<th>THÀNH TIỀN DỰ KIẾN</th>
																		<th>GHI CHÚ</th>
																	</tr>
																</thead>
																<tbody>
																	{dayItem.ingredent.filter((item) => Number(item.ingredent_type) === 1).length !== 0 ? (
																		<>
																			{/* Market */}
																			{dayItem.ingredent
																				.filter((item) => Number(item.ingredent_type) === 1)
																				.map((item) => {
																					marketWillMoney +=
																						Number(item.nutrients.price) * Number(item.count).toFixed(2);
																					const itemPrice =
																						Number(item.nutrients.price) * Number(item.count).toFixed(2);

																					ingredientIndex++;

																					return (
																						<tr key={item.id_ingredent} className="align-middle">
																							<td className="text-center">{ingredientIndex}</td>
																							<td>{item.ingredent_name}</td>
																							<td>
																								<Select
																									options={supplierOption}
																									placeholder={<div>Chọn nhà cung cấp</div>}
																									defaultValue={{
																										value: item.supplier.id_supplier,
																										label: item.supplier.supplier_name,
																									}}
																									// {{
																									//     value: item.supplier.id_supplier !== null
																									//         ? item.supplier.id_supplier
																									//         : (supplierOption.length > 0 ? supplierOption[0].value : ''),
																									//     label: item.supplier.supplier_name !== null
																									//         ? item.supplier.supplier_name
																									//         : (supplierOption.length > 0 ? supplierOption[0].label : ''),
																									// }}
																									onChange={(choice) => {
																										const newVoucherMar = voucherMar.map((dayObj) => {
																											return {
																												...dayObj,
																												ingredent: dayObj.ingredent.map((obj) => {
																													if (
																														Number(obj.id_ingredent) ===
																														Number(item.id_ingredent)
																													) {
																														return {
																															...obj,
																															supplier: {
																																...obj.supplier,
																																supplier_name: choice.label,
																																id_supplier: choice.value,
																															},
																														};
																													}

																													return obj;
																												}),
																											};
																										});

																										setVoucherMar(newVoucherMar);
																										setVoucherMarShow(newVoucherMar);
																									}}
																									isDisabled={!isChangeVoucherMar}
																									menuPortalTarget={document.querySelector("body")}
																								/>
																							</td>
																							<td>{item.unit.unit_name}</td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={Number(item.nutrients.price)}
																									thousandSeparator={true}
																									onValueChange={(values) => {
																										const newVoucherMar = voucherMar.map((dayObj) => {
																											return {
																												...dayObj,
																												ingredent: dayObj.ingredent.map((obj) => {
																													if (
																														Number(obj.id_ingredent) ===
																														Number(item.id_ingredent)
																													) {
																														return {
																															...obj,
																															nutrients: {
																																...obj.nutrients,
																																price: values.value,
																															},
																														};
																													}
																													return obj;
																												}),
																											};
																										});

																										setVoucherMar(newVoucherMar);
																										setVoucherMarShow(newVoucherMar);
																									}}
																									className="form-control text-end"
																									allowNegative={false}
																									isNumericString
																									disabled={!isChangeVoucherMar}
																								/>
																							</td>
																							<td className="text-end">
																								{isChangeVoucherMar ? (
																									<CurrencyFormat
																										defaultValue={formatter.format(item.count)}
																										thousandSeparator={true}
																										onValueChange={(values) => {
																											const newVoucherMar = voucherMar.map((dayObj) => {
																												return {
																													...dayObj,
																													ingredent: dayObj.ingredent.map((obj) => {
																														if (
																															Number(obj.id_ingredent) ===
																															Number(item.id_ingredent)
																														) {
																															return {
																																...obj,
																																count: Number(values.value),
																															};
																														}

																														return obj;
																													}),
																												};
																											});

																											setVoucherMar(newVoucherMar);
																											setVoucherMarShow(newVoucherMar);
																										}}
																										className="form-control text-end"
																										allowNegative={false}
																										isNumericString
																									/>
																								) : (
																									<CurrencyFormat
																										value={formatter.format(item.count)}
																										thousandSeparator={true}
																										className="form-control text-end"
																										allowNegative={false}
																										isNumericString
																										disabled
																									/>
																								)}
																							</td>
																							<td className="text-end"></td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={itemPrice.toFixed(0)}
																									displayType={"text"}
																									thousandSeparator={true}
																								/>
																							</td>
																							<td>
																								<Form.Control
																									type="text"
																									value={item.note ? item.note : ""}
																									onChange={(e) => {
																										const newVoucherMar = voucherMar.map((dayObj) => {
																											return {
																												...dayObj,
																												ingredent: dayObj.ingredent.map((obj) => {
																													if (
																														Number(obj.id_ingredent) ===
																														Number(item.id_ingredent)
																													) {
																														return {
																															...obj,
																															note: e.target.value,
																														};
																													}
																													return obj;
																												}),
																											};
																										});

																										setVoucherMar(newVoucherMar);
																										setVoucherMarShow(newVoucherMar);
																									}}
																									disabled={!isChangeVoucherMar}
																								/>
																							</td>
																						</tr>
																					);
																				})}
																			<tr>
																				<th></th>
																				<th>TỔNG CỘNG</th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th className="text-end">
																					<CurrencyFormat
																						value={marketWillMoney.toFixed(0)}
																						displayType={"text"}
																						thousandSeparator={true}
																					/>
																				</th>
																				<th></th>
																			</tr>
																		</>
																	) : (
																		""
																	)}

																	{dayItem.ingredent.filter((item) => Number(item.ingredent_type) === 2).length !== 0 ? (
																		<>
																			{/* Store */}
																			{dayItem.ingredent
																				.filter((item) => Number(item.ingredent_type) === 2)
																				.map((item) => {
																					storeWillMoney +=
																						Number(item.nutrients.price) * Number(item.count).toFixed(2);
																					const itemPrice =
																						Number(item.nutrients.price) * Number(item.count).toFixed(2);

																					ingredientIndex++;

																					return (
																						<tr key={item.id_ingredent} className="align-middle">
																							<td className="text-center">{ingredientIndex}</td>
																							<td>{item.ingredent_name} (Kho)</td>
																							<td>
																								<Select
																									options={supplierOption}
																									placeholder={<div>Chọn nhà cung cấp</div>}
																									defaultValue={{
																										value: item.supplier.id_supplier,
																										label: item.supplier.supplier_name,
																									}}
																									// {{
																									//     value: item.supplier.id_supplier !== null
																									//         ? item.supplier.id_supplier
																									//         : (supplierOption.length > 0 ? supplierOption[0].value : ''),
																									//     label: item.supplier.supplier_name !== null
																									//         ? item.supplier.supplier_name
																									//         : (supplierOption.length > 0 ? supplierOption[0].label : ''),
																									// }}
																									isDisabled
																									menuPortalTarget={document.querySelector("body")}
																								/>
																							</td>
																							<td>{item.unit.unit_name}</td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={Number(item.nutrients.price)}
																									thousandSeparator={true}
																									className="form-control text-end"
																									allowNegative={false}
																									isNumericString
																									disabled
																								/>
																							</td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={formatter.format(item.count)}
																									thousandSeparator={true}
																									className="form-control text-end"
																									allowNegative={false}
																									isNumericString
																									disabled
																								/>
																							</td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={formatter.format(item.inventory.amount)}
																									displayType={"text"}
																									thousandSeparator={true}
																								/>
																							</td>
																							<td className="text-end">
																								<CurrencyFormat
																									value={itemPrice.toFixed(0)}
																									displayType={"text"}
																									thousandSeparator={true}
																								/>
																							</td>
																							<td>
																								<Form.Control
																									type="text"
																									value={item.note ? item.note : ""}
																									onChange={(e) => {
																										const newVoucherMar = voucherMar.map((dayObj) => {
																											return {
																												...dayObj,
																												ingredent: dayObj.ingredent.map((obj) => {
																													if (
																														Number(obj.id_ingredent) ===
																														Number(item.id_ingredent)
																													) {
																														return {
																															...obj,
																															note: e.target.value,
																														};
																													}
																													return obj;
																												}),
																											};
																										});

																										setVoucherMar(newVoucherMar);
																										setVoucherMarShow(newVoucherMar);
																									}}
																									disabled={!isChangeVoucherMar}
																								/>
																							</td>
																						</tr>
																					);
																				})}
																			<tr>
																				<th></th>
																				<th>TỔNG CỘNG</th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th></th>
																				<th className="text-end">
																					<CurrencyFormat
																						value={storeWillMoney.toFixed(0)}
																						displayType={"text"}
																						thousandSeparator={true}
																					/>
																				</th>
																				<th></th>
																			</tr>
																		</>
																	) : (
																		""
																	)}
																</tbody>
															</Table>
														</div>
													</Tab>
												);
										  })
										: ""}
								</Tabs>

								{voucherMarShow.length !== 0 ? "" : <div className="d-flex justify-content-center">Không có dữ liệu</div>}
							</>
						)}

						{RenderXportTable()}

						<div className="d-flex justify-content-between mt-3">
							<div></div>

							<div className="d-flex">
								{isChangeVoucherMar ? (
									<Button
										variant="success"
										size="sm"
										onClick={() => {
											if (voucherMar.length !== 1) {
												toast("Chỉ lưu được 1 phiếu đi chợ mỗi ngày", { type: "error", autoClose: 2000 });
												return;
											}

											if (voucherMar.some((item) => item.ingredent === undefined || item.ingredent.length === 0)) {
												toast("Phiếu đi chợ đang trống", { type: "error", autoClose: 2000 });
												return;
											}

											handleSaveVoucehrMar();
										}}
										disabled={voucherMar.length === 0}
									>
										Lưu phiếu đi chợ <i className="fas fa-save"></i>
									</Button>
								) : (
									""
								)}
							</div>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

export default MarketList;
