import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Nav,
    Row, Form, InputGroup, Modal, Table
} from "react-bootstrap";
// import CategoryAdd from "./CategoryAdd";
import axios from "axios";
import DataTables from "../datatable/DataTables";
// import CategoryEdit from "./CategoryEdit";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Check from "../other/Check";
import FormatData from "../FormatData";
// import FoodIndex from "../food/FoodIndex";
import EditFoodFormModal from "./EditFoodFormModal";
import AddParentUnit from "../unit/AddParentUnit";
import IngredentAdd from '../ingredent/IngredentAdd'
class BankFood extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorys: [],
            category_child: [],
            category_id: "",
            category: [],
            foods: [],
            id_T: "",
            addCategoryModalShow: false,
            editFoodModalShow: false,
            editCategoryModalShow: false,
            parentUnitModalShow: false, addIngerdentModalShow: false, arrRS: [],
            checkload: false, foodTextSearch: "", navkey: 0, navName: 'Tất cả', showAdd: false, categories: [], units: [], food: [], classGroups: [], type: 0, listcategory: [], Select_item: false, arrayFood: ''
        };
    }

    async componentDidMount() {
        document.title = "Ngân hàng món ăn";
        this.viewFoodLibrary()
        this.dataCategogys();
        this.setState({
            categorys: [
                { id: 0, category_name: "Tất cả", },
                { id: 1, category_name: "Chiên", },
                { id: 2, category_name: "Xào", },
                { id: 3, category_name: "Mặn", },
                { id: 4, category_name: "Canh", },
                { id: 5, category_name: "Tráng miệng", },
                { id: 6, category_name: "Nước", },
                { id: 7, category_name: "Khô", },
            ],

        })
        const selectAllButton = document.querySelector('.dataTables_select');
    if (selectAllButton) {
      selectAllButton.style.display = 'none';
    }
        // this.getChildCategoryData(this.state.categorys[0]);
    }
    handleParentModalClose = () => {
        this.setState({ parentUnitModalShow: false });
    };
    addIngerdentModalClose = () => {
        this.setState({ addIngerdentModalShow: false });
        // this.getIngredentsData();
    };
    handleEditFoodModalClose = () => {
        this.setState({
            editFoodFormModalShow: false,
        });
    };
    handleFoodModalClose = () => {
        this.setState({
            showAdd: false,
        });
    };
    RenderIngredientSelect() {
        return (
            <div style={{ overflowX: "scroll" }}>
                <Table size="lg"
                    bordered
                    hover
                // style={
                //     this.state.categories.some(
                //         (category) =>
                //             Number(category.id) ===
                //             Number(this.state.categorySelected) &&
                //             Number(category.is_main) === 0
                //     )
                //         ? {}
                //         : { minWidth: 150 * this.state.classGroups.filter((item) => Number(item.is_main) === 1).length }
                // }
                >
                    <thead>
                        <tr>
                            <th rowSpan={2} className="align-middle text-center">
                                STT
                            </th>
                            <th
                                rowSpan={2}
                                className="align-middle text-center"
                                style={{ width: "220px" }}
                            >
                                Nguyên liệu <i className="text-danger">*</i>
                            </th>
                            <th rowSpan={2} className="align-middle text-center">
                                ĐVT
                            </th>
                            <th
                                colSpan={1
                                    // this.state.food.hasIngrednet?.group.some(
                                    //     (category) =>
                                    //         Number(category.id) ===
                                    //         Number(this.state.categorySelected) &&
                                    //         Number(category.is_main) === 0
                                    // )
                                    //     ? 1
                                    //     :
                                    //  this.state.food.hasIngrednet?.group.length
                                    // .filter(
                                    //     (item) => Number(item.is_main) === 1
                                    // ).length
                                }
                                className="text-center"
                            >
                                Định lượng<i className="text-danger">*</i>
                            </th>
                            {this.state.type ? <th rowSpan={2} className="align-middle text-center">Phương thức</th> : null}
                        </tr>


                        <tr>

                            {this.state.food?.hasIngrednet ?
                                this.state.food?.hasIngrednet[0]?.group?.map((item) => {
                                    return (
                                        <th
                                            key={`group_${item.id}`}
                                            className="text-center"
                                        >
                                            {item.name}
                                        </th>
                                    );
                                })

                                : <th className="text-center">Mẫu giáo </th>
                            }

                        </tr>
                    </thead>
                    <tbody>{
                        this.state.food?.hasIngrednet?.map((item, index) => {
                            return (
                                <tr>
                                    <td className="text-center">
                                        {index + 1}
                                    </td>
                                    <td>
                                        {
                                            item.ingredent_name
                                        }
                                    </td>
                                    <td className="text-center">{item.ingredent_unit}</td>
                                    <td className="text-center">
                                        {item.group[0].amount}
                                    </td>
                                    {this.state.type ? <td className="text-center">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            type="button"
                                            title="Xóa nguyên liệu"
                                        // onClick={() => {
                                        //     this.removeSelect(
                                        //         ingredient.id_ingredent
                                        //     );
                                        // }}
                                        >
                                            <i className="fa-solid fa-trash"></i>
                                        </Button></td> : null}
                                </tr>
                            )
                        })
                        // {/* this.RenderIngredientFoodDetail() */}
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
    viewFoodLibrary = () => {
        this.setState({ checkload: true })
        axios.get(`/viewFoodLibrary`).then((res) => {
            this.setState({ checkload: false })
            this.setState({ foods: res.data.data })
        }).catch((err) => console.log(err))
    }
    dataCategogys() {

        if (this.state.categorys) {
            let categorys = this.state.categorys;
            if (categorys.length > 0) {
                return categorys.map((item, i) => {
                    return (
                        <Nav.Item
                            key={"category" + item.id}
                        >
                            <Nav.Link
                                eventKey={
                                    "categoryLink" + i
                                    // item.id
                                    // this.state.navkey
                                }
                                // eventKey={"categoryLink" + i}
                                onClick={() => {
                                    return (
                                        // this.setState({ checkload: true }),
                                        this.setState({ category_id: item.id }),
                                        this.setState({ category: item }),
                                        this.setState({ navName: item.category_name }),
                                        this.setState({ navkey: i }),
                                        this.renderTableFoods()
                                    );
                                }}
                            >
                                {item.category_name}
                            </Nav.Link>
                        </Nav.Item >
                    );
                });
            }
        }
    }
    loading = () => {
        return <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </Card.Body>
        </Card>

    }
    AddFood = (food) => {

        var arrPost = [];
        arrPost.push(food);
        this.AdddFoodArr(arrPost);
    }
    AddMore = (arrFood) => {
        this.setState({ arrRS: arrFood })
     
        if (arrFood.length > 5) {
            toast("Không được chọn quá 5 món", {
                type: "error",
                autoClose: 1000,
            });
        }
        else {
            var arrPost = []
            arrFood?.map(item => arrPost.push(item.data))
            this.setState({ arrayFood: arrPost })

        }

    }
    AdddFoodArr = (arr) => {

        if (arr.length > 5) {
            toast("Không được chọn quá 5 món", {
                type: "error",
                autoClose: 1000,
            });
        }
        else {
            axios.post(`/addFoodByLibrary?arr_food=${JSON.stringify(arr)}`).then((res) => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, {
                        type: "success",
                        autoClose: 1000,
                    });


                    const dataFood = [...this.state.foods]
                    var id_food_index = 0
                    arr?.map(item => {
                        id_food_index = this.state.foods.findIndex(e => e.id == item.id)
                        dataFood[id_food_index].is_exist = 1
                    }


                    )
                    this.setState({ foods: dataFood })


                } else {
                    toast(res.data.data.mess, {
                        type: "error",
                        autoClose: 1000,
                    });
                }
            });


        }

    }
    renderTableFoods() {
        const columns = [
            {
                name: "Tên món ăn",
                selector: (row) => row.title,
                sortable: true,
            },
            {
                name: "Danh mục",
                selector: (row) => row.categoryName,
                sortable: true,
            },
            {
                name: "Đơn vị tính",
                selector: (row) => row.unitName,
                sortable: true,
            },
            {
                name: "Trạng thái",
                selector: (row) => row.status,
                sortable: true,
                center:true
            },

            {
                name: "Phương thức",
                // center: true,
                cell: (row) => (
                    <>
                        {new Check().permission(["28", '35', '67', '40']) ? (
                            <Button
                                size="sm"
                                variant="primary me-2"
                                type="button"
                                // style={{color:'white'}}
                                onClick={() => {
                                    this.setState({
                                        showAdd: true, type: 0, food: row,
                                        foodId: row.id,
                                        editFoodFormModalShow: true,
                                    });
                                }}
                                title="Chi tiết món ăn"
                            >
                                <i className="fa-solid fa-circle-info" />

                            </Button>
                        ) : (
                            ""
                        )}
                        <Button
                            size="sm"
                            variant="success me-2"
                            type="button"
                            // style={{color:'white'}}
                            onClick={() => {
                                this.setState({
                                    food: row,
                                    type: 1,
                                    // showAdd: true,
                                    foodId: row.id,
                                    // editFoodFormModalShow: true,

                                });
                         
                                this.AddFood(row.data)
                            }}
                            hidden={row.is_exist}
                            title="Thêm món ăn vào danh sách món ăn"
                        >
                            <i className="fa-solid fa-plus" />

                        </Button>


                    </>
                ),
            },
        ];

        const data = [];

        const selectedRows = (items) => {
            this.setState({ selectedRows: items, alertShow: true });

        };
        const selectRowDisabled = (row) => {
            return row.is_exist == true;
        };

        if (this.state.foods.length > 0) {
            this.state.foods.sort(function (a, b) {
                return a.is_exist - b.is_exist;
              }
              ).map((item, i) => {
                let var_ = {
                    id: item.id,
                    title: item.food_name,
                    categoryName: item.category,
                    unitName: item.food_unit,
                    hasIngrednet: item.has_ingredent,
                    is_exist: item.is_exist,
                    data: item,
                    status:item.is_exist ? "Đã có" : ""
                  
                }
                if (this.state.navName == 'Tất cả' || item.category == this.state.navName) {
                    data.push(var_);
                }
                return item;
            });
        }

        const filteredItems = data.filter((item) => {
            if (

                (new FormatData().toSlug(item.title)).includes(new FormatData().toSlug(this.state.foodTextSearch.trim()))

            ) {

                return item;

            }
            return false
        })
        const handleSelectedRowsChange = (state) => {

            const selectedCount = state.selectedCount;
            const maxSelectableRows = 5;

            // Giới hạn số lượng dòng được chọn tối đa là 5
            // if (selectedCount <= maxSelectableRows) {

            this.setState({ selectedRows: state.selectedRows, alertShow: true });

            this.AddMore(state.selectedRows)
            // }
            // else {

            //     toast("Không được chọn quá 5 món", {
            //         type: "error",
            //         autoClose: 1000,
            //     });

            // }
        };
     
        return (
            <DataTables
                data={filteredItems}
                columns={columns}
                selectedRows={handleSelectedRowsChange}
                // selectableRows={this.state.arrayFood}
                selectableRows={this.state.Select_item}
                onSelectedRowsChange={handleSelectedRowsChange}
                // onSelectedRowsChange={(item) => this.AddMore(item.selectedRows)}
                selectableRowDisabled={(rowData) => rowData.is_exist || rowData.id <1}
                selectableRowsNoSelectAll={true}
               
            />
        );
    }

    renderCategorys() {
        const addModalClose = () => {
            this.setState({ addCategoryModalShow: false });
            this.getCategorysData1();
        };
        const editModalClose = () => {
            this.setState({ editCategoryModalShow: false });
        };
        return (
            <>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <i className="fas fa-list me-1"></i> Ngân hàng món ăn
                        </Card.Title>
                    </Card.Header>
                    {/* <Col className="mb-2 mb-md-0 mt-2" md={6} style={{ zIndex: 2 }}> */}

                    {/* </Col> */}
                    <Col className="mt-2 ms-2">
                        <InputGroup style={{ height: '30px' }}>
                            <Form.Control
                                style={{ marginRight: '10px', height: '30px', marginTop: "1px" }}
                                type="text"
                                placeholder="Tìm món ăn"
                                value={this.state.foodTextSearch}
                                onChange={(event) => {
                                    this.setState({ foodTextSearch: event.target.value })
                                    // this.renderTableFoods()
                                }}
                            />
                            {/* <InputGroup.Text>
                            <i className="fa-solid fa-search me-1" />
                        </InputGroup.Text> */}
                        </InputGroup>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <Nav variant="tabs" defaultActiveKey={"categoryLink0"} className="flex-contaniner" style={{
                                marginTop: "10px", marginLeft: "10px",
                                display: 'flex', flexWrap: 'nowrap',
                                //  maxWidth: '950px',
                                overflow: 'auto'
                            }}>
                                {this.dataCategogys()}
                                {/* {this.getChildCategoryData()} */}
                                <Nav.Item className="position-absolute end-0 " style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Nav.Item className="flex-contaniner"> */}

                                    {this.state.Select_item ?
                                        <div>
                                            <Button
                                                size="sm"
                                                onClick={() => this.setState({ Select_item: false })}
                                                variant="secondary"
                                                className="me-2"
                                            >
                                                Hủy
                                            </Button>
                                            <Button
                                                size="sm"
                                                className="me-2"
                                                onClick={() => {

                                                    this.AdddFoodArr(this.state.arrayFood)
                                                }}
                                                variant="success"
                                                disabled={this.state.arrayFood == '' || this.state.arrRS.length > 5}

                                            >
                                                Thêm món
                                            </Button>
                                            <span style={{position:'relative',fontStyle:'italic', margin:'8px 25px 0 0', color:'red', zIndex:'2'}}>
                                        Tối đa 5 món
                                    </span>
                                        </div> : <Button className="me-2" size='sm' variant="warning" 
                                            onClick={() => this.setState({ Select_item: true })} disabled={this.state.checkload}
                                            hidden
                                        >
                                            Chọn nhiều
                                        </Button>
                                    }
                               

                                </Nav.Item>
                               
                            </Nav>
                        </Col>

                    </Row>
                    {this.state.checkload ? this.loading() : this.renderTableFoods()}
                    <Modal
                        show={this.state.showAdd}
                        onHide={this.handleFoodModalClose}
                        size="xl"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <i className="fa-solid fa-bowl-rice me-2"></i>
                                {this.state.type ? 'Thêm món ăn' : 'Chi tiết'}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row>
                                <Col md={4}>
                                    {/* Food Name */}
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            type="text"
                                            value={this.state.food.title}
                                            onChange={(e) => {
                                                this.setState({ bntAdd: false })
                                                const food = this.state.foodDetail;
                                                food.food_name = e.target.value;
                                                this.setState({
                                                    foodDetail: food,
                                                });
                                            }}
                                            name="food_name"
                                            id="food_name"
                                            placeholder="Tên món ăn"
                                            required
                                        />
                                        <Form.Label>
                                            Tên món ăn
                                            <i className="text-danger">*</i>
                                        </Form.Label>
                                    </Form.Floating>

                                    {/* Select Category */}
                                    {this.state.type ? null : <Form.Floating className="mb-3">
                                        <Form.Control
                                            type="text"
                                            value={this.state.food.categoryName}

                                            name="food_name"
                                            id="food_name"
                                            placeholder="Tên món ăn"
                                            required
                                        />
                                        <Form.Label>
                                            Danh mục món
                                            <i className="text-danger">*</i>
                                        </Form.Label>
                                    </Form.Floating>}



                                    {this.state.type ? null : <Form.Floating className="mb-3">
                                        <Form.Control
                                            type="text"
                                            value={this.state.food.unitName}

                                            name="food_name"
                                            id="food_name"
                                            placeholder="Tên món ăn"
                                            required
                                        />
                                        <Form.Label>
                                            Đơn vị tính
                                            <i className="text-danger">*</i>
                                        </Form.Label>
                                    </Form.Floating>}

                                </Col>

                                <Col md={8}>
                                    {this.RenderIngredientSelect()}

                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                size="sm"
                                variant="secondary"
                                id="btnCloseAddFood"
                                onClick={this.handleFoodModalClose}
                            >
                                <i className="fa-solid fa-times"></i> Đóng
                            </Button>

                            <Button
                                size="sm"
                                variant="success"
                                type="submit"
                                // onClick={() => this.addNewFood()}
                                // disabled={this.state.bntAdd}
                                hidden={!this.state.type}
                            >
                                <i className="fa-solid fa-check"></i> Lưu
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {
                        this.state.parentUnitModalShow
                            ?
                            <AddParentUnit show={this.state.parentUnitModalShow} onHide={this.handleParentModalClose} onSubmit={this.handleParentModalClose} />
                            : ""
                    }

                </Card>
            </>
        );
    }
    //end Category


    render() {
        return (
            <>
                <Container fluid className="px-3">
                    <Breadcrumb className="mb-2 mt-2">
                        <Breadcrumb.Item active>Món ăn</Breadcrumb.Item>
                        <Breadcrumb.Item active>Ngân hàng món ăn</Breadcrumb.Item>
                    </Breadcrumb>

                    {this.renderCategorys()}
                </Container>
            </>
        );
    }
}

export default BankFood;
