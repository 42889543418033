import { Component } from "react";
import {
    Card,
    Button,
    Container,
    Breadcrumb,
    Col,
    Row,
    InputGroup,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import FormatData from "../FormatData";
import DataTables from "../datatable/DataTables";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";
import EditFoodFormModal from "./EditFoodFormModal";
import AddFoodFormModal from "./AddFoodFormModal";
import Check from "../other/Check";

class FoodIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorys: [],
            categoryFilter: { value: 0, label: "Tất cả" },

            rootFoods: [],
            foods: [],
            foodId: 0,

            foodTextSearch: this.props.foodTextSearch,

            addFoodFormModalShow: false,
            editFoodFormModalShow: false,
            checkload: true,
            categoryFilter_id: this.props.id_category, checksort: 0
        };
    }

    async componentDidMount() {
        document.title = "Quản lý món ăn";

        this.getFoodsData();
        this.getCategorysData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id_category != this.state.categoryFilter_id) {
            this.setState({
                categoryFilter_id: prevProps.id_category,
                foodTextSearch: ""

            })
            // if (this.props.showModalAdd !== prevProps.showModalAdd) {
            // this.getFoodsData()
            // }

            this.getFoodsData()

            // this.handleFoodbySelectOrSearch(
            //     this.props.rootFoods,
            //     this.state.categoryFilter_id,
            //     this.state.foodTextSearch
            // );
        }
        if (prevProps.foodTextSearch != this.props.foodTextSearch) {
            this.setState({
                // categoryFilter_id: prevProps.id_category,
                foodTextSearch: prevProps.foodTextSearch

            })
            if (this.props.foodTextSearch == '') {
                this.getFoodsData()
            }
            this.getFoodsData()
        }

        // if (prevProps.showModalAdd != this.state.addFoodFormModalShow) {
        //     this.setState({
        //         addFoodFormModalShow: prevProps.showModalAdd
        //     })
        // }
    }
    // Fetch Data
    getCategorysData = () => {
        axios.post(`/viewCategory`).then((res) => {
            if (res.data.status === true) {
                this.setState({ categorys: res.data.data });
                this.handleFoodbySelectOrSearch(res.data.data, this.state.categoryFilter_id, "");

            }
        });
    };
    loading = () => {
        return <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </Card.Body>
        </Card>

    }
    getFoodsData = () => {
        axios.post(`/viewFood`).then((res) => {
            if (res.data.status === true) {
                this.setState({
                    rootFoods: res.data.data.sort(function (a, b) {
                        return b.updated_at - a.updated_at;
                    }
                    ),
                });
                this.setState({ checkload: false })

                this.handleFoodbySelectOrSearch(
                    res.data.data,
                    this.props.id_category,
                    // this.state.categoryFilter_id,
                    // this.state.foodTextSearch
                    this.props.foodTextSearch.trim()
                );
            }
        });
    };

    deleteFood = (id) => {
        swal({
            title: "Bạn muốn xóa món ăn?",
            icon: "error",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(`/deleteFood?id_food=${id}`).then((res) => {
                    if (res.data.status === true) {
                        toast(res.data.data.mess, {
                            type: "success",
                            autoClose: 1000,
                        });

                        const NewFoods = this.state.foods.filter((food) => {
                            return food.id !== id;
                        });
                        this.setState({ foods: NewFoods });
                    } else {
                        toast(res.data.data.mess, {
                            type: "error",
                            autoClose: 1000,
                        });
                    }
                });
            }
        });
    };

    // Logic
    handleFoodbySelectOrSearch = (rootFood, value, text) => {
        let root_ = rootFood;
        // let cate_ = value;
        if (value !== -1) {
            if (text) {
                root_ = rootFood.filter(
                    (food) =>
                        Number(food.id_category) == Number(this.props.id_category) &&
                        this.toSlug(food.food_name).includes(this.toSlug(text))
                );
            } else {
                root_ = rootFood.filter(
                    (food) => Number(food.id_category) == Number(value)
                );
            }
        } else {
            if (text) {
                root_ = rootFood.filter((food) =>
                    this.toSlug(food.food_name).includes(this.toSlug(text))
                );
            }
        }
        this.setState({
            foods: root_,
            // categoryFilter: cate_,
        });
    };

    selectFoodByCategory = (value) => {
        this.setState({
            categoryFilter: value,
            foodTextSearch: "",
        });
        this.handleFoodbySelectOrSearch(this.state.rootFoods, this.state.categoryFilter_id, "");
    };

    handleSearchFood = (event) => {
        this.setState({
            foodTextSearch: event.target.value,
        });
        this.handleFoodbySelectOrSearch(
            this.state.rootFoods,
            // this.state.categoryFilter,
            this.props.id_category,
            // event.target.value.trim()
            this.props.foodTextSearch.trim()
        );
    };

    toSlug(str) {
        str = str.toLowerCase(); // Chuyển hết sang chữ thường

        // xóa dấu
        str = str
            .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
            .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

        str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

        str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

        return str;
    }

    handleEditFoodModalClose = () => {
        this.setState({
            editFoodFormModalShow: false,
        });
    };

    handleAddFoodModalClose = () => {
        this.setState({
            addFoodFormModalShow: false,
        });
    };

    handleAddSubmit = () => {
        this.setState({
            addFoodFormModalShow: false,
        });
        this.getFoodsData();
    };

    handlEditSubmit = () => {
        this.setState({
            editFoodFormModalShow: false,
        });
        this.getFoodsData();
    };

    // Render
    renderSearchFilter() {
        let categories = [];

        if (this.state.categorys.length > 0) {
            categories = this.state.categorys.map((item) => ({
                value: item.id,
                label: item.category_name,
            }));
        }

        categories.unshift({ value: 0, label: "Tất cả" });

        return (
            <Row >
                <Col className="mb-2 mb-md-0 mt-2" md={6} style={{ zIndex: 2 }}></Col>
                <Col className="mb-2 mb-md-0" md={6} style={{ zIndex: 2 }}>
                    {new Check().permission(["29", '35', '67', '40']) ? (
                        <Button
                            variant="success"
                            style={{ marginLeft: "0", marginTop: '-30px', float: "right" }}
                            size="sm"
                            title="Thêm món ăn"
                            onClick={() =>
                                this.setState({
                                    addFoodFormModalShow: true,
                                    foodId: 0,
                                })
                            }
                        >
                            <i className="fa-solid fa-plus"></i> Thêm món
                        </Button>
                    ) : (
                        ""
                    )}
                    {/* <Select
                        options={categories}
                        placeholder={<div>Chọn mục</div>}
                        value={this.state.categoryFilter}
                        onChange={(choice) => {
                            this.selectFoodByCategory(choice);
                        }}
                    /> */}
                </Col>
            </Row>
        );
    }

    checkSort = (check) => {
        if (check) {

            var data = this.state.foods.sort(function (a, b) { return b.updated_at - a.updated_at })
            this.setState({ foods: data })
        }
        else {
            var data = this.state.foods.sort(function (a, b) { return a.updated_at - b.updated_at })
            this.setState({ foods: data })
        }
    }
    renderTableFoods() {

        const columns = [
            {
                name: "Tên món ăn",
                selector: (row) => row.title,
                sortable: true,
                style: {
                    div: {
                        // background:'red',

                        //   overflow: 'inherit !important ',
                        //   width: '1000px',
                    }
                }
            },
            {
                name: "Danh mục",
                selector: (row) => row.categoryName,
                sortable: true,
            },
            {
                name: "Đơn vị tính",
                selector: (row) => row.unitName,
                sortable: true,
            },
            {
                name: <> <span className="ms-1"
                    onClick={() => {
                        this.setState({ checksort: !this.state.checksort })
                        this.checkSort(this.state.checksort)

                    }}>Cập nhật  <i className="fa-solid fa-sort"></i>
                </span>
                </>,

                selector: (row) => new FormatData().date(row.updatedAt),

                right: false,
                cell: (row) => <>{new FormatData().date(row.updatedAt)}</>,

            },
            {
                name: "Phương thức",
                center: true,
                cell: (row) => (
                    <>
                        {new Check().permission(["28", '35', '67', '40']) ? (
                            <Button
                                size="sm"
                                variant="warning me-2"
                                type="button"
                                // style={{color:'white'}}
                                onClick={() => {
                                    this.setState({
                                        foodId: row.id,
                                        editFoodFormModalShow: true,
                                    });
                                }}
                                title="Chi tiết món ăn"
                            >
                                <i className="fa-solid fa-pencil"></i>
                            </Button>
                        ) : (
                            ""
                        )}

                        {new Check().permission(["31"]) ? (
                            <Button
                                size="sm"
                                variant="danger"
                                type="button"
                                onClick={() => this.deleteFood(row.id)}
                                title="Xóa món ăn"
                            >
                                <i className="fas fa-trash"></i>
                            </Button>
                        ) : (
                            ""
                        )}
                    </>
                ),
            },
        ];

        const data = [];

        const selectedRows = (items) => {
            this.setState({ selectedRows: items, alertShow: true });
        };

        if (this.state.foods.length > 0) {
            this.state.foods.map((item, i) => {
                data.push({
                    id: item.id,
                    title:
                        <div style={{ width: "100%" }} className="text-wrap">{item.food_name}</div>,

                    // item.food_name,
                    categoryName: item.category_name,
                    unitName: item.unit_name,
                    updatedAt: item.updated_at,
                });

                return item;
            });
        }

        return (
            <DataTables
                data={data}
                columns={columns}
                selectedRows={selectedRows}
            />
        );
    }

    render() {
        return (
            <Container fluid className="px-3">
                {/* <Breadcrumb className="mb-2 mt-2">
                    <Breadcrumb.Item active>Món ăn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Danh sách món</Breadcrumb.Item>
                </Breadcrumb> */}
                {/* <Card> */}
                {/* <Card.Header>
                        {new Check().permission(["29",'35','67','40']) ? (
                            <Button
                                variant="success"
                                style={{ marginLeft: "5px", float: "right" }}
                                size="sm"
                                onClick={() =>
                                    this.setState({
                                        addFoodFormModalShow: true,
                                        foodId: 0,
                                    })
                                }
                            >
                                <i className="fa-solid fa-plus"></i> Thêm
                            </Button>
                        ) : (
                            ""
                        )}

                        <Card.Title>
                            <i className="fas fa-list me-1"></i> Danh sách món
                            ăn
                        </Card.Title>
                    </Card.Header> */}

                {/* <Card.Body> */}
                {this.renderSearchFilter()}
                {this.state.checkload ? this.loading() : this.renderTableFoods()}
                {/* {this.renderTableFoods()} */}
                {/* </Card.Body> */}
                {/* </Card> */}

                {this.state.editFoodFormModalShow ? (
                    <EditFoodFormModal
                        show={this.state.editFoodFormModalShow}
                        foodId={this.state.foodId}
                        onHide={this.handleEditFoodModalClose}
                        onSubmit={this.handlEditSubmit}
                        onRefresh={this.getFoodsData}
                    />
                ) : null}

                {this.state.addFoodFormModalShow ? (
                    <AddFoodFormModal
                        show={this.state.addFoodFormModalShow}
                        id_category={this.state.categoryFilter_id}
                        onHide={this.handleAddFoodModalClose}
                        onSubmit={this.handleAddSubmit}
                        foodDetail={null}
                        ingredientFoodDetail={null}
                    />
                ) : null}
            </Container>
        );
    }
}

export default FoodIndex;
