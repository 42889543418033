import { useEffect, useState } from "react";
import NotificationSwitch from "./NotificationSwitch";
import { Alert } from "react-bootstrap";

const NotificationIndex = () => {
  const [switchStates, setSwitchStates] = useState({});
  useEffect(() => {
    const initSwitchStates = {
      "extra-enrollment":
        JSON.parse(localStorage.getItem("subscribed-extra-enrollment")) ||
        false,
      "day-off":
        JSON.parse(localStorage.getItem("subscribed-day-off")) || false,
      "medication-reminder":
        JSON.parse(localStorage.getItem("subscribed-medication-reminder")) ||
        false,
    };

    setSwitchStates(initSwitchStates);
  }, []);

  const handleSwitchChange = (topic, isSubscribed) => {
    setSwitchStates((preStates) => ({
      ...preStates,
      [topic]: isSubscribed,
    }));

    localStorage.setItem(`subscribed-${topic}`, isSubscribed);
  };

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Cài đặt thông báo</h5>
        </div>

        <div className="card-body">
          <Alert variant="primary" className="d-flex align-items-center py-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>

            <ol>
              <li>
                <strong>Cấu hình cá nhân hóa:</strong> Dữ liệu cấu hình thông
                báo được cá nhân hóa theo người dùng và theo từng trình duyệt.
              </li>
              <li>
                <strong>Lưu trữ thông báo:</strong> Việc thiết lập thông báo sẽ
                dựa trên dữ liệu được lưu trữ trong cookies của trình duyệt.
              </li>
              <li>
                <strong>Cập nhật dữ liệu:</strong> Mỗi lần người dùng thay đổi
                cấu hình thông báo, dữ liệu này sẽ được cập nhật và lưu trữ
                trong cookies của trình duyệt tương ứng.
              </li>
            </ol>
          </Alert>

          <ul className="list-unstyled mb-0">
            <li className="d-flex">
              <div className="flex-grow-1">
                <label
                  htmlFor="directMessage"
                  className="form-check-label fs-14"
                >
                  Nhóm học tập
                </label>
                <p className="text-muted">
                  Thông báo đến từ việc đăng ký vào nhóm học tập của phụ huynh
                  cho các học sinh
                </p>
              </div>
              <div className="flex-shrink-0">
                <NotificationSwitch
                  topic="extra-enrollment"
                  isSubscribed={switchStates["extra-enrollment"]}
                  onSwitchChange={handleSwitchChange}
                />
              </div>
            </li>

            <li className="d-flex mt-2">
              <div className="flex-grow-1">
                <label
                  className="form-check-label fs-14"
                  htmlFor="desktopNotification"
                >
                  Cắt suất - Nghỉ học
                </label>
                <p className="text-muted">
                  Thông báo đến từ việc phụ huynh đăng ký cắt suất - nghỉ học
                  cho học sinh
                </p>
              </div>
              <div className="flex-shrink-0">
                <NotificationSwitch
                  topic="day-off"
                  isSubscribed={switchStates["day-off"]}
                  onSwitchChange={handleSwitchChange}
                />
              </div>
            </li>

            <li className="d-flex mt-2">
              <div className="flex-grow-1">
                <label
                  className="form-check-label fs-14"
                  htmlFor="emailNotification"
                >
                  Nhắc nhở uống thuốc
                </label>
                <p className="text-muted">
                  Thông báo đến từ việc phụ huynh lên thời gian uống thuốc dành
                  cho trẻ
                </p>
              </div>
              <div className="flex-shrink-0">
                <NotificationSwitch
                  topic="medication-reminder"
                  isSubscribed={switchStates["medication-reminder"]}
                  onSwitchChange={handleSwitchChange}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationIndex;
