import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import BillModal from "./BillModal";
import Check from "../other/Check";
import BillEditModal from "./BillEditModal";
import { priceFormatterNoDecimal } from "../utils/helpers";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import PrintButton from "./PrintButton";

class BillIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],

      modalRoleShow: false,

      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
      selectedRows: [],
      checkload: true,
      editModal: false,
      filterText: "",
      studentsSearch: [],
      startDate: new Date(),
      endDate: new Date(),
      listClass: [],
      choiceClass: { value: 0, label: "Tất cả lớp" },
      schoolInfo: {},
    };
  }

  componentDidMount() {
    document.title = "Phiếu thu";
    this.getDetailschool();
    this.setState({
      startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      endDate: this.lastDayy(
        new Date().getFullYear(),
        Number(new Date().getMonth()) + 1
      ),
    });
    this.getClassRoom(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)) / 1000,
      this.lastDayy(
        new Date().getFullYear(),
        Number(new Date().getMonth()) + 1
      ) / 1000
    );
    this.getRoles(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      this.lastDayy(new Date().getFullYear(), Number(new Date().getMonth()) + 1)
    );
  }
  getDetailschool = () => {
    axios.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };
  tuitionReminder = () => {
    swal({
      title: "Bạn muốn nhắc nhỡ đóng học phí?",
      text: "Thông báo này sẽ gửi đến tất cả phiếu thu chưa thanh toán.",
      icon: "warning",
      buttons: ["Đóng", "Nhắc nhỡ"],
      warningMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(`/bill-notify`)
          .then((res) => {
            toast(res.data.messages || "Tạo thành công", { type: "success" });
          })
          .catch((err) => {
            toast("Hệ thống xảy ra lỗi", { type: "error" });
          });
      }
    });
  };
  exportListBillCreated = (ListBill) => {
    // onHide(true);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      this.book1(
        ListBill.sort(function (a, b) {
          return b.student?.classes[0]?.id - a.student?.classes[0]?.id;
        })
      ),
      "Phiếu thu"
    );

    XLSX.writeFile(wb, "Danh sách phiếu thu.xlsx");
  };

  book1 = (ListBill) => {
    const title = ["Danh sách phiếu thu"];
    const header = ["STT", "Tên học sinh", "Lớp", "Tổng tiền", "Trạng thái"];
    const aoaAccounts = [
      title,
      header,
      ...ListBill.map((account, index) => {
        return [
          index + 1,
          account.student?.student_name,
          account.student?.classes[0]?.class_name,
          account.trans_amount,
          account.pay_status == 1 ? "Đã thanh toán" : "Chưa thanh toán",
        ];
      }),
      [
        "",
        "Tổng",
        "",
        ListBill.reduce((sum, account) => sum + account.trans_amount, 0),
        "",
      ],
    ];
    var ws1 = XLSX.utils.aoa_to_sheet(aoaAccounts);
    const colAlpha = ["A", "B", "C", "D", "E"];
    ws1[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }]; // merge A1:E1
    ws1["!merges"] = merge;

    colAlpha.forEach((alpha) => {
      ws1[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
    });

    for (let i = 0; i < ListBill.length; i++) {
      colAlpha.forEach((alpha) => {
        ws1[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal:
              alpha == "A" ? "center" : alpha == "D" ? "right" : "left",
            wrapText: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          ...(alpha == "D" && { numFmt: "#,##0" }),
        };
      });
    }
    colAlpha.forEach((alpha, index) => {
      ws1[`${alpha}${3 + ListBill.length}`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: index == 1 || index == 3, // Bold "Tổng cộng" and total amount
        },
        alignment: {
          vertical: "center",
          horizontal: alpha == "A" ? "center" : alpha == "D" ? "right" : "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        ...(alpha === "D" && { numFmt: "#,##0" }),
      };
    });

    ws1["!cols"] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 10 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
    ];

    return ws1;
  };
  getClassRoom = (start, end) => {
    axios
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        let arr = [];
        arr.push({ value: 0, label: "Tất cả lớp" });
        res.data.data?.classrooms?.map((item) => {
          arr.push({ value: item.id, label: item.class_name });
        });
        this.setState({ listClass: arr });
      });
  };
  getRoles = (start, end) => {
    axios
      .get(
        `/bill-shows?end_date=${parseInt(end / 1000)}&start_date=${parseInt(
          start / 1000
        )}`
      )
      .then((res) => {
        this.setState({ checkload: false });
        this.setState({ roles: res.data.data?.docs }, () =>
          this.filterStudent(0, "")
        );
      });
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (idClass, text) => {
    this.setState({ filterText: text });
    const filteredStudents = this.state.roles?.filter((student) => {
      const matchesText =
        this.toSlug(student?.student?.student_name)?.includes(
          this.toSlug(text?.trim())
        ) ||
        this.toSlug(student?.billcode)?.includes(this.toSlug(text?.trim()));

      const matchesClass =
        idClass == 0 || student?.student?.classes[0]?.id == idClass;

      return matchesText && matchesClass;
    });

    this.setState({
      studentsSearch: filteredStudents,
      filterText: text,
    });
  };
  getRole = (id) => {
    if (id !== "") {
      axios.get(`/bill-show?id=${id}`).then((res) => {
        const data = res.data.data.data;
        this.setState({
          role: res.data.data.data,
        });

        this.setState({ editModal: true });
      });
    }
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa phiếu thu?",

      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/bill-delete?id=${item.id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success", autoClose: 1000 });
            this.getRoles(this.state.startDate, this.state.endDate);
          })
          .catch((err) => {
            toast(err.response.data.data.messages || "Lỗi", {
              type: "error",
              autoClose: 1000,
            });
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };

  dataTable() {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student?.student_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Lớp",
        selector: (row) => row.student?.classes[0]?.class_name,
        wrap: true,
        sortable: true,
      },
      {
        name: "Tổng tiền",
        selector: (row) => Number(row.trans_amount),
        cell: (row) => (
          <div>{priceFormatterNoDecimal.format(row.trans_amount || 0)}</div>
        ),
        sortable: true,
        right: true,
      },

      {
        name: "Trạng thái",
        selector: (row) => row.pay_status,
        cell: (row) =>
          row.pay_status == 1 ? (
            <div className="text-success">Đã thanh toán</div>
          ) : row.pay_status == 0 ? (
            <div className="text-danger">Chưa thanh toán</div>
          ) : (
            ""
          ),
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const totalRow = {
      trans_amount: this.state.studentsSearch.reduce(
        (acc, cur) => acc + Number(cur.trans_amount),
        0
      ),
      id: "total-student",

      class_name: "Tổng",
      name: "Tổng",
      pay_status: "2",
    };

    const conditionalRowStyles = [
      {
        when: (row) => row.name === "Tổng",
        style: {
          borderTop: "1px solid #000000",
          position: "absolute",
          bottom: "0",
        },
      },
    ];

    const customStyles = {
      table: {
        style: {
          paddingBottom: "38px",
        },
      },
    };
    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items });
    };

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <div className="d-flex">
              <PrintButton
                schoolInfo={this.state.schoolInfo}
                idBill={item.id}
                item={item}
              />

              {new Check().permission(["129", "131"], "or") ? (
                <Button
                  size="sm"
                  variant="warning me-1 m-1"
                  type="button"
                  // disabled={item.pay_status}
                  onClick={() => this.getRole(item.id)}
                  title="Chi tiết phiếu thu"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["132"]) ? (
                <Button
                  size="sm"
                  variant="danger me-1 m-1"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  disabled={item.pay_status}
                  title="Xóa phiếu thu"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </div>
          ),
        });

        return item;
      });
    }
    const temporaryData = [...data, totalRow];

    return (
      <DataTables
        data={temporaryData}
        columns={columns}
        selectedRows={selectedRows}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
      this.getRoles(this.state.startDate, this.state.endDate);
    };

    return (
      <>
        <Card.Header className="px-2 py-0">
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title className="mt-1">
              <i className="fas fa-list me-1"></i> Danh sách phiếu thu tháng{" "}
              {this.state.startDate?.getMonth() + 1}/{" "}
              {this.state.startDate?.getFullYear()}{" "}
              <input
                type="month"
                name="month"
                style={{
                  border: "none",
                  width: 39,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                }}
                className="me-2"
                onChange={(e) => {
                  if (e.target.value) {
                    const [year, month] = e.target.value.split("-");
                    this.setState({
                      startDate: new Date(year, month - 1),
                      endDate: this.lastDayy(
                        new Date(year, month - 1).getFullYear(),
                        Number(new Date(year, month - 1).getMonth()) + 1
                      ),
                    });
                    this.getClassRoom(
                      new Date(year, month - 1) / 1000,
                      this.lastDayy(
                        new Date(year, month - 1).getFullYear(),
                        Number(new Date(year, month - 1).getMonth()) + 1
                      ) / 1000
                    );
                    this.getRoles(
                      new Date(year, month - 1),
                      this.lastDayy(
                        new Date(year, month - 1).getFullYear(),
                        Number(new Date(year, month - 1).getMonth()) + 1
                      )
                    );
                  }
                }}
              />
            </Card.Title>
            <div>
              {new Check().permission(["63"]) ? (
                <Button
                  type="button"
                  variant="success"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={() => this.setState({ modalRoleShow: true })}
                >
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              ) : null}
              <Button
                type="button"
                variant="success me-2"
                size="sm"
                style={{ float: "right" }}
                onClick={() =>
                  this.exportListBillCreated(this.state.studentsSearch)
                }
                disabled={this.state.studentsSearch?.length < 1}
              >
                <i className="fa-solid fa-file-excel"></i> Xuất DSPT
              </Button>
              <Button
                variant="warning me-2"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.tuitionReminder()}
                title="Thông báo này sẽ gửi đên tất cả phiếu thu chưa thanh toán"
              >
                <i className="fa-solid fa-bell"></i> Nhắc nhỡ
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={2} style={{ zIndex: 3 }} className="mb-2">
              <Select
                options={this.state.listClass}
                value={this.state.choiceClass}
                onChange={(choice) => {
                  this.setState({ choiceClass: choice });
                  this.filterStudent(choice?.value, this.state.filterText);
                }}
              ></Select>
            </Col>
            <Col>
              <Form.Control
                className="mb-1"
                style={{ height: 39 }}
                type="text"
                placeholder="Nhập tên học sinh hoặc mã phiếu thu"
                value={this.state.filterText}
                onChange={(e) => {
                  this.filterStudent(
                    this.state.choiceClass?.value,
                    e.target.value
                  );
                }}
              />
            </Col>
          </Row>
          <div className=" mb-2">
            <Link to="/admin-v2/student2">
              <i className="text-danger me-1">**</i>
              <span className=" text-muted">
                Lưu ý: Nếu đối tác thanh toán là HDBank vui lòng nhập đầy đủ
                thông tin về mã định danh dân cư
              </span>
            </Link>
          </div>

          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <BillModal
              show={this.state.modalRoleShow}
              databill={this.state.roles}
              dataClass={this.state.listClass}
              onHide={modalClose}
              modal={{
                title: "Thêm phiếu thu",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
        {this.state.editModal ? (
          <>
            <BillEditModal
              show={this.state.editModal}
              onHide={modalClose}
              modal={{
                title: "Chi tiết phiếu thu",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Học phí</Breadcrumb.Item>
            <Breadcrumb.Item active>Phiếu thu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BillIndex;
