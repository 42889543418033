import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";

import { Breadcrumb, Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import MarketListTable from "./MarketListTable";

import { dateFormatter } from "../../utils/helpers";
import { exportMartketList } from "./exportFuntions";
import Check from "../../other/Check";
import { IS_ACTIVE } from "../../../../constants";

const toSlug = (str) => {
	str = str.toLowerCase(); // Chuyển hết sang chữ thường

	// xóa dấu
	str = str
		.normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
		.replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

	str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

	str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

	return str;
};

const MarketList = () => {
	const [school, setSchool] = useState({});

	const [listVoucherMarket, setListVoucherMarket] = useState([]);
	const [voucherMarket, setVoucherMarket] = useState({
		menu_date: new Date().getTime() / 1000,
		ingredents: [],
	});
	const [isChangeVoucherMarket, setIsChangeVoucherMarket] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isSendingForm, setIsSendingForm] = useState(false);
	const [keyword, setKeyWord] = useState("");

	const [suppliers, setSuplliers] = useState([]);

	const [listDays, setListDays] = useState([]);
	const [selectedDay, setSelectedDay] = useState({});

	useEffect(() => {
		document.title = "Danh sách đi chợ";

		let initialWeek = datesOfWeek(new Date());

		let todayIs = new Date().getDay();
		if (todayIs === 0) {
			todayIs = 7;
		}

		getMarketList(initialWeek[0], initialWeek[6], todayIs);
		getDetailschool();
		getListSuppliers();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//#region  API
	const getMarketList = async (startDate, endDate, dayId) => {
		setIsLoading(true);
		await axios
			.get(`voucher-show-week`, {
				params: {
					start_at: startDate.getTime() / 1000,
					term_at: endDate.getTime() / 1000,
					id_day: "1,2,3,4,5,6,7",
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setListDays(
						res.data.days.map((dayItem) => {
							return {
								id_day: dayItem.id_day,
								day_name: dayItem.day_name,
								date: new Date(dayItem.menu_date * 1000),
							};
						}),
					);

					setSelectedDay(res.data.days.find((dayItem) => dayItem.id_day === dayId)?.id_day || res.data.days[0].id_day);

					setListVoucherMarket(res.data.days);

					setVoucherMarket(
						res.data.days.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === dayId)
							? res.data.days.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === dayId)
							: res.data.days[0],
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getDetailschool = async () => {
		await axios
			.get(`/detailSchool`)
			.then((res) => {
				if (res.data.status === true) {
					setSchool(res.data.data[0]);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getListSuppliers = async () => {
		await axios
			.get("/supplier-show")
			.then((res) => {
				if (res.data.errCode === 0) {
					setSuplliers(
						res.data.suppliers.map((supplierItem) => {
							return {
								id: supplierItem.id,
								supplier_name: supplierItem.supplier_name,
							};
						}),
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveMarketList = async () => {
		setIsSendingForm(true);

		var bodyFormData = new FormData();

		bodyFormData.append("menu_date", voucherMarket.menu_date);
		bodyFormData.append("ingredents", JSON.stringify(voucherMarket.ingredents));

		await axios({
			method: "post",
			url: "voucher-save",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendingForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					let initialWeek = datesOfWeek(new Date(voucherMarket.menu_date * 1000));

					setIsChangeVoucherMarket(false);
					getMarketList(initialWeek[0], initialWeek[6], selectedDay);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteVoucherMarket = (voucherMarket) => {
		swal({
			title: "Bạn muốn xóa phiếu đi chợ",
			text: `"Ngày ${dateFormatter(new Date(voucherMarket.menu_date * 1000))}"`,
			icon: "error",
			buttons: ["Đóng", "Xóa"],
			dangerMode: true,
		}).then(async (ok) => {
			if (ok) {
				await axios
					.delete(`/voucher-destroy`, {
						params: {
							id: voucherMarket.id_voucher,
						},
					})
					.then((res) => {
						if (res.data.errCode === 0) {
							toast(res.data.message, {
								type: "success",
								autoClose: 1000,
							});

							let initialWeek = datesOfWeek(new Date(voucherMarket.menu_date * 1000));

							setIsChangeVoucherMarket(false);
							getMarketList(initialWeek[0], initialWeek[6], selectedDay);
						} else {
							toast(res.data.message, {
								type: "error",
								autoClose: 1000,
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		});
	};
	// #endregion

	//#region Logic
	const exportExcel = () => {
		const wb = XLSX.utils.book_new();

		const ws = exportMartketList(voucherMarket.ingredents);
		XLSX.utils.book_append_sheet(wb, ws, "Danh sách đi chợ");

		XLSX.writeFile(wb, `Danh sách đi chợ (${dateFormatter(new Date(voucherMarket.menu_date * 1000))}).xlsx`);
	};

	const handleXportMarketList = () => {
		swal({
			title: `Bạn muốn xuất phiếu đi chợ`,
			text: `Ngày ${dateFormatter(new Date(voucherMarket.menu_date * 1000))}`,
			icon: "info",
			buttons: ["Đóng", "Xuất"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				exportExcel();
			}
		});
	};

	const handleSaveMarketList = () => {
		swal({
			title: `Bạn muốn lưu phiếu đi chợ`,
			text: `Ngày ${dateFormatter(new Date(voucherMarket.menu_date * 1000))}`,
			icon: "info",
			buttons: ["Đóng", "Lưu"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				saveMarketList();
			}
		});
	};

	const handleCancelMarketChange = () => {
		swal({
			title: `Bạn muốn hủy thay đổi phiếu đi chợ`,
			text: `Lưu ý: hành động không thể khôi phục`,
			icon: "info",
			buttons: ["Đóng", "Hủy"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				setIsChangeVoucherMarket(false);
				setVoucherMarket(
					listVoucherMarket.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === selectedDay)
						? listVoucherMarket.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === selectedDay)
						: listVoucherMarket[0],
				);
			}
		});
	};

	const handleSearchIngredient = (keyword) => {
		setKeyWord(keyword);
		setVoucherMarket({
			...listVoucherMarket.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === selectedDay),
			ingredents: listVoucherMarket
				.find((listVoucherMarketItem) => listVoucherMarketItem.id_day === selectedDay)
				?.ingredents?.filter((ingredientItem) => toSlug(ingredientItem.ingredent_name.trim()).includes(toSlug(keyword.trim()))),
		});
	};

	const datesOfWeek = (current) => {
		var week = [];
		// Starting Monday not Sunday
		current.setDate(current.getDate() - current.getDay() + 1);
		current.setHours(0, 0, 0, 0);
		for (var i = 0; i < 7; i++) {
			week.push(new Date(current));
			current.setDate(current.getDate() + 1);
			current.setHours(0, 0, 0, 0);
		}
		return week;
	};
	// #endregion

	// Render
	const RenderOption = () => {
		return (
			<section>
				<Card.Title>Chọn ngày xuất phiếu đi chợ</Card.Title>
				<Row >
					<Col md={8} className="d-flex align-items-center mb-3" >
						<Row>
							{listDays.map((dayItem) => {
								return (<Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
									<Form.Check
										type="radio"
										value={dayItem.id_day}
										checked={selectedDay === dayItem.id_day}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedDay(Number(e.target.value));
												setKeyWord("");
												setVoucherMarket(
													listVoucherMarket.find((voucherMarketItem) => voucherMarketItem.id_day === Number(e.target.value)),
												);
											}
										}}
										name="day"
										className="me-2"
										disabled={isSendingForm}
										label={dayItem.day_name}
									/>
								</Col>
								);
							})}
						</Row>
					</Col>

					<Col md={4} className="mb-3">
						<Form.Control type="text" placeholder="Tìm nguyên liệu" value={keyword} onChange={(e) => handleSearchIngredient(e.target.value)} />
					</Col>
				</Row>
			</section>
		);
	};

	return (
		<Container fluid>
			<Breadcrumb>
				<Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
				<Breadcrumb.Item active>Danh sách đi chợ</Breadcrumb.Item>
			</Breadcrumb>
			<Card>
				<Card.Header>
					<div className="d-block d-md-flex justify-content-between">
						<div className="d-block d-md-flex align-items-center">
							<Card.Title className="me-2"> Danh sách đi chợ dự kiến {dateFormatter(new Date(voucherMarket.menu_date * 1000))}</Card.Title>

							{/* Select Week */}
							<input
								type="week"
								name="week"
								style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
								className="me-2 mb-3 mb-md-0"
								onChange={(e) => {
									const today = e.target.value ? new Date(e.target.valueAsNumber) : new Date();
									const week = datesOfWeek(today);

									setKeyWord("");
									getMarketList(week[0], week[6], selectedDay);
								}}
								disabled={isSendingForm}
							/>
						</div>

						<div className="d-flex">
							<Button size="sm" variant="success" onClick={() => handleXportMarketList()} className="me-2" disabled={isLoading || isSendingForm}>
								<i className="fa-solid fa-file-export" /> Xuất file
							</Button>
							{voucherMarket.is_voucher_saved === IS_ACTIVE.TRUE && new Check().permission([118]) ?
								<Button
									size="sm"
									variant="danger"
									onClick={() => deleteVoucherMarket(voucherMarket)}
									className="me-2"
									disabled={isLoading || isSendingForm}
								>
									<i className="fa-solid fa-times" /> Hủy phiếu đi chợ
								</Button>

								: <></>}

							{voucherMarket.is_voucher_saved === IS_ACTIVE.FALSE && new Check().permission([117]) ? <>
								<Button
									variant={isChangeVoucherMarket ? "danger" : "success"}
									size="sm"
									onClick={() => {
										if (isChangeVoucherMarket) {
											handleCancelMarketChange();
										} else {
											setIsChangeVoucherMarket(true);
										}
									}}
									disabled={isLoading || isSendingForm}
								>
									{isChangeVoucherMarket
										? <><i className="fa-solid fa-times" /> Hủy thay đổi</>
										: <><i className="fa-solid fa-exchange" /> Thay đổi phiếu đi chợ</>
									}
								</Button>

								{isChangeVoucherMarket ? (
									<Button
										variant="success"
										size="sm"
										onClick={() => handleSaveMarketList(true)}
										className="ms-2"
										disabled={isLoading || isSendingForm}
									>
										<i className="fa-solid fa-check" /> Lưu phiếu đi chợ
									</Button>
								) : (
									""
								)}
							</>
								: <></>}
						</div>
					</div>
				</Card.Header>

				<Card.Body>
					{isLoading ? (
						<div className="d-flex justify-content-center my-5">
							<Spinner animation="border" role="status" variant="primary" style={{ width: "3rem", height: "3rem" }}>
								<span className="visually-hidden">Đang tải...</span>
							</Spinner>
						</div>
					) : (
						<Row>
							{RenderOption()}

							<MarketListTable
								voucherMarket={voucherMarket}
								setVoucherMarket={setVoucherMarket}
								school={school}
								suppliers={suppliers}
								isChangeVoucherMarket={isChangeVoucherMarket}
							/>
						</Row>
					)}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default MarketList;
