import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import YearSchoolFormModal from "./YearSchoolFormModal";
import Check from "../other/Check";
import HolidayModal from "./HolidayModal";
class YearSchool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: '',
        role_name: '',
        is_rank: '',
        permission: [],
      },
      selectedRows: [],
      checkload: true, years: [], showModalHoliday: false, id_add: 0
    };
  }

  componentDidMount() {
    document.title = "Năm học";
    this.getRoles();
  }

  getRoles = () => {
    let arr = []
    axios.get(`/viewSchoolYearAll`).then((res) => {
      if (res.data.status === true) {
        this.setState({ checkload: false })
        this.setState({ years: res.data.data });

      }
    });
  };
  getDayOffbyYear = (id) => {
    axios.get(`/viewHolidaysBySchoolYear?id_school_year=${id}`).then((res) => {
      if (res.data.status === true) {
        // this.setState({ checkload: false })

        // this.setState({ years: res.data.data });
       
        return res.data.data
      }
      else return []
    });
  }
  getDate = (data) => {
    let date = new Date(data * 1000);
    let result =
      // `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    return result;
  }
  getRole = (id) => {

    if (id !== '') {
      axios.get(`/viewSchoolYear?id=${id}`).then((res) => {
        if (res.data.status == true) {
          const data = res.data.data[0];
          this.setState({
            role: {
              id: data.id,
              role_name: data.year_name,
              start_date: data.start_date,
              end_date: data.end_date
              // is_rank: data.is_rank,
              // permission: data.permission !== null ? data.permission.split(',') : [],
            }
          });

          this.setState({ modalRoleShow: true })
        }
      });
    }
  }

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa năm học?",
      text: `"${item.year_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(`/deleteSchoolYear?id=${item.id}`)
          .then((res) => {
            if (res.data.status === true) {
              toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }
  EditHoliday = (item) => {

    this.setState({ showModalHoliday: true })
    this.setState({
      role: item,
id:item.id
    })
  }
  deleteHoliday = (item) => {
    swal({
      title: "Bạn muốn xóa ngày nghỉ?",
      text: `"${item.holiday_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(`/deleteHoliday?id=${item.id}`)
          .then((res) => {
            if (res.data.status === true) {
              toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  }
  dataTable() {
    const ExpandedComponent = ({ listday }) => {

      const columns = [
        {
          name: 'Tên ngày nghỉ',
          selector: row => row.holiday_name,
          sortable: true,
        },
        {
          name: 'Ngày bắt đầu',
          selector: row =>this.getDate(row.start_date),
          sortable: true,
        },
        {
          name: 'Ngày kết thúc',
          selector: row => this.getDate(row.end_date),
          sortable: true,
        },
        {
          name: 'Phương thức',
          center: true,
          cell: row => (<>
            <Button size="sm" variant="warning me-2" type="button" onClick={() => this.EditHoliday(row)} title="Chi tiết ngày nghỉ" >
              <i className="fa-solid fa-pencil"></i>
            </Button>
            <Button size="sm" variant="danger" type="button" onClick={() => this.deleteHoliday(row)} title='Xóa ngày nghỉ'>
              <i className='fas fa-trash'></i>
            </Button>
          </>),
        },
      ];



      return (
        (data.length > 0) ?
          <DataTables className="ms-5" data={listday} columns={columns} />
          :
          <i className="text-muted ms-5">Không có ngày nghỉ !</i>
      );

    };

    const columns = [
      {
        name: "Tên năm học",
        selector: (row) => row.year_name,
        sortable: true,
      },
      {
        name: "Ngày bắt đầu ",
        selector: (row) => row.start,
        sortable: true,
      },
      {
        name: "Ngày kết thúc",
        selector: (row) => row.end,
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.years.length > 0) {
      this.state.years.map((item, i) => {

        data.push({
          id: item.id,
          start: this.getDate(item.start_date),
          end: this.getDate(item.end_date),
          year_name: item.year_name,
          listday: item.holidays  ,
          setting: (
            <>
              <Button size='sm' variant="success me-2" type="button" onClick={() =>

                this.setState({ showModalHoliday: true, id_add: item.id })} title="Thêm ngày nghỉ" >
                <i className="fa-solid fa-plus"></i>
              </Button>
              {/* {new Check().permission(['62']) ? */}
              <Button size='sm' variant="warning me-2" type="button" onClick={() => this.getRole(item.id)} title="Chi tiết năm học" >
                <i className="fa-solid fa-pencil"></i>
              </Button>

              {/* : null} */}
              {/* {new Check().permission(['65']) ? */}
              <Button size='sm' variant="danger" type="button" onClick={() => this.handleDelete(item)} title="Xóa năm học" >
                <i className="fas fa-trash"></i>
              </Button>
              {/* :null} */}
            </>
          ),
        });


        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows}
        expandableRows
        // expandableRowsComponent={ExpandedComponent}
        expandableRowsComponent={({ data }) => <ExpandedComponent listday={data.listday} />}

      />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: '',
        role_name: '',
        is_rank: '',
        permission: [],
      }
    });
  }




  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, showModalHoliday: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, showModalHoliday: false });
      this.refreshRole();
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(['63']) ?
            <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ modalRoleShow: true })} >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
            : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách năm học
          </Card.Title>
        </Card.Header>

        <Card.Body>

          {/* {this.state.checkload ? this.loading() : */}
          {this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? <>
          <YearSchoolFormModal
            show={this.state.modalRoleShow}
            onHide={modalClose}
            modal={this.state.role.id === '' ? { title: 'Thêm năm học', button: <><i className="fa-solid fa-check" /> Lưu</> } : { title: 'Chi tiết năm học', button: <><i className="fa-solid fa-pencil" /> Cập nhật</> }}
            datarole={this.state.role}
            submit={modalSubmit}
          />
        </> : null
        }
        {this.state.showModalHoliday ?
          <>
            <HolidayModal
              show={this.state.showModalHoliday}
              onHide={modalClose}
              id_add={this.state.id_add}
              modal={this.state.role.id === '' ? { title: 'Thêm ngày nghỉ', button: <><i className="fa-solid fa-check" /> Lưu</> } : { title: 'Chi tiết ngày nghỉ', button: <><i className="fa-solid fa-pencil" /> Cập nhật</> }}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </> : null}

      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Năm học</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default YearSchool;
