import React, { Component } from "react";
import { Modal, Button, Row, Col, Form, Table } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import AddParentUnit from "../unit/AddParentUnit";
import IngredentAdd from "../ingredent/IngredentAdd";
import AddFoodFormModal from "./AddFoodFormModal";
import Check from "../other/Check";

class EditFoodFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodDetail: {
                food_name: "",
                id_unit: 0,
                id_category: 0,
            },
            ingredientFoodDetail: [],

            units: [],
            categories: [],
            categorySelected: 0,

            ingredentOptions: [],

            classGroups: [],
            groups: [],

            parentUnitModalShow: false,
            addIngerdentModalShow: false,
            copyFoodModalShow: false,
        };
    }

    async componentDidMount() {
        this.getFoodDetail();
        this.getCategorysData();
        this.getUnitsData();
        this.getIngredentsData();
    }

    //#region Fetch API
    getFoodDetail = () => {
        axios.post(`/viewFood?id_food=${this.props.foodId}`).then((res) => {
            if (res.data.status === true) {
                this.setState({
                    foodDetail: res.data.data[0],
                    categorySelected: res.data.data[0].id_category,
                });

                this.getClassGroupsData(res.data.data[0]);
            } else {
                toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
        });
    };

    getIngredentsFood = (classGroups, foodData) => {
        axios
            .post(`/viewHasIngredentByFood?id_food=${this.props.foodId}`)
            .then((res) => {
                if (res.data.status === true) {
                    const ingredients = Number(foodData.is_main) === 1 ? res.data.data.map(item => {
                        const missingId = [];

                        classGroups.filter((item) => Number(item.is_main) === 1).map(groupItem => {
                            if (item.group.some(grItem => Number(grItem.id) === Number(groupItem.id))) {
                                return groupItem;
                            }

                            missingId.push(groupItem.id);

                            return groupItem;
                        })

                        missingId.map(id => {
                            item.group.push({
                                id: id.toString(),
                                amount: "0"
                            })

                            return id;
                        })

                        if (item.group.length > classGroups.filter((item) => Number(item.is_main) === 1).length) {
                            const muchId = [];

                            item.group.map(groupItem => {

                                if (classGroups.filter((item) => Number(item.is_main) === 1).some(item => Number(item.id) === Number(groupItem.id))) {
                                    return groupItem;
                                }

                                muchId.push(groupItem.id)

                                return groupItem;
                            })

                            muchId.map(id => {
                                item.group = item.group.filter(grItem => Number(grItem.id) !== Number(id))
                                return id;
                            })
                        }

                        return item;
                    }) : res.data.data;

                    let groups = [];

                    if (Number(foodData.is_main) === 1) {
                        groups = classGroups
                            .filter((item) => Number(item.is_main) === 1)
                            .map((item) => {
                                return {
                                    id: item.id,
                                    amount: 0,
                                };
                            });
                    } else {
                        groups = classGroups
                            .filter((item) => Number(item.is_main) === 0)
                            .map((item) => {
                                return {
                                    id: item.id,
                                    amount: 0,
                                };
                            });
                    }

                    ingredients.push({
                        id_ingredent: 0,
                        ingredent_name: "",
                        parent_unit: 0,
                        unit_id: 0,
                        unit_name: "",
                        group: groups,
                    });

                    this.setState({
                        ingredientFoodDetail: ingredients,
                        groups: groups,
                    });
                }
            });
    };

    getUnitsData = () => {
        axios.post(`/viewUnit`).then((res) => {
            if (res.data.status === true) {
                const parentUnit = res.data.data.filter(item => Number(item.parent_unit) === 0)

                const arrayUniqueByKey = [...new Map(parentUnit.map(item =>
                    [item["id"], item])).values()];

                this.setState({ units: arrayUniqueByKey });
            }
        });
    };

    getCategorysData = () => {
        axios.post(`/viewCategory`).then((res) => {
            if (res.data.status === true) {
                this.setState({
                    categories: res.data.data,
                });
            }
        });
    };

    getClassGroupsData = (foodData) => {
        axios.get(`/viewClassGroup`).then((res) => {
            if (res.data.status === true) {
                this.setState({
                    classGroups: res.data.data,
                });

                this.getIngredentsFood(res.data.data, foodData);
            }
        });
    };

    getIngredentsData = () => {
        axios.post(`/viewIngredent`).then((res) => {
            if (res.data.status === true) {
                const ingredients = res.data.data.filter(item => item.ingredent_type !== 3).map((ingredient) => {
                    return {
                        value: ingredient.id,
                        label: ingredient.ingredent_name,
                        id_unit: ingredient.id_unit,
                        parent_unit: ingredient.parent_unit,
                        unit_name: ingredient.unit_name,
                    };
                });

                this.setState({ ingredentOptions: ingredients });
            }
        });
    };

    editFoodDetail = () => {
        let id_food = this.state.foodDetail.id;
        let food_name = this.state.foodDetail.food_name;
        let id_category = this.state.foodDetail.id_category;
        let id_unit = this.state.foodDetail.id_unit;

        const foodHasIngredent = this.state.ingredientFoodDetail
            .filter((item) => item.id_ingredent !== 0)
            .map((item) => {
                delete item.ingredent_name;
                delete item.parent_unit;
                delete item.unit_id;
                delete item.unit_name;

                return item;
            });

        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const ingredientFood = foodHasIngredent.map(item => {
            return {
                id_ingredent: item.id_ingredent,
                group: item.group.map(groupItem => {
                    return {
                        id: Number(groupItem.id),
                        amount: formatter.format(groupItem.amount),
                    }
                })
            }
        })

        if (food_name === "" || Number(id_category) === 0 || Number(id_unit) === 0) {
            toast("Vui lòng nhập đủ thông tin.", {
                type: "error",
                autoClose: 1000,
            });
            return;
        }

        if (ingredientFood.length === 0) {
            toast("Vui lòng nhập nguyên liệu.", {
                type: "error",
                autoClose: 1000,
            });
            return false;
        }

        let has_ingredent_string = JSON.stringify(ingredientFood);

        swal({
            title: "Bạn muốn cập nhật món ăn?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios
                    .post(
                        `/updateFood?id_food=${id_food}&food_name=${food_name}&id_category=${id_category}&id_unit=${id_unit}&has_ingredent=${has_ingredent_string}`
                    )
                    .then((res) => {
                        if (res.data.status === true) {
                            toast(res.data.data.mess, {
                                type: "success",
                                autoClose: 1000,
                            });
                            const { onSubmit } = { ...this.props };
                            onSubmit();
                        } else {
                            toast(res.data.data.mess, {
                                type: "error",
                                autoClose: 1000,
                            });
                        }
                    });
            }
        });
    };
    //#endregion

    //#region Logic
    removeSelect = (ingredentId) => {
        swal({
            title: "Bạn muốn xóa nguyên liệu?",
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then((ok) => {
            if (ok) {
                this.setState({
                    ingredientFoodDetail:
                        this.state.ingredientFoodDetail.filter(
                            (item) => item.id_ingredent !== ingredentId
                        ),
                });

                toast("Đã xóa nguyên liệu.", {
                    type: "success",
                    autoClose: 1000,
                });
            }
        });
    };

    addUnit = () => {
        this.setState({ parentUnitModalShow: true });
    };
    handleParentModalClose = () => {
        this.setState({ parentUnitModalShow: false });
        this.getUnitsData();
    };

    addIngerdent = () => { this.setState({ addIngerdentModalShow: true }); }
    addIngerdentModalClose = () => {
        this.setState({ addIngerdentModalShow: false });
        this.getIngredentsData();
    }

    handleCopyFoodModalClose = () => {
        this.setState({
            copyFoodModalShow: false,
        });
    };

    handleCopySubmit = () => {
        const { onHide, onRefresh } = { ...this.props };
        this.setState({
            copyFoodModalShow: false,
        });

        onRefresh();
        onHide();
    };
    //#endregion

    // Render
    RenderIngredientSelect() {
        return (
            <div style={{ overflowX: "scroll" }}>
                <Table size="lg"
                    bordered
                    hover
                    style={
                        this.state.categories.some(
                            (category) =>
                                Number(category.id) ===
                                Number(this.state.categorySelected) &&
                                Number(category.is_main) === 0
                        )
                            ? {}
                            : { minWidth: 150 * this.state.classGroups.filter((item) => Number(item.is_main) === 1).length }
                    }
                >
                    <thead>
                        <tr>
                            <th rowSpan={2} className="align-middle text-center">
                                STT
                            </th>
                            <th
                                rowSpan={2}
                                className="align-middle text-center"
                                style={{ width: "220px" }}
                            >
                                Nguyên liệu <i className="text-danger">*</i>
                            </th>
                            <th rowSpan={2} className="align-middle text-center">
                                ĐVT
                            </th>
                            <th
                                colSpan={
                                    this.state.categories.some(
                                        (category) =>
                                            Number(category.id) ===
                                            Number(this.state.categorySelected) &&
                                            Number(category.is_main) === 0
                                    )
                                        ? 1
                                        : this.state.classGroups.filter(
                                            (item) => Number(item.is_main) === 1
                                        ).length
                                }
                                className="text-center"
                            >
                                Định lượng<i className="text-danger">*</i>
                            </th>
                            <th rowSpan={2} className="align-middle text-center">
                                Phương thức
                            </th>
                        </tr>

                        <tr>
                            {this.state.categories.some(
                                (category) =>
                                    Number(category.id) === Number(this.state.categorySelected) &&
                                    Number(category.is_main) === 0
                            )
                                ? this.state.classGroups
                                    .filter((item) => Number(item.is_main) === 0)
                                    .map((item) => {
                                        return (
                                            <th
                                                key={`group_${item.id}`}
                                                className="text-center"
                                            >
                                                {item.group_name}
                                            </th>
                                        );
                                    })
                                : this.state.classGroups
                                    .filter((item) => Number(item.is_main) === 1)
                                    .map((item) => {
                                        return (
                                            <th
                                                key={`group_${item.id}`}
                                                className="text-center"
                                            >
                                                {item.group_name}
                                            </th>
                                        );
                                    })}
                        </tr>
                    </thead>
                    <tbody>{this.RenderIngredientFoodDetail()}</tbody>
                </Table>
            </div>
        );
    }

    RenderIngredientFoodDetail() {
        return (
            <>
                {this.state.ingredientFoodDetail?.map((ingredient, index) => {
                    return (
                        <tr key={index}>
                            <td className="align-middle text-center">
                                {index + 1}
                            </td>
                            <td>
                                <Select
                                    options={this.state.ingredentOptions}
                                    className="text-start"
                                    placeholder={<>Chọn nguyên liệu</>}
                                    value={
                                        this.state.ingredentOptions.filter(
                                            (item) =>
                                                Number(item.value) ===
                                                Number(ingredient.id_ingredent)
                                        )[0] || "Chọn nguyên liệu"
                                    }
                                    onChange={(choice) => {
                                        if (
                                            this.state.ingredientFoodDetail.some(
                                                (item) =>
                                                    Number(item.id_ingredent) ===
                                                    Number(choice.value)
                                            )
                                        ) {
                                            toast(
                                                "Bạn đã chọn nguyên liệu này rồi",
                                                {
                                                    type: "error",
                                                    autoClose: 1000,
                                                }
                                            );
                                            return;
                                        }

                                        let newIngredent =
                                            this.state.ingredientFoodDetail.map(
                                                (item) => {
                                                    if (
                                                        ingredient.id_ingredent ===
                                                        Number(item.id_ingredent)
                                                    ) {
                                                        return {
                                                            id_ingredent:
                                                                choice.value,
                                                            unit_name:
                                                                choice.unit_name,
                                                            unit_id:
                                                                choice.id_unit,
                                                            parent_unit:
                                                                choice.parent_unit,
                                                            group: item.group,
                                                            ingredent_name:
                                                                choice.label,
                                                        };
                                                    }
                                                    return item;
                                                }
                                            );
                                        if (Number(ingredient.id_ingredent) === 0) {
                                            newIngredent.push({
                                                id_ingredent: 0,
                                                ingredent_name: "",
                                                parent_unit: 0,
                                                unit_id: 0,
                                                unit_name: "",
                                                group: this.state.groups,
                                            });
                                        }
                                        this.setState({
                                            ingredientFoodDetail: newIngredent,
                                        });
                                    }}
                                    menuPortalTarget={document.querySelector('body')}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </td>
                            <td className="align-middle text-center">
                                {this.state.units.filter(unit => Number(unit.id) === Number(ingredient.parent_unit))[0]?.unit_name}
                            </td>

                            {ingredient.group.sort(function (a, b) {
                                return a.id - b.id;
                            }).map((item) => {
                                return (
                                    <td key={item.id}>
                                        <CurrencyFormat
                                            className="form-control text-end"
                                            thousandSeparator={true}
                                            required
                                            value={item.amount}
                                            onValueChange={(values) => {
                                                this.setState({
                                                    ingredientFoodDetail: this.state.ingredientFoodDetail.map(ingreItem => {

                                                        if (Number(ingreItem.id_ingredent) === Number(ingredient.id_ingredent)) {
                                                            return {
                                                                id_ingredent: ingreItem.id_ingredent,
                                                                unit_name: ingreItem.unit_name,
                                                                unit_id: ingreItem.unit_id,
                                                                parent_unit: ingredient.parent_unit,
                                                                ingredent_name: ingredient.ingredent_name,
                                                                group: ingreItem.group.map(groupItem => {

                                                                    if (Number(groupItem.id) === Number(item.id)) {
                                                                        return {
                                                                            id: groupItem.id,
                                                                            amount: values.value
                                                                        }
                                                                    }

                                                                    return groupItem;
                                                                })
                                                            }
                                                        }

                                                        return ingreItem;
                                                    })
                                                })
                                            }}
                                            disabled={Number(ingredient.id_ingredent) === 0 ? true : false}
                                        />
                                    </td>
                                );
                            })}

                            <td className="align-middle text-center">
                                {Number(ingredient.id_ingredent) === 0 ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        type="button"
                                        title="Xóa nguyên liệu"
                                        onClick={() => {
                                            this.removeSelect(
                                                ingredient.id_ingredent
                                            );
                                        }}
                                    >
                                        <i className="fa-solid fa-trash"></i>
                                    </Button>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    }

    render() {
        const { onHide, ...other } = { ...this.props };

        const closeModal = () => {
            const food = {
                food_name: "",
                id_unit: 0,
                id_category: 0,
            };

            this.setState({
                foodDetail: food,
                ingredientFoodDetail: [],
            });

            onHide();
        };

        return (
            <>
                <Modal
                    show={other.show}
                    onHide={onHide}
                    size="xl"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <i className="fa-solid fa-bowl-rice me-2"></i>
                            Chi tiết món ăn
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                {/* Food Name */}
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        type="text"
                                        value={this.state.foodDetail.food_name}
                                        onChange={(e) => {
                                            const food = this.state.foodDetail;
                                            food.food_name = e.target.value;
                                            this.setState({
                                                foodDetail: food,
                                            });
                                        }}
                                        name="food_name"
                                        id="food_name"
                                        placeholder="Tên món ăn"
                                        required
                                    />
                                    <Form.Label>
                                        Tên món ăn<i className="text-danger">*</i>
                                    </Form.Label>
                                </Form.Floating>

                                {/* Select Category */}
                                <Form.Floating className="">
                                    <Form.Select
                                        value={this.state.foodDetail.id_category}
                                        onChange={(e) => {
                                            const food = this.state.foodDetail;
                                            food.id_category = Number(
                                                e.target.value
                                            );
                                            this.setState({
                                                foodDetail: food,
                                                categorySelected: Number(
                                                    e.target.value
                                                ),
                                            });
                                        }}
                                        name="id_category"
                                        required
                                    >
                                        <option value="">--Chọn--</option>
                                        {this.state.categories.length > 0
                                            ? this.state.categories.filter(item => Number(item.is_main) === (this.state.foodDetail.is_main)).map((item) => {
                                                return (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.category_name}
                                                    </option>
                                                );
                                            })
                                            : "Không có lựa chọn"}
                                    </Form.Select>
                                    <Form.Label>
                                        Danh mục<i className="text-danger">*</i>
                                    </Form.Label>
                                </Form.Floating>

                                {/* Select Unit */}
                                {new Check().permission(['68']) ?
                                    <Form.Group className="text-end">
                                        <Button
                                            size="sm"
                                            variant="link"
                                            type="button"
                                            className="fst-italic"
                                            onClick={this.addUnit}
                                        >
                                            {" "}
                                            +Thêm đơn vị tính
                                        </Button>
                                    </Form.Group> : null}

                                <Form.Floating className="mb-3">
                                    <Form.Select
                                        value={this.state.foodDetail.id_unit}
                                        onChange={(e) => {
                                            const food = this.state.foodDetail;
                                            food.id_unit = Number(e.target.value);
                                            this.setState({
                                                foodDetail: food,
                                            });
                                        }}
                                        name="id_unit"
                                        required
                                    >
                                        <option value="">--Chọn--</option>
                                        {this.state.units.length > 0
                                            ? this.state.units.map((item) => {
                                                return (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.unit_name}
                                                    </option>
                                                );
                                            })
                                            : "Không có lựa chọn"}
                                    </Form.Select>
                                    <Form.Label>
                                        Đơn vị tính<i className="text-danger">*</i>
                                    </Form.Label>
                                </Form.Floating>

                                <Button
                                    size="sm"
                                    variant="warning"
                                    type="button"
                                    onClick={() => {
                                        this.setState({
                                            copyFoodModalShow: true
                                        })
                                    }}
                                >
                                    <i className="fa-solid fa-copy"></i> Sao chép
                                </Button>
                            </Col>

                            <Col md={8}>
                                {this.RenderIngredientSelect()}
                                {new Check().permission(['41']) ?
                                    <Button
                                        size="sm"
                                        variant="link fst-italic float-end"
                                        onClick={this.addIngerdent}
                                    >
                                        +Thêm nguyên liệu
                                    </Button> : null}
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            size="sm"
                            variant="secondary"
                            id="btnCloseAddFood"
                            onClick={closeModal}
                        >
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>
                        {new Check().permission(['30']) ?
                            <Button
                                size="sm"
                                variant="success"
                                type="submit"
                                onClick={() => this.editFoodDetail()}
                            >
                                <i className="fa-solid fa-pencil"></i> Cập nhật
                            </Button> : null}
                    </Modal.Footer>
                </Modal>

                {
                    this.state.parentUnitModalShow
                        ?
                        <AddParentUnit show={this.state.parentUnitModalShow} onHide={this.handleParentModalClose} onSubmit={this.handleParentModalClose} />
                        : ""
                }
                <IngredentAdd show={this.state.addIngerdentModalShow} onHide={this.addIngerdentModalClose} typeModal='TM' />

                {this.state.copyFoodModalShow ? (
                    <AddFoodFormModal
                        show={this.state.copyFoodModalShow}
                        onHide={this.handleCopyFoodModalClose}
                        onSubmit={this.handleCopySubmit}
                        foodDetail={this.state.foodDetail}
                        ingredientFoodDetail={this.state.ingredientFoodDetail}
                    />
                ) : null}
            </>
        );
    }
}

export default EditFoodFormModal;
