import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Breadcrumb, Button, Container, Card, Spinner, Col, Form, Nav } from "react-bootstrap";
import DataTables from "../../admin2/datatable/DataTables";
import BankFoodAdd from "./food/BankFoodAdd";

const toSlug = (str) => {
	str = str.toLowerCase(); // Chuyển hết sang chữ thường

	// xóa dấu
	str = str
		.normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
		.replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

	str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

	str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

	return str;
};

const categories = [
	{ id: 1, category_name: "Mặn" },
	{ id: 2, category_name: "Canh" },
	{ id: 3, category_name: "Xào" },
	{ id: 4, category_name: "Chiên" },
	{ id: 5, category_name: "Khô" },
	{ id: 6, category_name: "Nước" },
	{ id: 7, category_name: "Tráng miệng" },
];

const BankFood = () => {
	const [rootBankFoods, setRootBankFoods] = useState([]);
	const [bankFoods, setBankFoods] = useState([]);
	const [selectedFoods, setSelectedFoods] = useState([]);
	const [foods, setFoods] = useState([]);
	const [groups, setGroups] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [currentRow, setCurrentRow] = useState(null);

	const [addBankFoodModalShow, setAddBankFoodModalShow] = useState(false);

	const [keyword, setKeyword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isClear, setIsClear] = useState(false);

	useEffect(() => {
		document.title = "Ngân hàng món ăn";
		getListBankFoods();
		getListFoods();
	}, []);

	useEffect(() => {
		setIsClear(false);
	}, [isClear]);

	useEffect(() => {
		if (selectedCategory === "tat-ca") {
			setBankFoods(rootBankFoods);
		} else {
			setBankFoods(rootBankFoods.filter((foodItem) =>
				toSlug(foodItem.category_name.trim()).includes(toSlug(selectedCategory.trim()))
			));
		}

		setKeyword("");
	}, [selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedCategory === "tat-ca") {
			setBankFoods(rootBankFoods.filter((foodItem) =>
				toSlug(String(foodItem.food_name)).includes(toSlug(keyword.trim())) ||
				toSlug(String(foodItem.food_code)).includes(toSlug(keyword.trim())) ||
				toSlug(String(foodItem.category_name)).includes(toSlug(keyword.trim()))
			));
		} else {
			setBankFoods(rootBankFoods.filter((foodItem) =>
				(
					toSlug(String(foodItem.food_name)).includes(toSlug(keyword.trim())) ||
					toSlug(String(foodItem.food_code)).includes(toSlug(keyword.trim()))
				)
				&& toSlug(foodItem.category_name.trim()).includes(toSlug(selectedCategory.trim()))
			));
		}
	}, [keyword]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getListBankFoods = async () => {
		setIsLoading(true);
		await axios
			.get(`/food-bank`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setIsLoading(false);
					setBankFoods(res.data.foods);
					setGroups(res.data.foods[0]?.ingredents[0]?.groups || []);
					setRootBankFoods(res.data.foods);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getListFoods = async () => {
		await axios
			.get(`/food-show`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setFoods(res.data.foods);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	// Logic
	const handleShowAddFoodModal = () => {
		setAddBankFoodModalShow(true);
	};
	const handleCloseAddFoodModal = (isReload) => {
		setAddBankFoodModalShow(false);
		if (isReload) {
			getListFoods();
			setSelectedFoods([]);
			setIsClear(true);
		}
	};
	// Logic

	// Render
	const foodColumns = [
		{
			name: "Mã món ăn",
			selector: (row) => row.food_code,
			sortable: true,
		},
		{
			name: "Tên món ăn",
			selector: (row) => row.food_name,
			sortable: true,
			wrap: true,
		},
		{
			name: "Danh mục",
			selector: (row) => row.category_name,
			sortable: true,
		},
		{
			name: "Đơn vị tính",
			selector: (row) => row.unit_name,
			sortable: true,
		},
	];

	const ingredientColumns = [
		{
			name: "Mã nguyên liệu",
			selector: (row) => row.ingredent_code,
			sortable: true,
		},
		{
			name: "Tên nguyên liệu",
			selector: (row) => row.ingredent_name,
			sortable: true,
			wrap: true,
		},
		groups?.length > 0
			? groups?.map((group) => {
				return {
					name: group.group_name,
					selector: (row) => row.ingredent_name,
				};
			})
			: null,
	];

	groups?.map((group, index) => {
		ingredientColumns.push({
			name: group.group_name,
			selector: (row) => <div>{row.groups[index].quantity + " " + row.groups[index].unit_cook_name}</div>,
		});

		return group;
	});

	const selectableRowDisabled = (row) => {
		return foods?.some((item) => item.food_name === row.food_name);
	};
	const IngredientTable = ({ data }) => {
		if (data.ingredents.length === 0) {
			return <i className="text-muted ms-5">Không có dữ liệu!</i>;
		}

		return <DataTables className="ms-5" data={data.ingredents} columns={ingredientColumns} pagination={false} fixedHeader={false} />;
	};

	return (
		<Container fluid className="px-3">
			<Breadcrumb className="mb-2 mt-2">
				<Breadcrumb.Item active>Món ăn</Breadcrumb.Item>
				<Breadcrumb.Item active>Ngân hàng món ăn</Breadcrumb.Item>
			</Breadcrumb>

			<Card className="mb-4">
				<Card.Header>
					<Button
						variant="success"
						size="sm"
						className="float-end"
						onClick={() => {
							if (selectedFoods.length === 0) {
								toast("Vui lòng chọn món ăn", {
									type: "error",
									autoClose: 1000,
								});

								return;
							}
							handleShowAddFoodModal();
						}}
					>
						<i className="fa-solid fa-plus"></i> Thêm vào danh sách món
					</Button>

					<Card.Title>
						<i className="fas fa-list me-1"></i> Ngân hàng món ăn
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<Col xs={12} md={4} className="mb-3">
						<Form.Control type="text" placeholder="Tìm món ăn" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
					</Col>

					<Nav variant="tabs" defaultActiveKey={0} className="float-start mb-3">
						<Nav.Item>
							<Nav.Link eventKey={0} onClick={() => setSelectedCategory("tat-ca")}>
								Tất cả
							</Nav.Link>
						</Nav.Item>

						{categories?.map((category) => {
							return (
								<Nav.Item key={category.id}>
									<Nav.Link eventKey={category.id} onClick={() => setSelectedCategory(toSlug(category.category_name))}>
										{category.category_name}
									</Nav.Link>
								</Nav.Item>
							);
						})}
					</Nav>

					<DataTables
						data={bankFoods}
						columns={foodColumns}
						selectableRows
						selectableRowsNoSelectAll
						onSelectedRowsChange={(item) => {
							setIsClear(false);
							setSelectedFoods(item.selectedRows);
						}}
						selectableRowDisabled={selectableRowDisabled}
						expandableRows
						expandableRowExpanded={(row) => row === currentRow}
						expandOnRowClicked
						onRowClicked={(row) => setCurrentRow(row)}
						expandableRowsComponent={IngredientTable}
						onRowExpandToggled={(bool, row) => setCurrentRow(row)}
						progressPending={isLoading}
						progressComponent={
							<Spinner animation="border" role="status" variant="primary" style={{ width: "3rem", height: "3rem" }} className="mt-3">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						}
						paginationRowsPerPageOptions={[30, 50, 100, 500, 1000]}
						clearSelectedRows={isClear}
					/>
				</Card.Body>
			</Card>

			{addBankFoodModalShow ? <BankFoodAdd show={addBankFoodModalShow} onHide={handleCloseAddFoodModal} foods={selectedFoods} /> : null}
		</Container>
	);
};

export default BankFood;
