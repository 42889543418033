import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import AddParentUnit from "./AddParentUnit";
import AddChildUnit from "./AddChildUnit";
import Check from "../other/Check";

class UnitIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            units: [],
            deps: [],
            addModalShow: false,
            editModalShow: false,
            unit: {
                id: "",
                unit_name: "",
                parent_unit: 0,
                // redemption_value: 0,
            },
            selectedRows: [],

            parentUnitModalShow: false,
            childUnitModalShow: false

        };
    }

    componentDidMount() {
        document.title = 'Đơn vị tính';
        this.getUnitsData();
    }

    getUnitsData() {
        axios.post(`/viewUnit`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({ units: res.data.data.filter(item => item.is_main !== 0) });
                }
            });
    }

    deleteUnit = (item) => {
        swal({
            title: "Bạn muốn xóa đơn vị tính?",
            text: `"${item.unit_name}"`,
            icon: "error",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(`/deleteUnit?id_unit=${item.id}`)
                    .then(res => {
                        if (res.data.status === true) {
                            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                            this.getUnitsData();
                        } else {
                            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                        }
                    });
            }
        });
    }

    dataTable() {
        const ExpandedComponent = ({ data }) => {

            if (data.unit_childs.length > 0) {
                const columns = [
                    {
                        name: 'Đơn vị đi chợ',
                        selector: row => row.unit_name,
                        sortable: true,
                    },
                    {
                        name: 'Phương thức',
                        center: true,
                        cell: row => (<>
                            <Button size="sm" variant="warning me-2" type="button" onClick={() => editModalShow(row)} title="Chi tiết đơn vị tính" >
                                <i className="fa-solid fa-pencil"></i>
                            </Button>
                            <Button size="sm" variant="danger" type="button" onClick={() => this.deleteUnit(row)} title='Xóa đơn vị tính'>
                                <i className='fas fa-trash'></i>
                            </Button>
                        </>),
                    },
                ];

                return (<DataTables className="ms-5" data={data.unit_childs} columns={columns} />);
            } else {
                return (<i className="text-muted ms-5">Không có đơn vị đi chợ!</i>);
            }
        };

        const editModalShow = (item) => {
            this.setState({
                unit: {
                    id: item.id,
                    unit_name: item.unit_name,
                    parent_unit: item.parent_unit,
                    // // redemption_value: item.redemption_value,
                }
            });

            if (item.parent_unit === 0) {
                this.setState({ parentUnitModalShow: true });

                return;
            }
            this.setState({ childUnitModalShow: true });
        }

        const addChildModalShow = (item) => {
            this.setState({
                unit: {
                    id: "",
                    unit_name: "",
                    parent_unit: item.id,
                    // redemption_value: 0,
                }
            });

            this.setState({ childUnitModalShow: true });
        }

        const columns = [
            {
                name: 'Đơn vị định lượng',
                selector: row => row.unit_name,
                sortable: true,
            },
            {
                name: 'Phương thức',
                center: true,
                cell: row => (<>
                    {new Check().permission(['67'], 'or') ?
                    <Button size="sm" variant="warning me-2" type="button" onClick={() => editModalShow(row)} title="Chi tiết đơn vị tính" >
                        <i className="fa-solid fa-pencil"></i>
                    </Button>:null}
                    {new Check().permission(['68'], 'or') ?
                    <Button size="sm" variant="primary me-2" type="button" onClick={() => addChildModalShow(row)} title="Thêm đơn vị đi chợ" >
                        <i className="fa-solid fa-plus"></i>
                    </Button>:null}
                    {new Check().permission(['70'], 'or') ?
                    <Button size="sm" variant="danger" type="button" onClick={() => this.deleteUnit(row)} title='Xóa đơn vị tính'>
                        <i className='fas fa-trash'></i>
                    </Button>:null}
                </>),
            },
        ];

        const data = [];

        const units = this.state.units;
        if (units.length > 0) {
            units.map((item) => {
                let unit_childs = [];
                if (item.parent_unit === 0) {

                    let child_ = units.filter((e) => Number(e.parent_unit) === Number(item.id));

                    if (child_.length > 0) {
                        child_.map((eChild) => {
                            unit_childs.push({
                                id: eChild.id,
                                unit_name: eChild.unit_name,
                                parent_unit: eChild.parent_unit,
                                // redemption_value: eChild.redemption_value,
                            })

                            return eChild;
                        })
                    }
                    data.push({
                        id: item.id,
                        unit_name: item.unit_name,
                        parent_unit: item.parent_unit,
                        // redemption_value: item.redemption_value,
                        unit_childs: unit_childs,
                    });
                }

                return item;
            });
        }
        return <DataTables
            data={data}
            columns={columns}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
        />
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;

        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        this.setState({ dataInput: dataInput });
    }

    handleParentModalClose = () => {
        this.setState({
            unit: {
                id: "",
                unit_name: "",
                parent_unit: 0,
                // redemption_value: 0,
            }
        })
        this.setState({ parentUnitModalShow: false });
    }

    handleChildModalClose = () => {
        this.setState({
            unit: {
                id: "",
                unit_name: "",
                parent_unit: 0,
                // redemption_value: 0,
            }
        })
        this.setState({ childUnitModalShow: false });
    }

    handleSubmitParentModal = () => {
        this.setState({
            unit: {
                id: "",
                unit_name: "",
                parent_unit: 0,
                // redemption_value: 0,
            }
        })
        this.getUnitsData();
        this.setState({ parentUnitModalShow: false });

    }

    handleSubmitChildModal = () => {
        this.setState({
            unit: {
                id: "",
                unit_name: "",
                parent_unit: 0,
                // redemption_value: 0,
            }
        })
        this.getUnitsData();
        this.setState({ childUnitModalShow: false });
    }

    renderUnitList() {

        return (
            <>
                <Card.Header>
                    {new Check().permission(['68'], 'or') ?
                    <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ parentUnitModalShow: true })} >
                        <i className="fa-solid fa-plus"></i> Thêm đơn vị định lượng
                    </Button>:null}
                    <Card.Title>
                        <i className="fas fa-list me-1"></i> Danh sách đơn vị tính
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    {this.dataTable()}
                </Card.Body>

                {this.state.parentUnitModalShow
                    ?
                    <AddParentUnit show={this.state.parentUnitModalShow} onHide={this.handleParentModalClose} onSubmit={this.handleSubmitParentModal} unitData={this.state.unit} />
                    : null
                }

                {this.state.childUnitModalShow
                    ?
                    <AddChildUnit show={this.state.childUnitModalShow} onHide={this.handleChildModalClose} onSubmit={this.handleSubmitChildModal} unitData={this.state.unit} units={this.state.units} />
                    : null
                }

            </>
        );
    }

    render() {
        return (<>
            <Container fluid className="px-3">
                <Breadcrumb className="mb-2 mt-2">
                    <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
                    <Breadcrumb.Item active>Đơn vị tính</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col md={6}>
                        <Card className="mb-4">
                            {this.renderUnitList()}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

export default UnitIndex;