import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

class HolidayModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

            dataInput: { ...this.props.datarole },
            isCheckGroup: [],
            startDate: this.props.datarole.start_date ? new Date(this.props.datarole.start_date * 1000) : new Date(),
            endDate: this.props.datarole.end_date ? new Date(this.props.datarole.end_date * 1000) : new Date(), err: {}
        };
    }

    componentDidMount() {

    }
    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;

        let errIinput = { ...this.state.err }
        delete (errIinput['year_name'])
        this.setState({ err: errIinput });
        this.setState({ dataInput: dataInput });
    }



    handleSelectGroup = (e) => {
        const { value, checked } = e.target;
        const idChildArr = (e.target.dataset.id_child).split(',');

        if (idChildArr.length > 0) {
            const dataInput = { ...this.state.dataInput };

            dataInput.permission = [...dataInput.permission, ...idChildArr];
            this.setState({ dataInput: dataInput });


            if (!checked) {
                idChildArr.map((id, i) => {
                    dataInput.permission = dataInput.permission.filter(item => item !== id);

                    this.setState({ dataInput: dataInput });

                    return id;
                });


            }


        }

        this.setState({ isCheckGroup: [...this.state.isCheckGroup, String(value)] });

        if (!checked) {
            this.setState({ isCheckGroup: this.state.isCheckGroup.filter(item => item !== String(value)) });
        }
    }

    createRole = () => {
        let data = this.state.dataInput;

        // /createSchoolYear?year_name=2025-2026&start_date=1719680401&end_date=1719766800
        axios.post(`/createHoliday?id_school_year=${this.props.id_add}&holiday_name=${data.holiday_name}&start_date=${parseInt(new Date(this.state.startDate.setHours(0, 0, 0)) / 1000)}&end_date=${parseInt(new Date(this.state.endDate.setHours(23, 59, 59)) / 1000)}`)
            .then((res) => {
                if (res.data.status) {
                    toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                    return this.props.submit();
                } else {
                    this.setState({ err: res.data.data })
                    // toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateRole = () => {
        let data = this.state.dataInput;
        swal({
            title: "Bạn muốn cập nhật ngày nghỉ?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                // &start_date=${parseInt(new Date(this.state.startDate.setHours(0, 0, 0)) / 1000)}&end_date=${parseInt(new Date(this.state.endDate.setHours(23, 59, 59)) / 1000)}`)

                axios.post(`/updateHoliday?id_school_year=${data.id_school_year}&holiday_name=${data.holiday_name}&id=${data.id}&start_date=${parseInt(new Date(this.state.startDate.setHours(0, 0, 0)) / 1000)}&end_date=${parseInt(new Date(this.state.endDate.setHours(23, 59, 59)) / 1000)}`)
                    .then((res) => {
                        if (res.data.status) {
                            toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            this.setState({ err: res.data.data })
                            // toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createRole();
        } else {
            this.updateRole();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }



    render() {
        const { onHide, ...other } = { ...this.props };
        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title ><i className="fa-regular fa-calendar-xmark"></i> {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-1">
                            <Form.Control type="text" name="holiday_name" defaultValue={this.state.dataInput?.holiday_name || ''} placeholder="Tên năm học"
                                onChange={this.handleInput}
                            />
                            <Form.Label>Tên kỳ nghỉ<i className="text-danger">*</i></Form.Label>
                            <span className="text-form-err">
                                {this.state.err.holiday_name}
                            </span>
                        </Form.Floating>
                        <center>Thời gian diễn ra</center>
                        <div className='d-flex' >

                            {/* <span className="pe-2 " style={{
                                position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                            }}>Ngày bắt đầu</span> */}
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date) => this.setState({ startDate: date, endDate: date }

                                )
                                }
                                selectsStart
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Chọn ngày bắt đầu"
                            /><span style={{ display: 'flex', alignItems: 'center', padding: '0.5rem' }}>-</span>
                            {/* <span className="pe-2 " style={{
                                position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                            }}>Ngày kết thúc</span> */}
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={(date) =>
                                    this.setState({ endDate: date })
                                }
                                selectsEnd
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                minDate={this.state.startDate}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Chọn ngày kết thúc"

                            />

                        </div>
                        <Row>
                            <Col md={6}>
                                <span className="text-form-err">
                                    {this.state.err.start_date}
                                </span>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit" disabled={!new Check().permission(['63', '64'])}>
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
        );
    }
}

export default HolidayModal;