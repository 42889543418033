import * as XLSX from "xlsx-js-style";
import { SHOW_INVENTORY } from "../../../../constants";

const fontStyle = {
	name: "Times New Roman",
};

const borderStyle = {
	top: { style: "thin" },
	bottom: { style: "thin" },
	left: { style: "thin" },
	right: { style: "thin" },
};

const exportBook1 = (groupIngredientArray, step1_show_inventory = 1) => {
	const table = document.getElementById("book-step-1");

	var ws = XLSX.utils.table_to_sheet(table, { raw: true });

	const marketGroupIngredient = groupIngredientArray?.filter((groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1);
	const storeGroupIngredient = groupIngredientArray?.filter((groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2);

	const lenMar = marketGroupIngredient.length === 0 ? 1 : marketGroupIngredient.length;
	const lenSto = (storeGroupIngredient.length === 0 && step1_show_inventory === SHOW_INVENTORY.TRUE ? 1 : storeGroupIngredient.length);

	const COUNT_TITLE_MARKET = 6;
	const COUNT_TITLE_STORE = step1_show_inventory === SHOW_INVENTORY.TRUE ? 4 : 0;

	// Style cell
	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];

	const colAlphaBook1_Footer = ["A", "F", "K"];

	ws[`A1`].s = {
		font: {
			...fontStyle,
			sz: 16,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
		},
	};

	ws[`A2`].s = {
		font: fontStyle,
		alignment: {
			vertical: "top",
			horizontal: "left",
			wrapText: true,
		},
	};

	ws[`A3`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "left",
		},
	};

	ws[`I2`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "top",
			horizontal: "left",
		},
	};

	colAlpha.map((alpha) => {
		ws[`${alpha}4`].s = {
			font: {
				...fontStyle,
			},
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}5`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}6`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	ws[`A${COUNT_TITLE_MARKET + lenMar + 1}`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "left",
		},
	};

	if (step1_show_inventory === SHOW_INVENTORY.TRUE) {
		for (let i = 1; i < COUNT_TITLE_STORE; i++) {
			colAlpha.map((alpha) => {
				ws[`${alpha}${6 + lenMar + i + 1}`].s = {
					font: fontStyle,
					alignment: {
						vertical: "center",
						horizontal: "center",
						wrapText: true,
					},
					border: borderStyle,
				};
				return alpha;
			});

		}
	}

	ws["!cols"] = [
		{ wch: 3 },
		{ wch: 10 },
		{ wch: 8 },
		{ wch: 8 },
		{ wch: 10 },
		{ wch: 10 },
		{ wch: 10 },
		{ wch: 8 },
		{ wch: 10 },
		{ wch: 9 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 6 },
	];

	const row = [{ hpt: 40 }, { hpt: 70 }, {}, { hpt: 60 }, {}, {}];

	lenMar === 1
		? row.push({})
		: marketGroupIngredient.map((data) => {
			row.push({});
			return data;
		});

	row.push({});
	row.push({ hpt: 60 });
	row.push({ hpt: 45 });
	row.push({});

	lenSto === 1
		? row.push({})
		: storeGroupIngredient.map((data) => {
			row.push({});
			return data;
		});

	row.push({ hpt: 60 });

	ws["!rows"] = row;

	colAlphaBook1_Footer.map((alpha) => {
		ws[`${alpha}${COUNT_TITLE_MARKET + lenMar + COUNT_TITLE_STORE + lenSto + 1}`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		return alpha;
	});

	const intLen = 7;

	for (let i = 0; i < lenMar; i++) {
		ws[`A${intLen + i}`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		colAlpha.slice(1).map((alpha) => {
			ws[`${alpha}${intLen + i}`].s = {
				font: fontStyle,
				alignment: {
					vertical: "center",
					wrapText: true,
				},
				border: borderStyle,
			};

			return alpha;
		});
	}

	const sendLen = intLen + lenMar + COUNT_TITLE_STORE;

	for (let i = 0; i < lenSto; i++) {
		ws[`A${sendLen + i}`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		colAlpha.slice(1).map((alpha) => {
			if (ws[`${alpha}${sendLen + i}`].v === "empty") {
				ws[`${alpha}${sendLen + i}`].v = " ";
			}

			ws[`${alpha}${sendLen + i}`].s = {
				font: fontStyle,
				alignment: {
					vertical: "center",
					wrapText: true,
				},
				border: borderStyle,
			};

			return alpha;
		});
	}

	let merge = [
		// Title
		{ s: { r: 0, c: 0 }, e: { r: 0, c: 14 } },
		{ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
		{ s: { r: 1, c: 8 }, e: { r: 1, c: 14 } },
		{ s: { r: 2, c: 0 }, e: { r: 2, c: 14 } },
		// Title

		// Header 1
		{ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
		{ s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
		{ s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
		{ s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },

		{ s: { r: 3, c: 4 }, e: { r: 3, c: 6 } },

		{ s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
		{ s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
		{ s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },

		{ s: { r: 3, c: 10 }, e: { r: 3, c: 11 } },
		{ s: { r: 3, c: 12 }, e: { r: 3, c: 13 } },

		{ s: { r: 3, c: 14 }, e: { r: 4, c: 14 } },
		// Header 1

		// Footer
		{ s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 0 }, e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 4 } },
		{ s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 5 }, e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 9 } },
		{ s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 10 }, e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 14 } },
		// Footer
	];

	if (step1_show_inventory === SHOW_INVENTORY.TRUE) {
		merge = merge.concat([
			// Header 2
			{ s: { r: 6 + lenMar, c: 0 }, e: { r: 6 + lenMar, c: 14 } },
			{ s: { r: 6 + lenMar + 1, c: 0 }, e: { r: 6 + lenMar + 2, c: 0 } },
			{ s: { r: 6 + lenMar + 1, c: 1 }, e: { r: 6 + lenMar + 2, c: 1 } },
			{ s: { r: 6 + lenMar + 1, c: 2 }, e: { r: 6 + lenMar + 2, c: 2 } },
			{ s: { r: 6 + lenMar + 1, c: 3 }, e: { r: 6 + lenMar + 2, c: 3 } },
			{ s: { r: 6 + lenMar + 1, c: 4 }, e: { r: 6 + lenMar + 2, c: 4 } },
			{ s: { r: 6 + lenMar + 1, c: 5 }, e: { r: 6 + lenMar + 2, c: 5 } },

			{ s: { r: 6 + lenMar + 1, c: 6 }, e: { r: 6 + lenMar + 1, c: 8 } },

			{ s: { r: 6 + lenMar + 1, c: 9 }, e: { r: 6 + lenMar + 2, c: 9 } },
			{ s: { r: 6 + lenMar + 1, c: 10 }, e: { r: 6 + lenMar + 2, c: 10 } },
			{ s: { r: 6 + lenMar + 1, c: 11 }, e: { r: 6 + lenMar + 2, c: 11 } },

			{ s: { r: 6 + lenMar + 1, c: 12 }, e: { r: 6 + lenMar + 1, c: 13 } },

			{ s: { r: 6 + lenMar + 1, c: 14 }, e: { r: 6 + lenMar + 2, c: 14 } },
			// Header 2
		]);
	}

	if (marketGroupIngredient.length === 0)
		merge.push({ s: { r: 6, c: 0 }, e: { r: 6, c: 14 } });

	if (storeGroupIngredient.length === 0)
		merge.push({ s: { r: 6 + lenMar + COUNT_TITLE_STORE, c: 0 }, e: { r: 6 + lenMar + COUNT_TITLE_STORE, c: 14 } });

	ws["!merges"] = merge;

	return ws;
};

const exportBook2 = (ingredientLen) => {
	const table = document.getElementById("book-step-2");

	var ws = XLSX.utils.table_to_sheet(table, { raw: true });

	// Style cell
	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"];

	ws[`A1`].s = {
		font: fontStyle,
		alignment: {
			vertical: "top",
			horizontal: "left",
			wrapText: true,
		},
	};

	ws[`G1`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "top",
			horizontal: "left",
		},
	};

	colAlpha.map((alpha) => {
		ws[`${alpha}2`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}3`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}4`].s = {
			font: fontStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
			border: borderStyle,
		};

		return alpha;
	});

	ws["!cols"] = [
		{ wch: 3.5 },
		{ wch: 8 },
		{ wch: 15 },
		{ wch: 30 },
		{ wch: 6 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 13 },
		{ wch: 10 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 7 },
		{ wch: 7 },
	];

	const row = [{ hpt: 70 }, { hpt: 60 }, {}];

	const intLen = 5;
	for (let i = 0; i < ingredientLen; i++) {
		row.push({});

		colAlpha.map((alpha) => {
			if (ws[`${alpha}${intLen + i}`].v === "empty") {
				ws[`${alpha}${intLen + i}`].v = " ";
			}

			if (alpha === "E" || alpha === "F" || alpha === "G" || alpha === "I" || alpha === "J") {
				ws[`${alpha}${intLen + i}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center",
					},
				};
			} else if (alpha === "B" || alpha === "C" || alpha === "D" || alpha === "H") {
				ws[`${alpha}${intLen + i}`].s = {
					font: {
						name: "Times New Roman",
					},
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "left",
					},
				};
			} else {
				ws[`${alpha}${intLen + i}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center",
					},
				};
			}

			return alpha;
		});

		if (intLen === intLen + i) {
			const str = ws[`H${intLen + i}`.toString()].v;
			ws[`H${intLen + i}`.toString()].v = str.split("delete").pop();
		}

		const str = ws[`G${intLen + i}`.toString()].v;
		ws[`G${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "");

		const str1 = ws[`F${intLen + i}`.toString()].v;
		ws[`F${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "");
	}

	row.push({}, { hpt: 60 });

	ws["!rows"] = row;

	const merge = [
		// Title
		{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
		{ s: { r: 0, c: 6 }, e: { r: 0, c: 12 } },
		// Title

		// Header
		{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
		{ s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
		{ s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
		{ s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
		{ s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
		{ s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
		{ s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

		{ s: { r: 1, c: 7 }, e: { r: 1, c: 9 } },
		{ s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },

		{ s: { r: 1, c: 12 }, e: { r: 2, c: 12 } },
		// Header

		// Footer
		{ s: { r: 4 + ingredientLen, c: 0 }, e: { r: 4 + ingredientLen, c: 3 } },
		{ s: { r: 4 + ingredientLen, c: 4 }, e: { r: 4 + ingredientLen, c: 8 } },
		{ s: { r: 4 + ingredientLen, c: 9 }, e: { r: 4 + ingredientLen, c: 12 } },
		// Footer
	];
	ws["!merges"] = merge;

	colAlpha.map((alpha) => {
		ws[`${alpha}${4 + ingredientLen + 1}`].s = {
			font: fontStyle,
			alignment: {
				vertical: "top",
				horizontal: "center",
			},
		};

		return alpha;
	});

	return ws;
};

const exportBook3 = (ingredientLen) => {
	const table = document.getElementById("book-step-3");

	var ws = XLSX.utils.table_to_sheet(table, { raw: true });

	// Style cell
	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

	const colAlphaBook3_Footer = ["A", "D", "H"];

	ws[`A1`].s = {
		font: fontStyle,
		alignment: {
			wrapText: true,
			vertical: "top",
			horizontal: "left",
		},
	};

	ws[`G1`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "top",
			horizontal: "left",
		},
	};

	colAlpha.map((alpha) => {
		ws[`${alpha}2`].s = {
			font: fontStyle,
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}3`].s = {
			font: fontStyle,
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}4`].s = {
			font: fontStyle,
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	ws["!cols"] = [{ wch: 3.5 }, { wch: 10 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 25 }, { wch: 10 }, { wch: 10 }, { wch: 10 }];

	const row = [{ hpt: 70 }, { hpt: 60 }, {}];

	const intLen = 5;

	for (let i = 0; i < ingredientLen; i++) {
		row.push({});

		colAlpha.map((alpha) => {
			if (ws[`${alpha}${intLen + i}`].v === "empty") {
				ws[`${alpha}${intLen + i}`].v = " ";
			}

			if (alpha === "D") {
				ws[`${alpha}${intLen + i}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center",
					},
				};
			} else if (alpha === "B" || alpha === "C" || alpha === "E" || alpha === "F" || alpha === "G") {
				ws[`${alpha}${intLen + i}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "left",
					},
				};
			} else {
				ws[`${alpha}${intLen + i}`].s = {
					font: fontStyle,
					border: borderStyle,
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center",
					},
				};
			}

			return alpha;
		});

		const str = ws[`B${intLen + i}`.toString()].v;
		ws[`B${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "");

		const str1 = ws[`E${intLen + i}`.toString()].v;
		ws[`E${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "");

		const str2 = ws[`F${intLen + i}`.toString()].v;
		ws[`F${intLen + i}`.toString()].v = str2.replaceAll("Selected time:", "");
	}

	row.push({}, { hpt: 60 });

	ws["!rows"] = row;

	const merge = [
		// Title
		{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
		{ s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
		// Title

		// Header
		{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
		{ s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
		{ s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
		{ s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
		{ s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
		{ s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
		{ s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

		{ s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },

		{ s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
		// Header

		// Footer
		{ s: { r: 4 + ingredientLen, c: 0 }, e: { r: 4 + ingredientLen, c: 2 } },
		{ s: { r: 4 + ingredientLen, c: 3 }, e: { r: 4 + ingredientLen, c: 6 } },
		{ s: { r: 4 + ingredientLen, c: 7 }, e: { r: 4 + ingredientLen, c: 9 } },
		// Footer
	];
	ws["!merges"] = merge;

	colAlphaBook3_Footer.map((alpha) => {
		ws[`${alpha}${4 + ingredientLen + 1}`].s = {
			font: fontStyle,
			alignment: {
				vertical: "top",
				horizontal: "center",
			},
		};

		return alpha;
	});

	return ws;
};

export { exportBook1, exportBook2, exportBook3 };
