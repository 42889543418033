import React, { Component } from "react";
import axios from "axios";
import { Card, Container, Table, Breadcrumb, Button, Tabs, Tab, Row, Col, Form } from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import moment from "moment";
import swal from "sweetalert";

const initialDay = [
    { id: 1, day_name: "Thứ 2" },
    { id: 2, day_name: "Thứ 3" },
    { id: 3, day_name: "Thứ 4" },
    { id: 4, day_name: "Thứ 5" },
    { id: 5, day_name: "Thứ 6" },
    { id: 6, day_name: "Thứ 7" },
    { id: 7, day_name: "Chủ nhật" }
]

class ThreeStepBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolInfo: {},
            quantitative: {},
            formSetting: {
                // Sample book
                book_3: [],
                book_6: "",
                book_7: "",
                book_8: [],
                book_9: [],
                book_11: "",
                book_12: "",

                // Step 1
                step1_m1_3: new Date(),
                step1_m2_5: new Date(),
                step1_m2_11: "",

                // Step 2
                step2_6: [],
                step2_7: [],
                step2_8: [],
                step2_9: "",
                step2_10: "",

                // Step 3
                step3_2: [],
                step3_5: [],
                step3_6: [],
                step3_7: "",
            },

            isLoading: false,

            listUnit: [],

            listDay: [],
            daySelect: 0,

            start: new Date(),
            term: new Date(),

            weekInMonth: ""
        };
    }

    async componentDidMount() {
        document.title = 'Sổ 3 bước';

        this.getDetailschool();

        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        if (todayIs === 0) {
            todayIs = 7;
        }

        const newListDay = initialDay.map((day, index) => {
            return {
                id: day.id,
                day_name: day.day_name,
                day: initialWeek[index]
            }
        });

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: newListDay
        })

        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length - 1], todayIs);
        this.getSettingFormData();
        this.getlistunit();
    }

    //#region Fetch data
    getDetailschool = () => {
        axios.get(`/detailSchool`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        schoolInfo: res.data.data[0]
                    })
                }
            });
    }

    getQuantitativeVoucher = (startDay, endDay, daysId) => {
        this.setState({
            isLoading: true
        })

        axios.post(`/voucher?is_real=1&type=2&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}&id_day=${daysId}`)
            .then(res => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status === true) {
                    this.setState({
                        quantitative: res.data.data[0]
                    })
                }
            });

    }

    getSettingFormData() {
        axios
            .get("/viewSettingForm")
            .then((res) => {
                if (res.data.status) {
                    let initialFormSetting = res.data.data[0];

                    initialFormSetting.step2_8 = res.data.data[0].step2_8 ? JSON.parse(res.data.data[0].step2_8) : [];

                    if (initialFormSetting.step2_6.toString().toLowerCase() === "null" || initialFormSetting.step2_6 === "" || initialFormSetting.step2_6.length === 0) {
                        initialFormSetting.step2_6 = [];
                    }

                    if (initialFormSetting.step2_7.toString().toLowerCase() === "null" || initialFormSetting.step2_7 === "" || initialFormSetting.step2_7.length === 0) {
                        initialFormSetting.step2_7 = [];
                    }

                    if (initialFormSetting.step3_2.toString().toLowerCase() === "null" || initialFormSetting.step3_2 === "" || initialFormSetting.step3_2.length === 0) {
                        initialFormSetting.step3_2 = [];
                    }

                    if (initialFormSetting.step3_5.toString().toLowerCase() === "null" || initialFormSetting.step3_5 === "" || initialFormSetting.step3_5.length === 0) {
                        initialFormSetting.step3_5 = [];
                    }

                    if (initialFormSetting.step3_6.toString().toLowerCase() === "null" || initialFormSetting.step3_6 === "" || initialFormSetting.step3_6.length === 0) {
                        initialFormSetting.step3_6 = [];
                    }

                    if (initialFormSetting.book_3.toString().toLowerCase() === "null" || initialFormSetting.book_3 === "" || initialFormSetting.book_3.length === 0) {
                        initialFormSetting.book_3 = [];
                    }

                    if (initialFormSetting.book_8.toString().toLowerCase() === "null" || initialFormSetting.book_8 === "" || initialFormSetting.book_8.length === 0) {
                        initialFormSetting.book_8 = [];
                    }

                    if (initialFormSetting.book_9.toString().toLowerCase() === "null" || initialFormSetting.book_9 === "" || initialFormSetting.book_9.length === 0) {
                        initialFormSetting.book_9 = [];
                    }

                    this.setState({
                        formSetting: initialFormSetting
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getlistunit() {
        axios.post(`/viewUnit`)
            .then(res => {
                if (res.data.status === true) {
                    this.setState({
                        listUnit: res.data.data
                    });
                }
            });
    }
    //#endregion

    //#region Handle Logic
    xport = () => {
        const wb = XLSX.utils.book_new();

        const ws1 = this.book1();
        const ws2 = this.book2();
        const ws3 = this.book3();

        XLSX.utils.book_append_sheet(wb, ws1, "Sổ bước 1");
        XLSX.utils.book_append_sheet(wb, ws2, "Sổ bước 2");
        XLSX.utils.book_append_sheet(wb, ws3, "Sổ bước 3");

        XLSX.writeFile(wb, "Sổ 3 bước.xlsx");
    };

    book1 = () => {
        const table1 = document.getElementById("book-step-1");

        var ws1 = XLSX.utils.table_to_sheet(table1, { raw: true })

        let allIngredient = [];

        this.state.quantitative?.time?.map(timeItem => {
            return timeItem.detail.map(foodItem => {
                return foodItem.ingrendent.map(ingredientItem => {
                    allIngredient.push(ingredientItem)
                    return ingredientItem;
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");

        const aIngredientArrayMar = aIngredientArray?.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 1);
        const aIngredientArraySto = aIngredientArray?.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 2);

        const lenMar = aIngredientArrayMar.length === 0 ? 1 : aIngredientArrayMar.length;
        const lenSto = aIngredientArraySto.length === 0 ? 1 : aIngredientArraySto.length;

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];

        const colAlphaBook1_Footer = ["A", "F", "K"];

        ws1[`A1`].s = {
            font: {
                name: "Times New Roman",
                sz: 16,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws1[`A2`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws1[`A3`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "left"
            }
        };

        ws1[`I2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        colAlpha.map((alpha) => {
            ws1[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws1[`${alpha}5`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws1[`${alpha}6`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws1[`A${6 + lenMar + 1}`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "left"
            }
        };

        colAlpha.map((alpha) => {

            ws1[`${alpha}${6 + lenMar + 2}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws1[`${alpha}${6 + lenMar + 3}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws1[`${alpha}${6 + lenMar + 4}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws1['!cols'] = [
            { wch: 3 },
            { wch: 10 },
            { wch: 8 },
            { wch: 8 },
            { wch: 8 },
            { wch: 10 },
            { wch: 10 },
            { wch: 8 },
            { wch: 10 },
            { wch: 8 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 6 },
        ];

        const row = [{ hpt: 40 }, { hpt: 70 }, {}, { hpt: 60 }, {}, {}];

        lenMar === 1 ? row.push({}) : aIngredientArrayMar.map(data => {
            row.push({});
            return data;
        })

        row.push({});
        row.push({ hpt: 60 });
        row.push({ hpt: 45 });
        row.push({});

        lenSto === 1 ? row.push({}) : aIngredientArraySto.map(data => {
            row.push({});
            return data;
        })

        row.push({ hpt: 60 });

        ws1['!rows'] = row;

        colAlphaBook1_Footer.map(alpha => {
            ws1[`${alpha}${6 + lenMar + 4 + lenSto + 1}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "top",
                    horizontal: "center",
                }
            };

            return alpha;
        })

        const intLen = 7;

        for (let i = 0; i < lenMar; i++) {
            ws1[`A${intLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            if (aIngredientArrayMar.length !== 0) {
                colAlpha.slice(1).map(alpha => {

                    if (ws1[`${alpha}${intLen + i}`].v === "empty") {
                        ws1[`${alpha}${intLen + i}`].v = ' '
                    }

                    ws1[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        alignment: {
                            vertical: "center",
                            wrapText: true
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                    };

                    return alpha;
                })
            }
        }

        const sendLen = intLen + lenMar + 4;

        for (let i = 0; i < lenSto; i++) {
            ws1[`A${sendLen + i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            if (aIngredientArraySto.length !== 0) {
                colAlpha.slice(1).map(alpha => {

                    if (ws1[`${alpha}${sendLen + i}`].v === "empty") {
                        ws1[`${alpha}${sendLen + i}`].v = ' '
                    }

                    ws1[`${alpha}${sendLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        alignment: {
                            vertical: "center",
                            wrapText: true
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                    };

                    return alpha;
                })
            }
        }

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 14 } },

            { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
            { s: { r: 1, c: 8 }, e: { r: 1, c: 14 } },

            { s: { r: 2, c: 0 }, e: { r: 2, c: 14 } },
            // Title

            // Header 1
            { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
            { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
            { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },

            { s: { r: 3, c: 4 }, e: { r: 3, c: 6 } },

            { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
            { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
            { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },

            { s: { r: 3, c: 10 }, e: { r: 3, c: 11 } },
            { s: { r: 3, c: 12 }, e: { r: 3, c: 13 } },

            { s: { r: 3, c: 14 }, e: { r: 4, c: 14 } },
            // Header 1

            { s: { r: 6 + lenMar, c: 0 }, e: { r: 6 + lenMar, c: 14 } },

            // Header 2
            { s: { r: 6 + lenMar + 1, c: 0 }, e: { r: 6 + lenMar + 2, c: 0 } },
            { s: { r: 6 + lenMar + 1, c: 1 }, e: { r: 6 + lenMar + 2, c: 1 } },
            { s: { r: 6 + lenMar + 1, c: 2 }, e: { r: 6 + lenMar + 2, c: 2 } },
            { s: { r: 6 + lenMar + 1, c: 3 }, e: { r: 6 + lenMar + 2, c: 3 } },
            { s: { r: 6 + lenMar + 1, c: 4 }, e: { r: 6 + lenMar + 2, c: 4 } },
            { s: { r: 6 + lenMar + 1, c: 5 }, e: { r: 6 + lenMar + 2, c: 5 } },

            { s: { r: 6 + lenMar + 1, c: 6 }, e: { r: 6 + lenMar + 1, c: 8 } },

            { s: { r: 6 + lenMar + 1, c: 9 }, e: { r: 6 + lenMar + 2, c: 9 } },
            { s: { r: 6 + lenMar + 1, c: 10 }, e: { r: 6 + lenMar + 2, c: 10 } },
            { s: { r: 6 + lenMar + 1, c: 11 }, e: { r: 6 + lenMar + 2, c: 11 } },

            { s: { r: 6 + lenMar + 1, c: 12 }, e: { r: 6 + lenMar + 1, c: 13 } },

            { s: { r: 6 + lenMar + 1, c: 14 }, e: { r: 6 + lenMar + 2, c: 14 } },
            // Header 2

            // Footer
            { s: { r: 6 + lenMar + 4 + lenSto, c: 0 }, e: { r: 6 + lenMar + 4 + lenSto, c: 4 } },
            { s: { r: 6 + lenMar + 4 + lenSto, c: 5 }, e: { r: 6 + lenMar + 4 + lenSto, c: 9 } },
            { s: { r: 6 + lenMar + 4 + lenSto, c: 10 }, e: { r: 6 + lenMar + 4 + lenSto, c: 14 } },
            // Footer
        ];
        ws1["!merges"] = merge;

        return ws1;
    }

    book2 = () => {
        const table2 = document.getElementById("book-step-2");

        var ws2 = XLSX.utils.table_to_sheet(table2, { raw: true })

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"];

        ws2[`A1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true
            }
        };

        ws2[`G1`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        colAlpha.map((alpha) => {

            ws2[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws2[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws2[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws2['!cols'] = [
            { wch: 3.5 },
            { wch: 8 },
            { wch: 15 },
            { wch: 18 },
            { wch: 6 },
            { wch: 7 },
            { wch: 7 },
            { wch: 13 },
            { wch: 10 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
            { wch: 7 },
        ];

        const row = [{ hpt: 70 }, { hpt: 60 }, {}];

        let lengthList = 0;

        this.state.quantitative.time?.map(timeItem => {
            lengthList += timeItem.detail.filter(item => item.ingrendent !== undefined).length;
            return timeItem;
        })

        const intLen = 5;
        for (let i = 0; i < lengthList; i++) {
            row.push({});

            colAlpha.map(alpha => {
                if (ws2[`${alpha}${intLen + i}`].v === "empty") {
                    ws2[`${alpha}${intLen + i}`].v = ' '
                }

                if (alpha === "E" || alpha === "F" || alpha === "G" || alpha === "I" || alpha === "J") {
                    ws2[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                } else if (alpha === "B" || alpha === "C" || alpha === "D" || alpha === "H") {
                    ws2[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "left"
                        }
                    };
                } else {
                    ws2[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                }

                return alpha;
            })

            if (intLen === intLen + i) {
                const str = ws2[`H${intLen + i}`.toString()].v;
                ws2[`H${intLen + i}`.toString()].v = str.split("delete").pop();
            }

            const str = ws2[`G${intLen + i}`.toString()].v;
            ws2[`G${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "")

            const str1 = ws2[`F${intLen + i}`.toString()].v;
            ws2[`F${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "")
        }

        row.push({}, { hpt: 60 });

        ws2['!rows'] = row;

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
            { s: { r: 0, c: 6 }, e: { r: 0, c: 12 } },
            // Title

            // Header
            { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
            { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
            { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
            { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
            { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
            { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
            { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

            { s: { r: 1, c: 7 }, e: { r: 1, c: 9 } },
            { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },

            { s: { r: 1, c: 12 }, e: { r: 2, c: 12 } },
            // Header

            // Footer
            { s: { r: 4 + lengthList, c: 0 }, e: { r: 4 + lengthList, c: 3 } },
            { s: { r: 4 + lengthList, c: 4 }, e: { r: 4 + lengthList, c: 8 } },
            { s: { r: 4 + lengthList, c: 9 }, e: { r: 4 + lengthList, c: 12 } },
            // Footer
        ];
        ws2["!merges"] = merge;

        colAlpha.map(alpha => {
            ws2[`${alpha}${4 + lengthList + 1}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "top",
                    horizontal: "center"
                }
            };

            return alpha;
        })

        return ws2;
    }

    book3 = () => {
        const table3 = document.getElementById("book-step-3");

        var ws3 = XLSX.utils.table_to_sheet(table3, { raw: true })

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

        const colAlphaBook3_Footer = ["A", "D", "H"];

        ws3[`A1`].s = {
            font: {
                name: "Times New Roman",
            },
            alignment: {
                wrapText: true,
                vertical: "top",
                horizontal: "left"
            }
        };

        ws3[`G1`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "top",
                horizontal: "left"
            }
        };

        colAlpha.map((alpha) => {

            ws3[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    wrapText: true,
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws3[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    wrapText: true,
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        colAlpha.map((alpha) => {

            ws3[`${alpha}4`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    wrapText: true,
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws3['!cols'] = [
            { wch: 3.5 },
            { wch: 10 },
            { wch: 15 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 25 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
        ];

        let lengthList = 0;

        this.state.quantitative.time?.map(timeItem => {
            lengthList += timeItem.detail.filter(item => item.ingrendent !== undefined).length;
            return timeItem;
        })

        const row = [{ hpt: 70 }, { hpt: 60 }, {}];

        const intLen = 5;

        for (let i = 0; i < lengthList; i++) {
            row.push({});

            colAlpha.map(alpha => {
                if (ws3[`${alpha}${intLen + i}`].v === "empty") {
                    ws3[`${alpha}${intLen + i}`].v = ' '
                }

                if (alpha === "D") {
                    ws3[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                } else if (alpha === "B" || alpha === "C" || alpha === "E" || alpha === "F" || alpha === "G") {
                    ws3[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "left"
                        }
                    };
                } else {
                    ws3[`${alpha}${intLen + i}`].s = {
                        font: {
                            name: "Times New Roman",
                        },
                        border: {
                            top: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                            right: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            vertical: "center",
                            horizontal: "center"
                        }
                    };
                }

                return alpha;
            })

            const str = ws3[`B${intLen + i}`.toString()].v;
            ws3[`B${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "")

            const str1 = ws3[`E${intLen + i}`.toString()].v;
            ws3[`E${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "")

            const str2 = ws3[`F${intLen + i}`.toString()].v;
            ws3[`F${intLen + i}`.toString()].v = str2.replaceAll("Selected time:", "")
        }

        row.push({}, { hpt: 60 });

        ws3['!rows'] = row;

        const merge = [
            // Title
            { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
            { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
            // Title

            // Header
            { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
            { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
            { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
            { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
            { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
            { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
            { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

            { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },

            { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
            // Header

            // Footer
            { s: { r: 4 + lengthList, c: 0 }, e: { r: 4 + lengthList, c: 2 } },
            { s: { r: 4 + lengthList, c: 3 }, e: { r: 4 + lengthList, c: 6 } },
            { s: { r: 4 + lengthList, c: 7 }, e: { r: 4 + lengthList, c: 9 } },
            // Footer
        ];
        ws3["!merges"] = merge;

        colAlphaBook3_Footer.map(alpha => {
            ws3[`${alpha}${4 + lengthList + 1}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "top",
                    horizontal: "center"
                }
            };

            return alpha;
        })

        return ws3;
    }

    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day)
        });

        this.getQuantitativeVoucher(this.state.start, this.state.term, day);
    }

    handleExport = () => {
        swal({
            title: `Xác nhận`,
            text: `Bạn muốn export file sổ 3 bước`,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }

    dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    setDate = (theDate) => {
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length - 1]
        })

        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

    group = (arr, key) => {
        return [...arr.reduce((acc, o) =>
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
            , new Map()).values()];
    }
    //#endregion

    // Render
    RenderDayOption() {
        return (
            <>
                <Card.Title>Chọn ngày điền sổ 3 bước</Card.Title>

                <div className="d-none d-md-flex justify-content-start my-3">
                    {
                        this.state.listDay.map((dayItem, i) => {
                            let toDayIs = dayItem.id % 7;

                            if (toDayIs === 0) {
                                toDayIs = 7;
                            }

                            return (
                                <div key={i} className="d-flex me-md-4 me-xl-5">
                                    <Form.Check
                                        type="radio"
                                        value={dayItem.id}
                                        checked={this.state.daySelect === dayItem.id}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleCheck(e.target.value);
                                            }
                                        }}
                                        name="day"
                                        className="me-2"
                                    />
                                    {dayItem.day_name}
                                </div>

                            );
                        })}
                </div>

                <Row className="my-3 d-md-none">
                    <Col>
                        {this.state.listDay
                            .slice(
                                0,
                                Math.round(this.state.listDay.length / 2)
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>

                    <Col>
                        {this.state.listDay
                            .slice(
                                Math.round(this.state.listDay.length / 2,
                                    this.state.listDay.length
                                )
                            )
                            .map((dayItem, i) => {

                                let toDayIs = dayItem.id % 7;

                                if (toDayIs === 0) {
                                    toDayIs = 7;
                                }

                                return (
                                    <Row key={i}
                                    >
                                        <Col xs={1} md={1}>
                                            <div className="text-center">
                                                <Form.Check
                                                    type="radio"
                                                    value={dayItem.id}
                                                    checked={this.state.daySelect === dayItem.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.handleCheck(e.target.value);
                                                            return;
                                                        }
                                                    }}
                                                    name="dayM"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={10} md={6}>
                                            {dayItem.day_name}
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </>
        )
    }

    RenderBook1() {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (!this.state.quantitative) {
            return (
                <div className="d-flex justify-content-center mt-3">
                    Không có dữ liệu
                </div>
            )
        }

        let allIngredient = [];

        this.state.quantitative?.time?.map(timeItem => {
            return timeItem.detail.map(foodItem => {
                return foodItem.ingrendent.map(ingredientItem => {

                    allIngredient.push({
                        ...ingredientItem,
                        group: ingredientItem.group?.map(groupItem => {
                            return {
                                ...groupItem,
                                count_student: Number(timeItem.is_calcultor?.findLast(calculator => calculator.id_group === groupItem.id)?.count_student || 0)
                            }
                        })
                    })

                    return ingredientItem;
                })
            })
        })

        const aIngredientArray = this.group(allIngredient, "id_ingredent");

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr>
                            <th colSpan={15} className="text-center">Phục lục 1: MẪU BIỂU GHI CHÉP KIỂM THỰC BA BƯỚC</th>
                        </tr>
                        <tr>
                            <td className="align-top" colSpan={8}>
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <th className="align-top" colSpan={7}>
                                Mẫu số 1: Kiểm tra trước khi chế biến thức ăn (Bước 1)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* I */}
                        <tr>
                            <th colSpan={15}>I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle" rowSpan={2}>TT</td>
                            <td className="align-middle" rowSpan={2}>Tên thực phẩm</td>
                            <td className="align-middle" rowSpan={2}>Thời gian nhập (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>Khối lượng (kg/lít...)</td>
                            <td className="align-middle" colSpan={3}>
                                Nơi cung cấp
                            </td>
                            <td className="align-middle" rowSpan={2}>Chứng từ, hóa đơn</td>
                            <td className="align-middle" rowSpan={2}>Giấy ĐK VS thú y</td>
                            <td className="align-middle" rowSpan={2}>Giấy kiểm dịch</td>
                            <td className="align-middle" colSpan={2}>Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td className="align-middle" colSpan={2}>Xét nghiệm nhanh (nếu có) (vi sinh, hóa lý)</td>
                            <td className="align-middle" rowSpan={2}>
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Tên cơ sở</td>
                            <td className="align-middle">Địa chỉ, điện thoại</td>
                            <td className="align-middle">Tên người giao hàng</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                            <td className="align-middle">(14)</td>
                            <td className="align-middle">(15)</td>
                        </tr>
                        {
                            aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 1).length > 0
                                ?
                                aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 1).map((aIngredientItem, index) => {
                                    let amountMarket = 0;

                                    aIngredientItem.map(ingredientItem => {
                                        ingredientItem.group.map(groudpItem => {
                                            if (ingredientItem.unit.is_root === 0) {
                                                amountMarket += (Number(groudpItem.amount) * Number(groudpItem.count_student)) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                            } else {
                                                amountMarket += Number(groudpItem.amount) * Number(groudpItem.count_student);
                                            }

                                            return groudpItem;
                                        })

                                        return ingredientItem;
                                    });

                                    if (amountMarket === 0) {
                                        return "";
                                    }

                                    return (
                                        <tr key={aIngredientItem[0].id_ingredent} className="align-middle">
                                            <td className="text-center">{index + 1}</td>
                                            <td >{aIngredientItem[0].ingredent_name}</td>
                                            <td className="text-center">
                                                {moment(this.state.formSetting.step1_m1_3).format("HH:mm")}
                                            </td>
                                            <td>
                                                {formatter.format(amountMarket)} {this.state.listUnit?.filter(unitItem => Number(unitItem.id) === Number(aIngredientItem[0].unit.id_unit))[0]?.unit_name}
                                            </td>
                                            <td>{aIngredientItem[0].supplier.supplier_name}</td>
                                            <td className="text-center">{aIngredientItem[0].supplier.supplier_phone}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    );

                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={15}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }

                        {/* II */}
                        <tr>
                            <th colSpan={15}>II. Thực phẩm khô và thực gói sẵn, phụ gia thực phẩm</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle" rowSpan={2}>TT</td>
                            <td className="align-middle" rowSpan={2}>Tên thực phẩm</td>
                            <td className="align-middle" rowSpan={2}>Tên cơ sở sản xuất</td>
                            <td className="align-middle" rowSpan={2}>Địa chỉ sản xuất</td>
                            <td className="align-middle" rowSpan={2}>Thời gian nhập (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>Khối lượng (kg/lít...)</td>
                            <td className="align-middle" colSpan={3}>
                                Nơi cung cấp
                            </td>
                            <td className="align-middle" rowSpan={2}>Hạn sử dụng</td>
                            <td className="align-middle" rowSpan={2}>Điều kiện bảo quản (T° thường, lạnh...)</td>
                            <td className="align-middle" rowSpan={2}>Chứng từ, hóa đơn</td>


                            <td className="align-middle" colSpan={2}>Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td className="align-middle" rowSpan={2}>
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Tên cơ sở</td>
                            <td className="align-middle">Tên chủ giao hàng</td>
                            <td className="align-middle">Địa chỉ, điện thoại</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                            <td className="align-middle">(14)</td>
                            <td className="align-middle">(15)</td>
                        </tr>

                        {
                            aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 2).length > 0
                                ?
                                aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 2)
                                    .map((aIngredientItem, index) => {
                                        let amountMarket = 0;
                                        aIngredientItem.map(ingredientItem => {
                                            ingredientItem.group.map(groudpItem => {
                                                if (ingredientItem.unit.is_root === 0) {
                                                    amountMarket += (Number(groudpItem.amount) * Number(groudpItem.count_student)) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                                } else {
                                                    amountMarket += Number(groudpItem.amount) * Number(groudpItem.count_student);
                                                }
                                                return groudpItem;
                                            })
                                            return ingredientItem;
                                        })

                                        if (amountMarket === 0) {
                                            return ""
                                        }

                                        return (
                                            <tr key={aIngredientItem[0].id_ingredent} className="align-middle">
                                                <td className="text-center ">{index + 1}</td>
                                                <td >{aIngredientItem[0].ingredent_name}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{moment(this.state.formSetting.step1_m2_5).format("HH:mm")}</td>
                                                <td>
                                                    {formatter.format(amountMarket)} {this.state.listUnit?.filter(unitItem => Number(unitItem.id) === Number(aIngredientItem[0].unit.id_unit))[0]?.unit_name}
                                                </td>
                                                <td>{aIngredientItem[0].supplier.supplier_name}</td>
                                                <td>{aIngredientItem[0].supplier.supplier_author}</td>
                                                <td>{aIngredientItem[0].supplier.supplier_phone}</td>
                                                <td>
                                                    {aIngredientItem[0].inventory.expriry_date ? this.stringLastDate(new Date(Number(aIngredientItem[0].inventory.expriry_date) * 1000)) : ""}
                                                </td>
                                                <td className="text-start">{this.state.formSetting.step1_m2_11}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                :
                                <tr>
                                    <td className="text-center" colSpan={15}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td colSpan={5}>Ban giám hiệu</td>
                            <td colSpan={5}>Tổ trưởng kiểm thực</td>
                            <td colSpan={5}>Người kiểm tra</td>
                        </tr>
                    </tfoot>
                </Table>

                <Table bordered id="book-step-1" className="d-none">
                    <thead>
                        <tr>
                            <th className="text-center">Phục lục 1: MẪU BIỂU GHI CHÉP KIỂM THỰC BA BƯỚC</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr>
                            <td className="align-top">
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <th className="align-top">
                                Mẫu số 1: Kiểm tra trước khi chế biến thức ăn (Bước 1)
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>

                        </tr>
                    </thead>
                    <tbody>
                        {/* I */}
                        <tr>
                            <th>I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Tên thực phẩm</td>
                            <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
                            <td className="align-middle">Khối lượng (kg/lít...)</td>
                            <td className="align-middle">
                                Nơi cung cấp
                            </td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Chứng từ, hóa đơn</td>
                            <td className="align-middle">Giấy ĐK VS thú y</td>
                            <td className="align-middle">Giấy kiểm dịch</td>
                            <td className="align-middle">Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td>empty</td>

                            <td className="align-middle">Xét nghiệm nhanh (nếu có) (vi sinh, hóa lý)</td>
                            <td>empty</td>

                            <td className="align-middle">
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Tên cơ sở</td>
                            <td className="align-middle">Địa chỉ, điện thoại</td>
                            <td className="align-middle">Tên người giao hàng</td>

                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>

                            <td>empty</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                            <td className="align-middle">(14)</td>
                            <td className="align-middle">(15)</td>
                        </tr>

                        {
                            aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 1).length > 0
                                ?
                                aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 1).map((aIngredientItem, index) => {

                                    let amountMarket = 0;

                                    aIngredientItem.map(ingredientItem => {
                                        ingredientItem.group.map(groudpItem => {
                                            if (ingredientItem.unit.is_root === 0) {
                                                amountMarket += (Number(groudpItem.amount) * Number(groudpItem.count_student)) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                            } else {
                                                amountMarket += Number(groudpItem.amount) * Number(groudpItem.count_student);
                                            }
                                            return groudpItem;
                                        })
                                        return ingredientItem;
                                    })

                                    return (
                                        <tr key={aIngredientItem[0].id_ingredent} className="align-middle">
                                            <td className="text-center">{index + 1}</td>
                                            <td >{aIngredientItem[0].ingredent_name}</td>
                                            <td className="text-center">
                                                {moment(this.state.formSetting.step1_m1_3).format("HH:mm")}
                                            </td>
                                            <td>
                                                {formatter.format(amountMarket)} {this.state.listUnit?.filter(unitItem => Number(unitItem.id) === Number(aIngredientItem[0].unit.id_unit))[0]?.unit_name}
                                            </td>
                                            <td>{aIngredientItem[0].supplier.supplier_name}</td>
                                            <td className="text-center">{aIngredientItem[0].supplier.supplier_phone}</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={15}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }

                        {/* II */}
                        <tr>
                            <th>II. Thực phẩm khô và thực gói sẵn, phụ gia thực phẩm</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Tên thực phẩm</td>
                            <td className="align-middle">Tên cơ sở sản xuất</td>
                            <td className="align-middle">Địa chỉ sản xuất</td>
                            <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
                            <td className="align-middle">Khối lượng (kg/lít...)</td>
                            <td className="align-middle">
                                Nơi cung cấp
                            </td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Hạn sử dụng</td>
                            <td className="align-middle">Điều kiện bảo quản (T° thường, lạnh...)</td>
                            <td className="align-middle">Chứng từ, hóa đơn</td>


                            <td className="align-middle">Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td>empty</td>

                            <td className="align-middle">
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Tên cơ sở</td>
                            <td className="align-middle">Tên chủ giao hàng</td>
                            <td className="align-middle">Địa chỉ, điện thoại</td>

                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>

                            <td>empty</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                            <td className="align-middle">(14)</td>
                            <td className="align-middle">(15)</td>
                        </tr>

                        {
                            aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 2).length > 0
                                ?
                                aIngredientArray.filter(aIngredientItem => aIngredientItem[0].ingredent_type === 2).map((aIngredientItem, index) => {
                                    let amountMarket = 0;

                                    aIngredientItem.map(ingredientItem => {
                                        ingredientItem.group.map(groudpItem => {
                                            if (ingredientItem.unit.is_root === 0) {
                                                amountMarket += (Number(groudpItem.amount) * Number(groudpItem.count_student)) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                            } else {
                                                amountMarket += Number(groudpItem.amount) * Number(groudpItem.count_student);
                                            }
                                            return groudpItem;
                                        })
                                        return ingredientItem;
                                    })

                                    return (
                                        <tr key={aIngredientItem[0].id_ingredent} className="align-middle">
                                            <td className="text-center ">{index + 1}</td>
                                            <td >{aIngredientItem[0].ingredent_name}</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>{moment(this.state.formSetting.step1_m2_5).format("HH:mm")}</td>
                                            <td>
                                                {formatter.format(amountMarket)} {this.state.listUnit?.filter(unitItem => Number(unitItem.id) === Number(aIngredientItem[0].unit.id_unit))[0]?.unit_name}
                                            </td>
                                            <td>{aIngredientItem[0].supplier.supplier_name}</td>
                                            <td>{aIngredientItem[0].supplier.supplier_author}</td>
                                            <td>{aIngredientItem[0].supplier.supplier_phone}</td>
                                            <td>
                                                {aIngredientItem[0].inventory.expriry_date ? this.stringLastDate(new Date(Number(aIngredientItem[0].inventory.expriry_date) * 1000)) : "empty"}
                                            </td>
                                            <td className="text-start">{this.state.formSetting.step1_m2_11}
                                            </td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                            <td>empty</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={15}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td>Ban giám hiệu</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Tổ trưởng kiểm thực</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Người kiểm tra</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }

    RenderBook2() {

        if (!this.state.quantitative) {
            return (
                <div className="d-flex justify-content-center mt-3">
                    Không có dữ liệu
                </div>
            )
        }

        let foodAmount = 0;

        this.state.quantitative.time?.map(timeItem => {
            foodAmount += timeItem.detail.filter(item => item.ingrendent !== undefined).length;
            return timeItem;
        })

        let bookIndex = 0;
        let bookIndex1 = 0;

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr>
                            <td className="align-top" colSpan={6}>
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <th className="align-top" colSpan={7}>
                                Mẫu số 2: Kiểm tra khi chế biến thức ăn (Bước 2)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle" rowSpan={2}>TT</td>
                            <td className="align-middle" rowSpan={2}>Ca/bữa ăn (Bữa ăn, giờ ăn)</td>
                            <td className="align-middle" rowSpan={2}>Tên món ăn</td>
                            <td className="align-middle" rowSpan={2}>Nguyên liệu chính để chế biến (tên, số lượng...)</td>
                            <td className="align-middle" rowSpan={2}>Số lượng/số suất ăn</td>
                            <td className="align-middle" rowSpan={2}>Thời gian sơ chế xong (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>Thời gian chế biến xong (ngày, giờ)</td>
                            <td className="align-middle" colSpan={3}>
                                Kiểm tra điều kiện vệ sinh (từ thời điểm bắt đầu sơ chế, chế biến cho đến khi thức ăn được chế biến xong)
                            </td>
                            <td className="align-middle" colSpan={2}>Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td className="align-middle" rowSpan={2}>
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Người tham gia chế biến</td>
                            <td className="align-middle">Trang thiết bị dụng cụ</td>
                            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                        </tr>

                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map((timeItem) => {

                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.filter(item => item.ingrendent !== undefined).map((foodItem, index) => {
                                                    bookIndex++;
                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }

                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;

                                                        countStudent += parseInt(studentAmount);

                                                        return group;
                                                    });
                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{bookIndex}</td>
                                                            <td className="text-start">
                                                                {
                                                                    index !== 0 ? "" : timeItem.time_name
                                                                }
                                                            </td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td>
                                                                {
                                                                    this.state.quantitative.idGroup.map(group => {
                                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                                            return "";
                                                                        }

                                                                        return (
                                                                            <div key={group.id}>
                                                                                <div className="fw-bold">
                                                                                    {group.group_name}:
                                                                                    <br />
                                                                                </div>

                                                                                {
                                                                                    foodItem.ingrendent.map(ingreItem => {
                                                                                        return (
                                                                                            <div key={ingreItem.id_ingredent}>
                                                                                                {ingreItem.ingredent_name} {ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount}{ingreItem.unit.unit_name}
                                                                                                <br />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {countStudent}
                                                            </td>
                                                            <td className="text-center">{`${moment(this.state.formSetting.step2_6?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center">{`${moment(this.state.formSetting.step2_7?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-start">
                                                                {
                                                                    this.state.formSetting.step2_8?.map(user => {
                                                                        return (
                                                                            <div key={user.value}>
                                                                                {user.label}
                                                                                <br />
                                                                                <br className="d-none" />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="text-center">{this.state.formSetting.step2_9}</td>
                                                            <td className="text-center">{this.state.formSetting.step2_10}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={13}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td colSpan={4}>Ban giám hiệu</td>
                            <td colSpan={5}>Tổ trưởng kiểm thực</td>
                            <td colSpan={4}>Người kiểm tra</td>
                        </tr>
                    </tfoot>
                </Table>

                <Table bordered id="book-step-2" className="d-none">
                    <thead>
                        <tr>
                            <td className="align-top">
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <th className="align-top">
                                Mẫu số 2: Kiểm tra khi chế biến thức ăn (Bước 2)
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn)</td>
                            <td className="align-middle">Tên món ăn</td>
                            <td className="align-middle">Nguyên liệu chính để chế biến (tên, số lượng...)</td>
                            <td className="align-middle">Số lượng/số suất ăn</td>
                            <td className="align-middle">Thời gian sơ chế xong (ngày, giờ)</td>
                            <td className="align-middle">Thời gian chế biến xong (ngày, giờ)</td>
                            <td className="align-middle">
                                Kiểm tra điều kiện vệ sinh (từ thời điểm bắt đầu sơ chế, chế biến cho đến khi thức ăn được chế biến xong)
                            </td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td>empty</td>

                            <td className="align-middle">
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Người tham gia chế biến</td>
                            <td className="align-middle">Trang thiết bị dụng cụ</td>
                            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                            <td>empty</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                            <td className="align-middle">(13)</td>
                        </tr>

                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map((timeItem) => {
                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.filter(item => item.ingrendent !== undefined).map((foodItem, index) => {
                                                    bookIndex1++;

                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }

                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;
                                                        countStudent += parseInt(studentAmount);
                                                        return group;
                                                    });

                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{bookIndex1}</td>
                                                            <td className="text-start">
                                                                {
                                                                    index !== 0 ? "empty" : timeItem.time_name
                                                                }
                                                            </td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td>
                                                                {
                                                                    this.state.quantitative.idGroup.map(group => {
                                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                                            return " ";
                                                                        }

                                                                        return (
                                                                            <div key={group.id}>
                                                                                <div className="fw-bold">
                                                                                    {group.group_name}:
                                                                                    <br />
                                                                                </div>

                                                                                {
                                                                                    foodItem.ingrendent.map(ingreItem => {
                                                                                        return (
                                                                                            <div key={ingreItem.id_ingredent}>
                                                                                                {ingreItem.ingredent_name} {ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount}{ingreItem.unit.unit_name}
                                                                                                <br />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="text-center">{countStudent}</td>
                                                            <td className="text-center">{`${moment(this.state.formSetting.step2_6?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center">{`${moment(this.state.formSetting.step2_7?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-start">
                                                                {
                                                                    this.state.formSetting.step2_8?.map(user => {
                                                                        return (
                                                                            <div key={user.value}>
                                                                                {user.label}
                                                                                <br />
                                                                                <br className="d-none" />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="text-center">{this.state.formSetting.step2_9}</td>
                                                            <td className="text-center">{this.state.formSetting.step2_10}</td>
                                                            <td>empty</td>
                                                            <td>empty</td>
                                                            <td>empty</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={13}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td>Ban giám hiệu</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Tổ trưởng kiểm thực</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Người kiểm tra</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }

    RenderBook3() {
        if (!this.state.quantitative) {
            return (
                <div className="d-flex justify-content-center mt-3">
                    Không có dữ liệu
                </div>
            )
        }

        let foodAmount = 0;

        this.state.quantitative.time?.map(timeItem => {
            foodAmount += timeItem.detail.filter(item => item.ingrendent !== undefined).length;
            return timeItem;
        })

        let bookIndex = 0;
        let bookIndex1 = 0;

        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr>
                            <td className="align-top" colSpan={6}>
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <th className="align-top" colSpan={4}>
                                Mẫu số 3: Kiểm tra trước khi ăn (Bước 3)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle" rowSpan={2}>TT</td>
                            <td className="align-middle" rowSpan={2}>Ca/bữa ăn (Bữa ăn, giờ ăn...)</td>
                            <td className="align-middle" rowSpan={2}>Tên món ăn</td>
                            <td className="align-middle" rowSpan={2}>Số lượng suất ăn</td>
                            <td className="align-middle" rowSpan={2}>Thời gian chia món ăn xong (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>Thời gian bắt đầu ăn (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>
                                Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
                            </td>
                            <td className="align-middle" colSpan={2}>Kiểm tra cảm quan món ăn (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td className="align-middle" rowSpan={2}>
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                        </tr>
                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map(timeItem => {
                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.filter(item => item.ingrendent !== undefined).map((foodItem) => {
                                                    bookIndex++;
                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }

                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;
                                                        countStudent += parseInt(studentAmount);
                                                        return group;
                                                    });

                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{bookIndex}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_2?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td className="text-center">
                                                                {countStudent}
                                                            </td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_5?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_6?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td>{this.state.formSetting.step3_7}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={10}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td colSpan={3}>Ban giám hiệu</td>
                            <td colSpan={4}>Tổ trưởng kiểm thực</td>
                            <td colSpan={3}>Người kiểm tra</td>
                        </tr>
                    </tfoot>
                </Table>

                <Table bordered id="book-step-3" className="d-none">
                    <thead>
                        <tr>
                            <td className="align-top">
                                Tên cơ sở: {this.state.schoolInfo.school_name}
                                <br />
                                Người kiểm tra: .............................................................................
                                <br />
                                Thời gian kiểm tra: ngày {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getDate()} tháng {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getMonth() + 1} năm {this.state.listDay.filter(item => item.id === this.state.daySelect)[0]?.day.getFullYear()}
                                <br />
                                Địa điểm kiểm tra:..........................................................................
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <th className="align-top">
                                Mẫu số 3: Kiểm tra trước khi ăn (Bước 3)
                            </th>
                            <th>empty</th>
                            <th>empty</th>
                            <th>empty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="align-middle">TT</td>
                            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn...)</td>
                            <td className="align-middle">Tên món ăn</td>
                            <td className="align-middle">Số lượng suất ăn</td>
                            <td className="align-middle">Thời gian chia món ăn xong (ngày, giờ)</td>
                            <td className="align-middle">Thời gian bắt đầu ăn (ngày, giờ)</td>
                            <td className="align-middle">
                                Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
                            </td>
                            <td className="align-middle">Kiểm tra cảm quan món ăn (màu, mùi, vị, trạng thái, bảo quản...)</td>
                            <td>empty</td>

                            <td className="align-middle">
                                Biện pháp xử lý/ Ghi chú
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td className="align-middle">Đạt</td>
                            <td className="align-middle">Không đạt</td>

                            <td>empty</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(1)</td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(4)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                        </tr>
                        {
                            this.state.quantitative && foodAmount > 0
                                ?
                                this.state.quantitative.time?.map(timeItem => {
                                    return (
                                        <React.Fragment key={timeItem.id_time}>
                                            {
                                                timeItem.detail.filter(item => item.ingrendent !== undefined).map((foodItem) => {
                                                    bookIndex1++;

                                                    let countStudent = 0;
                                                    this.state.quantitative.idGroup.map(group => {
                                                        if (foodItem.ingrendent.some(ingreItem => !ingreItem.group.filter(item => Number(item.id) === Number(group.id))[0]?.amount)) {
                                                            return "";
                                                        }

                                                        let studentAmount = timeItem.is_calcultor.find(calculator => Number(calculator.id_group) === Number(group.id))?.count_student;
                                                        countStudent += parseInt(studentAmount);
                                                        return group;
                                                    });

                                                    return (
                                                        <tr key={foodItem.id_food} className="align-middle">
                                                            <td>{bookIndex1}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_2?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-start">{foodItem.food_name}</td>
                                                            <td className="text-center">{countStudent}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_5?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td className="text-center">{`${timeItem.time_name} ${moment(this.state.formSetting.step3_6?.find(timeObj => Number(timeObj.time) === Number(timeItem.id_time))?.val).format("HH:mm")}`}</td>
                                                            <td>{this.state.formSetting.step3_7}</td>
                                                            <td>empty</td>
                                                            <td>empty</td>
                                                            <td>empty</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td className="text-center" colSpan={10}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr className="text-center" style={{ height: 50 }}>
                            <td>Ban giám hiệu</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Tổ trưởng kiểm thực</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            <td>Người kiểm tra</td>
                            <td>empty</td>
                            <td>empty</td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
                    <Breadcrumb.Item active>Sổ 3 bước</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-sm-flex justify-content-between">
                            <div className="d-block d-sm-flex mb-3 mb-sm-0">
                                <Card.Title className="me-3 pt-1">
                                    <i className="fas fa-list me-1"></i> Sổ 3 bước
                                    Tháng {this.state.start.getMonth() + 1} (Tuần {this.state.weekInMonth}) {this.stringFirstDate(this.state.start)} - {this.stringLastDate(this.state.term)}
                                </Card.Title>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(e.target.valueAsNumber);
                                                const week = this.setDate(firstDayOfWeek);

                                                const newListDay = initialDay.map((day, index) => {
                                                    return {
                                                        id: day.id,
                                                        day_name: day.day_name,
                                                        day: week[index]
                                                    }
                                                })

                                                this.setState({
                                                    weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                    listDay: newListDay
                                                })

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1], this.state.daySelect);

                                            } else {
                                                const today = new Date();
                                                const week = this.setDate(today);

                                                const newListDay = initialDay.map((day, index) => {
                                                    return {
                                                        id: day.id,
                                                        day_name: day.day_name,
                                                        day: week[index]
                                                    }
                                                })

                                                this.setState({
                                                    weekInMonth: (0 | week[0].getDate() / 7) + 1,
                                                    listDay: newListDay
                                                })

                                                this.getQuantitativeVoucher(week[0], week[week.length - 1], this.state.daySelect);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <Button variant="success" size="sm" onClick={() => {
                                let lengthList = 0;

                                this.state.quantitative?.time?.map(timeItem => {
                                    lengthList += timeItem.detail.filter(item => item.ingrendent !== undefined).length;
                                    return timeItem;
                                })

                                if (!this.state.quantitative || lengthList === 0) {
                                    toast("Sổ bước 1, 2, 3 đang không có dữ liệu", { type: "error", autoClose: 2000 });
                                    return;
                                }

                                this.handleExport();
                            }}>
                                Xuất file <i className="fa-solid fa-file-export"></i>
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {this.RenderDayOption()}

                        {
                            !this.state.isLoading
                                ?
                                <Tabs
                                    defaultActiveKey="1"
                                >
                                    <Tab eventKey="1" title="Bước 1">
                                        {this.RenderBook1()}
                                    </Tab>

                                    <Tab eventKey="2" title="Bước 2">
                                        {this.RenderBook2()}
                                    </Tab>

                                    <Tab eventKey="3" title="Bước 3">
                                        {this.RenderBook3()}
                                    </Tab>
                                </Tabs>
                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải sổ 3 bước...</span>
                                    </div>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default ThreeStepBook;