import React, { Component } from "react";
import {
	Breadcrumb,
	Button,
	Card,
	Container,
	Table, Modal
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import swal from "sweetalert";

class Report extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classifies: [],
			cutMeals: [],
			cutMealDetail: [],
			cutMealDetailShow: [],
			classifyShow: 1,
			listStaff: [],
			listAbsent: [],
			listcutStudent: [],
			toDay: new Date(),
			listSetting: [],
			setting: [],
			showCutMealDetail: false, listcut: [],
			list_: [],
			listStudent: [],
			studentss: [],
			startDate: new Date(),
			endDate: new Date(), show: false,
			start_: new Date(),
			end_: new Date(),
			listClass: [],
			tongAll: 0, tongdihoc: 0, tongcoan: 0, tongkhongan: 0,
			totalnghi: 0, totalkhongphep: 0,
			totalcophep: 0, tongsang: 0, tongchieu: 0, tongtrua: 0, count: [], checkload: true,
			datecut: []
		};
	}

	async componentDidMount() {
		document.title = "Thống kê sỉ số";
		this.getClassifiesData();


		this.getClassRoom(new Date(), new Date())
		this.getSetting(this.state.classifyShow);

		const start = new Date();
		start.setHours(0, 0, 0, 0);

		const end = new Date();
		end.setHours(23, 59, 59, 0);

		this.setState({
			startDate: start,
			endDate: end
		});
		// this.getListMealstudent()
	}
	getSetting = (classifyShow) => {
		axios.get(`/viewSetting?id_classify=${classifyShow}`).then((res) => {
			res.data.data.time.map(e => { e.count = 0 })
			this.setState({ listSetting: res.data.data.time.filter(e => e.is_active == 1) })
			this.setState({ setting: res.data.data.day.filter(e => e.is_active == 1) })
		})
	}
	checkSpicialDate = (date) => {
		const day = date.getDay();
		var listDay = []
		this.state.setting.map((item) => {
			if (item.is_active == 1) {
				if (
					item.stt_day == 7
				) { listDay.push(0) }
				else { listDay.push(item.stt_day) }
			}
			return item
		})
		return listDay.includes(day)
	}
	getDate_ = (data) => {
		let date = new Date(data)
		let result = `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`;
		return result;
	}
	getDate = (data) => {
		let date = new Date(data * 1000);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}
	getTong = () => {
		var tong = 0
		var tongOneDay = 0;
		this.state.listSetting.map(item => item.is_active == 1 ?
			(tongOneDay = tongOneDay + parseInt(item.poetry)
			)
			: null
		)
		this.state.listStudent.map(
			(student_, index) => {
				student_.absent.map((absent_, index1) => {
					if (absent_.type_meal == 1 && absent_.is_term != 0) tong = tong + absent_.price_total
					else {
						if (absent_.is_term != 0) {
							absent_.detail.map(item =>
								tong = parseInt(tong) + parseInt(item.price_meal)
								// tong = parseInt(tong) + parseInt(this.state.listSetting.filter(e => e.time_name == item.time_name)[0]?.poetry)
							)
						}
					}
				}
				)
			}
		)
		return tong

	}
	getClassRoom = (start, end) => {
		axios.get(`/viewClassRoom?start=${start.setHours(0, 0, 0) / 1000}&end=${end.setHours(23, 59, 59) / 1000}`).then(res => {
			// ?start=${new this.state.startDate.setHours(0, 0, 0) / 1000}&end=${this.state.endDate.setHours(23, 59, 59) / 1000}
			if (res.data.status === true) {
				this.setState({ listClass: res.data.data })
				this.getListMealstudent(this.state.startDate, this.state.endDate);
			}

		})
	}
	getListMealstudent(start, end) {
		this.setState({ checkload: true })
		let start_ = start.setHours(0, 0, 0) / 1000;
		let end_ = end.setHours(23, 59, 59) / 1000;
		axios
			.post(`/viewMeal?id_classify=1&start_date=${start_}&end_date=${end_}`)
			.then((res) => {
				if (res.data.status) {
					const dateFilter = res.data.data.filter(absentDay => start_ <= absentDay.meal_day && absentDay.meal_day <= end_ && absentDay.is_status == 1);
					const nghinguyenngay = dateFilter.length > 0 ? dateFilter.filter(e => e.type_meal == 1) : [];
					const nghibuoi = dateFilter.length > 0 ? dateFilter.filter(e => e.type_meal == 2) : [];
					// const hskhongan 		= this.state.studentss.length > 0 ? this.state.studentss.filter(e => e.is_meal == 1) : [];
					let listClass = [];
					let count1 = [];
					var tongDs = 0;
					var tongDihoc = 0;
					var tongCoan = 0;
					var tongKhongan = 0;
					var tongNghi = 0;
					var tongKhongphep = 0;
					var tongCophep = 0;
					let betweenDay = parseInt((end - start) / (24 * 60 * 60 * 1000) + 1)
					this.state.listClass.map(e => {
						var count_student = e.count_student;
						let day_default = Number(start.getDay());
						var dihoc = 0;
						var coan = 0;
						var khongan = e.not_eat ?? 0;
						const arr = [];
						var nghi = nghinguyenngay.filter(el => el.class_name == e.class_name).length;
						var khongphep = nghinguyenngay.filter(el => el.class_name == e.class_name && el.is_term == 0).length;
						var cophep = nghinguyenngay.filter(el => el.class_name == e.class_name && el.is_term == 1).length;
						for (var i = 0; i < betweenDay; i++) {
							let checkday = this.state.setting.filter(z => z.stt_day == day_default);
							if (checkday.length > 0) {
								// khongan					+= hskhongan.filter(el => el.class_name == e.class_name).length;
								const listMealByBuoi = nghibuoi.filter(el => el.class_name == e.class_name);
								dihoc += count_student;
								this.state.listSetting.map(time => {
									let moreDay = 0;
									let sumDay = 0;
									listMealByBuoi.map(meal => { moreDay += meal.detail.filter(buoi_ => time.time_name == buoi_.time_name).length; })
									nghibuoi.map(meal => { sumDay += meal.detail.filter(buoi_ => time.time_name == buoi_.time_name).length; })

									arr.push({ "time_name": time.time_name, "count": moreDay });
									count1.push({ "time_name": time.time_name, "count": sumDay });
								})
							}
							day_default = day_default + 1 > 7 ? 1 : (day_default + 1);
						}
						dihoc = dihoc - nghi;
						coan = dihoc - khongan;

						tongDs += count_student;
						tongKhongan += khongan;
						tongDihoc += dihoc;
						tongCoan += coan;
						tongNghi += nghi;
						tongKhongphep += khongphep;
						tongCophep += cophep;

						let data = {
							id: e.id,
							class_name: e.class_name,
							user_name: e.user_name,
							total: count_student,
							dihoc: dihoc,
							coan: coan,
							khongan: khongan,
							buoi: arr,
							tongnghi: nghi,
							khongphep: khongphep,
							cophep: cophep,
						}
						listClass.push(data);
					})

					this.setState({
						tongAll: tongDs,
						tongkhongan: tongKhongan,
						tongdihoc: tongDihoc,
						tongcoan: tongCoan,
						totalnghi: tongNghi,
						totalkhongphep: tongKhongphep,
						totalcophep: tongCophep,
						listStudent: listClass,
						count: count1, checkload: false
					})
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	getCutMealsData = (classifyId) => {
		// axios.post(`/viewMeal?id_classify=${classifyId}`)
		// 	.then(res => {
		// 		if (res.data.status === true) {
		// 			this.setState({
		// 				cutMeals: res.data.data
		// 			})

		// 		} else {
		// 			this.setState({
		// 				cutMeals: []
		// 			})
		// 		}

		// 	});
	}
	getClassifiesData = () => {
		axios.get(`/viewClassify`)
			.then(res => {
				if (res.data.status === true) {
					this.setState({
						classifies: res.data.data,
						classifyShow: res.data.data[0].id
					});
					this.getCutMealsData(res.data.data[0].id)
				}
			});
	}

	getCutMealsData = (classifyId) => {
		// axios.post(`/viewMeal?id_classify=${classifyId}`)
		// 	.then(res => {
		// 		if (res.data.status === true) {
		// 			this.setState({
		// 				cutMeals: res.data.data
		// 			})

		// 		} else {
		// 			this.setState({
		// 				cutMeals: []
		// 			})
		// 		}
		// 	});
	}


	getCutMealDetailData = (paramId) => {
		let classifyLink = `/viewListMealByClass?id_classify=${this.state.classifyShow}&${this.state.classifyShow === 1 ? `id_class=${paramId}` : `id_user=${paramId}`}`;

		axios.post(classifyLink)
			.then(res => {
				if (res.data.status === true) {
					this.setState({
						cutMealDetail: res.data.data,
						cutMealDetailShow: res.data.data,
					});
				} else {
					this.setState({
						cutMealDetail: [],
						cutMealDetailShow: [],
					})
				}
			});
	}

	handleSelectClassify = (key) => {
		this.getCutMealsData(key);
		this.setState({
			classifyShow: key.toString()
		});
	}
	formatNumber(num) {
		if (num != null && num != '')
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	Emaxlement = (array) => {

		let max = array[0];
		let max_index = 0;
		for (let i = 0; i < array.length; ++i) {
			if (max < array[i]) {
				max = array[i];
				max_index = i;
			}
		}
		return max
	}
	changeTime = (update) => {
		this.setState({ show: false })
		this.setState({ start_: new Date(update.setHours(0, 0, 0)) })
		this.setState({ end_: new Date(update.setHours(23, 59, 59)) })
		this.getClassRoom(new Date(update.setHours(0, 0, 0)), new Date(update.setHours(23, 59, 59)))
		this.getListMealstudent(new Date(update.setHours(0, 0, 0)), new Date(update.setHours(23, 59, 59)))
		this.setState({ startDate: new Date(update.setHours(0, 0, 0)), endDate: new Date(update.setHours(23, 59, 59)) })

	}
	loading = () => {
		return <Card>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
					<span className="sr-only">Loading...</span>
				</div>
			</Card.Body>
		</Card>

	}
	renderCutMealListTable() {
		return (
			<>
				{this.state.checkload ? this.loading() :
					<div style={{overflow:'auto'}}>
						<Table bordered style={{ borderColor: "#000" }}>
							<thead>
								<tr> <th className="text-center" colSpan={10 + this.state.listSetting.length}> THỐNG KÊ SỈ SỐ ({this.getDate_(this.state.startDate)}
									)
								</th></tr>
								<tr >
									<th rowSpan="2" className="align-middle text-center">STT</th>
									<th className="align-middle text-center" rowSpan="2">Lớp</th>
									<th className="align-middle text-center" rowSpan="2">Tên GV</th>
									<th className=" align-middle text-center" rowSpan='2'>Tổng DS</th>
									<th className="align-middle text-center" rowSpan="2" style={{ backgroundColor: "#c3d6da" }}> Đi học</th>
									{/* </tr>
								<tr> */}


									<th className="align-middle text-center" rowSpan="2" style={{ backgroundColor: "#c3d6da" }}> Có ăn</th>
									<th className=" align-middle text-center" rowSpan="2" style={{ backgroundColor: "#c3d6da" }}>Không ăn</th>
									<th className=" align-middle text-center" style={{ width: "150px", backgroundColor: 'lavender' }} colSpan={this.state.listSetting.length}>Đi học không ăn</th>
									<th className="align-middle text-center" rowSpan="2" style={{ backgroundColor: "#d9ffd9" }}> Tổng nghỉ</th>
									<th className=" align-middle text-center" rowSpan="2" style={{ backgroundColor: "#d9ffd9" }}>Không phép</th>
									<th className="align-middle text-center" rowSpan="2" style={{ backgroundColor: "#d9ffd9" }}>Có phép</th>


								</tr>
								<tr>
									{this.state.listSetting?.map(item => {
										return (
											<th className="align-middle text-center" style={{ backgroundColor: 'lavender' }}>{item.time_name}</th>
										)
									})}

								</tr>


							</thead>
							{

								<tbody>
									{this.state.setting?.filter(e => e.stt_day == this.state.startDate.getDay()).length > 0 ?

										<>
											{

												this.state.listStudent.map((student, index) => {
													return (
														<tr>
															<td>
																{index + 1}
															</td>
															<td>
																{student.class_name}
															</td>
															<td>{student.user_name}</td>
															<td>{student.total}</td>
															<td>{student.dihoc}</td>
															<td>
																{student.coan}
															</td>
															<td>{student.khongan}</td>

															{this.state.listSetting?.map(item => {
																return (
																	<td>
																		{student.buoi.filter(e => e.time_name == item.time_name).length > 0 ? student.buoi.filter(e => e.time_name == item.time_name)[0].count : 0}
																	</td>
																)
															})}
															<td>
																{student.tongnghi}
															</td>
															<td>
																{student.khongphep}
															</td>
															<td>
																{student.cophep}
															</td>
														</tr>
													)
												})

											}
											<tr><th colSpan='3'> Tổng</th>
												<th>{this.state.tongAll}</th>
												<th>{this.state.tongdihoc}</th>
												<th>{this.state.tongcoan}</th>
												<th>{this.state.tongkhongan}</th>

												{this.state.listSetting?.map(item => {
													return (
														<td>
															{this.state.count?.filter(e => e.time_name == item.time_name).length > 0 ? this.state.count?.filter(e => e.time_name == item.time_name)[0].count : 0}
														</td>
													)
												})}
												<th>{this.state.totalnghi}</th>
												<th>{this.state.totalkhongphep}</th>
												<th>{this.state.totalcophep}</th>
											</tr>
										</>

										: <>
										<tr >
											<td  colSpan={10 + this.state.listSetting.length} className="text-center">
											Không có dữ liệu...
											</td>
										
										</tr>
										</>
									

									}

								</tbody>





							}
						</Table>

						<Table
							bordered
							id="CutUserTable"
							style={{ borderColor: "#000" }}
							className="d-none"
						>
							<thead>
								<tr>
									<th className="text-center"> THỐNG KÊ SỈ SỐ ({this.getDate_(this.state.startDate)})</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>

									{
										this.state.listSetting.map(_ => {
											return (
												<th>empty</th>
											)
										})
									}

									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
								</tr>
								<tr >
									<th className="align-middle text-center">STT</th>
									<th className="align-middle text-center">Lớp</th>
									<th className="align-middle text-center">Tên GV</th>
									<th className=" align-middle text-center">Tổng DS</th>
									<th className="align-middle text-center" style={{ backgroundColor: "#c3d6da" }}> Đi học</th>
									<th className="align-middle text-center" style={{ backgroundColor: "#c3d6da" }}> Có ăn</th>
									<th className=" align-middle text-center" style={{ backgroundColor: "#c3d6da" }}>Không ăn</th>

									<th className=" align-middle text-center" style={{ width: "150px", backgroundColor: 'lavender' }} >Đi học không ăn</th>
									{
										this.state.listSetting.slice(1, this.state.listSetting.length).map(_ => {
											return (
												<th>empty</th>
											)
										})
									}

									<th className="align-middle text-center" style={{ backgroundColor: "#d9ffd9" }}> Tổng nghỉ</th>
									<th className=" align-middle text-center" style={{ backgroundColor: "#d9ffd9" }}>Không phép</th>
									<th className="align-middle text-center" style={{ backgroundColor: "#d9ffd9" }}>Có phép</th>
								</tr>
								<tr>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
									<th>empty</th>

									{this.state.listSetting?.map(item => {
										return (
											<th className="align-middle text-center" style={{ backgroundColor: 'lavender' }}>{item.time_name}</th>
										)
									})}

									<th>empty</th>
									<th>empty</th>
									<th>empty</th>
								</tr>
							</thead>
						
							<tbody>
								{

								
								this.state.listStudent.map((student, index) => {
									return (
										<tr>
											<td>
												{index + 1}
											</td>
											<td>
												{student.class_name}
											</td>
											<td>{student.user_name}</td>
											<td>{student.total}</td>
											<td>{student.dihoc}</td>
											<td>
												{student.coan}
											</td>
											<td>{student.khongan}</td>

											{this.state.listSetting?.map(item => {
												return (
													<td>
														{student.buoi.filter(e => e.time_name == item.time_name).length > 0 ? student.buoi.filter(e => e.time_name == item.time_name)[0].count : 0}
													</td>
												)
											})}
											<td>
												{student.tongnghi}
											</td>
											<td>
												{student.khongphep}
											</td>
											<td>
												{student.cophep}
											</td>
										</tr>
									)
								})}
								<tr>
									<th>Tổng</th>
									<th>empty</th>
									<th>empty</th>
									<th>{this.state.tongAll}</th>
									<th>{this.state.tongdihoc}</th>
									<th>{this.state.tongcoan}</th>
									<th>{this.state.tongkhongan}</th>
									{this.state.listSetting?.map(item => {
										return (
											<td>
												{this.state.count?.filter(e => e.time_name == item.time_name).length > 0 ? this.state.count?.filter(e => e.time_name == item.time_name)[0].count : 0}
											</td>
										)
									})}
									<th>{this.state.totalnghi}</th>
									<th>{this.state.totalkhongphep}</th>
									<th>{this.state.totalcophep}</th>
								</tr>
							</tbody>
						
						</Table>
					</div>
				}
			</>
		)
	}

	getDateTime = (data) => {
		let dateTime = new Date(data * 1000);
		return `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
	}
	xport = () => {
		const table = document.getElementById("CutUserTable");
		const wb = XLSX.utils.table_to_book(table);
		var ws = wb.Sheets["Sheet1"];

		const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R"];

		colAlpha.slice(0, this.state.listSetting?.length + 10).map((alpha) => {
			ws[`${alpha}1`].s = {
				font: {
					name: "Times New Roman",
					sz: 16,
					bold: true,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: "center"
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
			};

			return alpha;
		});

		colAlpha.slice(0, this.state.listSetting?.length + 10).map((alpha, index) => {
			if (index > 6 && index < 6 + this.state.listSetting?.length + 1) {
				ws[`${alpha}2`].s = {
					fill: {
						patternType: 'solid',
						fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'E6E6FA' },
						bgColor: { indexed: 64 }
					},
					font: {
						name: "Times New Roman",
						sz: 11,
						bold: true,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center"
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			} else if (index > 6 + this.state.listSetting?.length) {
				ws[`${alpha}2`].s = {
					fill: {
						patternType: 'solid',
						fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'd9ffd9' },
						bgColor: { indexed: 64 }
					},
					font: {
						name: "Times New Roman",
						sz: 11,
						bold: true,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center"
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			}

			ws[`${alpha}2`].s = {
				fill: alpha === "E" || alpha === "F" || alpha === "G" ? {
					patternType: 'solid',
					fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'c3d6da' },
					bgColor: { indexed: 64 }
				} : {
					patternType: 'solid',
					fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'ffffff' },
					bgColor: { indexed: 64 }
				},
				font: {
					name: "Times New Roman",
					sz: 11,
					bold: true,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: "center"
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
			};

			return alpha;
		});

		colAlpha.slice(0, this.state.listSetting?.length + 10).map((alpha, index) => {
			if (index > 6 && index < 6 + this.state.listSetting?.length + 1) {
				ws[`${alpha}3`].s = {
					fill: {
						patternType: 'solid',
						fgColor: { theme: 8, tint: 0.3999755851924192, rgb: 'E6E6FA' },
						bgColor: { indexed: 64 }
					},
					font: {
						name: "Times New Roman",
						sz: 11,
						bold: true,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "center"
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			}

			ws[`${alpha}3`].s = {
				font: {
					name: "Times New Roman",
					sz: 11,
					bold: true,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: "center"
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
			};

			return alpha;
		});

		for (let i = 4; i < this.state.listStudent.length + 4; i++) {
			colAlpha.slice(0, this.state.listSetting?.length + 10).map((alpha) => {

				ws[`${alpha}${i}`].s = {
					font: {
						name: "Times New Roman",
						sz: 11,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "left"
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			})
		}

		colAlpha.slice(0, this.state.listSetting?.length + 10).map((alpha, index) => {
			if (index > 6 && index < 6 + this.state.listSetting?.length + 1) {
				ws[`${alpha}${this.state.listStudent.length + 4}`].s = {
					font: {
						name: "Times New Roman",
						sz: 11,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: "left"
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			}

			ws[`${alpha}${this.state.listStudent.length + 4}`].s = {
				font: {
					name: "Times New Roman",
					sz: 11,
					bold: true,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: "left"
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
			};

			return alpha;
		});

		ws['!cols'] = [
			{ wch: 3.5 },
			{ wch: 15 },
			{ wch: 25 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
			{ wch: 7 },
		];
		ws['!rows'] = [{ hpt: 30 }];

		const merge = [
			{ s: { r: 0, c: 0 }, e: { r: 0, c: this.state.listSetting?.length + 9 } },

			{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
			{ s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
			{ s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
			{ s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
			{ s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
			{ s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
			{ s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

			{ s: { r: 1, c: 7 }, e: { r: 1, c: this.state.listSetting?.length + 6 } },

			{ s: { r: 1, c: this.state.listSetting?.length + 7 }, e: { r: 2, c: this.state.listSetting?.length + 7 } },
			{ s: { r: 1, c: this.state.listSetting?.length + 8 }, e: { r: 2, c: this.state.listSetting?.length + 8 } },
			{ s: { r: 1, c: this.state.listSetting?.length + 9 }, e: { r: 2, c: this.state.listSetting?.length + 9 } },

			{ s: { r: this.state.listStudent.length + 3, c: 0 }, e: { r: this.state.listStudent.length + 3, c: + 2 } },
		];
		ws["!merges"] = merge;

		XLSX.writeFile(wb, `Thống kê sỉ số ${this.getDate_(this.state.startDate)} .xlsx`);
	};
	handleExport = () => {
		swal({
			title: `Xác nhận`,
			text: `Xuất danh sách thống kê sỉ số ${this.getDate_(this.state.startDate)}`,
			icon: "info",
			buttons: ["Đóng", "Export"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				this.xport();
			}
		});
	}
	render() {

		return (
			<>
				<Container fluid>
					<Breadcrumb>
						<Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
						<Breadcrumb.Item active>Thống kê sỉ số</Breadcrumb.Item>
					</Breadcrumb>
					<Card>
						<Card.Header>
							<Card.Title>

								<i className="fas fa-list me-1"></i>Thống kê sỉ số {this.state.startDate ? (this.getDate_(this.state.startDate)) : ''}
								{" "}
								<Button
									size="sm"
									onClick={() => { this.setState({ show: true }) }}>
									< i className="fa-solid fa-calendar-days"> </i> </Button>

								<Button
									variant="success"
									size="sm"
									style={{ marginLeft: "5px", float: "right" }}
									onClick={() =>

										this.handleExport()}
								>
									Xuất danh sách thống kê sỉ số{ }
									<i className="fa-solid fa-file-export"></i>
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							{this.renderCutMealListTable()}
						</Card.Body>
					</Card>
				</Container>
				<Modal show={this.state.show} size={"sm"} onHide={() => this.setState({ show: false })} keyboard={false} backdrop="static" >
					<Modal.Header closeButton={this.state.endDate != null} >
						<Modal.Title>
							Chọn thời gian
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<center>

							<DatePicker selected={this.state.startDate}
								onChange={date => this.changeTime(date)
								}
								filterDate={this.checkSpicialDate}
								inline />
						</center>
					</Modal.Body>

				</Modal>
			</>
		);
	}
}

export default Report;
