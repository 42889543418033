import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
class ClassUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      schoolYears: this.props.schoolYears,
      groups: this.props.groups,
      users: this.props.users,
      errors: {},
      errTuition: {},
      dataInput: {
        id: this.props.id,
        class_name: "",
        id_year: "",
        id_group: "",
        teachers: [],
      },
      dataTuition: [],
      tuition: [],
    };
  }

  componentDidMount() {
    this.getClassroom();
    this.getAllTuition();
    this.getTuition();
  }
  getAllTuition = () => {
    axios.get("/tuition-shows").then((res) => {
      this.setState({
        tuition: res.data.data?.data || [],
      });
    });
  };
  getClassroom = async () => {
    await axios
      .get(`/classroom-show?id=${this.state.dataInput?.id}`)
      .then((res) => {
        if (res.status === 200) {
          const classroom = res.data.data.data;
          const teachers = classroom?.classHasTeachers?.map((item) => ({
            id: item.id_teacher,
            teacher_name: item.teacher_name,
            is_main: item.is_main,
          }));

          const dataInputNew = {
            id: classroom.id,
            class_name: classroom.class_name,
            id_year: classroom.id_year,
            id_group: classroom.id_group,
            teachers: teachers,
          };
          this.setState({ dataInput: dataInputNew });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getTuition = async () => {
    await axios
      .get(`/tuition-setting-shows?id_class=${this.state.dataInput?.id}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ dataTuition: res.data.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    let dataTuition = {
      id_class: this.state.dataInput.id,
      tuition_settings: this.state.dataTuition?.map((e) => {
        return {
          id_tuition: e.id_tuition,
          amount: parseFloat(e.amount),
        };
      }),
    };

    await swal({
      title: "Bạn muốn cập nhật lớp học?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        let formData = new FormData();
        formData.append("id", this.state.dataInput.id);
        formData.append("class_name", this.state.dataInput.class_name);
        formData.append("id_year", this.state.dataInput.id_year);
        formData.append("id_group", this.state.dataInput.id_group);
        formData.append(
          "teachers",
          JSON.stringify(this.state.dataInput.teachers)
        );

        await axios
          .put(`/classroom-update`, formData)
          .then((res) => {
            if (res.status === 200) {
              axios
                .put(`/tuition-setting-update`, dataTuition)
                .then((response) => {
                  this.setState({ errTuition: {} });
                  if (res.status == 200 && response.status == 200) {
                    this.props.close();
                    toast(`${res.data.messages}`, {
                      type: "success",
                      autoClose: 1000,
                    });
                    this.setState({
                      dataInput: {
                        class_name: "",
                        id_year: "",
                        id_group: this.props.id_group,
                        teachers: [],
                        err: {},
                      },
                    });
                  }
                })
                .catch((err) => {
                  this.setState({
                    errTuition: err.response.data.messages,
                  });
                });
            }
          })
          .catch((error) => {
            this.setState({ errors: error.response.data.messages });
          });
      }
    });
  };

  formclose = () => {
    this.setState({ show: false });
  };

  hanldeInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    let newDataInput = this.state.dataInput;
    newDataInput[name] = value;

    let newErrors = this.state.errors;
    delete newErrors[name];

    this.setState({
      dataInput: newDataInput,
      errors: newErrors,
    });
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props.close}>
          <Modal.Header closeButton>
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Chi tiết lớp học
            </h1>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Tabs className="mb-3 ">
                <Tab key={1} title="Thông tin" eventKey={1}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      className={
                        this.state.errors.class_name ? "is-invalid" : ""
                      }
                      placeholder="Tên lớp học"
                      name="class_name"
                      value={this.state.dataInput.class_name}
                      onChange={(e) => {
                        this.hanldeInput(e);
                      }}
                    />
                    <Form.Label for="floatingInputClassName">
                      Tên lớp học<i className="text-danger">*</i>
                    </Form.Label>
                    {this.state.errors.class_name ? (
                      <span className="text-danger">
                        {this.state.errors.class_name}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Floating>

                  <Table>
                    <thead>
                      <tr className="bg-light">
                        <th className="text-center">STT</th>
                        <th>Họ và tên</th>
                        <th>Vai trò</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.dataInput?.teachers?.length > 0 ? (
                        this.state.dataInput?.teachers?.map((item, i) => {
                          return (
                            <tr>
                              <td className="text-center">{i + 1}</td>
                              <td>{item.teacher_name}</td>
                              <td>
                                <Form.Select
                                  className={
                                    item.is_main === 1 ? "text-primary" : ""
                                  }
                                  value={item.is_main}
                                  onChange={(e) => {
                                    let newDataInput = {
                                      ...this.state.dataInput,
                                    };

                                    const findTeacher =
                                      newDataInput?.teachers?.find(
                                        (teacher) => teacher.id == item.id
                                      );

                                    let newTeachers =
                                      newDataInput?.teachers?.map((teacher) => {
                                        teacher.is_main =
                                          teacher.id === findTeacher.id ? 1 : 0;
                                        return teacher;
                                      });

                                    newDataInput.teachers = newTeachers;

                                    this.setState({ dataInput: newDataInput });
                                  }}
                                >
                                  <option value={1}>Chủ nhiệm</option>
                                  <option value={0}>Giáo viên</option>
                                </Form.Select>
                              </td>
                              <td>
                                {new Check().permission(["14"]) ?
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => {
                                      let newDataInput = {
                                        ...this.state.dataInput,
                                      };
                                      let newTeachers =
                                        newDataInput?.teachers.filter(
                                          (teacher) => teacher.id !== item.id
                                        );

                                      const checkIsMain = newTeachers.some(
                                        (teacher) => teacher.is_main === 1
                                      );
                                      if (newTeachers.length > 0 && !checkIsMain)
                                        newTeachers[0].is_main = 1;

                                      newDataInput.teachers = newTeachers;
                                      this.setState({ dataInput: newDataInput });
                                    }}
                                  >
                                    <i className="fa-solid fa-times" />
                                  </Button>
                                  : <></>}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr><td colSpan={4} className="text-muted">Không có giáo viên.</td></tr>
                      )}
                    </tbody>
                  </Table>

                  <Form.Floating className="mb-3">
                    <Form.Select
                      id="selectTeacherId"
                      name="teachers"
                      placeholder="Thêm giáo viên"
                      className={this.state.errors.teachers ? "is-invalid" : ""}
                      value={0}
                      onChange={(e) => {
                        const teacherId = Number(e.target.value);
                        let newDataInput = { ...this.state.dataInput };

                        const teacherChose = this.props.users.filter(
                          (item) => item.id === teacherId
                        );
                        const checkIsmain = newDataInput.teachers.some(
                          (item) => item.is_main === 1
                        );
                        const checkIsset = newDataInput.teachers.some(
                          (item) => item.id === teacherId
                        );

                        if (!checkIsset) {
                          newDataInput.teachers.push({
                            id: teacherChose[0].id,
                            teacher_name: teacherChose[0].user_name,
                            is_main: checkIsmain ? 0 : 1,
                          });
                        }

                        let newErrors = this.state.errors;
                        delete newErrors["teachers"];

                        this.setState({
                          dataInput: newDataInput,
                          errors: newErrors,
                        });
                      }}
                    >
                      <option value="">--Chọn--</option>

                      {this.props?.users.map((user) => {
                        const checkIsset = this.state.dataInput.teachers
                          ? this.state.dataInput.teachers.some(
                            (item) => item.id === user.id
                          )
                          : false;

                        if (!checkIsset)
                          return user.role_name === "Giáo viên" ? (
                            <option key={user.id} value={user.id}>
                              {user.user_name}
                            </option>
                          ) : (
                            <div key={user.id}></div>
                          );
                      })}
                    </Form.Select>
                    <Form.Label for="selectTeacherId">Thêm giáo viên</Form.Label>
                    {this.state.errors.teachers ? (
                      <span className="text-danger">
                        {this.state.errors.teachers}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Floating>

                  <Row>
                    <Col>
                      {new Check().permission(["7"]) ? (
                        <Form.Floating className="mb-3">
                          <Form.Select
                            name="id_group"
                            id="selectGroupId"
                            className={
                              this.state.errors.id_group ? "is-invalid" : ""
                            }
                            placeholder="Nhóm học sinh"
                            value={this.state.dataInput.id_group}
                            onChange={(e) => {
                              this.hanldeInput(e);
                            }}
                          >
                            <option value="">--Chọn--</option>
                            {this.state?.groups.map((group, i) => {
                              if (group.is_adult == 0 && group.is_main == 1) {
                                return (
                                  <option key={group.id} value={group.id}>
                                    {group.group_name}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Form.Select>
                          <Form.Label for="selectGroupId">
                            Nhóm học sinh<i className="text-danger">*</i>
                          </Form.Label>
                          {this.state.errors.id_group ? (
                            <span className="text-danger">
                              {this.state.errors.id_group}
                            </span>
                          ) : (
                            ""
                          )}
                        </Form.Floating>
                      ) : null}
                    </Col>

                    <Col>
                      <Form.Floating>
                        <Form.Select
                          id="idYear"
                          name="id_year"
                          className={
                            this.state.errors.id_year ? "is-invalid" : ""
                          }
                          placeholder="Năm học"
                          value={this.state.dataInput.id_year}
                          onChange={(e) => this.hanldeInput(e)}
                          disabled={true}
                        >
                          <option value="">--Chọn--</option>
                          {this.state?.schoolYears.map((year, i) => {
                            return (
                              <option key={year.id} value={year.id}>
                                {year.year_name}
                              </option>
                            );
                          })}
                        </Form.Select>

                        <Form.Label for="idYear">
                          Năm học<i className="text-danger">*</i>
                        </Form.Label>
                        {this.state.errors.id_year ? (
                          <span className="text-danger">
                            {this.state.errors.id_year}
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>
                <Tab key={2} title="Khoản thu" eventKey={2}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      id="selectTuitionId"
                      name="tuition"
                      placeholder="Khoản thu"
                      value={0}
                      onChange={(e) => {
                        const teacherId = Number(e.target.value);
                        let newDataInput = [...this.state.dataTuition];

                        const checkIsset = newDataInput?.some(
                          (item) => item.tuition?.id == teacherId
                        );

                        if (!checkIsset) {
                          newDataInput.push({
                            id_class: this.state.dataInput?.id,
                            id_tuition: this.state.tuition?.filter(
                              (tuition) => tuition.id == teacherId
                            )[0]?.id,
                            amount: this.state.tuition?.filter(
                              (tuition) => tuition.id == teacherId
                            )[0]?.amount,
                            tuition: this.state.tuition?.filter(
                              (tuition) => tuition.id == teacherId
                            )[0],
                          });
                        }

                        this.setState({
                          dataTuition: newDataInput,
                        });
                      }}
                    >
                      <option value="">--Chọn--</option>
                      {this.state.tuition?.map((user) => {
                        const checkIsset =
                          this.state.dataTuition.length > 0
                            ? this.state.dataTuition?.some(
                              (item) => item.id_tuition == user.id
                            )
                            : false;

                        if (!checkIsset)
                          return (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          );
                      })}
                    </Form.Select>
                    <Form.Label for="selectTeacherId">Khoản thu</Form.Label>
                  </Form.Floating>
                  <Table>
                    <thead>
                      <tr className="bg-light">
                        <th className="text-center">STT</th>
                        <th>Tên khoản thu</th>
                        <th>Giá</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.dataTuition?.length > 0 ? (
                        this.state.dataTuition?.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.tuition?.name}</td>
                                <td>
                                  <CurrencyFormat
                                    className="form-control text-end"
                                    placeholder="Giá"
                                    thousandSeparator={true}
                                    name="amount"
                                    required
                                    allowNegative={false}
                                    value={parseFloat(item.amount) || ""}
                                    onValueChange={(values) => {
                                      let dataNew = [...this.state.dataTuition];
                                      dataNew?.map((e, i) => {
                                        if (e.id_tuition == item.id_tuition)
                                          return (dataNew[i] = {
                                            ...dataNew[i],
                                            amount: values.value,
                                          });
                                        else return dataNew[i];
                                      });

                                      this.setState({
                                        dataTuition: dataNew,
                                      });
                                      delete this.state.errTuition[
                                        `tuition_settings[${i}].amount`
                                      ];
                                    }}
                                  />
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => {
                                      let newDataInput = [
                                        ...this.state.dataTuition,
                                      ];
                                      let newTeachers = newDataInput?.filter(
                                        (teacher) =>
                                          teacher.id_tuition != item.id_tuition
                                      );

                                      newDataInput = newTeachers;
                                      this.setState({
                                        dataTuition: newDataInput,
                                      });

                                      delete this.state.errTuition[
                                        `tuition_settings[${i}].amount`
                                      ];
                                    }}
                                  >
                                    <i className="fa-solid fa-times" />
                                  </Button>
                                </td>
                              </tr>

                              {this.state.errTuition && (
                                <tr
                                  style={{
                                    fontSize: 13,
                                    color: "red",
                                    marginTop: 6,
                                  }}
                                >
                                  <td></td>
                                  <td></td>
                                  <td colSpan={2}>
                                    {
                                      this.state.errTuition[
                                      `tuition_settings[${i}].amount`
                                      ]
                                    }
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-muted">
                            Không có dữ liệu...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Modal.Body>

            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.props.close}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {new Check().permission(["14"]) ?
                <Button size="sm" type="submit" variant="success">
                  <i className="fa-solid fa-check"></i> Cập nhật
                </Button>
                : <></>}

            </Modal.Footer>
          </Form>
        </Modal>
        {this.state.show ? this.renderGroupAdd() : null}
      </>
    );
  }
}

export default ClassUpdate;
