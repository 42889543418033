import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Modal, Button, Form } from "react-bootstrap";

const CategoryEdit = ({ show, onHide, selectedCategory }) => {
  const [category, setCategory] = useState({
    id: 0,
    category_name: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    category_name: "",
  });
  const categoryOptions = [
    { id: 1, name: "Mặn" },
    { id: 2, name: "Canh" },
    { id: 3, name: "Xào" },
    { id: 4, name: "Chiên" },
    { id: 5, name: "Khô" },
    { id: 6, name: "Nước" },
    { id: 7, name: "Tráng miệng" },
  ];

  useEffect(() => {
    setCategory({
      id: selectedCategory?.id,
      category_name: selectedCategory?.category_name,
      id_category_bank: selectedCategory?.id_category_bank,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const updateCategory = async () => {
    setErrorMessage({
      category_name: "",
    });

    let data = {
      category_name: category.category_name,
      id_category_bank: category.id_category_bank ?? "",
    };
    axios({
      method: "put",
      url: "category-update",
      data: category,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode == 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          onHide(true);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  return (
    <Modal
      size="sm"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-solid fa-tag"></i> Chi tiết danh mục
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateCategory();
        }}
      >
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              placeholder="Tên danh mục"
              required
              value={category.category_name}
              onChange={(e) => {
                setCategory({
                  ...category,
                  category_name: e.target.value,
                });
                delete errorMessage["category_name"];
              }}
            />
            <Form.Label>
              Tên danh mục<i className="text-danger">*</i>
            </Form.Label>

            {errorMessage.category_name && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errorMessage.category_name}
              </p>
            )}
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Select
              value={category.id_category_bank}
              onChange={(e) => {
                setCategory({
                  ...category,
                  id_category_bank: e.target.value,
                });
                delete errorMessage["id_category_bank"];
              }}
            // required
            >
              <option value="">--Chọn--</option>
              {categoryOptions.length > 0
                ? categoryOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })
                : "Không có lựa chọn"}
            </Form.Select>
            <Form.Label>
              Danh mục ngân hàng
              <i className="text-danger"></i>
            </Form.Label>
            {errorMessage.id_category_bank && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errorMessage.id_category_bank}
              </p>
            )}
          </Form.Floating>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times" /> Đóng
          </Button>

          <Button size="sm" variant="success" type="submit">
            <i className="fa-solid fa-check" /> Cập nhật
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CategoryEdit;
