import axios from "axios";
import React, { Component } from "react";
import {
	Alert,
	Breadcrumb,
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
	Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import StoreCreate from "./StoreCreate";
import CurrencyFormat from "react-currency-format";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ingredientTypeOptions = [
	{ label: "Tất cả", value: 0 },
	{ label: "Kho", value: 2 },
	{ label: "Vật tư", value: 3 }
]

class StoreIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			liststore: [],
			selectedRows: [],
			has_inventory: [],
			inventory_his: [],
			addliststore: [],
			suppliers: [],
			ingredents: [],
			showcreate: false,
			showdetail: false,
			showhis: false,
			showdelete: false,
			showupdate: false,
			startDate: new Date(),
			tabkey: 1,
			id_ingredent: "",
			amount_delete: "",
			ingredient_search: "", liststore_search: [],
			typeSelected: { label: "Tất cả", value: 0 },
			update_inventory: {
				id_inventory: "",
				is_type: false,
				amount: "",
			},
			propsdata: {
				id: "",
				amount: "",
				ingredent_name: "",
			},
			checkload: true, units: []
		};
	}

	async componentDidMount() {
		document.title = "Mua hàng";
		this.getStoreData(1, 100);
		this.getSuppliersData();
		this.getIngredentsData()
		this.getUnit(100)
	}

	getStoreData = (type, limit) => {
		axios
			.post(`/viewInventory?ingredent_type=2,3&limit=${limit}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ liststore: res.data.data });
					this.setState({ checkload: false })
					this.handleIngredentSearch(res.data.data, this.state.ingredient_search, this.state.typeSelected)

				}
			});
	};
	getUnit = (limit = 1000) => {
		axios.post(`/viewUnit?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ units: res.data.data });
			}
		});
	};
	getIngredentsData = (limit = 1000) => {
		axios.post(`/viewIngredent?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ ingredents: res.data.data });
			}
		});
	};
	getSuppliersData = (limit = 100) => {
		axios.post(`/viewSupplier?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ suppliers: res.data.data });
			}
		});
	};

	gethas_inventoryData = (type, id) => {
		axios
			.post(`/viewInventory?ingredent_type=${type}&id_ingredent=${id}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ has_inventory: res.data.data });
				}
			});
	};

	getinventory_hisData = (type, id) => {
		axios
			.post(`/viewHisInventory?ingredent_type=${type}&id_inventory=${id}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ inventory_his: res.data.data });
				}
			});
	};

	getaddliststore = (e) => {
		e.preventDefault();

		if (this.state.selectedRows.some(item => item.amount <= 0)) {
			toast("Số lượng nhập phải lớn hơn bằng 0", { type: "success", autoClose: 2000 });
			return;
		}

		axios.post(`/createMoreInventory?inventory=${JSON.stringify(this.state.selectedRows)}`)
			.then((res) => {
				this.setState({ selectedRows: "" })
				if (res.data.status === true) {
					this.getStoreData(this.state.tabkey, 100);
					toast(res.data.data.mess, { type: "success", autoClose: 1000 });
				} else {
					toast(res.data.data.mess, { type: "error", autoClose: 1000 });
				}
			});
	};

	renderSelectedRows() {
		let num = this.state.selectedRows.length;
		if (num > 0 && this.state.alertShow === true) {
			return (
				<Alert
					style={{
						position: "absolute",
						zIndex: "1",
						left: "91%",
					}}
					variant="info mb-0"
					onClose={() => this.setState({ alertShow: false })}
					dismissible
				>
					{num} mục đã chọn.
				</Alert>
			);
		}
	}
	toSlug(str) {
		str = str.toLowerCase(); // Chuyển hết sang chữ thường       
		// xóa dấu
		str = str
			.normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
			.replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
		str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
		str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
		return str;
	}
	handleIngredentSearch(root, text, typeFilter) {
		let root_ = root;
		this.setState({
			ingredient_search: text
		})

		if (text !== "") {
			root_ = this.state.liststore.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text)));
		}

		if (typeFilter.value !== 0) {
			root_ = this.state.liststore.filter((e) => e.ingredent_type === typeFilter.value);
		}

		this.setState({
			liststore_search: root_
		})
	}
	handSelectAddMore(item) {
		let var_ = {
			'ingredent_name': item.ingredent_name,
			'id_ingredent': item.id,
			'supplier': [
				this.state.ingredents.filter((e) => e.id === item.id)[0].has_supplier
			],
			'amount': 1,
			'ingredent_price': this.state.ingredents.filter((e) => e.id === item.id)[0].has_supplier.price,
			'expriry_date': item.ingredent_type == 3 ? new Date() / 1000 : "",
			'ingredent_type': item.ingredent_type,
		}

		let selectedRows_ = this.state.selectedRows;
		if (selectedRows_.length > 0) {
			selectedRows_.forEach((e, index) => {
				if (e.id_ingredent === item.id) {
					// selectedRows_[index].amount = parseInt(selectedRows_[index].amount) + 1;
				} else {
					if ((e.id_ingredent !== item.id) && selectedRows_.length < this.state.liststore.length) { selectedRows_.push(var_); }
				}
			})

		} else {
			selectedRows_ = [var_];
		}
		let arrObject = {};
		selectedRows_.map((item) => arrObject[item.id_ingredent] = item);
		selectedRows_ = Object.values(arrObject)
		this.setState({ selectedRows: Array.from(new Set(selectedRows_)) });
	}
	handelete(id) {
		var listTemp = []
		listTemp = this.state.selectedRows.filter((e) => e.id_ingredent !== id)
		this.setState({ selectedRows: listTemp })

	}
	formatter = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	loading = () => {
		return <Card>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
					<span className="sr-only">Loading...</span>
				</div>
			</Card.Body>
		</Card>

	}
	renderStoreList() {
		return (
			<>
				<Card.Header>
					<Card.Title>
						<i className="fas fa-list me-1"></i> Nguyên liệu
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col md={4}>
							<Select
								options={ingredientTypeOptions}
								placeholder={<div>Chọn loại nguyên liệu</div>}
								value={this.state.typeSelected}
								onChange={(choice) => {
									this.handleIngredentSearch(this.state.liststore, this.state.ingredient_search.trim(), choice)
									this.setState({
										typeSelected: choice
									})
								}}
							/>
						</Col>
						<Col md={8}>
							<Form.Control style={{ height: 39 }}
								type="text"
								placeholder="Tìm nguyên liệu"
								className="mb-3"
								onChange={
									(e) => {
										this.handleIngredentSearch(this.state.liststore, e.target.value.trim(), this.state.typeSelected)
									}
								}
							/>
						</Col>
					</Row>

					{this.state.checkload ? this.loading() :
						this.state.liststore_search.length > 0 ?
							<Table striped size="sm" >
								<thead>
									<tr >
										<th>Nguyên liệu</th>
										<th className="text-center" >ĐVT</th>
										<th className="text-center">SL</th>
									</tr>
								</thead>
								<tbody>
									{this.state.liststore_search.map((item, index) => {
										return (
											<tr key={index} onClick={() => { this.handSelectAddMore(item); }}>
												<td style={{ cursor: 'pointer' }}>{item.ingredent_name} </td>
												<td className="text-center">{this.state.units.filter(e => e.id == item.id_unit)[0]?.unit_name} </td>
												<td className="text-center">{this.formatter.format(item.amount)}</td>
											</tr>
										);
									})}
								</tbody>
							</Table> :
							//  this.loading()
							<p className="text-center">Không có dữ liệu ...</p>
					}

				</Card.Body>
			</>
		);
	}
	formatNumber(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	renderSupplier(value1, label1) {
		var option = []

		this.state.suppliers.length > 0
			&& this.state.suppliers.map((item) => {
				option.push({
					value: item.id,
					label: item.supplier_name
				})
				return item
			})

		option.unshift({
			value: value1,
			label: label1
		})

		// : null


		return (<Select
			// style={{ height: "35px",width:'400px' }}
			options={option}
			styles={this.customStyles}

			defaultValue={option[0]}
		>
		</Select>)
	}
	customStyles = {
		control: (baseStyles, state) => ({
			...baseStyles,
			width: "max-content"
			// background:'red'
			// minHeight: '48px',
			// color: state.isFocused ? 'yellow' : 'red',
			// boxShadow: state.isFocused ? '0 0 0 4px rgba(199, 246, 255, 1)' : null,
		}),
		// placeholder: (baseStyles, state) => ({
		//     ...baseStyles,
		// }),
		// singleValue: (baseStyles, state) => ({
		//     ...baseStyles,
		//     marginTop: '20px',
		// }),

	};

	gettong() {
		var tong = 0;
		if (this.state.selectedRows.length > 0) {
			this.state.selectedRows.map((item, index) => {
				tong = tong + item.amount * item.supplier[0].ingredent_price
				return (index + 1 == this.state.selectedRows.length) ? tong : 0
			})
		}
		return tong;

	}

	renderaddStoreList() {
		// var tong = 0;
		// var countAll = 0;
		return (
			<>
				<Card.Header>
					<Card.Title>
						<i className="fas fa-list me-1"></i>Thêm tồn kho
					</Card.Title>
				</Card.Header>

				<Form onSubmit={this.getaddliststore}>
					<Card.Body style={{
						minHeight: "350px",
						overflowY: "auto"
					}}>
						<Table>
							<thead>
								<tr style={{ whiteSpace: "nowrap" }}>
									<th>Nguyên liệu</th>
									<th >NCC</th>
									<th>Giá</th>
									<th>Số lượng</th>
									<th>Thành tiền</th>
									<th>HSD</th>
									<th>Phương thức</th>
								</tr>
							</thead>
							<tbody>
								{this.state.selectedRows.length > 0
									? this.state.selectedRows.map((item, i) => {
										return (
											<tr key={i}>
												<td >
													<div style={{
														justifyContent: "center", justifyItems: "center"
														, textAlign: "center", alignItems: "center", display: "flex"
													}} className="align-middle text-center"  >	{item.ingredent_name}</div>
												</td>
												< td>
													{/* ncc */}
													{this.renderSupplier(this.state.selectedRows[i].supplier[0].id_supplier, this.state.selectedRows[i].supplier[0].supplier_name)}
												</td>
												<td>
													{/* giá */}

													<CurrencyFormat
														className="form-control"
														style={{ height: "35px", width: "100px", textAlign: "right" }}
														allowNegative={false}
														name="ingredent_price"
														placeholder={this.formatNumber(this.state.selectedRows[i].supplier[0].ingredent_price)}
														thousandSeparator={true}
														value={this.state.selectedRows[i].supplier[0].ingredent_price}
														required
														onValueChange={(values) => {
															let selectedRows = [...this.state.selectedRows];
															selectedRows[i] = {
																id_ingredent: item.id
																	? item.id
																	: item.id_ingredent,
																ingredent_name: item.ingredent_name,
																expriry_date: selectedRows[i].expriry_date

																,
																supplier: [
																	{
																		id_supplier: selectedRows[i].supplier
																			? selectedRows[i].supplier[0]
																				.id_supplier
																			: "",
																		ingredent_price: values.value,
																	},
																],
																amount: selectedRows[i].amount,
															};

															this.setState({ selectedRows: selectedRows });

														}}

													/>
												</td>

												<td >
													<CurrencyFormat
														className="form-control"
														style={{ height: "35px", width: "100px", textAlign: "right" }}
														name="amount"
														thousandSeparator={true}
														value={this.state.selectedRows[i].amount}
														required
														onValueChange={(values) => {
															let selectedRows = [...this.state.selectedRows];
															selectedRows[i] = {
																id_ingredent: item.id
																	? item.id
																	: item.id_ingredent,
																ingredent_name: item.ingredent_name,
																expriry_date: selectedRows[i].expriry_date
																,
																supplier: [
																	{
																		id_supplier: selectedRows[i].supplier
																			? selectedRows[i].supplier[0]
																				.id_supplier
																			: "",
																		ingredent_price: selectedRows[i].supplier
																			? selectedRows[i].supplier[0].ingredent_price
																			: "",
																	},
																],
																amount: values.value,
															};

															this.setState({ selectedRows: selectedRows });
														}}
														allowNegative={false}
													/>
												</td>
												<td>
													<CurrencyFormat
														className="form-control"
														style={{ height: "35px", width: "100px", textAlign: "right" }}
														thousandSeparator={true}
														readOnly
														value={this.state.selectedRows[i].amount * this.state.selectedRows[i].supplier[0].ingredent_price}
														allowNegative={false}
													/>
												</td>
												<td>
													<DatePicker
														showIcon
														className="form-control"
														disabled={this.state.selectedRows[i].ingredent_type == 3}
														selected={this.state.selectedRows[i].expriry_date * 1000}
														dateFormat={this.state.selectedRows[i].ingredent_type == 3 ? '      ' : "dd/MM/yyyy"}

														onChange={(date) => {
															this.setState({ startDate: date })
															let selectedRows = [
																...this.state.selectedRows,
															];
															selectedRows[i] = {
																id_ingredent: item.id
																	? item.id
																	: item.id_ingredent,
																ingredent_name: item.ingredent_name,
																expriry_date: (item.ingredent_type == 3) ? (new Date()) / 1000 : date / 1000
																,
																supplier: [
																	{
																		id_supplier: selectedRows[i].supplier.length > 0
																			? selectedRows[i].supplier[0]
																				.id_supplier
																			: "",
																		ingredent_price: selectedRows[i]
																			.supplier.length > 0
																			? selectedRows[i].supplier[0]
																				.ingredent_price
																			: "",
																	},
																],
																amount: selectedRows[i].amount,
															};
															this.setState({ selectedRows: selectedRows });
														}}
														minDate={new Date()}
														showDisabledMonthNavigation
														required
													/>
												</td>
												<td className="text-center">
													<Button variant="danger" size="sm" style={{
														justifyContent: "center", justifyItems: "center"
														, textAlign: "center", alignItems: "center", display: "flex", height: "35px"
													}} title="Xóa" onClick={() => this.handelete(item.id_ingredent)}>Xóa</Button>
												</td>
											</tr>
										);
									})
									: null}
								<tr></tr>
								<tr>
									<td colspan="4" className="text-center" style={{ fontWeight: "bold", fontSize: "14px" }}>Tổng</td>
									<td>
										<CurrencyFormat
											className="form-control"
											style={{ height: "35px", width: "100px", textAlign: "right" }}
											thousandSeparator={true}
											readOnly
											value={this.gettong()}
											allowNegative={false}
										/>
									</td>
									<td></td><td></td>
								</tr>
							</tbody>
						</Table>
					</Card.Body>
					{this.state.selectedRows.length > 0 ? (
						<Button
							style={{ float: "right" }}
							className="mb-2 me-3"
							type="submit"
							variant="success"
							size="sm"
						>
							Nhập kho
						</Button>
					) : null}
				</Form>
			</>
		);
	}

	renderStoreAdd() {
		return (
			<StoreCreate
				show={this.state.showcreate}
				onHide={() => {
					this.setState({ showcreate: false });
				}}
				getStoreData={() => {
					this.setState({ showcreate: false });
					this.getStoreData(this.state.tabkey, 100);
				}}
				suppliers={this.state.suppliers}
				propsdata={this.state.propsdata}
			/>
		);
	}

	render() {
		return (
			<>
				<Container fluid>
					{/* {this.renderSelectedRows()} */}
					<Breadcrumb className="mb-2 mt-2">
						<Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
						<Breadcrumb.Item active>Mua hàng</Breadcrumb.Item>
					</Breadcrumb>
					<Row>
						<Col>
							<Card>{this.renderStoreList()}</Card>
						</Col>
						<Col md={8}>
							<Card>{this.renderaddStoreList()}</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default StoreIndex;
