import React, { Component } from "react";
import { Card, Col, Container, Row, Table, Tabs, Tab } from "react-bootstrap";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import Check from "./other/Check";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const initialDay = [
	{ id: 1, day_name: "Thứ 2" },
	{ id: 2, day_name: "Thứ 3" },
	{ id: 3, day_name: "Thứ 4" },
	{ id: 4, day_name: "Thứ 5" },
	{ id: 5, day_name: "Thứ 6" },
	{ id: 6, day_name: "Thứ 7" },
	{ id: 7, day_name: "Chủ nhật" }
]

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classifies: [],
			idClassify: 1,
			todaymenu: {
				time: []
			},
			isLoadingMenu: false,

			listday: [],
			listtime: [],

			listClass: [],
			listmealbyclass: [],
			totalPerson: [],
			isLoadingChart: false,

			voucherMarket: {},
			isLoadingVoucherMar: false,
		};
	}

	async componentDidMount() {
		document.title = 'Tổng quan';

		const date = new Date();
		const tmpDate = new Date();
		const week = this.dates(tmpDate);

		let todayIs = date.getDay();

		if (todayIs === 0) {
			todayIs = 7;
		}

		this.getClassifyData();
		this.getVoucherMarK(week[0], week[week.length - 1], todayIs);
	}

	//#region Fetch API
	getClassifyData() {
		axios
			.get("/viewClassify")
			.then((res) => {
				if (res.data.status) {
					this.setState({
						classifies: res.data.data,
						idClassify: res.data.data[0].id
					});

					this.getMenuData(res.data.data[0].id);
					this.getListMealByClassData(res.data.data[0].id);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getMenuData(type) {
		const date = new Date();
		const tmpDate = new Date();
		const week = this.dates(tmpDate);

		this.setState({
			isLoadingMenu: true,
		})

		axios
			.get(`/viewMenu?id_classify=${type}&start_at=${week[0].getTime() / 1000}&term_at=${week[week.length - 1].getTime() / 1000}`)
			.then((res) => {
				if (res.data.status === true) {

					let todayIs = date.getDay();

					if (todayIs === 0) {
						todayIs = 7;
					}

					let tmenu = [];

					if (res.data.data.length === 0) {
						tmenu = {
							time: []
						};
					} else {
						tmenu = res.data.data.detail.filter(dayItem => Number(dayItem.stt_day) === todayIs)[0];
					}

					if (res.data.data.id) {
						// this.getMenuSettingData(type, res.data.data.id);

						this.getSettingData(type);
					}

					this.setState({
						todaymenu: tmenu
					})
				}

				this.setState({
					isLoadingMenu: false,
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// getMenuSettingData = (type, menuId) => {
	//     axios
	//         .get(`/viewSetting?id_classify=${type}&id_menu=${menuId}`)
	//         .then((res) => {
	//             if (res.data.status) {
	// 				this.setState({
	// 					listday: res.data.data.day,
	// 					listtime: res.data.data.time
	// 				});
	//             }
	//         })
	//         .catch((error) => {
	//             console.log(error);
	//         });
	// };

	getSettingData = (type) => {
		axios
			.get(`/viewSetting?id_classify=${type}`)
			.then((res) => {
				if (res.data.status) {
					this.setState({
						listday: res.data.data.day,
						listtime: res.data.data.time
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	getListMealByClassData(type) {
		this.setState({
			isLoadingChart: true,
		})
		const currentDate = new Date();

		const listdayInWeek = this.dates(currentDate);
		axios
			.post(`/viewMeal?id_classify=${type}&start_date=${parseInt(listdayInWeek[0]/1000)}&end_date=${parseInt(listdayInWeek[listdayInWeek.length - 1]?.setHours(23,59,59)/1000)}`)
			.then((res) => {
				if (res.data.status) {

					if (type === 1) {
						this.getClassData();
					} else {
						this.getStaffData();
					}

					this.setState({
						listmealbyclass: res.data.data
					});
				}

				this.setState({
					isLoadingChart: false,
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getClassData = () => {
		axios.get(`/viewClassRoom`).then(res => {
			if (res.data.status === true) {

				let totalStudent = 0;

				res.data.data.map(student => {
					totalStudent += student.count_student;
					return student;
				})

				this.setState({
					listClass: res.data.data,
					totalPerson: totalStudent
				})
			}
		})
	}

	getStaffData() {
		axios
			.get("/showAllUser")
			.then((res) => {
				if (res.data.status) {
					this.setState({
						totalPerson: res.data.data.length
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getVoucherMarK(startDay, endDay, dayId) {
		this.setState({
			isLoadingVoucherMar: true,
		})
		axios.post(`/voucher?is_real=0&type=1&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}&id_day=${dayId}&id_classify=1,2`)
			.then(res => {
				this.setState({
					isLoadingVoucherMar: false,
				})

				if (res.data.status === true) {
					this.setState({
						voucherMarket: res.data.data[0]
					})
				}
			});
	}
	//#endregion

	//#region Handle Logic
	setClassify(id) {
		this.setState({
			idClassify: id,
			listmealbyclass: [],
			totalPerson: []
		})

		const date = new Date();
		const tmpDate = new Date();
		const week = this.dates(tmpDate);

		let todayIs = date.getDay();

		if (todayIs === 0) {
			todayIs = 7;
		}

		this.getClassifyData();
		this.getVoucherMarK(week[0], week[week.length - 1], todayIs);
		this.getMenuData(id);
		this.getListMealByClassData(id);
	}

	dates = (current) => {
		var week = [];
		// Starting Monday not Sunday
		current.setDate((current.getDate() - current.getDay() + 1));
		current.setHours(0, 0, 0, 0);
		for (var i = 0; i < 7; i++) {
			week.push(
				new Date(current)
			);
			current.setDate(current.getDate() + 1);
			current.setHours(0, 0, 0, 0);
		}
		return week;
	}

	stringDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
	//#endregion Handle Logic

	// Render
	render() {
		const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		// Summarize Chart
		const currentDate = new Date();

		const listdayInWeek = this.dates(currentDate);

		const options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: `Tổng hợp số lượng có mặt/nghỉ của ${this.state.idClassify === 1 ? "học sinh" : "nhân viên"} trong tuần ${('0' + listdayInWeek[0].getDate()).slice(-2)}-${this.stringDate(listdayInWeek[listdayInWeek.length - 1])}`,
				},
			},
		};

		const labels = [];

		this.state.listday.map(day => {
			if (day.is_active === 1) {
				labels.push(day.day_name)
			}
			return day;
		})

		let absentDayAmount = [0, 0, 0, 0, 0, 0, 0];
		let presentAmount = [];

		const mealCutInWeek = this.state.listmealbyclass.filter(mealCut => listdayInWeek[0].getTime() <= (mealCut.meal_day * 1000)
			&& (mealCut.meal_day * 1000) <= (listdayInWeek[listdayInWeek.length - 1].getTime() + 86400)
			&& Number(mealCut.is_status) === 1
		);

		mealCutInWeek.filter(mealCut => Number(mealCut.type_meal) === 1).map(mealCut => {
			const dayCut = new Date(mealCut.meal_day * 1000);
			let absentDay = dayCut.getDay();

			if (absentDay === 0) {
				absentDay = 7;
			}

			absentDayAmount[absentDay - 1] += 1;

			return mealCut;
		});

		absentDayAmount.map(abDay => {
			presentAmount.push(this.state.totalPerson - abDay);
			return abDay;
		});

		this.state.listday.map((day, index) => {

			if (day.is_active === 0) {
				absentDayAmount[index] = undefined;
				presentAmount[index] = undefined;
			}

			return day;
		})

		const data = {
			labels,
			datasets: [
				{
					label: 'SL có mặt',
					data: presentAmount.filter(day => day !== undefined),
					backgroundColor: '#0d6efd',
				},
				{
					label: 'SL nghỉ',
					data: absentDayAmount.filter(day => day !== undefined),
					backgroundColor: '#FF0000',
				},
			],
		};

		// Menu and Market List
		let timeNotActive = []

		const today = new Date();
		let todayIs = today.getDay();

		if (todayIs === 0) {
			todayIs = 7;
		}

		const dayName = initialDay.filter(day => day.id === todayIs)[0]?.day_name;

		let isMenuHasFood = false;

		if (this.state.todaymenu) {
			this.state.todaymenu.time?.map((time) => {
				if (time.food.length > 0) {
					isMenuHasFood = true;
				};
				return time;
			});
		}

		return (
			<Container fluid >
				<Card className="mt-2">
					<Card.Header>
						<Card.Title>
							Tổng quan
						</Card.Title>
					</Card.Header>
					<Card.Body>
						{
							new Check().permission(['72'], 'or')
								?
								!this.state.isLoadingChart
									?
									<>
										<Row>
											<Col md={12}>
												<Bar
													style={{ width: '100%', maxHeight: '500px', margin: '0 auto' }}
													options={options} data={data}
													className="d-block d-md-none"
												/>

												<div style={{ overflowX: "scroll" }}>
													<Bar
														style={{ minWidth: 1000, width: '100%', maxHeight: '500px', margin: '0 auto' }}
														options={options} data={data}
														className="d-none d-md-block"
													/>
												</div>
											</Col>
										</Row>
										<hr></hr>
									</>
									:
									<div style={{ height: 500 }}>
										<div className="d-flex justify-content-center align-items-center mt-5">
											<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
												<span className="sr-only">Đang tải thống kê có mặt/vắng ...</span>
											</div>
										</div>
									</div>
								: ""
						}

						<Tabs
							unmountOnExit={true}
							transition={false}
							onSelect={(key) => {
								this.setClassify(Number(key));
							}}
							className="mb-3"
						>
							{this.state.classifies &&
								this.state.classifies.map((item) => {
									if (item.id==1)
									return (
										<Tab
											key={item.id}
											title={item.classify_name}
											eventKey={item.id}
										>
											<Row style={{ marginTop: '20px' }}>
												<Col xs={12} md={6}>
													<h6>Danh sách thực đơn: {`${dayName} (${this.stringDate(today)})`}</h6>
													{
														!this.state.isLoadingMenu
															?
															this.state.todaymenu && isMenuHasFood && this.state.listday.some(day => Number(day.id) === Number(this.state.todaymenu.id_day) && Number(day.is_active) === 1)
																?
																<Table striped bordered size='sm'>
																	<thead>
																		<tr>
																			{
																				this.state.listtime?.map((time) => {

																					if (time.is_active === 0) {
																						timeNotActive.push(time.id);
																						return "";
																					}

																					return (
																						<th key={time.id}>{time.time_name}</th>
																					)
																				})
																			}
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			{
																				this.state.todaymenu.time?.map((timeObj, index) => {
																					if (timeNotActive.some(time => Number(time) === Number(timeObj.id_times))) {
																						return "";
																					}

																					return (
																						<td key={index}>
																							{
																								timeObj.food.map((food) => {
																									return (
																										<p key={food.id}>
																											{food.food_name}
																										</p>
																									)
																								})
																							}
																						</td>
																					)
																				})
																			}
																		</tr>
																	</tbody>
																</Table>
																:
																<div className="text-center mt-4">Không có dữ liệu</div>
															:
															<div className="d-flex justify-content-center align-items-center mt-3">
																<div className="spinner-border text-primary" role="status">
																	<span className="sr-only">Đang tải thực đơn trong ngày...</span>
																</div>
															</div>
													}
												</Col>

												<Col xs={12} md={6} className="mt-5 mt-md-0">
													<h6>Danh sách đi chợ dự kiến: {`${dayName} (${this.stringDate(today)})`}</h6>
													{
														!this.state.isLoadingVoucherMar
															?
															<div style={{ maxheight: '200px', overflowY: 'auto' }}>
																{
																	this.state.voucherMarket &&
																		this.state.voucherMarket.ingredent?.length > 0 &&
																		this.state.listday.some(day => Number(day.stt_day) === Number(this.state.todaymenu.stt_day) && Number(day.is_active) === 1)
																		?
																		<Table striped size='sm'>
																			<thead style={{ position: 'sticky', top: '0' }}>
																				<tr style={{ backgroundColor: 'white' }}>
																					<th scope="col">STT</th>
																					<th scope='col'>Tên mặt hàng</th>
																					<th scope='col'>Đơn vị</th>
																					<th scope="col">Số lượng</th>
																				</tr>
																			</thead>
																			<tbody>
																				{
																					this.state.voucherMarket.ingredent?.sort(function (a, b) {
																						return a.ingredent_type - b.ingredent_type;
																					}
																					)
																						.map((item, index) => {
																							return (
																								<tr key={index}>
																									<td>{index + 1}</td>
																									<td>{item.ingredent_name} {Number(item.ingredent_type) === 2 ? " (Kho)" : ""}</td>
																									<td>{item.unit.unit_name}</td>
																									<td className="text-end">
																										<CurrencyFormat value={formatter.format(item.count)} displayType={'text'} thousandSeparator={true} isNumericString />
																									</td>
																								</tr>
																							)
																						})
																				}
																			</tbody>
																		</Table>
																		:
																		<div className="text-center mt-4">Không có dữ liệu</div>
																}
															</div>
															:
															<div className="d-flex justify-content-center align-items-center mt-3">
																<div className="spinner-border text-primary" role="status">
																	<span className="sr-only">Đang tải danh sách đi chợ...</span>
																</div>
															</div>
													}
												</Col>
											</Row>
										</Tab>
									);
								})}
						</Tabs>
					</Card.Body>
				</Card>
			</Container>
		)

	}
}

export default Dashboard