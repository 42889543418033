import { toast } from "react-toastify";
import { message } from "../../../constants/message";
import { QUANTITIATIVE_ZONE } from "../../../constants";

const groupArrayByField = (arr, key) => {
  return [
    ...arr
      .reduce(
        (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
        new Map()
      )
      .values(),
  ];
};

const priceFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const priceFormatterNoDecimal = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const dateFormatter = (date) => {
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear()
  );
};

const takeDecimalNumber = (num, n) => {
  let base = 10 ** n;
  let result = Math.round(num * base) / base;
  return result;
};

const getDate = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};
const getTime = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
    //  +
    // ":" +
    // ("0" + date.getSeconds()).slice(-2)
  );
};

const toastErrorMessage = (error) => {
  let mess = "";

  switch (error.response.status) {
    case 400:
      mess = message.errorBadRequest;
      break;
    case 500:
      mess = error.response.data.messages || message.errorInternalServer;
      break;
    default:
      mess = message.errorUnknown;
  }

  return toast(mess, { type: "error", autoClose: 1000 });
};

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường
  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
  return str;
};

const findeQuantitativeZone = (id_age) => {
  if (id_age >= 1 && id_age <= 3) {
    return QUANTITIATIVE_ZONE[0];
  } else if (id_age === 4) {
    return QUANTITIATIVE_ZONE[1];
  } else if (id_age >= 5) {
    return QUANTITIATIVE_ZONE[2];
  }
  return null;
};

const isInRange = (number, attribute, data) => {
  if (data?.hasOwnProperty(attribute)) {
    const min = data[attribute].MIN;
    const max = data[attribute].MAX;
    return number < min || number > max;
  }
  return false;
};

export {
  groupArrayByField,
  priceFormatter,
  dateFormatter,
  takeDecimalNumber,
  getDate,
  toastErrorMessage,
  toSlug,
  priceFormatterNoDecimal,
  getTime,
  findeQuantitativeZone,
  isInRange,
};
