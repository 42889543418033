import React, { Component } from "react";
import axios from "axios";
import DataTables from "../datatable/DataTables";

import {
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab, Form
} from "react-bootstrap";

class BankIngredent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredents: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      deps: [],
      addModalShow: false,
      editModalShow: false,
      alertShow: false,
      ingredent: [],
      selectedRows: [],
      tabkey: "1",

      checkload: true
    };
  }

  componentDidMount() {
    document.title = "Ngân hàng nguyên liệu";
    this.getIngredentsData(this.state.tabkey);
  }

  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }

  changeTab = (key) => {
    this.setState({ tabkey: key, checkload: true })
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" })
  }

  getIngredentsData = (key) => {
    axios.get(`/viewIngredentLibrary?type_subspecies=${key}`).then((res) => {
      if (res.data.status === true) {
        this.setState({ ingredents: res.data.data });
        this.handleIngredentSearch(res.data.data, this.state.ingredentTextSearch)
      }
      this.setState({ checkload: false });
    });
  };

  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text
    })
    if (text !== "") {
      root_ = this.state.ingredents.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim())));
    }
    this.setState({
      ingredents_search: root_
    })
  }

  dataTable() {

    const columns = [
      {
        name: "MS",
        selector: (row) => row.ingredent_code,
        sortable: true,
      },
      {
        name: "Nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
        style: {
          div: {
            width: '250px',
          }
        }
      },

      {
        name: "TL thải bỏ",
        selector: (row) => this.formatter.format(row.ingredent_ratio),
        sortable: true,
        center: true,

      },
      {
        name: "KL đi chợ",
        selector: (row) => this.formatter.format(row.ingredent_RealVolume),
        sortable: true,
        center: true,
      },

      {
        name: "Năng lượng",
        selector: (row) => this.formatter.format(row.ingredent_energy),
        sortable: true,
        center: true,
      },
      {
        name: "Protein",
        selector: (row) => this.formatter.format(row.ingredent_protein),
        center: true,
        sortable: true,
      }, {
        name: "Lipid",
        selector: (row) => this.formatter.format(row.ingredent_lipid),
        center: true,
        sortable: true,

      }, {
        name: "Glucid",
        selector: (row) => this.formatter.format(row.ingredent_glucid),
        center: true,
        sortable: true,
      }
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item, i) => {

        data.push({
          ingredent_code: item.ingredent_code,
          ingredent_name: <div style={{width: "100%"}} className="text-wrap">{item.ingredent_name}</div>,
          ingredent_ratio: item.ingredent_ratio * 100,
          ingredent_RealVolume: item.ingredent_RealVolume,
          ingredent_energy: item.ingredent_energy,
          ingredent_protein: item.ingredent_protein,
          ingredent_lipid: item.ingredent_lipid,
          ingredent_glucid: item.ingredent_glucid
        });

        return item;
      });
    }

    return (
      <>
        <DataTables data={data} columns={columns} selectedRows={selectedRows}
        />
      </>

    );
  }

  renderTableIngredent() {
    return (
      <>
        {this.state.ingredents_search.length > 0 ?

          this.dataTable()
          :
          <div className="text-center mt-4">Không có dữ liệu</div>
        }
      </>
    )
  }
 
  renderIngredentList() {
    // const addModalClose = () => {
    //   this.setState({ addModalShow: false });
    //   this.getIngredentsData(this.state.tabkey);
    // };
    // const editModalClose = () => {
    //   this.setState({ editModalShow: false });
    //   this.getIngredentsData(this.state.tabkey);
    // };

    return (
      <>
        <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>

          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách nguyên liệu
          </Card.Title>
        </Card.Header>

        <Card.Body >
          <Row>
            <Col md={4}>{this.renderSelectedRows()}</Col>
          </Row>

          <Col className="mb-2">
            <Form.Control style={{ height: 39 }}
              type="text"
              placeholder="Tìm nguyên liệu"
              value={this.state.ingredentTextSearch}
              onChange={
                (e) => {
                  this.handleIngredentSearch(this.state.ingredents, e.target.value)
                }
              }
            />
          </Col>

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              this.changeTab(k)
            }}
            className="mb-3"
          >

            <Tab
              key={1}
              title="Động vật"
              eventKey={1}
            >

              {this.state.checkload ? this.loading() : this.renderTableIngredent()}
            </Tab>
            <Tab
              key={2}
              title="Thực vật"
              eventKey={2}
            >
              {this.state.checkload ? this.loading() : this.renderTableIngredent()}
            </Tab>
          </Tabs>
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Ngân hàng nguyên liệu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card
                className="mb-4"
              >{this.renderIngredentList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BankIngredent;
