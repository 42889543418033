import React, { Component } from "react";
import axios from "axios";
import { Container, Breadcrumb, Card, Row, Col, Form, Table } from "react-bootstrap";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
// import swal from "sweetalert";
// import * as XLSX from "xlsx-js-style";

// Components
import NutritionTable from "../../admin/food_portion/NutritionTable";
// import MarketBill from "./MarketBill";
// import NutritionResult from "./NutritionResult";
// import NutritionSetupResult from "./NutritionSetupResult";

const initialDay = [
    { id: 1, day_name: "Thứ 2" },
    { id: 2, day_name: "Thứ 3" },
    { id: 3, day_name: "Thứ 4" },
    { id: 4, day_name: "Thứ 5" },
    { id: 5, day_name: "Thứ 6" },
    { id: 6, day_name: "Thứ 7" },
    { id: 0, day_name: "Chủ nhật" }
]

class PortionCalculate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantitative: {},
            tmpQuantitative: {},
            isChangeQuantitative: false,
            isChangeIngredient: false,
            isChangePrice: false,
            wasChangedPrice: false,

            isLoading: false,

            listDay: [],
            daySelect: 0,

            listTimeSetting: [],
            timeOption: [],
            timeSelect: { value: 0, label: "" },

            listUnit: [],



            ortherFee: 0,
            moneySoonDay: 0,
            totalChild: 0,

            start: new Date(),
            term: new Date(),

            weekInMonth: "",
            //#########
            settingData: [],

            groupOption: [],
            groupSelect: { value: 0, label: "" },
            ingredentDatas: [],
            dayDatas: [],
        };
    }

    async componentDidMount() {
        document.title = 'Tính khẩu phần ăn';

        let initialDate = new Date();
        let initialWeek = this.setDate(initialDate);

        let today = new Date();
        let todayIs = today.getDay();

        this.setState({
            daySelect: todayIs,
            weekInMonth: (0 | initialWeek[0].getDate() / 7) + 1,
            listDay: initialDay.map((day, index) => {
                return {
                    id: day.id,
                    day_name: day.day_name,
                    day: initialWeek[index]
                }
            })
        });

        this.getQuantitativeVoucher(initialWeek[0], initialWeek[initialWeek.length - 1], todayIs);
        this.getSetting(today);

        // this.getStudentListsetting();
    }

    getSetting = (date) => {
        axios.get(`/setting-show?setting_date=${parseInt(date.getTime() / 1000)}`)
            .then(res => {
                if (res.data.errCode === 0) {
                    this.setState({
                        settingData: res.data.setting

                    });
                } else {
                    toast('Không tìm thấy cài đặt.', { type: "error", autoClose: 1000 });
                }
            });
    }

    getQuantitativeVoucher = (startDate, endDate, dayId) => {
        axios.get(`/menu-show?start_at=${startDate.getTime() / 1000}&term_at=${endDate.getTime() / 1000}&id_day=${dayId}`)
            .then(res => {
                if (res.data.errCode === 0) {

                    let groups = res.data.groups.map(({
                        id,
                        group_name
                    }) => ({
                        value: id,
                        label: group_name
                    }));

                    this.setState({
                        dayDatas: res.data.days[0],
                        groupOption: groups,
                        groupSelect: groups[0],
                    });

                    this.getIngredents(res.data.days[0], parseInt(groups[0].value));
                }

            });
    }

    getIngredents = (days, groupId) => {
        console.log(('groupId>>', groupId));
        console.log(('days>>', days));

        const uniqueIngredents = {}

        if (days.times.length > 0) {
            days.times.forEach((time, iTime) => {
                if (time.foods.length > 0) {
                    time.foods.forEach((food, iFood) => {
                        if (food.groups.length > 0) {
                            const groupData = food.groups.filter(item => item.id === groupId);
                            groupData.forEach((group, iGroup) => {
                                if (group.ingredents.length > 0 && group.id === groupId) {
                                    group.ingredents.forEach((ingredent, iIngredent) => {
                                        // let indexIng = uniqueIngredents.findIndex(item => item.id === ingredent.id)
                                        // console.log('indexIng>>', indexIng);

                                        // if (indexIng === -1) {
                                        //     ingredent.ing_quantity = parseFloat(ingredent.ing_quantity || 0);
                                        //     uniqueIngredents.push(ingredent);
                                        // } else {
                                        //     uniqueIngredents[indexIng].ing_quantity += parseFloat(ingredent.ing_quantity);
                                        // }

                                        if (!uniqueIngredents[ingredent.id]) {
                                            uniqueIngredents[ingredent.id] = { ...ingredent };
                                            uniqueIngredents[ingredent.id].total_quantity = parseFloat(ingredent.ing_quantity || 0);
                                        } else {
                                            uniqueIngredents[ingredent.id].total_quantity += parseFloat(ingredent.ing_quantity);
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            });
        }

        this.setState({ ingredentDatas: Object.values(uniqueIngredents) });
    }

    setDate = (theDate) => {
        const week = this.dates(theDate);

        this.setState({
            start: week[0],
            term: week[week.length - 1]
        })

        return week;
    }

    dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

    handleCheck = (day) => {
        this.setState({
            daySelect: Number(day),
            isChangeQuantitative: false,
            isChangeIngredient: false,
            isChangePrice: false,
            wasChangedPrice: false,
        });

        this.getQuantitativeVoucher(this.state.start, this.state.term, day)
    }

    RenderOption() {
        return (
            <>
                <Card.Title>Chọn ngày tính khẩu phần ăn</Card.Title>

                <div className="d-none d-md-flex justify-content-start my-3">
                    {
                        this.state.listDay.map((dayItem, i) => {
                            let toDayIs = dayItem.id % 7;

                            if (toDayIs === 0) {
                                toDayIs = 7;
                            }

                            return (
                                <div key={i} className="d-flex me-md-4 me-xl-5">
                                    <Form.Check
                                        type="radio"
                                        value={dayItem.id}
                                        checked={this.state.daySelect === Number(dayItem.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleCheck(e.target.value)
                                            }
                                        }}
                                        name="day"
                                        className="me-2"
                                    />
                                    {dayItem.day_name}
                                </div>

                            );
                        })}
                </div>

                <div className="d-block d-sm-flex justify-content-between mb-3">
                    <div className="d-flex">
                        {
                            this.state.groupOption.length !== 0
                                ?
                                <Select
                                    options={this.state.groupOption}
                                    value={this.state.groupSelect}
                                    placeholder="Chọn nhóm"
                                    onChange={(choice) => {
                                        // let orther = 0;
                                        // let money = 0;
                                        // let totalChild = 0;

                                        // this.state.quantitative.time.map(timeItem => {
                                        //     if (timeItem.is_calcultor.length !== 0 && Number(timeItem.id_time) === this.state.timeSelect.value) {
                                        //         return timeItem.is_calcultor.map(calculateItem => {
                                        //             if (Number(calculateItem.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === this.state.timeSelect.value)[0]?.stt_time) && Number(calculateItem.id_group) === Number(choice.value)) {
                                        //                 orther = Number(calculateItem.price_orther);
                                        //                 money = Number(calculateItem.voucher_price);
                                        //                 totalChild = Number(calculateItem.count_student);
                                        //             }

                                        //             return calculateItem;
                                        //         })
                                        //     }

                                        //     return timeItem;
                                        // })

                                        this.setState({
                                            groupSelect: choice,
                                            // ingredentDatas: [],
                                            // moneySoonDay: money,
                                            // totalChild: totalChild
                                        });

                                        this.getIngredents(this.state.dayDatas, parseInt(choice.value));
                                    }}
                                // isDisabled={this.state.isChangeQuantitative || this.state.isChangePrice}
                                />
                                : ""
                        }
                    </div>
                </div>

            </>
        )
    }

    RenderIngredientTable() {
        // const formatter = new Intl.NumberFormat('en-US', {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2,
        // });

        // const bigDetailArray = this.ingredientPortionArray();
        // let ingreBigArray = [];
        // const timeObj = this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect?.value)[0];

        // if (bigDetailArray.length === 0) {
        //     return (
        //         <div className="d-flex justify-content-center">
        //             Không có dữ liệu
        //         </div>
        //     )
        // }

        // timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
        //     ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
        //     return foodItem;
        // })

        // const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        // let totalMoney = 0;
        // let ingredientIndex = 0;

        { console.log('this.state.ingredentDatas>>', this.state.ingredentDatas) }


        return (
            <Table bordered responsive>
                <thead className="align-middle text-center">
                    <tr>
                        <th>STT</th>
                        <th>Tên thực phẩm</th>
                        <th>ĐVT</th>
                        <th>Giá tiền (đ)</th>
                        <th>1 trẻ(g)</th>
                        <th>Ăn được(g)</th>
                        <th>Đi chợ(g)</th>
                        <th>Đi chợ theo ĐVT</th>
                        <th>Giá 100g (đ)</th>
                        <th>Thành tiền (đ)</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.ingredentDatas.length > 0
                        ? this.state.ingredentDatas.map((ing, iIng) => {
                            return (
                                <tr key={`ing_${iIng}`}>
                                    <td>{iIng + 1}</td>
                                    <td>{ing.ingredent_name}</td>
                                    <td>{ing.unit_market_name}</td>
                                    <td>{ing.ing_price_expect}</td>
                                    <td>{ing.total_quantity}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                </tr>)
                        })
                        : <></>
                    }

                    {/* {
                        aIngredientArray.map((arrayItem, index) => {
                            let realMassOfOneChild = 0; // KL đi chợ 1 trẻ
                            let pureMassOfOneChild = 0; // KL ăn được 1 trẻ
                            let exchangeValue = 0;

                            let realMass = 0; // Tổng KL đi chợ
                            let pureMass = 0; // Tổng KL ăn được

                            let hunGramPrice = 0; // Đơn giá trên 100g
                            let ingredientPrice = 0; // Thành tiền

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                                // KL đi chợ, ăn được 1 trẻ
                                if (selectedGroup) {
                                    if (ingredientItem.unit.is_root === 0) {
                                        realMassOfOneChild += Number(selectedGroup.amount);
                                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    } else {
                                        realMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                        pureMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_FineMass);
                                    }

                                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                }

                                return ingredientItem;
                            })

                            if (realMassOfOneChild === 0) {
                                return "";
                            }

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

                                if (selectedGroup) {
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneChild * this.state.totalChild;

                                    // Tính tổng KL ăn được
                                    pureMass = pureMassOfOneChild * this.state.totalChild;
                                }

                                return ingredientItem;
                            })

                            // Tính thành tiền
                            if (exchangeValue !== 0) {
                                ingredientPrice = Math.round((realMass / exchangeValue) * Number(arrayItem[0].nutrients.price));
                            }

                            // Tính đơn giá / 100g
                            if (realMass !== 0) {
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMass);
                            }

                            totalMoney += Math.round(ingredientPrice);

                            ingredientIndex++;

                            return (
                                <tr key={index} className="align-middle text-end">
                                    <td className="text-center">{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>
                                        {this.state.listUnit?.filter(item => Number(item.id) === Number(arrayItem[0].unit.id_unit))[0]?.unit_name}
                                    </td>
                                    <td style={{ minWidth: 100 }}>
                                        <CurrencyFormat
                                            value={arrayItem[0].nutrients.price}
                                            displayType={this.state.isChangePrice ? 'input' : 'text'}
                                            thousandSeparator={true}
                                            onValueChange={(values) => {
                                                this.setState({
                                                    quantitative: {
                                                        ...this.state.quantitative,
                                                        time: this.state.quantitative.time.map(timeObj => {
                                                            return {
                                                                ...timeObj,
                                                                detail: timeObj.detail.map(detailObj => {
                                                                    return {
                                                                        ...detailObj,
                                                                        ingrendent: detailObj.ingrendent.map(ingredientObj => {

                                                                            if (Number(ingredientObj.id_ingredent) === Number(arrayItem[0].id_ingredent)) {
                                                                                return {
                                                                                    ...ingredientObj,
                                                                                    nutrients: {
                                                                                        ...ingredientObj.nutrients,
                                                                                        price: values.value
                                                                                    }
                                                                                }
                                                                            }

                                                                            return ingredientObj;
                                                                        })
                                                                    }
                                                                })
                                                            };
                                                        })
                                                    },
                                                    wasChangedPrice: true
                                                })
                                            }}
                                            className={this.state.isChangePrice ? "form-control text-end" : ""}
                                            allowNegative={false}
                                        //isNumericString
                                        />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMassOfOneChild)} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(pureMass)} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass)} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={hunGramPrice} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ingredientPrice} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    } */}
                    <tr className="align-middle text-end">
                        <th></th>
                        <th className="text-start">Tổng tiền</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            {/* <CurrencyFormat value={totalMoney} displayType={'text'} thousandSeparator={true} /> */}
                        </th>
                    </tr>
                </tbody>
            </Table>
        )
    }

    RenderFilterForm() {
        let totalChild = this.state.totalChild;
        let totalMoney = 0;

        // const bigDetailArray = this.ingredientPortionArray();

        // if (bigDetailArray.length !== 0) {
        //     this.state.quantitative?.time?.filter(item => item.id_time === this.state.timeSelect.value)[0].detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
        //         detailItem.ingrendent.map(ingredientItem => {
        //             const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.state.groupSelect.value)[0];

        //             if (selectedGroup && Number(ingredientItem.nutrients.redemption_value) !== 0) {
        //                 if (ingredientItem.unit.is_root === 0) {
        //                     totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume);
        //                 } else {
        //                     totalMoney += this.state.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price)
        //                 }
        //             }
        //             return ingredientItem;
        //         })
        //         return detailItem;
        //     })

        //     totalMoney = Math.round(totalMoney);
        // }

        // const timePoetry = Number(this.state.quantitative?.time?.find(item => Number(item.id_time) === this.state.timeSelect.value).poetry)


        return (
            <>
                <Card className="px-2 py-1">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng số trẻ:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    // value={totalChild}
                                    thousandSeparator={true}
                                    // onValueChange={(values) => {
                                    //     this.setState({
                                    //         totalChild: Number(values.value)
                                    //     })
                                    // }}
                                    className="form-control text-end"
                                    allowNegative={false}
                                //isNumericString
                                // disabled={
                                //     this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                // }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số tiền cho mỗi trẻ:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    // value={timePoetry}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    //isNumericString
                                    displayType="text"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng số tiền thu:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    // value={timePoetry * totalChild}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                //isNumericString

                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Các chi phí khác / 1 trẻ
                            </Form.Label>
                            <Col xs="5" sm="5">
                                <CurrencyFormat
                                    // value={
                                    // this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.is_calcultor
                                    //     .some(item => Number(item.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.stt_time)
                                    //         && Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu) === Number(item.id_menu)
                                    //         && Number(item.id_group) === Number(this.state.groupSelect.value)
                                    //     )
                                    //     ? 
                                    //     this.state.ortherFee
                                    //     :
                                    //     this.state.ortherFee === 0 ? "" : this.state.ortherFee
                                    // }
                                    thousandSeparator={true}
                                    // onValueChange={(values) => {
                                    // this.setState({
                                    //     ortherFee: Number(values.value)
                                    // })
                                    // }}
                                    className="form-control text-end"
                                    allowNegative={false}
                                //isNumericString
                                // disabled={
                                // this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                // }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng chi phí khác:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    value={Number(this.state.ortherFee) * totalChild}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    //isNumericString
                                    displayType="text"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số dư đầu kì:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    // value={
                                    // this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.is_calcultor
                                    //     .some(item => Number(item.id_time) === Number(this.state.quantitative.time?.filter(item => Number(item.id_time) === Number(this.state.timeSelect.value))[0]?.stt_time)
                                    //         && Number(this.state.quantitative.idGroup.filter(group => Number(group.id) === this.state.groupSelect.value)[0].id_menu) === Number(item.id_menu)
                                    //         && Number(item.id_group) === Number(this.state.groupSelect.value)
                                    //     )
                                    //     ?
                                    //     this.state.moneySoonDay
                                    //     :
                                    //     this.state.moneySoonDay === 0 ? "" : this.state.moneySoonDay
                                    // }
                                    thousandSeparator={true}
                                    // onValueChange={(values) => {
                                    // this.setState({
                                    //     moneySoonDay: values.value
                                    // })
                                    // }}
                                    className="form-control text-end"
                                    allowNegative={true}
                                //isNumericString
                                // disabled={
                                // this.state.quantitative.idGroup?.some(group => Number(group.id) === Number(this.state.groupSelect.value) && group.is_market)
                                // }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tiền chi trong buổi:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    // value={Number(this.state.ortherFee) * totalChild + totalMoney}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                //isNumericString
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tiền chi cho mỗi trẻ:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center fw-bold">
                                <CurrencyFormat
                                    // value={totalChild !== 0 ? Math.round((Number(this.state.ortherFee) * totalChild + totalMoney) / totalChild) : 0}
                                    thousandSeparator={true}
                                    displayType="text"
                                    allowNegative={false}
                                //isNumericString
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Số dư cuối kì:
                            </Form.Label>
                            <Col xs="4" sm="4" className="d-flex align-items-center">
                                <CurrencyFormat
                                    // value={Number(this.state.moneySoonDay) - (Number(this.state.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild)}
                                    thousandSeparator={true}
                                    displayType="text"
                                //isNumericString
                                />
                            </Col>
                            <Col xs="1" sm="1" className="d-flex align-items-center p-0" title="Sao chép số dư cuối kì" style={{ cursor: "pointer" }}
                            // onClick={() => {
                            //     navigator.clipboard.writeText(Number(this.state.moneySoonDay) - (Number(this.state.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild));
                            //     toast("Đã sao chép số dư cuối kì", { type: "success", autoClose: 1000 });
                            // }}
                            >
                                <i className="fa-solid fa-copy"></i>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column xs="7" sm="7">
                                Tổng tiền chênh lệch:
                            </Form.Label>
                            <Col xs="5" sm="5" className="d-flex align-items-center">
                                <CurrencyFormat
                                    // value={(timePoetry * totalChild) - (Number(this.state.ortherFee) * totalChild + totalMoney)}
                                    thousandSeparator={true}
                                    displayType="text"
                                //isNumericString
                                // style={
                                //     (timePoetry * totalChild) >= (Number(this.state.ortherFee * totalChild) + totalMoney)
                                //         ? { color: "green" }
                                //         : { color: "red" }}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Card>
            </>
        )
    }


    render() {
        return (
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tính khẩu phần ăn</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-sm-flex justify-content-between">
                            <div className="d-block d-sm-flex">
                                <Card.Title className="me-3 pt-1">
                                    Tính khẩu phần ăn {this.stringLastDate(this.state.listDay.filter((item) => item.id === this.state.daySelect)[0]?.day || new Date())}
                                </Card.Title>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{
                                            border: "none",
                                            width: 39,
                                            backgroundColor: "rgb(16,108,252)",
                                            borderRadius: 5,
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const firstDayOfWeek = new Date(
                                                    e.target.valueAsNumber
                                                );
                                                const week =
                                                    this.setDate(
                                                        firstDayOfWeek
                                                    );

                                                this.setState({
                                                    weekInMonth: (0 | (week[0].getDate() / 7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                    isChangeQuantitative: false,
                                                    isChangeIngredient: false,
                                                    isChangePrice: false,
                                                    wasChangedPrice: false,
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    { value: 0, label: "" },
                                                    { value: 0, label: "" }
                                                );
                                            } else {
                                                const today = new Date();
                                                const week =
                                                    this.setDate(today);

                                                this.setState({
                                                    weekInMonth:
                                                        (0 | (week[0].getDate() / 7)) + 1,
                                                    listDay: initialDay.map(
                                                        (day, index) => {
                                                            return {
                                                                id: day.id,
                                                                day_name:
                                                                    day.day_name,
                                                                day: week[
                                                                    index
                                                                ],
                                                            };
                                                        }
                                                    ),
                                                    isChangeQuantitative: false,
                                                    isChangeIngredient: false,
                                                    isChangePrice: false,
                                                    wasChangedPrice: false,
                                                });

                                                this.getQuantitativeVoucher(
                                                    week[0],
                                                    week[week.length - 1],
                                                    this.state.daySelect,
                                                    { value: 0, label: "" },
                                                    { value: 0, label: "" }
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} md={8} >
                                {this.RenderOption()}
                                {this.RenderIngredientTable()}
                                {/* {
                                    !this.state.isLoading
                                        ?
                                        bigDetailArray.length !== 0 ? (
                                            <>
                                                {}

                                                {
                                                    this.state.isChangeQuantitative
                                                        ?
                                                        this.RenderQuantitativeChangeTable()
                                                        : ""
                                                }
                                            </>
                                        ) : ""
                                        :
                                        ""
                                } */}
                            </Col>

                            <Col xs={12} md={4}>
                                {/* {
                                    !this.state.isLoading
                                        ?
                                        bigDetailArray.length !== 0 ? (
                                            <div className="sticky-md-top" style={{ top: "5rem" }}>
                                                <NutritionTable
                                                    quantitative={this.state.quantitative}
                                                    timeSelect={this.state.timeSelect}
                                                    groupSelect={this.state.groupSelect}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )
                                        : ""
                                } */}

                                <div className="sticky-md-top" style={{ top: "5rem" }}>
                                    <NutritionTable
                                        quantitative={this.state.quantitative}
                                        timeSelect={this.state.timeSelect}
                                        groupSelect={this.state.groupSelect}
                                    />
                                </div>

                                {this.RenderFilterForm()}
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default PortionCalculate;