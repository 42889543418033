import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import * as XLSX from "xlsx-js-style";

import { Breadcrumb, Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import QuantitativeKitchenTable from "./QuantitativeKitchenTable";

import { dateFormatter } from "../../utils/helpers";
import { exportQuantitativeKitchen, exportWeekQuantitativeKitchen } from "./exportFunctions";
import WeekQuantitativeKitchenTable from "./WeekQuantitativeKitchenTable";
import Check from "../../other/Check";

const QuantitativeKitchen = () => {
	const [school, setSchool] = useState({});

	const [listQuantitatives, setListQuantitatives] = useState([]);
	const [quantitative, setQuantitative] = useState({
		menu_date: new Date().getTime() / 1000,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	const [classGroups, setClassGroups] = useState([]);
	const [selectedClassGroup, setSelectedClassGroups] = useState({});

	const [listDays, setListDays] = useState([]);
	const [selectedDay, setSelectedDay] = useState({});
	const [listTimes, setListTimes] = useState([]);

	const [weekInMonth, setWeekInMonth] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [groupExcel, setGroupExcel] = useState([])

	useEffect(() => {
		document.title = "Định lượng cho bếp";

		let initialWeek = setDatesOfWeek(new Date());
		setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);

		let todayIs = new Date().getDay();
		if (todayIs === 0) {
			todayIs = 7;
		}

		getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
		getListClassGroups();
		getDetailschool();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//#region  API
	const getListQuantitatives = async (startDate, endDate, dayId) => {
		setIsLoading(true);
		await axios
			.get(`menu-show`, {
				params: {
					start_at: startDate.getTime() / 1000,
					term_at: endDate.getTime() / 1000,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setGroupExcel(res.data.groups)
					setListDays(
						res.data.days.map((dayItem) => {
							return {
								id_day: dayItem.id_day,
								day_name: dayItem.day_name,
								date: new Date(dayItem.menu_date * 1000),
							};
						}),
					);
					setListTimes(res.data.times);

					setSelectedDay(
						res.data.days.find((listQuantitativesItem) => listQuantitativesItem.id_day === dayId)
							? res.data.days.find((listQuantitativesItem) => listQuantitativesItem.id_day === dayId)?.id_day
							: res.data.days[0].id_day,
					);
					setListQuantitatives(res.data.days);
					setQuantitative(
						res.data.days.find((listQuantitativesItem) => listQuantitativesItem.id_day === dayId)
							? res.data.days.find((listQuantitativesItem) => listQuantitativesItem.id_day === dayId)
							: res.data.days[0],
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getListClassGroups = async () => {
		await axios
			.get(`/group-show`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setClassGroups(res.data.groups);
					setSelectedClassGroups(res.data.groups[0]);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getDetailschool = async () => {
		await axios
			.get(`/detailSchool`)
			.then((res) => {
				if (res.data.status === true) {
					setSchool(res.data.data[0]);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	// #endregion

	//#region Logic
	const exportQuantitativeExcel = () => {
		const wb = XLSX.utils.book_new();

		let ingredientQuantity = 0;

		quantitative.times?.map((timeItem) => {
			let timeQuantityIngredient = 0;
			if (timeItem.foods.length !== 0) {
				timeItem.foods?.map((foodItem) => {
					if (foodItem.groups[0].ingredents.length === 0) {
						timeQuantityIngredient++;

						return foodItem;
					}

					return foodItem.groups[0]?.ingredents?.map((ingredientItem) => {
						timeQuantityIngredient++;
						return ingredientItem;
					});
				});
			} else {
				timeQuantityIngredient = 1;
			}

			ingredientQuantity += timeQuantityIngredient;
			return timeItem;
		});

		const ws = exportQuantitativeKitchen(quantitative, ingredientQuantity, groupExcel);

		XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");
		XLSX.writeFile(wb, `Định lượng cho bếp (${dateFormatter(new Date(quantitative.menu_date * 1000))}).xlsx`);
	};

	const exportWeekQuantitativeExcel = () => {
		const wb = XLSX.utils.book_new();

		const ws = exportWeekQuantitativeKitchen(listDays.length, listTimes, listQuantitatives, selectedClassGroup);

		XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");

		XLSX.writeFile(wb, `Định lượng cho bếp (${dateFormatter(startDate)} - ${dateFormatter(endDate)}).xlsx`);

		setModalShow(false)
	};

	const handleXportQuantitativeKitchen = () => {
		swal({
			title: `Bạn muốn xuất file định lượng cho bếp`,
			text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
			icon: "info",
			buttons: ["Đóng", "Xuất"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				exportQuantitativeExcel();
			}
		});
	};

	const handleXportWeekQuantitativeKitchen = () => {
		exportWeekQuantitativeExcel();
	};

	const datesOfWeek = (current) => {
		var week = [];
		// Starting Monday not Sunday
		current.setDate(current.getDate() - current.getDay() + 1);
		current.setHours(0, 0, 0, 0);
		for (var i = 0; i < 7; i++) {
			week.push(new Date(current));
			current.setDate(current.getDate() + 1);
			current.setHours(0, 0, 0, 0);
		}
		return week;
	};

	const setDatesOfWeek = (theDate) => {
		const week = datesOfWeek(theDate);

		setStartDate(week[0]);
		setEndDate(week[week.length - 1]);
		return week;
	};
	// #endregion

	// Render
	const RenderOption = () => {
		return (
			<section className="mb-3">
				<Card.Title>Chọn ngày xuất phiếu định lượng cho bếp</Card.Title>

				<Row className="my-3">
					{listDays.map((dayItem) => {
						return (
							<Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
								<Form.Check
									type="radio"
									value={dayItem.id_day}
									checked={selectedDay === dayItem.id_day}
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedDay(Number(e.target.value));
											setQuantitative(listQuantitatives.find((quantitativeItem) => quantitativeItem.id_day === Number(e.target.value)));
										}
									}}
									name="day"
									className="me-2"
								/>
								{dayItem.day_name}
							</Col>
						);
					})}
				</Row>
			</section>
		);
	};

	const exportStore = (id) => {
		let data = {
			id_menu_day: id
		}

		axios({
			method: "post",
			url: "store-export-kitchen",
			data: data,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					toast(res.data.message, { type: "error", autoClose: 1000 });
				}
			})
			.catch((error) => {
				toast(error.response.data.messages, {
					type: "error",
					autoClose: 1000,
				});
			});
	}
	return (
		<Container fluid>
			<Breadcrumb>
				<Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
				<Breadcrumb.Item active>Định lượng cho bếp</Breadcrumb.Item>
			</Breadcrumb>
			<Card>
				<Card.Header>
					<div className="d-block d-md-flex justify-content-between">
						<div className="d-block d-md-flex align-items-center">
							<Card.Title className="me-2"> Định lượng cho bếp {dateFormatter(new Date(quantitative.menu_date * 1000))}</Card.Title>

							{/* Select Week */}
							<input
								type="week"
								name="week"
								style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
								className="me-2 mb-3 mb-md-0"
								onChange={(e) => {
									const today = e.target.value ? new Date(e.target.valueAsNumber) : new Date();
									const week = setDatesOfWeek(today);
									setWeekInMonth((0 | (week[0].getDate() / 7)) + 1);

									getListQuantitatives(week[0], week[6], selectedDay);
								}}
							/>
						</div>

						<div className="d-flex">
							<Button size="sm" variant="success" onClick={() => setModalShow(true)} className="me-2" disabled={isLoading}>
								<i className="fa-solid fa-file-export" /> Xuất file tuần
							</Button>

							<Button size="sm" variant="success" onClick={() => handleXportQuantitativeKitchen()} className="me-2" disabled={isLoading}>
								<i className="fa-solid fa-file-export" /> Xuất file ngày
							</Button>

							{new Check().permission(["48"]) ?
								<Button size="sm" variant="danger"
									onClick={() => {
										swal({
											title: `Bạn muốn xuất kho cho bếp`,
											text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
											icon: "info",
											buttons: ["Đóng", "Xuất"],
											successMode: true,
										}).then((ok) => {
											if (ok) {
												exportStore(listQuantitatives.find((quantitativeItem) => quantitativeItem.id_day === Number(selectedDay))?.id)
											}
										});

									}}
									disabled={isLoading}>
									<i className="fa-solid fa-arrow-up-from-water-pump" /> Xuất kho cho bếp
								</Button>
								: <></>}
						</div>
					</div>
				</Card.Header>

				<Card.Body>
					{isLoading ? (
						<div className="d-flex justify-content-center my-5">
							<Spinner animation="border" role="status" variant="primary" style={{ width: "3rem", height: "3rem" }}>
								<span className="visually-hidden">Đang tải...</span>
							</Spinner>
						</div>
					) : (
						<Row>
							{RenderOption()}

							<QuantitativeKitchenTable quantitative={quantitative} />

							{/* {modalShow ? ( */}
							<WeekQuantitativeKitchenTable
								school={school}
								weekInMonth={weekInMonth}
								startDate={startDate}
								endDate={endDate}
								listQuantitatives={listQuantitatives}
								listDays={listDays}
								listTimes={listTimes}
								classGroups={classGroups}
								selectedClassGroup={selectedClassGroup}
								setSelectedClassGroups={setSelectedClassGroups}
								onHide={() => setModalShow(false)}
								show={modalShow}
								handleExport={handleXportWeekQuantitativeKitchen}
							/>
							{/* ) : (
								""
							)} */}
						</Row>
					)}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default QuantitativeKitchen;
