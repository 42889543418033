import React, { Component } from "react";
import {Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

class NutritionSetupResult extends Component {
    ingredientPortionArray = () => {
        let detailArray = [];

        this.props.quantitative.time?.map(timeItem => {
            detailArray = detailArray.concat(timeItem.detail);
            return timeItem;
        })

        return detailArray;
    }

    group = (arr, key) => {
        return [...arr.reduce( (acc, o) => 
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
        , new Map()).values()];
    }

    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
    
    render(){  
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });

        const bigDetailArray = this.ingredientPortionArray();

        if(bigDetailArray.length === 0){
            return "";
        }

        let ingreBigArray = [];
        
        const timeObj = this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect?.value)[0];

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        let totalChild = this.props.totalChild;
        let totalMoney = 0;

        let energyStat = 0; // Tổng năng lượng
        let proteinStat = 0; // Tổng protein
        let lipidStat = 0; // Tổng lipid
        let glucidStat = 0; // Tổng glucid

        let animalProteinStat = 0; // Tổng protein động vật
        let animalLipidStat = 0; // Tổng lipid động vật

        if(bigDetailArray.length !== 0){
            this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect.value)[0].detail.filter(item => item.ingrendent !== undefined).map(detailItem => {
                detailItem.ingrendent.map(ingredientItem => {
                    const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                    if(selectedGroup){
                        // Tính tổng tiền
                        if(ingredientItem.unit.is_root === 0){
                            totalMoney += this.props.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                        } else {
                            totalMoney += this.props.totalChild * Number(selectedGroup.amount) * Number(ingredientItem.nutrients.price)
                        }

                        let pureMassOfOneGroup = 0; // KL ăn được 1 nhóm

                        // Tính KL ăn được 1 nhóm
                        if(ingredientItem.unit.is_root === 0){
                            pureMassOfOneGroup += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                        } else {
                            pureMassOfOneGroup += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass);
                        }

                        // Tính protein và lipid động vật
                        if(ingredientItem.type_subspecies === 1){
                            animalProteinStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                            animalLipidStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                        }

                        // Tính năng lượng, protein, lipid và glucid
                        energyStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                        proteinStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                        lipidStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                        glucidStat += pureMassOfOneGroup * Number(ingredientItem.nutrients.ingredent_glucid) / 100;
                    }
                    
                    return ingredientItem;
                })
                return detailItem;
            })

            totalMoney = Math.round(totalMoney);
        }

        const timePoetry = Number(this.props.quantitative?.time?.find(item => Number(item.id_time) === this.props.timeSelect.value).poetry)
        
        const plgTotal = (proteinStat*4 + lipidStat*9 + glucidStat*4);

        let ingredientIndex = 0;

        return(
            <Table 
                bordered 
                responsive
                id="nutrition-setup-result-table"
            >
                <thead>
                    <tr>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>

                        <td className="text-end align-top">
                            {this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day_name}, ngày {this.stringLastDate(this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day || new Date())}
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>
                            KẾT QUẢ THIẾT LẬP DƯỠNG CHẤT
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <td>
                            Bữa ăn: Ăn {this.props.timeSelect.label}
                            <br />
                            Độ tuổi: {this.props.groupSelect.label}
                            <br />
                            Tổng số {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"}: {totalChild}
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>

                        <td>
                            Ăn {this.props.timeSelect.label}:
                            <br />
                            {
                                this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect.value)[0].detail?.map(detailItem => {
                                    if(detailItem.ingrendent.some(ingredient => ingredient.group.some(group => Number(group.id) === Number(this.props.groupSelect.value)))){
                                        return(
                                            <div key={detailItem.id_food}>
                                                - {detailItem.food_name}
                                                <br />
                                            </div>
                                        )
                                    }
                                    return "";
                                })
                            }
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                    </tr>
                    <tr className="align-middle">
                        <td>
                            Số Tiền Trên Mỗi Trẻ: &nbsp;
                            <CurrencyFormat 
                                suffix="(đ)" 
                                value={totalChild !== 0 ? Math.round((Number(this.props.ortherFee) * totalChild + totalMoney)/totalChild) : 0}
                                displayType={'text'} 
                                thousandSeparator={true} 
                            />
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>STT</th>
                        <th>Tên thực phẩm</th>
                        <th>Năng lượng(Kcal)</th>
                        <th>Đạm(g)</th>
                        <th>empty</th>
                        <th>Béo(g)</th>
                        <th>empty</th>
                        <th>Bột Đường(g)</th>
                        <th>1 {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"} (g)</th>
                        <th>Ăn được (g)</th>
                        <th>Đi chợ (g)</th>
                        <th>Đơn giá (đ/100g)</th>
                        <th>Thành tiền (đ)</th>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th>ĐV</th>
                        <th>TV</th>
                        <th>ĐV</th>
                        <th>TV</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        aIngredientArray.map((arrayItem, index) => {
                            // Nutrition
                            let energyOfOneIngredient = 0;
                            let animalProteinOfOneIngredient = 0;
                            let plantProteinOfOneIngredient = 0;
                            let animalLipidOfOneIngredient = 0;
                            let plantLipidOfOneIngredient = 0;
                            let glucidOfOneIngredient = 0;

                            // Mass
                            let realMassOfOneChild = 0; // KL đi chợ 1 nguyên liệu
                            let pureMassFirst = 0; // KL ăn được (Giống "Tổng KL ăn được" nhưng khai báo để tính năng lượng)
                            let exchangeValue = 0;

                            let realMass = 0; // Tổng KL đi chợ
                            let pureMass = 0; // Tổng KL ăn được <-

                            let hunGramPrice = 0; // Đơn giá
                            let ingredientPrice = 0; // Thành tiền

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];
                                
                                if(selectedGroup){
                                    // Tính KL đi chợ, ăn được trong 1 nguyên liệu
                                    if(ingredientItem.unit.is_root === 0){
                                        realMassOfOneChild += Number(selectedGroup.amount);
                                        pureMassFirst += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    } else {
                                        realMassOfOneChild += Number(selectedGroup.amount * ingredientItem.nutrients.ingredent_RealVolume);
                                        pureMassFirst += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass);
                                    }

                                    // Tính protein và lipid động vật
                                    if(ingredientItem.type_subspecies === 1){
                                        animalProteinOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                        animalLipidOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                    } else {
                                        plantProteinOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                        plantLipidOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                    }

                                    energyOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                                    glucidOfOneIngredient = pureMassFirst * Number(ingredientItem.nutrients.ingredent_glucid) / 100;
                                    
                                    exchangeValue = Number(ingredientItem.nutrients.ingredent_RealVolume);
                                }

                                return ingredientItem;
                            })

                            if(energyOfOneIngredient === 0 || realMassOfOneChild === 0){
                                return "";
                            }

                            arrayItem.map(ingredientItem =>{
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                                if(selectedGroup){                       
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneChild * this.props.totalChild;

                                    // Tính tổng KL ăn được
                                    pureMass = pureMassFirst;
                                }

                                return ingredientItem;
                            })
                            
                            // Tính thành tiền
                            if(exchangeValue !== 0){
                                ingredientPrice = Math.round( (realMass / exchangeValue) * Number(arrayItem[0].nutrients.price));
                            }

                            // Tính đơn giá / 100g
                            if(realMass !== 0){
                                hunGramPrice = Math.round(ingredientPrice * 100 / realMass);
                            }

                            ingredientIndex++;

                            return(
                                <tr key={index} className="text-end align-middle">
                                    <td className="text-center">{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>{formatter.format(energyOfOneIngredient)}</td>
                                    <td>{animalProteinOfOneIngredient !== 0 ? formatter.format(animalProteinOfOneIngredient) : "empty"}</td>
                                    <td>{plantProteinOfOneIngredient !== 0 ? formatter.format(plantProteinOfOneIngredient) : "empty"}</td>
                                    <td>{animalLipidOfOneIngredient !== 0 ? formatter.format(animalLipidOfOneIngredient) : "empty"}</td>
                                    <td>{plantLipidOfOneIngredient !== 0 ? formatter.format(plantLipidOfOneIngredient) : "empty"}</td>
                                    <td>{formatter.format(glucidOfOneIngredient)}</td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMassOfOneChild)|| "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(pureMass)|| "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass)|| "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ hunGramPrice || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={ ingredientPrice || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr className="text-center align-middle">
                        <th>Động vật/Thực vật</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th>{proteinStat !== 0 ? formatter.format(animalProteinStat*100/proteinStat) : "empty"}</th>
                        <th>{proteinStat !== 0 ? formatter.format( 100 - (animalProteinStat*100/proteinStat)) : "empty"}</th>
                        <th>{lipidStat !== 0 ? formatter.format(animalLipidStat*100/lipidStat) : "empty"}</th>
                        <th>{lipidStat !== 0 ? formatter.format(  100 - (animalLipidStat*100/lipidStat)) : "empty"}</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Kết quả tính toán</th>
                        <th>empty</th>

                        <th>{formatter.format(energyStat)}</th>
                        <th>{formatter.format(proteinStat)}</th>
                        <th>empty</th>

                        <th>{formatter.format(lipidStat)}</th>
                        <th>empty</th>

                        <th>{formatter.format(glucidStat)}</th>
                        <th className="text-start">Tổng tiền đi chợ</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ totalMoney } displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Nhu cầu tại trường</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-start">Tiền thu trong buổi</th>
                        <th>empty</th>
                        <th className="text-end">
                            {timePoetry} x {totalChild} = &nbsp; <CurrencyFormat suffix="(đ)" value={ timePoetry * totalChild } displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Định mức trung bình cho một {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"} trong buổi</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(energyStat / totalChild) : "empty"}</th>
                        <th>{totalChild !== 0 ? formatter.format(proteinStat / totalChild) : "empty"}</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(lipidStat / totalChild) : "empty"}</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(glucidStat / totalChild) : "empty"}</th>
                        <th className="text-start">Số dư đầu kì</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ this.props.moneySoonDay } displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tỉ lệ đạt trong buổi</th>
                        <th>empty</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th>empty</th>
                        <th className="text-start">Chi phí khác</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ Number(this.props.ortherFee)*totalChild } displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tỉ lệ đạt ở trường</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-start">Số dư cuối kì</th>
                        <th>empty</th>

                        <th className="text-end">
                            <CurrencyFormat suffix="(đ)" value={ this.props.moneySoonDay - (Number(this.props.ortherFee) * totalChild + totalMoney) + (timePoetry * totalChild)} displayType={'text'} thousandSeparator={true} />
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>P : L : G</th>
                        <th>empty</th>

                        <th>empty</th>
                        <th>{ plgTotal !== 0 ? formatter.format(proteinStat*100*4/plgTotal) : "empty"}</th>
                        <th>empty</th>

                        <th>{ plgTotal !== 0 ? formatter.format(lipidStat*100*9/plgTotal) : "empty"}</th>
                        <th>empty</th>

                        <th>{ plgTotal !== 0 ? formatter.format(glucidStat*100*4/plgTotal) : "empty"}</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                </tbody>
            </Table>
        )
    }
}

export default NutritionSetupResult;