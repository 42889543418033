import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { groupArrayByField, priceFormatter } from "../../../utils/helpers";
import { dateFormatter } from "../../../utils/helpers";

const NutritionSetupResult = ({ quantitative, selectedClassGroup }) => {
  if (!quantitative) {
    return "";
  }

  // Get init data
  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const numberChildren = currentCostPerDays?.meal_quantity || 0;
  const feeMeal = Number(currentCostPerDays?.fee_meal || 0);
  const feeOther = Number(currentCostPerDays?.fee_other || 0);
  const feePerChild = feeMeal + feeOther;
  const startBalance = Number(currentCostPerDays?.start_balance || 0);
  const totalRevenue = feePerChild * numberChildren;

  // Nutrition
  let energyStat = 0;
  let proteinStat = 0;
  let lipidStat = 0;
  let glucidStat = 0;

  let animalProteinStat = 0;
  let animalLipidStat = 0;

  // Change data structure of quantitative to ingredient array
  let ingredientArray = [];
  let extraIngredientArray = [];

  quantitative.times?.map((timeItem) => {
    return timeItem.foods.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          ingredientArray.push(ingredientItem);

          const gramEatable = Number(
            ((ingredientItem.ing_quantity * ingredientItem.volume_market) /
              ingredientItem.volume_cook) *
              ((100 - ingredientItem.ingredent_ratio) / 100) *
              numberChildren
          );

          if (ingredientItem.id_subspecies === 1) {
            animalProteinStat +=
              (gramEatable * Number(ingredientItem.ingredent_protein)) / 100;
            animalLipidStat +=
              (gramEatable * Number(ingredientItem.ingredent_lipid)) / 100;
          }

          energyStat +=
            (gramEatable * Number(ingredientItem.ingredent_energy)) / 100;
          proteinStat +=
            (gramEatable * Number(ingredientItem.ingredent_protein)) / 100;
          lipidStat +=
            (gramEatable * Number(ingredientItem.ingredent_lipid)) / 100;
          glucidStat +=
            (gramEatable * Number(ingredientItem.ingredent_glucid)) / 100;

          return ingredientItem;
        });

      foodItem.groups.map((groupItem) => {
        if (
          groupItem.id === selectedClassGroup.id &&
          groupItem.more_meal !== null
        ) {
          groupItem?.ingredents.map((ingredientItem) => {
            extraIngredientArray.push({
              ...ingredientItem,
              mealQuantity: groupItem.more_meal,
            });
            return ingredientItem;
          });
        }

        return groupItem;
      });

      return foodItem;
    });
  });

  const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
    (a, b) => {
      let amountA = a.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      let amountB = b.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
    }
  );
  const groupExtraIngredientArray = groupArrayByField(
    extraIngredientArray,
    "id"
  ).sort((a, b) => {
    let amountA = a.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    let amountB = b.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
  });

  let totalMoney = 0;
  let totalExtraMoney = 0;

  groupIngredientArray.map((groupIngredientItem) => {
    let ingredientPrice = 0;
    let volumeMarket = 0;
    let gramMarket = 0;
    let marketQuantity = 0;

    groupIngredientItem.map((ingredientItem) => {
      // ingredientPrice += Number((ingredientItem.ing_quantity * numberChildren * ingredientItem.ing_price_expect) / ingredientItem.volume_cook);
      gramMarket += Number(
        (ingredientItem.ing_quantity *
          numberChildren *
          ingredientItem.volume_market) /
          ingredientItem.volume_cook
      );
      volumeMarket = Number(ingredientItem.volume_market);
      return ingredientItem;
    });

    gramMarket = Number(gramMarket).toFixed(2);
    marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
    ingredientPrice = Math.round(
      marketQuantity * groupIngredientItem[0].ing_price_expect
    );

    totalMoney += ingredientPrice;

    return groupIngredientItem;
  });

  groupExtraIngredientArray.map((groupIngredientItem) => {
    // let ingredientPrice = 0;

    // groupIngredientItem.map((ingredientItem) => {
    // 	const ingredientQuantity = ingredientItem.ing_quantity;

    // 	ingredientPrice += Number((ingredientQuantity * ingredientItem.mealQuantity * ingredientItem.ing_price_expect) / ingredientItem.volume_cook);
    // 	return ingredientItem;
    // });

    // ingredientPrice = Math.round(ingredientPrice);
    // totalExtraMoney += ingredientPrice;

    let ingredientPrice = 0;
    let volumeMarket = 0;
    let gramMarket = 0;
    let marketQuantity = 0;

    groupIngredientItem.map((ingredientItem) => {
      gramMarket += Number(
        (ingredientItem.ing_quantity *
          ingredientItem.mealQuantity *
          ingredientItem.volume_market) /
          ingredientItem.volume_cook
      );
      volumeMarket = Number(ingredientItem.volume_market);
      return ingredientItem;
    });

    gramMarket = Number(gramMarket).toFixed(2);
    marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
    ingredientPrice = Math.round(
      marketQuantity * groupIngredientItem[0].ing_price_expect
    );

    totalExtraMoney += ingredientPrice;

    return groupIngredientItem;
  });

  // Calculate money
  let costMoney = Number(
    feeOther * numberChildren + totalMoney + totalExtraMoney
  );
  let costMoneyPerChild =
    numberChildren !== 0
      ? Math.round(Number((costMoney - totalExtraMoney) / numberChildren))
      : 0;

  const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;

  return (
    <Table bordered responsive id="nutrition-setup-result-table">
      <thead>
        <tr>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>{`${quantitative.day_name}, ngày ${dateFormatter(
            new Date(quantitative.menu_date * 1000)
          )}`}</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
        </tr>
        <tr>
          <th>KẾT QUẢ THIẾT LẬP DƯỠNG CHẤT</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>
        <tr>
          <td>
            Độ tuổi: {currentCostPerDays?.group_name}
            <br />
            Tổng số trẻ: {numberChildren}
          </td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>
            {quantitative.times?.map((timeItem, timeIndex) => {
              const foods =
                timeItem.foods.filter((food) => {
                  return food.groups.some(
                    (group) => group.id === selectedClassGroup.id
                  );
                }) || [];

              return (
                <div key={timeItem.id}>
                  Ăn {timeItem.time_name}:
                  <br />
                  {foods.length > 0 ? (
                    foods.map((foodItem, foodIndex) => {
                      return (
                        <div key={`food_${timeIndex}_ ${foodIndex}`}>
                          - {foodItem.food_name}
                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
        </tr>
        <tr className="align-middle">
          <td>
            Số tiền trên mỗi trẻ: &nbsp;
            <CurrencyFormat
              suffix=" đ"
              value={costMoneyPerChild}
              displayType={"text"}
              thousandSeparator={true}
            />
          </td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
        </tr>
        <tr className="text-center align-middle">
          <th>STT</th>
          <th>Tên thực phẩm</th>
          <th>Năng lượng (Kcal)</th>
          <th>Đạm (g)</th>
          <th>empty</th>
          <th>Béo (g)</th>
          <th>empty</th>
          <th>Bột đường (g)</th>
          <th>1 trẻ (g)</th>
          <th>Ăn được (g)</th>
          <th>Đi chợ (g)</th>
          <th>Đơn giá (đ/100g)</th>
          <th>Thành tiền (đ)</th>
        </tr>
        <tr className="text-center align-middle">
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>

          <th>ĐV</th>
          <th>TV</th>
          <th>ĐV</th>
          <th>TV</th>

          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>
      </thead>
      <tbody>
        {groupIngredientArray.map((groupIngredientItem, ingredientIndex) => {
          // Gram
          let gramOneChild = 0;
          let gramEatable = 0;
          let gramMarket = 0;
          let gram100Price = 0;
          let ingredientPrice = 0;

          // Nutrition
          let energy = 0;
          let animalProtein = 0;
          let plantProtein = 0;
          let animalLipid = 0;
          let plantLipid = 0;
          let glucid = 0;
          let volumeMarket = 0;
          let marketQuantity = 0;

          groupIngredientItem.map((ingredientItem) => {
            gramOneChild += Number(
              (ingredientItem.ing_quantity * ingredientItem.volume_market) /
                ingredientItem.volume_cook
            );
            gramEatable += Number(
              ((ingredientItem.ing_quantity *
                numberChildren *
                ingredientItem.volume_market) /
                ingredientItem.volume_cook) *
                ((100 - ingredientItem.ingredent_ratio) / 100)
            );
            gramMarket += Number(
              (ingredientItem.ing_quantity *
                numberChildren *
                ingredientItem.volume_market) /
                ingredientItem.volume_cook
            );
            volumeMarket = Number(ingredientItem.volume_market);
            // ingredientPrice += Number(
            // 	(ingredientItem.ing_quantity * numberChildren * ingredientItem.ing_price_expect) / ingredientItem.volume_cook,
            // );

            return ingredientItem;
          });

          gramMarket = Number(gramMarket).toFixed(2);
          marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
          ingredientPrice = Math.round(
            marketQuantity * groupIngredientItem[0].ing_price_expect
          );
          gram100Price =
            Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

          // ingredientPrice = Math.round(ingredientPrice);
          gramEatable = Number(gramEatable).toFixed(2);
          // gramMarket = Number(gramMarket).toFixed(2);

          if (groupIngredientItem[0].id_subspecies === 1) {
            animalProtein +=
              (gramEatable * Number(groupIngredientItem[0].ingredent_protein)) /
              100;
            animalLipid +=
              (gramEatable * Number(groupIngredientItem[0].ingredent_lipid)) /
              100;
          } else {
            plantProtein =
              (gramEatable * Number(groupIngredientItem[0].ingredent_protein)) /
              100;
            plantLipid =
              (gramEatable * Number(groupIngredientItem[0].ingredent_lipid)) /
              100;
          }

          energy +=
            (gramEatable * Number(groupIngredientItem[0].ingredent_energy)) /
            100;
          glucid +=
            (gramEatable * Number(groupIngredientItem[0].ingredent_glucid)) /
            100;

          return (
            <tr
              key={groupIngredientItem[0].id}
              className="text-end align-middle"
            >
              <td className="text-center">{ingredientIndex + 1}</td>
              <td className="text-start">
                {groupIngredientItem[0].ingredent_name}
              </td>
              <td>
                <CurrencyFormat
                  value={parseFloat(energy)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </td>
              <td>
                {animalProtein !== 0 ? (
                  <CurrencyFormat
                    value={parseFloat(animalProtein)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ) : (
                  "empty"
                )}
              </td>
              <td>
                {plantProtein !== 0 ? (
                  <CurrencyFormat
                    value={parseFloat(plantProtein)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ) : (
                  "empty"
                )}
              </td>
              <td>
                {animalLipid !== 0 ? (
                  <CurrencyFormat
                    value={parseFloat(animalLipid)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ) : (
                  "empty"
                )}
              </td>
              <td>
                {plantLipid > 0 ? (
                  <CurrencyFormat
                    value={parseFloat(plantLipid)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ) : (
                  "empty"
                )}
              </td>
              <td>
                {glucid > 0 ? (
                  <CurrencyFormat
                    value={parseFloat(glucid)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ) : (
                  "empty"
                )}
              </td>
              <td>
                <CurrencyFormat
                  value={parseFloat(gramOneChild)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={parseFloat(gramEatable)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={parseFloat(gramMarket)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={gram100Price}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={ingredientPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
          );
        })}

        <tr className="text-center align-middle">
          <th>Động vật / Thực vật</th>
          <th>empty</th>
          <th>empty</th>

          <th>
            {proteinStat !== 0
              ? priceFormatter.format((animalProteinStat * 100) / proteinStat)
              : "empty"}
          </th>
          <th>
            {proteinStat !== 0
              ? priceFormatter.format(
                  100 - (animalProteinStat * 100) / proteinStat
                )
              : "empty"}
          </th>
          <th>
            {lipidStat !== 0
              ? priceFormatter.format((animalLipidStat * 100) / lipidStat)
              : "empty"}
          </th>
          <th>
            {lipidStat !== 0
              ? priceFormatter.format(100 - (animalLipidStat * 100) / lipidStat)
              : "empty"}
          </th>

          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Kết quả tính toán</th>
          <th>empty</th>

          <th>{priceFormatter.format(energyStat)}</th>
          <th>{priceFormatter.format(proteinStat)}</th>
          <th>empty</th>

          <th>{priceFormatter.format(lipidStat)}</th>
          <th>empty</th>

          <th>{priceFormatter.format(glucidStat)}</th>
          <th className="text-start">Tổng tiền đi chợ</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              suffix=" đ"
              value={totalMoney}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Nhu cầu tại trường</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th className="text-start">Tiền món dự trù</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              suffix=" đ"
              value={totalExtraMoney}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Định mức trung bình cho một trẻ trong ngày</th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(energyStat / numberChildren)
              : "empty"}
          </th>
          <th>
            {numberChildren !== 0
              ? priceFormatter.format(proteinStat / numberChildren)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(lipidStat / numberChildren)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(glucidStat / numberChildren)
              : "empty"}
          </th>
          <th className="text-start">Tiền thu trong ngày</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              value={feePerChild}
              displayType={"text"}
              thousandSeparator={true}
            />
            &nbsp;x&nbsp;{numberChildren}&nbsp;=&nbsp;
            <CurrencyFormat
              suffix=" đ"
              value={totalRevenue}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tỉ lệ đạt trong ngày</th>
          <th>empty</th>

          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>

          <th>empty</th>
          <th className="text-start">Số dư đầu kì</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              suffix=" đ"
              value={startBalance}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tỉ lệ đạt ở trường</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-start">Chi phí khác</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              suffix=" đ"
              value={feeOther * numberChildren}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>P : L : G</th>
          <th>empty</th>

          <th>empty</th>
          <th>
            {plgTotal !== 0
              ? priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {plgTotal !== 0
              ? priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {plgTotal !== 0
              ? priceFormatter.format(
                  100 -
                    (Number(
                      priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
                    ) +
                      Number(
                        priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
                      ))
                )
              : "empty"}
          </th>

          <th className="text-start">Số dư cuối kì</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              suffix=" đ"
              value={startBalance - costMoney + totalRevenue}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>
      </tbody>
    </Table>
  );
};

export default NutritionSetupResult;
