import axios from "axios";
import React, { Component } from "react";
import { Breadcrumb, Button, Card, Container, Table, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import swal from "sweetalert";

class ListStudent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            students_: [],
            createmodalshow: false,
            editModalShow: false,
            alertShow: false,
            selectedRows: [],
            student: [],
            listclass: [],
            filterText: '',
            studentListFile: null,
            studentitem: [],
            isAddStudentListLoading: false,
            checkload: true,
            tabkey: 1,
            students: [], rootData: [],
            dataExport: []
        };
    }

    async componentDidMount() {
        document.title = "Xuất danh sách học sinh";
        this.getlistclass();
        this.getStudent();
        this.getlistclass_();
    }

    getlistuser = () => {
        axios
          .get("/showAllUser")
          .then((res) => {
            if (res.data.status) {
              this.setState({
                listUser: res.data.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }; 
    getStudent = () => {
        axios
            .get(`/viewStudent`)
            .then((res) => {
                if (res.data.status) {
                    this.getStudentsData(res.data.data)
                    this.setState({ checkload: false })
                }
            })
    }

    getStudentsData = (students) => {
        axios
            .get(`/viewClassRoom`)
            .then((res) => {
                if (res.data.status) {
                    const dataClass = res.data.data
                    this.setState({
                        dataExport: dataClass.map(item => {
                            return ({
                                id_class: students.filter(e => e.class_name == item.class_name)[0].id,
                                class_name: item.class_name,
                                students: students.filter(e => e.class_name == item.class_name)
                            })
                        })
                    })
                }
            })
    };
    handleOpenEdit = (id) => {
        this.getStudentItem(id)
    }
    getlistclass = () => {
        var arrExport = []
        axios
            .get(`/viewClassRoom`)
            .then((res) => {
                if (res.data.status) {
                    this.getStudents(res.data.data[0].id)
                    this.setState({
                        listclass: res.data.data,
                        tabkey: res.data.data[0].id
                    });

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getlistclass_ = () => {
        var arrExport = []
        axios
            .get(`/viewClassRoom`)
            .then((res) => {
                if (res.data.status) {
                    // this.setState({
                    //     listclass: res.data.data,
                    // });
                    // this.getStudents(res.data.data[0].id)
                    for (let i = 0; i < res.data.data.length; i++) {
                        arrExport.push(this.getStudents_(res.data.data[i].id))
                    }

                    this.setState({
                        rootData: arrExport
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };
    getStudents_ = (id) => {
        var rs = []
        axios.post(`/viewChilClassRoom?id_class=${id}`).then((res) => {

            rs.push(res.data.data)
        })
        return rs
    }
    getStudents = (id) => {

        axios.post(`/viewChilClassRoom?id_class=${id}`).then((res) => {
            this.setState({ students: res.data.data })
            // rs.push(res.data.data)
        })
        // return rs
    }
    getDate = (data) => {
        let date = new Date(data * 1000);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
    getDate_ = (data) => {
        let date = new Date(data * 1000);
        return `${date.getDate()}`;
    }
    getMonth_ = (data) => {
        let date = new Date(data * 1000);
        return `${date.getMonth() + 1}`;
    }
    getYear_ = (data) => {
        let date = new Date(data * 1000);
        return `${date.getFullYear()}`;
    }

    loading = () => {
        return <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </Card.Body>
        </Card>

    }
    handleExport = (tabkey) => {
        let class_name = this.state.listclass.filter(e => Number(e.id) === Number(tabkey))[0]?.class_name
        swal({
            title: `Xác nhận`,
            text: `Xuất danh sách học sinh ${class_name}`,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.xport();
            }
        });
    }
    xport = () => {
        const table = document.getElementById("StudentTable");
        const wb = XLSX.utils.table_to_book(table);
        var ws = wb.Sheets["Sheet1"];
        ws['!cols'] = [
            { wch: 5 },
            { wch: 20 },
        ];
        ws['!rows'] = [];
        XLSX.writeFile(wb, "DSHS.xlsx");
    };
    handleExport_ = () => {
        let key = this.state.listclass.filter(e => e.id == this.state.tabkey)[0]?.class_name
        swal({
            title: `Xác nhận`,
            //   text: `Xuất phiếu đi chợ Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth}) ${stringFirstDate(start)} - ${stringLastDate(term)}   `,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.export_();
            }
        });
    }
    export_ = () => {
        const wb = XLSX.utils.book_new();
        this.state.listclass?.map((item, index) => {
            if (item.class_name != null)
                XLSX.utils.book_append_sheet(wb, this.book1(item.class_name), item.class_name);


        })


        XLSX.writeFile(wb, "Danh sách học sinh.xlsx");
    }
    book1 = (class_name) => {
        if (class_name != "") {


            const table1 = document.getElementById(`ds-${class_name}`)
            var ws1 = XLSX.utils.table_to_sheet(table1, { raw: true })


            ws1['!cols'] = [
                { wch: 5 },
                { wch: 20 },
                { wch: 10 },
                { wch: 25 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 20 },
            ];

            const row = []


            ws1['!rows'] = row;


            return ws1;
        }
    }
    renderStudentList() {
        return (
            <>
                {/*  */}
                <Card.Header>

                    <div className="d-block d-xl-flex justify-content-between">
                        <Card.Title>
                            <i className="fas fa-list me-1"></i> Danh sách học sinh
                        </Card.Title>
                        <div className="d-flex mt-3 mt-md-0">
                            <Button
                                type="button"
                                variant="warning"
                                size="sm"
                                className="me-3"
                                onClick={() => this.props.navHook("/admin/student")}
                            >
                                <i className="fa-solid fa-arrow-left"></i> Quay về
                            </Button>
                            <Button
                                variant="warning"
                                size="sm"
                                className="me-3"
                                onClick={() => {
                                    // xuat()
                                    this.handleExport_()
                                }}
                            >
                                Xuất hết

                            </Button>
                            <Button
                                type="button"
                                variant="success"
                                size="sm"
                                className="me-3"
                                onClick={() => this.handleExport(this.state.tabkey)}
                            >
                                <i className="fa-regular fa-cloud-arrow-down"></i> Export DSHS
                            </Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {this.state.checkload ? this.loading() :
                        <>
                            <Tabs
                                activeKey={this.state.tabkey}
                                // defaultActiveKey={2}
                                onSelect={(k) => {
                                    // let class_ = this.state.listclass[k];
                                    this.setState({
                                        tabkey: k,
                                    });
                                    this.getStudents(k);
                                }}
                            >
                                {this.state.listclass.length > 0 ?
                                    (
                                        this.state.listclass.map((item, index) => {
                                            return (
                                                <Tab
                                                    key={item.id}
                                                    title={item.class_name}
                                                    eventKey={item.id}
                                                    style={{ overflow: "auto", maxHeight: "400px" }}
                                                >
                                                    <br />
                                                    <Table
                                                        // responsive
                                                        bordered
                                                        id='StudentTable'
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="align-middle text-center" >STT</th>
                                                                <th className="align-middle text-center"> Mã định danh Bộ GD&ĐT</th>

                                                                <th className="align-middle text-center" style={{ width: "50px" }}>Họ và tên</th>
                                                                <th className="align-middle text-center" style={{ width: "80px" }}> Ngày sinh</th>
                                                                <th className="align-middle text-center">Giới tính</th>
                                                                <th className="align-middle text-center"> Dân tộc</th>
                                                                <th className="align-middle text-center"> Trạng thái</th>
                                                                <th className="align-middle text-center">SĐT liên hệ</th>
                                                                <th className="align-middle text-center"> Tên giáo viên</th>
                                                                <th className="align-middle text-center"> Nhóm học sinh</th>
                                                                <th className="align-middle text-center"> Số nhà</th>
                                                                <th className="align-middle text-center"> Tổ</th>
                                                                <th className="align-middle text-center"> KV </th>
                                                                <th className="align-middle text-center">Đường </th>
                                                                <th className="align-middle text-center"> Phường</th>
                                                                <th className="align-middle text-center"> Quận</th>
                                                                <th className="align-middle text-center">Tỉnh, TP </th>
                                                                <th className="align-middle text-center">Họ và tên cha</th>
                                                                <th className="align-middle text-center"> Nghề nghiệp cha </th>
                                                                <th className="align-middle text-center"> Điện thoại</th>
                                                                <th className="align-middle text-center">Họ và tên mẹ</th>
                                                                <th className="align-middle text-center"> Nghề nghiệp mẹ </th>
                                                                <th className="align-middle text-center"> Điện thoại</th>
                                                                <th className="align-middle text-center"> Ghi chú</th>
                                                                <th className="align-middle text-center">Mã định danh dân cư </th>



                                                            </tr>
                                                            {/* <tr>
                                                                <th> Ngày sinh</th> <th> Tháng sinh</th> <th> Năm sinh</th>
                                                            </tr> */}
                                                            {
                                                                this.state.students.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td className="text-center">{index + 1}</td>
                                                                            <td>{item.student_identifier}</td>
                                                                            {/* <td>{item.class_name}</td> */}
                                                                            <td style={{ width: "250px" }}>{item.student_name}</td>
                                                                            <td>{this.getDate(item.student_birthday)}</td>
                                                                            <td>{item.gender_name}</td>
                                                                            <td>Dân tộc</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>{ }</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                            <tr>
                                                                <th colSpan={6} className="text-center">Số lượng</th>
                                                                <th colSpan={2} className="text-center"> {this.state.students.length}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </Table>

                                                </Tab>
                                            )

                                        }))
                                    : null}
                            </Tabs>
                            {/* Export */}
                            {
                                this.state.dataExport.map(student => {
                                    return (
                                        <Table
                                            bordered
                                            id={`ds-${student.class_name}`}
                                            className='d-none'
                                        >
                                            <tr>
                                                {/* <th className="align-middle text-center" >STT</th> */}
                                                <th className="align-middle text-center"> Mã định danh Bộ GD&ĐT</th>

                                                <th className="align-middle text-center" style={{ width: "50px" }}>Họ và tên</th>
                                                <th className="align-middle text-center" style={{ width: "80px" }}> Ngày sinh</th>
                                                <th className="align-middle text-center">Giới tính</th>
                                                <th className="align-middle text-center"> Dân tộc</th>
                                                <th className="align-middle text-center"> Trạng thái</th>
                                                <th className="align-middle text-center">SĐT liên hệ</th>
                                                <th className="align-middle text-center"> Tên giáo viên</th>
                                                <th className="align-middle text-center"> Nhóm học sinh</th>
                                                <th className="align-middle text-center"> Số nhà</th>
                                                <th className="align-middle text-center"> Tổ</th>
                                                <th className="align-middle text-center"> KV </th>
                                                <th className="align-middle text-center">Đường </th>
                                                <th className="align-middle text-center"> Phường</th>
                                                <th className="align-middle text-center"> Quận</th>
                                                <th className="align-middle text-center">Tỉnh, TP </th>
                                                <th className="align-middle text-center">Họ và tên cha</th>
                                                <th className="align-middle text-center"> Nghề nghiệp cha </th>
                                                <th className="align-middle text-center"> Điện thoại</th>
                                                <th className="align-middle text-center">Họ và tên mẹ</th>
                                                <th className="align-middle text-center"> Nghề nghiệp mẹ </th>
                                                <th className="align-middle text-center"> Điện thoại</th>
                                                <th className="align-middle text-center"> Ghi chú</th>
                                                <th className="align-middle text-center">Mã định danh dân cư </th>
                                            </tr>
                                            {student.students.map(item=>{
                                                return (
                                                    <tr key={item.id}>
                                                    {/* <td className="text-center">{index + 1}</td> */}
                                                    <td>{item.student_identifier}</td>
                                                    {/* <td>{item.class_name}</td> */}
                                                    <td style={{ width: "250px" }}>{item.student_name}</td>
                                                    <td>{this.getDate(item.student_birthday)}</td>
                                                    <td>{item.gender_name}</td>
                                                    <td>Dân tộc</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{ }</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
    
                                                </tr>
                                                )
                                            })}
                                      
                                        </Table>
                                    )
                                })
                            }
                        </>
                    }
                </Card.Body>
            </>
        );
    }

    render() {
        return (
            <>
                <Container fluid>
                    {/* {this.renderSelectedRows()} */}
                    <Breadcrumb className="mb-2 mt-2">
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                            Tổng quan
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card>{this.renderStudentList()}</Card>
                </Container>
            </>
        );
    }
}
function myParams(Component) { //Back link AND use "params"
    return props => <Component navHook={useNavigate()}  {...props} params={useParams()} />;
}
export default myParams(ListStudent);
