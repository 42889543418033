import React, { Component } from "react";
import { Button, Breadcrumb, Card, Container, Table, Modal } from "react-bootstrap";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
registerLocale('vi', vi)

class SupplierShoppingReportIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSupplier: [],
            supplierSelected: 0,

            isLoading: false,

            startDate: new Date(),
            endDate: new Date(),

            tmpStartDate: new Date(),
            tmpEndDate: new Date(),
        };
    }

    async componentDidMount() {
        document.title = 'Báo cáo mua hàng';

        const date = new Date();

        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        start.setHours(0, 0, 0, 0);

        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        end.setHours(23, 59, 59, 0);

        this.setState({
            startDate: start,
            endDate: end,

            tmpStartDate: start,
            tmpEndDate: end,
        });

        this.getlistinventory(start, end);
    }

    getlistinventory = (startDate, endDate) => {
        this.setState({
            isLoading: true
        })
        axios
            .post(`/viewHisInventory?ingredent_type=1,2,3&start_at=${startDate.getTime() / 1000}&term_at=${endDate.getTime() / 1000}`)
            .then((res) => {
                this.setState({
                    isLoading: false
                })
                if (res.data.status === true) {
                    const listArraySupplier = this.group(res.data.data, "id_supplier");
                    this.setState({
                        listSupplier: listArraySupplier.sort(function (a, b) {
                            return a[0].id_supplier - b[0].id_supplier;
                        })
                    });
                }
            });
    };

    //#region Handle Logic
    xport = () => {
        const wb = XLSX.utils.book_new();

        const ws1 = this.sumarizeReport();
        const ws2 = this.detailReport();

        XLSX.utils.book_append_sheet(wb, ws1, "Tổng hợp mua hàng");
        XLSX.utils.book_append_sheet(wb, ws2, "Chi tiết mua hàng");

        XLSX.writeFile(wb, "Báo cáo mua hàng.xlsx");
    };

    sumarizeReport = () => {
        const table1 = document.getElementById("supplier-sumarize-report");

        var ws1 = XLSX.utils.table_to_sheet(table1);

        // Style cell
        const colAlpha = ["A", "B", "C", "D"];

        ws1[`A1`].s = {
            font: {
                name: "Times New Roman",
                sz: 16,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws1[`A2`].s = {
            font: {
                name: "Times New Roman",
                sz: 14,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        colAlpha.map((alpha) => {
            ws1[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        for (let i = 4; i <= this.state.listSupplier.length + 3; i++) {
            ws1[`A${i}`].s = {
                font: {
                    name: "Times New Roman",
                    sz: 11,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            colAlpha.slice(1).map((alpha) => {
                if (ws1[`${alpha}${i}`].v === "empty") {
                    ws1[`${alpha}${i}`].v = ' '
                }

                ws1[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })

            ws1[`C${i}`].z = "#,###,###,###";
            delete ws1[`C${i}`].w;
            XLSX.utils.format_cell(ws1[`C${i}`]);
        }

        ws1['!cols'] = [
            { wch: 5 },
            { wch: 20 },
            { wch: 25 },
            { wch: 20 }
        ];

        ws1['!rows'] = [{ hpt: 40 }, { hpt: 30 }];

        colAlpha.map(alpha => {
            if (ws1[`${alpha}${3 + this.state.listSupplier.length + 1}`].v === "empty") {
                ws1[`${alpha}${3 + this.state.listSupplier.length + 1}`].v = ' '
            }

            ws1[`${alpha}${3 + this.state.listSupplier.length + 1}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: alpha === "B" ? "center" : ""
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws1[`C${3 + this.state.listSupplier.length + 1}`].z = "#,###,###,###";
        delete ws1[`C${3 + this.state.listSupplier.length + 1}`].w;
        XLSX.utils.format_cell(ws1[`C${3 + this.state.listSupplier.length + 1}`]);

        return ws1;
    }

    detailReport = () => {
        const table2 = document.getElementById("supplier-detail-report");

        var ws2 = XLSX.utils.table_to_sheet(table2, { raw: true });

        let totalLength = 0;

        this.state.listSupplier.map(item => {
            totalLength += item.length;

            return item;
        })

        // Style cell
        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
        const colAlphaStyle = ["B", "C", "D", "E", "F"];

        ws2[`A1`].s = {
            font: {
                name: "Times New Roman",
                sz: 16,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        ws2[`A2`].s = {
            font: {
                name: "Times New Roman",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "left"
            }
        };

        colAlpha.map((alpha) => {

            ws2[`${alpha}3`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        for (let i = 4; i <= totalLength + 3; i++) {
            ws2[`A${i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws2[`G${i}`].z = "#,###,###,##0.00";
            delete ws2[`G${i}`].w;
            XLSX.utils.format_cell(ws2[`G${i}`]);

            ws2[`G${i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws2[`H${i}`].z = "#,###,###,###";
            delete ws2[`H${i}`].w;
            XLSX.utils.format_cell(ws2[`H${i}`]);

            ws2[`H${i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            ws2[`I${i}`].z = "#,###,###,###";
            delete ws2[`I${i}`].w;
            XLSX.utils.format_cell(ws2[`I${i}`]);

            ws2[`I${i}`].s = {
                font: {
                    name: "Times New Roman",
                },
                alignment: {
                    vertical: "center",
                    horizontal: "right"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            colAlphaStyle.map(alpha => {

                ws2[`${alpha}${i}`].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };

                return alpha;
            })
        }

        ws2['!cols'] = [
            { wch: 5 },
            { wch: 15 },
            { wch: 15 },
            { wch: 10 },
            { wch: 15 },
            { wch: 10 },
            { wch: 8 },
            { wch: 10 },
            { wch: 15 }
        ];

        ws2['!rows'] = [{ hpt: 35 }, {}, { hpt: 30 }];

        colAlpha.map(alpha => {
            if (ws2[`${alpha}${3 + totalLength + 1}`].v === "empty") {
                ws2[`${alpha}${3 + totalLength + 1}`].v = ' '
            }

            ws2[`${alpha}${3 + totalLength + 1}`].s = {
                font: {
                    name: "Times New Roman",
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: alpha === "I" ? "right" : "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        ws2[`I${3 + totalLength + 1}`].z = "#,###,###,###";
        delete ws2[`C${3 + totalLength + 1}`].w;
        XLSX.utils.format_cell(ws2[`C${3 + totalLength + 1}`]);

        return ws2;
    }

    stringDate = (dateNumber) => {
        const date = new Date(dateNumber * 1000);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    }

    stringDateTime = (dateNumber) => {
        const date = new Date(dateNumber * 1000);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
    }

    group = (arr, key) => {
        return [...arr.reduce((acc, o) =>
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
            , new Map()).values()];
    }
    //#endregion

    // Render
    RenderSupplierReport() {
        let totalBigMoney = 0;
        let totalMoney = 0;

        return (
            <>
                <div
                //  style={{overflowX: "scroll"}}
                >
                    <Table bordered striped hover
                    // style={{ minWidth: 1024 }}
                    >
                        <thead>
                            <tr className="text-center">
                                <th>Nhà cung cấp</th>
                                <th>Nhân viên</th>
                                <th className="text-end">Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.listSupplier.map((supplierItem, index) => {
                                    let supplierMoney = 0;

                                    supplierItem.map(ingredientItem => {
                                        supplierMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                        totalBigMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                        return ingredientItem;
                                    })

                                    return (
                                        <tr
                                            key={index}
                                            className="text-end"
                                            onClick={() => {
                                                this.setState({
                                                    supplierSelected: supplierItem[0].id_supplier
                                                })
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <td className="text-start">{supplierItem[0].supplier_name}</td>
                                            <td className="text-center">{supplierItem[0].supplier_author}</td>
                                            <td>
                                                <CurrencyFormat
                                                    value={(supplierMoney).toFixed(0)}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <th className="text-center">TỔNG CỘNG</th>
                                <th></th>
                                <th className="text-end">
                                    <CurrencyFormat
                                        value={(totalBigMoney).toFixed(0)}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div
                    style={{ overflowX: "scroll" }}
                >
                    <Table bordered striped hover
                        style={{ minWidth: 800 }
                        }
                    >
                        <thead>
                            <tr className="text-center">
                                <th>STT</th>
                                <th>Sản phẩm</th>
                                <th>Ngày hóa đơn</th>
                                <th>Đơn vị tính</th>
                                <th className="text-end">Số lượng</th>
                                <th className="text-end">Đơn giá</th>
                                <th className="text-end">Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.supplierSelected !== 0
                                    ?
                                    this.state.listSupplier
                                        .filter(item => item[0].id_supplier === this.state.supplierSelected)[0]
                                        .sort(function (a, b) {
                                            return b.created_at - a.created_at;
                                        })
                                        .map((ingredientItem, index) => {

                                            let supplierMoney = 0;

                                            supplierMoney = Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                            totalMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);

                                            return (
                                                <tr key={index} className="text-end">
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-start">{ingredientItem.ingredent_name}</td>
                                                    <td className="text-center">{this.stringDateTime(ingredientItem.created_at)}</td>
                                                    <td className="text-start">{ingredientItem.unit_name}</td>
                                                    <td>
                                                        <CurrencyFormat
                                                            value={Number(ingredientItem.amount).toFixed(2)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td>
                                                        <CurrencyFormat
                                                            value={Number(ingredientItem.ingredent_price)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td>
                                                        <CurrencyFormat
                                                            value={supplierMoney.toFixed(0)}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            displayType="text"
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    : ""
                            }
                            <tr className="text-end">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Tổng cộng</th>
                                <th>
                                    <CurrencyFormat
                                        value={totalMoney.toFixed(0)}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    RenderSupplierSumarizeReport() {
        let totalMoney = 0;

        return (
            <Table
                id="supplier-sumarize-report"
            >
                <thead>
                    <tr>
                        <th className="text-center h4" colSpan={4}>Thống kê mua hàng theo nhà cung cấp</th>
                    </tr>
                    <tr>
                        <th className="text-center h5" colSpan={4}>Từ ngày {this.stringDate(this.state.startDate.getTime() / 1000)} đến ngày {this.stringDate(this.state.endDate.getTime() / 1000)}</th>
                    </tr>
                    <tr className="text-center">
                        <th>STT</th>
                        <th>NHÀ CUNG CẤP</th>
                        <th>THÀNH TIỀN DỰ KIẾN</th>
                        <th>GHI CHÚ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.listSupplier.map((supplierItem, index) => {

                            let supplierMoney = 0;

                            supplierItem.map(ingredientItem => {
                                supplierMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                totalMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                return ingredientItem;
                            })

                            return (
                                <tr key={supplierItem[0].id_supplier}>
                                    <td>{index + 1}</td>
                                    <td>{supplierItem[0].supplier_name}</td>
                                    <td className="text-end">
                                        <CurrencyFormat
                                            value={Number(supplierMoney).toFixed(0)}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </td>
                                    <td>empty</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <th>empty</th>
                        <th className="text-center">TỔNG CỘNG</th>
                        <th className="text-end">
                            <CurrencyFormat
                                value={Number(totalMoney).toFixed(0)}
                                thousandSeparator={true}
                                allowNegative={false}
                                displayType="text"
                            />
                        </th>
                        <th>empty</th>
                    </tr>
                </tbody>
            </Table>
        )
    }

    RenderSupplierDetailReport() {
        let totalMoney = 0;
        let ingredientIndex = 0;

        return (
            <Table
                id="supplier-detail-report"
            >
                <thead>
                    <tr>
                        <th className="text-center h4" colSpan={9}>Thống kê chi tiết mua hàng theo nhà cung cấp</th>
                    </tr>
                    <tr>
                        <th colSpan={9}>Từ ngày {this.stringDateTime(this.state.startDate.getTime() / 1000)} đến ngày {this.stringDateTime(this.state.endDate.getTime() / 1000)}</th>
                    </tr>
                    <tr>
                        <th>STT</th>
                        <th>Nhà cung cấp</th>
                        <th>Ngày hóa đơn</th>
                        <th>Nhân viên</th>
                        <th>Tên sản phẩm</th>
                        <th>Đơn vị</th>
                        <th>Số lượng</th>
                        <th>Đơn giá</th>
                        <th>Thành tiền</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.listSupplier
                            .map((supplierItem) => {
                                return supplierItem
                                    .sort(function (a, b) {
                                        return b.created_at - a.created_at;
                                    })
                                    .map((ingredientItem) => {
                                        const supplierMoney = Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);
                                        totalMoney += Number(ingredientItem.ingredent_price) * Number(ingredientItem.amount).toFixed(2);

                                        ingredientIndex++;

                                        return (
                                            <tr key={ingredientIndex}>
                                                <td>{ingredientIndex}</td>
                                                <td>{ingredientItem.supplier_name}</td>
                                                <td>{this.stringDateTime(ingredientItem.created_at)}</td>
                                                <td>{ingredientItem.supplier_author}</td>
                                                <td>{ingredientItem.ingredent_name}</td>
                                                <td>{ingredientItem.unit_name}</td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(ingredientItem.amount).toFixed(2)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={Number(ingredientItem.ingredent_price)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={supplierMoney.toFixed(0)}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        displayType="text"
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                            })
                    }
                    <tr>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-end">Tổng cộng</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th className="text-end">
                            <CurrencyFormat
                                value={Number(totalMoney).toFixed(0)}
                                thousandSeparator={true}
                                allowNegative={false}
                                displayType="text"
                            />
                        </th>
                    </tr>
                </tbody>
            </Table>
        )
    }

    render() {
        return (
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Mua hàng theo nhà cung cấp
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-md-flex justify-content-between">
                            <div className="d-block d-md-flex">
                                <Card.Title>
                                    <i className="fas fa-list me-1" />
                                    Báo cáo mua hàng theo nhà cung cấp {this.stringDate(this.state.startDate.getTime() / 1000)} - {this.stringDate(this.state.endDate.getTime() / 1000)}
                                </Card.Title>

                                <Button
                                    size="sm"
                                    className="ms-2 mt-3 mt-md-0"
                                    onClick={() => { this.setState({ show: true }) }}
                                >
                                    <i className="fa-solid fa-calendar-days"></i>
                                </Button>
                            </div>

                            <Button
                                variant="success"
                                size="sm"
                                onClick={() => this.xport()}
                                className="mt-3 mt-md-0"
                            >
                                Xuất file <i className="fa-solid fa-file-export"></i>
                            </Button>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        {/* <div className="d-block d-sm-flex mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Từ ngày
                                </Form.Label>
                                <DatePicker 
                                    dateFormat="dd/MM/yyyy" 
                                    locale="vi" 
                                    selected={this.state.startDate} 
                                    className="rounded border border-2"
                                    onChange={(date) => {
                                        date.setHours(0, 0, 0, 0);

                                        if(date.getTime() > this.state.endDate.getTime()){
                                            toast("Ngày bắt đầu không thể lớn hơn ngày kết thúc", {
                                                type: "error",
                                                autoClose: 3000,
                                            });

                                            return;
                                        }

                                        this.setState({
                                            startDate: date,
                                        });

                                        this.getlistinventory(date, this.state.endDate)
                                    }} />
                            </Form.Group>

							<Form.Group className="mt-3 mt-sm-0">
								<Form.Label>
									Đến ngày
								</Form.Label>
								<DatePicker 
									dateFormat="dd/MM/yyyy" 
									locale="vi" 
									selected={this.state.endDate}
									className="rounded border border-2"
									onChange={(date) => {
										date.setHours(23, 59, 59, 0);

										if(this.state.startDate.getTime() > date){
											toast("Ngày kết thúc không thể nhỏ hơn ngày bắt đầu", {
												type: "error",
												autoClose: 3000,
											});

											return;
										}

										this.setState({
                                            endDate: date,
                                        })

                                        this.getlistinventory(this.state.startDate, date)
									}} />
							</Form.Group>
						</div> */}

                        {
                            !this.state.isLoading
                                ?
                                this.RenderSupplierReport()
                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải báo cáo mua hàng theo NCC...</span>
                                    </div>
                                </div>
                        }

                        <div
                            className="d-none"
                        >
                            {this.RenderSupplierSumarizeReport()}
                            {this.RenderSupplierDetailReport()}
                        </div>
                    </Card.Body>
                </Card>

                <Modal show={this.state.show} size={"sm"} >
                    <Modal.Header>
                        <Modal.Title>
                            Chọn thời gian
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <DatePicker
                                size={{ height: "600px" }}
                                onChange={(update) => {
                                    this.setState({
                                        tmpStartDate: update[0],
                                        tmpEndDate: update[1]
                                    })
                                }}
                                startDate={this.state.tmpStartDate}
                                endDate={this.state.tmpEndDate}
                                calendarStartDay={1}
                                selectsRange
                                inline
                            />
                        </center>
                        <br />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            onClick={() => {
                                this.setState({ show: false })
                            }}
                        >
                            Hủy
                        </Button>
                        <Button
                            size="sm"
                            disabled={this.state.tmpStartDate === null || this.state.tmpEndDate === null}
                            onClick={() => {
                                const start = this.state.tmpStartDate;
                                start.setHours(0, 0, 0, 0);

                                const end = this.state.tmpEndDate;
                                end.setHours(23, 59, 59, 0);

                                this.setState({
                                    show: false,
                                    startDate: start,
                                    endDate: end
                                })
                                this.getlistinventory(start, end)
                            }}
                        >
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

export default SupplierShoppingReportIndex;