import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";

const PaymentIndex = () => {
  const [validatedVTBForm, setValidatedVTBForm] = useState(false);
  const [validatedHDBForm, setValidatedHDBForm] = useState(false);
  const [vtbPaymentSettingValid, setVTBPaymentSettingValid] = useState(false);
  const [hdbPaymentSettingValid, setHDBPaymentSettingValid] = useState(false);
  const [showVTB, setShowVTB] = useState(false);
  const [showHD, setShowHD] = useState(false);

  const getPaymentSettings = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "/payment-setting-shows",
      });

      const settings = response.data.data;

      settings?.forEach((setting) => {
        if (setting.setting_key === "HDB_API_KEY") {
          setHDBFormData({
            id: setting.id || "",
            provider: "hdbank",
            merchantId: setting.payload.HDB_MERCHANT_ID || "",
            secretKey: setting.payload.HDB_CLIENT_SECRET || "",
            is_delete: setting.is_delete || "",
          });

          setHDBPaymentSettingValid(true);
        }

        if (setting.setting_key === "VTB_API_KEY") {
          setVTBFormData({
            id: setting.id,
            provider: "vietinbank",
            clientId: setting.payload.VTB_CLIENT_ID || "",
            clientSecret: setting.payload.VTB_CLIENT_SECRET || "",
            merchantName: setting.payload.VTB_MERCHANT_NAME || "",
            providerId: setting.payload.VTB_PROVIDER_ID || "",
            merchantId: setting.payload.VTB_MERCHANT_ID || "",
            terminalId: setting.payload.VTB_TERMINAL_ID || "",
            is_delete: setting.is_delete || "",
          });
          setVTBPaymentSettingValid(true);
        }
      });
    } catch (error) {
      toast.error("Đã có lỗi xảy ra khi tải dữ liệu");
      console.log(error);
    }
  };

  const [hdbFormData, setHDBFormData] = useState({
    provider: "hdbank",
    merchantId: "",
    secretKey: "",
    id: "",
    is_delete: "",
  });

  const handleHDBInputChange = (event) => {
    const { name, value } = event.target;
    setHDBFormData({
      ...hdbFormData,
      [name]: value,
    });
  };

  const [hdbErrors, setHDBErrors] = useState({});

  const handleSubmitHDBankForm = async (event) => {
    event.preventDefault();

    swal({
      title: `Bạn muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        const form = event.currentTarget;
        if (form?.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidatedHDBForm(true);

        try {
          const data = {
            provider: hdbFormData.provider,
            payload: {
              HDB_MERCHANT_ID: hdbFormData.merchantId,
              HDB_CLIENT_SECRET: hdbFormData.secretKey,
            },
          };

          const response = await axios({
            method: "post",
            url: "/payment-setting-create-or-update",
            data,
            headers: { "Content-Type": "application/json" },
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          setHDBErrors(error.response?.data?.messages);
        }
      }
    });
  };

  const [vtbFormData, setVTBFormData] = useState({
    provider: "vietinbank",
    clientId: "",
    clientSecret: "",
    merchantName: "",
    providerId: "",
    merchantId: "",
    terminalId: "",
    id: "",
    is_delete: "",
  });

  const handleVTBInputChange = (event) => {
    const { name, value } = event.target;
    setVTBFormData({
      ...vtbFormData,
      [name]: value,
    });
  };

  const [vtbErrors, setVTBErrors] = useState({});

  const handleSubmitVTBankForm = async (event) => {
    event.preventDefault();

    swal({
      title: `Bạn muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidatedVTBForm(true);

        try {
          const data = {
            provider: vtbFormData.provider,
            payload: {
              VTB_CLIENT_ID: vtbFormData.clientId,
              VTB_CLIENT_SECRET: vtbFormData.clientSecret,
              VTB_MERCHANT_NAME: vtbFormData.merchantName,
              VTB_PROVIDER_ID: vtbFormData.providerId,
              VTB_MERCHANT_ID: vtbFormData.merchantId,
              VTB_TERMINAL_ID: vtbFormData.terminalId,
            },
          };

          const response = await axios({
            url: "/payment-setting-create-or-update",
            method: "post",
            data,
            headers: { "Content-Type": "application/json" },
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          setVTBErrors(error.response?.data?.messages);
        }
      }
    });
  };

  const handleTogglePaymentSetting = async (id) => {
    swal({
      title: `Bạn muốn  ${
        hdbFormData.is_delete === 1 ? "Vô hiệu hóa" : "Kích hoạt"
      }?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        try {
          const response = await axios({
            method: "put",
            url: `/payment-setting-toggle?id=${id}`,
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          console.log(error.message);
          toast.error("Lỗi trong quá trình cập nhật");
        }
      }
    });
  };

  useEffect(() => {
    getPaymentSettings();
  }, []);

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Phương thức thanh toán</h5>
        </div>

        <div className="card-body">
          <div className="mb-3">
            <ul className="list-unstyled mb-0">
              <li className="d-flex my-3">
                <div className="flex-grow-1">
                  <p className="text-muted">
                    <div className="row">
                      <div className="col-md-2">
                        <Image
                          roundedCircle
                          fluid
                          src="https://api.vietqr.io/img/HDB.png"
                          alt="Ngân hàng thương mại cổ phần Phát triển Thành phố Hồ Chí Minh"
                          title="Ngân hàng thương mại cổ phần Phát triển Thành phố Hồ Chí Minh"
                        />
                      </div>

                      <div className="col-md-10">
                        <div className="col-md-10 d-flex align-items-center">
                          <p>
                            Khách hành có thể thanh toán trực tiếp an toàn thông
                            qua HD Bank. Vui lòng liên hệ với HD Bank để được
                            cung cấp các mã thanh toán.
                          </p>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </li>

              <Card>
                <Card.Body>
                  <Form
                    name="hdbankForm"
                    noValidate
                    validated={validatedHDBForm}
                    onSubmit={handleSubmitHDBankForm}
                  >
                    <div className="row">
                      <Col xs={12}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="float-end"
                          onClick={() => setShowHD(!showHD)}
                        >
                          {!showHD ? (
                            <>
                              <i className="fa-solid fa-eye"></i> Hiện
                            </>
                          ) : (
                            <>
                              <i className="fa-solid fa-eye-slash"></i> Ẩn
                            </>
                          )}
                        </Button>
                      </Col>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Merchant ID<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showHD ? "text" : "password"}
                            name="merchantId"
                            value={hdbFormData.merchantId}
                            onChange={handleHDBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {hdbErrors["payload.HDB_MERCHANT_ID"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Secret key<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showHD ? "text" : "password"}
                            name="secretKey"
                            value={hdbFormData.secretKey}
                            onChange={handleHDBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {hdbErrors["payload.HDB_CLIENT_SECRET"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-12 d-flex justify-content-end">
                        {hdbPaymentSettingValid && (
                          <div className="mx-2">
                            <Button
                              onClick={() =>
                                handleTogglePaymentSetting(hdbFormData.id)
                              }
                              variant={
                                hdbFormData.is_delete === 1
                                  ? "outline-secondary"
                                  : "outline-success"
                              }
                              type="button"
                            >
                              {hdbFormData.is_delete === 1
                                ? "Vô hiệu hóa"
                                : "Kích hoạt"}
                            </Button>
                          </div>
                        )}

                        <div>
                          <Button
                            variant="info"
                            className="text-white"
                            type="submit"
                          >
                            Cập nhật
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Card.Body>
              </Card>

              <li className="d-flex my-3">
                <div className="flex-grow-1">
                  <p className="text-muted">
                    <div className="row">
                      <div className="col-md-2">
                        <Image
                          roundedCircle
                          fluid
                          src="https://api.vietqr.io/img/ICB.png"
                          alt="Ngân hàng thương mại cổ phần Công thương Việt Nam"
                          title="Ngân hàng thương mại cổ phần Công thương Việt Nam"
                        />
                      </div>

                      <div className="col-md-10 d-flex align-items-center">
                        <p>
                          Khách hành có thể thanh toán trực tiếp an toàn thông
                          qua Vietin Bank. Vui lòng liên hệ với Vietin Bank để
                          được cung cấp các mã thanh toán.
                        </p>
                      </div>
                    </div>
                  </p>
                </div>
              </li>

              <Card>
                <Card.Body>
                  <div></div>

                  <Form
                    name="vietinbankForm"
                    noValidate
                    validated={validatedVTBForm}
                    onSubmit={handleSubmitVTBankForm}
                  >
                    <div className="row">
                      <Col xs={12}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="float-end"
                          onClick={() => setShowVTB(!showVTB)}
                        >
                          {!showVTB ? (
                            <>
                              <i className="fa-solid fa-eye"></i> Hiện
                            </>
                          ) : (
                            <>
                              <i className="fa-solid fa-eye-slash"></i> Ẩn
                            </>
                          )}{" "}
                        </Button>
                      </Col>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Client ID<i className="text-danger">*</i>
                          </Form.Label>

                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="clientId"
                            value={vtbFormData.clientId}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_CLIENT_ID"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Client Secret<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="clientSecret"
                            value={vtbFormData.clientSecret}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_CLIENT_SECRET"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Merchant Name<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="merchantName"
                            value={vtbFormData.merchantName}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_MERCHANT_NAME"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Provider ID<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="providerId"
                            value={vtbFormData.providerId}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_PROVIDER_ID"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Merchant ID<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="merchantId"
                            value={vtbFormData.merchantId}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_MERCHANT_ID"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Terminal ID<i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Control
                            type={showVTB ? "text" : "password"}
                            name="terminalId"
                            value={vtbFormData.terminalId}
                            onChange={handleVTBInputChange}
                            required
                          />

                          <Form.Control.Feedback type="invalid">
                            {vtbErrors["payload.VTB_TERMINAL_ID"]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-md-12 d-flex justify-content-end">
                        {vtbPaymentSettingValid && (
                          <div className="mx-2">
                            <Button
                              onClick={() =>
                                handleTogglePaymentSetting(vtbFormData.id)
                              }
                              variant={
                                vtbFormData.is_delete === 1
                                  ? "outline-secondary"
                                  : "outline-success"
                              }
                              type="button"
                            >
                              {vtbFormData.is_delete === 1
                                ? "Vô hiệu hóa"
                                : "Kích hoạt"}
                            </Button>
                          </div>
                        )}

                        <div>
                          <Button
                            variant="info"
                            className="text-white"
                            type="submit"
                          >
                            Cập nhật
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentIndex;
