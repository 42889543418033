import axios from "axios";
import React, { Component } from "react";
import { Button, Form, Modal, Row, Col, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
class GroupCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupname: "",
      recommended_from: this.props.PT == "CN" ? this.props.recommended_from : 0,
      recommended_to: this.props.PT == "TM" || this.props.PT == "" ? 0 : this.props.recommended_to,
      recommended: false,

    };
  }
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  creategroup() {
    axios
      .post(`/createClassGroup?group_name=${this.state.groupname}&recommended_from=${Number(this.state.recommended_from)}&recommended_to=${Number(this.state.recommended_to)}`)
      .then((res) => {
        if (res.data.status) {
          // this.setState({recommended_from:0
          //   ,recommended_to:0})
          this.setState({

            recommended_from: 0,
            recommended_to: 0,
          })
          this.props.close();
          toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
        } else {
          toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updategroup() {
    axios
      .post(
        `/updateClassGroup?id_group=${this.props.idgroup}&group_name=${this.state.groupname == '' ? this.props.groupname : this.state.groupname}&recommended_from=${Number(this.state.recommended_from)}&recommended_to=${Number(this.state.recommended_to)}`
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({

            recommended_from: 0,
            recommended_to: 0,
          })
          this.props.close();
          toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
          // this.setState({
          //   recommended_from:0,
          //   recommended_to:0
          // })
        } else {
          toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5" id="staticBackdropLabel">
            {this.props.PT === "TM" || this.props.PT === ""
              ? "Thêm nhóm học sinh"
              : "Cập nhật nhóm học sinh"}
          </h1>
        </Modal.Header>
        <Modal.Body>
          {/* {console.log(this.props)} */}
          <Form>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                placeholder="Tên nhóm học sinh"
                defaultValue={
                  this.props.PT == "TM" || this.props.PT == ""
                    ? ""
                    : this.props.groupname
                }
                onChange={(e) => {
                  if (this.props.PT == "TM" || this.props.PT == "") {
                    this.setState({ groupname: e.target.value });

                  } else {
                    this.setState({ groupname: e.target.value })
                  }
                }
                }
              />
              <Form.Label>
                Tên nhóm học sinh<i className="text-danger">*</i>
              </Form.Label>
              <div className="invalid-feedback"></div>
            </Form.Floating>
            <p className="text-center"> Nhu cầu khuyến nghị năng lượng (Kcal)</p>
            <Row>

              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    placeholder="Từ"
                    thousandSeparator={true}
                    suffix=' Kcal'
                    value={this.state.recommended_from
                    }
                    onValueChange={(values) => {
                      this.setState({
                        recommended_from: values.value
                      })
                    }}
                  />
                  <Form.Label>
                    Từ<i className="text-danger"></i> (Kcal)
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    placeholder="Đến"
                    value={this.state.recommended_to
                    }
                    suffix=' Kcal'
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      this.setState({
                        recommended_to: values.value
                      })
                    }}
                  />
                  <Form.Label>
                    Đến<i className="text-danger"></i> (Kcal)
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
            </Row>
          </Form>
          {this.state.recommended == false ?
            <Button size="sm" onClick={() => this.setState({ recommended: true })}
              variant="success">Khuyến nghị năng lượng</Button>
            : <Button onClick={() => this.setState({ recommended: false })}
              size="sm" variant="success" style={{ marginBottom: '15px' }}>Ẩn khuyến nghị năng lượng </Button>
          }


          {this.state.recommended ? <Table bordered >
            <thead>
              <tr>  <th colSpan={3} className="text-center">Khuyến nghị năng lượng cho trẻ </th>   </tr>
              <tr className="text-center">
                <th > Nhóm tuổi  </th>
                <th>  Nhu cầu khuyến nghị năng lượng  </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">  <td>3 - 6 tháng  </td> <td>500 - 550 Kcal</td><td><Button
                size="sm"
                onClick={() => {
                  this.setState({

                    recommended_from: 500,
                    recommended_to: 550
                  })
                }}

              >Copy</Button></td> </tr>
              <tr className="text-center"> <td>6 - 12 tháng  </td>  <td >600 - 700 Kcal</td> <td><Button
                size="sm"
                onClick={() => {
                  this.setState({

                    recommended_from: 600,
                    recommended_to: 700
                  })
                }}
              >Copy</Button></td> </tr>
              <tr className="text-center"> <td>12 - 18 tháng  </td>  <td rowSpan={3} className="align-middle text-center">930 - 1000 Kcal</td>  <td rowSpan={3} className="align-middle text-center"><Button
                size="sm"
                onClick={() => {
                  this.setState({

                    recommended_from: 930,
                    recommended_to: 1000
                  })
                }}
              >Copy</Button></td> </tr>
              <tr className="text-center"> <td>18 - 24 tháng  </td> </tr>
              <tr className="text-center"> <td>24 - 36 tháng  </td> </tr>
              <tr className="text-center"> <td>36 - 72 tháng  </td>  <td >1230 - 1320 Kcal</td> <td><Button
                size="sm"
                onClick={() => {
                  this.setState({

                    recommended_from: 1230,
                    recommended_to: 1320
                  })
                }}
              >Copy</Button></td> </tr>

            </tbody>
          </Table> : null}

        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={this.props.close}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
          {new Check().permission(['9']) ?
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => {
              if (this.props.PT === "TM" || this.props.PT === "") {
                this.creategroup();
              } else {
                this.updategroup();
              }
            }}
          >
            <i className="fa-solid fa-check"></i> Lưu
          </Button>:null}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default GroupCreate;
