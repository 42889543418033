import axios from "axios";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import { toSlug } from "../utils/helpers";
import SubjectAdd from "./SubjectAdd";
import SubjectEdit from "./SubjectEdit";
import SubjectInfo from "./SubjectInfo";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import { formatTimestamp } from "../../../helper/times";

const SubjectsIndex = () => {
  const [subjects, setSubjects] = useState([]);
  const [subjectsSearch, setSubjectsSearch] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [addSubjectModalShow, setAddSubjectModalShow] = useState(false);
  const [editSubjectModalShow, setEditSubjectModalShow] = useState(false);
  const [infoSubjectModalShow, setInfoSubjectModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const typeOptions = [
    {
      value: "ALL",
      label: "--Tất cả--",
    },
    {
      value: "DEFAULT",
      label: "Chính khóa",
    },
    {
      value: "EXTRA",
      label: "Học thêm",
    },
  ];

  useEffect(() => {
    getAllSubjects();
  }, []);

  //#region API
  const getAllSubjects = async () => {
    setIsLoading(true);
    await axios
      .get(`/subject-shows`)
      .then((res) => {
        if (res.status === 200) {
          setSubjects(res.data.data.subjects);
          setSubjectsSearch(res.data.data.subjects);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };

  const deleteSubject = (subjectId) => {
    axios
      .delete(`/subject-delete?id=${subjectId}`)
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        getAllSubjects();
      })
      .catch((err) => {
        const msg = err.response.data.messages ?? "Hệ thống xảy ra lỗi";
        toast(msg, {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  //#endregion API

  //#region Handle
  const handleShowInfoSubjectModal = (subjectId) => {
    setInfoSubjectModalShow(true);
    setSelectedSubject(subjectId);
  };
  const handleCloseInfoSubjectModal = () => {
    setInfoSubjectModalShow(false);
    setSelectedSubject();
  };

  const handleShowAddSubjectModal = () => {
    setAddSubjectModalShow(true);
    setSelectedSubject();
  };
  const handleCloseAddSubjectModal = (isReload) => {
    setAddSubjectModalShow(false);
    if (isReload) {
      getAllSubjects(1);
    }
  };

  const handleShowEditSubjectModal = (subjectId) => {
    setEditSubjectModalShow(true);
    setSelectedSubject(subjectId);
  };
  const handleCloseEditSubjectModal = (isReload) => {
    setEditSubjectModalShow(false);
    setSelectedSubject();
    if (isReload) {
      getAllSubjects(1);
    }
  };

  const handleDeleteSubject = (subject) => {
    swal({
      title: "Bạn muốn xóa môn học?",
      text: `"${subject.subject_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        deleteSubject(subject.id);
      }
    });
  };

  const handleSearch = (searchText) => {
    const listSubjects = subjects.filter((subject) =>
      toSlug(subject.subject_name).includes(toSlug(searchText))
    );
    setSubjectsSearch(listSubjects);
  };

  const handleSelectedTypeSearch = (type) => {
    if (type.value === "ALL") {
      setSubjectsSearch(subjects);
    } else {
      const listSubjects = subjects.filter(
        (subject) => subject.type === type.value
      );
      setSubjectsSearch(listSubjects);
    }
  };

  //#endregion Handle
  const dataTableColumns = [
    {
      name: "Mã môn học",
      selector: (row) => row.subject_code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tên môn học",
      selector: (row) => row.subject_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Học phí",
      selector: (row) => parseInt(row.subject_price),
      sortable: true,
      right: true,
      cell: (row) => {
        return row.type === "DEFAULT" ? (
          <>-</>
        ) : (
          <CurrencyFormat
            thousandSeparator={true}
            suffix=" ₫"
            displayType="text"
            value={parseInt(row.subject_price, 10)}
          />
        );
      },
    },
    {
      name: "Loại",
      selector: (row) => row.type,
      sortable: true,
      center: true,
      cell: (row) => row.type === "DEFAULT"
        ? <span className="text-primary">Chính khóa</span>
        : <span className="text-warning">Học thêm</span>
    },
    {
      name: "Cập nhật",
      selector: (row) => parseFloat(row.updated_at),
      sortable: true,
      right: true,
      cell: (row) => formatTimestamp(row.updated_at, "dd/mm/yyyy hh:mm:ss")
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          {/* {new Check().permission([139]) ? (
            <Button
              size="sm"
              variant="primary me-2"
              type="button"
              onClick={() => handleShowInfoSubjectModal(row.id)}
            >
              <i className="fa-solid fa-circle-info"></i>
            </Button>
          ) : (
            ""
          )} */}

          {new Check().permission([139, 141], "or") ? (
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditSubjectModal(row.id)}
              title="Chi tiết môn học"
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : (
            ""
          )}

          {new Check().permission([142]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => handleDeleteSubject(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  //#region Render
  return (
    <>
      <Container fluid>
        <Breadcrumb className="mb-2 mt-2">
          <Breadcrumb.Item active>Môn học</Breadcrumb.Item>
          <Breadcrumb.Item active>Danh sách môn học</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-4">
          <Card.Header>
            <Card.Title>
              <i className="fas fa-list me-1" /> Danh sách môn học

              {new Check().permission([140]) ?
                <Button
                  variant="success"
                  size="sm"
                  className="float-end ms-2"
                  onClick={() => handleShowAddSubjectModal()}
                  title="Thêm môn học"
                >
                  <i className="fa-solid fa-plus" /> Thêm
                </Button>
                : <></>}

            </Card.Title>
          </Card.Header>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Card.Body>
              <Row>
                <Col md={3} sm={6} className="mb-2">
                  <Form.Control
                    style={{ height: "39px" }}
                    onChange={(event) => handleSearch(event.target.value)}
                    placeholder="Nhập tên môn học"
                  />
                </Col>
                <Col
                  md={4}
                  sm={6}
                  className="mb-2"
                  style={{ zIndex: 99, display: "flex" }}
                >
                  <span style={{ padding: "10px", whiteSpace: "nowrap" }}>
                    Loại môn học:
                  </span>
                  <Select
                    options={typeOptions}
                    defaultValue={typeOptions[0]}
                    onChange={(value) => {
                      handleSelectedTypeSearch(value);
                    }}
                  />
                </Col>
              </Row>
              <DataTables data={subjectsSearch} columns={dataTableColumns} />
            </Card.Body>
          )}
        </Card>
      </Container>

      {addSubjectModalShow && (
        <SubjectAdd
          show={addSubjectModalShow}
          onHide={handleCloseAddSubjectModal}
        />
      )}

      {editSubjectModalShow && (
        <SubjectEdit
          show={editSubjectModalShow}
          onHide={handleCloseEditSubjectModal}
          selectedSubjectId={selectedSubject}
        />
      )}

      {infoSubjectModalShow && (
        <SubjectInfo
          show={infoSubjectModalShow}
          onHide={handleCloseInfoSubjectModal}
          selectedSubjectId={selectedSubject}
        />
      )}
    </>
  );
  //#endregion Render
};

export default SubjectsIndex;
