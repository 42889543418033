
import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import AddChildUnit from "../unit/AddChildUnit";
import SupplierFormModal from '../Supplier/SupplierFormModal';
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import Check from "../other/Check";

class RoleAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      unitOptions: [],
      suppliers: [],
      ingredent: [],
      choiceIngrdent: {},
      dataInput: {
        id: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.id,
        id_unit: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.id_unit,
        id_supplier: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.id_supplier,
        ingredent_name: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.ingredent_name,
        ingredent_price: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.ingredent_price,
        ingredent_code: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.ingredent_code == 'null' ?
          '' : this.props.obj?.ingredent.ingredent_code,
        ingredent_ratio: this.props.typeModal == 'TM' ? 0 : this.formatter.format(this.props.obj?.ingredent.ingredent_ratio),
        ingredent_FineMass: 100,
        ingredent_RealVolume: this.props.typeModal == 'TM' ? '' : this.props.obj?.ingredent.ingredent_RealVolume,
        ingredent_energy: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.ingredent_energy,
        ingredent_protein: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.ingredent_protein,
        ingredent_lipid: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.ingredent_lipid,
        ingredent_glucid: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.ingredent_glucid,
        type_subspecies: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.type_subspecies,
        is_bank: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.is_bank,
        ingredent_type: (this.props.keyTab == undefined || this.props.keyTab == '') ? 0 : this.props.keyTab,
        expiration: this.props.typeModal == 'TM' ? 0 : this.props.obj?.ingredent.ingredent_alarm,
      },
      childUnitModalShow: false,
      modalSuppliershow: false,
      keyIngredent: 1, bntAdd: false
    };
  }

  componentDidMount() {
    this.getUnitsData();
    this.getSuppliersData();
    this.getIngredentLibraryData();
  }
  formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    let dataInput = { ...this.state.dataInput };
    dataInput.ingredent_price = this.state.dataInput.ingredent_price ? this.state.dataInput.ingredent_price : 0

    dataInput[field] = value;
    this.setState({ dataInput: dataInput });


  }
  updateIngredent = () => {
    let id = this.state.dataInput.id;
    let id_unit = this.state.dataInput.id_unit;
    let id_supplier = this.state.dataInput.id_supplier;
    let ingredent_name = this.state.dataInput.ingredent_name;
    let ingredent_price = this.state.dataInput.ingredent_price;
    let ingredent_type = this.state.dataInput.ingredent_type;
    let ingredent_code = this.state.dataInput.ingredent_code;
    let type_subspecies = this.state.dataInput.type_subspecies;
    let ingredent_ratio = this.state.dataInput.ingredent_ratio
    let ingredent_lipid = this.state.dataInput.ingredent_lipid
    let ingredent_protein = this.state.dataInput.ingredent_protein
    let ingredent_glucid = this.state.dataInput.ingredent_glucid
    let ingredent_energy = this.state.dataInput.ingredent_energy
    let expiration = this.state.dataInput.expiration
    let is_bank = this.state.dataInput.is_bank
    if (this.checkNull(ingredent_name) || this.checkNull(id_supplier) || this.checkNull(ingredent_type) || this.checkNull(id_unit)
      || this.checkNull(id_supplier) || this.checkNull(ingredent_price) || this.checkNull(this.state.dataInput.ingredent_RealVolume)
    ) { toast("Vui lòng nhập đầy đủ thông tin", { type: "error" }); }
    else {
      axios.post(`/updateIngredent?id_ingredent=${id}&id_unit=${id_unit}&id_supplier=${id_supplier}&ingredent_name=${ingredent_name}&ingredent_price=${ingredent_price}&ingredent_type=${ingredent_type}&ingredent_code=${ingredent_code}&type_subspecies=${type_subspecies}&ingredent_ratio=${ingredent_ratio}&ingredent_energy=${ingredent_energy}&ingredent_protein=${ingredent_protein}&ingredent_lipid=${ingredent_lipid}&ingredent_glucid=${ingredent_glucid}&ingredent_FineMass=${this.state.dataInput.ingredent_RealVolume - (this.state.dataInput.ingredent_RealVolume * this.state.dataInput.ingredent_ratio) / 100}&ingredent_RealVolume=${this.state.dataInput.ingredent_RealVolume}&ingredent_alarm=${expiration}&is_bank=${is_bank}`)
        .then(res => {
          if (res.data.status === true) {
            toast(res.data.data.mess, { type: "success" });
            this.setState({
              dataInput: {
                id: '',
                id_unit: '',
                id_supplier: '',
                ingredent_name: '',
                ingredent_price: '',
                ingredent_type: '2',
                is_bank: 0,
              }
            });
            this.handleHide()
          } else {
            toast(res.data.data.mess, { type: "error" });
          }
        });
    }

  }
  checkNull = (item) => {
    return (item == null || item == '' || item == 0 || item == undefined || item == 'undefined' || item == 'null')
  }
  createIngredent = () => {
    let id_unit = this.state.dataInput.id_unit;
    let is_bank = 0
    let id_supplier = this.state.dataInput.id_supplier;
    let ingredent_name = this.state.dataInput.ingredent_name;
    let ingredent_price = this.state.dataInput.ingredent_price;
    let ingredent_type = this.state.dataInput.ingredent_type == undefined ? this.props.keyTab : this.state.dataInput.ingredent_type;
    let ingredent_code = this.state.dataInput.ingredent_code;
    let type_subspecies = this.state.dataInput.type_subspecies;
    let ingredent_ratio = this.state.dataInput.ingredent_ratio
    let ingredent_lipid = this.state.dataInput.ingredent_lipid
    let ingredent_protein = this.state.dataInput.ingredent_protein
    let ingredent_glucid = this.state.dataInput.ingredent_glucid
    let ingredent_energy = this.state.dataInput.ingredent_energy
    let ingredent_FineMass = this.state.dataInput.ingredent_FineMass
    if (ingredent_code == this.state.choiceIngrdent.ingredent_code
      && ingredent_ratio == this.state.choiceIngrdent.ingredent_ratio * 100
      && ingredent_lipid == this.state.choiceIngrdent.ingredent_lipid
      && ingredent_protein == this.state.choiceIngrdent.ingredent_protein
      && ingredent_glucid == this.state.choiceIngrdent.ingredent_glucid
      && ingredent_energy == this.state.choiceIngrdent.ingredent_energy
      && this.state.dataInput?.ingredent_RealVolume == this.state.choiceIngrdent.ingredent_RealVolume

    ) { is_bank = 1 }
    else {
      is_bank = this.state.dataInput.is_bank

    }


    let expiration = this.state.dataInput.expiration
    if (ingredent_type == 1 || ingredent_type == 2) {
      if (this.checkNull(ingredent_name) || this.checkNull(id_supplier) || this.checkNull(ingredent_type) || this.checkNull(id_unit)
        || this.checkNull(id_supplier) || this.checkNull(ingredent_price) || this.checkNull(this.state.dataInput.ingredent_RealVolume)
      ) { toast("Vui lòng nhập đầy đủ thông tin", { type: "error" }); }
      else {

        this.setState({ bntAdd: true })
        axios.post(`/createIngredent?id_unit=${id_unit}&id_supplier=${id_supplier}&ingredent_name=${ingredent_name}&ingredent_price=${ingredent_price}&ingredent_type=${ingredent_type}&ingredent_code=${ingredent_code}&type_subspecies=${type_subspecies}&ingredent_ratio=${ingredent_ratio}&ingredent_energy=${ingredent_energy}&ingredent_protein=${ingredent_protein}&ingredent_lipid=${ingredent_lipid}&ingredent_glucid=${ingredent_glucid}&ingredent_FineMass=${this.state.dataInput.ingredent_RealVolume - (this.state.dataInput.ingredent_RealVolume * this.state.dataInput.ingredent_ratio) / 100}&ingredent_RealVolume=${this.state.dataInput.ingredent_RealVolume}&ingredent_alarm=${expiration}&is_bank=${is_bank}`)
          .then(res => {
            if (res.data.status === true) {
              this.setState({ bntAdd: false })

              toast(res.data.data.mess, { type: "success" });

              this.setState({
                dataInput: {
                  id: '',
                  id_unit: '',
                  id_supplier: '',
                  ingredent_name: '',
                  ingredent_price: '',
                  ingredent_type: '2',
                  is_bank: 0,
                }
              });
              this.handleHide()
            } else {
              this.setState({ bntAdd: false })
              toast(res.data.data.mess, { type: "error" });
            }
          }).catch(err =>
            this.setState({ bntAdd: false })
          )
          ;
      }
    }
    else {
      if (this.checkNull(ingredent_name) || this.checkNull(id_supplier) || this.checkNull(ingredent_type) || this.checkNull(id_unit)
        || this.checkNull(ingredent_price)
      ) { toast("Vui lòng nhập đầy đủ thông tin", { type: "error" }); }
      else {

        this.setState({ bntAdd: true })
        axios.post(`/createIngredent?id_unit=${id_unit}&id_supplier=${id_supplier}&ingredent_name=${ingredent_name}
      &ingredent_price=${ingredent_price}&ingredent_type=${ingredent_type}&ingredent_code=${ingredent_code}
      &type_subspecies=${type_subspecies}&ingredent_ratio=${ingredent_ratio}&ingredent_energy=${ingredent_energy}
      &ingredent_protein=${ingredent_protein}&ingredent_lipid=${ingredent_lipid}&ingredent_glucid=${ingredent_glucid}
      &ingredent_FineMass=${this.state.dataInput.ingredent_RealVolume - (this.state.dataInput.ingredent_RealVolume * this.state.dataInput.ingredent_ratio) / 100}&ingredent_RealVolume=${this.state.dataInput.ingredent_RealVolume}&ingredent_alarm=${expiration}&is_bank=${is_bank}`)
          .then(res => {
            if (res.data.status === true) {
              this.setState({ bntAdd: false })

              toast(res.data.data.mess, { type: "success" });

              this.setState({
                dataInput: {
                  id: '',
                  id_unit: '',
                  id_supplier: '',
                  ingredent_name: '',
                  ingredent_price: '',
                  ingredent_type: '2',
                  is_bank: 0,
                }
              });
              this.handleHide()
            } else {
              this.setState({ bntAdd: false })
              toast(res.data.data.mess, { type: "error" });
            }
          }).catch(err =>
            this.setState({ bntAdd: false })
          )
          ;
      }
    }

  }

  handleHide = () => {
    return this.props.onHide();
  }

  addUnit = () => { this.setState({ childUnitModalShow: true }); }
  handleChildModalClose = () => {
    this.setState({ childUnitModalShow: false });
    this.getUnitsData();
  }
  getUnitsData = () => {
    axios.post(`/viewUnit`)
      .then(res => {
        if (res.data.status === true) {
          const parentUnit = res.data.data.filter(item => item.parent_unit !== 0)

          const arrayUniqueByKey = [...new Map(parentUnit.map(item =>
            [item["id"], item])).values()];
          this.setState({
            units: arrayUniqueByKey,
            unitOptions: res.data.data
          })
        }
      });
  }
  dataUnits() {
    if (this.state.units.length > 0) {
      return this.state.units.map((item, i) => {
        return (<option key={item.id} value={item.id}>{item.unit_name}</option>)
      });
    }
  }

  getSuppliersData = (limit = 100) => {
    axios.post(`/viewSupplier?limit=${limit}`)
      .then(res => {
        if (res.data.status === true) {
          const arrayUniqueByKey = [...new Map(res.data.data.map(item =>
            [item["id"], item])).values()];

          this.setState({ suppliers: arrayUniqueByKey });
        }
      });
  }

  getIngredentLibraryData = () => {
    axios.get(`/viewIngredentLibrary`)
      .then(res => {
        if (res.data.status === true) {
          const ingredients = res.data.data.map((ingredient) => {
            return {
              value: ingredient.ingredent_name,
              label: ingredient.ingredent_name,
              ingredent_code: ingredient.ingredent_code,
              type_subspecies: ingredient.type_subspecies,
              ingredent_ratio: ingredient.ingredent_ratio,
              ingredent_FineMass: ingredient.ingredent_FineMass,
              ingredent_RealVolume: ingredient.ingredent_RealVolume,
              ingredent_energy: ingredient.ingredent_energy,
              ingredent_protein: ingredient.ingredent_protein,
              ingredent_lipid: ingredient.ingredent_lipid,
              ingredent_glucid: ingredient.ingredent_glucid,
            };
          });

          this.setState({ ingredent: ingredients });
        }
      });
  }

  changeIngredent = (choice) => {

    this.setState({
      choiceIngrdent: choice,
      dataInput: {

        id: this.state.dataInput.id,
        id_unit: this.state.dataInput.id_unit,
        ingredent_price: this.state.dataInput.ingredent_price,
        id_supplier: this.state.dataInput.id_supplier,
        ingredent_name: choice.value,
        ingredent_code: choice.ingredent_code,
        type_subspecies: choice.type_subspecies,
        ingredent_ratio: this.formatter.format(choice.ingredent_ratio * 100),
        ingredent_lipid: choice.ingredent_lipid,
        ingredent_protein: choice.ingredent_protein,
        ingredent_glucid: choice.ingredent_glucid,
        ingredent_energy: choice.ingredent_energy,
        ingredent_FineMass: choice.ingredent_FineMass,
        ingredent_type: this.state.dataInput.ingredent_type,
        is_bank: this.state.dataInput.is_bank,
        ingredent_RealVolume: choice.ingredent_RealVolume
      }
    });
  }


  dataSuppliers() {
    if (this.state.suppliers.length > 0) {
      return this.state.suppliers.map((item, i) => {
        return (<option key={item.id} value={item.id}> {item.supplier_name} </option>)
      });
    }
  }

  render() {
    let option = [
      // { label: "Hình thức cắt", value: 0 },
      { label: "Động vật", value: 1 },
      { label: "Thực vật", value: 2 },

    ]
    let optionStore = [
      { label: "Hàng chợ", value: 1 },
      { label: "Hàng lưu kho", value: 2 },
      { label: "Vật tư", value: 3 },
    ]

    return (<>
      <Modal
        {...this.props}
        size="lg"
        backdrop="static"
        keyboard={false}
        id="addIngredentModal"
      >
        <Modal.Header closeButton>
          <Modal.Title >

            <i className="fa-solid fa-carrot"></i>  {this.props.typeModal === "TM" ? " Thêm nguyên liệu" : "Chi tiết nguyên liệu"} &nbsp;&nbsp;&nbsp;
            {/* {this.props.typeModal != 'TM' ?
              ""
              :
              this.state.keyIngredent == 0 || this.state.dataInput.ingredent_type == 3 ?
                <Button
                  size="sm"
                  onClick={() => {
                    let dataInput =
                      { ...this.state.dataInput }
                    dataInput.is_bank = 1;
                    dataInput.id_supplier = 0;
                    dataInput.id_unit = 0;
                    dataInput.ingredent_price = 0;
                    dataInput.ingredent_FineMass = 0;
                    dataInput.ingredent_RealVolume = 0;
                    dataInput.ingredent_name = '';
                    this.setState({ dataInput: dataInput, keyIngredent: 1 });
                  }}

                  variant="success"
                  hidden={this.state.dataInput.ingredent_type == 3}
                > { }
                  <i className="fa-solid fa-book"></i> Ngân hàng NL</Button>
                :
                <Button
                  size="sm"
                  onClick={() => {
             
                    let dataInput =
                      { ...this.state.dataInput }
                    dataInput.is_bank = 0;
                    dataInput.id_supplier = 0;
                    dataInput.id_unit = 0;
                    // dataInput.ingredent_price=0;
                    dataInput.ingredent_FineMass = 0;
                    dataInput.ingredent_RealVolume = 0;
                    dataInput.ingredent_name = '';
                    dataInput.ingredent_code = 0;

                    this.setState({ dataInput: dataInput, keyIngredent: 0 });

                  }}
                  variant="success"
                  hidden={this.state.dataInput.ingredent_type == 3}
                > { } NL khác</Button>
            } */}
          </Modal.Title>

        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Row>
              {this.state.dataInput.ingredent_type == 3 ? null :
                <Col 
                md={4}
                 >
                  <Form.Floating className="mb-3">
                    <Form.Control type="text"
                      value={this.state.dataInput.ingredent_code}
                      onChange={this.handleInput}
                      name="ingredent_code"
                      id="ingredent_code"
                      placeholder="Mã nguyên liệu" />
                    <Form.Label>Mã nguyên liệu<i className="text-danger"></i></Form.Label>
                  </Form.Floating>
                </Col>}
              {this.state.dataInput.ingredent_type == 3 ? <Col md={12}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text"
                    // defaultValue={ this.props.ingredent?.ingredent_name}
                    defaultValue={this.props?.typeModal != 'TM' ? 
                    this.props.obj.ingredent?.ingredent_name : this.state.dataInput.ingredent_name}
                    onChange={this.handleInput}

                    name="ingredent_name" id="ingredent_name" placeholder="Tên nguyên liệu" required />
                  <Form.Label>Tên nguyên liệu<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col> : this.props?.typeModal != 'TM' ?    <Col md={8}>
                  <Form.Floating className="mb-3">
                    <Form.Control type="text"
                      // value={this.state.dataInput.ingredent_name} 
                      defaultValue={this.state.dataInput.ingredent_name}
                      onChange={this.handleInput} name="ingredent_name" id="ingredent_name" placeholder="Tên nguyên liệu" required />
                    <Form.Label>Tên nguyên liệu<i className="text-danger">*</i></Form.Label>
                  </Form.Floating>
                </Col> :
              this.state.keyIngredent == 0 ?
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control type="text"
                      // value={this.state.dataInput.ingredent_name} 
                      defaultValue={this.state.dataInput.ingredent_name}
                      onChange={this.handleInput} name="ingredent_name" id="ingredent_name" placeholder="Tên nguyên liệu" required />
                    <Form.Label>Tên nguyên liệu<i className="text-danger">*</i></Form.Label>
                  </Form.Floating>
                </Col>
                :
                <Col  md={6}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '50px'
                      }),
                    }}
                    options={this.state.ingredent}
                    placeholder={<>Chọn nguyên liệu từ ngân hàng </>}
                    className="mb-3"
                    name="ingredient_name"
                    value={
                      this.state.ingredent.filter(
                        (item) => item.value === this.state.dataInput.ingredent_name
                      )[0] || "Chọn nguyên liệu"
                    }
                    onChange={(choice) => {

                      this.changeIngredent(choice);
                    }}
                  />
                  
                </Col>
             
              }
         
              <Col className="mb-3" style={{marginTop:'10px'}}>
              
              {this.props.typeModal != 'TM' ?
              ""
              :
              this.state.keyIngredent == 0 || this.state.dataInput.ingredent_type == 3 ?
                <Button
                  size="sm"
                  onClick={() => {
                    // this.setState({ keyIngredent: 1, dataInput: { is_bank: 1 } })

                    // const { value } = values;
                    let dataInput =
                      { ...this.state.dataInput }
                    dataInput.is_bank = 1;
                    dataInput.id_supplier = 0;
                    dataInput.id_unit = 0;
                    dataInput.ingredent_price = 0;
                    dataInput.ingredent_FineMass = 0;
                    dataInput.ingredent_RealVolume = 0;
                    dataInput.ingredent_name = '';
                    // dataInput.ingredent_energy=0;
                    // dataInput.ingredent_price=0;
                    // dataInput.ingredent_price=0;

                    this.setState({ dataInput: dataInput, keyIngredent: 1 });
                  }}

                  variant="success"
                  hidden={this.state.dataInput.ingredent_type == 3}
                > { }
                  <i className="fa-solid fa-book"></i> Ngân hàng NL</Button>
                :
                <Button
                  size="sm"
                  onClick={() => {
                    // this.setState({ keyIngredent: 0, dataInput: { is_bank: 0 } })
                    let dataInput =
                      { ...this.state.dataInput }
                    dataInput.is_bank = 0;
                    dataInput.id_supplier = 0;
                    dataInput.id_unit = 0;
                    // dataInput.ingredent_price=0;
                    dataInput.ingredent_FineMass = 0;
                    dataInput.ingredent_RealVolume = 0;
                    dataInput.ingredent_name = '';
                    dataInput.ingredent_code = 0;

                    this.setState({ dataInput: dataInput, keyIngredent: 0 });

                  }}
                  variant="success"
                  hidden={this.state.dataInput.ingredent_type == 3}
                > { } NL khác</Button>
            }
              
              </Col>
            </Row>

                <Row>
                  {this.state.dataInput.ingredent_type == 3 ? null :
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select value={this.state.dataInput.type_subspecies} onChange={this.handleInput} name="type_subspecies" required
                          className="form-control"
                          style={{ height: '50px' }}
                        >
                          <option value="">--Chọn--</option>
                          {option.map(item => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            )
                          })}
                        </Form.Select>
                        <Form.Label>Loại nguyên liệu<i className="text-danger">*</i></Form.Label>
                      </Form.Floating>
                    </Col>}
                  <Col
                    md={this.state.dataInput.ingredent_type == 3 ? 12 :
                      this.state.dataInput.ingredent_type == 2 ? 4 : 8
                    }
                  >
                    <Form.Floating className="mb-3">
                      <Form.Select defaultValue={this.state.dataInput.ingredent_type} onChange={this.handleInput} name="ingredent_type" required
                        className="form-control"
                        style={{ height: '50px', marginBottom: '10px' }}
                        placeholder="Loại kho"
                      >
                        <option value="">--Chọn--</option>
                        {this.state.keyIngredent == 0 ?
                          optionStore.map(item => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            )
                          }) :
                          optionStore.filter(e => e.value != 3).map(item => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            )
                          })
                        }
                      </Form.Select>
                      <Form.Label>Loại kho<i className="text-danger">*</i></Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col>
                    {this.state.dataInput.ingredent_type == 2 ?
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control"
                          name="expiration"
                          thousandSeparator={true}
                          placeholder="Giá nhập"
                          required
                          value={this.state.dataInput.expiration}
                          onValueChange={(values) => {

                            const { value } = values;
                            let dataInput =
                              { ...this.state.dataInput }
                            dataInput.expiration = value;
                            this.setState({ dataInput: dataInput });

                          }}
                        />
                        <Form.Label>Mức cảnh báo hết hạn<i className="text-danger">*</i> (Ngày)</Form.Label>
                      </Form.Floating>
                      : null}
                  </Col>



                </Row>

            <Row>
              <Col md={4}>
                {new Check().permission(['68']) ?
                  <Form.Group className="text-end">
                    <Link className="fst-italic" onClick={this.addUnit} > +Thêm đơn vị tính</Link>
                  </Form.Group> : null}
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={this.state.dataInput.id_unit}
                    onChange={this.handleInput} name="id_unit" required>
                    <option value="">--Chọn--</option>
                    {this.dataUnits()}
                  </Form.Select>
                  <Form.Label>Đơn vị tính<i className="text-danger">*</i></Form.Label>
                </Form.Floating>

              </Col>
              <Col md={4}>
                {new Check().permission(['24']) ?
                  <Form.Group className="text-end">
                    <Link className="fst-italic" onClick={() => this.setState({ modalSuppliershow: true })} > +Thêm nhà cung cấp</Link>
                  </Form.Group> : null}
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={this.state.dataInput.id_supplier}
                    onChange={this.handleInput} name="id_supplier" required>
                    <option value="">--Chọn--</option>
                    {this.dataSuppliers()}

                  </Form.Select>
                  <Form.Label>Nhà cung cấp<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col>

              <Col md={4}>
                {new Check().permission(['68', '24'], 'or') ?
                  <br /> : null}
                <Form.Floating>
                  <CurrencyFormat
                    className="form-control mb-3"
                    name="ingredent_price"
                    thousandSeparator={true}
                    placeholder="Giá nhập"
                    required
                    // defaultValue={this.state.dataInput.ingredent_price}
                    value={this.state.dataInput.ingredent_price}
                    onValueChange={(values) => {

                      const { value } = values;
                      let dataInput =
                        { ...this.state.dataInput }
                      dataInput.ingredent_price = value;
                      this.setState({ dataInput: dataInput });
                    }}
                  />
                  <Form.Label>Giá<i className="text-danger">*</i> (VND)</Form.Label>
                </Form.Floating>
              </Col>
            </Row>
            {this.state.dataInput.ingredent_type == 3 ? null :
              <>
                <Row>
                  <Col md={4}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        name="ingredent_RealVolume"
                        placeholder="Giá nhập"
                        value={this.state.dataInput.ingredent_RealVolume}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_RealVolume = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>Khối lượng đi chợ(g)<i className="text-danger">*</i></Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        name="ingredent_ratio"
                        placeholder="Giá nhập"
                        value={this.state.dataInput.ingredent_ratio}
                        onValueChange={(values) => {
                          const { value } = values;

                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_ratio = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}

                      />
                      <Form.Label>Tỉ lệ thải bỏ<i className="text-danger"></i> (%)</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end"
                        thousandSeparator={true}
                        name="ingredent_FineMass"
                        placeholder="Giá nhập"
                        value={this.formatter.format(this.state.dataInput.ingredent_RealVolume - (this.state.dataInput.ingredent_RealVolume * this.state.dataInput.ingredent_ratio) / 100)}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_FineMass = value;
                          this.setState({ dataInput: dataInput });
                        }}
                        disabled
                      />
                      <Form.Label>Khối lượng ăn được<i className="text-danger"></i> (g)</Form.Label>
                    </Form.Floating>
                  </Col>
                </Row>
                <br />
                <p className="text-center" style={{ fontWeight: 'bold' }}>Thành phần dinh dưỡng trong 100g ăn được</p>
                <Row>
                  <Col md={3}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        name="ingredent_energy"
                        placeholder="Giá nhập"
                        // suffix=" Kcal"
                        value={this.state.dataInput.ingredent_energy}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_energy = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>Năng lượng<i className="text-danger"></i> (Kcal)</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        placeholder="Giá nhập"
                        name="ingredent_protein"
                        // suffix=" g"
                        value={this.state.dataInput.ingredent_protein}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_protein = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>Protein<i className="text-danger"></i> (g)</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        placeholder="Giá nhập"
                        name="ingredent_lipid"
                        // suffix=" g"
                        value={this.state.dataInput.ingredent_lipid}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_lipid = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>Lipid<i className="text-danger"></i> (g)</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mb-3"
                        thousandSeparator={true}
                        placeholder="Giá nhập"
                        name="ingredent_glucid"
                        // suffix=" g"
                        value={this.state.dataInput.ingredent_glucid}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_glucid = value;
                          dataInput.is_bank = 0;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>Glucid<i className="text-danger"></i> (g)</Form.Label>
                    </Form.Floating>
                  </Col>
                </Row>

              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.handleHide} >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            {this.props.typeModal == "TM" ?
              <Button size="sm" variant="success" onClick={() => this.createIngredent()}
                disabled={this.state.bntAdd}>
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
              :
              new Check().permission(['42']) ?
                <Button size="sm" variant="success" onClick={() => this.updateIngredent()}>
                  <i className="fa-solid fa-pencil"></i> Cập nhật
                </Button>
                : null}

          </Modal.Footer>
        </Form>
      </Modal>
      {
        this.state.childUnitModalShow
          ?
          <AddChildUnit show={this.state.childUnitModalShow} onHide={this.handleChildModalClose} onSubmit={this.handleChildModalClose} units={this.state.unitOptions} />
          : ""
      }

      {this.state.modalSuppliershow ?
        <SupplierFormModal
          show={this.state.modalSuppliershow}
          onHide={() => this.setState({ modalSuppliershow: false })}
          modal={{ title: 'Thêm nhà cung cấp', button: <><i className="fa-solid fa-check" /> Lưu</> }}
          data={{
            id: '',
            supplier_name: '',
            supplier_phone: '',
            supplier_address: '',
            supplier_author: '',
          }}
          submit={() => { this.setState({ modalSuppliershow: false }); this.getSuppliersData(); }}
        />
        : null
      }
    </>
    );
  }
}

export default RoleAdd;