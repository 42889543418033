import React, { useEffect, useState } from "react";
import axios from "axios";
import { Breadcrumb, Button, Card, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import swal from "sweetalert";

export default function MenuIndex() {
    const [dataClassify, setDataClassify] = useState([]);
    const [idClassify, setidClassify] = useState(1);
    const [show, setShow] = useState(false);
    const [showCopyMenu, setShowCopyMenu] = useState(false);
    const [typeTM, settypeTM] = useState(false);

    const [listmenu, setlistmenu] = useState("");
    const [listday, setlistday] = useState([]);
    const [listtime, setlisttime] = useState([]);
    const [foodOption, setFoodOption] = useState([]);
    const [abandonedFood, setAbandonedFood] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [staffGroup, setStaffGroup] = useState({});
    const [otherGroup, setOtherGroup] = useState({});
    const [listClassGroup, setListClassGroup] = useState([]);
    const [classGroupOption, setclassGroupOption] = useState([]);
    const [classGroupSelected, setClassGroupSelected] = useState({});

    // const [copiedMenu, setCopiedMenu] = useState({});

    const [dataday, setdataday] = useState({});
    const [datatime, setdatatime] = useState({});
    const [formFood, setFormFood] = useState([]);

    const [firstDayOfWeekToPaste, setFirstDayOfWeekToPaste] = useState(null);
    const [lastDayOfWeekToPaste, setLastDayOfWeekToPaste] = useState(null);

    const [start, setStartDate] = useState(new Date());
    const [term, setTermDate] = useState(new Date());
    const [weekInMonth, setWeekInMonth] = useState("");

    const currentDate = new Date();

    useEffect(() => {
        document.title = "Thực đơn";

        getClassifyData();
        getFoodData();
        getClassGroupData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //#region Fetch API
    const getClassifyData = () => {
        axios
            .get("/viewClassify")
            .then((res) => {
                if (res.data.status) {
                    setDataClassify(res.data.data);

                    let initialDate = new Date();
                    let initialWeek = setDate(initialDate);

                    setWeekInMonth((0 | initialWeek[0].getDate() / 7) + 1);

                    setClassify(res.data.data[0].id, initialWeek[0], initialWeek[initialWeek.length - 1]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const setClassify = (id, startDay, endDay) => {
        setClassGroupSelected({ value: 0, label: "Tất cả" });
        setidClassify(id);
        getSettingData(id, startDay, endDay);
    };

    const getSettingData = (type, startDay, endDay) => {
        axios
            .get(`/viewSetting?id_classify=${type}`)
            .then((res) => {
                if (res.data.status) {
                    setlistday(res.data.data.day);
                    setlisttime(res.data.data.time);

                    getMenuData(type, res.data.data.day, startDay, endDay);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const getMenuSettingData = (type, menuId) => {
    //     axios
    //         .get(`/viewSetting?id_classify=${type}&id_menu=${menuId}`)
    //         .then((res) => {
    //             if (res.data.status) {

    //                 if (
    //                     res.data.data.day.length !== 0 &&
    //                     res.data.data.time.length !== 0
    //                 ) {
    //                     setlistday(res.data.data.day);
    //                     setlisttime(res.data.data.time);
    //                 }

    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const getMenuData = (type, days, startDay, endDay) => {
        setIsLoading(true);

        let emptyMenu = {
            detail: [],
        };

        days.map((day) => {
            emptyMenu.detail.push({
                stt_day: day.stt_day,
                time: null
            });

            return day;
        });

        console.log('start>>', start);

        axios.get(`/viewMenu?id_classify=${type}&start_at=${startDay.getTime() / 1000}&term_at=${endDay.getTime() / 1000}`)
            .then((res) => {
                setIsLoading(false);

                if (res.data.status === true) {
                    let menu = res.data.data;
                    if (menu.length === 0) {
                        setlistmenu(emptyMenu);
                        return;
                    }
                    // getMenuSettingData(type, menu.id);
                    setlistmenu(menu);

                    // if (menu.start_at === parseInt(start.getTime() / 1000) && menu.term_at === parseInt(term.getTime() / 1000)) { //Check times 
                    let classGroupData = [];
                    if (menu.group.length > 0 && menu.group.length > 0) {

                        menu.group.map(group => {
                            classGroupData.push({ value: group.id, label: group.name });
                            return group;
                        });

                    }
                    classGroupData.unshift({ value: 0, label: "Tất cả" });

                    setClassGroupSelected(classGroupData[0]);
                    setclassGroupOption(classGroupData);
                    // }
                } else {
                    setlistmenu(emptyMenu);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const getFoodData = () => {
        axios.post(`/viewFood`).then((res) => {
            if (res.data.status === true) {

                let foodsData = [];
                if (res.data.data.length > 0) {
                    foodsData = res.data.data.map((item) => {

                        if (item.is_main !== 1) {
                            return { value: item.id, label: `${item.food_name} (Món phụ)`, isMain: item.is_main };
                        }

                        return { value: item.id, label: item.food_name, isMain: item.is_main };
                    });
                }

                setFoodOption(foodsData.reverse());
            }
        });
    };

    const getClassGroupData = () => {
        axios
            .get(`/viewClassGroup`)
            .then((res) => {
                if (res.data.status === true) {
                    setListClassGroup(res.data.data.filter(classGroup => Number(classGroup.is_adult) === 0 && Number(classGroup.is_main) === 1));
                    setStaffGroup(res.data.data.find(classGroup => Number(classGroup.is_adult) === 1));
                    setOtherGroup(res.data.data.find(classGroup => Number(classGroup.is_main) === 0));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getcreateMenu = (id_classify) => {
        if (idClassify === 1 && formFood.some(food => food.group.length === 0)) {
            toast("Chưa có nhóm học sinh để thêm món ăn", {
                type: "error",
                autoClose: 1000,
            });
            return;
        }

        if (idClassify === 1 && formFood.some(food => Number(food.amount) <= 0 && Number(food.group[0]) === otherGroup.id)) {
            toast("Số lượng món ăn không thể nhỏ hơn hoặc bằng không", {
                type: "error",
                autoClose: 1000,
            });
            return;
        }

        const detailParam = JSON.stringify([
            {
                id_day: dataday.id,
                day_name: dataday.day_name,
                time: [{
                    id_times: datatime.id,
                    time_name: datatime.time_name,
                    food: formFood
                }]
            }
        ]);

        axios
            .post(`/createMenu?id_classify=${id_classify}
                    &detail=${detailParam}
                    &start_at=${start.getTime() / 1000}
                    &term_at=${term.getTime() / 1000}`
            )
            .then((res) => {
                if (res.data.status === true) {
                    getMenuData(id_classify, [], start, term);
                    setShow(false);
                    toast(res.data.data.mess, {
                        type: "success",
                        autoClose: 1000,
                    });
                    setFoodOption(foodOption.concat(abandonedFood).sort(function (a, b) {
                        return - (a.value - b.value);
                    }
                    ));
                    setAbandonedFood([]);
                    setFormFood([]);
                } else {
                    toast(res.data.data.mess, {
                        type: "error",
                        autoClose: 1000,
                    });
                }
            });
    };

    const getupdateMenu = (id_classify, id) => {
        if (idClassify === 1 && formFood.some(food => food.group.length === 0)) {
            toast("Chưa có nhóm học sinh để thêm món ăn", {
                type: "error",
                autoClose: 1000,
            });
            return;
        }

        if (idClassify === 1 && formFood.some(food => Number(food.amount) <= 0 && Number(food.group[0]) === otherGroup.id)) {
            toast("Số lượng món ăn không thể nhỏ hơn hoặc bằng không", {
                type: "error",
                autoClose: 1000,
            });
            return;
        }

        const detailParam = JSON.stringify([
            {
                id_day: dataday.id,
                day_name: dataday.day_name,
                time: [{
                    id_times: datatime.id,
                    time_name: datatime.time_name,
                    food: formFood
                }]
            }
        ]);

        axios.post(`/updateMenu?id_menu=${id}
                        &id_classify=${id_classify}
                        &detail=${detailParam}
                        &start_at=${start.getTime() / 1000}
                        &term_at=${term.getTime() / 1000}`
        ).then((res) => {
            if (res.data.status === true) {
                getMenuData(id_classify, [], start, term);

                setShow(false);
                toast(res.data.data.mess, {
                    type: "success",
                    autoClose: 1000,
                });
                setFoodOption(foodOption.concat(abandonedFood).sort(function (a, b) {
                    return - (a.value - b.value);
                }
                ));
                setAbandonedFood([]);
                setFormFood([]);
            } else {
                toast(res.data.data.mess, {
                    type: "error",
                    autoClose: 1000,
                });
            }
        });
    };
    //#endregion

    //#region Handle Logic
    const eventModal = (modal, TMfood, idDay, idTime, dataFood) => {
        let data_ = [];
        setShow(modal);
        settypeTM(TMfood);
        setdataday(listday.find((day) => Number(day.id) === Number(idDay)));
        setdatatime(listtime.find((time) => Number(time.id) === Number(idTime)));

        if (dataFood.length) {
            dataFood.map((food) => {
                data_.push({
                    id: food.id,
                    food_name: food.food_name,
                    group: food.group.map(group => group.id),
                    amount: food.amount ? food.amount : 0,
                });

                return food;
            });
        }

        const tmpAbaFood = [];

        if (foodOption.some(foodItem => foodItem === undefined)) {
            foodOption.filter(foodItem => foodItem !== undefined);
        }

        const foodRemain = foodOption.map(foodItem => {
            if (dataFood.some(food => Number(food.id) === Number(foodItem.value))) {
                tmpAbaFood.push(foodItem);
                return undefined;
            }

            return foodItem;
        }).filter(foodItem => foodItem !== undefined);

        setAbandonedFood(tmpAbaFood);
        setFoodOption(foodRemain);
        setFormFood(data_);
    };

    const handleSelectFood = (food) => {
        setAbandonedFood([...abandonedFood, food]);
        setFoodOption(foodOption.filter(foodItem => foodItem.value !== food.value));

        if (idClassify === 1) {
            setFormFood([
                ...formFood,
                {
                    id: Number(food.value),
                    food_name: food.label,
                    group: Number(food.isMain) === 1 ? listClassGroup.filter(classGroup => Number(classGroup.is_adult) === 0 && Number(classGroup.is_main) === 1).map(classGroup => classGroup.id) : [otherGroup.id],
                    amount: 0,
                },
            ]);
        } else {
            setFormFood([
                ...formFood,
                {
                    id: Number(food.value),
                    food_name: food.label,
                    group: [staffGroup.id]
                },
            ]);
        }
    };

    const handleUnSelectFood = (foodId) => {
        setFoodOption([...foodOption, abandonedFood.filter(foodItem => Number(foodItem.value) === Number(foodId))[0]]);
        setAbandonedFood(abandonedFood.filter(foodItem => Number(foodItem.value) !== Number(foodId)));

        setFormFood(formFood.filter((food) => Number(food.id) !== Number(foodId)));
    };

    const xport = () => {
        const table = document.getElementById("menuTable");
        const wb = XLSX.utils.table_to_book(table);

        const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];

        var ws = wb.Sheets["Sheet1"];

        var wscols =
            [
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
            ];

        const colsActive = listday.filter(day => Number(day.is_active) === 1);
        const rowsActive = listtime.filter(time => Number(time.is_active) === 1);

        ws['!cols'] = wscols.slice(0, colsActive.length + 1);
        ws['!rows'] = [{ hpt: 30 }];

        // Style cell
        ws[`A1`].s = {
            font: {
                name: "Times New Roman",
                sz: 13.5,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            }
        };

        for (let i = 2; i < rowsActive.length + 3; i++) {
            ws[`A${i}`].s = {
                font: {
                    name: "Times New Roman",
                    sz: 11,
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };
        }

        colAlpha.slice(0, colsActive.length + 1).map((alpha) => {
            ws[`${alpha}2`].s = {
                font: {
                    name: "Times New Roman",
                    sz: 11,
                    bold: true,
                },
                alignment: {
                    vertical: "center",
                    horizontal: "center"
                },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
            };

            return alpha;
        })

        // Line break handling
        colAlpha.slice(1, colsActive.length + 1).map((alpha) => {
            for (let i = 3; i < rowsActive.length + 3; i++) {
                if (ws[`${alpha}${i}`.toString()].v === "empty") {
                    ws[`${alpha}${i}`.toString()].v = ' '
                }

                const str = ws[`${alpha}${i}`.toString()].v;
                ws[`${alpha}${i}`.toString()].v = str.replaceAll("/break", "\n")

                ws[`${alpha}${i}`.toString()].s = {
                    font: {
                        name: "Times New Roman",
                    },
                    alignment: {
                        vertical: "top",
                        horizontal: "left",
                        wrapText: true
                    },
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" },
                    },
                };
            }

            return alpha;
        })

        XLSX.writeFile(wb, idClassify === 1 ? "Thực đơn cho học sinh.xlsx" : "Thực đơn cho nhân viên.xlsx");
    };

    const handleExportMenu = () => {
        swal({
            title: `Xác nhận`,
            text: `Export thực đơn ${Number(classGroupSelected.value) === 0 ? "tổng" : "chi tiết"} Tháng ${start.getMonth() + 1} (Tuần ${weekInMonth}) ${stringFirstDate(start)} - ${stringLastDate(term)} cho ${idClassify === 1 ? Number(classGroupSelected.value) === 0 ? "học sinh" : classGroupSelected.label : "nhân viên"}`,
            icon: "info",
            buttons: ["Đóng", "Export"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                xport();
            }
        });
    }

    const dates = (current) => {
        var week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        current.setHours(0, 0, 0, 0);
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
            current.setHours(0, 0, 0, 0);
        }
        return week;
    }

    const setDate = (theDate) => {
        const week = dates(theDate);
        setStartDate(week[0]);
        setTermDate(week[week.length - 1]);
        return week;
    }

    const stringFirstDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

    const permission = (data, type = 'and') => {
        if (localStorage.getItem('role_rank') === '1') { return true; }

        const permission = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : [];

        if (data.length === 0) {
            return false;
        } else {
            let result = false;

            data.map((item) => {
                if (permission.length > 0) {
                    switch (type) {
                        case 'or':
                            if (permission.indexOf(item) > -1) { result = true; }
                            break;

                        case 'and':
                            if (permission.indexOf(item) > -1) {
                                result = true;
                            } else {
                                result = false;
                            }
                            break;
                        default: result = false;
                    }
                }

                return item;
            });

            return result;
        }
    }
    //#endregion

    // Render UI
    // const RenderMenuOption = () => {
    //     return(
    //         <div className="d-flex justify-content-between mb-3">
    //             {/* <Button
    //                 variant="success"
    //                 size="sm"
    //                 className="me-3"
    //                 style={idClassify === 2 ? { padding: "9px 7px", } : {}}
    //                 onClick={() => {
    //                     setShowCopyMenu(true)
    //                 }}
    //                 disabled={listmenu.id ? false : true}
    //             >
    //                 <i className="fa-solid fa-copy"></i> Sao chép thực đơn
    //             </Button> */}

    //             <div></div>

    //             {
    //                 idClassify === 2 ? "" : 
    //                 <Select
    //                     options={classGroupOption}
    //                     placeholder={<div>Tất cả</div>}
    //                     value={classGroupSelected}
    //                     onChange={(choice) => {
    //                         setClassGroupSelected(choice);
    //                     }}
    //                 />
    //             }
    //         </div>
    //     )
    // }

    const RenderCopyMenuModal = () => {

        var startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        var weekNumber = Math.ceil(days / 7);

        return (
            <Modal
                show={showCopyMenu}
                onHide={() => {
                    setShowCopyMenu(false);
                    setFirstDayOfWeekToPaste(null);
                    setLastDayOfWeekToPaste(null);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sao chép thực đơn</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>Bạn muốn sao chép thực đơn tuần ({stringFirstDate(start)} - {stringLastDate(term)}) vào tuần:</div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {firstDayOfWeekToPaste && lastDayOfWeekToPaste
                                ? `(${stringFirstDate(firstDayOfWeekToPaste)} - ${stringLastDate(lastDayOfWeekToPaste)})`
                                : ""
                            }
                        </div>

                        <input
                            type="week"
                            name="week"
                            style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                            className="mt-3"
                            onChange={(e) => {
                                const firstDayOfWeek = new Date(e.target.valueAsNumber);
                                const pastWeek = dates(firstDayOfWeek);
                                setFirstDayOfWeekToPaste(pastWeek[0]);
                                setLastDayOfWeekToPaste(pastWeek[pastWeek.length - 1]);
                                // setCopiedMenu(listmenu.id);
                            }}
                            min={`${currentDate.getFullYear()}-W${weekNumber + 1}`}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="success"
                        onClick={() => {
                            // console.log(copiedMenu);
                            // console.log(firstDayOfWeekToPaste.getTime()/1000);
                            // console.log(lastDayOfWeekToPaste.getTime()/1000);
                        }}
                    >
                        Sao chép
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const Rendermenu = () => {
        if (!listmenu.id) {
            listday.map(dayObj => {
                if (listmenu.detail?.some(day_ => Number(day_.stt_day) === Number(dayObj.stt_day))) {
                    return "";
                }

                listmenu.detail?.push({
                    stt_day: dayObj.stt_day,
                    time: null
                });

                return dayObj;
            })

            listmenu.detail?.sort(function (a, b) {
                return a.stt_day - b.stt_day;
            });

            listmenu.detail?.map((dayObj) => {
                let missingId = [];

                listtime.map((time_) => {
                    if (dayObj.time) {
                        if (dayObj.time.some((time) => parseInt(time.stt_times) === Number(time_.stt_time))) {
                            return "";
                        }
                    }
                    missingId.push(time_.stt_time);
                    return time_;
                })

                if (!dayObj.time) {
                    dayObj.time = [];
                }

                missingId.map((id) => {
                    dayObj.time.push({ stt_times: id.toString(), food: [] })
                    return id;
                })

                dayObj.time.sort(function (a, b) {
                    return a.stt_times - b.stt_times;
                });

                return dayObj;
            })
        }

        let bigArray = []

        let timeArray = []

        listmenu.detail?.map((dayObj) => {
            dayObj.time.map((timeObj) => {
                bigArray.push(timeObj);
                return timeObj;
            })
            return dayObj;
        })

        listtime.map((timeObj) => {
            let tmpArray = bigArray.filter((arr) => arr.stt_times === timeObj.stt_time.toString());

            timeArray.push(tmpArray)

            return timeObj;
        })

        let colsActive = 0;

        listday.map(day => {
            if (Number(day.is_active) === 1) {
                colsActive += 1;
            }

            return day;
        })

        return (
            <>
                {
                    !isLoading
                        ?
                        timeArray.length !== 0
                            ?
                            <div style={{ overflowX: "scroll" }}>
                                <Table striped bordered size="sm" style={{ minWidth: 999 }}>
                                    <thead>
                                        <tr className="d-none">
                                            <th colSpan={colsActive + 1}>
                                                {idClassify === 1
                                                    ?
                                                    Number(classGroupSelected.value) === 0
                                                        ?
                                                        "Học sinh"
                                                        :
                                                        classGroupSelected.label
                                                    :
                                                    "Nhân viên"}

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                Thực đơn Tháng {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Thứ</th>
                                            {listday.map((day, index) => {
                                                if (!day.is_active) {
                                                    return "";
                                                }
                                                return (
                                                    <th
                                                        key={index}
                                                        className="text-center"
                                                    >
                                                        {day.day_name}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            timeArray.map((timeObj, timeIndex) => {

                                                if (Number(listtime[timeIndex].is_active) === 0) {
                                                    return "";
                                                }

                                                return (
                                                    <tr key={timeIndex}>
                                                        <th className="text-center">{listtime.find((time) => time.stt_time.toString() === timeObj[0]?.stt_times)?.time_name}</th>
                                                        {
                                                            timeObj.map((timeItem, dayIndex) => {

                                                                if (Number(listday[dayIndex]?.is_active) === 0) {
                                                                    return "";
                                                                }

                                                                // const cruDate = new Date();
                                                                // let lineInWeek = cruDate.getDay();

                                                                // if(Number(lineInWeek) === 0){
                                                                //     lineInWeek = 7;
                                                                // }

                                                                // if(currentDate.getTime() > term.getTime() + 86400){
                                                                //     lineInWeek = 8;
                                                                // }

                                                                // if(currentDate.getTime() < start.getTime()){
                                                                //     lineInWeek = -1;
                                                                // }

                                                                return (
                                                                    <td
                                                                        key={dayIndex}
                                                                        className={
                                                                            // dayIndex+1 >= lineInWeek && 
                                                                            permission(['53', "54"], 'or') ? "menu-table-cell" : "disabled-cell"}
                                                                        onClick={() => {

                                                                            if (!permission(['53', "54"], 'or')) {
                                                                                toast("Bạn không có quyền thay đổi thực đơn", {
                                                                                    type: "warning",
                                                                                    autoClose: 3000,
                                                                                });

                                                                                return;
                                                                            }

                                                                            // if(dayIndex+1 >= lineInWeek ){

                                                                            if (timeItem.is_calcultor) {
                                                                                toast("Thực đơn đã lưu khẩu phần ăn. Không thể thay đổi", {
                                                                                    type: "warning",
                                                                                    autoClose: 3000,
                                                                                });
                                                                                return;
                                                                            }

                                                                            if (listmenu.detail[dayIndex].is_market) {
                                                                                toast("Thực đơn đã lưu phiếu đi chợ. Không thể thay đổi", {
                                                                                    type: "warning",
                                                                                    autoClose: 3000,
                                                                                });
                                                                                return;
                                                                            }

                                                                            eventModal(true, timeItem.food.length === 0, listday[dayIndex].id, listtime[timeIndex].id, timeItem.food)

                                                                            // } 
                                                                            // else {
                                                                            //     toast("Đã qua ngày thay đổi thực đơn", {
                                                                            //         type: "warning",
                                                                            //         autoClose: 3000,
                                                                            //     });
                                                                            // }
                                                                        }}
                                                                    >
                                                                        {
                                                                            timeItem.food.map(food => {
                                                                                return (
                                                                                    <div key={food.id}>
                                                                                        {/* Food Name */}
                                                                                        {
                                                                                            Number(classGroupSelected.value) === 0
                                                                                                ?
                                                                                                <div className="fw-bold">{food.food_name}</div>
                                                                                                :
                                                                                                food.group.map(groupObj => {
                                                                                                    if (Number(groupObj.id) === Number(classGroupSelected.value)) {
                                                                                                        return (
                                                                                                            <div key={Number(groupObj.id)} className="fw-bold">{food.food_name}</div>
                                                                                                        );
                                                                                                    }
                                                                                                    return "";
                                                                                                })
                                                                                        }

                                                                                        {/* Class Group */}
                                                                                        {
                                                                                            Number(classGroupSelected.value) === 0 && idClassify === 1 ?
                                                                                                food.group.length === listClassGroup.length
                                                                                                    ? ""
                                                                                                    :
                                                                                                    food.group.map(group => {

                                                                                                        if (Number(group.id) === Number(otherGroup.id)) {
                                                                                                            return (
                                                                                                                <div key={Number(group.id)} style={{ fontSize: 12 }}>
                                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                                    ({food.amount} phần)
                                                                                                                </div>
                                                                                                            )
                                                                                                        }

                                                                                                        return (
                                                                                                            <div key={Number(group.id)} style={{ fontSize: 12 }}>
                                                                                                                <div className="d-none">{" /break "}</div>
                                                                                                                ({group.name})
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                : ""
                                                                                        }

                                                                                        {/* Ingredient */}
                                                                                        {
                                                                                            Number(classGroupSelected.value) !== 0
                                                                                                ?

                                                                                                food.group.map((groupObj) => {
                                                                                                    if (Number(groupObj.id) === Number(classGroupSelected.value)) {
                                                                                                        return (
                                                                                                            <div key={Number(groupObj.id)}>
                                                                                                                <div className="d-none">{" /break "}</div>
                                                                                                                <div className="d-none">{" /break "}</div>
                                                                                                                <div>
                                                                                                                    {groupObj.ingrendent.map((ingre, index) => {

                                                                                                                        if (Number(ingre.amount) === 0) {
                                                                                                                            return "";
                                                                                                                        }

                                                                                                                        return (
                                                                                                                            <div key={index} style={{ fontSize: 12 }}>
                                                                                                                                {ingre.ingredent_name} {ingre.amount}{ingre.unit_name}
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <div className="d-none">{" /break "}</div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    return "";
                                                                                                })

                                                                                                : ""
                                                                                        }

                                                                                        {
                                                                                            Number(classGroupSelected.value) === 0
                                                                                                ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                </>
                                                                                                : ""
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <Table
                                    id="menuTable"
                                    striped
                                    bordered
                                    size="sm"
                                    className="d-none"
                                >
                                    <thead>
                                        <tr className="d-none">
                                            <th colSpan={colsActive + 1}>
                                                {idClassify === 1
                                                    ?
                                                    Number(classGroupSelected.value) === 0
                                                        ?
                                                        "Học sinh"
                                                        :
                                                        classGroupSelected.label
                                                    :
                                                    "Nhân viên"}

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                Thực đơn Tháng {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Thứ</th>
                                            {listday.map((day, index) => {
                                                if (!day.is_active) {
                                                    return "";
                                                }
                                                return (
                                                    <th
                                                        key={index}
                                                        className="text-center"
                                                    >
                                                        {day.day_name}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            timeArray.map((timeObj, timeIndex) => {

                                                if (Number(listtime[timeIndex].is_active) === 0) {
                                                    return "";
                                                }

                                                return (
                                                    <tr key={timeIndex}>
                                                        <th className="text-center">{listtime.find((time) => time.stt_time.toString() === timeObj[0]?.stt_times)?.time_name}</th>
                                                        {
                                                            timeObj.map((timeItem, dayIndex) => {

                                                                if (Number(listday[dayIndex]?.is_active) === 0) {
                                                                    return "";
                                                                }

                                                                const cruDate = new Date();
                                                                let lineInWeek = cruDate.getDay();

                                                                if (Number(lineInWeek) === 0) {
                                                                    lineInWeek = 7;
                                                                }

                                                                if (currentDate.getTime() > term.getTime() + 86400) {
                                                                    lineInWeek = 8;
                                                                }

                                                                if (currentDate.getTime() < start.getTime()) {
                                                                    lineInWeek = -1;
                                                                }

                                                                return (
                                                                    <td
                                                                        key={dayIndex}
                                                                        className={dayIndex + 1 >= lineInWeek && permission(['53', "54"], 'or') ? "menu-table-cell" : "disabled-cell"}
                                                                        onClick={() => {

                                                                            if (!permission(['53', "54"], 'or')) {
                                                                                toast("Bạn không có quyền thay đổi thực đơn", {
                                                                                    type: "warning",
                                                                                    autoClose: 3000,
                                                                                });

                                                                                return;
                                                                            }

                                                                            if (dayIndex + 1 >= lineInWeek) {

                                                                                if (timeItem.is_calcultor) {
                                                                                    toast("Thực đơn đã lưu khẩu phần ăn. Không thể thay đổi", {
                                                                                        type: "warning",
                                                                                        autoClose: 3000,
                                                                                    });
                                                                                    return;
                                                                                }

                                                                                eventModal(true, timeItem.food.length === 0, listday[dayIndex].id, listtime[timeIndex].id, timeItem.food)
                                                                            } else {
                                                                                toast("Đã qua ngày thay đổi thực đơn", {
                                                                                    type: "warning",
                                                                                    autoClose: 3000,
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            timeItem.food.length !== 0
                                                                                ?
                                                                                timeItem.food.map(food => {
                                                                                    return (
                                                                                        <div key={food.id}>
                                                                                            {/* Food Name */}
                                                                                            {
                                                                                                Number(classGroupSelected.value) === 0
                                                                                                    ?
                                                                                                    <div className="fw-bold">{food.food_name}</div>
                                                                                                    :
                                                                                                    food.group.map(groupObj => {
                                                                                                        if (Number(groupObj.id) === Number(classGroupSelected.value)) {
                                                                                                            return (
                                                                                                                <div key={Number(groupObj.id)} className="fw-bold">{food.food_name}</div>
                                                                                                            );
                                                                                                        }
                                                                                                        return "";
                                                                                                    })
                                                                                            }

                                                                                            {/* Class Group */}
                                                                                            {
                                                                                                Number(classGroupSelected.value) === 0 && idClassify === 1 ?
                                                                                                    food.group.length === listClassGroup.length
                                                                                                        ? ""
                                                                                                        :
                                                                                                        food.group.map(group => {

                                                                                                            if (Number(group.id) === Number(otherGroup.id)) {
                                                                                                                return (
                                                                                                                    <div key={Number(group.id)} style={{ fontSize: 12 }}>
                                                                                                                        <div className="d-none">{" /break "}</div>
                                                                                                                        ({food.amount} phần)
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                            return (
                                                                                                                <div key={Number(group.id)} style={{ fontSize: 12 }}>
                                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                                    ({group.name})
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    : ""
                                                                                            }

                                                                                            {/* Ingredient */}
                                                                                            {
                                                                                                Number(classGroupSelected.value) !== 0
                                                                                                    ?

                                                                                                    food.group.map((groupObj) => {
                                                                                                        if (Number(groupObj.id) === Number(classGroupSelected.value)) {
                                                                                                            return (
                                                                                                                <div key={Number(groupObj.id)}>
                                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                                    <div>
                                                                                                                        {groupObj.ingrendent.map((ingre, index) => {

                                                                                                                            if (Number(ingre.amount) === 0) {
                                                                                                                                return "";
                                                                                                                            }

                                                                                                                            return (
                                                                                                                                <div key={index} style={{ fontSize: 12 }}>
                                                                                                                                    {ingre.ingredent_name} {ingre.amount}{ingre.unit_name}
                                                                                                                                    <br />
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                    <br />
                                                                                                                    <div className="d-none">{" /break "}</div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                        return "";
                                                                                                    })

                                                                                                    : ""
                                                                                            }

                                                                                            {
                                                                                                Number(classGroupSelected.value) === 0
                                                                                                    ?
                                                                                                    <>
                                                                                                        <br />
                                                                                                        <div className="d-none">{" /break "}</div>
                                                                                                    </>
                                                                                                    : ""
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                : null
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center">
                                Không có dữ liệu
                            </div>
                        :
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                <span className="sr-only">Đang tải thực đơn...</span>
                            </div>
                        </div>
                }
            </>
        );
    };

    return (
        <>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Thực đơn tuần</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>
                        <div className="d-block d-md-flex justify-content-between">
                            <div className="d-block d-md-flex">
                                <Card.Title className="me-3 pt-0 pt-sm-1">
                                    Thực đơn Tháng {start.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(start)} - {stringLastDate(term)}
                                </Card.Title>

                                <div className="d-flex align-items-center mt-3 mt-sm-0">
                                    <input
                                        type="week"
                                        name="week"
                                        style={{ border: "none", width: 39, backgroundColor: "rgb(16,108,252)", borderRadius: 5 }}
                                        className="me-2"
                                        onChange={(e) => {
                                            const today = e.target.value ? new Date(e.target.valueAsNumber) : new Date();
                                            const week = setDate(today);

                                            setWeekInMonth((0 | week[0].getDate() / 7) + 1);
                                            getSettingData(idClassify, week[0], week[week.length - 1]);
                                        }}
                                    />

                                    {
                                        idClassify === 2 ? "" :
                                            <Select
                                                options={classGroupOption}
                                                placeholder={<div>Tất cả</div>}
                                                value={classGroupSelected}
                                                onChange={(choice) => {
                                                    setClassGroupSelected(choice);
                                                }}
                                            />
                                    }
                                </div>
                            </div>

                            {listmenu.id ?
                                <>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() => handleExportMenu()}
                                        // disabled={listmenu.id ? false : true}
                                        className="mt-3 mt-md-0"
                                    >
                                        Xuất file <i className="fa-solid fa-file-export"></i>
                                    </Button>
                                </>
                                : ""}
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <Tabs
                            defaultActiveKey={idClassify}
                            unmountOnExit={true}
                            transition={false}
                            onSelect={(key) => {
                                setClassify(Number(key), start, term);
                            }}
                            className="mb-3"
                        >
                            <Tab key={1} title={'Học sinh'} eventKey={1}  >
                                {Rendermenu()}
                            </Tab>

                            {/* {dataClassify && dataClassify.map((item) => {
                                    return (
                                        <Tab
                                            key={item.id}
                                            title={item.classify_name}
                                            eventKey={item.id}
                                        >
                                            {/* {RenderMenuOption()} 

                                            {Rendermenu()}
                                        </Tab>
                                    );
                            })} */}
                        </Tabs>
                    </Card.Body>
                </Card>

                {/* Food Change Modal */}
                <Modal
                    show={show}
                    size={idClassify === 1 ? "lg" : ""}
                    onHide={() => {
                        setShow(false);

                        setFoodOption(foodOption.concat(abandonedFood).sort(function (a, b) {
                            return - (a.value - b.value);
                        }
                        ));
                        setAbandonedFood([]);

                        setFormFood([]);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{typeTM ? "Thêm món" : "Đổi món"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Thứ</Form.Label>
                                    <Form.Control
                                        value={dataday.day_name}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Buổi</Form.Label>
                                    <Form.Control
                                        value={datatime.time_name}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            idClassify === 1 && formFood.length > 0
                                ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Món ăn</th>
                                            {
                                                listClassGroup.map(classGroup => {
                                                    return (
                                                        <th key={Number(classGroup.id)} className="text-center">{classGroup.group_name}</th>
                                                    )
                                                })
                                            }
                                            {
                                                formFood.some(food => Number(food.group[0]) === Number(otherGroup.id))
                                                    ?
                                                    <th className="">Số lượng</th>
                                                    : ""
                                            }
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formFood.map((food) => {
                                                return (
                                                    <tr key={Number(food.id)}>
                                                        <td>{food.food_name}</td>
                                                        {
                                                            food.group.some(id => Number(id) === Number(otherGroup.id))
                                                                ?
                                                                listClassGroup.map(classGroup => <td key={Number(classGroup.id)}></td>)
                                                                :
                                                                listClassGroup.map(classGroup => {
                                                                    return (
                                                                        <td key={Number(classGroup.id)} className="text-center">
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                checked={
                                                                                    food.group?.some(id => Number(id) === Number(classGroup.id))
                                                                                }
                                                                                value={Number(classGroup.id)}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFormFood(
                                                                                            formFood.map(foodItem => {
                                                                                                if (Number(food.id) === Number(foodItem.id)) {
                                                                                                    const tmpArray = Number(foodItem.group[0]) !== 0 ? foodItem.group : [];
                                                                                                    tmpArray.push(Number(e.target.value))

                                                                                                    return {
                                                                                                        id: foodItem.id,
                                                                                                        food_name: foodItem.food_name,
                                                                                                        group: tmpArray,
                                                                                                        amount: foodItem.amount
                                                                                                    };
                                                                                                }
                                                                                                return foodItem;
                                                                                            })
                                                                                        )
                                                                                    } else {
                                                                                        setFormFood(
                                                                                            formFood.map(foodItem => {
                                                                                                if (Number(food.id) === Number(foodItem.id)) {
                                                                                                    return {
                                                                                                        id: foodItem.id,
                                                                                                        food_name: foodItem.food_name,
                                                                                                        group: foodItem.group.filter(id => Number(id) !== Number(e.target.value)),
                                                                                                        amount: foodItem.amount
                                                                                                    };
                                                                                                }
                                                                                                return foodItem;
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    )
                                                                })
                                                        }

                                                        {
                                                            food.group.some(id => Number(id) === Number(otherGroup.id))
                                                                ?
                                                                <td>
                                                                    <CurrencyFormat
                                                                        style={{ width: 69, height: 27 }}
                                                                        className="form-control text-end"
                                                                        thousandSeparator
                                                                        required
                                                                        value={food.amount}
                                                                        allowNegative={false}
                                                                        onValueChange={(values) => {
                                                                            food.amount = values.value;
                                                                        }}
                                                                    />
                                                                </td>
                                                                :
                                                                <td></td>
                                                        }

                                                        <td className="text-center">
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                onClick={() => handleUnSelectFood(Number(food.id))}
                                                            >
                                                                Bỏ
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : ""
                        }

                        {
                            idClassify === 2 && formFood.length > 0
                                ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Món ăn</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formFood.map(food => {
                                            return (
                                                <tr key={Number(food.id)}>
                                                    <td>{food.food_name}</td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => handleUnSelectFood(Number(food.id))}
                                                        >
                                                            Bỏ
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                : ""
                        }

                        <Form.Group>
                            <Form.Label>
                                Chọn món<i className="text-danger">*</i>
                            </Form.Label>
                            {console.log('foodOption>>', foodOption)}
                            <Select
                                options={idClassify === 1 ? foodOption : foodOption.filter(food => Number(food.isMain) === 1)}
                                placeholder={<div>Nhập tên món ăn</div>}
                                onChange={(choice) => {
                                    if (!formFood.some(food => Number(food.id) === Number(choice.value))) {
                                        handleSelectFood(choice);
                                    } else {
                                        toast("Bạn đã chọn món ăn này rồi", {
                                            type: "error",
                                            autoClose: 1000,
                                        });
                                    }
                                }}
                            />

                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            type="submit"
                            variant="success"
                            onClick={() => {
                                if (!listmenu.id) {
                                    getcreateMenu(idClassify);
                                } else {
                                    getupdateMenu(idClassify, listmenu.id);
                                }
                            }}
                        >
                            {typeTM ? "Thêm" : "Đổi"}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {RenderCopyMenuModal()}
            </Container>
        </>
    );
}