import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
registerLocale("vi", vi);

const FormSettingDatePicker = ({ selected, onChange, className = "text-center form-control" }) => {
	return (
		<DatePicker
			selected={selected}
			onChange={onChange}
			onKeyDown={(e) => {
				e.preventDefault();
			}}
			locale="vi"
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={15}
			timeCaption="Thời gian"
			dateFormat="HH:mm aaaa"
			className={className}
		/>
	);
};

export default FormSettingDatePicker;
