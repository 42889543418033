import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import Check from "../other/Check";
// import { convertLegacyProps } from "antd/es/button/button";
import DataTables from "../datatable/DataTables";

import swal from "sweetalert";
dayjs.extend(customParseFormat);

export default function MealcutIndex() {
  let [dateRange, setDateRange] = useState([new Date(), new Date()]);
  let [startDate, endDate] = dateRange;
  const [dataClassify, setDataClassify] = useState([]);
  const [idClassify, setidClassify] = useState();
  const [show, setShow] = useState(false);
  const [idCut, setIdcut] = useState(0);
  const [ismeal, setIsmael] = useState(0);
  const [ListCut, setListCut] = useState([]);
  const [datecut, setdatecut] = useState([]);
  const [sessioncut, setsessioncut] = useState([]);
  const today = new Date();
  const [dataListUser, setDataListUser] = useState([]);
  const [dataListStudent, setDataListStudent] = useState([]);
  const [dataListSearch, setDataListSearch] = useState([]);
  const [keyGroup, setKeyGroup] = useState({ value: 0, label: "Tất cả nhóm" });
  const [keyClass, setKeyClass] = useState({ value: 0, label: "Tất cả lớp" });
  const [keySearch, setKeySearch] = useState([]);
  const [optionsGroup, setOptionsGroup] = useState([]);
  const [optionsClass, setOptionsClass] = useState([
    { value: 0, label: "Tất cả lớp" },
  ]);
  const [optionsMonth, setOptionMonth] = useState({ value: 1, label: "1" });
  const [selectMonth, setSelectMonth] = useState({
    value: today.getMonth() + 1,
    label: "Tháng " + Number(today.getMonth() + 1),
  });
  const [setMonth, setsetMonth] = useState(today.getMonth() + 1);
  const [selectCut, setSelectCut] = useState(1);
  const [NoteCut, setNoteCut] = useState("");
  const [selectOption, setselectoption] = useState([
    { label: "Nghỉ", value: 1 },
  ]);
  const [checkloading, setCheckloading] = useState(true);
  const [showCutMealDetail, setshowCutMealDetail] = useState(false);
  const [cutMealDetail, setcutMealDetail] = useState([]);
  let [checked, setChecked] = useState([]);
  const [selectDataDate, setselectDataDate] = useState([]);
  const [selectDataMonth, setselectDataMonth] = useState([]);
  const [selectDataYear, setselectDataYear] = useState([]);
  const [dataDate, setdataDate] = useState({ value: 0, label: "Tất cả ngày" });
  const [dataMonth, setdataMonth] = useState([
    {
      value: new Date().getMonth() + 1,
      label: "Tháng " + Number(new Date().getMonth() + 1),
    },
  ]);
  const [dataYear, setdataYear] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });
  const [choiceDate, setchoiceDate] = useState([
    { value: 0, label: "Tất cả ngày" },
  ]);
  const [choiceMonth, setchoiceMonth] = useState({
    value: new Date().getMonth() + 1,
    label: new Date().getMonth() + 1,
  });
  const [choiceYear, setchoiceYear] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });
  const [cutMealDetailSearch, setcutMealDetailSearch] = useState([]);
  const [showloading, setshowloading] = useState(false);
  const [startMonth, setStartMonth] = useState(new Date());
  const [startDatee, setStartDatee] = useState(
    new Date(new Date().setHours(0, 0, 0))
  );
  const [endDatee, setEndDate] = useState(
    new Date(new Date().setHours(23, 59, 59))
  );
  var listDay = [];
  useEffect(() => {
    document.title = "Cắt suất ăn";
    getClassify();
    setEndDate(lastDayy(new Date().getFullYear(), new Date().getMonth() + 1));
    selectMonth1();
    // setStartDatee(new Date().setDate(1))
    selectDate(new Date(choiceYear.value, choiceMonth.value, 0));
    selectYear(today.getFullYear());
  }, []);

  const getOption = () => {
    let option = [
      // { label: "Hình thức cắt", value: 0 },
      { label: "Nghỉ", value: 1 },
      { label: "Cắt suất", value: 2 },
    ];
    return (
      <Row>
        <div className="text-danger">
          {" "}
          Lưu ý : Chọn cắt suất nếu{" "}
          {idClassify == "1" ? " bé đi học" : "đi làm"} nhưng không ăn.
        </div>

        <Col md={4}>
          {}
          <Select
            styles={{ height: "20px" }}
            options={ismeal ? option.filter((e) => e.value !== 2) : option}
            defaultValue={option[1]}
            value={selectOption}
            onChange={(choice) => {
              setSelectCut(choice.value);
              setselectoption(choice);
            }}
          ></Select>
        </Col>
        <Col md={8}>
          <Form.Floating>
            <Form.Control
              type="text"
              style={{ height: "40px" }}
              onChange={(e) => {
                setNoteCut(e.target.value);
              }}
              placeholder="Lý do cắt suất"
              required={true}
            />
            <Form.Label style={{ padding: "10px" }}>
              Lý do<i className="text-danger">*</i>
            </Form.Label>
          </Form.Floating>
        </Col>
        {selectCut === 2 ? (
          // (getOptionCut())
          <>{getOptionCut()}</>
        ) : null}
      </Row>
    );
  };
  const getDateD = (type, data) => {
    let date = new Date(data * 1000);
    let result = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    if (type === 2) result = `${date.getMonth() + 1}/${date.getFullYear()}`;
    if (type === 3) result = `${date.getFullYear()}`;
    return result;
  };
  const getTime = (data) => {
    let date = new Date(data * 1000);
    let result =
      // `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);

    return result;
  };

  const onChangeDate = (e) => {
    setchoiceDate(e);
    setdataDate({ value: e.value, label: e.value === 0 ? e.label : e.value });
    searchDate(e.value, choiceMonth.value, choiceYear.value, cutMealDetail);
  };

  const onChangeMonth = (e, year) => {
    setchoiceMonth(e);

    var lastDayOfLastMonth = new Date(year, e.value, 0);
    selectDate(lastDayOfLastMonth);
    setdataMonth({ value: e.value, label: e.value === 0 ? e.label : e.label });
    // setdataDate({ value: 0, label: "Tất cả" })

    searchDate(dataDate.value, e.value, year, cutMealDetail);
  };

  const onChangeYear = (e) => {
    setchoiceYear(e);
    setdataDate({ value: 0, label: "Tất cả ngày" });
    setdataYear({ value: e.value, label: e.value });
    onChangeMonth({ value: 0, label: "Tất cả" }, e.value);
  };

  const searchDate = (day, month, year, cutMealDetail) => {
    var arr_ = cutMealDetail;
    var date_ = day + "/" + month + "/" + year;
    if (day === 0) date_ = month + "/" + year;
    if (month === 0) date_ = year;
    var type = day > 0 ? 1 : month > 0 ? 2 : 3;
    arr_ = arr_.filter((e) => getDateD(type, e.meal_day) == date_);
    arr_.sort(function (a, b) {
      if (a.is_status <= b.is_status) return -1;
      if (a.is_status > b.is_status) return 1;
      return 0;
    });
    setcutMealDetailSearch(arr_);
  };
  const selectMonth1 = () => {
    let viewSelectMonth = [];
    viewSelectMonth.push({ value: 0, label: "Tất cả tháng" });
    for (let i = 1; i <= 12; i++) {
      viewSelectMonth.push({ value: i, label: "Tháng " + i });
    }
    setselectDataMonth(viewSelectMonth);
  };
  const selectYear = (year) => {
    let viewSelectYear = [];
    for (let i = year - 10; i <= year; i++) {
      viewSelectYear.push({ value: i, label: i });
    }
    setselectDataYear(viewSelectYear);
  };
  const selectDate = (day) => {
    let viewSelecDate = [];
    viewSelecDate.push({ value: 0, label: "Tất cả ngày" });
    for (let i = 1; i <= day.getDate(); i++) {
      viewSelecDate.push({ value: i, label: i });
    }
    setselectDataDate(viewSelecDate);
  };
  const getOptionCut = () => {
    var arr_ = [];
    var luuy = <span>Lưu ý</span>;
    sessioncut.map((item, index) =>
      item.is_active
        ? arr_.push({ value: index + 1, label: item.time_name })
        : null
    );
    const nodes = [
      {
        value: "0",
        // label: 'Không ăn cả ngày ',

        children: arr_,
      },
    ];
    return (
      <>
        <CheckboxTree
          nodes={arr_}
          showExpandAll
          checked={checked}
          expanded={[0]}
          onCheck={(checked) => {
            setChecked(checked);
          }}
          icons={{
            check: (
              <i
                className="fa-solid fa-square-check"
                style={{ color: "#005eff" }}
              ></i>
            ),
            uncheck: (
              <i
                className="fa-regular fa-square"
                style={{ color: "#005eff" }}
              ></i>
            ),
            halfCheck: (
              <i
                className="fa-regular fa-square "
                style={{ color: "#99beff" }}
              ></i>
            ),
            expandClose: "",
            expandOpen: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      </>
    );
  };
  const getClassify = () => {
    axios
      .get("/viewClassify")
      .then((res) => {
        if (res.data.status) {
          setDataClassify(res.data.data);
          setClassify(res.data.data[0].id);
          setCheckloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    selectMonth_();
  };

  const setClassify = (id) => {
    setidClassify(id);
    getSetting(id);
  };

  const checkSpicialDate = (date) => {
    const day = date.getDay();
    datecut.map((item) => {
      if (item.is_active === 1) {
        if (item.stt_day === 7) {
          listDay.push(0);
        } else {
          listDay.push(item.stt_day);
        }
      }
      return item;
    });
    return listDay.includes(day);
  };

  const getSetting = (idClassify) => {
    axios.get(`/viewSetting?id_classify=${idClassify}`).then((res) => {
      setdatecut(res.data.data.day);
      setsessioncut(res.data.data.time);
      idClassify.toString() === "1"
        ? getClassRoom({ value: 0, label: "Tất cả nhóm" })
        : getAllUser();
    });
  };

  const getAllUser = () => {
    axios
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          setDataListUser(res.data.data);
          handleSearch(res.data.data, "");
          getMealByClasses(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getGroup = () => {
  // 	let dataOptionsGroup = [];
  // 	axios.get("/viewClassGroup").then((res) => {
  // 		if (res.data.status === true) {
  // 			setCheckloading(false)
  // 			res.data.data.map((item, index) => {
  // 				if (item.is_adult === 0 && item.is_main === 1) dataOptionsGroup.push({ value: item.id, label: item.group_name })
  // 				return item;
  // 			})
  // 			dataOptionsGroup.unshift({ value: 0, label: "Tất cả nhóm" });

  // 			setOptionsGroup(dataOptionsGroup);
  // 			getClassRoom({ value: 0, label: "Tất cả nhóm" });
  // 		}
  // 	})
  // }

  const getClassRoom = (data) => {
    setKeyGroup(data);
    let dataOptionsClass = [];
    dataOptionsClass.push({
      value: 0,
      lable: "Tất cả lớp",
    });
    axios
      .get("/viewClassRoom")
      .then((res) => {
        let dataRes = res.data.data;
        if (data.value > 0)
          dataRes = res.data.data.filter((e) => e.id_group === data.value);
        dataRes.map((item, index) => {
          dataOptionsClass.push({ value: item.id, label: item.class_name });
          return item;
        });

        setOptionsClass(dataOptionsClass);
        getMealByClasses(dataRes[0]?.id ? dataRes[0].id : 0);
        setClass(
          {
            value: dataRes[0]?.id ? dataRes[0].id : 0,
            label: dataRes[0]?.class_name
              ? dataRes[0]?.class_name
              : " Tất cả lớp",
          },
          startDatee,
          endDatee
        );
      })
      .catch((err) => console.log(err));
  };

  const setClass = (data, start, end) => {
    setKeyClass(data);
    // axios.post(`/viewChilClassRoom?id_class=${data.value}&start=${lastDayy(end.getFullYear(), end.getMonth() + 1) / 1000}`).then((res) => {

    axios
      .post(
        `/viewChilClassRoom?id_class=${data.value}&start=${
          new Date(start.setDate(1)) / 1000
        }&end=${lastDayy(end.getFullYear(), end.getMonth() + 1) / 1000}`
      )
      .then((res) => {
        setDataListStudent(res.data.data);
        getMealByClasses(data.value);
        handleSearch(res.data.data, "");
      })
      .catch((err) => console.log(err));
  };

  const getMealByClasses = (classId) => {
    setCheckloading(true);
    let va_ = "";
    if (classId == 0) {
      va_ = `/viewMeal?id_classify=1`;
    } else {
      va_ = `/viewMeal?id_classify=1&id_class=${classId}`;
    }
    if (classId === null) va_ = `/viewMeal?id_classify=2`;
    axios
      .post(va_)
      .then((res) => {
        setCheckloading(false);

        if (res.data.status === true) setListCut(res.data.data);
        searchDate(0, 0, new Date().getFullYear(), res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCutMealDetailData = (id_person) => {
    setshowCutMealDetail(true);

    let classifyLink = `/viewMeal?id_classify=2&id_person=${id_person}`;

    if (idClassify.toString() === "1") {
      classifyLink = `/viewMeal?id_classify=1&id_class=${keyClass.value}&id_person=${id_person}`;
    }
    axios.post(classifyLink).then((res) => {
      if (res.data.status === true) {
        setcutMealDetail(res.data.data);
        searchDate(
          0,
          new Date().getMonth() + 1,
          today.getFullYear(),
          res.data.data
        );
      } else {
      }
    });
  };
  const getCutMealDetailData_ = (id_person) => {
    let classifyLink = `/viewMeal?id_classify=2&id_person=${id_person}`;

    if (idClassify.toString() === "1") {
      classifyLink = `/viewMeal?id_classify=1&id_class=${keyClass.value}&id_person=${id_person}`;
    }
    axios.post(classifyLink).then((res) => {
      if (res.data.status === true) {
        setcutMealDetail(res.data.data);
      } else {
      }
    });
  };
  const dataTable = (key) => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
        style: {
          div: {
            // background:'red',

            overflow: "inherit !important ",
            width: "200px",
          },
        },
      },
      {
        name: "Thời gian",
        selector: (row) => row.time,
        center: true,
      },

      {
        name: "SL duyệt/ chưa duyệt",
        selector: (row) => row.count,
        // sortable: true,
        center: true,
      },
      {
        name: "Chi tiết",
        selector: (row) => row.setting,
        // sortable: true,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (dataListSearch.length > 0) {
      dataListSearch.map((item, i) => {
        data.push({
          student_name: key == 1 ? item.student_name : item.user_name,
          count: (
            <>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {ListCut.length > 0
                  ? ListCut.filter(
                      (e) =>
                        e.student_name === item.student_name &&
                        e.is_status === 1 &&
                        getMonth_(e.meal_day).toString() === setMonth.toString()
                    ).length
                  : 0}
              </span>{" "}
              /{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {ListCut.length > 0
                  ? ListCut.filter(
                      (e) =>
                        e.student_name === item.student_name &&
                        e.is_status === 0 &&
                        getMonth_(e.meal_day).toString() === setMonth.toString()
                    ).length
                  : 0}
              </span>
            </>
          ),
          time: (
            <>
              <Button
                size="sm"
                disabled={
                  !new Check().permission(["73"]) ||
                  item.student_status == 2 ||
                  new Date() / 1000 < item.process?.start_at
                }
                onClick={() => {
                  getCutMealDetailData_(item.id);
                  setShow(true);
                  setIdcut(item.id);
                  setIsmael(item.is_meal);
                  setDateRange([new Date(), new Date()]);
                }}
              >
                <i className="fa-solid fa-calendar-days"> </i>{" "}
              </Button>
            </>
          ),
          count_student: item.count_student,

          setting: (
            <>
              <Button
                size="sm"
                title="Xem chi tiết"
                onClick={() => {
                  getCutMealDetailData(item.id);
                }}
              >
                Xem
              </Button>
            </>
          ),
        });

        return item;
      });
    }

    return (
      <>
        <DataTables data={data} columns={columns} selectedRows={selectedRows} />
      </>
    );
  };
  const renderDetailCutMealList = () => {
    return (
      <>
        <Modal
          size="xl"
          show={showCutMealDetail}
          onHide={() => {
            setshowCutMealDetail(false);
            // console.log((new Date()).getFullYear())
            setdataYear({
              value: new Date().getFullYear(),
              label: new Date().getFullYear(),
            });
            setdataDate({ value: 0, label: "Tất cả ngày" });
            setdataMonth({
              value: new Date().getMonth() + 1,
              label: "Tháng " + Number(new Date().getMonth() + 1),
            });
          }}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Chi tiết cắt suất {cutMealDetail[0]?.student_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {cutMealDetail.length > 0 ? (
              <>
                <Row
                  style={{ justifyContent: "flex-start", marginTop: "-10px" }}
                >
                  <Col md={2} style={{ zIndex: 4 }}>
                    <Select
                      options={selectDataDate}
                      defaultValue={selectDataDate[0]}
                      value={dataDate}
                      onChange={(choice) => {
                        onChangeDate(choice);
                      }}
                    ></Select>
                  </Col>
                  <Col md={2} style={{ zIndex: 3 }}>
                    <Select
                      options={selectDataMonth}
                      defaultValue={selectDataMonth[0]}
                      value={dataMonth}
                      onChange={(choice) => {
                        onChangeMonth(choice, choiceYear.value);
                      }}
                    ></Select>
                  </Col>
                  <Col md={2} style={{ zIndex: 2 }}>
                    <Select
                      options={selectDataYear}
                      defaultValue={selectDataYear[0]}
                      value={dataYear}
                      onChange={(choice) => {
                        onChangeYear(choice);
                      }}
                    ></Select>
                  </Col>
                </Row>
                {idClassify.toString() === "1" ? (
                  <Table responsive striped style={{ position: "relative" }}>
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ whiteSpace: "nowrap" }}>
                        <th style={{ width: "50px" }}>Ngày</th>
                        <th className="text-center" style={{ width: "300px" }}>
                          Loại
                        </th>
                        <th style={{ width: "140px" }}>Lí do</th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Ngày báo
                        </th>
                        <th className="text-center" style={{ width: "80px" }}>
                          Duyệt{" "}
                        </th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Người duyệt
                        </th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Ngày duyệt
                        </th>
                        <th className="text-center" style={{ width: "100px" }}>
                          Hủy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr style={{ height: "40px" }}></tr> */}
                      {cutMealDetailSearch.map((mealDetail) => (
                        <tr key={mealDetail.id}>
                          <td style={{ width: "50px" }} className="text-end">
                            {" "}
                            {getDate(mealDetail.meal_day)}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "300px" }}
                          >
                            {mealDetail.type_meal === 1
                              ? "Nguyên ngày"
                              : mealDetail.detail?.map(
                                  (item, index) =>
                                    item.time_name +
                                    (index + 1 === mealDetail.detail.length
                                      ? ""
                                      : ", ")
                                )}
                          </td>
                          <td style={{ width: "1400x" }}>
                            <span
                              style={{
                                width: "140px",
                                // whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "block",
                              }} 
                              title=  {mealDetail.note}
                            >
                              {mealDetail.note}
                            </span>
                          </td>
                          <td style={{ width: "150px" }}>
                            {getTime(mealDetail.created_at)}
                          </td>
                          <td className="text-center" style={{ width: "80px" }}>
                            {!mealDetail.is_term ? (
                              <p style={{ color: "red" }}>Báo trễ</p>
                            ) : mealDetail.is_status === 1 ? (
                              <i
                                className="fa-sharp fa-solid fa-check fa-lg"
                                title="Đã duyệt"
                                style={{ color: "#00c203" }}
                              ></i>
                            ) : null}
                          </td>
                          {/* {mealDetail.is_status ? !mealDetail.is_term ? "" : mealDetail.user_by : ""} */}
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            {" "}
                            {mealDetail.is_status
                              ? !mealDetail.is_term
                                ? ""
                                : mealDetail.user_by
                              : ""}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            {" "}
                            {mealDetail.is_status
                              ? !mealDetail.is_term
                                ? ""
                                : getTime(mealDetail.updated_at)
                              : ""}
                          </td>

                          <td className="text-center">
                            <Button
                              variant="danger"
                              size="sm"
                              title="Xóa cắt suất"
                              onClick={
                                () => delCut(mealDetail.id_meal, mealDetail.id)
                                // getCutMealDetailData(mealDetail.id)}
                              }
                              hidden={mealDetail.is_status}
                            >
                              {" "}
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                        // : null
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table responsive>
                    <thead>
                      <tr style={{ whiteSpace: "nowrap" }}>
                        <th style={{ width: "50px" }}>Ngày</th>
                        <th className="text-center" style={{ width: "300px" }}>
                          Loại
                        </th>
                        <th className="text-center" style={{ width: "140px" }}>
                          Lí do
                        </th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Ngày báo
                        </th>
                        <th className="text-center" style={{ width: "80px" }}>
                          Duyệt{" "}
                        </th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Người duyệt
                        </th>
                        <th className="text-center" style={{ width: "150px" }}>
                          Ngày duyệt
                        </th>
                        <th className="text-center" style={{ width: "100px" }}>
                          Hủy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: "40px" }}></tr>
                      {cutMealDetailSearch.map((mealDetail) => (
                        <tr key={mealDetail.id}>
                          <td style={{ width: "50px" }}>
                            {" "}
                            {getDate(mealDetail.meal_day)}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "300px" }}
                          >
                            {mealDetail.type_meal === 1
                              ? "Nguyên ngày"
                              : mealDetail.detail?.map(
                                  (item, index) =>
                                    item.time_name +
                                    (index + 1 === mealDetail.detail.length
                                      ? ""
                                      : ", ")
                                )}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "140px" }}
                          >
                            {" "}
                            {mealDetail.note}
                          </td>
                          <td style={{ width: "150px" }}>
                            {getTime(mealDetail.created_at)}
                          </td>

                          <td className="text-center" style={{ width: "80px" }}>
                            {!mealDetail.is_term ? (
                              "Báo trễ"
                            ) : mealDetail.is_status === 1 ? (
                              <i
                                className="fa-sharp fa-solid fa-check fa-lg"
                                title="Đã duyệt nguyen Van A - 14/4/2023 10:10:10"
                                style={{ color: "#00c203" }}
                              ></i>
                            ) : null}
                          </td>

                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            {" "}
                            {mealDetail.is_status ? mealDetail.user_by : ""}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            {" "}
                            {mealDetail.is_status
                              ? getTime(mealDetail.updated_at)
                              : ""}
                          </td>
                          <td className="text-center">
                            <Button
                              variant="danger"
                              size="sm"
                              title="Xóa cắt suất"
                              onClick={() =>
                                delCut(mealDetail.id_meal, mealDetail.id)
                              }
                              hidden={mealDetail.is_status}
                            >
                              {" "}
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            ) : (
              <>
                <p className="text-center">Không có dữ liệu</p>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  };
  const selectClassByGroup = (value) => {
    getClassRoom(value);
    setKeyClass({ value: 0, label: "Tất cả lớp" });
  };

  const selectClass = (value) => {
    setClass(value, startDatee, endDatee);
    setKeySearch("");
  };

  const handleSearch = (root_data, text) => {
    setKeySearch(text);
    let root_ = root_data;
    if (text !== "") {
      if (idClassify.toString() === "1") {
        root_ = root_data.filter((e) =>
          toSlug(e.student_name).includes(toSlug(text.trim()))
        );
      } else {
        root_ = root_data.filter((e) =>
          toSlug(e.user_name).includes(toSlug(text.trim()))
        );
      }
    }
    setDataListSearch(root_);
  };

  const handlCut = (idN) => {
    let since_day = startDate.setHours(0, 0, 0) / 1000;
    let to_day = endDate.setHours(23, 59, 59) / 1000;
    if (selectCut === 1) {
      checked = [];
    }
    let meal = [
      {
        since_day: since_day,
        to_day: to_day,
        time_meal: checked,
      },
    ];
    let linkApi = `/createMeal?id_classify=${idClassify}&type_meal=${selectCut}&note=${NoteCut}&id_person=${idN}&meal=${JSON.stringify(
      meal
    )}`;
    if (idClassify.toString() === "1") {
      linkApi = `/createMeal?id_classify=${idClassify}&type_meal=${selectCut}&note=${NoteCut}&id_class=${
        keyClass.value
      }&id_person=${idN}&meal=${JSON.stringify(meal)}`;
    }
    if (
      meal[0].to_day - meal[0].since_day < 86400 &&
      cutMealDetail.filter(
        (item) =>
          item.meal_day - meal[0].since_day < 86400 &&
          item.meal_day - meal[0].since_day > 0 &&
          item.is_status == 1
      ).length > 0
    ) {
      toast("Ngày đăng ký cắt đã duyệt", { type: "error", autoClose: 1000 });
    } else {
      if (selectCut === 0) {
        toast("Vui lòng chọn hình thức cắt", {
          type: "error",
          autoClose: 1000,
        });
      } else {
        if (NoteCut.trim() === "") {
          toast("Vui lòng nhập lý do cắt suất", {
            type: "error",
            autoClose: 1000,
          });
        } else {
          if (selectCut == 2 && checked.length == 0) {
            toast("Vui lòng nhập chọn buổi", {
              type: "error",
              autoClose: 1000,
            });
          } else {
            setshowloading(true);
            axios.post(linkApi).then((res) => {
              if (res.data.status === true) {
                getMealByClasses(idClassify === 1 ? keyClass.value : null);
                toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                setShow(false);
                setNoteCut("");
                setshowloading(false);
                setSelectCut(1);
                setselectoption({ label: "Nghỉ", value: 1 });
                setChecked([]);
              } else {
                toast(res.data.data.mess, { type: "error", autoClose: 1000 });
              }
            });
          }
        }
      }
    }
  };

  const getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; //update 20230915
  };
  const getMonth_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getMonth() + 1}`;
  };

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường

    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

    str = str.replace(/(\s+)/g, "-"); // Xóa khoảng trắng thay bằng ký tự -

    str = str.replace(/-+/g, "-"); // Xóa ký tự - liên tiếp

    str = str.replace(/^-+|-+$/g, ""); // xóa phần dư - ở đầu & cuối

    return str;
  };

  const loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const selectMonth_ = () => {
    let viewSelectMonth = [];
    for (let i = 1; i <= 12; i++) {
      viewSelectMonth.push({ value: i, label: "Tháng " + i });
    }
    setOptionMonth(viewSelectMonth);
  };
  const handleSaveIngredient = (id) => {
    swal({
      //   title: `Bạn muốn xuất kho cho bếp`,
      //   text: `Lưu ý: Hành động sẽ cập nhật lại nguyên liệu trong kho và không thể khôi phục lại.`,
      icon: "info",
      buttons: ["Đóng", "Lưu"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        // saveIngredientAmount();
        handlCut(id);
      }
    });
  };
  const classify_1 = () => {
    return (
      <>
        {checkloading ? (
          loading()
        ) : dataListSearch.length > 0 ? (
          dataTable(1)
        ) : (
          <p className="text-center">Không có dữ liệu</p>
        )}
      </>
    );
  };

  const classify_2 = () => {
    return (
      <>
        {dataListSearch.length > 0 ? (
          dataTable(2)
        ) : (
          // <Table striped size='sm'>
          // 	<thead>
          // 		<tr>
          // 			<th className="align-middle ">Họ và tên</th>
          // 			<th className="align-middle text-center">Thời gian</th>
          // 			<th className="align-middle text-center">

          // 				SL duyệt / chưa duyệt</th>
          // 			<th className="align-middle text-center">Chi tiết</th>
          // 		</tr>
          // 	</thead>
          // 	<tbody>
          // 		{dataListSearch && dataListSearch.map((item, index) => {
          // 			return (

          // 				<tr key={index}>
          // 					<td >{item.user_name} </td>
          // 					<td className="text-center">
          // 						<Button
          // 							size="sm"
          // 							onClick={() => {
          // 								getCutMealDetailData_(item.id)
          // 								setShow(true)
          // 								setIdcut(item.id)
          // 								setIsmael(item.is_meal)
          // 								setDateRange([new Date(), new Date()])
          // 							}
          // 							}>
          // 							<i className="fa-solid fa-calendar-days"> </i> {" "}</Button></td>
          // 					<td className="text-center">

          // 						<span style={{ color: 'red' }}>{(ListCut.length > 0 ? ListCut.filter((e) => e.id_user === item.id && e.is_status === 1 && getMonth_(e.meal_day).toString() === setMonth.toString()).length : 0)}</span>
          // 						{" "}	 / {" "}
          // 						<span style={{ color: 'green' }}>
          // 							{
          // 								(ListCut.length > 0 ? ListCut.filter((e) => e.id_user === item.id && e.is_status === 0 && getMonth_(e.meal_day).toString() === setMonth.toString()).length : 0)
          // 							}
          // 						</span>
          // 					</td>
          // 					<td className="text-center">
          // 						<Button size="sm" title="Xem chi tiết" onClick={() => { getCutMealDetailData(item.id) }} >Xem</Button>
          // 					</td>
          // 				</tr>
          // 			)
          // 		})
          // 		}
          // 	</tbody>
          // </Table>
          <p className="text-center">Không có dữ liệu</p>
        )}
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectCut(1);
    setselectoption({ label: "Nghỉ", value: 1 });
    setChecked([]);
  };

  const lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };

  const delCut = (id, id_user) => {
    swal({
      title: `Bạn chắc muốn xóa cắt xuất?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios.post(`/delMeal?id_meal=${id}`).then((res) => {
          if (res.data.status === true) {
            setcutMealDetailSearch(
              cutMealDetailSearch.filter((e) => e.id_meal !== id)
              // .sort(function (a, b) {
              // 	if (a.is_status <= b.is_status) return -1;
              // 	if (a.is_status > b.is_status) return 1;
              // 	return 0;
              // })
            );
            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
            // getCutMealDetailData(id_user)
          } else {
            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
          }
        });
      }
    });
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
        <Breadcrumb.Item active>Cắt suất ăn</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center ">
            <h5
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fas fa-list me-1 mt-1" />
              Cắt suất ăn tháng {startDatee.getMonth() + 1}/{" "}
              {startDatee.getFullYear()}
            </h5>
            {/* <DatePicker
							selected={startMonth}
							onChange={(date) => {
								// console.log(date)
								// set
								setEndDate(lastDayy(date.getFullYear(), date.getMonth() + 1))
								// console.log(lastDayy(date.getFullYear(),date.getMonth()+1))
								setClass(keyClass, new Date(date.setHours(0, 0, 0)), lastDayy(date.getFullYear(), date.getMonth() + 1))
								setStartDatee(new Date(date.setHours(0, 0, 0)))
								setStartMonth(date)
								setsetMonth(date.getMonth() + 1);
								// setSelectMonth(choice)
								// this.setState({ startMonth: date })
								// this.showDate(date)
							}}
							dateFormat="MM/yyyy"
							showMonthYearPicker
							locale="vi"
						// styles={this.customStyles}
						/> */}
            <input
              type="month"
              name="month"
              style={{
                border: "none",
                width: 39,
                backgroundColor: "rgb(16,108,252)",
                borderRadius: 5,
              }}
              className="me-2"
              onChange={(e) => {
                const [year, month] = e.target.value.split("-");
                setStartMonth(new Date(year, month - 1));
                setsetMonth(Number(new Date(year, month - 1).getMonth()) + 1);
                setStartDatee(new Date(year, month - 1));
                setEndDate(
                  lastDayy(
                    new Date(year, month - 1).getFullYear(),
                    Number(new Date(year, month - 1).getMonth()) + 1
                  )
                );
                setClass(
                  keyClass,
                  new Date(year, month - 1),
                  lastDayy(
                    new Date(year, month - 1).getFullYear(),
                    Number(new Date(year, month - 1).getMonth()) + 1
                  )
                );
                // this.setState({ startMonth: new Date(year, month - 1) })
                // this.showDate(new Date(year, month - 1))
              }}
            />
          </div>
        </Card.Header>
        <Card.Body>
          {checkloading ? (
            loading()
          ) : (
            <div className="row">
              {/* <div style={{ float: 'right' }} className="col-6 d-flex"> */}
              {idClassify.toString() === "1" ? (
                <div className="d-flex col-md-12 col-sm-12 px-0 mb-2">
                  {/* <Row>
										<Col md={6} style={{ marginBottom: "5px" }}> */}
                  <div
                    className="col-6 col-md-4 pe-1"
                    style={{ minWidth: "150px", zIndex: 3 }}
                  >
                    <Select
                      defaultValue={optionsClass[0]}
                      options={optionsClass.filter((e) => e.value > 0)}
                      placeholder="Chọn Lớp"
                      value={keyClass}
                      onChange={(choice) => {
                        selectClass(choice);
                      }}
                    />
                  </div>

                  <div
                    className="col-6 col-md-4 ps-1"
                    style={{ minWidth: "150px" }}
                  >
                    <Form.Control
                      style={{ height: 39 }}
                      type="text"
                      placeholder="Nhập tên học sinh"
                      value={keySearch}
                      onChange={(e) => {
                        handleSearch(dataListStudent, e.target.value);
                      }}
                    />
                  </div>
                  {/* </Col>
										<Col md={6}> */}

                  {/* </Col> */}
                  {/* </Row> */}
                </div>
              ) : (
                <Col md={4} className="px-0 mb-2">
                  <Form.Control
                    style={{ height: 39 }}
                    type="text"
                    placeholder="Nhập tên nhân viên"
                    onChange={(e) => {
                      handleSearch(dataListUser, e.target.value);
                    }}
                  />
                </Col>
              )}

              {/* </div> */}
              <Tabs
                defaultActiveKey={idClassify}
                onSelect={(e) => {
                  // setCheckloading(true)
                  setClassify(e);
                }}
                className="mb-3 col-md-12 col-sm-12"
              >
                {dataClassify &&
                  dataClassify.map((item, index) => {
                    // localStorage.getItem('role_rank')==4 ?
                    return (
                      <Tab
                        key={item.id}
                        title={item.classify_name}
                        eventKey={item.id}
                      >
                        {idClassify.toString() === "1"
                          ? classify_1()
                          : classify_2()}
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size={"md"}>
        <Modal.Header closeButton>
          <Modal.Title>Chọn thời gian cắt suất</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <DatePicker
              size={{ height: "600px" }}
              selected={startDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              startDate={startDate}
              endDate={endDate}
              minDate={!new Check().permission(["74"]) ? new Date() : 0}
              filterDate={checkSpicialDate}
              selectsRange
              selectsDisabledDaysInRange
              inline
            />
          </center>
          <br />
          {getOption()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setSelectCut(1);
              setselectoption({ label: "Nghỉ", value: 1 });
              setShow(false);
              setChecked([]);
            }}
          >
            Hủy
          </Button>
          <Button
            size="sm"
            disabled={startDate === null || endDate === null || showloading}
            onClick={() => {
              handlCut(idCut);
            }}
            variant="warning"
          >
            Cắt
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showloading}
        onHide={handleClose}
        size={"md"}
        style={{ marginTop: "200px" }}
      >
        <Modal.Body>{loading()}</Modal.Body>
      </Modal>
      {renderDetailCutMealList()}
    </Container>
  );
}
