import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Check from "../other/Check";

function Settingindex() {
  const [main, setmain] = useState([]);
  const [listday, setlistday] = useState([]);
  const [listtime, setlisttime] = useState([]);
  const [classify, setClassify] = useState([]);
  const [idClassify, setIdclassify] = useState(1);
  const [tabkey, settabkey] = useState(0);
  const [show, setShow] = useState(false);
  const [showmain, setShowmain] = useState(false);

  let hour_ = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  let minute_ = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

  useEffect(() => {
    document.title = "Cài đặt chung";
    getClassifyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let totalPrice = 0;
    listtime.map(item => {
      if (item.is_active === 1) {
        totalPrice += Number(item.poetry);
      }
      return item;
    })

    setmain(main.map(item => {
      return {
        ...item,
        price_meal: totalPrice,
      }
    }))

  }, [listtime, listday]); // eslint-disable-line react-hooks/exhaustive-deps

  const getClassifyData = () => {
    axios
      .get("/viewClassify")
      .then((res) => {
        if (res.data.status === true) {
          // setClassify(res.data.data);
          handleSelectClassify(res.data.data[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectClassify = (key) => {
    setmain([]);
    setlistday([]);
    setlisttime([]);
    setShow(false);
    setShowmain(false);

    setTimeout(function () {
      getlistsetting(key);
    }, 50);

    setIdclassify(key);
  }

  const getlistsetting = (classifyId) => {
    axios
      .get(`/viewSetting?id_classify=${classifyId}`)
      .then((res) => {
        if (res.data.status === true) {
          setmain(res.data.data.main);
          setlistday(res.data.data.day);
          setlisttime(res.data.data.time);
          setShow(res.data.data.day[0].is_poetry === 1 ? true : false);
          setShowmain(res.data.data.main[0].turn_latch === 1 ? true : false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatesetting = () => {
    swal({
      title: "Bạn muốn cập nhật cài đặt?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios.post(
          `/updateSetting?id_classify=${idClassify}&main=${JSON.stringify(main)}&day=${JSON.stringify(
            listday
          )}&time=${JSON.stringify(listtime)}`
        )
          .then((res) => {
            if (res.data.status === true) {
              toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
              getlistsetting(idClassify);
            } else {
              toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  const handleInputmain = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    main[0][name] = value;
  };

  const RenderMain = () => {

    if (Number(idClassify) === 2) {
      return ""
    }

    return (
      <>
        <Col xs={12} sm={12} md={10} className="mt-5">
          {main.map((item, i) => {
            return (
              <Row key={i}>
                <Col xs={12} md={7}>
                  <Card.Title className="mb-3 mb-md-3">Cài đặt thời gian chốt</Card.Title>
                  <div>
                    {main.map((item) => {
                      return (
                        <Form.Check
                          key={i}
                          type="switch"
                          defaultChecked={item.turn_latch === 1 ? true : false}
                          onChange={(e) => {
                            setShowmain(e.target.checked);
                            if (e.target.checked === true) {
                              item.turn_latch = 1;
                            } else {
                              item.turn_latch = 0;
                            }
                          }}
                          label="Kích hoạt"
                          className="mb-2"
                        />
                      );
                    })}
                  </div>

                  <Row hidden={!showmain}>
                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <Form.Select
                          onChange={handleInputmain}
                          name="time_hour"
                          defaultValue={item.time_hour}
                          required
                        >
                          {hour_.map(e => { return <option key={e} value={e}>{e}</option> })}
                        </Form.Select>
                        <label htmlFor="school_name">
                          Giờ
                        </label>
                      </Form.Floating>
                    </Col>

                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <Form.Select
                          onChange={handleInputmain}
                          name="time_minute"
                          defaultValue={item.time_minute}
                          required
                        >
                          {minute_.map(e => { return <option key={e} value={e}>{e}</option> })}
                        </Form.Select>
                        <label htmlFor="school_name">
                          Phút
                        </label>
                      </Form.Floating>
                    </Col>

                    <Col xs={6} md={6} className="mt-3 mt-md-0" style={{ display: "flex", alignItems: "center" }}>
                      <Form.Floating style={{ display: "flex" }}>

                        <Form.Check
                          type="checkbox"
                          style={{ fontSize: "16px", marginRight: "10px" }}
                          defaultChecked={item.turn_today === 1 ? true : false}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              item.turn_today = 1;
                            } else {
                              item.turn_today = 0;
                            }
                          }}

                        />
                        Chốt trong ngày
                      </Form.Floating>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <div>
                    <Card.Title className="me-3">Tổng thu của phụ huynh</Card.Title>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end mt-0 mt-md-5"
                        thousandSeparator={true}
                        required
                        suffix=" ₫"
                        value={main[0].price_proceed}
                        onValueChange={(values) => {
                          setmain(main.map(item => {
                            return {
                              ...item,
                              price_proceed: values.value
                            }
                          }))
                        }}
                        allowNegative={false}
                      />
                      <Form.Label>Giá (VND)</Form.Label>
                    </Form.Floating>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </>
    )
  }

  const RenderTimeSetting = () => {

    return (
      <>
        <div className="mt-5 mb-3">
          <Col xs={12} sm={12} md={10}>
            <Row>
              <Col xs={Number(idClassify) === 1 ? 4 : 12} md={3} className="align-self-end">
                <Card.Title>Cài đặt buổi</Card.Title>
              </Col>

              <Col xs={4} md={4} className="align-self-end">
                <div className="d-flex">
                  <Card.Title>
                    Số tiền chi thực phẩm:
                    <CurrencyFormat
                      className="ms-3 fw-normal"
                      thousandSeparator={true}
                      suffix=" ₫"
                      value={main[0].price_meal}
                      displayType="text"
                      allowNegative={false}
                    />
                  </Card.Title>
                </div>
              </Col>

              {
                Number(idClassify) === 1
                  ?
                  <Col xs={4} md={4}>
                    <Card.Title>
                      Số tiền trả nghỉ nguyên ngày:
                    </Card.Title>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end"
                        thousandSeparator={true}
                        suffix=" ₫"
                        value={main[0].price_totalreturn}
                        onValueChange={(values) => {
                          setmain(main.map(item => {
                            return {
                              ...item,
                              price_totalreturn: values.value
                            }
                          }))
                        }}
                        displayType="input"
                        allowNegative={false}
                      />
                      <Form.Label>Giá (VND)</Form.Label>
                    </Form.Floating>
                  </Col>
                  : ""
              }
            </Row>
          </Col>
        </div>

        <Col xs={12} sm={12} md={10}>
          {listtime
            .map((timeItem, i) => {
              return (
                <Row
                  key={i}
                  className="mb-2"
                >
                  <Col xs={Number(idClassify) === 1 ? 4 : 12} md={3}>
                    <div className="d-flex">
                      <div className="d-flex align-items-center me-3">
                        <Form.Check
                          type="checkbox"
                          defaultChecked={timeItem.is_active === 1 ? true : false}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setlisttime(listtime.map(item => {
                                if (item.id === timeItem.id) {
                                  return {
                                    ...item,
                                    is_active: 1
                                  };
                                }
                                return item;
                              }))
                            } else {
                              setlisttime(listtime.map(item => {
                                if (item.id === timeItem.id) {
                                  return {
                                    ...item,
                                    is_active: 0
                                  };
                                }
                                return item;
                              }))
                            }
                          }}
                        />
                      </div>

                      <Form.Floating>
                        <Form.Control
                          defaultValue={timeItem.time_name}
                          onChange={(e) => {
                            setlisttime(listtime.map(item => {
                              if (item.id === timeItem.id) {
                                return {
                                  ...item,
                                  time_name: e.target.value
                                };
                              }
                              return item;
                            }))
                          }}
                        />
                      </Form.Floating>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control text-end"
                        thousandSeparator={true}
                        required
                        suffix=" ₫"
                        value={timeItem.poetry}
                        onValueChange={(values) => {
                          const newListTime = listtime.map(item => {
                            if (item.id === timeItem.id) {
                              return {
                                ...item,
                                poetry: values.value
                              }
                            }
                            return item;
                          });

                          setlisttime(newListTime)
                        }}
                        allowNegative={false}
                      />
                      <Form.Label>Giá (VND)</Form.Label>
                    </Form.Floating>
                  </Col>

                  {
                    Number(idClassify) === 1
                      ?
                      <Col xs={4} md={4}>
                        <Form.Floating>
                          <CurrencyFormat
                            className="form-control text-end"
                            thousandSeparator={true}
                            required
                            suffix=" ₫"
                            value={timeItem.price_return}
                            onValueChange={(values) => {
                              const newListTime = listtime.map(item => {
                                if (item.id === timeItem.id) {
                                  return {
                                    ...item,
                                    price_return: values.value
                                  }
                                }
                                return item;
                              });

                              setlisttime(newListTime)
                            }}
                            allowNegative={false}
                          />
                          <Form.Label>Giá (VND)</Form.Label>
                        </Form.Floating>
                      </Col>
                      : ""
                  }
                </Row>
              );
            })}
        </Col>
      </>
    )
  }

  const RenderDaySetting = () => {
    return (
      <>
        <div className="mt-5 mb-3">
          <Card.Title>Cài đặt thứ</Card.Title>
        </div>
        <Tabs
          activeKey={tabkey}
          unmountOnExit={true}
          transition={false}
          onSelect={(k) => {
            settabkey(k);
            let d = listday[k];
            if (d.is_poetry === 1) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
          className="mb-3"
        >
          {listday.map((dayItem, i) => {
            return (
              <Tab key={i} title={dayItem.day_name} eventKey={i}>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={dayItem.is_active === 1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setlistday(listday.map(item => {
                          if (item.id === dayItem.id) {
                            return {
                              ...item,
                              is_active: 1
                            };
                          }
                          return item;
                        }))
                      } else {
                        setlistday(listday.map(item => {
                          if (item.id === dayItem.id) {
                            return {
                              ...item,
                              is_active: 0
                            };
                          }
                          return item;
                        }))
                      }
                    }}
                    label="Kích hoạt"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={dayItem.is_poetry === 1 ? true : false}
                    onChange={(e) => {
                      setShow(e.target.checked);
                      if (e.target.checked === true) {
                        setlistday(listday.map(item => {
                          if (item.id === dayItem.id) {
                            return {
                              ...item,
                              is_poetry: 1
                            };
                          }
                          return item;
                        }))
                      } else {
                        setlistday(listday.map(item => {
                          if (item.id === dayItem.id) {
                            return {
                              ...item,
                              is_poetry: 0
                            };
                          }
                          return item;
                        }))
                      }
                    }}
                    label="Phụ thu thêm"
                  />
                </Form.Group>

                <Col md={3}>
                  <Form.Floating className="mb-3" hidden={!show}>
                    <CurrencyFormat
                      className="form-control text-end"
                      thousandSeparator={true}
                      placeholder="Phụ thu"
                      required
                      suffix=" ₫"
                      value={dayItem.poetry}
                      onValueChange={(values) => {
                        const newDayTime = listday.map(item => {
                          if (item.id === dayItem.id) {
                            return {
                              ...item,
                              poetry: values.value
                            }
                          }

                          return item;
                        });

                        setlistday(newDayTime);
                      }}
                      allowNegative={false}
                    />
                    <Form.Label>Giá (VND)</Form.Label>
                  </Form.Floating>
                </Col>
              </Tab>
            );
          })}
        </Tabs>
      </>
    )
  }

  return (
    <Container fluid className="px-3">
      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
        <Breadcrumb.Item active>Cài đặt chung</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-gears"></i> Cài đặt

            {new Check().permission(['60']) ?
              <Button size="sm" variant="success" onClick={() => updatesetting()} style={{ float: "right" }}>
                <i className="fas fa-pencil"></i> Cập nhật
              </Button> : null}
          </Card.Title>
        </Card.Header>

        {main.length !== 0 && listday.length !== 0 && listtime !== 0
          ?
          <>
            <Card.Body>
              {/* Ẩn Nhân viên */}
              {/* <Tabs
                defaultActiveKey={idClassify}
                onSelect={(key) => {

                  if (key === idClassify) {
                    return;
                  }

                  handleSelectClassify(key);
                }}
                unmountOnExit={true}
                transition={false}
              > 
               {classify &&
                  classify.map((item) => {
                    return (
                      <Tab
                        key={item.id}
                        title={item.classify_name}
                        eventKey={item.id}
                      >
                        {RenderMain()}

                        {RenderTimeSetting()}

                        {RenderDaySetting()}
                      </Tab>
                    );
                  })} 
              </Tabs> */}

              {RenderMain()}

              {RenderTimeSetting()}

              {RenderDaySetting()}
            </Card.Body>
          </>

          :

          <Card.Body className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
              <span className="sr-only">Loading...</span>
            </div>
          </Card.Body>
        }
      </Card>
    </Container >
  );
}

export default Settingindex;
