import React, { Component } from "react";
import { Breadcrumb, Container,  Tab, Tabs } from "react-bootstrap";
import NutritionPortionDayReport from "./NutritionPortionDayReport";
import NutritionPortionWeekReport from "./NutritionPortionWeekReport";


class NutritionPortionReport extends Component {
    constructor(state) {
		super(state);
		this.state = {
		};
	}

	async componentDidMount() {
		document.title = 'Dưỡng chất';
	}

    render() {        

        return (
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
                    <Breadcrumb.Item active>Dưỡng chất</Breadcrumb.Item>
                </Breadcrumb>

                <Tabs defaultActiveKey={1} className="mb-3">
                    <Tab eventKey={1} title="Dưỡng chất 1 trẻ">
                        <NutritionPortionDayReport />
                    </Tab>
                    <Tab eventKey={2} title="Dinh dưỡng tuần">
                        <NutritionPortionWeekReport />
                    </Tab>
                </Tabs>
            </Container>
        );
    }
}

export default NutritionPortionReport;