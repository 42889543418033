import React, { Component } from "react"; 
import Navbar from "../../../layouts/frontend/Navbar";
import { useNavigate, useParams  } from "react-router-dom";

import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateSchool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataInput: {
                school_name: '',
                school_email: '',
                school_phone: '',
                school_password: '',
                school_address: '',
                school_author: '',
                school_identifier: '',
                // school_logo: '',
                school_db: '',
                school_confirm: '',
            },
        };
    }

    async componentWillMount() {
        document.title = 'Thêm trường học';
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = {...this.state.dataInput};
        dataInput[field] = value;
        this.setState({dataInput: dataInput});
    }

    registerSubmit = (e) =>  {
        e.preventDefault();

        const school_name = this.state.dataInput.school_name;
        const school_author = this.state.dataInput.school_author;
        const school_phone = this.state.dataInput.school_phone;
        const school_email = this.state.dataInput.school_email;
        const school_address = this.state.dataInput.school_address;
        const school_password = this.state.dataInput.school_password;
        const school_identifier = this.state.dataInput.school_identifier;
        // const school_db = this.state.dataInput.school_db;

        if(school_identifier.length > 35){
            toast("Mã định danh không được nhiều hơn 35 kí tự", {
                type: "error",
                autoClose: 2000,
            });

            return;
        }

        if(school_phone.length > 11){
            toast("Sai định dạng điện thoại", {
                type: "error",
                autoClose: 1000,
            });

            return;
        } 

        // const school_logo = this.state.dataInput.school_logo;
        const school_confirm = this.state.dataInput.school_confirm;

        // &school_logo=${school_logo}
        axios.post(`/createdSchool?school_name=${school_name}&school_identifier=${school_identifier}&school_email=${school_email}&school_phone=${school_phone}&school_password=${school_password}&school_address=${school_address}&school_author=${school_author}&school_confirm=${school_confirm}
        `).then(res => {
            if(res.data.status === true){
                this.props.navHook('/admin/user');  
                toast(res.data.data.mess, { type: "success" , autoClose: 1000});
            }else{
                toast(res.data.data.mess, { type: "error", autoClose: 1000 });
            }
        });
    }

    render () {
        return (
            <div>
                <Navbar />
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5><i className="fas fa-plus"></i> THÊM TRƯỜNG HỌC</h5>
                                </div>

                                <form onSubmit={this.registerSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            {/* <div className="col-md-12">
                                                <div className="form-floating mb-3">
                                                    <input type="text" id="school_db" name="school_db" onChange={this.handleInput} value={this.state.dataInput.school_db} className="form-control" placeholder="Cơ sở dữ liệu" />
                                                    <label htmlFor="school_db">Cơ sở dữ liệu</label>
                                                </div>
                                            </div> */}

                                            <div className="col-md-12">
                                                <div className="form-floating mb-3">
                                                    <input type="text" id="school_name" name="school_name" onChange={this.handleInput} value={this.state.dataInput.school_name} className="form-control" placeholder="Tên trường học" required />
                                                    <label htmlFor="school_name">Tên trường học<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                    <input type="text" id="school_address" name="school_address" onChange={this.handleInput} value={this.state.dataInput.school_address} className="form-control" placeholder="Địa chỉ"  />
                                                    <label htmlFor="school_address">Địa chỉ</label>
                                                    
                                                </div>
                                            </div>
                                        
                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" id="school_author" name="school_author" onChange={this.handleInput} value={this.state.dataInput.school_author} className="form-control" placeholder="Đại diện" required />
                                                    <label htmlFor="school_author">Đại diện<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" id="school_identifier" name="school_identifier" onChange={this.handleInput} value={this.state.dataInput.school_identifier} className="form-control" placeholder="Đại diện" required />
                                                    <label htmlFor="school_identifier">Mã định danh Bộ GD&ĐT<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="email" id="school_email" name="school_email" onChange={this.handleInput} value={this.state.dataInput.school_email} className="form-control" placeholder="Email" required />
                                                    <label htmlFor="school_email">Email<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" id="school_phone" name="school_phone" onChange={this.handleInput} value={this.state.dataInput.school_phone} className="form-control" placeholder="Đại diện" required />
                                                    <label htmlFor="school_phone">Số điện thoại<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="password" id="school_password" name="school_password" onChange={this.handleInput} value={this.state.dataInput.school_password} className="form-control" placeholder="Mật khẩu" required />
                                                    <label htmlFor="school_password">Mật khẩu<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="password" id="school_confirm" name="school_confirm" onChange={this.handleInput} value={this.state.dataInput.school_confirm} className="form-control" placeholder="Mã xác nhận"  />
                                                    <label htmlFor="school_confirm">Mã xác nhận<i className="text-danger">*</i></label>
                                                </div>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <div className="input-group mb-3">
                                                    <label className="input-group-text" htmlFor="school_logo">Logo</label>
                                                    <input type="file" id="school_logo" name="school_logo" onChange={this.handleInput} value={this.state.dataInput.school_logo} className="form-control" placeholder="Logo" />
                                                </div>
                                            </div> */}
                                        </div>    
                                    </div>

                                    <div className="card-footer">
                                        <button size="sm" type="submit" className="btn btn-success">
                                            <i className="fas fa-check"></i> Thêm
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


function myParams(Component) { //Back link AND use "params"
    return props => <Component  navHook={useNavigate()}  {...props} params={useParams()} />;
}

export default  myParams(CreateSchool);