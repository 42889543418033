import React, { Component } from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {Modal, Button, Form} from "react-bootstrap";

class CategoryAdd extends Component {
    constructor(props){
        super(props);

        this.state = {
            dataInput: {
                id: '',
                category_name: '',
            }, 
        };
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = {...this.state.dataInput};
        dataInput[field] = value;
        this.setState({dataInput: dataInput});
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        let category_name = this.state.dataInput.category_name;
        axios.post(`/createCategory?category_name=${category_name}`)
        .then(res => {
            if(res.data.status === true){
                toast(res.data.data.mess, { type: "success" });
                const btnCloseAdd = document.getElementById('btnCloseAddCategory');
                btnCloseAdd.click();
            }else{
                toast(res.data.data.mess, { type: "error" });
            }
        });
    }

    handleHide = () => {
        return  this.props.onHide();
    }

    render(){
        return (<>
            <Modal 
                {...this.props} 
                size="sm" 
                backdrop="static"
                keyboard={false} 
                id="addCategoryModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <i className="fa-solid fa-plus"></i> Thêm danh mục
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={this.handleInput} name="category_name" id="category_name" placeholder="Tên danh mục" required />
                            <Form.Label>Tên danh mục<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddCategory" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit" >
                            <i className="fa-solid fa-check"></i> Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            </>
        );
    }
}

export default CategoryAdd;