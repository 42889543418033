import React from "react";
import { Table } from "react-bootstrap";
import {
  priceFormatter,
  findeQuantitativeZone,
  isInRange,
} from "../../../utils/helpers";

const qualified = { color: "green" };
const unqualified = { color: "red" };

const NutritionTable = ({
  quantitative,
  selectedClassGroup,
  isChangeMarket,
}) => {
  const rangeQuantity = findeQuantitativeZone(selectedClassGroup.id_age_group);

  if (!quantitative) {
    return "";
  }

  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const enegryRatio = Number(currentCostPerDays?.enegry_ratio);
  const recommendFrom = Number(currentCostPerDays?.recommended_from);
  const recommendTo = Number(currentCostPerDays?.recommended_to);

  // Nutrition variables
  let energyStat = 0;
  let proteinStat = 0;
  let lipidStat = 0;
  let glucidStat = 0;

  let animalProteinStat = 0;
  let animalLipidStat = 0;

  quantitative.times?.map((timeItem) => {
    return timeItem.foods.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          const ingredientQuantity = isChangeMarket
            ? ingredientItem.tmp_ing_quantity
            : ingredientItem.ing_quantity;

          const gramOneChild = Number(
            ((ingredientQuantity * ingredientItem.volume_market) /
              ingredientItem.volume_cook) *
              ((100 - ingredientItem.ingredent_ratio) / 100)
          );

          if (ingredientItem.id_subspecies === 1) {
            animalProteinStat +=
              (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
            animalLipidStat +=
              (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
          }

          energyStat +=
            (gramOneChild * Number(ingredientItem.ingredent_energy)) / 100;
          proteinStat +=
            (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
          lipidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
          glucidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_glucid)) / 100;

          return ingredientItem;
        });

      return foodItem;
    });
  });

  const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;

  const energyPersen = (energyStat * 100 * 100) / (recommendFrom * enegryRatio);
  const recommendEnergy = Math.round(
    Number((recommendFrom * enegryRatio) / 100)
  );

  return (
    <div>
      <div style={{ backgroundColor: "#FCAC0D" }} className="p-2">
        <div>
          Năng lượng {currentCostPerDays?.group_name}: Cả ngày{" "}
          <div className="d-inline fw-bold">
            {recommendFrom} - {recommendTo} kcal
          </div>
        </div>
        <div>
          Định mức 100% trong trường là{" "}
          <div className="d-inline fw-bold">{recommendEnergy} kcal</div> (chiếm{" "}
          {enegryRatio}% cả ngày).
        </div>
      </div>
      <Table bordered responsive size="sm" className="mt-2 mb-1">
        <thead>
          <tr className="align-middle text-center">
            <th></th>
            <th>Tỉ lệ đạt(%)</th>
            <th>Năng lượng(Kcal)</th>
            <th>Đạm(g)</th>
            <th>Béo(g)</th>
            <th>Đường bột(g)</th>
          </tr>
        </thead>
        <tbody className="align-middle text-center">
          <tr>
            <td title="Định lượng">ĐL</td>
            <td style={energyPersen >= 100 ? qualified : unqualified}>
              {priceFormatter.format(energyPersen)}
            </td>
            <td>{priceFormatter.format(energyStat)}</td>
            <td>{priceFormatter.format(proteinStat)}</td>
            <td>{priceFormatter.format(lipidStat)}</td>
            <td>{priceFormatter.format(glucidStat)}</td>
          </tr>

          <tr>
            <td>P:L:G</td>
            <td></td>
            <td></td>
            <td
              style={
                plgTotal !== 0
                  ? isInRange(
                      (proteinStat * 100 * 4) / plgTotal,
                      "PROTEIN",
                      rangeQuantity
                    )
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {plgTotal !== 0
                ? priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
                : ""}
            </td>
            <td
              style={
                plgTotal !== 0
                  ? isInRange(
                      (lipidStat * 100 * 9) / plgTotal,
                      "LIPIT",
                      rangeQuantity
                    )
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {plgTotal !== 0
                ? priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
                : ""}
            </td>
            <td
              style={
                plgTotal !== 0
                  ? isInRange(
                      100 -
                        (Number(
                          priceFormatter.format(
                            (proteinStat * 100 * 4) / plgTotal
                          )
                        ) +
                          Number(
                            priceFormatter.format(
                              (lipidStat * 100 * 9) / plgTotal
                            )
                          )),
                      "GLUCID",
                      rangeQuantity
                    )
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {plgTotal !== 0
                ? priceFormatter.format(
                    100 -
                      (Number(
                        priceFormatter.format(
                          (proteinStat * 100 * 4) / plgTotal
                        )
                      ) +
                        Number(
                          priceFormatter.format(
                            (lipidStat * 100 * 9) / plgTotal
                          )
                        ))
                  )
                : ""}
            </td>
          </tr>

          <tr>
            <td title="Động vật">ĐV</td>
            <td></td>
            <td></td>
            <td
              style={
                proteinStat !== 0
                  ? priceFormatter.format(
                      (animalProteinStat * 100) / proteinStat
                    ) > 58 ||
                    priceFormatter.format(
                      (animalProteinStat * 100) / proteinStat
                    ) < 42
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {proteinStat !== 0
                ? priceFormatter.format((animalProteinStat * 100) / proteinStat)
                : ""}
            </td>
            <td
              style={
                lipidStat !== 0
                  ? priceFormatter.format((animalLipidStat * 100) / lipidStat) >
                      58 ||
                    priceFormatter.format((animalLipidStat * 100) / lipidStat) <
                      42
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {lipidStat !== 0
                ? priceFormatter.format((animalLipidStat * 100) / lipidStat)
                : ""}
            </td>
            <td></td>
          </tr>

          <tr>
            <td title="Thực vật">TV</td>
            <td></td>
            <td></td>
            <td
              style={
                proteinStat !== 0
                  ? priceFormatter.format(
                      (animalProteinStat * 100) / proteinStat
                    ) > 56 ||
                    priceFormatter.format(
                      (animalProteinStat * 100) / proteinStat
                    ) < 44
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {proteinStat !== 0
                ? priceFormatter.format(
                    100 - (animalProteinStat * 100) / proteinStat
                  )
                : ""}
            </td>
            <td
              style={
                lipidStat !== 0
                  ? priceFormatter.format((animalLipidStat * 100) / lipidStat) >
                      56 ||
                    priceFormatter.format((animalLipidStat * 100) / lipidStat) <
                      44
                    ? unqualified
                    : qualified
                  : unqualified
              }
            >
              {lipidStat !== 0
                ? priceFormatter.format(
                    100 - (animalLipidStat * 100) / lipidStat
                  )
                : ""}
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default NutritionTable;
