import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";

class AddParentUnit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parentUnit:
                this.props.unitData ||
                {
                    id: "",
                    unit_name: "",
                    parent_unit: 0,
                    // redemption_value: 0,
                },
        };
    }

    async componentDidMount() {
    }

    handleSubmit = () => {
        let id = this.state.parentUnit.id;
        let unitName = this.state.parentUnit.unit_name;
        let parent_unit = this.state.parentUnit.parent_unit;
        // let redemption_value = this.state.parentUnit.redemption_value;

        if(id === ''){
            // axios.post(`/createUnit?unit_name=${unitName}&parent_unit=${parent_unit}&redemption_value=${redemption_value}`)
            axios.post(`/createUnit?unit_name=${unitName}&parent_unit=${parent_unit}`)
            .then(res => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                    this.props.onSubmit();
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                }
            });
        }else{
            // axios.post(`/updateUnit?id_unit=${id}&unit_name=${unitName}&parent_unit=${parent_unit}&redemption_value=${redemption_value}`)
            axios.post(`/updateUnit?id_unit=${id}&unit_name=${unitName}&parent_unit=${parent_unit}`)
            .then(res => {
                if (res.data.status === true) {
                    toast(res.data.data.mess, { type: "success", autoClose: 1000 });
                    this.props.onSubmit();
                } else {
                    toast(res.data.data.mess, { type: "error", autoClose: 1000 });
                }
            });
        } 
    }

    render() {
        const {onHide} = {...this.props};

        return (
            <Modal show={this.props.show} onHide={onHide} size="sm" backdrop="static" keyboard={false} id="addUnitModal" >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <i className="fa-solid fa-weight-scale"></i> {this.state.parentUnit.id !== "" ? "Cập nhật đơn vị" : "Thêm đơn vị"}
                    </Modal.Title>
                </Modal.Header>

                <Card className="text-center">
                    <Card.Body>
                        <Form.Floating className="mt-2">
                            <Form.Control 
                                type="text"  
                                name="unit_name" 
                                value={this.state.parentUnit.unit_name} 
                                onChange={(e) => {
                                    let newUnit = this.state.parentUnit;
                                    newUnit.unit_name = e.target.value;

                                    this.setState({
                                        parentUnit: newUnit
                                    })
                                }}
                                placeholder="Đơn vị định lượng" 
                                required />
                            <Form.Label>Đơn vị tính<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>
                    </Card.Body>
                </Card>

                <Modal.Footer>
                    <Button size="sm" variant="secondary" id="btnCloseAddUnit" onClick={onHide}>
                        <i className="fa-solid fa-times"></i> Đóng
                    </Button>

                    <Button size="sm" variant="success" type="submit" onClick={() => this.handleSubmit()}>
                            <i className="fa-solid fa-check"></i> Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddParentUnit;