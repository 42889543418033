import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import swal from "sweetalert";

const CreateSetting = () => {
    const [main, setmain] = useState([]);
    const [listday, setlistday] = useState([]);
    const [listtime, setlisttime] = useState([]);
    const [idClassify, setIdclassify] = useState(1);
    // const [show, setShow] = useState(false);
    // const [showmain, setShowmain] = useState(false);

    const [settings, setSettings] = useState({
        fee_meal: 0,
        fee_other: 0,
        turn_latch: 0,
        turn_today: 1,
        time_latch: '',
        price_meal: 0,
        price_return: 0,
        setting_date_start: '',
        setting_date_end: ''
    });

    // const [days, setDays] = useState([]);
    const [times, setTimes] = useState([]);

    let hour_ = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    let minute_ = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    useEffect(async () => {
        document.title = "Cài đặt chung";
        await getDays();
        await getTimes();
    }, []);

    const getTimes = async () => {
        await axios.get("/getTimes")
            .then((res) => {
                if (res.data.errCode === 0) {
                    setTimes(res.data.times);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getDays = async () => {
        await axios.get("/getDays")
            .then((res) => {
                if (res.data.errCode === 0) {
                    getDays(res.data.times);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const handleSelectClassify = (key) => {
    //     setmain([]);
    //     setlistday([]);
    //     setlisttime([]);
    //     setShow(false);
    //     setShowmain(false);

    //     setTimeout(function () {
    //         getlistsetting(key);
    //     }, 50);

    //     setIdclassify(key);
    // }

    const getlistsetting = (classifyId) => {
        axios
            .get(`/viewSetting?id_classify=${classifyId}`)
            .then((res) => {
                if (res.data.status === true) {
                    setmain(res.data.data.main);
                    setlistday(res.data.data.day);
                    setlisttime(res.data.data.time);
                    // setShow(res.data.data.day[0].is_poetry === 1 ? true : false);
                    // setShowmain(res.data.data.main[0].turn_latch === 1 ? true : false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updatesetting = () => {
        swal({
            title: "Bạn muốn cập nhật cài đặt?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(
                    `/updateSetting?id_classify=${idClassify}&main=${JSON.stringify(main)}&day=${JSON.stringify(
                        listday
                    )}&time=${JSON.stringify(listtime)}`
                )
                    .then((res) => {
                        if (res.data.status === true) {
                            toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000 });
                            getlistsetting(idClassify);
                        } else {
                            toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    }

    // const handleInputmain = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     main[0][name] = value;
    // };



    const RenderMain = () => {
        return <>
            <Row className="mb-3">
                <Card.Title>Thời gian chốt cắt suất</Card.Title>

                <Col xs={4} md={2}>
                    <Form.Check
                        type="switch"
                        // defaultChecked={item.turn_latch === 1 ? true : false}
                        // onChange={(e) => {
                        //     setShowmain(e.target.checked);
                        //     if (e.target.checked === true) {
                        //         item.turn_latch = 1;
                        //     } else {
                        //         item.turn_latch = 0;
                        //     }
                        // }}
                        label="Kích hoạt"
                        className="mb-2"
                    />

                    <Form.Check
                        type="switch"
                        // defaultChecked={item.turn_latch === 1 ? true : false}
                        // onChange={(e) => {
                        //     setShowmain(e.target.checked);
                        //     if (e.target.checked === true) {
                        //         item.turn_latch = 1;
                        //     } else {
                        //         item.turn_latch = 0;
                        //     }
                        // }}
                        label="Chốt trong ngày"
                        className="mb-2"
                    />
                </Col>

                <Col xs={4} md={2}>
                    <Form.Floating>
                        <Form.Select
                            // onChange={handleInputmain}
                            name="time_hour"
                            // defaultValue={item.time_hour}
                            required
                        >
                            {hour_.map(e => { return <option key={e} value={e}>{e}</option> })}
                        </Form.Select>
                        <label htmlFor="school_name">
                            Giờ
                        </label>
                    </Form.Floating>
                </Col>

                <Col xs={6} md={2}>
                    <Form.Floating>
                        <Form.Select
                            // onChange={handleInputmain}
                            name="time_minute"
                            // defaultValue={item.time_minute}
                            required
                        >
                            {minute_.map(e => { return <option key={e} value={e}>{e}</option> })}
                        </Form.Select>
                        <label htmlFor="school_name">
                            Phút
                        </label>
                    </Form.Floating>
                </Col>

                <Col xs={6} md={3}>
                    <Form.Floating>
                        <Form.Control
                            name="time_minute"
                            type="date"
                            required
                        />
                        <label htmlFor="school_name">
                            Ngày hiệu lực
                        </label>
                    </Form.Floating>
                </Col>

                <Col xs={6} md={3}>
                    <Form.Floating>
                        <Form.Control
                            name="time_minute"
                            type="date"
                            required
                        />
                        <label htmlFor="school_name">
                            Ngày kết thúc
                        </label>
                    </Form.Floating>
                </Col>
            </Row>

            <Row className="mb-3">
                <Card.Title>Thu - chi trong ngày</Card.Title>
                <Col >
                    <Form.Floating>
                        <CurrencyFormat
                            className="form-control text-end"
                            thousandSeparator={true}
                            suffix=" ₫"
                            // value={main[0].price_totalreturn}
                            // onValueChange={(values) => {
                            //     setmain(main.map(item => {
                            //         return {
                            //             ...item,
                            //             price_totalreturn: values.value
                            //         }
                            //     }))
                            // }}
                            displayType="input"
                            allowNegative={false}
                        />
                        <Form.Label>Tiền thu phụ huynh <span className="text-danger">*</span></Form.Label>
                    </Form.Floating>
                </Col>

                <Col >
                    <Form.Floating>
                        <CurrencyFormat
                            className="form-control text-end"
                            thousandSeparator={true}
                            suffix=" ₫"
                            // value={main[0].price_totalreturn}
                            // onValueChange={(values) => {
                            //     setmain(main.map(item => {
                            //         return {
                            //             ...item,
                            //             price_totalreturn: values.value
                            //         }
                            //     }))
                            // }}
                            displayType="input"
                            allowNegative={false}
                        />
                        <Form.Label>Tiền hoàn trả nghỉ học <span className="text-danger">*</span></Form.Label>
                    </Form.Floating>
                </Col>

                <Col >
                    <Form.Floating>
                        <CurrencyFormat
                            className="form-control text-end"
                            thousandSeparator={true}
                            suffix=" ₫"
                            // value={main[0].price_totalreturn}
                            // onValueChange={(values) => {
                            //     setmain(main.map(item => {
                            //         return {
                            //             ...item,
                            //             price_totalreturn: values.value
                            //         }
                            //     }))
                            // }}
                            displayType="input"
                            allowNegative={false}
                        />
                        <Form.Label>Tiền chi thực phẩm <span className="text-danger">*</span></Form.Label>
                    </Form.Floating>
                </Col>

                <Col >
                    <Form.Floating>
                        <CurrencyFormat
                            className="form-control text-end"
                            thousandSeparator={true}
                            suffix=" ₫"
                            // value={main[0].price_totalreturn}
                            // onValueChange={(values) => {
                            //     setmain(main.map(item => {
                            //         return {
                            //             ...item,
                            //             price_totalreturn: values.value
                            //         }
                            //     }))
                            // }}
                            displayType="input"
                            allowNegative={false}
                        />
                        <Form.Label>Chi phí khác <span className="text-danger">*</span></Form.Label>
                    </Form.Floating>
                </Col>


            </Row >


            <Row className="mb-3">
                <Card.Title>
                    Buổi ăn
                </Card.Title>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup >
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control defaultValue={"Sáng"} />
                    </InputGroup>
                </Col>
            </Row>

            <Row>
                <Card.Title>Ngày học</Card.Title>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 2"}
                    />
                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 3"}
                    />

                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 4"}
                    />

                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 5"}
                    />

                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 6"}
                    />
                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label={"Thứ 7"}
                    />

                </Col>
                <Col >
                    <Form.Check
                        style={{ marginLeft: '.75rem' }}
                        type="checkbox"
                        label="Chủ nhật"
                    />
                </Col>
            </Row >
        </>
    }

    return (
        <Container fluid className="px-3">
            <Breadcrumb className="mb-2 mt-2">
                <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
                <Breadcrumb.Item active>Thêm cài đặt chung</Breadcrumb.Item>
            </Breadcrumb>

            <Card className="mb-4">
                <Card.Header>
                    <Card.Title>
                        <i className="fa-solid fa-gears"></i> Cài đặt
                        <Button size="sm" variant="success" style={{ float: "right" }}>
                            <i className="fas fa-check"></i> Lưu
                        </Button>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {RenderMain()}
                </Card.Body>

            </Card>
        </Container >
    );
}

export default CreateSetting;
