import React from "react";
import { Link, NavLink } from "react-router-dom";
import Check from "../../components/admin/other/Check";

const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion" >
      <div className="sb-sidenav-menu">
        <div className="nav">
          {/* {new Check().permission(['52', '72'],'or')
            ?
            <NavLink className="nav-link" to="/admin/school-management">
              <div className="sb-nav-link-icon">
                <i className="fa-solid fa-school"></i>
              </div>
              Quản lý trường học
            </NavLink>
            : null
          } */}

          {new Check().permission(['52', '72'], 'or')
            ?
            <NavLink className="nav-link" to="/admin/dashboard">
              <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
              Tổng quan
            </NavLink>
            : null
          }

          {new Check().permission(['72'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseMeal" aria-expanded="false" aria-controls="pagesCollapseMeal" >
                <div className="sb-nav-link-icon"><i className="fa-solid fa-utensils"></i></div>
                Suất ăn
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
              </Link>
              <div className="collapse" id="pagesCollapseMeal" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav">

                  {new Check().permission(['72']) ?
                    <NavLink className="nav-link" to="/admin/meal">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-list-check"></i>
                      </div>
                      Danh sách cắt suất ăn
                    </NavLink>
                    : null}

                  {new Check().permission(['72']) ?
                    <NavLink className="nav-link" to="/admin/meal-cut">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-scissors"></i>
                      </div>
                      Cắt suất ăn
                    </NavLink>
                    : null}
                  {new Check().permission(['72']) ?
                    <NavLink className="nav-link" to="/admin/reportAtt">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-list"></i>
                      </div>
                      Thống kê sỉ số
                    </NavLink>
                    : null}
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['52', '57'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseMenu" aria-expanded="false" aria-controls="pagesCollapseMenu" >
                <div className="sb-nav-link-icon">
                  <i className="fa-solid fa-file-lines"></i>
                </div>
                Thực đơn
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
              </Link>
              <div className="collapse" id="pagesCollapseMenu" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['52']) ?
                    <NavLink className="nav-link" to="/admin/menu">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-file-lines"></i>
                      </div>
                      Thực đơn
                    </NavLink>
                    : null}

                  {
                    new Check().permission(['57']) ?
                      <>
                        <NavLink
                          className="nav-link"
                          to="/admin/portion-calculate"
                        >
                          <div className="sb-nav-link-icon">
                            <i className="fa-solid fa-calculator"></i>
                          </div>
                          Tính khẩu phần ăn
                        </NavLink>

                        <NavLink className="nav-link"
                          to="/admin/nutrition-report"
                        >
                          <div className="sb-nav-link-icon">  <i className="fa-solid fa-file-lines"></i> </div>
                          Năng lượng dinh dưỡng
                        </NavLink>
                      </>
                      : null
                  }

                  {new Check().permission(['57']) ?
                    <NavLink className="nav-link" to="/admin/market">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-bag-shopping"></i>
                      </div>
                      Danh sách đi chợ
                    </NavLink>
                    : null}

                  {new Check().permission(['57']) ?
                    <NavLink className="nav-link" to="/admin/marketkitchen">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-utensils"></i>
                      </div>
                      Định lượng bếp
                    </NavLink>
                    : null}
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['40', '44', '46', '47', '50'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseStore" aria-expanded="false" aria-controls="pagesCollapseStore" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-store"></i> </div>
                Kho hàng
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
              </Link>
              <div className="collapse" id="pagesCollapseStore" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['47']) ?
                    <NavLink className="nav-link" to="/admin/buy">
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-cart-plus"></i> </div>
                      Mua hàng
                    </NavLink>
                    : null
                  }

                  <NavLink className="nav-link" to="/admin/material">
                    <div className="sb-nav-link-icon"> <i className="fa-solid fa-cart-plus"></i> </div>
                    Xuất kho vật tư
                  </NavLink>

                  {new Check().permission(['46']) ?
                    <NavLink className="nav-link" to="/admin/store">
                      <div className="sb-nav-link-icon"><i className="fa-solid fa-boxes-stacked"></i></div>
                      Xuất-Nhập-Tồn
                    </NavLink>
                    : null
                  }

                  {new Check().permission(['40']) ?
                    <NavLink className="nav-link" to="/admin/ingredent">
                      <div className="sb-nav-link-icon"><i className="fa-solid fa-carrot me-1"></i></div>
                      Nguyên liệu
                    </NavLink>
                    : null
                  }
                  {new Check().permission(['44']) ?
                    <NavLink className="nav-link" to="/admin/bank-ingredent">
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-book"></i>
                      </div>
                      Ngân hàng nguyên liệu
                    </NavLink>
                    : null}

                  {new Check().permission(['50']) ?
                    <NavLink className="nav-link" to="/admin/storeinventory">
                      <div className="sb-nav-link-icon"><i className="fa fa-clock-rotate-left me-1"></i></div>
                      Lịch sử
                    </NavLink>
                    : null
                  }

                  <NavLink className="nav-link" to="/admin/storematerial">
                    <div className="sb-nav-link-icon"><i className="fa-solid fa-circle-dollar-to-slot me-1"></i></div>
                    Tổng hợp kho/vật tư
                  </NavLink>
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['23'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseSupplier" aria-expanded="false" aria-controls="pagesCollapseSupplier" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-building-wheat"></i> </div>
                Nhà cung cấp
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i> </div>
              </Link>
              <div className="collapse" id="pagesCollapseSupplier" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['23'], 'or') ?
                    <NavLink className="nav-link" to="/admin/supplier">
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-list"></i> </div>
                      Danh sách
                    </NavLink> : null}
                  {new Check().permission(['23'], 'or') ?
                    <NavLink className="nav-link" to="/admin/hissupplier">
                      <div className="sb-nav-link-icon"> <i className="fa fa-clock-rotate-left"></i> </div>
                      Lịch sử
                    </NavLink> : null}
                </nav>
              </div>
            </>
            : null
          }

          {new Check().permission(['28', '33', '35'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseFood" aria-expanded="false" aria-controls="pagesCollapseFood" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-bowl-rice"></i> </div>
                Món ăn
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i> </div>
              </Link>
              <div className="collapse" id="pagesCollapseFood" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {/* {new Check().permission(['28'], 'or') ?
                    <NavLink className="nav-link" to="/admin/food">
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-list"></i> </div>
                      Danh sách món
                    </NavLink>
                    : null} */}

                  {new Check().permission(['35']) ?
                    <NavLink className="nav-link" to="/admin/category-food">
                      <div className="sb-nav-link-icon">  <i className="fa-solid fa-tag"></i> </div>
                      Danh mục món
                    </NavLink>
                    : null}
                  {new Check().permission(['33']) ?
                    <NavLink className="nav-link" to="/admin/bank-food"
                    // onClick={(event) => event.preventDefault()}
                    // style={{ cursor: "help" }}
                    // title="Đang phát triển..."
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-book"></i>
                      </div>
                      Ngân hàng món
                    </NavLink>
                    : null}
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['7', '12', '17'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseStudent" aria-expanded="false" aria-controls="pagesCollapseStudent" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-user-graduate"></i> </div>
                Học sinh
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
              </Link>
              <div className="collapse" id="pagesCollapseStudent" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['17'], 'or') ?
                    <NavLink className="nav-link" to="/admin/student">
                      <div className="sb-nav-link-icon"><i className="fa-solid fa-list"></i></div>
                      Danh sách
                    </NavLink>
                    : null
                  }

                  {/* {new Check().permission(['12'], 'or') ?
                    <NavLink className="nav-link" to="/admin/class">
                      <div className="sb-nav-link-icon"><i className="fa-solid fa-users"></i></div>
                      Lớp học
                    </NavLink>
                    : null
                  } */}

                  {new Check().permission(['7'], 'or') ?
                    <NavLink className="nav-link" to="/admin/student-group">
                      <div className="sb-nav-link-icon"><i className="fa-solid fa-users"></i></div>
                      Nhóm học sinh
                    </NavLink>
                    : null
                  }
                </nav>
              </div>
            </>
            : null
          }

          {new Check().permission(['2', '62'], 'or') && localStorage.getItem('role_rank') < 3 ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseUser" aria-expanded="false" aria-controls="pagesCollapseUser" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-chalkboard-user"></i> </div>
                Nhân viên
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
              </Link>

              <div className="collapse" id="pagesCollapseUser" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['2'], 'or') ?
                    <NavLink className="nav-link" to="/admin/user">
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-list"></i> </div>
                      Danh sách
                    </NavLink>
                    : null}

                  {new Check().permission(['62'], 'or') ?
                    <NavLink className="nav-link" to="/admin/role">
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-user-tag"></i> </div>
                      Chức vụ
                    </NavLink>
                    : null}
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['82', '83', '84', '89', '90', '91', '92'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseReport" aria-expanded="false" aria-controls="pagesCollapseReport">
                <div className="sb-nav-link-icon"><i className="fa-solid fa-chart-simple"></i></div>
                Báo cáo
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
              </Link>

              <div className="collapse" id="pagesCollapseReport" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['92'], 'or') ?
                    <NavLink className="nav-link" to="/admin/report-detail">
                      <div className="sb-nav-link-icon">  <i className="fa-solid fa-file-lines"></i> </div>
                      Báo cáo chi tiết sỉ số
                    </NavLink>
                    : null}
                  {new Check().permission(['82'], 'or') ?
                    <NavLink className="nav-link" to="/admin/listcut">
                      <div className="sb-nav-link-icon">  <i className="fa-solid fa-file-lines"></i> </div>
                      Chi tiết cắt suất ăn
                    </NavLink>
                    : null}
                  {new Check().permission(['83'], 'or') ?
                    <NavLink className="nav-link" to="/admin/absent-report" >
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-file-lines"></i> </div>
                      Ngày nghỉ nhân viên
                    </NavLink>
                    : null}
                  {new Check().permission(['84'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/market-report"
                    >
                      <div className="sb-nav-link-icon"> <i className="fa-solid fa-circle-dollar-to-slot"></i> </div>
                      Chi phí nguyên liệu
                    </NavLink>
                    : null}

                  {new Check().permission(['89'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/finance-book"
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-book"></i>
                      </div>
                      Sổ tài chính
                    </NavLink>
                    : null
                  }

                  {new Check().permission(['90'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/finance-report"
                    >
                      <div className="sb-nav-link-icon">  <i className="fa-solid fa-file-lines"></i> </div>
                      Báo cáo thu chi
                    </NavLink>
                    : null
                  }

                  {new Check().permission(['91'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/supplier-report"
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-circle-dollar-to-slot"></i>
                      </div>
                      Mua hàng theo NCC
                    </NavLink>
                    : null
                  }
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['77', '78', '79'], 'or') ?
            <>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseFrom" aria-expanded="false" aria-controls="pagesCollapseFrom" >
                <div className="sb-nav-link-icon"><i className="fa-solid fa-file-export"></i></div>
                Biểu mẫu
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
              </Link>
              <div className="collapse" id="pagesCollapseFrom" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion"  >
                <nav className="sb-sidenav-menu-nested nav">
                  {new Check().permission(['77'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/3-step-book"
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-book"></i>
                      </div>
                      Sổ 3 bước
                    </NavLink>
                    : null}
                  {new Check().permission(['78'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/samplebook"
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-book"></i>
                      </div>
                      Sổ lưu mẫu ngày
                    </NavLink>
                    : null}
                  {new Check().permission(['79'], 'or') ?
                    <NavLink className="nav-link"
                      to="/admin/form-setting"
                    >
                      <div className="sb-nav-link-icon">
                        <i className="fa-solid fa-gears"></i>
                      </div>
                      Cài đặt biểu mẫu
                    </NavLink>
                    : null}
                </nav>
              </div>
            </>
            : null}

          {new Check().permission(['59', '67', '86'], 'or') ? <>
            <div className="sb-sidenav-menu-heading">Hệ thống</div>
            {new Check().permission(['59', '67', '86'], 'or') ?
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#settingsPages" aria-expanded="false" aria-controls="settingsPages" >
                <div className="sb-nav-link-icon"> <i className="fa-solid fa-gears"></i> </div>
                Cài đặt
                <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i> </div>
              </Link> : null}
            <div className="collapse" id="settingsPages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion" >
              <nav className="sb-sidenav-menu-nested nav accordion" id="settingsPages2" >
                <NavLink className="nav-link" to="/admin/year-school">
                  <div className="sb-nav-link-icon">
                    <i className="fa-solid fa-calendar-days"></i>
                  </div>
                  Năm học
                </NavLink>
                {new Check().permission(['86'], 'or') && localStorage.getItem('role_rank') < 3 ?
                  <NavLink className="nav-link" to="/admin/school">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-school"></i>
                    </div>
                    Trường học
                  </NavLink>
                  : null}
                {new Check().permission(['67'], 'or') ?
                  <NavLink className="nav-link" to="/admin/unit">
                    <div className="sb-nav-link-icon"> <i className="fa-solid fa-weight-scale"></i> </div>
                    Đơn vị tính
                  </NavLink>
                  : null}
                {new Check().permission(['59'], 'or') && localStorage.getItem('role_rank') < 3 ?
                  <NavLink className="nav-link" to="/admin/mainsetting">
                    <div className="sb-nav-link-icon"> <i className="fa-solid fa-gears"></i> </div>
                    Cài đặt chung
                  </NavLink>
                  : null
                }
                {Number(localStorage.getItem('vip')) === 1 ?
                  <NavLink className="nav-link" to="/admin/bank-file">
                    <div className="sb-nav-link-icon"> <i className="fa-solid fa-file-pen"></i></div>
                    Thư viện file
                  </NavLink>
                  : null}
              </nav>
            </div>
          </>
            : null
          }
        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
