import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";

class SupplierFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissions: [],
            dataInput: { ...this.props.data },
            isCheckGroup: [],
        };
    }

    componentDidMount() {
        this.getPermissions();
    }

    getPermissions = () => {
        axios.get("/viewPermission")
            .then((res) => {
                this.setState({ permissions: res.data.data });
            });
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        this.setState({ dataInput: dataInput });
    }

    createSupplier = () => {
        let data = this.state.dataInput;
        let errors = 0;
        if (data.supplier_name === '') { toast(`Vui lòng nhập tên nhà cung cấp.`, { type: "error", autoClose: 1500 }); errors++; }
        // if (data.supplier_phone === '') { toast(`Vui lòng nhập số điện thoại.`, { type: "error", autoClose: 1500  }); errors++; }
        if (data.supplier_author === '') { toast(`Vui lòng nhập đại diện.`, { type: "error", autoClose: 1500  }); errors++; }
        if (errors > 0) { return false; }

        axios.post(`/createSupplier?supplier_name=${data.supplier_name}&supplier_phone=${data.supplier_phone}&supplier_address=${data.supplier_address}&supplier_author=${data.supplier_author}`)
            .then((res) => {
                if (res.data.status) {
                    toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                    return this.props.submit();
                } else {
                    toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateSupplier = () => {
        let data = this.state.dataInput;
        let errors = 0;
        if (data.supplier_name === '') { toast(`Vui lòng nhập tên nhà cung cấp.`, { type: "error", autoClose: 1500  }); errors++; }
        // if (data.supplier_phone === '') { toast(`Vui lòng nhập số điện thoại.`, { type: "error", autoClose: 1500 }); errors++; }
        if (data.supplier_author === '') { toast(`Vui lòng nhập đại diện.`, { type: "error", autoClose: 1500  }); errors++; }

        if (errors > 0) { return false; }

        swal({
            title: "Bạn muốn cập nhật nhà cung cấp?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.post(`/updateSupplier?id_supplier=${data.id}&supplier_name=${data.supplier_name}&supplier_phone=${data.supplier_phone}&supplier_address=${data.supplier_address}&supplier_author=${data.supplier_author}`)
                    .then((res) => {
                        if (res.data.status) {
                            toast(`${res.data.data.mess}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createSupplier();
        } else {
            this.updateSupplier();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }

    render() {
        const { onHide, ...other } = { ...this.props };
        return (<>
            <Modal show={other.show} onHide={onHide} size="md" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title><i className="fa-solid fa-building-wheat" />&nbsp;{other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control onChange={this.handleInput} name="supplier_name" value={this.state.dataInput.supplier_name} placeholder="Tên nhà cung cấp" />
                            <Form.Label>Tên nhà cung cấp<i className="text-danger">*</i> </Form.Label>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control onChange={this.handleInput} name="supplier_phone" value={this.state.dataInput.supplier_phone} placeholder="Số điện thoại" />
                            <Form.Label>Số điện thoại</Form.Label>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control onChange={this.handleInput} name="supplier_address" value={this.state.dataInput.supplier_address} placeholder="Địa chỉ" />
                            <Form.Label>Địa chỉ</Form.Label>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control onChange={this.handleInput} name="supplier_author" value={this.state.dataInput.supplier_author} placeholder="Đại diện" />
                            <Form.Label>Đại diện<i className="text-danger">*</i> </Form.Label>
                        </Form.Floating>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit" >
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
        );
    }
}

export default SupplierFormModal;