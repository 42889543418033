import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Tabs,
  Tab,
  Modal,
  Table,
  Form, Col, Row
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import Check from "../../other/Check";
import DatePicker from "react-datepicker";
import DataTables from "../../datatable/DataTables";
import swal from "sweetalert";

class ListmealIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifies: [],
      cutMeals: [],
      cutMealDetail: [],
      cutMealDetailSearch: [],
      searchtext: "",
      cutMealDetailShow: [],
      classifyShow: 1,
      toDay: new Date(),
      listClass: [],
      listUser: [],
      showCutMealDetail: false,
      selectDate_: [],
      selectMonth_: [],
      selectYear_: [],
      dataDate: [{ value: 0, label: "Tất cả ngày" }],
      dataMonth: [{ value: Number((new Date()).getMonth()) + 1, label: "Tháng " + Number(Number((new Date()).getMonth()) + 1) }],
      dataYear: [{ value: (new Date()).getFullYear(), label: (new Date()).getFullYear() }],
      choiceDate: { value: 0, label: "Tất cả ngày" },
      choiceMonth: { value: Number((new Date()).getMonth()) + 1, label: "Tháng " + Number(Number((new Date()).getMonth()) + 1) },
      choiceYear: { value: (new Date()).getFullYear(), label: (new Date()).getFullYear() },
      checkloading: true,
      startDate: new Date().setHours(0, 0, 0),
      endDate: new Date().setFullYear(23, 59, 59)
    };

  }

  async componentDidMount() {
    document.title = "Suất ăn";
    this.getClassifiesData();
    // this.getCutMealsData(1, 0, 0)
    this.getClassRoom(new Date().setHours(0, 0, 0))
    this.getAllUser()
    this.selectMonth()
    this.selectDate(new Date(this.state.choiceYear.value, this.state.choiceMonth.value, 0));
    this.selectYear(this.state.toDay.getFullYear())
    // this.searchDate(this.state.choiceDate.value, Number((new Date()).getMonth())+1, this.state.choiceYear.value, this.state, this.state.cutMealDetail)
  }
  loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }
  searchText = (text) => {
    this.setState({ searchtext: text })
    this.searchDate(this.state.choiceDate.value, this.state.choiceMonth.value, this.state.choiceYear.value, text, this.state.cutMealDetail)
  }
  onChangeDate = (e) => {
    this.setState({ choiceDate: e })
    this.setState({
      dataDate: [{ value: e.value, label: e.value === 0 ? e.label : e.value }],
    })
    this.searchDate(e.value, this.state.choiceMonth.value, this.state.choiceYear.value, this.state.searchtext, this.state.cutMealDetail)
  }

  onChangeMonth = (e, year) => {
    // console.log(e)
    this.setState({ choiceMonth: e })
    var lastDayOfLastMonth = (new Date(year, e.value, 0));
    this.selectDate(lastDayOfLastMonth);
    this.setState({
      dataMonth: [{ value: e.value, label: e.value === 0 ? e.label : e.label }],
      // dataDate: [{ value: 0, label: "Tất cả" }],
    })
    this.searchDate(this.state.dataDate[0]?.value ?? 0, e.value, year, this.state.searchtext, this.state.cutMealDetail)

  }

  onChangeYear = (e) => {
    this.setState({ choiceYear: e })
    this.setState({
      dataYear: [{ value: e.value, label: e.value }],
      dataDate: [{ value: 0, label: "Tất cả ngày" }],
    })
    this.onChangeMonth({ value: 0, label: "Tất cả" }, e.value);
  }

  searchDate = (day, month, year, searchtext, cutMealDetail) => {
    var arr_ = cutMealDetail;
    var date_ = day + '/' + month + '/' + year;
    if (day === 0) date_ = month + "/" + year;
    if (month === 0 && day == 0) date_ = year;
    if (month === 0 && day != 0) date_ = day + '/' + year;

    var type = day > 0 ? 1 : month > 0 ? 2 : 3;
    if (day > 0 && month == 0) type = 4
    arr_ = arr_.filter(e =>
      this.getDate(type, e.meal_day) == date_
    )

    if (arr_.length > 0) {
      if (searchtext !== "") {
        arr_ = arr_.filter(e =>
          this.toSlug(e.student_name).includes(this.toSlug(searchtext.trim()))
        )
      }

    }
    arr_.sort(function (a, b) {
      // if (a.is_status == 0 && b.is_status == 1) return -1;
      // if (a.is_status == 1 && b.is_status == 0) return 1;
      if (a.meal_day > b.meal_day) return -1;
      if (a.meal_day < b.meal_day) return 1;
      // if (a.is_status === 1 && b.is_status == 0) return 1; 
      // if (a.is_status ==0 && b.is_status == 1) return -1;
      return 0;
      // if (a.is_status <= b.is_status) return -1;
      // if (a.is_status > b.is_status) return 1;
      // return 0;
    })
    // console.log(cutMealDetail)
    this.setState({ cutMealDetailSearch: arr_ })
  }

  getAllUser = () => {
    axios.get("/showAllUser").then((res) => {
      if (res.data.status) {
        this.setState({ listUser: res.data.data })
      }
    })
      .catch((error) => { console.log(error); });
  }
  getClassifiesData = () => {
    axios.get(`/viewClassify`)
      .then(res => {
        if (res.data.status === true) {
          this.setState({
            classifies: res.data.data,
            classifyShow: res.data.data[0].id,
            checkloading: false
          });

          this.getCutMealsData(res.data.data[0].id, new Date(this.state.startDate), new Date(new Date(this.state.startDate).setHours(23, 59, 59)))

        }
        this.setState({
          startDate: new Date(this.state.startDate),
          endDate: new Date(new Date(this.state.startDate).setHours(23, 59, 59))
        })
      });
  }

  getCutMealsData = (classifyId, startDate, endDate) => {
    this.setState({
      cutMeals: [],
      // startDate: new Date(this.state.startDate * 1000).setHours(0, 0, 0)
    })
    axios.post(`/viewMeal?id_classify=${classifyId}&start_date=${parseInt(startDate/1000)}&end_date=${parseInt(endDate/1000)}`)

      .then(res => {
        if (res.data.status === true) {
          this.setState({
            cutMeals:
              res.data.data,
          })

          // this.setState({cutMealDetailSearch:res.data.data})
          // this.searchDate(this.state.choiceDate.value, this.state.choiceMonth.value, this.state.choiceYear.value, this.state.searchtext, res.data.data)
          // this.searchDate(this.state.choiceDate.value, this.state.choiceMonth.value, this.state.choiceYear.value, this.state.searchtext, res.data.data.filter(absentDay => this.state.startDate/1000 <= absentDay.meal_day && absentDay.meal_day <= this.state.endDate/1000 && absentDay.is_status == 1))
        } else {
          this.setState({
            cutMeals: []
          })
        }

      });
  }
  delCut = (id, id_user) => {
    swal({
      title: `Bạn chắc muốn xóa cắt xuất?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,

    }).then((ok) => {
      if (ok) {
        axios.post(`/delMeal?id_meal=${id}`).then(res => {
          if (res.data.status === true) {

            this.setState({
              cutMealDetailSearch: this.state.cutMealDetailSearch.filter(e => e.id_meal !== id)
                .sort(function (a, b) {
                  if (a.meal_day > b.meal_day) return -1;
                  if (a.meal_day < b.meal_day) return 1;
                  // if (a.is_status <= b.is_status  ) return -1;
                  // if (a.is_status > b.is_status) return 1;
                  if (a.is_status === 1 && b.is_status == 0) return 1;
                  if (a.is_status == 0 && b.is_status == 1) return -1;
                  return 0;
                })
            })

            // setcutMealDetailSearch(cutMealDetailSearch.filter(e => e.id_meal !== id).sort(function (a, b) {
            // 	if (a.is_status <= b.is_status) return -1;
            // 	if (a.is_status > b.is_status) return 1;
            // 	return 0;
            // }));
            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
            // getCutMealDetailData(id_user)

          } else {
            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
          }
        })
      }
    });
  }
  getClassRoom = (start) => {

    axios.get(`/viewClassRoom?start=${start / 1000}`).then(res => {

      if (res.data.status === true) {
        this.setState({ listClass: res.data.data })
      }

    })
    this.getCutMealsData(1, new Date( new Date(start).setHours(0,0,0)), new Date(new Date(start)?.setHours(23, 59, 59)))

  }
  getCutMealDetailData = (paramId) => {
    let classifyLink = `/viewMeal?id_classify=${this.state.classifyShow}&${this.state.classifyShow.toString() === "1" ? `id_class=${paramId}` : `id_person=${paramId}`}`;
    axios.post(classifyLink)
      .then(res => {
        if (res.data.status === true) {
          this.setState({
            cutMealDetail: res.data.data,
            cutMealDetailShow: res.data.data,
            cutMealDetailSearch: res.data.data
          });
          this.searchDate(this.state.choiceDate.value,
            
            this.state.choiceMonth.value
            // Number((new Date()).getMonth()) + 1
            , this.state.choiceYear.value, this.state.searchtext, res.data.data)
          // this.searchDate(0, 0, (new Date()).getFullYear(), this.state.searchtext, res.data.data)
        } else {
          this.setState({
            cutMealDetail: [],
            cutMealDetailShow: [],
          })
        }
      });
  }

  handleSelectClassify = (key) => {
    this.getCutMealsData(key, new Date(this.state.startDate), new Date(new Date(this.state.startDate).setHours(23, 59, 59)));
    // this.searchDate(this.state)
    // this.searchDate(this.state.dataDate[0].value, this.state.choiceMonth.value, this.state.choiceYear.value, this.state.searchtext, this.state.cutMealDetail)
    this.setState({
      classifyShow: key.toString()
    });
  }

  handleApprove = (id) => {
    let link = `/changeMoreStatus?${this.state.classifyShow.toString() === "1" ? `id_class=${id}` : `id_person=${id}`}`;
    axios.post(link)
      .then(res => {
        if (res.data.status === true) {
          this.getCutMealsData(this.state.classifyShow, new Date(this.state.startDate), new Date(new Date(this.state.startDate).setHours(23, 59, 59)));

          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      });
  }
  handleApproveToday = () => {
    let link = `/changeMoreStatus?id_classify=${this.state.classifyShow.toString()}`;
    axios.post(link)
      .then(res => {
        if (res.data.status === true) {
          this.getCutMealsData(this.state.classifyShow, new Date(this.state.startDate), new Date(new Date(this.state.startDate).setHours(23, 59, 59)));

          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      });
  }

  confirmChangeStatus = (id_meal, is_term, is_status, id_class) => {
    if (is_term === 0 && is_status === 0) {
      swal({
        title: "Chọn trạng thái duyệt cắt xuất.",
        icon: "warning",
        buttons: {
          cancel: "Đóng",
          catch: {
            text: "Báo đúng",
            value: "true",
          },
          defeat: {
            text: "Báo trễ",
            value: "false",
            dangerMode: true,
          },
        },
      }).then((value) => {
        switch (value) {
          case "false":
            return this.handleCanelMeal(id_meal, 0, id_class);
          case "true":
            return this.handleCanelMeal(id_meal, 1, id_class);
          default:
            break;
        }
      });
    } else {
      return this.handleCanelMeal(id_meal, null, id_class);
    }

  }

  handleCanelMeal = (id, is_term = null, id_class) => {
    axios.post(`/changeStatus?id_meal=${id}&is_term=${is_term}`)
      .then(res => {


        if (res.data.status === true) {
          this.getCutMealDetailData(id_class);
          /*
          const index = this.state.cutMeals.findIndex(emp => emp.id_meal.toString() === id.toString());
          let cutMeals = [...this.state.cutMeals];
          cutMeals[index].is_status = cutMeals[index].is_status ? 0 : 1;

          const indexDe = this.state.cutMealDetail.findIndex(emp => emp.id_meal.toString() === id.toString());
          let cutMealDetail = [...this.state.cutMealDetail];
          cutMealDetail[indexDe].is_status = cutMealDetail[indexDe].is_status ? 0 : 1;
          let getAuth = this.state.listUser.filter(e => e.id.toString() === localStorage.getItem('auth_id_user').toString());
          let nameUser = "";
          if (getAuth.length > 0) nameUser = getAuth[0].user_name;
          cutMealDetail[indexDe].user_by = nameUser ?? "";
          cutMealDetail[indexDe].updated_at = (new Date()) / 1000;

          // cutMealDetail.sort(function (a, b) {
          //   if (a.is_status <= b.is_status) return -1;
          //   if (a.is_status > b.is_status) return 1;
          //   return 0;
          // })

          this.setState({ cutMeals, cutMealDetail });
          // this.setState({ cutMealDetailSearch: cutMealDetail })
          this.searchDate(this.state.dataDate[0].value, this.state.choiceMonth.value, this.state.choiceYear.value, this.state.searchtext, cutMealDetail);

          */

          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      });
  }

  selectMonth = () => {
    let viewSelectMonth = [];
    viewSelectMonth.push({ value: 0, label: "Tất cả tháng" })
    for (let i = 1; i <= 12; i++) {
      viewSelectMonth.push({ value: i, label: "Tháng " + i })
    }

    this.setState({ selectMonth_: viewSelectMonth })
  }
  selectYear = (year) => {
    let viewSelectYear = [];
    for (let i = year - 10; i <= year; i++) {
      viewSelectYear.push({ value: i, label: i })
    }
    this.setState({ selectYear_: viewSelectYear })

  }
  selectDate = (day) => {
    let viewSelecDate = [];
    viewSelecDate.push({ value: 0, label: "Tất cả ngày" })
    for (let i = 1; i <= day.getDate(); i++) {
      viewSelecDate.push({ value: i, label: i })
    }
    this.setState({ selectDate_: viewSelecDate })
  }
  dataTable() {

    const columns = [
      {
        name: "Tên lớp",
        selector: (row) => row.class_name,
        sortable: true,
      },
      {
        name: "Giáo viên",
        selector: (row) => row.user_name,
        sortable: true,
        style: {
          div: {
            // background:'red',

            overflow: 'inherit !important ',
            width: '200px'
          }
        }

      },

      {
        name: "SL đi học",
        selector: (row) => row.count_student,
        sortable: true,
        center: true,

      },
      {
        name: "SL đã duyệt",
        selector: (row) => row.daduyet,
        // sortable: true,
        center: true,
      },
      {
        name: "Tổng chưa duyệt",
        selector: (row) => row.chuaduyet,
        // sortable: true,
        center: true,
        style: {
          div: {
            fontWeight: 'bold'
          }
        }
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        // sortable: true,
        center: true,
      },

    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    var startDate = new Date(this.state.startDate).setHours(0, 0, 0)
    var endDate = new Date(this.state.endDate).setHours(23, 59, 59)

    if (this.state.listClass.length > 0) {
      this.state.listClass.map((item, i) => {
        data.push({
          // <td>{item.class_name}</td>
          // <td>{item.user_name}</td>
          class_name: item.class_name,
          user_name: item.user_name,
          count_student: item.count_student - (this.state.cutMeals.length > 0 ?
            this.state.cutMeals.filter((e) => e.class_name === item.class_name &&
              e.is_status === 1 && e.type_meal == 1 && startDate / 1000 - 500 <= e.meal_day && e.meal_day <=
              endDate / 1000
              // this.state.endDate / 1000
            ).length : 0),
          daduyet: this.state.cutMeals.length > 0 ?

            this.state.cutMeals.filter((e) =>
              e.class_name === item.class_name &&
              e.is_status === 1 && startDate / 1000 - 100 <= e.meal_day && e.meal_day <=
              endDate / 1000

              // this.state.endDate / 1000
            ).length : 0,
          chuaduyet: this.state.cutMeals.length > 0 ? this.state.cutMeals.filter((e) => e.class_name === item.class_name && e.is_status !== 1).length : 0,
          setting:
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  this.getCutMealDetailData(item.id);
                  this.setState({
                    showCutMealDetail: true
                  })
                }}
              >
                <i style={{ width: 14 }} className="d-block d-sm-none fa-solid fa-info" /> <div className="d-none d-sm-block">Chi tiết</div>
              </Button>
            </>
        });

        return item;
      });
    }

    const totalStudentdihoc = data.reduce((total, record) => total + record.count_student, 0);
    const totaldaduyet = data.reduce((total, record) => total + record.daduyet, 0);
    const totalchuaduyet = data.reduce((total, record) => total + record.chuaduyet, 0);


    const totalRow = {
      id: 'total-student',
      name: 'Tổng',
      count_student: totalStudentdihoc,
      user_name: "Tổng",
      daduyet: totaldaduyet,
      chuaduyet: totalchuaduyet
    };

    const temporaryData = [...data, totalRow];
    return (
      <>
        <DataTables data={temporaryData} columns={columns} selectedRows={selectedRows}
        // className='custom-data-table'

        />
      </>

    );
  }
  dataTableUser() {

    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.user_name,
        sortable: true,
      },
      {
        name: "Nghỉ",
        selector: (row) => row.nghi,
        sortable: true,

      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        // sortable: true,
        center: true,
      },

    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.listUser.length > 0) {
      this.state.listUser.map((item, i) => {
        data.push({
          nghi: ((this.state.cutMeals.length > 0 ? this.state.cutMeals.filter((e) => e.user_name === item.user_name && e.is_status === 1
            && this.state.startDate / 1000 <= e.meal_day && e.meal_day <= this.state.endDate / 1000).length : 0) > 0) ? "Có" : "Không",
          user_name: item.user_name,
          setting:
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  this.getCutMealDetailData(item.id);
                  this.setState({ showCutMealDetail: true })
                }}
              >
                <i style={{ width: 14 }} className="d-block d-sm-none fa-solid fa-info" /> <div className="d-none d-sm-block">Chi tiết</div>
              </Button>
            </>
        });

        return item;
      });
    }

    const Tong = data.reduce((total, record) => total + (record.nghi == "Có" ? 1 : 0), 0);
    const totalRow = {
      id: 'total-student',
      name: 'Tổng',
      user_name: 'Tổng',
      nghi: Tong,
    };
    const temporaryData = [...data, totalRow];
    return (
      <>
        <DataTables data={temporaryData} columns={columns} selectedRows={selectedRows}
        />
      </>

    );
  }
  renderCutMealListTable() {
    var totalPresent = 0;
    var totalUnapprove = 0;
    var totalApprove = 0;
    var total = 0;
    {
      (this.state.cutMeals.filter(item1 => this.state.startDate / 1000 <= item1.meal_day && item1.meal_day <= this.state.endDate / 1000)).map(item => {

        {
          totalApprove += (item.is_status == 1
          )
          total += (item.is_status == 1
            && item.type_meal == 1
          )
        }
        // if (item.is_status === 0) { totalUnapprove++ }
      })
      this.state.cutMeals.map(e => { if (e.is_status === 0) { totalUnapprove++ } })

    }

    return (
      <>
        {
          this.state.classifyShow.toString() === "1" ?
            this.dataTable()
            :
            this.dataTableUser()

        }
      </>
    )
  }
  renderDetailCutMealList() {
    return (
      <>
        <Modal
          size="xl"
          show={this.state.showCutMealDetail}
          // keyboard={false}
          // backdrop="static"

          onHide={() => {
            this.setState({
              showCutMealDetail: false, cutMealDetail: [], cutMealDetailShow: [], searchtext: '',
              dataMonth: { value: Number((new Date()).getMonth()) + 1, label: "Tháng " + Number(Number((new Date()).getMonth()) + 1) },
              dataDate: { value: 0, label: "Tất cả ngày" },
              choiceDate: { value: 0, label: "Tất cả ngày" },
              choiceMonth: { value: Number((new Date()).getMonth()) + 1, label: "Tháng " + Number(Number((new Date()).getMonth()) + 1) },
              dataYear: { value: (new Date()).getFullYear(), label: (new Date()).getFullYear() },
              choiceYear: { value: (new Date()).getFullYear(), label: (new Date()).getFullYear() }
            });
          }}

        >
          <Modal.Header closeButton>
            <Modal.Title>Chi tiết cắt suất {this.state.classifyShow.toString() === "1" ? this.state.cutMealDetail[0]?.class_name : this.state.cutMealDetail[0]?.user_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.state.cutMealDetail.length === 0 ?
                <div className="text-center">Không có dữ liệu</div>
                :
                <>
                  <Row style={{ justifyContent: "flex-start", marginTop: '-10px' }}>
                    {this.state.classifyShow.toString() === "1" ?
                      <Col md={3}>
                        <Form.Control placeholder="Nhập tên học sinh" onChange={(e) => {
                          this.searchText(e.target.value)
                        }}
                          value={this.state.searchtext} />
                      </Col> : null}
                    <Col md={2} style={{ zIndex: 4 }} >
                      < Select
                        options={this.state.selectDate_}
                        defaultValue={this.state.selectDate_[0]}
                        value={this.state.dataDate}
                        onChange={(choice) => {
                          this.onChangeDate(choice)
                        }}
                      >
                      </Select >
                    </Col>
                    <Col md={2} style={{ zIndex: 3 }}>
                      < Select
                        options={this.state.selectMonth_}
                        defaultValue={this.state.selectMonth_[0]}
                        value={this.state.dataMonth}
                        onChange={(choice) => {
                          this.onChangeMonth(choice, this.state.choiceYear.value)
                        }}
                      >
                      </Select >
                    </Col>
                    <Col md={2} style={{ zIndex: 2 }}>
                      < Select
                        options={this.state.selectYear_}
                        defaultValue={this.state.selectYear_[0]}
                        value={this.state.dataYear}
                        onChange={(choice) => {
                          this.onChangeYear(choice)
                        }}
                      >
                      </Select >
                    </Col>
                  </Row>

                  <Table responsive striped style={{ position: 'relative' }}>
                    <thead style={{ position: 'sticky', top: 0 }}>
                      <tr style={{ whiteSpace: "nowrap" }}>
                        {this.state.classifyShow.toString() === "1" ? <> <th style={{ width: "140px" }}>Tên HS</th></> : <></>}
                        <th className="text-center" style={{ width: "80px" }}>Ngày nghỉ</th>
                        <th className="text-center" style={{ width: this.state.classifyShow.toString() === "1" ? "160px" : "220px" }}>Loại</th>
                        <th className="text-center" style={{ width: this.state.classifyShow.toString() === "1" ? "140px" : "170px" }}>Lí do</th>
                        <th className="text-center" style={{ width: "120px" }}>Ngày báo</th>
                        <th className="text-center" style={{ width: "90px" }}>Duyệt</th>
                        <th className="text-center" style={{ width: "130px" }}>Người duyệt</th>
                        <th className="text-center" style={{ width: "150px" }}>Ngày duyệt</th>
                        <th className="text-center" style={{ width: this.state.classifyShow.toString() === "1" ? "110px" : "150px" }}>Phương thức</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr style={{ height: "40px", border: 'none' }}></tr> */}
                      {
                        this.state.cutMealDetailSearch.map((mealDetail) => (
                          <tr key={mealDetail.id}>
                            {this.state.classifyShow.toString() === "1" ? <><td style={{ width: "150px" }}>{mealDetail.student_name}</td></> : <></>}
                            <td className="text-center" style={{ width: "80px" }}> {this.getDate(1, mealDetail.meal_day)}</td>
                            <td className="text-center" style={{ width: this.state.classifyShow.toString() === "1" ? "200px" : "260px" }}>
                              {mealDetail.type_meal === 1 ? "Nguyên ngày" : mealDetail.detail?.map((item, index) => item.time_name + ((index + 1 === mealDetail.detail.length) ? "" : ", "))}
                            </td>
                            <td className="text-center" style={{ width: this.state.classifyShow.toString() === "1" ? "140px" : "140px" }}>
                              
                            <span
                              style={{
                                width: "140px",
                                // whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "block",
                              }} 
                              title=  {mealDetail.note}
                            >
                              {mealDetail.note}
                            </span>
                              
                               </td>
                            <td className="text-center" style={{ width: "150px" }}>

                              {this.getTime(mealDetail.created_at)}</td>

                            <td className="text-center" style={{ width: "80px" }}>
                              <div className={!mealDetail.is_term ? "text-danger" : mealDetail.is_status ? "text-success" : "text-danger"}>
                                {!mealDetail.is_term && mealDetail.is_status == '1'
                                  ? "Báo trễ"
                                  : mealDetail.is_status == '1'
                                    ? <i className="fa-sharp fa-solid fa-check fa-lg" title="Đã duyệt" style={{ color: "#00c203" }}></i>
                                    : null
                                }
                              </div>
                            </td>
                            <td className="text-center" style={{ width: "150px" }}> {mealDetail.is_status ? !mealDetail.is_term ? "" : mealDetail.user_by : ""}</td>
                            <td className="text-center" style={{ width: "150px" }}>{mealDetail.is_status ? !mealDetail.is_term ? "" : this.getTime(mealDetail.updated_at) : " "}</td>

                            <td className="text-end " >
                              {
                                new Check().permission(['74', '75'])
                                  ?
                                  <>
                                    {new Check().permission(['74'])
                                      ? <>
                                        <Button
                                          // disabled={mealDetail?.meal_day < (new Date(new Date().setHours(0, 0, 0, 0))) / 1000
                                          //   ? true
                                          //   : false
                                          // }
                                          variant={!mealDetail.is_term && mealDetail.is_status == '1' ? "secondary me-2" : !mealDetail.is_status ? "success me-2" : "secondary me-2"}
                                          size="sm"
                                          onClick={() =>
                                            this.confirmChangeStatus(mealDetail.id_meal, mealDetail.is_term, mealDetail.is_status, mealDetail.id_class)
                                            // this.handleCanelMeal(mealDetail.id_meal, mealDetail.id)
                                          }
                                        >
                                          {!mealDetail.is_term && mealDetail.is_status == '1' ? "Hủy" : !mealDetail.is_status ? "Duyệt" : "Hủy"}
                                        </Button>
                                      </>
                                      : null
                                    }


                                    <Button variant="danger" size="sm" title="Xóa cắt suất"
                                      onClick={() =>
                                        this.delCut(mealDetail.id_meal, mealDetail.id)
                                        // getCutMealDetailData(mealDetail.id)}
                                      }
                                      hidden={Number(localStorage.getItem('role_rank')) >= 4}
                                    >
                                      <i className='fas fa-trash'></i>
                                    </Button>
                                  </>
                                  : ""
                              }
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
            }
          </Modal.Body>
        </Modal >
      </>
    )
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       

    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp

    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ

    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt

    return str;
  }

  getDate = (type, data) => {
    let date = new Date(data * 1000);
    let result = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    if (type == 2) result = `${date.getMonth() + 1}/${date.getFullYear()}`;
    if (type == 3) result = `${date.getFullYear()}`;
    if (type == 4) result = `${date.getDate()}/${date.getFullYear()}`;
    return result;
  }

  getTime = (data) => {
    let date = new Date(data * 1000);
    let result =
      // `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
    return result;
  }

  getDate_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}`;
  }

  changeTime = (date) => {
    this.setState({ show: false })
    this.getCutMealsData(this.state.classifyShow, new Date(date.setHours(0, 0, 0)), new Date(date.setHours(23, 59, 59)));

    this.getClassRoom(new Date(date.setHours(0, 0, 0)))
    this.setState({ startDate: new Date(date.setHours(0, 0, 0)), endDate: date.setHours(23, 59, 59) })
  }

  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách cắt suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fa-solid fa-list-check"></i> Danh sách cắt suất ăn {" "}
                {/*  */}
                {this.getDate(1, this.state.startDate / 1000)} {" "}
                <Button size="sm" onClick={() => { this.setState({ show: true }) }}>
                  < i className="fa-solid fa-calendar-days"> </i>
                </Button>
                {new Check().permission(['74']) ?
                  <div style={{ float: 'right', display: 'flex' }} >
                    <div style={{ fontSize: "12px", marginBottom: "5px", fontWeight: "bold", display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      *Duyệt nhanh cắt suất được tính từ ngày hôm nay trở về trước
                    </div>
                    <Button size="sm" variant="success" title="Duyệt từ hôm nay trở về trước" style={{ whiteSpace: 'nowrap' }} onClick={() => this.handleApproveToday()}>
                      Duyệt nhanh
                    </Button>
                  </div>
                  : null
                }
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.checkloading ? this.loading() :
                <Tabs
                  id="controlled-tab-example"
                  className="mb-3"
                  unmountOnExit={true}
                  transition={false}
                  onSelect={(key) => this.handleSelectClassify(key)}
                >
                  {
                    this.state.classifies?.map((classify) => (
                      <Tab key={classify.id} eventKey={classify.id} title={classify.classify_name}>
                        {
                          this.renderCutMealListTable()
                        }
                      </Tab>
                    ))
                  }
                </Tabs>}

            </Card.Body>
          </Card>
        </Container>
        <Modal show={this.state.show} size={"sm"} onHide={() => this.setState({ show: false })}  >
          <Modal.Header closeButton >
            <Modal.Title>
              Chọn thời gian
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                selected={this.state.startDate}
                onChange={date => this.changeTime(date)}
                // onChange={(date) => 	this.setState({ start_:date})}
                inline />
            </center>
            <br />

          </Modal.Body>

        </Modal>
        {this.renderDetailCutMealList()}
      </>
    );
  }
}

export default ListmealIndex;
