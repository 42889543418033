import React, { Component } from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

class NutritionResult extends Component {
    ingredientPortionArray = () => {
        let detailArray = [];

        this.props.quantitative.time?.map(timeItem => {
            detailArray = detailArray.concat(timeItem.detail);
            return timeItem;
        })

        return detailArray;
    }

    group = (arr, key) => {
        return [...arr.reduce( (acc, o) => 
            acc.set(o[key], (acc.get(o[key]) || []).concat(o))
        , new Map()).values()];
    }

    stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
    
    render(){  
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });

        const bigDetailArray = this.ingredientPortionArray();

        if(bigDetailArray.length === 0){
            return "";
        }

        let ingreBigArray = [];
        
        const timeObj = this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect?.value)[0];

        timeObj.detail.filter(item => item.ingrendent !== undefined).map(foodItem => {
            ingreBigArray = ingreBigArray.concat(foodItem.ingrendent);
            return foodItem;
        })

        const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

        let totalChild = this.props.totalChild;

        let energyStat = 0; // Tổng năng lượng
        let proteinStat = 0; // Tổng protein
        let lipidStat = 0; // Tổng lipid
        let glucidStat = 0; // Tổng glucid

        let animalProteinStat = 0; // Tổng protein động vật
        let animalLipidStat = 0; // Tổng lipid động vật

        this.props.quantitative?.time?.filter(timeItem => timeItem.id_time === this.props.timeSelect.value)[0]?.detail?.filter(item => item.ingrendent !== undefined).map(detailItem => {
            detailItem.ingrendent.map(ingredientItem => {
                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                if(selectedGroup){                    
                    let pureMassOfOneIngredient = 0; // KL ăn được trong 1 nguyên liệu

                    // Tính ăn được trong 1 nguyên liệu
                    if(ingredientItem.unit.is_root === 0){
                        pureMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                    } else {
                        pureMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass);
                    }

                    // Tính protein và lipid động vật
                    if(ingredientItem.type_subspecies === 1){
                        animalProteinStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                        animalLipidStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                    }

                    // Tính năng lượng, protein, lipid và glucid
                    energyStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                    proteinStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                    lipidStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                    glucidStat += pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_glucid) / 100;
                }
                
                return ingredientItem;
            })
            return detailItem;
        })

        const plgTotal = (proteinStat*4 + lipidStat*9 + glucidStat*4);

        let ingredientIndex = 0;

        return(
            <Table 
                bordered 
                responsive
                id="nutrition-result-table"
            >
                <thead>
                    <tr>
                        <td colSpan={5}></td>
                        <td className="text-end align-top">
                            {this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day_name}, ngày {this.stringLastDate(this.props.listDay.filter((item) =>item.id === this.props.daySelect)[0]?.day || new Date())}
                        </td>
                        <td colSpan={4}></td>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>
                            KẾT QUẢ DƯỠNG CHẤT
                        </th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                    <tr>
                        <td>
                            Bữa ăn: Ăn {this.props.timeSelect.label}
                            <br />
                            Độ tuổi: {this.props.groupSelect.label}
                            <br />
                            Tổng số {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"}: {totalChild}
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>

                        <td>
                            Ăn {this.props.timeSelect.label}:
                            <br />
                            {
                                this.props.quantitative?.time?.filter(item => item.id_time === this.props.timeSelect.value)[0].detail?.map(detailItem => {
                                    if(detailItem.ingrendent.some(ingredient => ingredient.group.some(group => Number(group.id) === Number(this.props.groupSelect.value)))){
                                        return(
                                            <div key={detailItem.id_food}>
                                                - {detailItem.food_name}
                                                <br />
                                            </div>
                                        )
                                    }
                                    return "";
                                })
                            }
                        </td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>
                        <td>empty</td>

                    </tr>
                    <tr className="text-center align-middle">
                        <th>STT</th>
                        <th>Tên thực phẩm</th>
                        <th>Năng lượng(Kcal)</th>
                        <th>Đạm(g)</th>
                        <th>empty</th>
                        <th>Béo(g)</th>
                        <th>empty</th>
                        <th>Bột Đường(g)</th>
                        <th>Ăn Được(g)</th>
                        <th>Đi Chợ(g)</th>
                    </tr>
                    <tr className="text-center align-middle">
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th>ĐV</th>
                        <th>TV</th>
                        <th>ĐV</th>
                        <th>TV</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        aIngredientArray.map((arrayItem, index) => {
                            // Nutrition
                            let energy = 0; // Năng lượng trong 1 nguyên liệu
                            let animalProtein = 0; // Protein động vật trong 1 nguyên liệu
                            let plantProtein = 0; // Protein thực vật trong 1 nguyên liệu
                            let animalLipid = 0; // Lipid động vật trong 1 nguyên liệu
                            let plantLipid = 0; // Lipid thực vật trong 1 nguyên liệu
                            let glucid = 0; // Glucid trong 1 nguyên liệu

                            // Mass
                            let realMassOfOneIngredient = 0; // KL ăn được trong 1 nguyên liệu
                            let pureMassOfOneIngredient = 0; // KL ăn được trong 1 nguyên liệu

                            let realMass = 0; // Tổng KL đi chợ
                            let pureMass = 0; // Tổng KL ăn được

                            arrayItem.map(ingredientItem => {
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];
                                
                                // Tính KL đi chợ, ăn được trong 1 nguyên liệu
                                if(selectedGroup){
                                    if(ingredientItem.unit.is_root === 0){
                                        realMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild);
                                        pureMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass) / Number(ingredientItem.nutrients.ingredent_RealVolume);
                                    } else {
                                        realMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_RealVolume);
                                        pureMassOfOneIngredient += Number(selectedGroup.amount * this.props.totalChild * ingredientItem.nutrients.ingredent_FineMass);
                                    }

                                    if(ingredientItem.type_subspecies === 1){
                                        animalProtein = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                        animalLipid = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                    } else {
                                        plantProtein = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_protein) / 100;
                                        plantLipid = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_lipid) / 100;
                                    }

                                    energy = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_energy) / 100;
                                    glucid = pureMassOfOneIngredient * Number(ingredientItem.nutrients.ingredent_glucid) / 100;
                                    
                                }

                                return ingredientItem;
                            })

                            if(energy === 0 || realMassOfOneIngredient === 0){
                                return "";
                            }

                            arrayItem.map(ingredientItem =>{
                                const selectedGroup = ingredientItem.group.filter(groupItem => groupItem.id === this.props.groupSelect.value)[0];

                                if(selectedGroup){
                                    // Tính tổng KL đi chợ
                                    realMass = realMassOfOneIngredient;

                                    // Tính tổng  KL ăn được
                                    pureMass = pureMassOfOneIngredient;
                                }

                                return ingredientItem;
                            })

                            ingredientIndex++;

                            return(
                                <tr key={index} className="text-end align-middle">
                                    <td className="text-center">{ingredientIndex}</td>
                                    <td className="text-start">{arrayItem[0].ingredent_name}</td>
                                    <td>{formatter.format(energy)}</td>
                                    <td>{animalProtein !== 0 ? formatter.format(animalProtein) : "empty"}</td>
                                    <td>{plantProtein !== 0 ? formatter.format(plantProtein) : "empty"}</td>
                                    <td>{animalLipid !== 0 ? formatter.format(animalLipid) : "empty"}</td>
                                    <td>{plantLipid !== 0 ? formatter.format(plantLipid) : "empty"}</td>
                                    <td>{formatter.format(glucid)}</td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(pureMass) || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <CurrencyFormat value={formatter.format(realMass) || "empty"} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr className="text-center align-middle">
                        <th>Động vật/Thực vật</th>
                        <th>empty</th>
                        <th>empty</th>

                        <th>{proteinStat !== 0 ? formatter.format(animalProteinStat*100/proteinStat) : ""}</th>
                        <th>{proteinStat !== 0 ? formatter.format( 100 - (animalProteinStat*100/proteinStat)) : ""}</th>
                        <th>{lipidStat !== 0 ? formatter.format(animalLipidStat*100/lipidStat) : ""}</th>
                        <th>{lipidStat !== 0 ? formatter.format(  100 - (animalLipidStat*100/lipidStat)) : ""}</th>

                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tổng kết quả</th>
                        <th>empty</th>

                        <th>{formatter.format(energyStat)}</th>

                        <th>{formatter.format(proteinStat)}</th>
                        <th>empty</th>

                        <th>{formatter.format(lipidStat)}</th>
                        <th>empty</th>

                        <th>{formatter.format(glucidStat)}</th>

                        <th></th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tổng yêu cầu</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Định mức trung bình cho một {this.props.groupSelect.is_adult ? "nhân viên" : "trẻ"} trong buổi</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(energyStat / totalChild) : "empty"}</th>
                        <th>{totalChild !== 0 ? formatter.format(proteinStat / totalChild) : "empty"}</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(lipidStat / totalChild) : "empty"}</th>
                        <th>empty</th>

                        <th>{totalChild !== 0 ? formatter.format(glucidStat / totalChild) : "empty"}</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tỉ lệ đạt trong buổi</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>Tỉ lệ đạt ở trường</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>

                    <tr className="text-center align-middle">
                        <th>P : L : G</th>
                        <th>empty</th>
                        <th>empty</th>
                        <th>{ plgTotal !== 0 ? formatter.format(proteinStat*100*4/plgTotal) : "empty"}</th>
                        <th>empty</th>
                        <th>{ plgTotal !== 0 ? formatter.format(lipidStat*100*9/plgTotal) : "empty"}</th>
                        <th>empty</th>
                        <th>{ plgTotal !== 0 ? formatter.format(glucidStat*100*4/plgTotal) : "empty"}</th>
                        <th>empty</th>
                        <th>empty</th>
                    </tr>
                </tbody>
            </Table>
        )
    }
}
export default NutritionResult;