import React from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { groupArrayByField } from "../../../utils/helpers";

const SummaryForm = ({
  quantitative,
  setQuantitative,
  selectedClassGroup,
  isChangeMarket,
  isChangeFood,
}) => {
  if (!quantitative) {
    return "";
  }

  // Get init data
  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const numberChildren = currentCostPerDays?.meal_quantity || 0;
  const feeMeal = Number(currentCostPerDays?.fee_meal || 0);
  const feeOther = Number(currentCostPerDays?.fee_other || 0);
  const feePerChild = feeMeal + feeOther;
  const startBalance = Number(currentCostPerDays?.start_balance || 0);

  // Change data structure of quantitative to ingredient array
  let ingredientArray = [];
  let extraIngredientArray = [];
  let ingredients = [];

  quantitative.times?.map((timeItem) => {
    timeItem.foods?.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          ingredientArray.push(ingredientItem);

          const ingredientQuantity = isChangeMarket
            ? ingredientItem.tmp_ing_quantity
            : ingredientItem.ing_quantity;

          if (!ingredients.some((item) => item.id == ingredientItem.id)) {
            ingredients.push({
              ...ingredientItem,
              sumQuantity: parseFloat(ingredientQuantity),
            });
          } else {
            let index = ingredients.findIndex(
              (item) => item.id === ingredientItem.id
            );
            ingredients[index].sumQuantity += parseFloat(ingredientQuantity);
          }

          return ingredientItem;
        });

      foodItem.groups.map((groupItem) => {
        if (
          groupItem.id === selectedClassGroup.id &&
          groupItem.more_meal !== null
        ) {
          groupItem?.ingredents.map((ingredientItem) => {
            extraIngredientArray.push({
              ...ingredientItem,
              mealQuantity: groupItem.more_meal,
            });

            return ingredientItem;
          });
        }

        return groupItem;
      });
      return foodItem;
    });

    return timeItem;
  });

  let totalMoney = 0;

  if (ingredients.length > 0) {
    for (let i = 0; i < ingredients.length; i++) {
      const { volume_market, volume_cook, ing_price_expect } = ingredients[i];
      const sumQuantity = Number(ingredients[i].sumQuantity).toFixed(2) || 0;
      const gramOneChild = (sumQuantity * volume_market) / volume_cook || 0;
      // const gramEatable2 = Number(sumQuantity * meal_quantity * volume_market / volume_cook * (100 - ingredent_ratio) / 100).toFixed(2);
      const gramMarket = Number(gramOneChild * numberChildren).toFixed(2) || 0;
      const marketQuantity = Number(gramMarket / volume_market).toFixed(4) || 0;
      const ingredientPrice =
        Math.round(marketQuantity * ing_price_expect) || 0;
      // const gram100Price = Math.round(ingredientPrice2 * 100 / gramMarket2) || 0;
      totalMoney += parseInt(ingredientPrice);
    }
  }

  // const groupIngredientArray = groupArrayByField(ingredientArray, "id");
  const groupExtraIngredientArray = groupArrayByField(
    extraIngredientArray,
    "id"
  );

  let totalExtraMoney = 0;

  // groupIngredientArray.map((groupIngredientItem) => {
  // 	let ingredientPrice = 0;

  // 	let gramMarket = 0;
  // 	let marketQuantity = 0;
  // 	let volumeMarket = 0;

  // 	groupIngredientItem?.map((ingredientItem) => {
  // 		const ingredientQuantity = isChangeMarket ? ingredientItem.tmp_ing_quantity : ingredientItem.ing_quantity;
  // 		gramMarket += Number(ingredientQuantity * numberChildren * ingredientItem.volume_market / ingredientItem.volume_cook);
  // 		volumeMarket = Number(ingredientItem.volume_market);
  // 		return ingredientItem;
  // 	});

  // 	gramMarket = Number(gramMarket).toFixed(4);
  // 	marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
  // 	ingredientPrice = marketQuantity * groupIngredientItem[0].ing_price_expect;
  // 	totalMoney += Math.round(ingredientPrice);

  // 	return groupIngredientItem;
  // });

  groupExtraIngredientArray.map((groupIngredientItem) => {
    let ingredientPrice = 0;
    let gramMarket = 0;
    let volumeMarket = 0;
    let marketQuantity = 0;

    groupIngredientItem.map((ingredientItem) => {
      const ingredientQuantity = isChangeMarket
        ? ingredientItem.tmp_ing_quantity
        : ingredientItem.ing_quantity;
      gramMarket += Number(
        (ingredientQuantity *
          ingredientItem.mealQuantity *
          ingredientItem.volume_market) /
          ingredientItem.volume_cook
      );
      volumeMarket = Number(ingredientItem.volume_market);

      return ingredientItem;
    });

    gramMarket = Number(gramMarket).toFixed(4);
    marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
    ingredientPrice = marketQuantity * groupIngredientItem[0].ing_price_expect;
    totalExtraMoney += Math.round(ingredientPrice);

    return groupIngredientItem;
  });

  // Calculate money

  let totalPriceMeal = Number(feePerChild * numberChildren);
  let totalFeeOhter = Number(feeOther * numberChildren);

  let costMoney = Number(
    feeOther * numberChildren + totalMoney + totalExtraMoney
  );
  let costMoneyPerChild =
    numberChildren !== 0
      ? Math.round(Number((costMoney - totalExtraMoney) / numberChildren))
      : 0;

  let endBalance = Number(startBalance + totalPriceMeal - costMoney);
  let amountDifference = Number(totalPriceMeal - costMoney);

  const summaryFormItems = [
    {
      id: 1,
      label: "Tổng số trẻ:",
      component: (
        <CurrencyFormat
          value={numberChildren}
          thousandSeparator={true}
          onValueChange={(values) => {
            setQuantitative({
              ...quantitative,
              costPerDays: quantitative.costPerDays.map((costPerDay) => {
                if (costPerDay.id_group === selectedClassGroup.id) {
                  return {
                    ...costPerDay,
                    meal_quantity: Number(values.value),
                  };
                }

                return costPerDay;
              }),
            });
          }}
          className="form-control text-end"
          allowNegative={false}
          isNumericString
          disabled={isChangeMarket || isChangeFood}
        />
      ),
    },
    {
      id: 2,
      label: "Số tiền thu mỗi trẻ:",
      component: (
        <CurrencyFormat
          value={feePerChild}
          thousandSeparator={true}
          allowNegative={false}
          isNumericString
          displayType="text"
        />
      ),
    },
    {
      id: 3,
      label: "Tổng số tiền thu:",
      component: (
        <CurrencyFormat
          value={totalPriceMeal}
          thousandSeparator={true}
          displayType="text"
          allowNegative={false}
          isNumericString
          className="fw-bold"
        />
      ),
    },
    {
      id: 4,
      label: "Chi phí khác / 1 trẻ:",
      component: (
        <CurrencyFormat
          value={feeOther}
          thousandSeparator={true}
          allowNegative={false}
          isNumericString
          displayType="text"
        />
      ),
    },
    {
      id: 5,
      label: "Tổng chi phí khác:",
      component: (
        <CurrencyFormat
          value={totalFeeOhter}
          thousandSeparator={true}
          allowNegative={false}
          isNumericString
          displayType="text"
        />
      ),
    },
    {
      id: 6,
      label: "Số dư đầu kì:",
      component: (
        <CurrencyFormat
          value={startBalance}
          thousandSeparator={true}
          onValueChange={(values) => {
            setQuantitative({
              ...quantitative,
              costPerDays: quantitative.costPerDays.map((costPerDay) => {
                if (costPerDay.id_group === selectedClassGroup.id) {
                  return {
                    ...costPerDay,
                    start_balance: Number(values.value),
                  };
                }

                return costPerDay;
              }),
            });
          }}
          className="form-control text-end"
          allowNegative={true}
          isNumericString
          disabled={isChangeMarket || isChangeFood}
        />
      ),
    },
    {
      id: 7,
      label: "Tiền món dự trù:",
      component: (
        <CurrencyFormat
          value={totalExtraMoney}
          thousandSeparator={true}
          displayType="text"
          allowNegative={false}
          isNumericString
          className="fw-bold"
        />
      ),
    },
    {
      id: 8,
      label: "Tiền chi trong ngày:",
      component: (
        <CurrencyFormat
          value={costMoney}
          thousandSeparator={true}
          displayType="text"
          allowNegative={false}
          isNumericString
          className="fw-bold"
        />
      ),
    },
    {
      id: 9,
      label: "Tiền chi cho mỗi trẻ:",
      component: (
        <CurrencyFormat
          value={costMoneyPerChild}
          thousandSeparator={true}
          displayType="text"
          allowNegative={false}
          isNumericString
          className="fw-bold"
        />
      ),
    },
    {
      id: 10,
      label: "Số dư cuối kì:",
      component: (
        <div className="d-flex justify-content-between w-100">
          <CurrencyFormat
            value={endBalance}
            thousandSeparator={true}
            displayType="text"
            isNumericString
          />{" "}
          <div
            className="d-flex align-items-center p-0"
            title="Sao chép số dư cuối kì"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(endBalance);
              toast("Đã sao chép số dư cuối kì", {
                type: "success",
                autoClose: 1000,
              });
            }}
          >
            <i className="fa-solid fa-copy" />
          </div>
        </div>
      ),
    },
    {
      id: 11,
      label: "Tổng tiền chênh lệch:",
      component: (
        <CurrencyFormat
          value={amountDifference}
          thousandSeparator={true}
          displayType="text"
          isNumericString
          style={amountDifference >= 0 ? { color: "green" } : { color: "red" }}
        />
      ),
    },
  ];

  return (
    <Card className="px-2 py-1">
      <Form>
        {summaryFormItems.map((formItem) => {
          return (
            <Form.Group key={formItem.id} as={Row}>
              <Form.Label column xs="7" sm="7">
                {formItem.label}
              </Form.Label>
              <Col xs="5" sm="5" className="d-flex align-items-center">
                {formItem.component}
              </Col>
            </Form.Group>
          );
        })}
      </Form>
    </Card>
  );
};

export default SummaryForm;
