import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../admin/other/Check";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

class YearSchoolFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.datarole },
      isCheckGroup: [],
      startDate: this.props.datarole.start_date
        ? new Date(this.props.datarole.start_date * 1000)
        : new Date(),
      endDate: this.props.datarole.end_date
        ? new Date(this.props.datarole.end_date * 1000)
        : new Date(),
      err: {},
    };
  }

  componentDidMount() { }
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;

    let errIinput = { ...this.state.err };
    delete errIinput["year_name"];
    this.setState({ err: errIinput });
    this.setState({ dataInput: dataInput });
  };

  handleSelectGroup = (e) => {
    const { value, checked } = e.target;
    const idChildArr = e.target.dataset.id_child.split(",");

    if (idChildArr.length > 0) {
      const dataInput = { ...this.state.dataInput };

      dataInput.permission = [...dataInput.permission, ...idChildArr];
      this.setState({ dataInput: dataInput });

      if (!checked) {
        idChildArr.map((id, i) => {
          dataInput.permission = dataInput.permission.filter(
            (item) => item !== id
          );

          this.setState({ dataInput: dataInput });

          return id;
        });
      }
    }

    this.setState({
      isCheckGroup: [...this.state.isCheckGroup, String(value)],
    });

    if (!checked) {
      this.setState({
        isCheckGroup: this.state.isCheckGroup.filter(
          (item) => item !== String(value)
        ),
      });
    }
  };

  createRole = () => {
    let data = {
      year_name: this.state.dataInput.role_name || "",
      start_date: parseInt(
        new Date(this.state.startDate?.setHours(0, 0, 0)) / 1000
      ),
      end_date: parseInt(
        new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000
      ),
    };
    axios
      .post(`/school-year-create`, data, {
        headers: { "Content-Type": "application/json" },
      })

      .then((res) => {
        toast(`${res.data.messages}`, { type: "success", autoClose: 1000 });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response?.data?.messages });
      });
  };

  updateRole = () => {
    let data = {
      id: this.state.dataInput.id,
      year_name: this.state.dataInput.role_name || "",
      start_date: parseInt(
        new Date(this.state.startDate?.setHours(0, 0, 0)) / 1000
      ),
      end_date: parseInt(
        new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000
      ),
    };
    // let data = this.state.dataInput;
    swal({
      title: "Bạn muốn cập nhật năm học?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/school-year-update`, data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            toast(`${res.data.messages}`, { type: "success" });
            return this.props.submit();
          })
          .catch((error) => {
            this.setState({ err: error.response?.data?.messages });
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.dataInput.id === "") {
      this.createRole();
    } else {
      this.updateRole();
    }
  };

  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-calendar-days"></i> {other.modal.title}
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Form.Floating className="mb-1">
                <Form.Control
                  type="text"
                  name="role_name"
                  defaultValue={this.state.dataInput?.role_name || ""}
                  placeholder="Tên năm học"
                  onChange={this.handleInput}
                />
                <Form.Label>
                  Tên năm học<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err.year_name}
                </span>
              </Form.Floating>

              <center>Thời gian diễn ra<i className="text-danger">*</i></center>
              <div className="d-flex">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => {
                    delete this.state.err["start_date"];
                    this.setState({ startDate: date });
                  }}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.startDate}
                  className="form-control text-end"
                  placeholderText="Chọn ngày bắt đầu"
                  dateFormat="dd/MM/yyyy"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  -
                </span>

                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => {
                    delete this.state.err["end_date"];
                    this.setState({ endDate: date });
                  }}
                  selectsEnd
                  startDate={this.state.endDate}
                  endDate={this.state.endDate}
                  className="form-control text-end"
                  placeholderText="Chọn ngày kết thúc"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <Row>
                <Col>
                  <span className="text-form-err">
                    {this.state.err.start_date}
                  </span>
                </Col>
                <Col>
                  {" "}
                  <span className="text-form-err">
                    {this.state.err.end_date}
                  </span>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {other.modal.button ?
                <Button
                  size="sm"
                  variant="success"
                  type="submit"
                >
                  {other.modal.button}
                </Button>
                : <></>
              }
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default YearSchoolFormModal;
