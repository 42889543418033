import React, { Component } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Check from "../other/Check";

class EvaluationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.datarole },
      err: {},
      questions: [
        {
          question_content: "",
          answers: [{ answer_content: "", answer_score: 0 }],
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      questions:
        this.props?.datarole?.questions?.length > 0
          ? this.props?.datarole?.questions
          : [
            {
              question_content: "",
              answers: [{ answer_content: "", answer_score: 0 }],
            },
          ],
    });
  }
  addQuestion = () => {
    this.setState((prevState) => ({
      questions: [
        ...prevState.questions,
        {
          question_content: "",
          answers: [{ answer_content: "", answer_score: 0 }],
        },
      ],
    }));
  };
  handleQuestionContentChange = (index, event) => {
    const newQuestions = [...this.state.questions];
    newQuestions[index].question_content = event.target.value;
    this.setState({ questions: newQuestions });
  };
  handleAnswerChange = (qIndex, aIndex, field, value) => {
    const newQuestions = [...this.state.questions];
    newQuestions[qIndex].answers[aIndex][field] = value;
    this.setState({ questions: newQuestions });
  };

  addAnswer = (index) => {
    const newQuestions = [...this.state.questions];
    newQuestions[index]?.answers?.push({ answer_content: "", answer_score: 0 });
    this.setState({ questions: newQuestions });
  };
  removeQuestion = (index) => {
    this.setState((prevState) => ({
      questions: prevState.questions?.filter((_, qIndex) => qIndex !== index),
    }));
  };

  removeAnswer = (qIndex, aIndex) => {
    const newQuestions = [...this.state.questions];
    newQuestions[qIndex].answers = newQuestions[qIndex]?.answers?.filter(
      (_, index) => index !== aIndex
    );
    this.setState({ questions: newQuestions });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.type == "TM" || this.props.type == "")
      this.createEvaluation();
    else this.updateEvaluation();
  };

  createEvaluation = () => {
    let arrQ = this.state.questions
      ?.filter((e) => e.question_content != "")
      ?.map((item) => {
        return {
          question_content: item.question_content,
          answers: item.answers?.map((item2) => {
            return {
              answer_content: item2.answer_content,
              answer_score: item2.answer_score,
            };
          }),
        };
      });
    let payload = {
      period_name: this.state.dataInput?.period_name,
      start_date: this.state.dataInput?.start_date, // required
      end_date: this.state.dataInput?.end_date, // required
      is_status:
        this.state.dataInput?.is_status == 1
          ? "PUBLISHED"
          : this.state.dataInput?.is_status == 0
            ? "HIDDEN"
            : "DRAFT", // required, only accept: PUBLISHED, HIDDEN, DRAFT ->1:0:2
      questions: arrQ,
    };
    let data = {
      payload: payload,
    };

    axios
      .post("evaluation-period-create", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };
  updateEvaluation = () => {
    let arrQ = this.state.questions
      ?.filter((e) => e.question_content != "")
      ?.map((item) => {
        return {
          question_content: item.question_content,
          answers: item.answers?.map((item2) => {
            return {
              answer_content: item2.answer_content,
              answer_score: item2.answer_score,
            };
          }),
        };
      });
    let payload = {
      period_name: this.state.dataInput?.period_name,
      start_date: this.state.dataInput?.start_date, // required
      end_date: this.state.dataInput?.end_date, // required
      is_status:
        this.state.dataInput?.is_status == 1
          ? "PUBLISHED"
          : this.state.dataInput?.is_status == 0
            ? "HIDDEN"
            : "DRAFT", // required, only accept: PUBLISHED, HIDDEN, DRAFT ->1:0:2
      questions: arrQ,
    };
    let data = {
      payload: payload,
      id: this.state.dataInput?.id,
    };

    axios
      .put("evaluation-period-update", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }

  QuestionCard = () => {
    return (
      <>
        {/* <Form onSubmit={this.handleSubmit}> */}
        {this.state.questions.map((question, qIndex) => (
          <Col md={12}>
            <Card className="mb-4" key={qIndex}>
              <Card.Header as="h5">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <span>Câu Hỏi {qIndex + 1}</span>
                   */}
                  <Form.Group
                    controlId={`formQuestionContent-${qIndex}`}
                    className="d-flex align-items-center  justify-content-between me-1 w-100"
                  >
                    <span className="me-1">{qIndex + 1}.</span>

                    <Form.Control
                      type="text"
                      placeholder="Nhập nội dung câu hỏi"
                      value={question.question_content}
                      onChange={(event) => {
                        this.handleQuestionContentChange(qIndex, event);
                        delete this.state.err[
                          `payload.questions[${qIndex}].question_content`
                        ];
                      }}
                      required={question?.answers?.some(
                        (e) => e.answer_score > 0 || e.answer_content != ""
                      )}
                    />
                  </Form.Group>

                  {new Check().permission(['113']) ?
                    <div className="d-flex">
                      <Button
                        variant="outline-success"
                        onClick={this.addQuestion}
                        hidden={this.state.questions?.length != qIndex + 1}
                        title="Thêm câu hỏi"
                      >
                        <i className="fa-solid fa-plus" />
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          this.removeQuestion(qIndex);
                          delete this.state.err[
                            `payload.questions[${qIndex}].question_content`
                          ];
                        }}
                        className="ms-2"
                        hidden={this.state.questions?.length <= 1}
                        title="Xóa câu hỏi"
                      >
                        <i className="fa-solid fa-times" />
                      </Button>
                    </div>
                    : <></>}
                </div>
                <span className="text-form-err">
                  {
                    this.state.err[
                    `payload.questions[${qIndex}].question_content`
                    ]
                  }
                </span>
              </Card.Header>
              <Card.Body className="p-1">
                <ListGroup variant="flush ">
                  {question?.answers?.map((answer, aIndex) => (
                    <ListGroup.Item key={aIndex} className="mb-1 px-1">
                      <Form.Group controlId={`formAnswer-${qIndex}-${aIndex}`}>
                        {/* <Form.Label>{aIndex + 1}</Form.Label> */}
                        <div className="d-flex align-items-center">
                          <span className="me-1">{aIndex + 1}.</span>
                          <InputGroup className="me-2">
                            <InputGroup.Text>Câu trả lời</InputGroup.Text>
                            <Form.Control
                              type="text"
                              // placeholder={`Điểm`}
                              value={answer.answer_content}
                              onChange={(event) => {
                                this.handleAnswerChange(
                                  qIndex,
                                  aIndex,
                                  "answer_content",
                                  event.target.value
                                );

                                delete this.state.err[
                                  `payload.questions[${qIndex}].answers[${aIndex}].answer_content`
                                ];
                              }}
                              required={question.question_content != ""}
                            />
                          </InputGroup>
                          <InputGroup style={{ width: "42%" }}>
                            <InputGroup.Text>Điểm</InputGroup.Text>
                            <Form.Control
                              type="number"
                              // placeholder="Điểm"
                              className="text-end"
                              value={answer.answer_score}
                              onChange={(event) => {
                                delete this.state.err[
                                  `payload.questions[${qIndex}].answers[${aIndex}].answer_score`
                                ];
                                this.handleAnswerChange(
                                  qIndex,
                                  aIndex,
                                  "answer_score",
                                  parseInt(event.target.value, 10)
                                );
                              }}
                              required={question.question_content != ""}
                            />
                          </InputGroup>
                          {new Check().permission(['113']) ? <>
                            <Button
                              variant="outline-success"
                              onClick={() => this.addAnswer(qIndex)}
                              className="ms-2"
                              title="Thêm câu trả lời"
                              hidden={question?.answers?.length != aIndex + 1}
                            >
                              <i className="fa-solid fa-plus" />
                            </Button>
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                this.removeAnswer(qIndex, aIndex);
                                delete this.state.err[
                                  `payload.questions[${qIndex}].answers[${aIndex}].answer_score`
                                ];
                                delete this.state.err[
                                  `payload.questions[${qIndex}].answers[${aIndex}].answer_content`
                                ];
                              }}
                              className="ms-2"
                              title="Xóa câu trả lời"
                              hidden={question?.answers?.length <= 1}
                            >
                              <i className="fa-solid fa-times" />
                            </Button>
                          </>
                            : <></>}
                        </div>
                        <Row>
                          <Col md={7}>
                            <span className="text-form-err">
                              {
                                this.state.err[
                                `payload.questions[${qIndex}].answers[${aIndex}].answer_content`
                                ]
                              }
                            </span>
                          </Col>
                          <Col>
                            <span className="text-form-err">
                              {
                                this.state.err[
                                `payload.questions[${qIndex}].answers[${aIndex}].answer_score`
                                ]
                              }
                            </span>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
    );
  };
  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.type == "TM" || this.props.type == ""
                ? "Thêm kỳ đánh giá"
                : "Chi tiết kỳ đánh giá"}
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="role_name"
                      defaultValue={this.state.dataInput?.period_name || ""}
                      onChange={(event) => {
                        let data = { ...this.state.dataInput };
                        data.period_name = event.target.value;
                        this.setState({ dataInput: data });
                        delete this.state.err[`payload.period_name`];
                      }}
                      placeholder="   Tên kỳ đánh giá"
                      required
                    />
                    <Form.Label>
                      Tên kỳ đánh giá<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`payload.period_name`]}
                    </span>
                  </Form.Floating>
                </Col>

                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput?.is_status}
                      onChange={(event) => {
                        let data = { ...this.state.dataInput };
                        data.is_status = event.target.value;
                        delete this.state.err[`payload.is_status`];
                        this.setState({ dataInput: data });
                      }}
                      name="is_status"
                      required
                    >
                      <option value="">--Chọn--</option>
                      <option value="1">Đang diễn ra</option>
                      <option value="0">Đang khóa</option>
                      <option value="2">Bản nháp</option>
                    </Form.Select>
                    <Form.Label>
                      Trạng thái
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">
                      {this.state.err[`payload.is_status`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      defaultValue={moment(
                        new Date(this.state.dataInput?.start_date * 1000)
                      ).format("YYYY-MM-DD")}
                      name="start_date"
                      onChange={(e) => {
                        let data = { ...this.state.dataInput };
                        data.start_date = new Date(e.target.value) / 1000;
                        this.setState({ dataInput: data });
                        delete this.state.err[`payload.start_date`];
                      }}
                    />
                    <Form.Label>
                      Ngày bắt đầu
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`payload.start_date`]}
                    </span>
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      defaultValue={moment(
                        new Date(this.state.dataInput?.end_date * 1000)
                      ).format("YYYY-MM-DD")}
                      name="end_date"
                      onChange={(e) => {
                        let data = { ...this.state.dataInput };
                        data.end_date = new Date(e.target.value) / 1000;
                        this.setState({ dataInput: data });
                        delete this.state.err[`payload.end_date`];
                      }}
                    />
                    <Form.Label>
                      Ngày kết thúc
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`payload.end_date`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              <p className="text-center fw-bold">DANH SÁCH CÂU HỎI</p>
              <Row>{this.QuestionCard()}</Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {new Check().permission(['113']) ?
                <Button size="sm" variant="success" type="submit">
                  <i className="fa-solid fa-check"></i> Lưu
                </Button>
                : <></>}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EvaluationModal;
