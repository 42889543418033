import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Col, Form
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import FormatData from "../FormatData";

export default function SupplierIndex2() {
  const [show, setShow] = useState(false);
  const [listIngredient, setListIngredient] = useState([]);
  const [listIngredientShow, setListIngredientShow] = useState([]);
  const [listHisIngredient, setListHistIngredient] = useState([]);

  const [isLoading, setIsLoading] = useState(true)
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    document.title = "Lịch sử nhà cung cấp";

    getListIngredient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch API
  const getListIngredient = () => {
    axios
      .post("/viewIngredent?ingredent_type=1,2,3")
      .then((res) => {
        if (res.data.status) {
          setListIngredient(res.data.data);
          setListIngredientShow(res.data.data);

          setIsLoading(false);

          handleSearchIngredient(res.data.data.sort(function (a, b) {
            return (b.has_supplier.created_at - a.has_supplier.created_at)
          }), searchText);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getListHisIngredient = (idIngredient) => {
    axios
      .post(`/hisPriceIngredentby?id_ingredent=${idIngredient}`)
      .then((res) => {
        if (res.data.status) {
          setListHistIngredient(res.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Logic
  const handleSearchIngredient = (list, text) => {
    setSearchText(text);

    let filterList = [];

    filterList = list.filter((e) => toSlug(e.ingredent_name).includes(toSlug(text)));

    if (text === "") {
      filterList = list;
    }

    setListIngredientShow(filterList);
  }

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  // Render
  const Loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>
  }
  const formatNumber=(num)=> {
    if (num != null && num != '')
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  const getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
      // + " " +date.getHours() +':' +date.getMinutes()+':'+date.getHours()
      ;
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  }
  const DataTable = () => {
    const columns = [
      {
        name: "Tên nguyên liệu",
        selector: (row) => row.ingredent_name,
      },
      {
        name: "Giá (VNĐ)",
        selector: (row) => row.ingredent_price,
        right: true,
      }, {
        name: "Ngày cập nhật",
        selector: (row) => row.updated_at,
        // right: true,
        center: true
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];


    const data = listIngredientShow.map(ingredient => {
      return {
        ingredent_name: ingredient.ingredent_name,
        ingredent_price: formatNumber(ingredient.has_supplier?.ingredent_price ),
        updated_at: getDate(ingredient.has_supplier?.created_at),
        setting: (
          <Button
            // variant="danger"
            size="sm"
            onClick={() => {
              setShow(true);
              getListHisIngredient(ingredient.id)
            }}
          >
            Lịch sử giá
          </Button>
        )
      }
    })

    return (
      <DataTables data={data} columns={columns} />
    );
  };

  const Hissupplierview = () => {
    return (
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        keyboard={false}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Lịch sử giá</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Nhà cung cấp</th>
                <th>Giá</th>
                <th>Ngày nhập</th>
                <th>Nhập bởi</th>
              </tr>
            </thead>
            <tbody>
              {listHisIngredient.sort(function (a, b) {
                return b.created_at - a.created_at;
              })
                .map((item, i) => {
                  let created_at = new FormatData().date(item.created_at);
                  let ingredent_price = new FormatData().currency(
                    item.ingredent_price
                  );
                  return (
                    <tr key={i}>
                      <td>{item.supplier_name}</td>
                      <td>{ingredent_price}</td>
                      <td>{created_at}</td>
                      <td>{item.user_name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Nhà cung cấp</Breadcrumb.Item>
        <Breadcrumb.Item active>Lịch sử</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <h5>
            <i className="fas fa-list me-1" /> Lịch sử giá của nhà cung cấp
          </h5>
        </Card.Header>
        <Card.Body>
          {
            isLoading
              ?
              Loading()
              :
              listIngredient.length !== 0 ?
                <>
                  <Col >
                    <Form.Control style={{ height: 39 }}
                      type="text"
                      placeholder="Tìm nguyên liệu"
                      onChange={
                        (e) => {
                          handleSearchIngredient(listIngredient, e.target.value.trim())
                        }
                      }
                    />
                  </Col>
                  <DataTable />
                </>
                :
                <p className="text-center">Không có dữ liệu...</p>
          }
        </Card.Body>
      </Card>
      <Hissupplierview />
    </Container>
  );
}
