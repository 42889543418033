import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Form,
  Modal, Col, Row, InputGroup, Table
} from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import Select from 'react-select'
import Check from "../other/Check";
import moment from "moment/moment";
import * as XLSX from "xlsx-js-style";

export default function UserIndex() {
  useEffect(() => {
    document.title = "Nhân viên";
    getlistrole();
    getEthnic();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [show, setShow] = useState(false);
  const [type, settype] = useState("");
  const [listuser, setlistuser] = useState([]);
  const [userSelectedFile, setuserSelectedFile] = useState(null);
  const [isAddStaffListLoading, setisAddStaffListLoading] = useState(null);
  const [listrole, setlistrole] = useState([]);
  const [listEthnic, setlistEthnic] = useState([]);
  const [id_user, setid_user] = useState("");
  const [user, setuser] = useState({});
  const [selectedRows, setselectedRows] = useState([]);
  const [alertShow, setalertShow] = useState(false);
  const [RoleFilter, setRoleFilter] = useState({ value: 0, label: "Tất cả chức vụ" });
  const [classsearch, setclasssearch] = useState([]);
  const [RoleSearch, setRoleSearch] = useState([]);
  const [checkload, setCheckload] = useState(true);
  const roleRank = localStorage.getItem("role_rank");

  // Fetch API
  const getlistuser = () => {
    axios.get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          setlistuser(res.data.data);
          setCheckload(false)
          handleClassbySelectOrSearch(res.data.data, RoleFilter, "")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getlistrole = () => {
    axios.post("/viewRole")
      .then((res) => {
        if (res.data.status) {
          setlistrole(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEthnic = () => {
    axios.get('/viewEthnic').then(res => {
      if (res.data.status === true) {
        setlistEthnic(res.data.data);
        getlistuser();
      }
    }
    )
  }

  const handleClose = () => {
    setShow(false);
    settype("");
    setuser({});
  };

  function activeuser(item, isactive, event) {
    swal({
      title: `${isactive === 0
        ? "Xác nhận kích hoạt nhân viên?"
        : "Bạn muốn xóa nhân viên?"
        }`,
      text: `"${item.user_name}"`,
      icon: `${isactive === 0 ? "success" : "error"}`,
      buttons: ['Đóng', 'Xóa'],
      dangerMode: isactive !== 0 ? true : false,
      successMode: isactive === 0 ? true : false,
    }).then((ok) => {
      if (ok) {
        axios.post(`/activeUser?id_user=${item.id}`)
          .then((res) => {
            getlistuser();
            toast(`${res.data.data.mess}`, {
              type: "success",
              autoClose: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function getdetailuser(id) {
    axios.post(`/detailUser?id_user=${id}`)
      .then((res) => {
        if (res.data.status) {
          setuser(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Handle Logic
  const selectUserByRole = (value) => {
    setRoleFilter(value);
    setclasssearch("");
    handleClassbySelectOrSearch(listuser, value, "");
  }

  const handleSearchRole = (event) => {
    setclasssearch(event.target.value)

    handleClassbySelectOrSearch(listuser, RoleFilter, event.target.value.trim())
  }

  const handleClassbySelectOrSearch = (listuser, value, text) => {
    let root_ = listuser;

    if (value.value !== 0) {
      if (text) {
        root_ = listuser.filter((e) => e.id_role === value.value &&
          toSlug(e.user_name).includes(toSlug(text)));
      } else {
        root_ = listuser.filter((e) => e.id_role === value.value);
      }

    } else {
      if (text) { root_ = listuser.filter((e) => toSlug(e.user_name).includes(toSlug(text))); }
    }

    // setRoleSearch(root_.filter(e => e.is_rank > Number(localStorage.getItem('role_rank'))));
    setRoleSearch(root_);
  }

  const handleImportStaffListExcel = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('student_excel', userSelectedFile);

    setisAddStaffListLoading(true);

    axios.post(`/importUser`, bodyFormData)
      .then((res) => {
        if (res.data.status === true) {
          this.getStudentsData();

          toast("Lưu thành công", {
            type: "success",
            autoClose: 2000,
          });

          setisAddStaffListLoading(false);
          setuserSelectedFile(null);
          document.getElementById("stafflistFile").value = "";

        } else {
          toast(res.data.data.mess, {
            type: "error",
            autoClose: 2000,
          });

          setisAddStaffListLoading(false);
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  const xport = () => {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, listStaff(), "Nhân viên");

    XLSX.writeFile(wb, "Danh sách nhân viên.xlsx");
  }

  const listStaff = () => {
    const table = document.getElementById("list-staff-table");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true })

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center"
      }
    };

    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center"
      }
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center"
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        }
      };

      return alpha;
    })

    for (let i = 0; i < listuser.filter(user => Number(user.is_active) === 1).length; i++) {

      colAlpha.map(alpha => {
        if (ws[`${alpha}${4 + i}`].v === "empty") {
          ws[`${alpha}${4 + i}`].v = ' '
        }

        ws[`${alpha}${4 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          }
        };

        return alpha;
      })
    }

    ws['!cols'] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 20 },
      { wch: 10 },
      { wch: 8 },
      { wch: 15 },
      { wch: 7 },
      { wch: 11 },
      { wch: 15 },
      { wch: 25 },
      { wch: 25 },
    ];

    ws['!rows'] = [{ hpt: 30 }, {}];

    return ws;
  }

  const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  // Render
  const loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }

  const RenderSelectedRows = () => {
    let num = selectedRows.length;
    if (num > 0 && alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => setalertShow(false)}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  };

  const DataTable = () => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.user_name,
        sortable: true,
        cell: (row) =>
          <div style={{ width: "100%" }} className="text-wrap">

            {row.user_name}</div>,
        style: { div: { width: '200px !important' } }
      },
      {
        name: "Chức vụ",
        selector: (row) => row.role_name,
        sortable: true,
        cell: (row) =>
          <div style={{ width: "100%" }} className="text-wrap">

            {row.role_name}</div>,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      setselectedRows(items);
      setalertShow(true);
    };

    if (RoleSearch.length > 0) {
      RoleSearch.map((item, i) => {
        data.push({
          id: item.id,
          user_name: item.user_name,
          role_name: item.role_name,
          setting:
            (
              <>
                {new Check().permission(["2", "4"], "or") ?
                  <Button variant="warning me-2" size="sm"
                    title="Chi tiết nhân viên"
                    onClick={() => {
                      setid_user(item.id);
                      getdetailuser(item.id);
                      settype("CN");
                      setShow(true);
                    }}
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </Button>
                  : null}

                {item.is_rank === 1
                  ? <></>
                  : new Check().permission(['5'])
                    ?
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => activeuser(item)}
                      title="Xóa nhân viên"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </Button>
                    : null}
              </>
            ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  };

  function Formuser() {
    const [identifier, setidentifier] = useState(user.user_identifier);
    const [username, setusername] = useState(user.user_name);
    const [gender, setgender] = useState(user.user_gender);
    const [birthday, setbirthday] = useState(user.user_birthday || new Date());
    const [ethnic, setethnic] = useState(user.user_ethnic);
    const [phone, setphone] = useState(user.user_phone || "");
    const [contract, setcontract] = useState(user.user_contract || "");
    const [diploma, setdiploma] = useState(user.user_diploma || "");
    const [account, setaccount] = useState(user.user_account || "");
    const [password, setpassword] = useState("");
    const [is_meal, setIs_meal] = useState(user.is_meal === undefined ? 0 : user.is_meal)
    const [id_role, setid_role] = useState(user.role ? user.role.role_id : "");

    let roleid = user.role ? user.role.role_id : "";

    function createuser() {
      axios.post(`/createUser`, null, {
        params: {
          user_identifier: identifier,
          user_name: username,
          user_gender: gender,
          user_birthday: birthday,
          user_ethnic: ethnic,
          user_phone: phone,
          user_contract: contract,
          user_diploma: diploma,
          user_account: account,
          user_password: password,
          id_role: id_role,
          is_meal: is_meal
        }
      })
        .then((res) => {
          if (res.data.status) {
            getlistuser();
            handleClose();
            setid_user("");
            toast(`${res.data.data.mess}`, {
              type: "success",
              autoClose: 1000,
            });
          } else {
            toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function updateuser() {
      swal({
        title: "Bạn muốn cập nhật nhân viên?",
        icon: 'warning',
        buttons: ['Đóng', 'Cập nhật'],
        successMode: true,
      }).then((ok) => {
        if (ok) {
          axios.post(`/updateUser`, null, {
            params: {
              id_user: id_user,
              user_identifier: identifier,
              user_name: username,
              user_gender: gender,
              user_birthday: birthday,
              user_ethnic: ethnic,
              user_phone: phone,
              user_contract: contract,
              user_diploma: diploma,
              user_account: account,
              user_password: password,
              id_role: id_role,
              is_meal: is_meal
            }
          })
            .then((res) => {
              if (res.data.status) {
                getlistuser();
                handleClose();
                setid_user("");
                toast(`${res.data.data.mess}`, {
                  type: "success",
                  autoClose: 1000,
                });
              } else {
                toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }

    const handleSubmit = (e) => {
      e.preventDefault();

      if (identifier.length > 35) {
        toast("Mã định danh không được nhiều hơn 35 kí tự", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }

      if (phone !== "") {
        if (!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/)) {
          toast("Sai định dạng điện thoại", {
            type: "error",
            autoClose: 1000,
          });
          return;
        }
      }

      if (Number(moment(moment(birthday * 1000), "YYYY").fromNow().split(" ")[0]) < 18) {
        toast("Sai ngày sinh không hợp lệ", {
          type: "error",
          autoClose: 1000,
        });
        return;
      }

      if (type === "TM") {
        createuser();
      } else {
        updateuser();
      }
    }


    return (
      <Modal show={show} size="md" onHide={handleClose} backdrop="static" keyboard={false} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-chalkboard-user"></i>&nbsp;
            {type === "TM" ? "Thêm nhân viên" : "Chi tiết nhân viên"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>

            <Form.Floating className="mb-3">
              <Form.Control type="text" onChange={(e) => setidentifier(e.target.value)} defaultValue={user.user_identifier} placeholder="Mã định danh Bộ GD&ĐT" required />
              <Form.Label>
                Mã định danh Bộ GD&ĐT<i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>

            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setusername(e.target.value)} defaultValue={user.user_name} placeholder="Họ và tên" required />
                  <Form.Label>Họ và tên<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={user.user_gender}
                    onChange={(e) => {
                      setgender(e.target.value)
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    <option value="1">Nam</option>
                    <option value="2">Nữ</option>
                    <option value="3">Khác</option>
                  </Form.Select>
                  <Form.Label>
                    Giới tính<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={user.user_ethnic}
                    onChange={(e) => {
                      setethnic(e.target.value)
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    {
                      listEthnic.length > 0
                        ? listEthnic.map(ethnic_ => {
                          return <option key={ethnic_.id} value={ethnic_.id}>{ethnic_.ethnic_name}</option>
                        })
                        : null}
                  </Form.Select>
                  <Form.Label>
                    Dân tộc<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>


            <Row>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Control className="form-control" defaultValue={phone} onChange={(e) => { setphone(e.target.value) }} placeholder="Số điện thoại" />
                  <Form.Label>Số điện thoại</Form.Label>
                </Form.Floating>
              </Col>

              <Col md={4}>
                <Form.Floating className="mb-3">
                  <input
                    type="date"
                    className="form-control"
                    required
                    defaultValue={moment(new Date(user.user_birthday * 1000)).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setbirthday(new Date(e.target.value).getTime() / 1000)
                    }}
                  />
                  <Form.Label>
                    Ngày sinh<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>

              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Select onChange={(e) => setid_role(e.target.value)} defaultValue={roleid} required>
                    <option value="">--Chọn--</option>
                    {listrole.map((role) => {
                      return <option key={role.id} value={role.id}>
                        {role.role_name}
                      </option>
                    })}
                  </Form.Select>
                  <Form.Label>Chức vụ<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setcontract(e.target.value)} defaultValue={user.user_contract} placeholder="Hình thức hợp đồng" />
                  <Form.Label>
                    Hình thức hợp đồng
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setdiploma(e.target.value)} defaultValue={user.user_diploma} placeholder="Trình độ chuyên môn" />
                  <Form.Label>
                    Trình độ chuyên môn
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setaccount(e.target.value)} defaultValue={user.user_account} placeholder="Tài khoản" />
                  <Form.Label>Tài khoản<i className="text-danger"></i></Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="new-password" onChange={(e) => setpassword(e.target.value)} defaultValue={user.password}
                    placeholder="Mật khẩu" />
                  <Form.Label>Mật khẩu<i className="text-danger"></i></Form.Label>
                </Form.Floating>
              </Col>
            </Row>


            <Form.Check className="mb-3" type="checkbox" label="Không ăn ở trường"
              name="is_meal"
              onChange={(e) => setIs_meal(e.target.checked ? 1 : 0)}
              checked={is_meal}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>

            {type === "TM" ?
              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
              : user.is_author === 1 && roleRank > 1
                ? <></>
                : new Check().permission(["4"])
                  ?
                  <Button size="sm" type="submit" variant="success">
                    <i className="fa-solid fa-check"></i> Cập nhật
                  </Button>
                  : <></>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  const renderSearchFilter = () => {
    let options = []
    listrole.map((item) => {
      options.push({
        value: item.id,
        label: item.role_name
      })

      return item;
    },
      options.unshift({ value: 0, label: "Tất cả chức vụ" })
    )

    return (
      <Row >
        <Col md={3} sm={6} className="mb-2">
          <InputGroup >
            <Form.Control style={{ height: '39px' }} onChange={(event) => handleSearchRole(event)} value={classsearch} placeholder="Nhập tên nhân viên" />
            {/* <InputGroup.Text><i className="fa-solid fa-search me-1" /></InputGroup.Text> */}
          </InputGroup >
        </Col>

        <Col md={3} sm={6} className="mb-2" style={{ zIndex: '2' }}>
          <Select
            defaultValue={options[0]}
            options={options}
            placeholder="Chọn nhóm chức vụ"
            value={RoleFilter}
            onChange={(choice) => {
              selectUserByRole(choice);
            }}
          />
        </Col>
      </Row>
    )
  }

  const renderStaffList = () => {

    const currentMonth = new Date();

    let startYear = 0;
    let endYear = 0;

    if (currentMonth.getMonth() + 1 >= 7) {
      startYear = currentMonth.getFullYear();
      endYear = currentMonth.getFullYear() + 1;
    } else {
      startYear = currentMonth.getFullYear() - 1;
      endYear = currentMonth.getFullYear();
    }

    return (
      <div
        className="d-none"
      >
        <Table id="list-staff-table">
          <thead>
            <tr>
              <th colSpan="11">DANH SÁCH NHÂN VIÊN</th>
            </tr>
            <tr>
              <th colSpan="11">Năm học {startYear} - {endYear}</th>
            </tr>
            <tr className="text-center">
              <th>STT</th>
              <th>Mã định danh Bộ GD&ĐT</th>
              <th>Họ tên</th>
              <th>Ngày sinh</th>
              <th>Giới tính</th>
              <th>Trạng thái</th>
              <th>Dân tộc</th>
              <th>Điện thoại</th>
              <th>Chức vụ</th>
              <th>Hình thức hợp đồng</th>
              <th>T.Độ chuyên môn nghiệp vụ</th>
            </tr>
          </thead>
          <tbody>
            {
              listuser?.filter(user => Number(user.is_active) === 1).map((user, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{user.user_identifier}</td>
                    <td>{user.user_name}</td>
                    <td className="text-center">{user ? stringLastDate(new Date(user.user_birthday * 1000)) : "empty"}</td>
                    <td>{["Nam", "Nữ", "Khác"].filter((_, index) => index + 1 === Number(user.user_gender))[0]}</td>
                    <td>{user.is_active === 1 ? "Đang làm việc" : "empty"}</td>
                    <td>{user ? listEthnic?.filter(item => Number(item.id) === Number(user.user_ethnic))[0]?.ethnic_name : "empty"}</td>
                    <td className="text-center">{user.user_phone !== "" ? user.user_phone : "empty"}</td>
                    <td>{user.role_name !== "" ? user.role_name : "empty"}</td>
                    <td>{user.user_contract !== "" ? user.user_contract : "empty"}</td>
                    <td>{user.user_diploma !== "" ? user.user_diploma : "empty"}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }

  return (
    <Container fluid>

      <RenderSelectedRows />

      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Danh sách nhân viên
            </Card.Title>
            {new Check().permission(['3']) ?
              <div>
                {/* <label className="btn btn-success me-3 mt-3 mt-md-0" style={{ fontSize: 11 }} htmlFor="stafflistFile">
                  <i className="fas fa-file-import"></i> Nhập DSNV
                </label>
                <input
                  type="file"
                  id="stafflistFile"
                  className="d-none"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  multiple={false}
                  onChange={(e) => handleSelectFile(e)}
                  disabled={isAddStaffListLoading}
                /> */}

                {
                  userSelectedFile
                    ?
                    <>
                      <label style={{ minWidth: 100, textAlign: "center", verticalAlign: "middle" }} className="text-truncate me-3 mt-3 mt-md-0">Đã chọn: {userSelectedFile.name}</label>

                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-3 mt-3 mt-md-0"
                        onClick={() => {
                          handleImportStaffListExcel();
                        }}
                        disabled={isAddStaffListLoading}
                      >
                        Lưu
                      </Button>

                      <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        className="me-3 mt-3 mt-md-0"
                        onClick={() => {
                          document.getElementById("stafflistFile").value = "";
                          setuserSelectedFile(null);
                        }}
                        disabled={isAddStaffListLoading}
                      >
                        Hủy
                      </Button>
                    </>
                    :
                    ""
                }

                <Button type="button" className="me-3 mt-3 mt-md-0" variant="success" size="sm" onClick={() => xport()}>
                  <i className="fa-regular fa-cloud-arrow-down"></i> Xuất DSNV
                </Button>

                {/* <Button type="button" className="me-3 mt-3 mt-md-0" variant="success" size="sm" href='/File mẫu danh sách nhân viên.xlsx' target="_blank" download>
                  <i className="fa-solid fa-download"></i> Tải file mẫu
                </Button> */}

                <Button type="button" className="mt-3 mt-md-0" variant="success" size="sm" onClick={() => { settype("TM"); setShow(true); }}>
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              </div>
              : null}
          </div>
        </Card.Header>
        <Card.Body>
          {renderSearchFilter()}
          {
            checkload ? loading() : <DataTable />
          }
          {renderStaffList()}
        </Card.Body>
      </Card>
      {
        show ? <Formuser /> : ""
      }
    </Container>
  );
}
