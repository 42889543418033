import axios from "axios";
import React, { Component } from "react";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
	Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import Select from 'react-select';
import swal from "sweetalert";

const exportType = [
	{ value: 7, label: "Sử dụng" },
	{ value: 3, label: "Hết hạn" },
	{ value: 4, label: "Bị hỏng" },
	{ value: 6, label: "Khác" },
]

class ExportMaterial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listmaterial: [],
			textSearch: "", 
            listmaterial_search: [],
			selectedMaterials: [],

			listunit: [],

			isLoadding: false,
		};
	}

	async componentDidMount() {
		document.title = "Vật tư ";

		this.getStoreData();
		this.getUnit();
	}

	//#region Fetch API
	getStoreData = () => {
		this.setState({ 
			isLoadding: true
		});

		axios
			.post(`/viewInventory?ingredent_type=3&limit=200`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ 
						listmaterial: res.data.data,
						isLoadding: false
					});
					this.handleIngredentSearch(res.data.data, this.state.textSearch)
				}
			});
	};

	getUnit = (limit = 1000) => {
		axios.post(`/viewUnit?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ listunit: res.data.data });
			}
		});
	};

	exportMaterial = () => {
		axios
			.post(
				`/exportInventoryIngerdent`, null,
				{
					params: {
						ingredents: JSON.stringify(this.state.selectedMaterials.map(material => {
							return{
								id_ingredent: material.id,
								amount_his: Number(material.amount),
								type_note: material.reasonType.value,
								note: material.reasonText
							}
						}))
					}
				}
			)
			.then((res) => {
				if (res.data.status === true) {
					this.getStoreData();

					this.setState({ 
						selectedMaterials: [] 
					});
					toast("Xuất kho thành công", { type: "success", autoClose: 1000 });

				} else {
					toast(res.data.data.mess, { type: "error", autoClose: 1000 });
				}
			});
	}
	//#endregion

	//#region Handle Logic
	handleIngredentSearch = (root, text) => {
		let root_ = root;

		if (text !== "") {
			root_ = this.state.listmaterial?.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text)));
		}

		this.setState({
			textSearch: text,
			listmaterial_search: root_
		})
	}

	handleSelectMaterial = (item) => {
		if(this.state.selectedMaterials.some(material => Number(material.id) === Number(item.id))){
			return;
		}

		const newSelectMaterial = this.state.selectedMaterials;

		newSelectMaterial.push({
			id: item.id,
			ingredent_name: item.ingredent_name,
			amount: 1,
			reasonType: { value: 7, label: "Sử dụng" },
			reasonText: "",
		})

		this.setState({
			selectedMaterials: newSelectMaterial
		})
	}

	handleUnselectMaterial = (id) => {
		this.setState({
			selectedMaterials: this.state.selectedMaterials.filter(item => Number(item.id) !== Number(id))
		})
	}

	handleExportMaterial = () => {
		swal({
            title: `Xác nhận`,
            text: `Bạn muốn xuất kho những vật tư này`,
            icon: "info",
            buttons: ["Đóng", "Xuất"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                this.exportMaterial();
            }
        });
	}

	toSlug(str) {
		str = str.toLowerCase(); // Chuyển hết sang chữ thường       
		// xóa dấu
		str = str
			.normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
			.replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
		str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
		str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
		return str;
	}
	//#endregion

	// Render
    loading() {
		return <Card>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
					<span className="sr-only">Loading...</span>
				</div>
			</Card.Body>
		</Card>
	}

    renderMaterialList(){
		const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,      
			maximumFractionDigits: 2,
		});
		
        return(
            <Card>
                <Card.Header>
					<Card.Title>
						<i className="fas fa-list me-1"></i> Vật tư
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<Col>
						<Form.Control style={{ height: 39 }}
							type="text"
							placeholder="Tìm vật tư"
							className="mb-3"
							onChange={
								(e) => {
									this.handleIngredentSearch(this.state.listmaterial, e.target.value.trim())
								}
							}
						/>
					</Col>

					{   
                        this.state.isLoadding 
                        ? 
                            this.loading() 
                        :
						    this.state.listmaterial_search.length > 0 
                            ?
                                <Table striped size="sm" hover>
                                    <thead>
                                        <tr >
                                            <th>Vật tư</th>
                                            <th className="text-center" >ĐVT</th>
                                            <th className="text-center">SL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listmaterial_search.map((item) => {
                                            return (
                                                <tr key={item.id} style={{ cursor: 'pointer' }} onClick={() => this.handleSelectMaterial(item)}>
                                                    <td>{item.ingredent_name} </td>
                                                    <td className="text-center">
														{this.state.listunit.find(e => Number(e.id) === Number(item.id_unit))?.unit_name} 
													</td>
                                                    <td className="text-center">
														<CurrencyFormat value={formatter.format(item.amount)} displayType={'text'} thousandSeparator={true} isNumericString />
													</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table> 
                            : <p className="text-center">Không có dữ liệu ...</p>
					}

				</Card.Body>
            </Card>
        )
    }

    renderAddMaterial(){
		return(
			<Card>
				<Card.Header>
					<Card.Title>
						Xuất kho vật tư
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<Table>
						<thead>
							<tr>
								<th>Vật tư</th>
								<th>Số lượng</th>
								<th>Lý do</th>
								{
									this.state.selectedMaterials.some(materialItem => materialItem.reasonType.value === 6)
									? <th></th>
									: ""
								}
								<th className="text-center">Phương thức</th>
							</tr>
						</thead>
						<tbody>
							{
								this.state.selectedMaterials?.map(material => {
									return(
										<tr key={material.id}>
											<td>{material.ingredent_name}</td>
											<td>
												<CurrencyFormat 
													value={material.amount} 
													thousandSeparator={true} 
													onValueChange={(values) => {
														this.setState({
															selectedMaterials: this.state.selectedMaterials.map(materialItem => {
																if(Number(material.id) === Number(materialItem.id)){
																	return{
																		...materialItem,
																		amount: values.value
																	}
																}
																return materialItem;
															})
														})
													}}
													style={{maxWidth: "80%"}}
													className="form-control text-end"
													allowNegative={false}
													isNumericString
												/>
											</td>
											<td>
												<Select
													options={exportType}
													placeholder={<div>Nhập lý do xuất kho</div>}
													value={material.reasonType}
													onChange={(choice) => {
														this.setState({
															selectedMaterials: this.state.selectedMaterials.map(materialItem => {
																if(Number(material.id) === Number(materialItem.id)){
																	return{
																		...materialItem,
																		reasonType: choice
																	}
																}
																return materialItem;
															})
														})
													}}
													styles={{
														control: (baseStyles) => ({
														...baseStyles,
														maxWidth: '80%'
														}),
													}}
												/>
											</td>

											{
												this.state.selectedMaterials.some(materialItem => materialItem.reasonType.value === 6)
												?
													<td>
														{
															material.reasonType.value === 6
															?
																<Form.Control 
																	type="text"
																	value={material.reasonText}
																	placeholder="Nhập lý do"
																	onChange={(e) => {
																		this.setState({
																			selectedMaterials: this.state.selectedMaterials.map(materialItem => {
																				if(Number(material.id) === Number(materialItem.id)){
																					return{
																						...materialItem,
																						reasonText: e.target.value
																					}
																				}
																				return materialItem;
																			})
																		})
																	}}
																/>
															: ""
														}
													</td>
												: ""
											}

											<td className="text-center">
												<Button 
													size="sm"
													variant="danger" 
													title="Xóa" 
													onClick={() => this.handleUnselectMaterial(material.id)}
												>
													Xóa
												</Button>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</Table>

					{
						this.state.selectedMaterials.length !== 0
						?
							<Button
								style={{ float: "right" }}
								variant="primary"
								size="sm"
								onClick={() => this.handleExportMaterial()}
							>
								Xuất kho
							</Button>
						: ""
					}
				</Card.Body>
			</Card>
		)
    }

    render(){
        return(
            <Container fluid>
                <Breadcrumb className="mb-2 mt-2">
                    <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
                    <Breadcrumb.Item active>Xuất kho vật tư</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        {this.renderMaterialList()}
                    </Col>
                    <Col md={8}>
						{this.renderAddMaterial()}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ExportMaterial;