import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Modal, Button, Form, Card } from "react-bootstrap";

const errorTextStyle = {
	fontSize: 13,
	color: "red",
	marginTop: 6,
};

class UnitAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			unit_name: "",
			errorMessage: {
				unit_name: "",
			},
		};
	}

	// API
	createUnit = async () => {
		this.setState({
			errorMessage: {
				unit_name: "",
			},
		});

		var bodyFormData = new FormData();
		bodyFormData.append("unit_name", this.state.unit_name);

		await axios({
			method: "post",
			url: "unit-create",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					this.props.onHide(true);
				} else {
					this.setState({
						errorMessage: res.data.message,
					});
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	render() {
		const { onHide } = { ...this.props };

		return (
			<Modal show={this.props.show} onHide={onHide} size="sm" backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						<i className="fa-solid fa-weight-scale"></i> Thêm đơn vị
					</Modal.Title>
				</Modal.Header>

				<Card>
					<Card.Body>
						<Form
							id="unit-add-form"
							onSubmit={(e) => {
								e.preventDefault();
								this.createUnit();
							}}
						>
							<Form.Floating className="mt-2">
								<Form.Control
									type="text"
									name="unit_name"
									value={this.state.unit_name}
									onChange={(e) => {
										this.setState({
											unit_name: e.target.value,
										});
									}}
									placeholder="Đơn vị"
								/>
								<Form.Label>
									Đơn vị<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{this.state.errorMessage.unit_name && <p style={errorTextStyle}>{this.state.errorMessage.unit_name}</p>}
						</Form>
					</Card.Body>
				</Card>

				<Modal.Footer>
					<Button size="sm" variant="secondary" onClick={onHide}>
						<i className="fa-solid fa-times"></i> Đóng
					</Button>

					<Button size="sm" variant="success" type="submit" form="unit-add-form">
						<i className="fa-solid fa-check"></i> Lưu
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default UnitAdd;
