import * as XLSX from "xlsx-js-style";
import { ONE_ITEM_FOOD } from "../../../../constants";

const fontStyle = {
  name: "Times New Roman",
  sz: 9,
};

const MAX_WITH_A4_LANDSCAPE = 116;

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportMenu0 = (dayLength, timeLength) => {
  const table = document.getElementById("menu-table");
  var ws = XLSX.utils.table_to_sheet(table);

  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];

  var wscols = [
    { wch: 10 },
    { wch: 18 },
    { wch: 18 },
    { wch: 18 },
    { wch: 18 },
    { wch: 18 },
    { wch: 18 },
    { wch: 18 },
  ];
  const colsLength = dayLength;
  const rowsLength = timeLength;

  ws["!cols"] = wscols.slice(0, colsLength + 1);
  ws["!rows"] = [{ hpt: 30 }];

  // Style cells
  ws[`A1`].s = {
    font: {
      ...fontStyle,
      sz: 13.5,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`${colAlpha[Math.floor((colsLength + 1) / 2)]}1`].s = {
    font: {
      ...fontStyle,
      sz: 13.5,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  for (let i = 2; i < rowsLength + 3; i++) {
    ws[`A${i}`].s = {
      font: {
        ...fontStyle,
        sz: 11,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
  }

  colAlpha.slice(0, colsLength + 1).map((alpha) => {
    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        sz: 11,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    return alpha;
  });

  // Line break handling
  colAlpha.slice(1, colsLength + 1).map((alpha) => {
    for (let i = 3; i < rowsLength + 3; i++) {
      if (ws[`${alpha}${i}`.toString()].v === "empty") {
        ws[`${alpha}${i}`.toString()].v = " ";
      }

      ws[`${alpha}${i}`.toString()].s = {
        font: {
          ...fontStyle,
        },
        alignment: {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        },
        border: borderStyle,
      };
    }

    return alpha;
  });

  return ws;
};

const exportMenu1 = (
  dayLength,
  listTimes,
  listQuantitatives,
  selectedClassGroup
) => {
  const table = document.getElementById(`menu-export-excel-1`);
  var ws = XLSX.utils.table_to_sheet(table);

  const initColAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
  ];
  const colAlpha = initColAlpha.slice(0, dayLength * 2 + 1);
  
  let heightArray = [{ hpt: 40 }, { hpt: 30 }];

  // Header
  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`${colAlpha[Math.floor((dayLength * 2) / 2) + 1]}1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  //Merge Title
  const merge = [
    {
      s: { r: 0, c: 0 },
      e: { r: 0, c: Math.floor((dayLength * 2) / 2) },
    },
    {
      s: { r: 0, c: Math.floor((dayLength * 2) / 2) + 1 },
      e: { r: 0, c: dayLength * 2 },
    },
  ];

  //Format weekdays
  colAlpha.map((alpha) => {
    if (ws[`${alpha}2`].v === "empty") {
      ws[`${alpha}2`].v = " ";
    }

    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
      fill: { fgColor: { rgb: "B5EAD7" } },
    };

    return alpha;
  });

  //Merge row unit header
  for (let i = dayLength * 2 + 1; i > 2; i -= 2) {
    merge.push({
      s: { r: 1, c: i - 2 },
      e: { r: 1, c: i - 1 },
    });
  }

  // Body
  let totalLength = 3;

  listTimes.map((timeItem) => {
    const quantitativeTimes = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          quantitativeTimes.push(timeObj);
        }

        return timeObj;
      });
    });

    const listItemRows = quantitativeTimes.map((quantitativeTimeItem) => {
      let itemRow = [];
      let rowSpanLength = 0;

      quantitativeTimeItem.foods.map((foodItem) => {
        if (
          foodItem.groups.some(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.ingredents.length !== 0
          )
        ) {
          rowSpanLength += 1;
          rowSpanLength += foodItem.groups[0].ingredents.length;
        }

        return foodItem;
      });

      return {
        id: quantitativeTimeItem.id,
        items: itemRow,
        length: rowSpanLength !== 0 ? rowSpanLength : 1,
      };
    });

    const maxRowSpanLength = Math.max(
      ...listItemRows.map((itemRow) => itemRow.length)
    );

    merge.push({
      //merge Meal
      s: { r: totalLength - 1, c: 0 },
      e: { r: totalLength + maxRowSpanLength - 2, c: 0 },
    });

    heightArray[totalLength - 1] = { hpt: 30 }; //set height row Meal

    totalLength += maxRowSpanLength;

    return timeItem;
  });

  for (let i = 3; i < totalLength; i++) {
    //Format Ingredient
    colAlpha.map((alpha, alphaIndex) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: alphaIndex % 2 === 0 ? "center" : "start",
        },
        border: borderStyle,
      };
      return alpha;
    });

    //Format meal
    ws[`A${i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
  }

  let totalLengthTmp = 3;

  listTimes.map((timeItem) => {
    const quantitativeTimes = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          quantitativeTimes.push(timeObj);
        }

        return timeObj;
      });
    });

    const listItemRows = quantitativeTimes.map(
      (quantitativeTimeItem, quantitativeTimeItemIndex) => {
        let rowSpanLength = 0;

        quantitativeTimeItem.foods.map((foodItem) => {
          if (
            foodItem.groups.some(
              (groupItem) =>
                groupItem.id === selectedClassGroup.id &&
                groupItem.ingredents.length !== 0
            )
          ) {
            const startColFoodName = quantitativeTimeItemIndex * 2 + 1;
            const startRowFoodName = rowSpanLength + totalLengthTmp - 1;

            //Merge Food name
            merge.push({
              s: { r: startRowFoodName, c: startColFoodName },
              e: { r: startRowFoodName, c: startColFoodName + 1 },
            });

            //Format Food name
            ws[`${colAlpha[startColFoodName]}${startRowFoodName + 1}`].s = {
              font: { ...fontStyle, bold: true },
              alignment: {
                wrapText: true,
                vertical: "center",
                horizontal: "center",
              },
              fill: { fgColor: { rgb: "E0FEFE" } },
            };

            heightArray[startRowFoodName] = { hpt: 30 };

            rowSpanLength += 1;
            rowSpanLength += foodItem.groups[0].ingredents.length;
          }

          return foodItem;
        });

        return rowSpanLength !== 0 ? rowSpanLength : 1;
      }
    );

    const maxRowSpanLength = Math.max(
      ...listItemRows.map((itemRow) => itemRow)
    );
    totalLengthTmp += maxRowSpanLength;

    return timeItem;
  });

  const cols = [{ wch: 8 }];
  const widthDay = (MAX_WITH_A4_LANDSCAPE - 10) / dayLength;
  for (let i = 1; i < dayLength + 1; i++) {
    cols.push({ wch: (widthDay * 2) / 3 });
    cols.push({ wch: (widthDay * 1) / 3 });
  }

  ws["!cols"] = cols;
  ws["!rows"] = heightArray;
  ws["!merges"] = merge;

  return ws;
};

const exportMenu2 = (
  dayLength,
  listTimes,
  listQuantitatives,
  selectedClassGroup
) => {
  const table = document.getElementById(`menu-export-excel-2`);
  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  const initColAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
  ];
  const colAlpha = initColAlpha.slice(0, dayLength * 3 + 2);

  let heightArray = [{ hpt: 30 }, {}, { hpt: 30 }];

  // Format School Name Header
  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: "true",
    },
  };

  ws[`${colAlpha[5]}1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
  };

  ws[`${colAlpha[dayLength * 3 - 1]}1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
  };

  //Format Unit name header
  ws[`${colAlpha[dayLength * 3 - 1]}2`].s = {
    font: { ...fontStyle },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
  };

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
    { s: { r: 0, c: 5 }, e: { r: 0, c: dayLength * 3 - 2 } },
    { s: { r: 0, c: dayLength * 3 - 1 }, e: { r: 0, c: dayLength * 3 + 1 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
    { s: { r: 1, c: 5 }, e: { r: 1, c: dayLength * 3 - 2 } },
    { s: { r: 1, c: dayLength * 3 - 1 }, e: { r: 1, c: dayLength * 3 + 1 } },
  ];

  //Format weekdays
  colAlpha.map((alpha) => {
    if (ws[`${alpha}3`].v === "empty") {
      ws[`${alpha}3`].v = " ";
    }

    ws[`${alpha}3`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
      fill: { fgColor: { rgb: "B5EAD7" } },
    };

    return alpha;
  });

  //Meage Weekdays
  for (let i = dayLength * 3 + 2; i > 3; i -= 3) {
    merge.push({
      s: { r: 2, c: i - 3 },
      e: { r: 2, c: i - 1 },
    });
  }

  // Body
  let totalLength = 4;
  let totalLengthTmp = 4;

  const moneyFormat = "#,###,###,###";

  listTimes.map((timeItem) => {
    const quantitativeTimes = [];
    let categoryTimes = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          quantitativeTimes.push(timeObj);
          let filteredFoods = timeObj.foods.filter((foodItem) =>
            foodItem.groups.some(
              (groupItem) =>
                parseInt(groupItem.id) === selectedClassGroup.id &&
                groupItem.ingredents.length !== 0
            )
          );

          let categoryTimesToAdd = [];

          if (filteredFoods.length > 0) {
            categoryTimesToAdd = filteredFoods.map((foodItem) => {
              return {
                id_category: foodItem.id_category,
                id_category_bank: foodItem.id_category_bank,
                category_name: foodItem.category_name,
                count_meal: quantitativeItem?.costPerDays,
                more_meal:
                  foodItem?.groups?.filter(
                    (meal) => parseInt(meal.id) === selectedClassGroup?.id
                  )[0]?.more_meal ?? 0,
              };
            });
          }

          // Nếu k có category thì thêm category empty
          else {
            categoryTimesToAdd.push({
              id_category: "",
              id_category_bank: "",
              category_name: "empty",
              count_meal: [],
              more_meal: 0,
            });
          }

          categoryTimes = categoryTimes.concat(categoryTimesToAdd);
        }

        return timeObj;
      });
    });

    categoryTimes = [
      ...new Map(
        categoryTimes.map((item) => [item["id_category"], item])
      ).values(),
    ].sort(function (a, b) {
      return (
        (a.id_category_bank === null) - (b.id_category_bank === null) ||
        +(a.id_category_bank > b.id_category_bank) ||
        -(a.id_category_bank < b.id_category_bank)
      );
    });

    let totalCateLength = 0;
    let dataCate =
      categoryTimes?.filter((ca) => ca.id_category !== "").length > 0
        ? categoryTimes?.filter((ca) => ca.id_category !== "")
        : categoryTimes;

    categoryTimes = dataCate.map((categoryItem) => {
      const listItemRows = quantitativeTimes.map((quantitativeTimeItem) => {
        let rowSpanLength = 0;

        quantitativeTimeItem.foods
          .filter(
            (foodItem) => foodItem.id_category === categoryItem.id_category
          )
          .map((foodItem) => {
            if (
              foodItem.groups.some(
                (groupItem) =>
                  groupItem.id === selectedClassGroup.id &&
                  groupItem.ingredents.length !== 0
              )
            ) {
              rowSpanLength += 1;
              rowSpanLength += foodItem.groups[0].ingredents.length;
            }
            return foodItem;
          });
        return rowSpanLength !== 0 ? rowSpanLength : 1;
      });

      //Merge column Category Food
      const maxRowSpanLength = Math.max(
        ...listItemRows.map((itemRow) => itemRow)
      );
      merge.push({
        s: { r: totalLengthTmp - 1, c: 1 },
        e: { r: totalLengthTmp + maxRowSpanLength - 2, c: 1 },
      });

      totalLengthTmp += maxRowSpanLength;
      totalCateLength += maxRowSpanLength;

      return categoryItem;
    });

    //Merge column Meal
    merge.push({
      s: { r: totalLength - 1, c: 0 },
      e: { r: totalLength + totalCateLength - 2, c: 0 },
    });

    totalLength += totalCateLength;

    return timeItem;
  });

  for (let i = 4; i < totalLength; i++) {
    colAlpha.map((alpha, alphaIndex) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      if ((alphaIndex - 1) % 3 === 0) {
        ws[`${alpha}${i}`].z = moneyFormat;
        delete ws[`${alpha}${i}`].w;
        XLSX.utils.format_cell(ws[`${alpha}${i}`]);
      }

      //Format Ingredients
      ws[`${alpha}${i}`].s = {
        font: fontStyle,
        sz: 9,
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: (alphaIndex + 1) % 3 === 0 ? "left" : "center",
        },
        border: borderStyle,
      };

      return alpha;
    });

    ws[`A${i}`].s = {
      font: {
        ...fontStyle,
        sz: 9,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    ws[`B${i}`].s = {
      font: {
        ...fontStyle,
        sz: 9,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
  }

  let totalLengthClone = 4;
  let countIngredientItemsByMeal = [];

  listTimes.map((timeItem, indexTime) => {
    const quantitativeTimes = [];
    let categoryTimes = [];
    countIngredientItemsByMeal[indexTime] = 0;

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          quantitativeTimes.push(timeObj);
          categoryTimes = categoryTimes.concat(
            timeObj.foods
              .filter((foodItem) =>
                foodItem.groups.some(
                  (groupItem) =>
                    groupItem.id === selectedClassGroup.id &&
                    groupItem.ingredents.length !== 0
                )
              )
              .map((foodItem) => {
                return {
                  id_category: foodItem.id_category,
                  id_category_bank: foodItem.id_category_bank,
                  category_name: foodItem.category_name,
                };
              })
          );
        }

        return timeObj;
      });
    });

    if (quantitativeTimes.length > 0) {
      let countIngredient = 0;

      quantitativeTimes.map((quantitativeTimeItem) => {
        quantitativeTimeItem.foods.map((foodItem, foodIndex) => {
          if (
            foodItem.groups.some(
              (groupItem) =>
                groupItem.id === selectedClassGroup.id &&
                groupItem.ingredents.length > 0
            )
          ) {
            const ingredients =
              foodItem.groups.find(
                (groupItem) =>
                  groupItem.id === selectedClassGroup.id &&
                  groupItem.ingredents.length !== 0
              )?.ingredents || [];
            countIngredient += ingredients.length;
          }

          return foodItem;
        });

        return quantitativeTimeItem;
      });

      countIngredientItemsByMeal[indexTime] = countIngredient;
    }

    categoryTimes = [
      ...new Map(
        categoryTimes.map((item) => [item["id_category"], item])
      ).values(),
    ].sort(function (a, b) {
      return (
        (a.id_category_bank === null) - (b.id_category_bank === null) ||
        +(a.id_category_bank > b.id_category_bank) ||
        -(a.id_category_bank < b.id_category_bank)
      );
    });

    let totalCateLength = 0;

    categoryTimes = categoryTimes.map((categoryItem) => {
      const listItemRows = quantitativeTimes.map(
        (quantitativeTimeItem, quantitativeTimeItemIndex) => {
          let rowSpanLength = 0;

          quantitativeTimeItem.foods
            .filter(
              (foodItem) => foodItem.id_category === categoryItem.id_category
            )
            .map((foodItem) => {
              if (
                foodItem.groups.some(
                  (groupItem) =>
                    groupItem.id === selectedClassGroup.id &&
                    groupItem.ingredents.length !== 0
                )
              ) {
                //Merge Food name
                let startRowFoodName =
                  totalCateLength + totalLengthClone + rowSpanLength - 1;

                if (indexTime > 0) {
                  //find Meal not eat
                  let rowEmpty = 0;
                  for (let i = 0; i < indexTime; i++) {
                    rowEmpty += countIngredientItemsByMeal[i] === 0 ? 1 : 0;
                  }
                  startRowFoodName += rowEmpty;
                }

                merge.push({
                  s: {
                    r: startRowFoodName,
                    c: quantitativeTimeItemIndex * 3 + 2,
                  },
                  e: {
                    r: startRowFoodName,
                    c: quantitativeTimeItemIndex * 3 + 4,
                  },
                });

                // Format Food name
                ws[
                  `${colAlpha[quantitativeTimeItemIndex * 3 + 2]}${
                    startRowFoodName + 1
                  }`
                ].s = {
                  font: {
                    ...fontStyle,
                    sz: 9,
                    bold: true,
                  },
                  alignment: {
                    wrapText: true,
                    vertical: "center",
                    horizontal: "center",
                  },
                  fill: { fgColor: { rgb: "E0FEFE" } },
                };

                heightArray[startRowFoodName] = { hpt: 30 };

                rowSpanLength += 1;
                rowSpanLength += foodItem.groups[0].ingredents.length;
              }

              return foodItem;
            });

          return rowSpanLength !== 0 ? rowSpanLength : 1;
        }
      );

      const maxRowSpanLength = Math.max(
        ...listItemRows.map((itemRow) => itemRow)
      );
      totalCateLength += maxRowSpanLength;

      return categoryItem;
    });

    totalLengthClone += totalCateLength;

    return timeItem;
  });

  // Footer
  ws[`A${totalLength}`].s = {
    font: {
      ...fontStyle,
      sz: 9,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  //Format column footer
  ws[`${colAlpha[0]}${totalLength + 1}`].s = {
    font: {
      ...fontStyle,
      sz: 9,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[
    `${colAlpha[Math.ceil((dayLength * 3 + 2) / 2) + 1]}${totalLength + 1}`
  ].s = {
    font: {
      ...fontStyle,
      sz: 9,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  colAlpha.map((alpha) => {
    if (ws[`${alpha}${totalLength}`].v === "empty") {
      ws[`${alpha}${totalLength}`].v = " ";
    }

    return alpha;
  });

  merge.push({
    s: { r: totalLength, c: 0 },
    e: { r: totalLength, c: Math.ceil((dayLength * 3 + 2) / 2) },
  });

  merge.push({
    s: { r: totalLength, c: Math.ceil((dayLength * 3 + 2) / 2) + 1 },
    e: { r: totalLength, c: dayLength * 3 + 1 },
  });

  //END Format column footer

  const cols = [{ wch: 6 }, { wch: 6 }];
  const widthDay = (MAX_WITH_A4_LANDSCAPE - 14) / dayLength;

  for (let i = 2; i < dayLength * 3 + 2; i += 3) {
    cols.push({ wch: widthDay / 2 });
    cols.push({ wch: (widthDay * 1) / 4 });
    cols.push({ wch: (widthDay * 1) / 4 });
  }

  ws["!cols"] = cols;
  ws["!rows"] = heightArray;
  ws["!merges"] = merge;

  return ws;
};

const exportMenu3 = (
  dayLength,
  listTimes,
  listQuantitatives,
  selectedClassGroup
) => {
  const table = document.getElementById(`menu-export-excel-3`);
  var ws = XLSX.utils.table_to_sheet(table);

  const initColAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
  ];

  const colAlpha = initColAlpha.slice(0, dayLength * 2 + 1);

  const ROW_HEADER_QTY = 2;

  let heightArray = [{ hpt: 40 }, { hpt: 30 }];

  //Merge title
  const merge = [
    {
      s: { r: 0, c: 0 },
      e: { r: 0, c: Math.floor((dayLength * 2) / 2) },
    },
    {
      s: { r: 0, c: Math.floor((dayLength * 2) / 2) + 1 },
      e: { r: 0, c: dayLength * 2 },
    },
  ];

  //Merge weekdays
  for (let i = dayLength * 2 + 1; i > 2; i -= 2) {
    merge.push({
      s: { r: 1, c: i - 2 },
      e: { r: 1, c: i - 1 },
    });
  }

  // Body
  let totalLength = ROW_HEADER_QTY;
  let formatFoodName = [];
  let countItemTime = [];

  listTimes.map((timeItem, indexTime) => {
    countItemTime[indexTime] = 0;

    const quantitativeTimes = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          timeObj.indexTime = indexTime;
          quantitativeTimes.push(timeObj);
        }

        return timeObj;
      });
    });

    let checkItemRows = [];

    quantitativeTimes.map((quantitativeTimeItem) => {
      let itemRow = [];

      const filteredFoodDatas = quantitativeTimeItem?.foods
        .map((foodData) => {
          const filteredGroups = foodData.groups.filter(
            (group) => group.id === selectedClassGroup.id
          );
          return { ...foodData, groups: filteredGroups };
        })
        .filter((foodData) => foodData.groups.length > 0);

      filteredFoodDatas.map((foodItem, foodIndex) => {
        if (
          foodItem.groups.some(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.ingredents.length > 0
          )
        ) {
          const ingredients =
            foodItem.groups.find(
              (groupItem) =>
                groupItem.id === selectedClassGroup.id &&
                groupItem.ingredents.length !== 0
            )?.ingredents || [];

          itemRow.push({
            id_day: quantitativeTimeItem.id_day,
            id: foodItem.id,
            name: foodItem.food_name,
            keyFood: foodIndex,
            countIngredient: ingredients.length,
          });
        }

        return foodItem;
      });

      checkItemRows.push({
        id: quantitativeTimeItem.id,
        id_day: quantitativeTimeItem.id_day,
        items: itemRow,
      });

      return quantitativeTimeItem;
    });

    let listItemRows = [];
    let countItem = 0;

    quantitativeTimes.map((quantitativeTimeItem, indexDay) => {
      let itemRow = [];
      let startRowFood = {};
      let countIngredient = {};

      const filteredFoodDatas = quantitativeTimeItem?.foods
        .map((foodData) => {
          const filteredGroups = foodData.groups.filter(
            (group) => group.id === selectedClassGroup.id
          );
          return { ...foodData, groups: filteredGroups };
        })
        .filter((foodData) => foodData.groups.length > 0);

      filteredFoodDatas.map((foodItem, foodIndex) => {
        const ingredients =
          foodItem.groups.find(
            (groupItem) => groupItem.id === selectedClassGroup.id
          )?.ingredents || [];

        if (ingredients.length > 0) {
          let maxItemFoodWeek = 0;

          for (let i = 0; i <= foodIndex; i++) {
            let maxCountIngredientFood = 0;

            checkItemRows.forEach((row) => {
              row.items.forEach((item) => {
                if (
                  item.keyFood === i &&
                  item.countIngredient + ONE_ITEM_FOOD > maxCountIngredientFood
                ) {
                  maxCountIngredientFood = item.countIngredient + ONE_ITEM_FOOD;
                }
              });
            });

            maxItemFoodWeek += maxCountIngredientFood;
          }

          let maxItemFoodDay = ONE_ITEM_FOOD;

          checkItemRows.forEach((row) => {
            row.items.forEach((item) => {
              if (
                item.keyFood === foodIndex &&
                item.countIngredient + ONE_ITEM_FOOD > maxItemFoodDay
              ) {
                maxItemFoodDay = item.countIngredient + ONE_ITEM_FOOD;
              }
            });
          });

          countIngredient[foodIndex] = foodItem.groups[0].ingredents.length;
          startRowFood[foodIndex] = maxItemFoodWeek - maxItemFoodDay;

          countItem =
            countIngredient[foodIndex] +
              startRowFood[foodIndex] +
              ONE_ITEM_FOOD >
            countItem
              ? countIngredient[foodIndex] + startRowFood[foodIndex]
              : countItem;

          let num = 0;
          if (foodIndex > 0) {
            num =
              startRowFood[foodIndex] -
              (countIngredient[foodIndex - 1] + ONE_ITEM_FOOD) -
              startRowFood[foodIndex - 1];
          }

          if (num > 0) {
            for (let i = 0; i < num; i++) {
              itemRow.push({
                isEmpty: true,
              });
            }
          }

          const minItem = maxItemFoodWeek - maxItemFoodDay;

          itemRow.push({
            id: foodItem.id,
            name: foodItem.food_name,
            quantity: 0,
            unit: "",
            isFood: true,
            minItem: minItem,
            countIngredient: ingredients.length,
          });

          ingredients.map((ingredientItem) => {
            itemRow.push({
              id: ingredientItem.id,
              name: ingredientItem.ingredent_name,
              quantity: ingredientItem.ing_quantity,
              unit: ingredientItem.unit_cook_name,
              isFood: false,
            });

            return ingredientItem;
          });

          // Merge food name
          const colFood = (indexDay + 1) * 2 - 1;

          let startRowTime = ROW_HEADER_QTY;

          if (indexTime > 0) {
            for (let i = 1; i <= indexTime; i++) {
              startRowTime += countItemTime[indexTime - i];
            }
          }

          const rowFood = minItem + startRowTime + indexTime;

          merge.push({
            s: { r: rowFood, c: colFood },
            e: { r: rowFood, c: colFood + 1 },
          });

          heightArray[rowFood] = { hpt: 30 };

          formatFoodName.push(`${colAlpha[colFood]}${rowFood + 1}`);
        }

        return foodItem;
      });

      listItemRows.push({
        id: quantitativeTimeItem.id,
        indexDay: indexDay,
        items: itemRow,
        countItem: countItem,
      });

      countItemTime[indexTime] = Math.max(countItem, countItemTime[indexTime]);

      return quantitativeTimeItem;
    });

    totalLength += countItemTime[indexTime] + (indexTime > 0 ? 1 : 0);

    // Merge Meal
    merge.push({
      s: { c: 0, r: totalLength - countItemTime[indexTime] },
      e: { c: 0, r: totalLength },
    });

    heightArray[totalLength - countItemTime[indexTime]] = { hpt: 30 }; //set height row Meal

    return timeItem;
  });

  for (let i = 2; i < totalLength + ROW_HEADER_QTY; i++) {
    //Format Ingredient
    colAlpha.map((alpha, indexAlpha) => {
      if (ws[`${alpha}${i}`].v === "empty") ws[`${alpha}${i}`].v = " ";

      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
          sz: 9,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: indexAlpha % 2 === 0 ? "center" : "start",
        },
        border: borderStyle,
      };

      return alpha;
    });

    //Format Meal name
    ws[`A${i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
  }

  //Format Food name
  if (formatFoodName.length > 0) {
    formatFoodName.map((item) => {
      ws[item].s = {
        font: {
          ...fontStyle,
          sz: 9,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: borderStyle,
        fill: { fgColor: { rgb: "E0FEFE" } },
      };

      return item;
    });
  }

  //Format Header
  const cols = [{ wch: 7 }];

  const widthDay = (MAX_WITH_A4_LANDSCAPE - 7) / dayLength;

  for (let i = 1; i < dayLength + 1; i++) {
    cols.push({ wch: (widthDay * 2) / 3 });
    cols.push({ wch: (widthDay * 1) / 3 });
  }

  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`${colAlpha[Math.floor((dayLength * 2) / 2) + 1]}1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  colAlpha.map((alpha) => {
    if (ws[`${alpha}2`].v === "empty") {
      ws[`${alpha}2`].v = " ";
    }

    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
      fill: { fgColor: { rgb: "B5EAD7" } },
    };

    return alpha;
  });

  ws["!cols"] = cols;

  ws["!rows"] = heightArray;

  ws["!merges"] = merge;

  return ws;
};

const exportMenu4 = (
  dayLength,
  listTimes,
  listQuantitatives,
  selectedClassGroup
) => {
  const table = document.getElementById(`menu-export-excel-4`);
  var ws = XLSX.utils.table_to_sheet(table);

  const initColAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
  ];

  const colAlpha = initColAlpha.slice(0, dayLength + 1);

  const ROW_HEADER_QTY = 2;

  let heightArray = [{ hpt: 40 }, { hpt: 30 }];

  //Merge title
  const filterTimesByIdTime = (data, id_time) => {
    return data.times.filter((time) => time.id_time === id_time);
  };

  const groupTimesByIdTime = (dataArray) => {
    // console.log(dataArray, "âấáa");

    const result = [];
    dataArray.forEach((data) => {
      const uniqueIdTimes = [
        ...new Set(data.times.map((time) => time.id_time)),
      ];
      // console.log(uniqueIdTimes, "âấáa");

      uniqueIdTimes.forEach((id_time) => {
        const filteredTimes = filterTimesByIdTime(data, id_time);
        result.push({ id_time, times: filteredTimes });
      });
    });
    // console.log(result, "result");

    return result;
  };

  const aggregateIngredentsByTime = (data) => {
    const groupedTimesMap = new Map();
    data.forEach((item) => {
      const { id_time, times } = item;
      if (!groupedTimesMap.has(id_time)) {
        groupedTimesMap.set(id_time, times);
      } else {
        const existingTimes = groupedTimesMap.get(id_time);
        groupedTimesMap.set(id_time, [...existingTimes, ...times]);
      }
    });
    const calculateAverage = (numbers) => {
      // console.log(numbers, "number");

      const validNumbers = numbers.filter(
        (number) => !isNaN(number) && number > 0
      );
      const sum = validNumbers.reduce((acc, number) => acc + number, 0);
      return validNumbers.length ? sum / validNumbers.length : 0;
    };

    const aggregatedData = [...groupedTimesMap.entries()].map(
      // check ở đây
      ([id_time, times]) => {
        const ingredentsMap = new Map();
        times.forEach((time) => {
          time.foods.forEach((food) => {
            food.groups
              ?.filter((group) => group.id == selectedClassGroup.id)
              ?.forEach((group) => {
                group.ingredents
                  ?.filter((e) => e.ingredent_type == 2)
                  ?.forEach((ingredent) => {
                    // merge id_time
                    const { id, ing_quantity } = ingredent;
                    const id_day = time.id_day;
                    if (!ingredentsMap.has(id)) {
                      ingredentsMap.set(id, [{ ingredent, id_time, id_day }]);
                    } else {
                      ingredentsMap
                        .get(id)
                        .push({ ingredent, id_time, id_day });
                    }
                  });
              });
          });
        });
        // merge id_day
        let resultMap = new Map();
        ingredentsMap.forEach((ingredients, id) => {
          let groupedByDay = {};
          ingredients.forEach((entry) => {
            let day = entry.id_day;
            if (!groupedByDay[day]) {
              groupedByDay[day] = {
                ...entry,
                ingredent: { ...entry.ingredent, ing_quantity: 0 },
              };
            }
            groupedByDay[day].ingredent.ing_quantity += parseFloat(
              entry.ingredent.ing_quantity
            );
          });

          resultMap.set(id, Object.values(groupedByDay));
        });

        const uniqueIngredents = [];
        resultMap.forEach((ingredentList, id) => {
          const sameIdTimeIngredents = ingredentList.filter(
            (item) => item.id_time == id_time
          );

          if (sameIdTimeIngredents.length == 1) {
            uniqueIngredents.push(sameIdTimeIngredents[0].ingredent);
          } else {
            const averageQuantity = calculateAverage(
              sameIdTimeIngredents.map((item) =>
                parseFloat(item.ingredent.ing_quantity)
              )
            );

            const [firstItem] = sameIdTimeIngredents;
            uniqueIngredents.push({
              ...firstItem.ingredent,
              ing_quantity: averageQuantity.toFixed(2).toString(),
            });
          }
        });

        return { id_time, ingredents: uniqueIngredents };
      }
    );

    return aggregatedData;
  };
  const merge = [
    {
      s: { r: 0, c: 0 },
      e: { r: 0, c: Math.floor(dayLength / 2) },
    },
    {
      s: { r: 0, c: Math.floor(dayLength / 2) + 1 },
      e: { r: 0, c: dayLength },
    },
  ];

  // Body
  let totalLength = ROW_HEADER_QTY;
  let formatFoodName = [];
  let countItemTime = [];

  listTimes.map((timeItem, indexTime) => {
    countItemTime[indexTime] = 0;

    const quantitativeTimes = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times.map((timeObj) => {
        if (timeObj.id_time === timeItem.id_time) {
          timeObj.indexTime = indexTime;
          quantitativeTimes.push(timeObj);
        }

        return timeObj;
      });
    });
    // Find Store by time

    const groupedDataMap = new Map();
    groupTimesByIdTime(listQuantitatives)?.forEach((item) => {
      const { id_time, times } = item;
      if (!groupedDataMap.has(id_time)) {
        groupedDataMap.set(id_time, times);
      } else {
        const existingTimes = groupedDataMap?.get(id_time);
        groupedDataMap.set(id_time, [...existingTimes, ...times]);
      }
    });
    const IngredentbyTime = [...groupedDataMap.entries()].map(
      ([id_time, times]) => ({
        id_time,
        times,
      })
    );
    let checkItemRows = [];

    quantitativeTimes.map((quantitativeTimeItem) => {
      let itemRow = [];

      const filteredFoodDatas = quantitativeTimeItem?.foods
        .map((foodData) => {
          const filteredGroups = foodData.groups.filter(
            (group) => group.id === selectedClassGroup.id
          );
          return { ...foodData, groups: filteredGroups };
        })
        .filter((foodData) => foodData.groups.length > 0);

      filteredFoodDatas.map((foodItem, foodIndex) => {
        if (
          foodItem.groups.some(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              // ?.filter((ingr) => ingr.ingredent_type == 1)
              groupItem?.ingredents.length > 0
          )
        ) {
          const ingredients =
            foodItem.groups
              .find(
                (groupItem) =>
                  groupItem.id === selectedClassGroup.id &&
                  groupItem.ingredents.length !== 0
              )
              ?.ingredents?.filter((ingr) => ingr.ingredent_type == 1) || [];

          itemRow.push({
            id_day: quantitativeTimeItem.id_day,
            id: foodItem.id,
            name: foodItem.food_name,
            keyFood: foodIndex,
            countIngredient: ingredients.length || 0,
          });
        }

        return foodItem;
      });

      checkItemRows.push({
        id: quantitativeTimeItem.id,
        id_day: quantitativeTimeItem.id_day,
        items: itemRow,
      });

      return quantitativeTimeItem;
    });

    let listItemRows = [];
    let countItem = 0;
    let countItemStore = 0;
    let ingredientsStore = [];
    quantitativeTimes.map((quantitativeTimeItem, indexDay) => {
      let itemRow = [];
      let startRowFood = {};
      let countIngredient = {};

      const filteredFoodDatas = quantitativeTimeItem?.foods
        .map((foodData) => {
          const filteredGroups = foodData.groups.filter(
            (group) => group.id === selectedClassGroup.id
          );
          return { ...foodData, groups: filteredGroups };
        })
        .filter((foodData) => foodData.groups.length > 0);

      filteredFoodDatas.map((foodItem, foodIndex) => {
        const ingredients =
          foodItem.groups
            .find((groupItem) => groupItem.id === selectedClassGroup.id)
            ?.ingredents?.filter((ingr) => ingr.ingredent_type == 1) || [];
        ingredientsStore =
          foodItem.groups
            .find((groupItem) => groupItem.id === selectedClassGroup.id)
            ?.ingredents?.filter((ingr) => ingr.ingredent_type == 2) || [];

        if (ingredients.length >= 0) {
          let maxItemFoodWeek = 0;

          for (let i = 0; i <= foodIndex; i++) {
            let maxCountIngredientFood = 0;

            checkItemRows.forEach((row) => {
              row.items.forEach((item) => {
                if (
                  item.keyFood === i &&
                  item.countIngredient + ONE_ITEM_FOOD > maxCountIngredientFood
                ) {
                  maxCountIngredientFood = item.countIngredient + ONE_ITEM_FOOD;
                }
              });
            });

            maxItemFoodWeek += maxCountIngredientFood || 0;
          }

          let maxItemFoodDay = ONE_ITEM_FOOD;

          checkItemRows.forEach((row) => {
            row.items.forEach((item) => {
              if (
                item.keyFood === foodIndex &&
                item.countIngredient + ONE_ITEM_FOOD > maxItemFoodDay
              ) {
                maxItemFoodDay = item.countIngredient + ONE_ITEM_FOOD;
              }
            });
          });

          countIngredient[foodIndex] =
            foodItem.groups[0]?.ingredents?.filter(
              (ingr) => ingr.ingredent_type == 1
            ).length || 0;
          startRowFood[foodIndex] = maxItemFoodWeek - maxItemFoodDay;

          countItem =
            countIngredient[foodIndex] +
              startRowFood[foodIndex] +
              ONE_ITEM_FOOD >
            countItem
              ? countIngredient[foodIndex] + startRowFood[foodIndex]
              : countItem;

          let num = 0;
          if (foodIndex > 0) {
            num =
              startRowFood[foodIndex] -
              (countIngredient[foodIndex - 1] + ONE_ITEM_FOOD) -
              startRowFood[foodIndex - 1];
          }

          if (num > 0) {
            for (let i = 0; i < num; i++) {
              itemRow.push({
                isEmpty: true,
              });
            }
          }

          const minItem = maxItemFoodWeek - maxItemFoodDay;

          itemRow.push({
            id: foodItem.id,
            name: foodItem.food_name,
            quantity: 0,
            unit: "",
            isFood: true,
            minItem: minItem,
            countIngredient: ingredients.length || 0,
          });

          ingredients.map((ingredientItem) => {
            itemRow.push({
              id: ingredientItem.id,
              name: ingredientItem.ingredent_name,
              quantity: ingredientItem.ing_quantity,
              unit: ingredientItem.unit_cook_name,
              isFood: false,
            });

            return ingredientItem;
          });

          // Merge food name
          const colFood = indexDay + 1;

          let startRowTime = ROW_HEADER_QTY;

          if (indexTime > 0) {
            for (let i = 1; i <= indexTime; i++) {
              startRowTime += countItemTime[indexTime - i];
            }
          }

          const rowFood = minItem + startRowTime + indexTime;

          // heightArray[rowFood] = { hpt: 30 };

          formatFoodName.push(`${colAlpha[colFood]}${rowFood + 1}`);
        }

        return foodItem;
      });

      listItemRows.push({
        id: quantitativeTimeItem.id,
        indexDay: indexDay,
        items: itemRow,
        countItem: countItem,
      });

      countItemTime[indexTime] = Math.max(
        countItem,
        countItemTime[indexTime],
        1
      );

      return quantitativeTimeItem;
    });
    totalLength += countItemTime[indexTime] + (indexTime > 0 ? 1 : 0);

    // Merge times
    merge.push({
      s: { c: 0, r: totalLength - countItemTime[indexTime] + 1 },
      e: { c: 0, r: totalLength },
    });
    // Store

    if (countItem == 0 && ingredientsStore?.length > 0) {
      heightArray[totalLength - countItemTime[indexTime] + 1] = {
        hpt:
          aggregateIngredentsByTime(IngredentbyTime)?.filter(
            (e, ind) => ind == indexTime
          )[0]?.ingredents?.length *
            16 +
          15,
      }; //set height store no ingredient market
    }

    if (
      // countItem == 0 &&
      aggregateIngredentsByTime(IngredentbyTime)?.filter(
        (e, ind) => ind == indexTime
      )[0]?.ingredents?.length > countItemTime[indexTime]
    ) {
      heightArray[totalLength] = {
        hpt:
          (aggregateIngredentsByTime(IngredentbyTime)?.filter(
            (e, ind) => ind == indexTime
          )[0]?.ingredents?.length +
            -countItemTime[indexTime]) *
            16 +
          16,
      }; //set height store
    }

    return timeItem;
  });

  for (let i = 2; i < totalLength + ROW_HEADER_QTY; i++) {
    //Format Ingredient
    colAlpha.map((alpha, indexAlpha) => {
      if (ws[`${alpha}${i}`].v === "empty") ws[`${alpha}${i}`].v = " ";

      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
          sz: 9,
        },
        alignment: {
          wrapText: true,
          vertical: alpha == "A" ? "start" : "center",
          horizontal: "start",
        },
        border: borderStyle,
      };

      return alpha;
    });

    //Format Meal name
    ws[`A${i}`].s = {
      font: {
        ...fontStyle,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "start",
      },
      border: borderStyle,
    };
  }

  // Format Food name
  if (formatFoodName.length > 0) {
    formatFoodName.map((item) => {
      //  điều kiện đè khi ingrdent=0, ingrdentStore!=0
      // heightArray[parseInt(item.substring(1))] = {};
      // };
      ws[item].s = {
        font: {
          ...fontStyle,
          sz: 9,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: borderStyle,
        fill: { fgColor: { rgb: "E0FEFE" } },
      };

      return item;
    });
  }

  //Format Header
  const cols = [{ wch: 18 }];
  for (let i = 1; i < dayLength + 1; i++) {
    cols.push({ wch: 17.1 });
    // cols.push({ wch: (widthDay * 1) / 3 });
  }

  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`${colAlpha[Math.floor(dayLength / 2) + 1]}1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
  };

  colAlpha.map((alpha) => {
    if (ws[`${alpha}2`].v === "empty") {
      ws[`${alpha}2`].v = " ";
    }

    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
      fill: { fgColor: { rgb: "B5EAD7" } },
    };

    return alpha;
  });

  ws["!cols"] = cols;

  ws["!rows"] = heightArray;

  ws["!merges"] = merge;

  return ws;
};

export { exportMenu0, exportMenu1, exportMenu2, exportMenu3, exportMenu4 };
