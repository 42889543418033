import React from "react";
import { useReactToPrint } from "react-to-print";
import { Row, Col, Button, Table } from "react-bootstrap";
import { priceFormatterNoDecimal } from "../utils/helpers";
import axios from "axios";
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataExport: this.props.data,
      date: this.props.date,
      countDuration: this.props.countDuration,
      idBill: this.props.idBill,
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
    }
  }

  render() {
    return (
      <div
        id={`exportPDFbill${this.props.item?.id}`}
        style={{
          fontFamily: "Times New Roman, Times, serif",
          color: "#000",
          // ,margin:' 20px 55px '
        }}
        className="hidden-print"
      >
        <div>{this.props.schoolInfo?.school_name}</div>
        <div>
          {this.props.schoolInfo?.school_address}. ĐT:
          {this.props.schoolInfo?.school_phone}{" "}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            PHIẾU THU{" "}
            {new Date(this.props.item?.bill_date * 1000).getMonth() + 1}/
            {new Date(this.props.item?.bill_date * 1000).getFullYear()}
          </div>
          <div style={{ height: "20px" }}>
            <div
              style={{
                display: "flex",
                fontSize: "16px",
                // justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {this.props.item?.student?.student_name} :{" "}
              {this.props.item?.student?.classes[0]?.class_name}
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          <div
            style={{
              //  border: '2px solid',
              // borderTop:'1px solid',

              display: "contents",
            }}
            className="tableExport"
          >
            {/* <thead> */}
            <Table bordered style={{ border: "1px solid" }}>
              <thead>
                <tr>
                  <th className="text-center align-middle bordertd fw-bold">
                    STT
                  </th>
                  <th className="text-start  align-middle fw-bold">DỊCH VỤ</th>
                  <th className="text-end  align-middle">ĐƠN GIÁ</th>
                  <th className="text-end  align-middle">SL</th>
                  <th className="text-end  align-middle">Thành tiền</th>
                </tr>
              </thead>
              <tbody>
                {this.props.item?.details?.map((item, index) => {
                  return (
                    <>
                      <tr className="fw-bold align-middle">
                        <td className="text-center align-middle ">
                          {index + 1}
                        </td>
                        <td>{item?.content}</td>
                        <td className="text-end align-middle ">
                          {priceFormatterNoDecimal.format(item?.price)}
                        </td>
                        <td className="text-end align-middle ">
                          {priceFormatterNoDecimal.format(item?.quantity)}
                        </td>
                        <td className="text-end align-middle ">
                          {priceFormatterNoDecimal.format(
                            item.bill_detailable === "HolidayRefunded" ||
                              item.bill_detailable === "FoodRefunded"
                              ? 0 - item?.sub_total
                              : item?.sub_total
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={4}>TỔNG CỘNG</th>
                  <th className="text-end">
                    {priceFormatterNoDecimal.format(
                      this.props.item?.trans_amount
                    )}
                  </th>
                </tr>
              </tfoot>
            </Table>
            <div style={{ height: "20px" }}></div>
            <div>Ghi chú</div>
          </div>
          <div className="text-end">
            Ngày .... tháng{" "}
            {new Date(this.props.item?.bill_date * 1000).getMonth() + 1} năm{" "}
            {new Date(this.props.item?.bill_date * 1000).getFullYear()}{" "}
          </div>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-around",
              fontWeight: "bold",
              marginTop: "15px",
              border: "none !important",
            }}
          >
            <Col className="text-center">Người nộp tiền</Col>
            <Col className="text-center">Người thu</Col>
            <Col className="text-center">Hiệu trưởng</Col>
          </Row>
        </div>
        <footer
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "#fff",
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-around",
            fontWeight: "bold",
            fontStyle: "italic",
            fontSize: "0.8rem",
          }}
        >
          7 ngày kể từ ngày xuất
        </footer>
      </div>
    );
  }
}

const PrintButton = ({ schoolInfo, idBill, item }) => {
  const componentRef = React.useRef();
  const [id, setIdBill] = React.useState(idBill);
  const [dataBill, setDataBill] = React.useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Phiếu thu`,
    // pageStyle: `{ size: 2.5in 4in;

    // }`
  });
  const getBill = (idBill) => {
    if (idBill !== "") {
      const element = document.getElementById(`exportPDFbill${idBill}`);
      var clonedElement = element?.cloneNode(true);
      clonedElement?.classList?.remove("d-none");
      handlePrint();
    }
  };

  return (
    <div>
      {id && (
        <ComponentToPrint
          ref={componentRef}
          idBill={id}
          data={dataBill}
          schoolInfo={schoolInfo}
          item={item}
          key={id}
        />
      )}

      <Button
        variant="outline-danger"
        size="sm"
        style={{ float: "right", whiteSpace: "nowrap" }}
        className="m-1"
        onClick={() => {
          getBill(idBill);
        }}
        title="Xuất phiếu thu"
      >
        <i className="fa-solid fa-file-pdf"></i>
      </Button>
    </div>
  );
};

export default PrintButton;
