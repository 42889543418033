import axios from "axios";
import React, { Component } from "react";
import {
	Alert,
	Breadcrumb,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Tab,
	Table,
	Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import FormatData from "../FormatData";
import StoreCreate from "./StoreCreate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { formControlUnstyledClasses } from "@mui/base";
import Check from "../other/Check";

const exportType = [
	{ value: 3, label: "Hết hạn" },
	{ value: 4, label: "Bị hỏng" },
	{ value: 6, label: "Khác" },
]

class StoreIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			liststore: [],
			selectedRows: [],
			has_inventory: [],
			inventory_his: [],
			addliststore: [],
			suppliers: [],
			showcreate: false,
			showdetail: false,
			showhis: false,
			showdelete: false,
			showupdate: false,
			startDate: new Date(),
			tabkey: 1,
			id_ingredent: "",
			amount_delete: "",
			delete_type: { value: 3, label: "Hết hạn" },
			delete_note: "",
			ingredient_search: "", liststore_search: [],
			update_inventory: {
				id_inventory: "",
				is_type: false,
				amount: "",
			},
			propsdata: {
				id: "",
				amount: "",
				ingredent_name: "",
			},
			endDate: new Date(),
			show: false,
			units: [],
			dataFilter: []
		};
	}

	async componentDidMount() {
		document.title = "Quản lý kho";
		this.getStoreData(2, 100, this.state.startDate);
		this.getSuppliersData();
		this.getUnit(100);
	}
	formatter = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	getUnit = (limit = 1000) => {
		axios.post(`/viewUnit?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ units: res.data.data });
			}
		});
	};
	getDate_ = (data) => {
		let date = data
		let result = `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`;
		return result;
	}

	getStoreData = (type, limit, start) => {
		let start_ = start.setHours(0, 0, 0, 0);
		let end_ = this.state.endDate.setHours(23, 59, 59, 0);
		axios
			.post(`/viewInventory?ingredent_type=${type}&start_at=${start_ / 1000}&end_at=${end_ / 1000}&limit=${limit}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ liststore: res.data.data });
					this.handleIngredentSearch(res.data.data, this.state.ingredient_search)
				}
				else { this.loading() }
			});
	};

	getSuppliersData = (limit = 100) => {
		axios.post(`/viewSupplier?limit=${limit}`).then((res) => {
			if (res.data.status === true) {
				this.setState({ suppliers: res.data.data });
			}
		});
	};

	gethas_inventoryData = (type, id) => {
		axios
			.post(`/viewInventory?ingredent_type=${type}&id_ingredent=${id}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ has_inventory: res.data.data });
				}
			});
	};

	getinventory_hisData = (type, id) => {
		axios
			.post(`/viewHisInventory?ingredent_type=${type}&id_inventory=${id}`)
			.then((res) => {
				if (res.data.status === true) {
					this.setState({ inventory_his: res.data.data });
				}
			});
	};

	getaddliststore = (e) => {
		e.preventDefault();
		axios
			.post(
				`/createMoreInventory?inventory=${JSON.stringify(
					this.state.selectedRows
				)}`
			)
			.then((res) => {
				if (res.data.status === true) {
					this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
					toast(res.data.data.mess, { type: "success", autoClose: 1000 });
				} else {
					toast(res.data.data.mess, { type: "error", autoClose: 1000 });
				}
			});
	};

	deleteinventory = () => {
		if (this.state.delete_type.value == 6) {
			if (this.state.delete_note == "") {
				toast("Vui lòng nhập lí do", { type: "error", autoClose: 1000 })
			} else {
				axios
					.post(
						`/exportInventoryIngerdent?id_ingredent=${this.state.id_ingredent}&amount_his=${this.state.amount_delete}&type_note=${this.state.delete_type.value}&note=${this.state.delete_note}`
					)
					.then((res) => {
						if (res.data.status === true) {
							this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
							this.setState({ showdelete: false });
							toast("Xuất kho thành công", { type: "success", autoClose: 1000 });
						} else {
							toast(res.data.data.mess, { type: "error", autoClose: 1000 });
						}
					});
			}
		} else {
			axios
				.post(
					`/exportInventoryIngerdent?id_ingredent=${this.state.id_ingredent}&amount_his=${this.state.amount_delete}&type_note=${this.state.delete_type.value}&note=${this.state.delete_note}`
				)
				.then((res) => {
					if (res.data.status === true) {
						this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
						this.setState({ showdelete: false });
						toast("Xuất kho thành công", { type: "success", autoClose: 1000 });
					} else {
						toast(res.data.data.mess, { type: "error", autoClose: 1000 });
					}
				});
		}
	};
	updateinventory = () => {
		if (this.state.delete_type.value == 6) {
			if (this.state.delete_note == "") {
				toast("Vui lòng nhập lí do", { type: "error", autoClose: 1000 })
			} else {
				axios
					.post(
						`/updateInventory?id_inventory=${this.state.update_inventory.id_inventory
						}&is_type=${this.state.update_inventory.is_type ? 1 : 2}&amount_his=${this.state.update_inventory.amount
						}&type_note=${this.state.delete_type.value}&note=${this.state.delete_note}`
					)
					.then((res) => {
						if (res.data.status === true) {
							this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
							this.setState({ showupdate: false });
							toast(res.data.data.mess, { type: "success", autoClose: 1000 });
						} else {
							toast(res.data.data.mess, { type: "error", autoClose: 1000 });
						}
					});
			}
		} else {
			axios
				.post(
					`/updateInventory?id_inventory=${this.state.update_inventory.id_inventory
					}&is_type=${this.state.update_inventory.is_type ? 1 : 2}&amount_his=${this.state.update_inventory.amount
					}&type_note=${this.state.delete_type.value}&note=${this.state.delete_note}`
				)
				.then((res) => {
					if (res.data.status === true) {
						this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
						this.setState({ showupdate: false });
						toast(res.data.data.mess, { type: "success", autoClose: 1000 });
					} else {
						toast(res.data.data.mess, { type: "error", autoClose: 1000 });
					}
				});
		}
	};

	deleteinventorychild = (id, event) => {
		swal({
			title: "Bạn muốn xóa lô",
			icon: "error",
			buttons: true,
			successMode: true,
		}).then((ok) => {
			if (ok) {
				axios
					.post(`/deleteInventory?id_inventory=${id}`)
					.then((res) => {
						this.getStoreData(this.state.tabkey, 100, this.state.startDate);
						toast(`${res.data.data.mess}`, {
							type: "success",
							autoClose: 1000,
						});
					})
					.catch((error) => {
						console.log(error);
					});
				this.setState({ showdetail: true });
			}
		});
	};

	detailinventoryview = () => {
		return (
			<Modal
				size="lg"
				show={this.state.showdetail}
				onHide={() => {
					this.setState({ showdetail: false });
				}}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Danh sách lô</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table>
						<thead>
							<tr>
								<th>Mã lô</th>
								<th className="text-center">Số lượng</th>
								<th className="text-center">Giá (VNĐ)</th>
								<th className="text-center">Ngày hết hạn</th>
								<th className="text-center">Phương thức</th>
							</tr>
						</thead>
						<tbody>
							{this.state.has_inventory.length > 0
								? this.state.has_inventory.sort(function (a, b) {
									return b.expriry_date - a.expriry_date;
								}).map((item, i) => {
									let expriry_date = this.getDate(item.expriry_date);
									let ingredent_price = new FormatData().currency(item.ingredent_price);
									return (
										<tr key={i}>
											<td>{item.code_inventory}</td>
											<td className="text-center">{this.formatter.format(item.amount)}</td>
											<td className="text-center">
												{ingredent_price}
											</td>
											<td className="text-center">{expriry_date}</td>
											<td className="text-center">
												{new Check().permission(['47']) ?
													<Button
														type="button"
														className="me-2"
														variant="success"
														size="sm"
														onClick={() => {
															this.setState({
																showupdate: true,
																showdetail: false,
																update_inventory: {
																	id_inventory: item.id,
																	is_type: true,
																},
															});
														}}
													>
														Nhập kho
													</Button> : null}
												{new Check().permission(['48']) ?
													<Button
														type="button"
														className="me-2"
														variant="success"
														size="sm"
														onClick={() => {
															this.setState({
																showupdate: true,
																showdetail: false,
																update_inventory: {
																	id_inventory: item.id,
																	is_type: false,
																},
															});
														}}
													>
														Xuất kho
													</Button> : null}
												{new Check().permission(['50']) ?
													<Button
														type="button"
														variant="primary"
														size="sm"
														onClick={() => {
															this.getinventory_hisData(
																this.state.tabkey,
																item.id
															);
															this.setState({
																showhis: true,
																showdetail: false,
															});
														}}
													>
														Lịch sử
													</Button> : null}
											</td>
										</tr>
									);
								})
								: null}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
		);
	};

	hisinventoryview = () => {
		return (
			<Modal
				size="lg"
				show={this.state.showhis}
				onHide={() => {
					this.setState({ showhis: false, showdetail: true });
				}}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Lịch sử lô</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table>
						<thead>
							<tr>
								<th className="text-center">Loại</th>
								<th className="text-center">Số lượng</th>
								<th>Thời gian cập nhật</th>
								<th>Nhập bởi</th>
							</tr>
						</thead>
						<tbody>
							{this.state.inventory_his.sort(function (a, b) {
								return b.created_at - a.created_at;
							}).map((item, i) => {
								let created_at = new FormatData().date(item.created_at);

								return (
									<tr key={i}>
										<td className="text-center">{item.is_type == 1 ? "(Nhập) " + item.inventoryType_name : "(Xuất) " + item.inventoryType_name}</td>
										<td className="text-center"> {item.is_type == 1 ? ' ' + this.formatter.format(item.amount) : '- ' + this.formatter.format(item.amount)}</td>
										<td>{created_at}</td>
										<td>{item.user_name}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
		);
	};

	deleteinventoryview = () => {
		return (
			<Modal
				size="lg"
				show={this.state.showdelete}
				onHide={() => {
					this.setState({ showdelete: false });
				}}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Xuất kho</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Floating className="mb-3">
						<Form.Control
							type="text"
							onChange={(e) => {
								this.setState({ amount_delete: e.target.value });
							}}
							name="amount"
							required
						/>
						<Form.Label>
							Số lượng<i className="text-danger">*</i>
						</Form.Label>
					</Form.Floating>

					<Select
						options={exportType}
						placeholder={<div>FNhập lý do xuất kho</div>}
						value={this.state.delete_type}
						onChange={(choice) => {
							this.setState({ delete_type: choice })
						}}

						// className="mb-3"
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								minHeight: '50px', marginTop: '10px', marginBottom: '10px'
							}),
						}}
					/>

					<Form.Floating className="mb-3">
						<Form.Control
							type="text"
							onChange={(e) => {
								this.setState({ delete_note: e.target.value });
							}}
							name="amount"
							disabled={this.state.delete_type.value !== 6}
						/>
						<Form.Label>
							Lý do<i className="text-danger"></i>
						</Form.Label>
					</Form.Floating>
				</Modal.Body>

				<Modal.Footer>
					<Button size="sm" variant="secondary" onClick={() => this.setState({ showdelete: false })}>
						<i className="fa-solid fa-times"></i> Đóng
					</Button>
					<Button
						size="sm"
						type="submit"
						variant="success"
						onClick={() => {
							this.deleteinventory();
						}}
					>
						<i className="fa-solid fa-check"></i> Lưu
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	updateinventoryview = () => {
		return (
			<Modal
				size="lg"
				show={this.state.showupdate}
				onHide={() => {
					this.setState({ showupdate: false, showdetail: true });
				}}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.state.update_inventory.is_type ? "Nhập kho" : "Xuất kho"}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Floating className="mb-3">
						<Form.Control
							type="text"
							onChange={(e) => {
								this.setState({
									update_inventory: {
										id_inventory: this.state.update_inventory.id_inventory,
										is_type: this.state.update_inventory.is_type,
										amount: e.target.value,
									},
								});
							}}
							name="amount"
							placeholder="số lượng"
							required
						/>
						<Form.Label>
							Số lượng<i className="text-danger">*</i>
						</Form.Label>
						{this.state.update_inventory.is_type ? "" :
							<>
								<Select
									options={exportType}
									placeholder={<div>Nhập lý do xuất kho</div>}
									value={this.state.delete_type}
									onChange={(choice) => {
										this.setState({ delete_type: choice })
									}}

									className="mb-3"
								/>

								<Form.Floating className="mb-3">
									<Form.Control
										type="text"
										onChange={(e) => {
											this.setState({ delete_note: e.target.value });
										}}
										name="amount"
										disabled={this.state.delete_type.value !== 6}
									/>
									<Form.Label>
										Lý do<i className="text-danger"></i>
									</Form.Label>
								</Form.Floating>
							</>}

					</Form.Floating>
				</Modal.Body>

				<Modal.Footer>
					<Button
						size="sm"
						type="submit"
						variant="success"
						onClick={() => {
							this.updateinventory();
						}}
					>
						<i className="fa-solid fa-check"></i> Lưu
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	getDate = (data) => {
		let date = new Date(data * 1000);
		return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
		// return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}

	dataTable(key) {
		const columns =
			// key === 2
				// ?
				 [
					{
						name: "Nguyên liệu",
						selector: (row) => row.ingredent_name,
						sortable: true,
						style: {
							div: {
							//   background:'red',
				  
							//   overflow: 'inherit !important ',
							  width: '100px !important'
							}
						  }
					},
					{
						name: "Đơn vị tính",
						selector: (row) => row.unit_name,
						sortable: true,
						center:true,
						// style: {
						// 	div: {
						// 	  // background:'red',
				  
						// 	  overflow: 'inherit !important ',
						// 	  width: '200px'
						// 	}
						//   }
					},
					{
						name: "Tồn đầu kỳ",
						selector: (row) => row.inventory_existence,
						sortable: true,
						center: true,
					},
					{
						name: "Nhập trong kỳ",
						selector: (row) => row.inventory_enterSlot,
						center: true,
					},{
						name: "Xuất trong kỳ",
						selector: (row) => row.inventory_enterSlot,
						sortable: true,
						center:true
					},
					{
						name: "Tồn cuối kỳ",
						selector: (row) => row.total,
						sortable: true,
						center:true
					},
					{
						name: "Phương thức",
						selector: (row) => row.setting,
						sortable: true,
						center: true,
						style: {
							div: {
							  // background:'red',
				  
							  overflow: 'inherit !important ',
							//   width: '180px'
							}
						  }
					},
				]
				// : [
				// 	{
				// 		name: "Xuất trong kỳ",
				// 		selector: (row) => row.inventory_enterSlot,
				// 		sortable: true,
				// 	},
				// 	{
				// 		name: "Tồn cuối kỳ",
				// 		selector: (row) => row.total,
				// 		sortable: true,
				// 	},
				// 	{
				// 		name: "Phương thức",
				// 		selector: (row) => row.setting,
				// 		sortable: true,
				// 		center: true,
				// 	},
				// ];

		const data = [];

		const selectedRows = (items) => {
			this.setState({ selectedRows: items, alertShow: true });
		};
	
		if (this.state.liststore_search.length > 0) {
			this.state.liststore_search.map((item) => {
				if (item.ingredent_type) {
					data.push({
						id: item.id,
						ingredent_name:
						<div style={{width: "100%"}} className="text-wrap">{item.ingredent_name}</div>,
						// item.ingredent_name,
						unit_name:	<div style={{width: "100%"}} className="text-wrap"> {this.state.units.filter(e => e.id == item.id_unit)[0]?.unit_name}</div>,
						inventory_existence: this.formatter.format(item.inventory_existence) ?? 0,
						inventory_enterSlot:this.formatter.format(item.inventory_enterSlot) ?? 0,
						total:this.formatter.format(((item.inventory_existence ?? 0) + (item.inventory_enterSlot ?? 0)) - (item.inventory_termSlot ?? 0)),
						inventory_termSlot:this.formatter.format(item.inventory_termSlot) ?? 0,
						setting:
						<div style={{width: "200px"}} className="text-wrap">
						{new Check().permission(['47']) ?
							<Button
								type="button"
								className="me-2"
								variant="success"
								size="sm"
								onClick={() => {
									this.setState({
										propsdata: {
											id: item.id,
											amount: item.amount,
											ingredent_name: item.ingredent_name,
										},
										showcreate: true,
									});
								}}
							>
								Nhập
							</Button> : null}
						{new Check().permission(['48']) ?
							<Button
								type="button"
								className="me-2"
								variant="success"
								size="sm"
								onClick={() => {
									this.setState({
										showdelete: true,
										id_ingredent: item.id,
									});
								}}
							>
								Xuất
							</Button> : null}
						{new Check().permission(['50']) ?
							<Button
								type="button"
								variant="primary"
								size="sm"
								onClick={() => {
									this.gethas_inventoryData(this.state.tabkey, item.id);
									this.setState({
										showdetail: true,
									});
								}}
							>
								Lô hàng
							</Button> : null}
							</div>
					});
				}

				return item;
			});
		}

		return (
			<DataTables
				data={data}
				columns={columns}
				selectedRows={selectedRows}
				// select={key === 2 ? true : false}
			/>
		);
	}

	loading = () => {
		return <Card>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
					<span className="sr-only">Loading...</span>
				</div>
			</Card.Body>
		</Card>

	}

	renderSelectedRows() {
		let num = this.state.selectedRows.length;
		if (num > 0 && this.state.alertShow === true) {
			return (
				<Alert
					style={{
						position: "absolute",
						zIndex: "1",
						left: "91%",
					}}
					variant="info mb-0"
					onClose={() => this.setState({ alertShow: false })}
					dismissible
				>
					{num} mục đã chọn.
				</Alert>
			);
		}
	}

	toSlug(str) {
		str = str.toLowerCase(); // Chuyển hết sang chữ thường       
		// xóa dấu
		str = str
			.normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
			.replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
		str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
		str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
		return str;
	}

	handleIngredentSearch(root, text) {
		let root_ = root;
		this.setState({
			ingredient_search: text
		})
		if (text !== "") {
			root_ = this.state.liststore.filter((e) => this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim())));
		}
		this.setState({
			liststore_search: root_
		})
	}

	handSelectAddMore(item) {
		let var_ = {
			'ingredent_name': item.ingredent_name,
			'id_ingerdent': item.id,
			'supplier': [],
			'amount': 1,
			'expriry_date': (new Date()) / 1000,
		}
		let selectedRows_ = this.state.selectedRows;
		if (selectedRows_ !== "") {
			selectedRows_.forEach((e, index) => {
				if (e.id_ingerdent === item.id) {
					selectedRows_[index].amount = parseInt(selectedRows_[index].amount) + 1;
				} else {
					if (selectedRows_.length < this.state.liststore.length) { selectedRows_.push(var_); }
				}
			})
		} else {
			selectedRows_ = [var_];
		}
		this.setState({ selectedRows: selectedRows_ });

	}

	renderStoreList() {
		return (
			<>
				<Card.Header style={{display :"flex"}}>
					<Card.Title>
						<i className="fas fa-list me-1"></i> Tồn kho {this.state.startDate ? this.getDate_(this.state.startDate) : ""} -  {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}
					</Card.Title>
					<div style={{ marginLeft : "10px"}} >
						<Button size="sm" onClick={() => { this.setState({ show: true }) }}>
							<i className="fa-solid fa-calendar-days"> </i> {" "}
						</Button>

					</div>

				</Card.Header>
				<Card.Body>
					{/* <div style={{ float: 'right' , }}>  */}
					<Col className="mb-2"
					>
						<Form.Control style={{ height: 39 }}
							type="text"
							placeholder="Tìm nguyên liệu"
							value={this.state.ingredient_search}
							onChange={
								(e) => {
									this.handleIngredentSearch(this.state.liststore, e.target.value)
								}
							}
						/>
					</Col>
					{/* </div> */}
					<Tabs
						activeKey={this.state.tabkey}
						onSelect={(k) => {
							this.setState({ tabkey: k });
							this.getStoreData(Number(k) + 1, 100, this.state.startDate);
							this.handleIngredentSearch(this.state.liststore, '')
							this.setState({ ingredient_search: '' })
								;
						}}
						className="mb-3"
					>
						<Tab
							style={{ height: "650px", overflowY: "auto" }}
							key={1}
							title="Kho"
							eventKey={1}
						>
							{/* <Col
								xs={7} sm={12}>
								<Form.Control style={{ height: 39 }}
									type="text"
									placeholder="Tìm nguyên liệu"
									value={this.state.ingredient_search}
									onChange={
										(e) => {
											this.handleIngredentSearch(this.state.liststore, e.target.value.trim())
										}
									}
								/>
							</Col> */}
							{this.state.liststore_search.length > 0 ?
//here
							
								this.dataTable()
								: <p className="text-center">Không có dữ liệu ...</p>}
						</Tab>
						<Tab
							style={{ height: "650px", overflowY: "auto" }}
							key={2}
							title="Vật tư"
							eventKey={2}

						>
							{/* <Col
								xs={7} sm={12}>
								<Form.Control style={{ height: 39 }}
									type="text"
									placeholder="Tìm nguyên liệu"
									onChange={
										(e) => {
											this.handleIngredentSearch(this.state.liststore, e.target.value.trim())
										}
									}
								/>
							</Col> */}
								{this.state.liststore_search.length > 0 ?
//here
							
								this.dataTable()
								: <p className="text-center">Không có dữ liệu ...</p>}
							
						</Tab>
					</Tabs>
				</Card.Body>
			</>
		);
	}

	renderStoreAdd() {
		return (
			<StoreCreate
				show={this.state.showcreate}
				tabkey={this.state.tabkey}
				onHide={() => {
					this.setState({ showcreate: false });
				}}
				getStoreData={(key) => {
					this.setState({ showcreate: false });
					this.getStoreData(Number(key) + 1, 100, this.state.startDate);
				}}
				suppliers={this.state.suppliers}
				propsdata={this.state.propsdata}
			/>
		);
	}

	render() {
		return (
			<>
				<Container fluid>
					{/* {this.renderSelectedRows()} */}
					<Breadcrumb className="mb-2 mt-2">
						<Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
						<Breadcrumb.Item active>Xuất-Nhập-Tồn</Breadcrumb.Item>
					</Breadcrumb>
					<Row>
						<Col>
							<Card>{this.renderStoreList()}</Card>
						</Col>

					</Row>
				</Container>

				<Modal show={this.state.show} onHide={() => this.setState({ show: false })} size={"sm"} >
					<Modal.Header closeButton>
						<Modal.Title>
							Chọn thời gian
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<center>
							<DatePicker
								size={{ height: "600px" }}
								selected={this.state.startDate}
								onChange={(update) => {
									this.setState({ startDate: update[0] })
									this.setState({ endDate: update[1] })
								}}
								startDate={this.state.startDate}
								endDate={this.state.endDate}
								// minDate={new Date()}
								selectsRange
								inline
							/>
						</center>
						<br />

					</Modal.Body>
					<Modal.Footer>
						<Button size="sm" onClick={() =>
							this.setState({ show: false })}>Hủy</Button>
						<Button
							size="sm"
							disabled={this.state.startDate === null || this.state.endDate === null}
							onClick={() => {
								this.setState({ show: false })
								this.getStoreData(parseInt(this.state.tabkey) + 1, 100, this.state.startDate)
							}
							}
						>Lưu</Button>
					</Modal.Footer>
				</Modal>

				{this.detailinventoryview()}
				{this.updateinventoryview()}
				{this.deleteinventoryview()}
				{this.hisinventoryview()}
				{this.renderStoreAdd()}
			</>
		);
	}
}

export default StoreIndex;
