import React, { Component } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";

class StoreBookModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props?.data || []
        };
    }

    exportExcelStyle = () => {
        const borderStyle = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
        };

        const fontStyle = {
            name: "Times New Roman",
            sz: 9,
        };

        const MAX_WITH_A4_LANDSCAPE = 116;

        const table = document.getElementById("store-book");
        var ws = XLSX.utils.table_to_sheet(table);

        const initColAlpha = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
        ];

        const colsLength = 12;

        const colAlpha = initColAlpha.slice(0, colsLength);

        var wscols = [
            { wch: 4 },
            { wch: 20 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
            { wch: 9 },
        ];

        //Merge Title
        const merge = [
            {
                s: { r: 0, c: 0 },
                e: { r: 0, c: 11 }
            },
            {
                s: { r: 1, c: 0 },
                e: { r: 1, c: 11 }
            },

            {
                s: { r: 3, c: 0 },
                e: { r: 4, c: 0 }
            },
            {
                s: { r: 3, c: 1 },
                e: { r: 4, c: 1 }
            },
            {
                s: { r: 3, c: 2 },
                e: { r: 4, c: 2 }
            },
            {
                s: { r: 3, c: 3 },
                e: { r: 4, c: 3 }
            },


            {
                s: { r: 3, c: 4 },
                e: { r: 3, c: 5 }
            },
            {
                s: { r: 3, c: 6 },
                e: { r: 3, c: 7 }
            },
            {
                s: { r: 3, c: 8 },
                e: { r: 3, c: 9 }
            },
            {
                s: { r: 3, c: 10 },
                e: { r: 3, c: 11 }
            }
        ];

        ws[`A1`].s = {
            font: {
                ...fontStyle,
                sz: 12,
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center",
            },
        };

        ws[`A2`].s = {
            font: {
                ...fontStyle,
            },
            alignment: {
                vertical: "center",
                horizontal: "center",
            },
        };

        colAlpha.map((alpha) => {
            for (let i = 4; i <= 5; i++) {
                ws[`${alpha}${i}`].s = {
                    font: {
                        ...fontStyle,
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                    },
                    border: borderStyle,
                    fill: { fgColor: { rgb: "B5EAD7" } },
                };
            }

            return alpha;
        });

        const startRowTitle = 4;
        const endRowTitle = 5;
        const rowsIngredientLength = this.state.data.length;
        const moneyFormat = "#,###,###,###";
        const numberFormat = "#,###,###,##0.0000";

        colAlpha.map((alpha) => {
            for (let i = startRowTitle; i <= endRowTitle; i++) {
                ws[`${alpha}${i}`].s = {
                    font: {
                        ...fontStyle,
                        bold: true,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                    },
                    border: borderStyle,
                    fill: { fgColor: { rgb: "B5EAD7" } },
                };
            }

            for (let i = endRowTitle + 1; i <= rowsIngredientLength + endRowTitle + 1; i++) {
                ws[`${alpha}${i}`].s = {
                    font: {
                        ...fontStyle,
                        bold: i === rowsIngredientLength + endRowTitle + 1 ? true : false,
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: alpha === "A" || alpha === "C" ? "center" : false,
                    },
                    border: borderStyle,
                };

                if ((alpha === "D" || alpha === "F" || alpha === "H" || alpha === "J" || alpha === "L") && i > endRowTitle) {
                    ws[`${alpha}${i}`].z = moneyFormat;
                    delete ws[`${alpha}${i}`].w;
                    XLSX.utils.format_cell(ws[`${alpha}${i}`]);
                }

                if ((alpha === "E" || alpha === "G" || alpha === "I" || alpha === "K") && i > endRowTitle) {
                    ws[`${alpha}${i}`].z = numberFormat;
                    delete ws[`${alpha}${i}`].w;
                    XLSX.utils.format_cell(ws[`${alpha}${i}`]);
                }
            }

            return alpha;
        });


        ws["!merges"] = merge;
        ws["!cols"] = wscols;
        ws["!rows"] = [{ hpt: 24 }];

        return ws;
    };

    handleExportExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = this.exportExcelStyle();
        XLSX.utils.book_append_sheet(wb, ws, "Sổ kho");
        XLSX.writeFile(wb, "Sổ kho.xlsx");
    };

    render() {
        let sumMoneyBegingBalance = 0;
        let sumMoneyImport = 0;
        let sumMoneyExport = 0;
        let sumMoneyEndingBalance = 0;

        let sumMoneyBegingBalance2 = 0;
        let sumMoneyImport2 = 0;
        let sumMoneyExport2 = 0;
        let sumMoneyEndingBalance2 = 0;

        return (<Modal
            {...this.props}
            size="xl"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Sổ kho ({this.props.dateFilter})</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table bordered>
                    <thead className="text-center align-middle bg-light">
                        <tr>
                            <th rowSpan={2}>STT</th>
                            <th rowSpan={2}>Tên nguyên liệu</th>
                            <th rowSpan={2}>Đơn vị</th>
                            <th rowSpan={2}>Đơn giá</th>
                            <th colSpan={2}>Tồn đầu kỳ</th>
                            <th colSpan={2}>Nhập trong kỳ</th>
                            <th colSpan={2}>Xuất trong kỳ</th>
                            <th colSpan={2}>Tồn cuối kỳ</th>
                        </tr>
                        <tr>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.data.length > 0
                            ? this.state.data.map((item, index) => {
                                sumMoneyBegingBalance += parseFloat(item.beginning_inventory_value);
                                sumMoneyImport += parseFloat(item.total_money_import);
                                sumMoneyExport += parseFloat(item.total_money_import);
                                sumMoneyEndingBalance += parseFloat(item.ending_inventory_value);

                                const priceAvg = Number(Math.abs(item.ending_inventory_value) / Math.abs(item.ending_balance)).toFixed(0);

                                return (
                                    <tr key={`store-book-item-${index}`}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{item.ingredent_name}</td>
                                        <td className="text-center">{item.unit_name}</td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(priceAvg)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.beginning_balance) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.beginning_inventory_value) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_qty_import) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_money_import) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_qty_export) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_money_export) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.ending_balance) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.ending_inventory_value) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                            : (<></>)
                        }
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyBegingBalance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyImport}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyExport}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyEndingBalance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                        </tr>
                    </tfoot>
                </Table>

                <Table bordered id="store-book" className="d-none">
                    <thead className="text-center align-middle">
                        <tr>
                            <th>SỔ KHO</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <th>({this.props.dateFilter})</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <th>STT</th>
                            <th>Tên nguyên liệu</th>
                            <th>Đơn vị</th>
                            <th>Đơn giá</th>
                            <th>Tồn đầu kỳ</th>
                            <th>&nbsp;</th>
                            <th>Nhập trong kỳ</th>
                            <th>&nbsp;</th>
                            <th>Xuất trong kỳ</th>
                            <th>&nbsp;</th>
                            <th>Tồn cuối kỳ</th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                            <th>Số lượng</th>
                            <th>Thành tiền</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.data.length > 0
                            ? this.state.data.map((item, index) => {
                                sumMoneyBegingBalance2 += parseFloat(item.beginning_inventory_value);
                                sumMoneyImport2 += parseFloat(item.total_money_import);
                                sumMoneyExport2 += parseFloat(item.total_money_import);
                                sumMoneyEndingBalance2 += parseFloat(item.ending_inventory_value);

                                const priceAvg = Number(Math.abs(item.ending_inventory_value) / Math.abs(item.ending_balance)).toFixed(0);

                                return (
                                    <tr key={`store-book-item-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>{item.ingredent_name}</td>
                                        <td className="text-center">{item.unit_name}</td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(priceAvg)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.beginning_balance) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.beginning_inventory_value) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_qty_import) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_money_import) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_qty_export) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.total_money_export) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.ending_balance) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <CurrencyFormat
                                                value={parseFloat(item.ending_inventory_value) || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                            : (<></>)
                        }
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyBegingBalance2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyImport2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyExport2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                            <td>&nbsp;</td>
                            <th className="text-end">
                                <CurrencyFormat
                                    value={sumMoneyEndingBalance2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={() => this.props.onHide()}>
                    <i className="fa-solid fa-times"></i> Đóng
                </Button>

                <Button size="sm" variant="success" onClick={() => this.handleExportExcel()}>
                    <i className="fa-solid fa-file-export"></i> Xuất excel
                </Button>
            </Modal.Footer>



        </Modal>);
    }
}

export default StoreBookModal;
