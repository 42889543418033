import React, { Component } from "react";
import axios from "axios";
import { Container, Breadcrumb, Card, Tab, Tabs, Table, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import Check from "../other/Check";
import vi from "date-fns/locale/vi";
registerLocale('vi', vi)

class FormSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSetting: {
                // Sample book
                book_3: [],
                book_6: "",
                book_7: "",
                book_8: [],
                book_9: [],
                book_11: "",
                book_12: "",

                // Step 1
                step1_m1_3: new Date().getTime() / 1000,
                step1_m2_5: new Date().getTime() / 1000,
                step1_m2_11: "",

                // Step 2
                step2_6: [],
                step2_7: [],
                step2_8: [],
                step2_9: "",
                step2_10: "",

                // Step 3
                step3_2: [],
                step3_5: [],
                step3_6: [],
                step3_7: "",
            },
            isLoading: false,
            listTime: [],
            userOption: []
        };
    }

    async componentDidMount() {
        document.title = 'Cài đặt biểu mẫu';

        this.getlistsetting();
        this.getUserData();
    }

    //#region Fetch API
    getSettingFormData(listTime) {
        this.setState({
            isLoading: true
        })

        axios
            .get("/viewSettingForm")
            .then((res) => {
                this.setState({
                    isLoading: false
                })

                if (res.data.status) {

                    let initialFormSetting = res.data.data[0];

                    if (initialFormSetting.step2_8 !== "") {
                        initialFormSetting.step2_8 = JSON.parse(initialFormSetting.step2_8)
                    }

                    if (initialFormSetting.step2_6.toString().toLowerCase() === "null" || initialFormSetting.step2_6 === "" || initialFormSetting.step2_6.length === 0) {
                        initialFormSetting.step2_6 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.step2_7.toString().toLowerCase() === "null" || initialFormSetting.step2_7 === "" || initialFormSetting.step2_7.length === 0) {
                        initialFormSetting.step2_7 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.step3_2.toString().toLowerCase() === "null" || initialFormSetting.step3_2 === "" || initialFormSetting.step3_2.length === 0) {
                        initialFormSetting.step3_2 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.step3_5.toString().toLowerCase() === "null" || initialFormSetting.step3_5 === "" || initialFormSetting.step3_5.length === 0) {
                        initialFormSetting.step3_5 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.step3_6.toString().toLowerCase() === "null" || initialFormSetting.step3_6 === "" || initialFormSetting.step3_6.length === 0) {
                        initialFormSetting.step3_6 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.book_3.toString().toLowerCase() === "null" || initialFormSetting.book_3 === "" || initialFormSetting.book_3.length === 0) {
                        initialFormSetting.book_3 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.book_8.toString().toLowerCase() === "null" || initialFormSetting.book_8 === "" || initialFormSetting.book_8.length === 0) {
                        initialFormSetting.book_8 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    if (initialFormSetting.book_9.toString().toLowerCase() === "null" || initialFormSetting.book_9 === "" || initialFormSetting.book_9.length === 0) {
                        initialFormSetting.book_9 = listTime.map(time => {
                            return {
                                time: time.id,
                                val: 1688432400225
                            }
                        })
                    }

                    this.setState({
                        formSetting: initialFormSetting
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updateSettingFormData() {
        axios
            .post("/updateSettingForm", null, {
                params: {
                    step1_m1_3: this.state.formSetting.step1_m1_3,
                    step1_m2_5: this.state.formSetting.step1_m2_5,
                    step1_m2_11: this.state.formSetting.step1_m2_11,

                    step2_6: JSON.stringify(this.state.formSetting.step2_6),
                    step2_7: JSON.stringify(this.state.formSetting.step2_7),
                    step2_8: JSON.stringify(this.state.formSetting.step2_8),
                    step2_9: this.state.formSetting.step2_9,
                    step2_10: this.state.formSetting.step2_10,

                    step3_2: JSON.stringify(this.state.formSetting.step3_2),
                    step3_5: JSON.stringify(this.state.formSetting.step3_5),
                    step3_6: JSON.stringify(this.state.formSetting.step3_6),
                    step3_7: this.state.formSetting.step3_7,

                    book_3: JSON.stringify(this.state.formSetting.book_3),
                    book_6: this.state.formSetting.book_6,
                    book_7: this.state.formSetting.book_7,
                    book_8: JSON.stringify(this.state.formSetting.book_8),
                    book_9: JSON.stringify(this.state.formSetting.book_9),
                    book_11: this.state.formSetting.book_11,
                    book_12: this.state.formSetting.book_12,
                }
            })
            .then((res) => {
                if (res.data.status) {
                    this.getSettingFormData();
                    toast("Cập nhật biểu mẫu thành công", {
                        type: "success",
                        autoClose: 1000,
                    });
                } else {
                    toast("Cập nhật biểu mẫu thất bại", {
                        type: "error",
                        autoClose: 1000,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getlistsetting = () => {
        axios
            .get(`/viewSetting?id_classify=1`)
            .then((res) => {
                if (res.data.status === true) {
                    this.setState({
                        listTime: res.data.data.time
                    });

                    this.getSettingFormData(res.data.data.time);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getUserData() {
        axios
            .get("/showAllUser")
            .then((res) => {
                if (res.data.status) {
                    this.setState({
                        userOption: res.data.data.map(user => {
                            return {
                                value: user.id,
                                label: user.user_name
                            }
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //#endregion 

    // Render
    RenderBook1() {
        return (
            <>
                {/* I */}
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={15}>I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(3)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-start align-middle" style={{ padding: "5px 20%" }}>
                                <DatePicker
                                    selected={this.state.formSetting.step1_m1_3 ? this.state.formSetting.step1_m1_3 : new Date().getTime() / 1000}
                                    onChange={(date) => this.setState({
                                        formSetting: {
                                            ...this.state.formSetting,
                                            step1_m1_3: date.getTime(),
                                        }
                                    })}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    locale="vi"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Thời gian"
                                    dateFormat="HH:mm aaaa"
                                    className="text-center form-control"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {/* II */}
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={15}>II. Thực phẩm khô và thực gói sẵn, phụ gia thực phẩm</th>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
                            <td className="align-middle">Điều kiện bảo quản (T° thường, lạnh...)</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(11)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-start align-middle">
                                <DatePicker
                                    selected={this.state.formSetting.step1_m2_5 ? this.state.formSetting.step1_m2_5 : new Date().getTime() / 1000}
                                    onChange={(date) => this.setState({
                                        formSetting: {
                                            ...this.state.formSetting,
                                            step1_m2_5: date.getTime(),
                                        }
                                    })}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    locale="vi"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Thời gian"
                                    dateFormat="HH:mm aaaa"
                                    className="text-center form-control"
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={this.state.formSetting.step1_m2_11}
                                    onChange={(e) => {
                                        this.setState({
                                            formSetting: {
                                                ...this.state.formSetting,
                                                step1_m2_11: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    RenderBook2() {
        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr className="text-center">
                            <td className="align-middle" rowSpan={2}>Buổi</td>
                            <td className="align-middle" rowSpan={2}>Thời gian sơ chế xong (ngày, giờ)</td>
                            <td className="align-middle" rowSpan={2}>Thời gian chế biến xong (ngày, giờ)</td>
                            <td className="align-middle" colSpan={3}>
                                Kiểm tra điều kiện vệ sinh (từ thời điểm bắt đầu sơ chế, chế biến cho đến khi thức ăn được chế biến xong)
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle">Người tham gia chế biến</td>
                            <td className="align-middle">Trang thiết bị dụng cụ</td>
                            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle"></td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(10)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.listTime.map((time, index) => {

                                if (time.is_active === 0) {
                                    return "";
                                }

                                return (
                                    <tr key={time.id}>
                                        <td>{time.time_name}</td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.step2_6 !== "" ? Number(this.state.formSetting.step2_6[index]?.val) ? Number(this.state.formSetting.step2_6[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            step2_6: this.state.formSetting.step2_6?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }
                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.step2_7 !== "" ? Number(this.state.formSetting.step2_7[index]?.val) ? Number(this.state.formSetting.step2_7[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            step2_7: this.state.formSetting.step2_7?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>

                                        {
                                            index === 0
                                                ?
                                                <>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Select
                                                            options={this.state.userOption}
                                                            placeholder={<div>Chọn người tham gia</div>}
                                                            value={this.state.formSetting.step2_8}
                                                            onChange={(choice) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        step2_8: choice,
                                                                    }
                                                                })
                                                            }}
                                                            isMulti
                                                            menuPortalTarget={document.querySelector('body')}
                                                        />
                                                    </td>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.step2_9}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        step2_9: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.step2_10}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        step2_10: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                                : ""
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    RenderBook3() {
        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr className="text-center">
                            <td className="align-middle">Buổi</td>
                            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn...)</td>
                            <td className="align-middle">Thời gian chia món ăn xong (ngày, giờ)</td>
                            <td className="align-middle">Thời gian bắt đầu ăn (ngày, giờ)</td>
                            <td className="align-middle">
                                Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle"></td>
                            <td className="align-middle">(2)</td>
                            <td className="align-middle">(5)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.listTime.map((time, index) => {

                                if (time.is_active === 0) {
                                    return "";
                                }

                                return (
                                    <tr key={time.id}>
                                        <td>{time.time_name}</td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.step3_2 !== "" ? Number(this.state.formSetting.step3_2[index]?.val) ? Number(this.state.formSetting.step3_2[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            step3_2: this.state.formSetting.step3_2?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.step3_5 !== "" ? Number(this.state.formSetting.step3_5[index]?.val) ? Number(this.state.formSetting.step3_5[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            step3_5: this.state.formSetting.step3_5?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.step3_6 !== "" ? Number(this.state.formSetting.step3_6[index]?.val) ? Number(this.state.formSetting.step3_6[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            step3_6: this.state.formSetting.step3_6?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        {
                                            index === 0
                                                ?
                                                <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                    <Form.Control
                                                        type="text"
                                                        value={this.state.formSetting.step3_7}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                formSetting: {
                                                                    ...this.state.formSetting,
                                                                    step3_7: e.target.value,
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                : ""
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    RenderSampleBook() {
        return (
            <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 999 }}>
                    <thead>
                        <tr className="text-center">
                            <td className="align-middle">Buổi</td>
                            <td className="align-middle">Bữa ăn (giờ ăn...)</td>
                            <td className="align-middle">Dụng cụ chứa mẫu thức ăn lưu</td>
                            <td className="align-middle">Nhiệt độ bảo quản mẫu (℃)</td>
                            <td className="align-middle">Thời gian lấy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Thời gian hủy mẫu (giờ, ngày, tháng, năm)</td>
                            <td className="align-middle">Người lưu mẫu (ký và ghi rõ họ tên)</td>
                            <td className="align-middle">Người hủy mẫu (ký và ghi rõ họ tên)</td>
                        </tr>
                        <tr className="text-center">
                            <td className="align-middle"></td>
                            <td className="align-middle">(3)</td>
                            <td className="align-middle">(6)</td>
                            <td className="align-middle">(7)</td>
                            <td className="align-middle">(8)</td>
                            <td className="align-middle">(9)</td>
                            <td className="align-middle">(11)</td>
                            <td className="align-middle">(12)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.listTime.map((time, index) => {
                                if (time.is_active === 0) {
                                    return "";
                                }

                                return (
                                    <tr key={time.id}>
                                        <td>{time.time_name}</td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.book_3 !== "" ? Number(this.state.formSetting.book_3[index]?.val) ? Number(this.state.formSetting.book_3[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            book_3: this.state.formSetting.book_3?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        {
                                            index === 0
                                                ?
                                                <>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.book_6}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        book_6: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.book_7}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        book_7: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                                : ""
                                        }

                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.book_8 !== "" ? Number(this.state.formSetting.book_8[index]?.val) ? Number(this.state.formSetting.book_8[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            book_8: this.state.formSetting.book_8?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.formSetting.book_9 !== "" ? Number(this.state.formSetting.book_9[index]?.val) ? Number(this.state.formSetting.book_9[index]?.val) : new Date() : new Date()}
                                                onChange={(date) => {
                                                    this.setState({
                                                        formSetting: {
                                                            ...this.state.formSetting,
                                                            book_9: this.state.formSetting.book_9?.map((item, indexObj) => {

                                                                if (index === indexObj) {
                                                                    return {
                                                                        ...item,
                                                                        val: date.getTime()
                                                                    }
                                                                }

                                                                return item
                                                            }),
                                                        }
                                                    })
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                locale="vi"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Thời gian"
                                                dateFormat="HH:mm aaaa"
                                                className="text-center form-control"
                                            />
                                        </td>
                                        {
                                            index === 0
                                                ?
                                                <>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.book_11}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        book_11: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td rowSpan={this.state.listTime.length} className="text-center align-middle">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.formSetting.book_12}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    formSetting: {
                                                                        ...this.state.formSetting,
                                                                        book_12: e.target.value,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                                : ""
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
                    <Breadcrumb.Item active>Cài đặt biểu mẫu</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <Card.Title>
                            <i className="fas fa-list me-1"></i> Cài đặt biểu mẫu
                        </Card.Title>
                        {
                            new Check().permission(['80']) ?
                                <Button
                                    variant="success"
                                    size="sm"
                                    className=""
                                    onClick={() => {
                                        this.updateSettingFormData();
                                    }}
                                >
                                    <i className="fa-solid fa-pencil"></i> Cập nhật biểu mẫu
                                </Button>
                                : null
                        }
                    </Card.Header>
                    <Card.Body>
                        {
                            !this.state.isLoading
                                ?
                                <Tabs
                                    defaultActiveKey="1"
                                    className="mb-3"
                                >
                                    <Tab eventKey="1" title="Bước 1">
                                        {this.RenderBook1()}
                                    </Tab>

                                    <Tab eventKey="2" title="Bước 2">
                                        {this.RenderBook2()}
                                    </Tab>

                                    <Tab eventKey="3" title="Bước 3">
                                        {this.RenderBook3()}
                                    </Tab>

                                    <Tab eventKey="4" title="Mẫu ngày">
                                        {this.RenderSampleBook()}
                                    </Tab>
                                </Tabs>
                                :
                                <div className="d-flex justify-content-center align-items-center mt-3">
                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span className="sr-only">Đang tải cài đặt biểu mẫu...</span>
                                    </div>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default FormSetting;