import { toast } from "react-toastify";

export const toastError = (error) => {
    let message = "";

    if (error.response.status === 400 && error.response?.data?.messages) {
        const jsonObject = error.response.data.messages;
        for (const [key, value] of Object.entries(jsonObject)) {
            message += `${key > 0 ? `, ` : ``}${value}`;
        }
    } else {
        message = error.message;
    }

    return toast(`${message}`, { type: "error" });
}

export const toastSuccess = (message) => {
    return toast(`${message}`, { type: "success" });
}