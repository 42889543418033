import React, { Component } from "react";
import { Button, Breadcrumb, Card, Container, Table, Modal} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
registerLocale('vi', vi)

class AbsentReportIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classifies: [],
			idClassify: 1,

			listStaff: [],
			listAbsent: [],
			isLoading: false,

			schoolInfo: {},

			startDate: new Date(),
			endDate: new Date(),
			start: new Date(),
			end:new Date(),

			tmpStartDate: new Date(),
			tmpEndDate: new Date(),

			show: false
		};
	}

	async componentDidMount() {
		document.title = 'Báo cáo ngày nghỉ';

		const date = new Date();

		const start = new Date(date.getFullYear(), date.getMonth(), 1);
		start.setHours(0, 0, 0, 0);

		const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		end.setHours(23, 59, 59, 0);

		this.setState({
			startDate: start,
			endDate: end,

			tmpStartDate: start,
			tmpEndDate: end,
		});

		this.getDetailschool();
		this.getClassifyData();
		this.getStaffData(start, end);
	}

	//#region Fetch Api
	getDetailschool = () => {
		axios.get(`/detailSchool`)
			.then(res => {
				if (res.data.status === true) {
					this.setState({
						schoolInfo: res.data.data[0]
					})
				}
			});
	}

	getClassifyData() {
		axios
			.get("/viewClassify")
			.then((res) => {
				if (res.data.status) {
					this.setState({
						classifies: res.data.data,
						idClassify: res.data.data[0].id
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	setClassify(id) {
		this.setState({
			idClassify: id,
		})
	}

	getStaffData(start, end) {
		axios
			.get("/showAllUser")
			.then((res) => {
				if (res.data.status) {
					this.setState({
						listStaff: res.data.data
					});

					this.getListMealByClassData(res.data.data, start, end);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getListMealByClassData(staffs, start, end) {
		this.setState({
			isLoading: true
		})
		
		axios
			.post(`/viewMeal?id_classify=2`)
			.then((res) => {
				this.setState({
					isLoading: false
				})

				if (res.data.status) {
					const dateFilter = res.data.data.filter(absentDay => start.getTime() / 1000 <= absentDay.meal_day && absentDay.meal_day <= end.getTime() / 1000);
					this.setState({
						listAbsent: staffs.map(staff => {
							return {
								id: staff.id,
								username: staff.user_name,
								absent: dateFilter.filter(absentDay => Number(absentDay.id_user) === Number(staff.id) && Number(absentDay.is_status) !== 0)
							}
						})
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	//#endregion

	//#region Handle Logic
	xport = (numberIndex) => {
		const table = document.getElementById("staff-absent-report");

		const wb = XLSX.utils.book_new();

		var ws = XLSX.utils.table_to_sheet(table, { raw: true })

		const colAlpha = ["A", "B", "C", "D", "E", "F"];

		var wscols =
			[
				{ wch: 4 },
				{ wch: 20 },
				{ wch: 13 },
				{ wch: 10 },
				{ wch: 15 },
				{ wch: 20 }
			];

		ws['!cols'] = wscols;
		ws['!rows'] = [{ hpt: 20 }, { hpt: 35 }, { hpt: 25 }]

		// Style cell
		ws[`A1`].s = {
			font: {
				name: "Times New Roman",
				bold: true
			},
			alignment: {
				vertical: "center",
				horizontal: "left"
			}
		};

		ws[`A2`].s = {
			font: {
				name: "Times New Roman",
				sz: 16,
				bold: true
			},
			alignment: {
				vertical: "center",
				horizontal: "center"
			}
		};

		ws[`A3`].s = {
			font: {
				name: "Times New Roman",
				sz: 14,
				bold: true
			},
			alignment: {
				vertical: "top",
				horizontal: "center"
			}
		};

		colAlpha.map((alpha) => {
			ws[`${alpha}4`].s = {
				font: {
					name: "Times New Roman",
					sz: 11,
					bold: true,
				},
				alignment: {
					vertical: "center",
					horizontal: "center"
				},
				border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
			};

			return alpha;
		});

		for (let i = 5; i <= numberIndex + 4; i++) {
			colAlpha.map((alpha) => {
				if(ws[`${alpha}${i}`].v === "empty"){
					ws[`${alpha}${i}`].v = ' '
				}

				ws[`${alpha}${i}`].s = {
					font: {
						name: "Times New Roman",
						sz: 11,
					},
					alignment: {
						wrapText: true,
						vertical: "center",
						horizontal: alpha === "A" || alpha === "D" ? "center" : ""
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};
	
				return alpha;
			});
		}

		colAlpha.map((alpha) => {
			if(ws[`${alpha}${5 + numberIndex}`].v === "empty"){
                ws[`${alpha}${5 + numberIndex}`].v = ' '
            }

			ws[`${alpha}${5 + numberIndex}`].s = {
				font: {
					name: "Times New Roman",
					sz: 11,
					bold: true,
				},
				alignment: {
					vertical: "center",
					horizontal: "center"
				},
				border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" },
                },
			};

			return alpha;
		});

		const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 5  } },
        ];

		let indexCell = 4;

		this.state.listAbsent.map(absent => {
			if (absent.absent.length === 0) {
				indexCell += 1;

				return absent;
			}

			merge.push({ s: { r: indexCell, c: 1 }, e: { r: indexCell+absent.absent.length-1, c: 1 } })
			merge.push({ s: { r: indexCell, c: 2 }, e: { r: indexCell+absent.absent.length-1, c: 2 } })

			indexCell += absent.absent.length;

			return absent;
		});

        ws["!merges"] = merge;

		XLSX.utils.book_append_sheet(wb, ws, "Ngày nghỉ nhân viên");

		XLSX.writeFile(wb, "Báo cáo ngày nghỉ nhân viên.xlsx");
	};

	stringDate = (dateNumber) => {
		const date = new Date(dateNumber * 1000);
		date.setHours(0, 0, 0, 0);
		return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
	}
	//#endregion

	render() {
		let numberIndex = 0;
		var total = 0;

		let senNumberIndex = 0;
		var senTotal = 0;
		return (
			<Container fluid >
				<Breadcrumb>
					<Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
					<Breadcrumb.Item active>
						Ngày nghỉ nhân viên
					</Breadcrumb.Item>
				</Breadcrumb>
				<Card>
					<Card.Header>
						<div className="d-block d-md-flex justify-content-between">
							<div className="d-block d-md-flex">
								<Card.Title>
									<i className="fas fa-list me-1" />
									Báo cáo ngày nghỉ nhân viên từ ngày {this.stringDate(this.state.startDate/1000)} đến  {this.stringDate(this.state.endDate/1000)}
								</Card.Title>

                                <Button
									size="sm"
									className="ms-2 mt-3 mt-md-0"
									onClick={() => { this.setState({ show: true }) }}
								>
									<i className="fa-solid fa-calendar-days"></i> 
                                </Button>
                            </div>

							<Button
								variant="success"
								size="sm"
								className="mt-3 mt-md-0"
								onClick={() => this.xport(numberIndex)}
							>
								Xuất file <i className="fa-solid fa-file-export"></i>
							</Button>
						</div>
					</Card.Header>
					<Card.Body>
						{	
							!this.state.isLoading
							?
								<>
									<Table bordered>
										<thead>
											<tr>
												<th colSpan={6}>{this.state.schoolInfo.school_name?.toUpperCase()}</th>
											</tr>
											<tr className="text-center h4">
												<th colSpan={6}>Danh sách ngày nghỉ nhân viên</th>
											</tr>
											<tr className="text-center h5">
												<th colSpan={6}>Từ ngày {this.stringDate(this.state.startDate.getTime() / 1000)} đến ngày {this.stringDate(this.state.endDate.getTime() / 1000)}</th>
											</tr>
											<tr className="text-center">
												<th>STT</th>
												<th>Họ và tên</th>
												<th>SL ngày nghỉ</th>
												<th>Ngày</th>
												<th>Buổi</th>
												<th>Lý do</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.listAbsent.map(absent => {
													absent.absent.map(absent_ => {
														if (absent_?.is_status) total += 1;

														return absent_
													})
													numberIndex += 1;


													if (absent.absent.length === 0) {
														return (
															<tr key={absent.id}>
																<td className="text-center">{numberIndex}</td>
																<td>{absent.username}</td>
																<td className="text-center">{absent.absent.length}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														)
													}

													return (
														<React.Fragment key={absent.id}>
															<tr>
																<td className="text-center">{numberIndex}</td>
																<td className="align-middle" rowSpan={absent.absent.length}>{absent.username}</td>
																<td className="text-center align-middle" rowSpan={absent.absent.length}>{absent.absent.length}</td>

																<td>
																	{this.stringDate(absent.absent[0]?.meal_day)}
																</td>
																<td>
																	{Number(absent.absent[0]?.type_meal) === 1 ? 'Nguyên ngày' : absent.absent[0]?.detail.map((item, index) =>
																		item.time_name + ((index + 1 === absent.absent[0]?.detail.length) ? "" : ", ")
																	)}
																</td>
																<td>
																	{absent.absent[0].note}
																</td>
															</tr>

															{
																absent.absent.slice(1, absent.absent.length)?.map((dayAbsent, index) => {
																	numberIndex += 1;
																	return (
																		<tr key={index}>
																			<td className="text-center">{numberIndex}</td>
																			<td>{this.stringDate(dayAbsent.meal_day)}</td>
																			<td> 
																				{Number(dayAbsent?.type_meal) === 1 ? 'Nguyên ngày' :dayAbsent?.detail.map((item, index) =>
																					item.time_name + ((index + 1 === dayAbsent?.detail.length) ? "" : ", ")
																				)}
																			</td>
																			<td>{dayAbsent.note}</td>
																		</tr>
																	)
																})
															}
														</React.Fragment>
													)
												})
											}
											<tr>
												<th></th>
												<th className="text-center">Tổng</th>
												<th className="text-center">{total}</th>
												<th></th>
												<th></th>
												<th></th>
											</tr>
										</tbody>
									</Table>

									<Table
										bordered 
										id="staff-absent-report"
										className="d-none"
									>
										<thead>
											<tr>
												<th>{this.state.schoolInfo.school_name?.toUpperCase()}</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
											</tr>
											<tr className="text-center h4">
												<th>Danh sách ngày nghỉ nhân viên</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
											</tr>
											<tr className="text-center h5">
												<th>Từ ngày {this.stringDate(this.state.startDate.getTime() / 1000)} đến ngày {this.stringDate(this.state.endDate.getTime() / 1000)}</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
											</tr>
											<tr className="text-center">
												<th>STT</th>
												<th>Họ và tên</th>
												<th>SL ngày nghỉ</th>
												<th>Ngày</th>
												<th>Buổi</th>
												<th>Lý do</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.listAbsent.map(absent => {
													absent.absent.map(absent_ => {
														if (absent_?.is_status) senTotal += 1;

														return absent_
													})
													senNumberIndex += 1;

													if (absent.absent.length === 0) {
														return (
															<tr key={absent.id}>
																<td className="text-center">{senNumberIndex}</td>
																<td>{absent.username}</td>
																<td className="text-center">{absent.absent.length}</td>
																<td>empty</td>
																<td>empty</td>
																<td>empty</td>
															</tr>
														)
													}

													return (
														<React.Fragment key={absent.id}>
															<tr>
																<td className="text-center">{senNumberIndex}</td>
																<td className="align-middle">{absent.username}</td>
																<td className="text-center align-middle">{absent.absent.length}</td>

																<td>
																	{this.stringDate(absent.absent[0]?.meal_day)}
																</td>
																<td>
																	{Number(absent.absent[0]?.type_meal) === 1 ? 'Nguyên ngày' : absent.absent[0]?.detail.map((item, index) =>
																		item.time_name + ((index + 1 === absent.absent[0]?.detail.length) ? "" : ", ")
																	)}
																</td>
																<td>
																	{absent.absent[0].note}
																</td>
															</tr>

															{
																absent.absent.slice(1, absent.absent.length)?.map((dayAbsent, index) => {
																	senNumberIndex += 1;
																	return (
																		<tr key={index}>
																			<td className="text-center">{senNumberIndex}</td>
																			<td>empty</td>
																			<td>empty</td>
																			<td>{this.stringDate(dayAbsent.meal_day)}</td>
																			<td> 
																				{Number(dayAbsent?.type_meal) === 1 ? 'Nguyên ngày' :dayAbsent?.detail.map((item, index) =>
																					item.time_name + ((index + 1 === dayAbsent?.detail.length) ? "" : ", ")
																				)}
																			</td>
																			<td>{dayAbsent.note}</td>
																		</tr>
																	)
																})
															}
														</React.Fragment>
													)
												})
											}
											<tr>
												<th>empty</th>
												<th className="text-center">Tổng</th>
												<th className="text-center">{senTotal}</th>
												<th>empty</th>
												<th>empty</th>
												<th>empty</th>
											</tr>
										</tbody>
									</Table>
								</>
							:
								<div className="d-flex justify-content-center align-items-center mt-5">
									<div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
										<span className="sr-only">Đang tải báo cáo ngày nghỉ nhân viên...</span>
									</div>
								</div>
						}
					</Card.Body>
				</Card>

				<Modal show={this.state.show} size={"sm"} >
					<Modal.Header>
						<Modal.Title>
							Chọn thời gian
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<center>
							<DatePicker
								size={{ height: "600px" }}
								onChange={(update) => {
									this.setState({ 
                                        tmpStartDate: update[0],
                                        tmpEndDate: update[1]
                                    })
								}}
								startDate={this.state.tmpStartDate}
								endDate={this.state.tmpEndDate}
								calendarStartDay={1}
								selectsRange
								inline
							/>
						</center>
						<br />

					</Modal.Body>
					<Modal.Footer>
						<Button 
                            size="sm"
                            onClick={() => {
                                this.setState({ show: false })
                            }}
                        >
                            Hủy
                        </Button>
						<Button
							size="sm"
							disabled={this.state.tmpStartDate === null || this.state.tmpEndDate === null}
							onClick={() => {
                                const start =this.state.tmpStartDate;
                                start.setHours(0, 0, 0, 0);

                                const end =this.state.tmpEndDate;
                                end.setHours(23, 59, 59, 0);

                                this.setState({
                                    show: false,
                                    startDate: start,
                                    endDate: end
                                })
								
								this.getListMealByClassData(this.state.listStaff, start, end);
                            }}
						>
                            Lưu
                        </Button>
					</Modal.Footer>
				</Modal>
			</Container>
			
		)
	}
}

export default AbsentReportIndex;