import React from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../utils/helpers";

const WeekQuantitativeKitchenTable = ({
	school,
	weekInMonth,
	startDate,
	endDate,
	listQuantitatives,
	listDays,
	listTimes,
	classGroups,
	selectedClassGroup,
	setSelectedClassGroups,
	show,
	onHide,
	handleExport,
}) => {
	return (
		<Modal size="sm" backdrop="static" show={show} onHide={() => onHide()}>
			<Modal.Header closeButton>
				<Modal.Title>Xuất định lượng cho bếp</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="mb-3">
					<Col xs={12} className="mb-3">
						<Select
							options={classGroups}
							placeholder={<div>Tất cả</div>}
							getOptionLabel={(option) => option.group_name}
							getOptionValue={(option) => option.id}
							value={selectedClassGroup}
							onChange={(choice) => {
								setSelectedClassGroups(choice);
							}}
						/>
					</Col>

				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button size="sm" variant="secondary" onClick={() => onHide()}>
					<i className="fa-solid fa-times"></i> Đóng
				</Button>

				<Button size="sm" type="submit" variant="success" onClick={() => { handleExport() }}>
					<i className="fa-solid fa-check"></i> Xuất
				</Button>
			</Modal.Footer>

			<Table bordered responsive id="week-quantitative-kitchen-table" className="d-none" >
				<thead className="text-center align-middle d-none">
					<tr>
						<th colSpan={5}>{school.school_name?.toUpperCase()}</th>
						<th colSpan={(listDays.length - 2) * 3}>
							ĐỊNH LƯỢNG CHO BẾP {selectedClassGroup.group_name?.toUpperCase()} THÁNG{" "}
							{("0" + (startDate.getMonth() + 1)).slice(-2) + "/" + startDate.getFullYear()} <br /> Tuần {weekInMonth} từ{" "}
							{dateFormatter(startDate)} đến {dateFormatter(endDate)}
						</th>
						<th colSpan={3}>
							<div>
								{selectedClassGroup.group_name}
								<br />
								Đơn vị tính: Gam
							</div>

						</th>
					</tr>
					<tr>
						<th colSpan={2}>Số lượng trẻ</th>
						{listQuantitatives.map((dayItem) => {
							return (
								<React.Fragment key={dayItem.id_day}>
									<th>{dayItem.costPerDays?.filter(e => e.id_group == selectedClassGroup?.id)[0]?.meal_quantity}</th>
									<th></th>
									<th></th>
								</React.Fragment>
							);
						})}

						<th colSpan={3}></th>
					</tr>
					<tr>
						<th>Buổi</th>
						<th>empty</th>
						{listDays.map((dayItem) => {
							return (
								<React.Fragment key={dayItem.id_day}>
									<th>{dayItem.day_name}</th>
									<th>empty</th>
									<th>empty</th>
								</React.Fragment>
							);
						})}
					</tr>
				</thead>

				<tbody className="align-middle">
					{listTimes.map((timeItem) => {
						const quantitativeTimes = [];
						let categoryTimes = [];

						listQuantitatives.map((quantitativeItem) => {
							return quantitativeItem.times.map((timeObj) => {
								if (timeObj.id_time === timeItem.id_time) {
									quantitativeTimes.push(timeObj);
									let filteredFoods = timeObj.foods
										.filter((foodItem) =>
											foodItem.groups.some(
												(groupItem) => groupItem.id == selectedClassGroup.id && groupItem.ingredents.length !== 0,
											),
										);

									let categoryTimesToAdd = [];

									if (filteredFoods.length > 0) {
										categoryTimesToAdd = filteredFoods.map((foodItem) => {
											return {
												id_category: foodItem.id_category,
												id_category_bank: foodItem.id_category_bank,
												category_name: foodItem.category_name,
												count_meal: quantitativeItem?.costPerDays,
												more_meal: foodItem?.groups?.filter(meal => meal.id == selectedClassGroup?.id)[0]?.more_meal ?? 0
											};
										});
									}
									// Nếu k có category thì thêm category empty 
									else {
										categoryTimesToAdd.push({
											id_category: '',
											id_category_bank: '',
											category_name: 'empty',
											count_meal: [],
											more_meal: 0
										});
									}

									categoryTimes = categoryTimes.concat(categoryTimesToAdd);
								}

								return timeObj;
							});
						});

						categoryTimes =
							[...new Map(categoryTimes.map((item) => [item["id_category"], item])).values()]
						categoryTimes
							.sort(function (a, b) {
								return (
									(a.id_category_bank === null) - (b.id_category_bank === null) ||
									+(a.id_category_bank > b.id_category_bank) ||
									-(a.id_category_bank < b.id_category_bank)
								);
							});
						// Nếu có category thì loại bỏ categort empty ra
						let dataCate = categoryTimes?.filter(ca => ca.id_category != '').length > 0 ? categoryTimes?.filter(ca => ca.id_category != '') : categoryTimes
						categoryTimes = dataCate.map((categoryItem) => {
							const listItemRows = quantitativeTimes.map((quantitativeTimeItem) => {

								let itemRow = [];
								let rowSpanLength = 0;
								quantitativeTimeItem.foods
									.filter((foodItem) => foodItem.id_category === categoryItem.id_category) ?

									quantitativeTimeItem.foods
										.filter((foodItem) => foodItem.id_category === categoryItem.id_category).map((foodItem) => {

											if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)) {
												rowSpanLength += 1;
												rowSpanLength += foodItem.groups[0].ingredents.length;
												itemRow.push({
													id: foodItem.id,
													name: foodItem.food_name,
													quantity: 0,
													marketQuantity: 0,
													isFood: true,

													more_meal: foodItem?.groups?.filter(e => e.id == selectedClassGroup?.id)[0]?.more_meal ?? 0,
												});
												foodItem.groups
													.find((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)
													.ingredents.map((ingredientItem) => {
														itemRow.push({
															id: ingredientItem.id,
															name: ingredientItem.ingredent_name,
															quantity: (ingredientItem.ing_quantity * ingredientItem.volume_market) / ingredientItem.volume_cook,
															count: listQuantitatives?.filter(e => e.id_day == quantitativeTimeItem.id_day)[0]?.costPerDays?.filter(r => r.id_group == selectedClassGroup.id)[0]?.meal_quantity
																?? 0,
															more_meal:
																foodItem?.groups?.filter(e => e.id == selectedClassGroup?.id)[0]?.more_meal ?? 0,
															// 0,
															// categoryItem.more_meal,
															marketQuantity: Number(
																(ingredientItem.ing_quantity * ingredientItem.volume_market) / ingredientItem.volume_cook,
															),
															isFood: false,
														});

														return ingredientItem;
													});
											}

											return foodItem;
										})
									: itemRow.push(
										{
											id: '',
											name: 'empty',
											quantity: 0,
											marketQuantity: 0,
											isFood: true,

											more_meal: 0,
										}
									)

								return {
									id: quantitativeTimeItem.id,
									items: itemRow,
									length: rowSpanLength !== 0 ? rowSpanLength : 1,
								};
							});


							const maxRowSpanLength = Math.max(...listItemRows.map((itemRow) => itemRow.length));

							return {
								...categoryItem,
								timeQuantitative: listItemRows.map((itemRow) => {
									return {
										...itemRow,
										length: maxRowSpanLength,
									};
								}),
								cateLength: maxRowSpanLength,
							};
						});
						return (
							<React.Fragment key={timeItem.id_time}>
								<tr>
									<th className="text-center">{timeItem.time_name ?? "empty"}</th>
									<th className="text-center">{categoryTimes[0]?.category_name ?? "empty"}</th>
									{categoryTimes[0]?.timeQuantitative?.map((rowItem, index) => {

										if (rowItem.items?.length > 0)
											return (

												<React.Fragment key={rowItem.id}>
													<th>{rowItem.items[0]?.name ?
														rowItem.items[1]?.more_meal == 0 ?
															rowItem.items[0]?.name ?? "empty"
															:
															rowItem.items[0]?.name + (
																rowItem.items[1]?.more_meal == 0 ? "" : ` (SL: ${rowItem.items[1]?.more_meal})`)

															|| "empty"
														: 'empty'
													}</th>
													<th>empty</th>
													<th>empty</th>
												</React.Fragment>
											)
										else {
											return (
												<React.Fragment key={index}>
													<th>empty</th>
													<th>empty</th>
													<th>empty</th>

												</React.Fragment>
											)
										}
									})}
								</tr>

								{categoryTimes.map((categoryTimeItem, categoryTimeItemIndex) => {
									const arr = [];

									for (let i = categoryTimeItemIndex === 0 ? 1 : 0; i < categoryTimeItem.cateLength; i++) {
										arr.push(
											<tr key={i} className="text-center">
												<th>empty</th>
												<th>{i === 0 ? categoryTimeItem.category_name : "empty"}</th>
												{categoryTimeItem?.timeQuantitative?.map((rowItem) => {
													if (rowItem?.items?.length == 0) return (
														<React.Fragment key={rowItem.id}>
															<th>empty</th>	<th>empty</th>	<th>empty</th>
														</React.Fragment>
													)
													if (rowItem.items[i]?.isFood) {
														return (
															<React.Fragment key={rowItem.id}>
																<th>{rowItem.items[i]?.name ?
																	rowItem.items[i]?.more_meal != 0 ?
																		rowItem.items[i]?.name + ` (SL: ${rowItem.items[i]?.more_meal})` :

																		rowItem.items[i]?.name
																	: 'empty'
																}</th>
																<th>empty</th>
																<th>empty</th>
															</React.Fragment>
														);
													}


													return (
														<React.Fragment key={rowItem.id}>
															<td className="text-start">{rowItem.items[i]?.name || "empty"}</td>
															<td>
																{rowItem.items[i]?.quantity ? (
																	<CurrencyFormat
																		value={rowItem.items[i]?.quantity}
																		displayType={"text"}
																		thousandSeparator={true}
																		decimalScale={2}
																	/>
																) : (
																	"empty"
																)}
															</td>
															<td>
																{rowItem.items[i]?.marketQuantity ? (
																	<CurrencyFormat
																		value={
																			rowItem.items[i]?.more_meal == 0 ?
																				rowItem.items[i]?.marketQuantity * rowItem.items[i]?.count
																				/ 1000
																				:
																				rowItem.items[i]?.marketQuantity * rowItem.items[i]?.more_meal
																				/ 1000
																		}
																		displayType={"text"}
																		thousandSeparator={true}
																		decimalScale={3}
																	/>
																) : (
																	"empty"
																)}
															</td>
														</React.Fragment>
													);
												})}
											</tr>,
										);
									}

									return arr;
								})}
							</React.Fragment>
						);
					})}
				</tbody>

				<tfoot>
					<tr>

					</tr>
					<tr className="align-middle text-center">
						{(() => {
							const arr = [];
							for (let i = 0; i < listDays.length * 3 + 2; i++) {
								if (i === 0) {
									arr.push(<th key={i}>Người lập</th>);
									continue;
								}

								if (i === Math.ceil((listDays.length * 3 + 2) / 2) + 1) {
									arr.push(<th key={i}>Hiệu phó bán trú</th>);
									continue;
								}

								arr.push(<th key={i}>empty</th>);
							}
							return arr;
						})()}
					</tr>
				</tfoot>
			</Table>
		</Modal>
	);
};

export default WeekQuantitativeKitchenTable;
