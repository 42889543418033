import axios from "axios";
import { onMessage } from "firebase/messaging";
import messaging from "../../fcm/config";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import bell from "../../assets/admin/assets/img/bell.svg";

const NotificationBell = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleMarkAsRead = async () => {
    try {
      const unreadNotifications = notifications
        ?.filter((notification) => notification.read_at === null)
        ?.map((notification) => notification.id);

      unreadNotifications.length &&
        (await axios({
          url: "notification-marks-as-read",
          method: "patch",
          params: { ids: unreadNotifications },
          headers: { "Content-Type": "application/json" },
        }));
    } catch (error) {
      console.log("Mark as read error: ", error.message);
      toast.error("Đã có lỗi xảy ra");
    }
  };

  const toggle = () => {
    setShowOffcanvas(!showOffcanvas);
    handleMarkAsRead();
  };

  const getNotifications = async () => {
    try {
      const response = await axios({
        url: "/notification-shows",
        method: "get",
      });

      setNotifications(response.data.data.docs);
    } catch (error) {
      console.log("Notification error: ", error.message);
      toast.error("Lỗi khi tải dữ liệu");
    }
  };

  const listeningMessage = useCallback(() => {
    onMessage(messaging, (payload) => {
      toast.success(
        `${payload.notification.title}: ${payload.notification.body}`,
        {
          autoClose: 3000,
        }
      );

      getNotifications();
    });
  }, []);

  useEffect(() => {
    getNotifications();
    listeningMessage();
  }, [listeningMessage]);

  return (
    <>
      <i
        onClick={toggle}
        style={{ cursor: "pointer" }}
        className="fa-solid fa-bell fa-lg text-white d-flex justify-content-between align-items-center me-1 pt-1"
      >
        <sup>
          {
            notifications.filter(
              (notification) => notification.read_at === null
            ).length
          }
        </sup>
      </i>

      <Offcanvas show={showOffcanvas} onHide={toggle} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Thông báo</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {notifications.length ? (
            <>
              {notifications.map((notification, index) => (
                <div
                  key={index}
                  className={`card rounded-0 ${
                    index === 0 ? "border-top-0" : ""
                  } ${
                    index === notifications.length - 1 ? "border-bottom-0" : ""
                  } border-start-0 border-end-0`}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-1">
                        <span className="stretched-link">
                          <p className="mt-0 mb-2">
                            <b>{notification.payload?.notification?.title}</b>{" "}
                          </p>

                          <span className="text-secondary">
                            {notification.payload?.notification?.body}
                          </span>
                        </span>
                        <p className="mb-0 fw-medium text-muted">
                          <small>
                            <i className="fa fa-clock-rotate-left me-2"></i>{" "}
                            {notification?.created_at} -{" "}
                            {notification?.read_at !== null &&
                              `Đã đọc: ${notification?.read_at}`}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div
              id="notification-bell-empty-state"
              className="d-flex justify-content-center align-items-center w-100"
            >
              <img className="img-fluid mx-auto w-25" src={bell} alt="bell" />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NotificationBell;
