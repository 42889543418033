import axios from "axios";
import React, { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import StoreCreate from "./StoreCreate";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ingredientTypeOptions = [
  { label: "Tất cả", value: 0 },
  { label: "Kho", value: 2 },
  { label: "Vật tư", value: 3 },
];

class StoreIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liststore: [],
      selectedRows: [],
      has_inventory: [],
      inventory_his: [],
      addliststore: [],
      suppliers: [],
      ingredents: [],
      showcreate: false,
      showdetail: false,
      showhis: false,
      showdelete: false,
      showupdate: false,
      startDate: new Date(),
      tabkey: 1,
      id_ingredent: "",
      amount_delete: "",
      ingredient_search: "",
      liststore_search: [],
      typeSelected: { label: "Tất cả", value: 0 },
      update_inventory: {
        id_inventory: "",
        is_type: false,
        amount: "",
      },
      propsdata: {
        id: "",
        amount: "",
        ingredent_name: "",
      },
      checkload: true,
      units: [],
      dayRe: new Date(),
      optionSuppliers: [],
    };
  }

  async componentDidMount() {
    document.title = "Mua hàng";
    // this.getStoreData(1, 100);
    this.getSuppliersData();

    this.getUnit(100);
    this.getStore();
  }
  getStore = (type) => {
    let type_ = type === 0 || type === undefined ? "2,3" : type;
    axios.get(`ingredent-show?ingredent_type=${type_}`).then((res) => {
      if (res.data.errCode === 0) {
        this.setState({ liststore: res.data.ingredents });
        this.setState({ checkload: false });
        this.handleIngredentSearch(
          res.data.ingredents,
          this.state.ingredient_search,
          this.state.typeSelected
        );
      }
    });
  };
  getStoreData = (type, limit) => {
    axios
      .post(`/viewInventory?ingredent_type=2,3&limit=${limit}`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ liststore: res.data.data });
          this.setState({ checkload: false });
          this.handleIngredentSearch(
            res.data.data,
            this.state.ingredient_search,
            this.state.typeSelected
          );
        }
      });
  };
  getUnit = (limit = 1000) => {
    axios.get(`/unit-show`).then((res) => {
      if (res.data.status === true) {
        this.setState({ units: res.data.units });
      }
    });
  };

  getSuppliersData = () => {
    axios.get(`/supplier-show`).then((res) => {
      if (res.data.errCode === 0) {
        this.setState({ suppliers: res.data.suppliers });

        if (res.data.suppliers.length > 0)
          this.setState({
            optionSuppliers: res.data.suppliers.map((item) => ({
              value: item.id,
              label: item.supplier_name,
            })),
          });
      }
    });
  };

  gethas_inventoryData = (type, id) => {
    axios
      .post(`/viewInventory?ingredent_type=${type}&id_ingredent=${id}`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ has_inventory: res.data.data });
        }
      });
  };

  getinventory_hisData = (type, id) => {
    axios
      .post(`/viewHisInventory?ingredent_type=${type}&id_inventory=${id}`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ inventory_his: res.data.data });
        }
      });
  };

  getaddliststore = (e) => {
    e.preventDefault();
    let arr = this.state.selectedRows?.map((item) => {
      const { ingredent_name, ...rest } = item;
      return rest;
    });
    let data = {
      id_employe: localStorage.getItem("id_login_kafood") ?? "",
      rec_date: parseInt(this.state.dayRe / 1000),
      rec_shipper: "",
      invoice_code: "",
      invoice_date: parseInt(new Date() / 1000),
      rec_notes: "",
      ingredents: JSON.stringify(arr),
    };
    if (this.state.selectedRows.some((item) => item.ing_quantity <= 0)) {
      toast("Số lượng nhập phải lớn hơn bằng 0", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    axios({
      method: "post",
      url: "store-import",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      // axios.post(`/store-import`,data)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ selectedRows: "" });

          this.getStore(this.state.typeSelected?.value);

          // this.getStoreData(this.state.tabkey, 100);
          toast(res.data.message, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.message, { type: "error", autoClose: 1000 });
        }
      })
      .catch((err) => {
        toast("Hệ thống xáy ra lỗi", { type: "error", autoClose: 1000 });
      });
  };

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }
  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }
  handleIngredentSearch(root, text, typeFilter) {
    let root_ = root;
    this.setState({
      ingredient_search: text,
    });

    if (text !== "") {
      root_ = this.state.liststore.filter((e) =>
        this.toSlug(e.ingredent_name).includes(this.toSlug(text))
      );
    }

    // if (typeFilter.value !== 0) {
    //   root_ = this.state.liststore.filter(
    //     (e) => e.id_ing_type === typeFilter.value
    //   );
    // }

    this.setState({
      liststore_search: root_,
    });
  }
  handSelectAddMore(item) {
    let var_ = {
      ingredent_name: item.ingredent_name,
      id_ingredent: item.id,
      id_supplier: item.id_supplier || 0,
      // 'supplier_name': item.supplier_name||'',
      id_unit_market: item.id_unit_market || 0,
      // [
      // 	this.state.ingredents.filter((e) => e.id === item.id)[0].has_supplier
      // ],
      ing_quantity: 1,
      ing_price: item.ing_price_expect || 0,
      //  this.state.ingredents.filter((e) => e.id === item.id)[0].has_supplier.price,
      ing_mfg_date: parseInt(new Date() / 1000),
      ing_exp_date:
        item.ingredent_type === 3 ? parseInt(new Date() / 1000) : "",
      ingredent_type: item.ingredent_type,
      note: "",
    };

    let selectedRows_ = this.state.selectedRows;
    if (selectedRows_.length > 0) {
      selectedRows_.forEach((e, index) => {
        if (e.id_ingredent !== item.id) {
          selectedRows_.push(var_);
        } else {
          if (e.id_unit_market !== item.id_unit_market) {
            selectedRows_.push(var_);
          }
        }
      });
      // selectedRows_.forEach((e, index) => {
      //   if (e.id_ingredent === item.id) {
      //     // selectedRows_[index].ing_quantity = parseInt(selectedRows_[index].ing_quantity) + 1;
      //   } else {
      //     if (
      //       e.id_ingredent !== item.id &&
      //       // e.id_unit_market != item.id_unit_market &&
      //       selectedRows_.length < this.state.liststore.length
      //     ) {
      //       selectedRows_.push(var_);
      //     }
      //   }
      // });
    } else {
      selectedRows_ = [var_];
    }
    let arrObject = {};

    // selectedRows_.map(
    //   (item) => (arrObject[(item.id_ingredent)] = item)
    // );
    selectedRows_.map((item) => {
      const key = item.id_ingredent + "_" + item.id_unit_market;
      arrObject[key] = item;
      return item;
    });

    selectedRows_ = Object.values(arrObject);
    this.setState({ selectedRows: Array.from(new Set(selectedRows_)) });
    this.setState({ selectedRows: selectedRows_ });
  }
  handelete(id) {
    var listTemp = [];
    listTemp = this.state.selectedRows.filter((e) => e.id_ingredent !== id);
    this.setState({ selectedRows: listTemp });
  }

  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  renderStoreList() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Nguyên liệu
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={5}>
              <Select
                options={ingredientTypeOptions}
                placeholder={<div>Chọn loại nguyên liệu</div>}
                value={this.state.typeSelected}
                onChange={(choice) => {
                  // this.handleIngredentSearch(
                  //   this.state.liststore,
                  //   this.state.ingredient_search.trim(),
                  //   choice
                  // );
                  this.setState(
                    {
                      typeSelected: choice,
                      ingredient_search: "",
                    },
                    () => this.getStore(choice.value)
                  );
                }}
              />
            </Col>
            <Col md={7}>
              <Form.Control
                style={{ height: 39 }}
                type="text"
                placeholder="Tìm nguyên liệu"
                className="mb-3"
                value={this.state.ingredient_search}
                onChange={(e) => {
                  this.handleIngredentSearch(
                    this.state.liststore,
                    e.target.value.trim(),
                    this.state.typeSelected
                  );
                }}
              />
            </Col>
          </Row>

          {this.state.checkload ? (
            this.loading()
          ) : this.state.liststore_search.length > 0 ? (
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>Nguyên liệu</th>
                  <th className="text-center">ĐVT</th>
                  <th className="text-end">SL</th>
                </tr>
              </thead>
              <tbody>
                {this.state.liststore_search.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        this.handSelectAddMore(item);
                      }}
                    >
                      <td style={{ cursor: "pointer" }}>
                        {item.ingredent_name}{" "}
                      </td>
                      <td className="text-center">
                        {item.unit_market_name}
                        {/* {
                          this.state.units.filter(
                            (e) => e.id == item.id_unit_market
                          )[0]?.unit_name
                        }{" "} */}
                      </td>
                      <td className="text-end">
                        {this.formatter.format(item.total_qty || 0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            //  this.loading()
            <p className="text-center">Không có dữ liệu ...</p>
          )}
        </Card.Body>
      </>
    );
  }
  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  renderSupplier(value1, label1, item, i) {
    var option = [];
    this.state.suppliers.length > 0 &&
      this.state.suppliers.map((item) => {
        option.push({
          value: item.id,
          label: item.supplier_name,
        });
        return item;
      });

    return (
      <Select
        options={option}
        placeholder="Chọn Nhà cung cấp"
        defaultValue={option?.filter((e) => e.value === value1)[0]}
        onChange={(values) => {
          let selectedRows = [...this.state.selectedRows];
          selectedRows[i] = {
            ...item,
            id_supplier: values.value,
          };
          this.setState({ selectedRows: selectedRows });
        }}
        required
      ></Select>
    );
  }

  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "max-content",
      // background:'red'
      // minHeight: '48px',
      // color: state.isFocused ? 'yellow' : 'red',
      // boxShadow: state.isFocused ? '0 0 0 4px rgba(199, 246, 255, 1)' : null,
    }),
  };

  gettong() {
    var tong = 0;
    if (this.state.selectedRows.length > 0) {
      this.state.selectedRows.map((item, index) => {
        tong = tong + item.ing_quantity * item.ing_price;
        return index + 1 === this.state.selectedRows.length ? tong : 0;
      });
    }
    return tong;
  }

  renderaddStoreList() {
    return (
      <>
        <Card.Header>
          <Card.Title className="d-flex justify-content-between align-items-center mb-0">
            <span style={{ whiteSpace: "nowrap" }}>Thêm tồn kho</span>

            <div className="d-flex align-items-center">
              <label style={{ whiteSpace: "nowrap" }} className="me-1">
                Ngày mua:{" "}
              </label>
              <DatePicker
                className="form-control"
                showIcon
                dateFormat="dd/MM/yyyy"
                selected={this.state.dayRe}
                onChange={(date) => this.setState({ dayRe: date })}
              />
            </div>
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.getaddliststore}>
          <Card.Body
            style={{
              minHeight: "350px",
              overflowY: "auto",
            }}
          >
            <Table>
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Nguyên liệu</th>
                  <th>Nhà cung cấp</th>
                  <th className="text-end">Giá</th>
                  <th className="text-end">Số lượng</th>
                  <th className="text-end">Thành tiền</th>
                  <th className="text-end">Hạn sử dụng</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.selectedRows.length > 0
                  ? this.state.selectedRows.map((item, i) => {
                      return (
                        <tr key={i} className="align-middle">
                          <td>
                            <div
                              style={{
                                justifyItems: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                              className="align-middle"
                            >
                              {" "}
                              {item.ingredent_name}
                            </div>
                          </td>

                          <td
                            style={{ minWidth: "8rem" }}
                            title={
                              this.state.optionSuppliers?.filter(
                                (e) =>
                                  e.value ===
                                  this.state.selectedRows[i].id_supplier
                              )[0]?.label
                            }
                          >
                            <Select
                              options={this.state.optionSuppliers}
                              title="hihi"
                              placeholder="Chọn Nhà cung cấp"
                              value={this.state.optionSuppliers?.filter(
                                (e) =>
                                  e.value ===
                                  this.state.selectedRows[i].id_supplier
                              )}
                              onChange={(values) => {
                                let selectedRows = [...this.state.selectedRows];
                                selectedRows[i] = {
                                  ...item,
                                  id_supplier: values.value,
                                };
                                this.setState({ selectedRows: selectedRows });
                              }}
                              required
                            />
                          </td>

                          <td>
                            <CurrencyFormat
                              className="form-control text-end float-end"
                              style={{
                                height: "35px",
                                width: "70px",
                                textAlign: "right",
                              }}
                              allowNegative={false}
                              decimalScale={0}
                              name="ing_price"
                              placeholder={this.formatNumber(
                                this.state.selectedRows[i].ing_price
                              )}
                              thousandSeparator={true}
                              value={this.state.selectedRows[i].ing_price}
                              required
                              onValueChange={(values) => {
                                let selectedRows = [...this.state.selectedRows];
                                selectedRows[i] = {
                                  ...item,
                                  ing_price: values.value,
                                };

                                this.setState({ selectedRows: selectedRows });
                              }}
                            />
                          </td>

                          <td>
                            <CurrencyFormat
                              className="form-control text-end float-end"
                              style={{
                                height: "35px",
                                width: "50px",
                                textAlign: "right",
                              }}
                              name="ing_quantity"
                              thousandSeparator={true}
                              value={this.state.selectedRows[i].ing_quantity}
                              required
                              onValueChange={(values) => {
                                let selectedRows = [...this.state.selectedRows];
                                selectedRows[i] = {
                                  ...item,
                                  ing_quantity: values.value,
                                };

                                this.setState({ selectedRows: selectedRows });
                              }}
                              allowNegative={false}
                            />
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              thousandSeparator={true}
                              displayType={"text"}
                              decimalScale={0}
                              value={
                                this.state.selectedRows[i].ing_quantity *
                                this.state.selectedRows[i].ing_price
                              }
                            />
                          </td>
                          <td style={{ width: "7.8rem" }}>
                            <DatePicker
                              showIcon
                              className="form-control"
                              disabled={
                                this.state.selectedRows[i].ingredent_type === 3
                              }
                              selected={
                                this.state.selectedRows[i].ing_exp_date * 1000
                              }
                              dateFormat={
                                this.state.selectedRows[i].ingredent_type === 3
                                  ? "      "
                                  : "dd/MM/yyyy"
                              }
                              onChange={(date) => {
                                this.setState({ startDate: date });
                                let selectedRows = [...this.state.selectedRows];
                                selectedRows[i] = {
                                  ...item,
                                  ing_exp_date:
                                    item.ingredent_type === 3
                                      ? new Date() / 1000
                                      : date / 1000,
                                };
                                this.setState({ selectedRows: selectedRows });
                              }}
                              minDate={new Date()}
                              showDisabledMonthNavigation
                              required
                            />
                          </td>
                          <td className="text-center">
                            <Button
                              variant="outline-danger"
                              size="sm"
                              title="Xóa"
                              onClick={() => this.handelete(item.id_ingredent)}
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
                <tr></tr>
                <tr>
                  <td
                    colspan="4"
                    className="text-end"
                    style={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    Tổng
                  </td>
                  <td className="text-end">
                    <CurrencyFormat
                      className="fw-bold"
                      thousandSeparator={true}
                      displayType={"text"}
                      value={this.gettong()}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          {this.state.selectedRows.length > 0 ? (
            <Button
              style={{ float: "right" }}
              className="mb-2 me-3"
              type="submit"
              variant="success"
              size="sm"
            >
              <i className="fa-solid fa-check"></i> Nhập kho
            </Button>
          ) : null}
        </Form>
      </>
    );
  }

  renderStoreAdd() {
    return (
      <StoreCreate
        show={this.state.showcreate}
        onHide={() => {
          this.setState({ showcreate: false });
        }}
        getStoreData={() => {
          this.setState({ showcreate: false });
          this.getStore(this.state.typeSelected?.value);
        }}
        suppliers={this.state.suppliers}
        propsdata={this.state.propsdata}
      />
    );
  }

  render() {
    return (
      <>
        <Container fluid>
          {/* {this.renderSelectedRows()} */}
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Mua hàng</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card>{this.renderStoreList()}</Card>
            </Col>
            <Col md={8}>
              <Card>{this.renderaddStoreList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default StoreIndex;
